export const SIDE_BAR_CONTENT1_SET = 'SIDE_BAR_CONTENT1_SET';
export const SIDE_BAR_CONTENT2_SET = 'SIDE_BAR_CONTENT2_SET';

export const CHAPTER_DETAILS_TAB_SET = 'CHAPTER_DETAILS_TAB_SET';

export const SEARCH_CHAPTER_SET = 'SEARCH_CHAPTER_SET';
export const SEARCH_ASSETS_SET = 'SEARCH_ASSETS_SET';

export const CHAPTER_RANGE_VALUES_SET = 'CHAPTER_RANGE_VALUES_SET';

export const DESCRIPTION_SET = 'DESCRIPTION_SET';

export const CHAPTER_NAME_SET = 'CHAPTER_NAME_SET';
export const CHAPTER_GENRE_SET = 'CHAPTER_GENRE_SET';
export const CHAPTER_CATEGORY_SET = 'CHAPTER_CATEGORY_SET';
export const CHAPTER_PEOPLE_SET = 'CHAPTER_PEOPLE_SET';
export const CHAPTER_OBJECTS_SET = 'CHAPTER_OBJECTS_SET';
export const CHAPTER_LOCATION_SET = 'CHAPTER_LOCATION_SET';

export const EDIT_CHAPTER_DETAILS_SHOW = 'EDIT_CHAPTER_DETAILS_SHOW';
export const EDIT_CHAPTER_DETAILS_HIDE = 'EDIT_CHAPTER_DETAILS_HIDE';

export const ASSET_CHAPTER_ADD_SUCCESS = 'ASSET_CHAPTER_ADD_SUCCESS';
export const ASSET_CHAPTER_ADD_IN_PROGRESS = 'ASSET_CHAPTER_ADD_IN_PROGRESS';
export const ASSET_CHAPTER_ADD_ERROR = 'ASSET_CHAPTER_ADD_ERROR';

export const ASSET_CHAPTERS_FETCH_SUCCESS = 'ASSET_CHAPTERS_FETCH_SUCCESS';
export const ASSET_CHAPTERS_FETCH_IN_PROGRESS = 'ASSET_CHAPTERS_FETCH_IN_PROGRESS';
export const ASSET_CHAPTERS_FETCH_ERROR = 'ASSET_CHAPTERS_FETCH_ERROR';

export const CHAPTER_MIN_VALUE_SET = 'CHAPTER_MIN_VALUE_SET';
export const CHAPTER_MAX_VALUE_SET = 'CHAPTER_MAX_VALUE_SET';

export const ASSET_CHAPTER_UPDATE_SUCCESS = 'ASSET_CHAPTER_UPDATE_SUCCESS';
export const ASSET_CHAPTER_UPDATE_IN_PROGRESS = 'ASSET_CHAPTER_UPDATE_IN_PROGRESS';
export const ASSET_CHAPTER_UPDATE_ERROR = 'ASSET_CHAPTER_UPDATE_ERROR';

export const ASSET_CHAPTER_CATEGORIES_FETCH_SUCCESS = 'ASSET_CHAPTER_CATEGORIES_FETCH_SUCCESS';
export const ASSET_CHAPTER_CATEGORIES_FETCH_IN_PROGRESS = 'ASSET_CHAPTER_CATEGORIES_FETCH_IN_PROGRESS';
export const ASSET_CHAPTER_CATEGORIES_FETCH_ERROR = 'ASSET_CHAPTER_CATEGORIES_FETCH_ERROR';

export const ASSET_CHAPTER_PEOPLE_FETCH_SUCCESS = 'ASSET_CHAPTER_PEOPLE_FETCH_SUCCESS';
export const ASSET_CHAPTER_PEOPLE_FETCH_IN_PROGRESS = 'ASSET_CHAPTER_PEOPLE_FETCH_IN_PROGRESS';
export const ASSET_CHAPTER_PEOPLE_FETCH_ERROR = 'ASSET_CHAPTER_PEOPLE_FETCH_ERROR';

export const ASSET_CHAPTER_OBJECTS_FETCH_SUCCESS = 'ASSET_CHAPTER_OBJECTS_FETCH_SUCCESS';
export const ASSET_CHAPTER_OBJECTS_FETCH_IN_PROGRESS = 'ASSET_CHAPTER_OBJECTS_FETCH_IN_PROGRESS';
export const ASSET_CHAPTER_OBJECTS_FETCH_ERROR = 'ASSET_CHAPTER_OBJECTS_FETCH_ERROR';

export const SLIDER_TYPE_SET = 'SLIDER_TYPE_SET';
