import { combineReducers } from 'redux';
import {
    BUGS_ADD_SET,
    BUGS_ADD_SUCCESS,
    BUGS_DIALOG_HIDE,
    BUGS_DIALOG_SHOW,
    BUGS_SLOT_EDIT,
    BUGS_TAB_VALUE,
    FETCH_ALL_BUGS_ERROR,
    FETCH_ALL_BUGS_IN_PROGRESS,
    FETCH_ALL_BUGS_SUCCESS,
    FETCH_BUGS_ERROR,
    FETCH_BUGS_IN_PROGRESS,
    FETCH_BUGS_SUCCESS,
    TIME_VALIDATION_SET,
    UPDATED_BUGS_LIST_SET,
} from '../../constants/bugs';
import {
    DEFAULT_COUNT,
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../../constants/url';

const dialog = (state = false, action) => {
    switch (action.type) {
    case BUGS_DIALOG_SHOW:
        return true;
    case BUGS_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const bugs = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case BUGS_SLOT_EDIT:
        return {
            ...state,
            list: action.value,
        };
    case FETCH_BUGS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_BUGS_SUCCESS:
        return {
            inProgress: false,
            list: action.value,
        };
    case FETCH_BUGS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case BUGS_DIALOG_HIDE:
        return {
            ...state,
            list: [],
        };
    default:
        return state;
    }
};

const tabValue = (state = 0, action) => {
    switch (action.type) {
    case BUGS_TAB_VALUE:
        return action.value;
    case BUGS_DIALOG_HIDE:
        return 0;
    default:
        return state;
    }
};

const allBugs = (state = {
    list: [],
    inProgress: false,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: DEFAULT_COUNT,
    searchKey: DEFAULT_SEARCH_KEY,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    id: '',
}, action) => {
    switch (action.type) {
    case FETCH_ALL_BUGS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_BUGS_SUCCESS:
        return {
            list: action.value,
            inProgress: false,
            count: action.count,
            skip: action.skip,
            limit: action.limit,
            searchKey: action.searchKey,
            sortBy: action.sortBy,
            order: action.order,
            id: action.id,
        };
    case FETCH_ALL_BUGS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const addBugs = (state = {
    list: [],
    time: null,
}, action) => {
    switch (action.type) {
    case BUGS_ADD_SET:
        return {
            ...state,
            list: action.value,
        };
    case BUGS_DIALOG_SHOW:
        return {
            ...state,
            time: action.value,
        };
    case BUGS_ADD_SUCCESS:
        return {
            ...state,
            list: [],
        };
    case BUGS_DIALOG_HIDE:
        return {
            ...state,
            time: null,
        };
    default:
        return state;
    }
};

const slot = (state = {}, action) => {
    switch (action.type) {
    case BUGS_DIALOG_SHOW:
        return action.slot;
    case BUGS_DIALOG_HIDE:
        return {};
    default:
        return state;
    }
};

const updatedBugs = (state = {
    list: [],
}, action) => {
    if (action.type === UPDATED_BUGS_LIST_SET) {
        return {
            list: action.value,
        };
    }

    return state;
};

const timeValidation = (state = false, action) => {
    switch (action.type) {
    case TIME_VALIDATION_SET:
        return action.value;
    case BUGS_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

export default combineReducers({
    dialog,
    bugs,
    tabValue,
    allBugs,
    addBugs,
    slot,
    updatedBugs,
    timeValidation,
});
