import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import events from '../../../../dummy/events';

const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
        style: {
            backgroundColor: 'lightblue',
        },
    });

class Agenda extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            events,
        };
    }

    render () {
        const localizer = momentLocalizer(moment);
        return (
            <div className="calendar_section">
                <Calendar
                    selectable
                    showMultiDayTimes
                    components={{
                        timeSlotWrapper: ColoredDateCellWrapper,
                    }}
                    events={[]}
                    localizer={localizer}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    step={60}
                    views={['month']}
                    onSelectSlot={(event) => this.props.select(event)}/>
            </div>
        );
    }
}

Agenda.propTypes = {
    select: PropTypes.func.isRequired,
};

export default Agenda;
