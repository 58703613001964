import { Button } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateInteractiveVideo } from '../../actions/interactiveVideo';

const PublishButton = (props) => {
    const handleClick = () => {
        props.update({ isPublic: true }, props.currentInteractiveVideo &&
            props.currentInteractiveVideo._id, localStorage.getItem('authorizationToken'));
    };

    return (
        <Button
            className="add_question_button"
            onClick={handleClick}>
            Publish Video
        </Button>
    );
};

PublishButton.propTypes = {
    currentInteractiveVideo: PropTypes.shape({
        _id: PropTypes.string.isRequired,
    }).isRequired,
    update: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        currentInteractiveVideo: state.interactiveVideo.current.data,
    };
};

const actionToProps = {
    update: updateInteractiveVideo,
};

export default connect(stateToProps, actionToProps)(PublishButton);
