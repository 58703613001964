import { InputAdornment } from '@material-ui/core';
import { Instagram } from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setInstagramID } from '../../../../actions/asset';
import TextField from '../../../../components/TextField/InputProps';

const InstagramTextField = (props) => {
    const onChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            className="icon_text_field"
            id="instagram_text_field"
            inputProps={{
                startAdornment: (
                    <InputAdornment className="icon_div" position="start">
                        <Instagram/>
                    </InputAdornment>
                ),
            }}
            name="instagram"
            type="text"
            value={props.value}
            variant="outlined"
            onChange={onChange}/>
    );
};

InstagramTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.instagramID,
    };
};

const actionsToProps = {
    onChange: setInstagramID,
};

export default connect(stateToProps, actionsToProps)(InstagramTextField);
