import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import ImageUploading from 'react-images-uploading';
import { connect } from 'react-redux';
import {
    showDialogDeleteThumb,
    showDialogUpdateThumb,
    showDialogUploadThumb,
    showDialogViewThumb,
    uploadAssetThumbnail,
    uploadedThumbs,
} from '../../../../actions/asset';
import addIcon from '../../../../assets/addYellow.svg';
import deleteIcon from '../../../../assets/thumbnails/delete.png';
import viewIcon from '../../../../assets/thumbnails/view.png';
import DialogDeleteThumb from './DialogDeleteThumb';
import DialogViewThumb from './DialogViewThumb';
import './index.css';

class Thumbnail extends React.Component {
    constructor (props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange (imageList, addUpdateIndex, type) {
        const arr = [...this.props.thumbsList];

        arr.push({
            img: imageList && imageList[0],
            type: type,
        });
        const successArr = this.props.successList;

        const img = new Image();
        img.src = imageList[0].data_url;
        img.onload = () => {
            if (type === 'horizontal') {
                if (img.width > img.height) {
                    this.props.uploadAssetThumbnail(imageList && imageList[0], type, (cb) => {
                        if (cb) {
                            successArr.push({
                                type: type,
                                img: cb && cb.img_path,
                            });
                            this.props.uploadedThumbs([...arr], [...successArr]);
                        }
                    });
                } else {
                    alert('upload a file where width is greater than height. For ex: 1920 (w) x 1080 (h)');
                }
            } else if (type === 'vertical') {
                if (img.height > img.width) {
                    this.props.uploadAssetThumbnail(imageList && imageList[0], type, (cb) => {
                        if (cb) {
                            successArr.push({
                                type: type,
                                img: cb && cb.img_path,
                            });
                            this.props.uploadedThumbs([...arr], [...successArr]);
                        }
                    });
                } else {
                    alert('upload a file where height is greater than width. For ex: 1080 (w) x 1920 (h)');
                }
            } else if (type === 'square') {
                if (img.height === img.width) {
                    this.props.uploadAssetThumbnail(imageList && imageList[0], type, (cb) => {
                        if (cb) {
                            successArr.push({
                                type: type,
                                img: cb && cb.img_path,
                            });
                            this.props.uploadedThumbs([...arr], [...successArr]);
                        }
                    });
                } else {
                    alert('upload a file where height is equal to width. For ex: 1080 (w) x 1080 (h)');
                }
            }
        };
    }

    render () {
        const images = [];
        const horizontal = this.props.thumbsList && this.props.thumbsList.length > 0
            ? this.props.thumbsList.filter((item) => (item && item.type === 'horizontal')) : [];
        const vertical = this.props.thumbsList && this.props.thumbsList.length > 0
            ? this.props.thumbsList.filter((item) => (item && item.type === 'vertical')) : [];
        const square = this.props.thumbsList && this.props.thumbsList.length > 0
            ? this.props.thumbsList.filter((item) => (item && item.type === 'square')) : [];

        return (
            <div className="thumbnail">
                <div className="thumbnail_header"> Thumbnails</div>
                <div className="thumb_section">
                    {
                        this.props.thumbData && this.props.thumbData.horizontal
                            ? <div className="thumnb_section1">
                                <div className="thumb_img horizontal_img">
                                    <img alt="" src={this.props.thumbData.horizontal}/>
                                </div>
                                <div className="thumb_actions">
                                    <div onClick={() => this.props.showDialogDeleteThumb('horizontal', 'main')}>
                                        <img alt="" src={deleteIcon}/>
                                    </div>
                                    <div
                                        onClick={() => this.props.showDialogViewThumb(this.props.thumbData.horizontal, 'horizontal')}>
                                        <img alt="" src={viewIcon}/>
                                    </div>
                                </div>
                            </div>
                            : horizontal && horizontal.length > 0
                                ? <div className="thumnb_section1">
                                    <div className="thumb_img horizontal_img">
                                        <img
                                            alt=""
                                            src={horizontal && horizontal[0] && horizontal[0].img && horizontal[0].img.data_url}/>
                                    </div>
                                    <div className="thumb_actions">
                                        <div
                                            onClick={() => this.props.showDialogDeleteThumb(horizontal && horizontal[0] && horizontal[0].type, 'local')}>
                                            <img alt="" src={deleteIcon}/>
                                        </div>
                                        <div onClick={() => this.props.showDialogViewThumb(horizontal[0])}>
                                            <img alt="" src={viewIcon}/>
                                        </div>
                                    </div>
                                </div>
                                : <ImageUploading
                                    dataURLKey="data_url"
                                    maxNumber={69}
                                    value={images}
                                    onChange={(imageList, addUpdateIndex) => this.onChange(imageList, addUpdateIndex, 'horizontal')}
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps,
                                    }) => (
                                        <Button
                                            className="thumbnails_section horizontal_thumb"
                                            title="Click to select landscape Thumbnail"
                                            onClick={onImageUpload}
                                            {...dragProps}>
                                            <img alt="horizontal" src={addIcon}/>
                                        </Button>
                                    )}
                                </ImageUploading>
                    }
                    {
                        this.props.thumbData && this.props.thumbData.vertical
                            ? <div className="thumnb_section1">
                                <div className="thumb_img vertical_img">
                                    <img alt="" src={this.props.thumbData.vertical}/>
                                </div>
                                <div className="thumb_actions">
                                    <div onClick={() => this.props.showDialogDeleteThumb('vertical', 'main')}>
                                        <img alt="" src={deleteIcon}/>
                                    </div>
                                    <div
                                        onClick={() => this.props.showDialogViewThumb(this.props.thumbData.vertical, 'vertical')}>
                                        <img alt="" src={viewIcon}/>
                                    </div>
                                </div>
                            </div>
                            : vertical && vertical.length > 0
                                ? <div className="thumnb_section1">
                                    <div className="thumb_img vertical_img">
                                        <img
                                            alt=""
                                            src={vertical && vertical[0] && vertical[0].img && vertical[0].img.data_url}/>
                                    </div>
                                    <div className="thumb_actions">
                                        <div
                                            onClick={() => this.props.showDialogDeleteThumb(vertical && vertical[0] && vertical[0].type, 'local')}>
                                            <img alt="" src={deleteIcon}/>
                                        </div>
                                        <div onClick={() => this.props.showDialogViewThumb(vertical[0])}>
                                            <img alt="" src={viewIcon}/>
                                        </div>
                                    </div>
                                </div>
                                : <ImageUploading
                                    dataURLKey="data_url"
                                    maxNumber={69}
                                    value={images}
                                    onChange={(imageList, addUpdateIndex) => this.onChange(imageList, addUpdateIndex, 'vertical')}
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps,
                                    }) => (
                                        <Button
                                            className="thumbnails_section vertical_thumb"
                                            title="Click to select Portrait Thumbnail"
                                            onClick={onImageUpload}
                                            {...dragProps}>
                                            <img alt="vertical" src={addIcon}/>
                                        </Button>
                                    )}
                                </ImageUploading>
                    }
                    {
                        this.props.thumbData && this.props.thumbData.square
                            ? <div className="thumnb_section1">
                                <div className="thumb_img square_img">
                                    <img alt="" src={this.props.thumbData.square}/>
                                </div>
                                <div className="thumb_actions">
                                    <div onClick={() => this.props.showDialogDeleteThumb('square', 'main')}>
                                        <img alt="" src={deleteIcon}/>
                                    </div>
                                    <div
                                        onClick={() => this.props.showDialogViewThumb(this.props.thumbData.square, 'square')}>
                                        <img alt="" src={viewIcon}/>
                                    </div>
                                </div>
                            </div>
                            : square && square.length > 0
                                ? <div className="thumnb_section1">
                                    <div className="thumb_img square_img">
                                        <img alt="" src={square && square[0] && square[0].img && square[0].img.data_url}/>
                                    </div>
                                    <div className="thumb_actions">
                                        <div
                                            onClick={() => this.props.showDialogDeleteThumb(square && square[0] && square[0].type, 'local')}>
                                            <img alt="" src={deleteIcon}/>
                                        </div>
                                        <div onClick={() => this.props.showDialogViewThumb(square[0])}>
                                            <img alt="" src={viewIcon}/>
                                        </div>
                                    </div>
                                </div>
                                : <ImageUploading
                                    dataURLKey="data_url"
                                    maxNumber={69}
                                    value={images}
                                    onChange={(imageList, addUpdateIndex) => this.onChange(imageList, addUpdateIndex, 'square')}
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps,
                                    }) => (
                                        <Button
                                            className="thumbnails_section square_thumb"
                                            title="Click to select square Thumbnail"
                                            onClick={onImageUpload}
                                            {...dragProps}>
                                            <img alt="square" src={addIcon}/>
                                        </Button>
                                    )}
                                </ImageUploading>
                    }
                </div>
                {/* <div className="thumb_section"> */}
                {/*    { */}
                {/*        this.props.thumbData && this.props.thumbData.horizontal */}
                {/*            ? <div className="thumnb_section1"> */}
                {/*                <div className="thumb_img"> */}
                {/*                    <img alt="" src={this.props.thumbData.horizontal}/> */}
                {/*                </div> */}
                {/*                <div className="thumb_actions"> */}
                {/*                    <div onClick={() => this.props.showDialogDeleteThumb('horizontal', 'main')}> */}
                {/*                        <img alt="" src={deleteIcon}/> */}
                {/*                    </div> */}
                {/*                    <div */}
                {/*                        onClick={() => this.props.showDialogViewThumb(this.props.thumbData.horizontal, 'horizontal')}> */}
                {/*                        <img alt="" src={viewIcon}/> */}
                {/*                    </div> */}
                {/*                </div> */}
                {/*            </div> */}
                {/*            : null */}
                {/*    } */}
                {/*    { */}
                {/*        this.props.thumbData && this.props.thumbData.vertical */}
                {/*            ? <div className="thumnb_section1"> */}
                {/*                <div className="thumb_img"> */}
                {/*                    <img alt="" src={this.props.thumbData.vertical}/> */}
                {/*                </div> */}
                {/*                <div className="thumb_actions"> */}
                {/*                    <div onClick={() => this.props.showDialogDeleteThumb('vertical', 'main')}> */}
                {/*                        <img alt="" src={deleteIcon}/> */}
                {/*                    </div> */}
                {/*                    <div */}
                {/*                        onClick={() => this.props.showDialogViewThumb(this.props.thumbData.vertical, 'vertical')}> */}
                {/*                        <img alt="" src={viewIcon}/> */}
                {/*                    </div> */}
                {/*                </div> */}
                {/*            </div> */}
                {/*            : null */}
                {/*    } */}
                {/*    { */}
                {/*        this.props.thumbData && this.props.thumbData.square */}
                {/*            ? <div className="thumnb_section1"> */}
                {/*                <div className="thumb_img"> */}
                {/*                    <img alt="" src={this.props.thumbData.square}/> */}
                {/*                </div> */}
                {/*                <div className="thumb_actions"> */}
                {/*                    <div onClick={() => this.props.showDialogDeleteThumb('square', 'main')}> */}
                {/*                        <img alt="" src={deleteIcon}/> */}
                {/*                    </div> */}
                {/*                    <div */}
                {/*                        onClick={() => this.props.showDialogViewThumb(this.props.thumbData.square, 'square')}> */}
                {/*                        <img alt="" src={viewIcon}/> */}
                {/*                    </div> */}
                {/*                </div> */}
                {/*            </div> */}
                {/*            : null */}
                {/*    } */}
                {/*    { */}
                {/*        this.props.thumbsList && this.props.thumbsList.length > 0 */}
                {/*            ? this.props.thumbsList.map((thumb, index) => { */}
                {/*                return ( */}
                {/*                    <div key={index} className="thumnb_section1"> */}
                {/*                        <div className="thumb_img"> */}
                {/*                            <img alt="" src={thumb && thumb.img && thumb.img.data_url}/> */}
                {/*                        </div> */}
                {/*                        <div className="thumb_actions"> */}
                {/*                            <div */}
                {/*                                onClick={() => this.props.showDialogDeleteThumb(thumb && thumb.type, 'local')}> */}
                {/*                                <img alt="" src={deleteIcon}/> */}
                {/*                            </div> */}
                {/*                            <div onClick={() => this.props.showDialogViewThumb(thumb)}> */}
                {/*                                <img alt="" src={viewIcon}/> */}
                {/*                            </div> */}
                {/*                        </div> */}
                {/*                    </div> */}
                {/*                ); */}
                {/*            }, */}
                {/*            ) */}
                {/*            : null */}
                {/*    } */}
                {/* </div> */}
                {/* <div className="upload_thumb_section"> */}
                {/*    <ImageUploading */}
                {/*        dataURLKey="data_url" */}
                {/*        maxNumber={69} */}
                {/*        value={images} */}
                {/*        onChange={this.onChange} */}
                {/*    > */}
                {/*        {({ */}
                {/*            imageList, */}
                {/*            onImageUpload, */}
                {/*            onImageRemoveAll, */}
                {/*            onImageUpdate, */}
                {/*            onImageRemove, */}
                {/*            isDragging, */}
                {/*            dragProps, */}
                {/*        }) => ( */}
                {/*            // write your building UI */}
                {/*            <div className="upload__image-wrapper"> */}
                {/*                <div className="upload_thumb_div"> */}
                {/*                    <Button */}
                {/*                        className="upload_thumb" */}
                {/*                        onClick={onImageUpload} */}
                {/*                        {...dragProps}> */}
                {/*                        Upload */}
                {/*                    </Button> */}
                {/*                    <div> Please add thumbnails to Asset</div> */}
                {/*                </div> */}
                {/*            </div> */}
                {/*        )} */}
                {/*    </ImageUploading> */}
                {/* </div> */}
                <DialogDeleteThumb/>
                <DialogViewThumb/>
                {/* <DialogUploadThumb/> */}
            </div>
        );
    }
}

Thumbnail.propTypes = {
    showDialogDeleteThumb: PropTypes.func,
    showDialogUpdateThumb: PropTypes.func,
    showDialogUploadThumb: PropTypes.func,
    showDialogViewThumb: PropTypes.func,
    successList: PropTypes.array,
    thumbData: PropTypes.object,
    thumbsList: PropTypes.array,
    uploadAssetThumbnail: PropTypes.func,
    uploadedThumbs: PropTypes.func,
    value: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        thumbsList: state.asset.thumbsList.list,
        successList: state.asset.thumbsList.successList,
        value: state.asset.dialogUploadThumb.thumbs,
        thumbData: state.asset.thumbData,
    };
};

const actionToProps = {
    showDialogDeleteThumb: showDialogDeleteThumb,
    showDialogViewThumb: showDialogViewThumb,
    showDialogUpdateThumb: showDialogUpdateThumb,
    showDialogUploadThumb: showDialogUploadThumb,
    uploadAssetThumbnail: uploadAssetThumbnail,
    uploadedThumbs: uploadedThumbs,
};

export default connect(stateToProps, actionToProps)(Thumbnail);
