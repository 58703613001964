import React from 'react';
import CustomIcon from '../../../../components/Icon';
import TextField from '../../../../components/TextField';
import { connect } from 'react-redux';
import { addChannelLiveStreamText } from '../../../../actions/channel';
import * as PropTypes from 'prop-types';

class GenerateText extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            text: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.insertText = this.insertText.bind(this);
    }

    handleChange (e) {
        this.setState({ text: e });
    }

    insertText () {
        this.props.addChannelLiveStreamText(this.props.channel,
            this.props.stream,
            { text: this.state.text }, (error) => {
                if (error) {
                    return;
                }

                this.setState({ text: '' });
            });
    }

    render () {
        return (
            <div className="generated_text">
                <div className="header">
                    <div className="header_left">
                        <CustomIcon
                            className="generated_text"
                            icon="generated_text"/>
                        <p className="sarabun_font_weight">
                            Generate Text or GFX
                        </p>
                    </div>
                </div>
                <div className="section1">
                    <div>
                        <TextField
                            label={'Enter text to display'}
                            value={this.state.text}
                            variant={'outlined'}
                            onChange={(e) => this.handleChange(e)}/>
                    </div>
                    <div className="buttons_section">
                        <button
                            className="sarabun_font button_live"
                            disabled={!this.state.text}
                            onClick={this.insertText}>
                            Insert Into Stream
                        </button>
                        <button className="sarabun_font button_live">
                            Set a Timer
                        </button>
                    </div>
                    <div className="section1_text">
                    </div>
                </div>
            </div>
        );
    }
}

GenerateText.propTypes = {
    addChannelLiveStreamText: PropTypes.func.isRequired,
    channel: PropTypes.string.isRequired,
    stream: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        stream: state.live.stream.current,
        channel: state.live.channel.current,
    };
};

const actionsToProps = {
    addChannelLiveStreamText,
};

export default connect(stateToProps, actionsToProps)(GenerateText);
