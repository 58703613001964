import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import { setOptions } from '../../../actions/interactiveVideo';

const Option1TextField = (props) => {
    const onChange = (value) => {
        const option = props.index;
        let obj;
        if (props.goto) {
            obj = {
                ...props.options,
                [option]: {
                    ...props.options[option],
                    name: value,
                },
            };
        } else {
            obj = {
                ...props.options,
                [option]: value,
            };
        }

        props.onChange(obj);
    };

    return (
        <TextField
            id={props.index + '_text_field'}
            name={props.index}
            placeholder={props.index}
            type="text"
            value={props.value}
            onChange={onChange}/>
    );
};

Option1TextField.propTypes = {
    index: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    goto: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        options: state.interactiveVideo.options.options,
    };
};

const actionsToProps = {
    onChange: setOptions,
};

export default connect(stateToProps, actionsToProps)(Option1TextField);
