export const INTERACTIVE_QUESTION_SET = 'INTERACTIVE_QUESTION_SET';
export const INTERACTIVE_QUESTIONS_LIST_SET = 'INTERACTIVE_QUESTIONS_LIST_SET';
export const INTERACTIVE_PLAYER_SET = 'INTERACTIVE_PLAYER_SET';
export const INTERACTIVE_QUESTION_TYPE_SET = 'INTERACTIVE_QUESTION_TYPE_SET';

export const DEFAULT_OPTION_SET = 'DEFAULT_OPTION_SET';
export const OPTIONS_SET = 'OPTIONS_SET';
export const OPTION_TYPE_SET = 'OPTION_TYPE_SET';

export const QUESTION_TYPES_SHOW = 'QUESTION_TYPES_SHOW';

export const INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const INTERACTIVE_VIDEOS_FETCH_ERROR = 'INTERACTIVE_VIDEOS_FETCH_ERROR';

export const INTERACTIVE_VIDEO_ADD_IN_PROGRESS = 'INTERACTIVE_VIDEO_ADD_IN_PROGRESS';
export const INTERACTIVE_VIDEO_ADD_SUCCESS = 'INTERACTIVE_VIDEO_ADD_SUCCESS';
export const INTERACTIVE_VIDEO_ADD_ERROR = 'INTERACTIVE_VIDEO_ADD_ERROR';

export const INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_IN_PROGRESS = 'INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_IN_PROGRESS';
export const INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_SUCCESS = 'INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_SUCCESS';
export const INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_ERROR = 'INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_ERROR';

export const INTERACTIVE_EDIT_SET = 'INTERACTIVE_EDIT_SET';
export const INTERACTIVE_SAVE_SET = 'INTERACTIVE_SAVE_SET';

export const INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS = 'INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS';
export const INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_SUCCESS = 'INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_SUCCESS';
export const INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_ERROR = 'INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_ERROR';

export const INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_IN_PROGRESS = 'INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_IN_PROGRESS';
export const INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_SUCCESS = 'INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_SUCCESS';
export const INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_ERROR = 'INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_ERROR';

export const CURRENT_INTERACTION_SET = 'CURRENT_INTERACTION_SET';

export const INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS = 'INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS';
export const INTERACTIVE_VIDEO_UPDATE_SUCCESS = 'INTERACTIVE_VIDEO_UPDATE_SUCCESS';
export const INTERACTIVE_VIDEO_UPDATE_ERROR = 'INTERACTIVE_VIDEO_UPDATE_ERROR';
