import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import settingsIcon from '../../../../../assets/settings.svg';
import Icon from '../../../../../components/Icon';
import './index.css';
import SlotPreview from './SlotProview';

const SlotsTabs = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="slots_sections">
            <div className="slots_header">
                <div className="slots_tabs_section">
                    <AppBar position="static">
                        <Tabs className="tabs_class" value={value} onChange={handleChange}>
                            <Tab
                                className={value === 0 ? 'active_class tab_class' : 'tab_class'}
                                icon={<Icon className="slotPreview" icon="slotPreview"/>}
                                label="Slot Preview"/>
                            <div className="tabs_divider">
                                <div className=""/>
                            </div>
                            <Tab
                                className={value === 2 ? 'active_class tab_class' : 'tab_class'}
                                icon={<Icon className="streamPromo" icon="streamPromo"/>}
                                label="Ad Configuration"/>
                            <div className="tabs_divider">
                                <div className=""/>
                            </div>
                            <Tab
                                className={value === 4 ? 'active_class tab_class' : 'tab_class'}
                                icon={<Icon className="overlays" icon="overlays"/>}
                                label="Overlay"/>
                        </Tabs>
                    </AppBar>
                </div>
                <div className="slots_header_right">
                    <div> 140min 22sec/180min</div>
                    <Button>
                        <img alt="settings" src={settingsIcon}/>
                    </Button>
                </div>
            </div>
            {value === 0 &&
            <div className="value_active stream slots_content_section">
                <SlotPreview/>
            </div>}
            {value !== 0 && <div className={value === 0 ? 'value_active slots_content_section' : 'slots_content_section'}/>}
        </div>
    );
};

export default SlotsTabs;
