import React from 'react';
import LanguageCodeSelectField from './LanguageCode';
import TextTextField from './TextTextField';
import VoiceSelectField from './VoiceSelectField';

const TextToSpeech = () => {
    return (
        <div className="category_fields">
            <div className="width_100_percentage">
                <TextTextField/>
            </div>
            <LanguageCodeSelectField/>
            <VoiceSelectField/>
        </div>
    );
};

export default TextToSpeech;
