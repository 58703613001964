const API_URL = process.env.REACT_APP_API_URL;

export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_COUNT = 10;
export const DEFAULT_SEARCH_KEY = '';
export const DEFAULT_SORT_BY = '';
export const DEFAULT_ORDER = null;

export const BASE_URL = 'https://ctlabs.omniflix.studio';
export const UPLOAD_ASSET_THUMB = `${BASE_URL}/upload-thumbnail`;

export const APP_URL = process.env.REACT_APP_APP_URL;
export const USER_LOGIN = `${API_URL}/login`;

export const USER_LOGOUT = `${API_URL}/me/logout`;
export const USER_ACCESS_TOKEN = `${API_URL}/me/access-token`;
export const URL_ASSET_GENRES_GET = `${API_URL}/genres`;

export const URL_ASSET_LIST = `${API_URL}/me/assets`;
export const URL_FOLDER_LIST = `${API_URL}/me/folders`;

export const URL_CHANNELS_LIST = `${API_URL}/me/channels`;

export const URL_RUNNERS_LIST = `${API_URL}/me/runners`;

export const URL_CHANNEL_LANGUAGES_GET = `${API_URL}/public/languages`;
export const URL_CHANNEL_CATEGORIES_GET = `${API_URL}/me/asset-categories`;

export const TAGS_LIST_URL = `${API_URL}/me/asset-tags`;

export const SLOT_TEMPLATE_URL = `${API_URL}/me/slot-templates`;

export const URL_YOUTUBE_EMBED = 'https://www.youtube.com/embed';

export const REST_URL = 'https://flixnet1-api.freeflix.network';
export const RPC_URL = 'https://flixnet1-rpc.freeflix.network';
export const FAUCET_URL = 'https://flixnet1-faucet-api.freeflix.network/claim-tokens';
export const ACCOUNTS_URL = `${API_URL}/me/accounts`;
export const LINEAR_BROADCAST_MINT_URL = `${REST_URL}/nft/linearBroadcastNFT`;
export const INTERACTIVE_VIDEO_URL = `${API_URL}/interactive-videos`;

export const urlFetchProgram = (channelId, liveStreamId, slotId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots/${slotId}/programs?sortBy=start_at`
);

export const urlAddProgram = (channelId, liveStreamId, slotId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots/${slotId}/programs`
);

export const urlUpdateProgram = (channelId, liveStreamId, slotId, programId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots/${slotId}/programs/${programId}`
);

export const urlDeleteProgram = (channelId, liveStreamId, slotId, programId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots/${slotId}/programs/${programId}`
);

export const urlAddProgramOverlay = (channelId, liveStreamId, slotId, programId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots/${slotId}/programs/${programId}/overlays`
);

export const urlUpdateProgramOverlay = (channelId, liveStreamId, slotId, programId, overlayId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots/${slotId}/programs/${programId}/overlays/${overlayId}`
);

export const urlDeleteProgramOverlay = (channelId, liveStreamId, slotId, programId, overlayId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots/${slotId}/programs/${programId}/overlays/${overlayId}`
);

export const urlFetchSlots = (channelId, liveStreamId, startAt, endAt, sortBy, order) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots?` +
    `startAt=${startAt}&endAt=${endAt}&sortBy=${sortBy}&order=${order}`
);

export const urlAddSlot = (channelId, liveStreamId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots`
);

export const urlUpdateSlot = (channelId, liveStreamId, slotId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots/${slotId}`
);

export const urlPushSlot = (channelId, liveStreamId, slotId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots/${slotId}/push`
);

export const urlStartStream = (channelId, liveStreamId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/start`
);

export const urlStream = (channelId, liveStreamId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}`
);

export const urlStopStream = (channelId, liveStreamId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/stop`
);

export const urlDeleteSlot = (channelId, liveStreamId, slotId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots/${slotId}`
);

export const getCategoriesData = (id) => {
    return `${API_URL}/me/assets?category=${id}`;
};

export const URL_ASSET_CATEGORIES_GET = `${API_URL}/me/asset-categories`;
// export const URL_ASSET_GET = `${API_URL}/me/assets?category=5de5fc890b8ab70f9e66d90c`;
export const URL_FILES_LIST = `${API_URL}/me/files`;
export const URL_ASSETS_LIST = `${API_URL}/me/assets`;
export const URL_SYNC_ASSETS = `${API_URL}/me/assets/sync`;
export const ASSETS_OVERVIEW_URL = `${API_URL}/me/assets-overview`;
export const getAssetListURL = (type, encode, recent, days, skip, limit, search, sortBy, order) => {
    const params = [
        'total=true',
    ];

    if (type) {
        params.push(`type=${type}`);
    }
    if (encode) {
        params.push(`encode=${encode}`);
    }
    if (recent) {
        params.push(`recent=${recent}`);
    }
    if (days) {
        params.push(`days=${days}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (search && search.length > 0) {
        params.push(`search=${search}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${API_URL}/me/assets?${params.join('&')}`;
};

export const getBugsListURL = (id, skip, limit, search, sortBy, order) => {
    const params = [
        'total=true',
    ];

    if (id) {
        params.push(`category=${id}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (search && search.length > 0) {
        params.push(`search=${search}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${API_URL}/me/assets?${params.join('&')}`;
};

export const urlFetchAssets = (encode) => (
    `${API_URL}/me/assets?encode=${encode}`
);

export const URL_CHANNELS_GET = `${API_URL}/me/channels`;
export const URL_AUTHENTICATE_CHANNELS = `${API_URL}/me/channels/oauth/google`;
export const URL_CHANNEL_LIVE_STREAMS_GET = `${API_URL}/channels`;

export const urlFetchChannelLiveStreams = (channelId) => (
    `${API_URL}/me/channels/${channelId}/live-streams`
);

export const urlFetchPlayOut = (channelId, liveStreamId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/status`
);

export const getTagsURL = (categories) => {
    const params = [];

    if (categories && categories.length) {
        categories.map((value) => params.push(`categories[]=${value}`));
    }

    return `${API_URL}/me/asset-tags?encode=true&${params.join('&')}`;
};

export const urlFillPrograms = (channelId, liveStreamId, slotId) => (
    `${URL_CHANNELS_LIST}/${channelId}/live-streams/${liveStreamId}/slots/${slotId}/fill-programs?append=true`
);

export const urlFetchProfileImage = (organizationId, userId) => (
    `${API_URL}/organizations/${organizationId}/users/${userId}/profile/image`
);

export const getAssetsOverviewUrl = (encode) => {
    const params = [];

    if (encode) {
        params.push(`encode=${encode}`);
    }

    return `${ASSETS_OVERVIEW_URL}?${params.join('&')}`;
};

export const uploadFileURL = (ip, organizationId) => (
    `https://${ip}/organizations/${organizationId}/files/upload`
);

export const reUploadFileURL = (ip, organizationId, assetId) => (
    `https://${ip}/organizations/${organizationId}/assets/${assetId}/file-upload`
);

export const downloadFileURL = (ip, organizationId, path) => {
    const params = [];
    if (path) {
        params.push(`path=${path}`);
    }

    return `https://${ip}/organizations/${organizationId}/files/download?${params.join('&')}`;
};

export const fetchBugsURL = (channelID, streamID, slotID) => {
    return `${API_URL}/me/channels/${channelID}/live-streams/${streamID}/slots/${slotID}/overlays`;
};

export const updateBugsURL = (channelID, streamID, slotID, ID) => {
    return `${API_URL}/me/channels/${channelID}/live-streams/${streamID}/slots/${slotID}/overlays/${ID}`;
};

export const slotTemplateUpdateURL = (ID) => {
    return `${API_URL}/me/slot-templates/${ID}`;
};

export const fetchPeopleURL = `${API_URL}/people`;
export const fetchAlbumURL = `${API_URL}/me/albums`;

export const peopleUpdate = (ID) => {
    return `${API_URL}/people/${ID}`;
};

export const albumUpdate = (ID) => {
    return `${API_URL}/me/albums/${ID}`;
};

export const addTextToAudioURL = `${API_URL}/me/generate-audio-from-text`;

export const urlFetchScenes = (id) => {
    return `${API_URL}/me/assets/${id}/scenes`;
};

export const urlFetchAsset = (id) => (
    `${API_URL}/me/assets/${id}`
);

export const urlUpdateAssetNFT = (id) => (
    `${API_URL}/me/assets/${id}/nft`
);

export const urlAddAssetChapter = (id, categoryId, peopleId, objectName) => {
    const params = [];

    if (categoryId) {
        params.push(`category=${categoryId}`);
    }

    if (peopleId) {
        params.push(`people=${peopleId}`);
    }

    if (objectName) {
        params.push(`object=${objectName}`);
    }

    return `${API_URL}/me/assets/${id}/chapters?${params.join('&')}`;
};

export const urlFetchChannelLiveStreamConfig = (channelId, streamId) => (
    `${API_URL}/me/channels/${channelId}/live-streams/${streamId}/configuration`
);

export const urlAddChannelLiveStreamText = (channelId, streamId) => (
    `${API_URL}/me/channels/${channelId}/live-streams/${streamId}/live-text`
);

export const urlUpdateAssetChapter = (assetId, chapterId) => (
    `${API_URL}/me/assets/${assetId}/chapters/${chapterId}`
);

export const urlFetchAssetChapterCategories = (id) => (
    `${API_URL}/me/assets/${id}/chapter-categories`
);

export const urlFetchAssetChapterPeople = (id) => (
    `${API_URL}/me/assets/${id}/chapter-people`
);

export const urlFetchAssetChapterObjects = (id) => (
    `${API_URL}/me/assets/${id}/chapter-objects`
);

export const urlClearPrograms = (channelId, streamId, slotId) => (
    `${API_URL}/me/channels/${channelId}/live-streams/${streamId}/slots/${slotId}/clear-programs`
);

export const urlWatchURL = (ID, IP) => {
    const params = [];

    if (IP) {
        params.push(`?deviceId=${IP}`);
    }

    return `${API_URL}/assets/${ID}/watch-url${params.join('&')}`;
};

export const urlFetchBalance = (address) => (
    `${REST_URL}/bank/balances/${address}`
);

export const urlMintNFT = (id) => (
    `${API_URL}/me/assets/${id}/mint-nft`
);

export const urlFetchNFT = (address) => (
    `${REST_URL}/nft/mediaNFT?assetID=${address}`
);

export const authenticateAccountURL = (id) => `${API_URL}/accounts/${id}/auth`;
export const accountURL = (ID) => `${API_URL}/me/accounts/${ID}`;

export const urlFetchInteractiveVideo = (assetId, createdBy, skip, limit) => {
    const params = [];

    if (assetId) {
        params.push(`assetId=${assetId}`);
    }
    if (createdBy) {
        params.push(`createdBy=${createdBy}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${API_URL}/interactive-videos?${params.join('&')}`;
};

export const urlAddInteractions = (id) => `${API_URL}/interactive-videos/${id}/interactions`;
export const urlUpdateInteraction = (interactiveVideoId, interactionId) => `${API_URL}/interactive-videos/${interactiveVideoId}/interactions/${interactionId}`;
export const urlUpdateInteractiveVideo = (interactiveVideoId) => `${API_URL}/interactive-videos/${interactiveVideoId}`;
