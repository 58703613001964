import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DataTable from '../../../../../components/DataTable';
import SkeletonLoader from '../../../../../components/SkeletonLoader/Table';
import './index.css';

const SlotDataTable = (props) => {
    const [index, setIndex] = React.useState([]);

    const handleDelete = (ind) => {
        const indexNumber = index.indexOf(ind);
        const array = [...index];

        if (indexNumber !== -1) {
            array.splice(indexNumber, 1);
            setIndex([...array]);
        }
    };

    const options = {
        serverSide: false,
        pagination: false,
        selectableRows: true,
        rowsSelected: index,
        print: false,
        download: false,
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <SkeletonLoader columns={7}/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Sort for ${column.label}`,
            },
            toolbar: {
                search: 'Search',
                downloadCsv: 'Download CSV',
                print: 'Print',
                viewColumns: 'View Columns',
                filterTable: 'Filter Table',
            },
            filter: {
                all: 'All',
                title: 'FILTERS',
                reset: 'RESET',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onCellClick: (colData, cellMeta) => {
            if (cellMeta.colIndex === 4) {
                handleDelete(cellMeta.rowIndex);
            }

            if (cellMeta.colIndex === 4 || props.data.length === 0) {
                return;
            }

            const ind = [...index];
            const alreadyExit = ind.filter((value) => value === cellMeta.rowIndex);
            if (alreadyExit.length === 0) {
                ind.push(cellMeta.rowIndex);
                setIndex([...ind]);
            } else {
                ind.map((value, index1) => {
                    if (value === cellMeta.rowIndex) {
                        ind.splice(index1, 1);
                    }
                });
                setIndex([...ind]);
            }
        },
        onRowsDelete: (rowsDeleted) => {
            const received = props.handleMultiDelete(rowsDeleted);
            if (received) {
                setIndex([]);
            }
        },
    };

    const columns = [{
        name: 'assetName',
        label: 'Asset Name',
        options: {
            filter: false,
            sort: false,
        },
    }, {
        name: 'startTime',
        label: 'Start Time',
        options: {
            filter: true,
            sort: false,
        },
    }, {
        name: 'endTime',
        label: 'End Time',
        options: {
            filter: false,
            sort: false,
        },
    }, {
        name: 'duration',
        label: 'Duration',
        options: {
            filter: true,
            sort: false,
            display: true,
        },
    }, {
        name: 'Action',
        label: 'Action',
        options: {
            filter: false,
            sort: false,
        },
    }];

    const tableData = props.data && props.data.length
        ? props.data.map((item, index) =>
            [
                <div key={index} className="filename" title={item[0].props.title}>
                    {item[0].props.title}
                </div>,
                item[1].props.children,
                item[2].props.children,
                item[3].props.children[0] + item[3].props.children[1], item[5]])
        : [];

    return (
        <>
            {props.deleteInProgress || props.programsInProgress
                ? <CircularProgress className="loader_css" size="100%"/>
                : <DataTable
                    columns={columns}
                    data={tableData}
                    name=""
                    options={options}/>}
        </>
    );
};

SlotDataTable.propTypes = {
    data: PropTypes.array.isRequired,
    deleteInProgress: PropTypes.bool.isRequired,
    handleMultiDelete: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    programsInProgress: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        deleteInProgress: state.live.deleteProgram.inProgress,
        programsInProgress: state.live.program.inProgress,
    };
};

export default connect(stateToProps)(SlotDataTable);
