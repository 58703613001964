import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAddUrl } from '../../../actions/asset';
import TextField from '../../../components/TextField';

class UrlTextField extends React.Component {
    constructor (props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange (value) {
        if (this.props.label === 'twitter') {
            if (value) {
                const check = value.split('twitter.com');
                if (check.length > 1) {
                    const statusCheck = check[1].split('/');
                    if (statusCheck[2] === 'status') {
                        const val = value.split('/');
                        if (value) {
                            if (val[2] !== '') {
                                if (val && val[5] && val.length > 1) {
                                    if (val[5].split('?') && val[5].split('?').length > 1) {
                                        this.props.onChange(val[5].split('?')[0]);
                                    } else {
                                        this.props.onChange(val[5]);
                                    }
                                } else {
                                    this.props.onChange(value);
                                }
                            }
                        } else {
                            this.props.onChange(value);
                        }
                    }
                }
            }
        } else if (value) {
            const val = value.split('v=');
            if (val.length > 1) {
                const valSplit = val[1].split('&');
                this.props.onChange(valSplit[0]);
            } else {
                this.props.onChange(value);
            }
        }
    }

    render () {
        return (
            <TextField
                id="url_text_field"
                label={'Add ' + this.props.label + ' ID'}
                name="add_url"
                required={true}
                type="text"
                value={this.props.value}
                onChange={this.onChange}/>
        );
    }
}

UrlTextField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.sourceUrl,
    };
};

const actionsToProps = {
    onChange: setAddUrl,
};

export default connect(stateToProps, actionsToProps)(UrlTextField);
