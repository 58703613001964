import { InputAdornment } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setDescription } from '../../../actions/chapterMarking';
import CopyButtonIcon from '../../../components/CopyButton/CopyButtonIcon';
import TextField from '../../../components/TextField/InputProps';

class DescriptionTextField extends Component {
    componentDidMount () {
        if (this.props.defaultValue) {
            this.props.onChange(this.props.defaultValue);
        }
    }

    render () {
        return (
            <TextField
                className="description_text_field"
                id="description_text_field"
                inputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <CopyButtonIcon data={this.props.value}/>
                        </InputAdornment>
                    ),
                }}
                label={this.props.label ? this.props.label : null}
                multiline={true}
                name="text"
                value={this.props.value}
                variant="outlined"
                onChange={this.props.onChange}/>
        );
    }
}

DescriptionTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        value: state.chapterMarking.new.description,
    };
};

const actionsToProps = {
    onChange: setDescription,
};

export default connect(stateToProps, actionsToProps)(DescriptionTextField);
