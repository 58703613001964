import React from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import './index.css';

const LinearProgressWithLabel = (props) => {
    return (
        <Box alignItems="center" className="progress_with_label" display="flex">
            <Box mr={1} width="100%">
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography className="text" color="textSecondary" variant="body2">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;
