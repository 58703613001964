import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setRecordLabel } from '../../../../../actions/asset';
import TextField from '../../../../../components/TextField';

const RecordLabelTextField = (props) => {
    return (
        <TextField
            id="record_label_text_field"
            label="Record Label"
            name="recordLabel"
            value={props.value}
            onChange={props.onChange}/>
    );
};

RecordLabelTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.music.recordLabel,
    };
};

const actionToProps = {
    onChange: setRecordLabel,
};

export default connect(stateToProps, actionToProps)(RecordLabelTextField);
