import Axios from 'axios';
import {
    ACCOUNT_ADD_ERROR,
    ACCOUNT_ADD_IN_PROGRESS,
    ACCOUNT_ADD_SUCCESS,
    ACCOUNT_ADDRESS_SET,
    ACCOUNT_AUTHENTICATION_ERROR,
    ACCOUNT_AUTHENTICATION_IN_PROGRESS,
    ACCOUNT_AUTHENTICATION_SUCCESS,
    ACCOUNT_BALANCE_FETCH_ERROR,
    ACCOUNT_BALANCE_FETCH_IN_PROGRESS,
    ACCOUNT_BALANCE_FETCH_SUCCESS,
    ACCOUNT_SUCCESS_DIALOG_HIDE,
    ACCOUNT_SUCCESS_DIALOG_SHOW,
    CONNECT_ACCOUNT_DIALOG_HIDE,
    CONNECT_ACCOUNT_DIALOG_SHOW,
    NETWORK_SET,
    ACCOUNT_DELETE_ERROR,
    ACCOUNT_DELETE_IN_PROGRESS,
    ACCOUNT_DELETE_SUCCESS,
    ACCOUNT_UPDATE_ERROR,
    ACCOUNT_UPDATE_IN_PROGRESS,
    ACCOUNT_UPDATE_SUCCESS,
    ACCOUNTS_FETCH_ERROR,
    ACCOUNTS_FETCH_IN_PROGRESS,
    ACCOUNTS_FETCH_SUCCESS,
    NFT_FETCH_ERROR,
    NFT_FETCH_IN_PROGRESS,
    NFT_FETCH_SUCCESS,
    NFT_MINT_ERROR,
    NFT_MINT_IN_PROGRESS,
    NFT_MINT_SUCCESS,
    TOKENS_FETCH_ERROR,
    TOKENS_FETCH_IN_PROGRESS,
    TOKENS_FETCH_SUCCESS,
    SEND_TOKENS_DIALOG_SHOW,
    SEND_TOKENS_DIALOG_HIDE,
    TO_ADDRESS_SET,
    TOKENS_TO_SEND_SET,
} from '../constants/account';
import {
    ACCOUNTS_URL,
    accountURL,
    authenticateAccountURL,
    FAUCET_URL,
    urlFetchBalance,
    urlFetchNFT,
    urlMintNFT,
} from '../constants/url';

const fetchAccountBalanceInProgress = () => {
    return {
        type: ACCOUNT_BALANCE_FETCH_IN_PROGRESS,
    };
};

const fetchAccountBalanceSuccess = (value) => {
    return {
        type: ACCOUNT_BALANCE_FETCH_SUCCESS,
        value,
    };
};

const fetchAccountBalanceError = (message) => {
    return {
        type: ACCOUNT_BALANCE_FETCH_ERROR,
        message,
    };
};

export const fetchAccountBalance = (address) => (dispatch) => {
    dispatch(fetchAccountBalanceInProgress());

    const url = urlFetchBalance(address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchAccountBalanceSuccess(res.data.result));
        })
        .catch((error) => {
            dispatch(fetchAccountBalanceError(
                error.response
                    ? error.response.data.message
                    : error.message,
            ));
        });
};

const fetchTokensInProgress = () => {
    return {
        type: TOKENS_FETCH_IN_PROGRESS,
    };
};

const fetchTokensSuccess = (value) => {
    return {
        type: TOKENS_FETCH_SUCCESS,
        value,
    };
};

const fetchTokensError = (message) => {
    return {
        type: TOKENS_FETCH_ERROR,
        message,
    };
};

export const fetchTokens = (address) => (dispatch) => {
    dispatch(fetchTokensInProgress());

    Axios.post(FAUCET_URL, { address }, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchTokensSuccess(res.data.result));
        })
        .catch((error) => {
            dispatch(fetchTokensError(
                error.response
                    ? error.response.data.message
                    : error.message,
            ));
        });
};

export const setAccountAddress = (value) => {
    return {
        type: ACCOUNT_ADDRESS_SET,
        value,
    };
};

const mintNFTInProgress = () => {
    return {
        type: NFT_MINT_IN_PROGRESS,
    };
};

const mintNFTSuccess = (value) => {
    return {
        type: NFT_MINT_SUCCESS,
        value,
    };
};

const mintNFTError = (message) => {
    return {
        type: NFT_MINT_ERROR,
        message,
    };
};

export const mintNFT = (id, data, cb) => (dispatch) => {
    dispatch(mintNFTInProgress());
    const url = urlMintNFT(id);

    Axios.post(url, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(mintNFTSuccess(res.data.result));
            cb(null, res.data.result.tx);
        })
        .catch((error) => {
            dispatch(mintNFTError(
                error.response
                    ? error.response.data.message
                    : error.message,
            ));
            cb(error);
        });
};

const fetchNFTInProgress = () => {
    return {
        type: NFT_FETCH_IN_PROGRESS,
    };
};

const fetchNFTSuccess = (value) => {
    return {
        type: NFT_FETCH_SUCCESS,
        value,
    };
};

const fetchNFTError = (message) => {
    return {
        type: NFT_FETCH_ERROR,
        message,
    };
};

export const fetchNFT = (address) => (dispatch) => {
    dispatch(fetchNFTInProgress());
    const url = urlFetchNFT(address);

    Axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchNFTSuccess(res.data.result));
        })
        .catch((error) => {
            dispatch(fetchNFTError(
                error.response
                    ? error.response.data.message
                    : error.message,
            ));
        });
};

export const showConnectDialog = () => {
    return {
        type: CONNECT_ACCOUNT_DIALOG_SHOW,
    };
};

export const hideConnectDialog = () => {
    return {
        type: CONNECT_ACCOUNT_DIALOG_HIDE,
    };
};

export const showAccountSuccessDialog = () => {
    return {
        type: ACCOUNT_SUCCESS_DIALOG_SHOW,
    };
};

export const hideAccountSuccessDialog = () => {
    return {
        type: ACCOUNT_SUCCESS_DIALOG_HIDE,
    };
};

export const showSendTokensDialog = () => {
    return {
        type: SEND_TOKENS_DIALOG_SHOW,
    };
};

export const hideSendTokensDialog = () => {
    return {
        type: SEND_TOKENS_DIALOG_HIDE,
    };
};

export const setNetwork = (value) => {
    return {
        type: NETWORK_SET,
        value,
    };
};

const fetchAccountsInProgress = () => {
    return {
        type: ACCOUNTS_FETCH_IN_PROGRESS,
    };
};

const fetchAccountsSuccess = (items) => {
    const data = {};
    items.forEach((item) => {
        data[item._id] = item;
    });

    return {
        type: ACCOUNTS_FETCH_SUCCESS,
        items: data,
    };
};

const fetchAccountsError = (message) => {
    return {
        type: ACCOUNTS_FETCH_ERROR,
        message,
    };
};

export const fetchAccounts = () => (dispatch) => {
    dispatch(fetchAccountsInProgress());

    Axios.get(ACCOUNTS_URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchAccountsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchAccountsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const addAccountInProgress = () => {
    return {
        type: ACCOUNT_ADD_IN_PROGRESS,
    };
};

const addAccountSuccess = (item) => {
    return {
        type: ACCOUNT_ADD_SUCCESS,
        item,
        message: 'Account added',
    };
};

const addAccountError = (message) => {
    return {
        type: ACCOUNT_ADD_ERROR,
        message,
    };
};

export const addAccount = (data, cb) => (dispatch) => {
    dispatch(addAccountInProgress());

    Axios.post(ACCOUNTS_URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(addAccountSuccess(res.data.result));
            cb(null, res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(addAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const authenticateAccountInProgress = () => {
    return {
        type: ACCOUNT_AUTHENTICATION_IN_PROGRESS,
    };
};

const authenticateAccountSuccess = (item) => {
    return {
        type: ACCOUNT_AUTHENTICATION_SUCCESS,
        item,
    };
};

const authenticateAccountError = (message) => {
    return {
        type: ACCOUNT_AUTHENTICATION_ERROR,
        message,
    };
};

export const authenticateAccount = (id, data, cb) => (dispatch) => {
    dispatch(authenticateAccountInProgress());

    const url = authenticateAccountURL(id);

    Axios.post(url, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(authenticateAccountSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(authenticateAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

const updateAccountInProgress = () => {
    return {
        type: ACCOUNT_UPDATE_IN_PROGRESS,
    };
};

const updateAccountSuccess = (item, message) => {
    return {
        type: ACCOUNT_UPDATE_SUCCESS,
        item,
        message,
    };
};

const updateAccountError = (message) => {
    return {
        type: ACCOUNT_UPDATE_ERROR,
        message,
    };
};

export const updateAccount = (id, data) => (dispatch) => {
    dispatch(updateAccountInProgress());

    const url = accountURL(id);

    Axios.put(url, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(updateAccountSuccess(res.data.account, 'Success'));
        })
        .catch((error) => {
            dispatch(updateAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const deleteAccountInProgress = () => {
    return {
        type: ACCOUNT_DELETE_IN_PROGRESS,
    };
};

const deleteAccountSuccess = (message) => {
    return {
        type: ACCOUNT_DELETE_SUCCESS,
        message,
    };
};

const deleteAccountError = (message) => {
    return {
        type: ACCOUNT_DELETE_ERROR,
        message,
    };
};

export const deleteAccount = (ID, cb) => (dispatch) => {
    dispatch(deleteAccountInProgress());

    const URL = accountURL(ID);
    Axios.delete(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then(() => {
            dispatch(deleteAccountSuccess('Success'));
            cb(null);
        })
        .catch((error) => {
            dispatch(deleteAccountError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};

export const setToAddress = (value) => {
    return {
        type: TO_ADDRESS_SET,
        value,
    };
};

export const setTokensToSend = (value) => {
    return {
        type: TOKENS_TO_SEND_SET,
        value,
    };
};
