import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideUpdateProgramDialog } from '../../../../../actions/program';
import DateOfUploadTextField from '../../../../asset/DialogAddAsset/DateOfUploadTextField';
import DurationTextField from '../../../../asset/DialogAddAsset/DurationTextField';
import EncodedStateTextField from '../../../../asset/DialogAddAsset/EncodedStateTextField';
import FileTypeTextField from '../../../../asset/DialogAddAsset/FileTypeTextField';
import GenreSelectField from '../../../../asset/DialogAddAsset/GenreSelectField';
import NameTextField from '../../../../asset/DialogAddAsset/NameTextField';
import SourceTypeSelectField from '../../../../asset/DialogAddAsset/SourceTypeSelectField';
import './index.css';

class DialogUpdateProgram extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            updated: true,
        };
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleUpdate () {
        this.setState({
            updated: !this.state.updated,
        });
    }

    render () {
        return (
            <Dialog
                className="dialog_update_program"
                id="mouse-over-popover"
                open={this.props.open}
                onClose={this.props.onClickCancel}
            >
                <div className="update_selected_program">
                    <div className="header_section">
                        <p> Asset Info</p>
                        {
                            this.state.updated
                                ? <div>
                                    <Button onClick={() => this.handleUpdate()}>Edit</Button>
                                    <Button> Delete </Button>
                                </div>
                                : <Button onClick={() => this.handleUpdate()}> Update </Button>
                        }
                    </div>
                    <div className="content_section">
                        <div>
                            <p> Asset Name </p>
                            {
                                this.state.updated
                                    ? <p> XXXXXX </p>
                                    : <NameTextField label="Asset Name"/>
                            }
                        </div>
                        <div>
                            <p> Asset Genre</p>
                            {
                                this.state.updated
                                    ? <p> XXXXXX </p>
                                    : <GenreSelectField/>
                            }
                        </div>
                        <div>
                            <p> File Name</p>
                            {
                                this.state.updated
                                    ? <p> XXXXXX </p>
                                    : <NameTextField label="File Name"/>
                            }
                        </div>
                        <div>
                            <p> File Type</p>
                            {
                                this.state.updated
                                    ? <p> XXXXXX </p>
                                    : <FileTypeTextField/>
                            }
                        </div>
                        <div>
                            <p> Video Resolution</p>
                            {
                                this.state.updated
                                    ? <p> XXXXXX </p>
                                    : <NameTextField label="Video Resolution"/>
                            }
                        </div>
                        <div>
                            <p> Source</p>
                            {
                                this.state.updated
                                    ? <p> XXXXXX </p>
                                    : <SourceTypeSelectField/>
                            }
                        </div>
                        <div>
                            <p>Duration</p>
                            {
                                this.state.updated
                                    ? <p> XXXXXX </p>
                                    : <DurationTextField/>
                            }
                        </div>
                        <div>
                            <p>Date of Upload</p>
                            {
                                this.state.updated
                                    ? <p> XXXXXX </p>
                                    : <DateOfUploadTextField/>
                            }
                        </div>
                        <div>
                            <p>Encoded State</p>
                            {
                                this.state.updated
                                    ? <p> XXXXXX </p>
                                    : <EncodedStateTextField/>
                            }
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

DialogUpdateProgram.propTypes = {
    open: PropTypes.bool.isRequired,
    onClickCancel: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.live.updateProgram,
        // newLabel: state.asset.newGenre,
        // genres: state.asset.new.categories.list,
    };
};

const actionsToProps = {
    onClickCancel: hideUpdateProgramDialog,
};

export default connect(stateToProps, actionsToProps)(DialogUpdateProgram);
