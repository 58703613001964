import { AppBar, Button, CircularProgress, Modal, Tab, Tabs, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchAssetsData, fetchAssetsOverviewData, setCurrentAsset } from '../../../../actions/asset';
import { fetchBugs } from '../../../../actions/bugs';
import { fetchSlotTemplate } from '../../../../actions/dynamicPrograms';
import {
    addProgram,
    addProgramOverlay,
    deleteProgram,
    deleteProgramOverlay,
    fetchPrograms,
    setUpdatedPrograms,
    updateProgram,
} from '../../../../actions/program';
import {
    addSlot,
    clearDynamicInputValues,
    deleteSlot,
    fetchSlots,
    setCurrentSlot,
    setSlotPopupShow,
    showSlotSettingsDialog,
} from '../../../../actions/slot';
import downIcon from '../../../../assets/down-arrow.svg';
import rightIcon from '../../../../assets/side-arrow.svg';
import CustomIcon from '../../../../components/Icon';
import SkeletonLoader from '../../../../components/SkeletonLoader/Slots';
import {
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../../../../constants/url';
import DynamicSlot from '../DynamicSlotModal';
import ClearSlotButton from './ClearSlotButton';
import './index.css';
import ProgramDataTable from './ProgramDataTable';
import SlotDataTable from './SlotDataTable';
import Slots from './Slots';

const styles = (theme) => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
});

const BUG_INTERVAL = 5;

const arrayMove = (items, oldIndex, newIndex) => {
    if (newIndex === oldIndex) {
        return items;
    }
    const target = items[oldIndex];
    const increment = newIndex < oldIndex ? -1 : 1;

    for (let element = oldIndex; element !== newIndex; element += increment) {
        items[element] = items[element + increment];
    }
    items[newIndex] = target;
    return items;
};

class Stream extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            open: false,
            value: 0,
            streamSectionWidth: '',
            selectedContent: [],
            checked: {},
            slotPopup: false,
            selectedList: [],
        };
        this.onSortEnd = this.onSortEnd.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeWindowWidth = this.handleChangeWindowWidth.bind(this);
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
        this.renderCategories = this.renderCategories.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.updateSelectedContent = this.updateSelectedContent.bind(this);
        this.renderSlots = this.renderSlots.bind(this);
        this.getPrograms = this.getPrograms.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getSlots = this.getSlots.bind(this);
        this.addDefaultSlots = this.addDefaultSlots.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
        this.handleAddAds = this.handleAddAds.bind(this);
        this.handleAddBugs = this.handleAddBugs.bind(this);
        this.handleDeleteOverlay = this.handleDeleteOverlay.bind(this);
        this.close = this.close.bind(this);
        this.handleOpenProgramPopup = this.handleOpenProgramPopup.bind(this);
        this.handleOpenSlotPopup = this.handleOpenSlotPopup.bind(this);
        this.handleCloseSlotPopUp = this.handleCloseSlotPopUp.bind(this);
        this.renderSlotData = this.renderSlotData.bind(this);
        this.handleMultiDelete = this.handleMultiDelete.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleExpansion = this.handleExpansion.bind(this);
        this.handleSlotSettings = this.handleSlotSettings.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleAddContent = this.handleAddContent.bind(this);
    }

    handleDelete (value) {
        this.props.deleteProgram(this.props.channel,
            this.props.stream,
            this.props.currentSlot._._id,
            value._id, 1, (cb) => {
                if (cb) {
                    return;
                }
                const selected = this.state.selectedContent.filter((a) => a._id !== value._id);
                this.updateSelectedContent(selected);

                const updatedContent = Object.values(this.props.programs)
                    .filter((v) => (v.slot && v.slot._id) !== (value.slot && value.slot._id));

                this.props.setUpdatedPrograms(updatedContent);
                this.props.fetchPrograms(this.props.channel,
                    this.props.stream,
                    this.props.currentSlot._._id, () => {
                    });
            });
    }

    handleMultiDelete (rowsDeleted) {
        if (rowsDeleted.data && rowsDeleted.data.length) {
            this.deleteRow(rowsDeleted.data, rowsDeleted.data[0], 0);
        }

        return true;
    }

    deleteRow (rowsDeleted, value, index) {
        this.props.deleteProgram(this.props.channel,
            this.props.stream,
            this.props.currentSlot._._id,
            this.state.selectedContent[value.index]._id,
            index + 1, (error) => {
                if (!error) {
                    if (rowsDeleted[index + 1]) {
                        this.deleteRow(rowsDeleted, rowsDeleted[index + 1], index + 1);
                    }

                    if (index === rowsDeleted.length - 1) {
                        this.setState({
                            selectedContent: Object.values(this.props.programs).filter((v) => v.slot._id === this.props.currentSlot._._id),
                        });
                        this.props.setCurrentSlot(this.props.currentSlot._,
                            Object.values(this.props.programs).filter((v) => v.slot._id === this.props.currentSlot._._id));

                        const updatedContent = Object.values(this.props.programs)
                            .filter((v) => (v.slot && v.slot._id) !== (this.props.currentSlot && this.props.currentSlot._._id));
                        this.props.setUpdatedPrograms(updatedContent);
                        this.props.fetchPrograms(this.props.channel,
                            this.props.stream,
                            this.props.currentSlot._._id, (cb) => {
                            });
                    }
                }
            });
    }

    componentDidMount () {
        this.getSlots();
        this.handleChangeWindowWidth();
        const token = localStorage.getItem('authorizationToken');

        if (token) {
            this.props.fetchAssetsOverviewData(token, 'complete');
        }

        window.addEventListener('resize', this.handleChangeWindowWidth);
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevProps.selectedDate !== this.props.selectedDate ||
            prevProps.stream !== this.props.stream) {
            this.getSlots();
        }

        if (prevProps.programs !== this.props.programs && (this.props.currentSlot && this.props.currentSlot._)) {
            this.setState({
                selectedContent: Object.values(this.props.programs).filter((v) => v.slot._id === this.props.currentSlot._._id),
            });
            this.props.setCurrentSlot(this.props.currentSlot._,
                Object.values(this.props.programs).filter((v) => v.slot._id === this.props.currentSlot._._id));
        }
    }

    getSlots () {
        const timeZone = this.props.configuration['stream_timezone'];
        let slotLength = this.props.configuration['slot_length'];
        slotLength = slotLength / 3600;
        let slotsCount = 0;
        const date = this.props.selectedDate;
        // const dateWithOutTimeZone = date.toDateString();

        for (let i = 0; i < 24; i = i + slotLength) {
            slotsCount = slotsCount + 1;
        }

        const start = momentTimeZone.tz(date, timeZone).startOf('day');
        this.props.fetchSlots(this.props.channel,
            this.props.stream,
            start.clone().add(0, 'hours').utc().valueOf(),
            start.clone().add(24, 'hours').utc().valueOf() + 1,
            'start_at',
            1,
            (cb) => {
                if (cb) {
                    return;
                }

                if (this.props.slots &&
                    Object.keys(this.props.slots).length !== slotsCount) {
                    this.addDefaultSlots();
                } else {
                    this.getPrograms();
                }
            });
    }

    addDefaultSlots () {
        const timeZone = this.props.configuration['stream_timezone'];
        let slotLength = this.props.configuration['slot_length'];
        slotLength = slotLength / 3600;

        const defaultSlots = [];
        let slotsCount = 0;
        const date = this.props.selectedDate;
        // const dateWithOutTimeZone = date.toDateString();
        const start = momentTimeZone.tz(date, timeZone).startOf('day');
        for (let i = 0; i < 24; i = i + slotLength) {
            slotsCount = slotsCount + 1;
            // const startDate = momentTimeZone.tz(dateWithOutTimeZone, timeZone).hour(i).minute(0).second(0).millisecond(0).toISOString();
            // const endDate = momentTimeZone.tz(dateWithOutTimeZone, timeZone).hour(i + slotLength).minute(0).second(0).millisecond(0).toISOString();
            // Start time in UTC
            const startDate = start.clone().add(i, 'hours').utc().toISOString();
            // End time in UTC (2 hours after the start)
            const endDate = start.clone().add(i + slotLength, 'hours').utc().toISOString();

            defaultSlots.push({
                name: 'Slot' + slotsCount,
                startAt: startDate,
                endAt: endDate,
            });
        }

        // if (this.props.slots &&
        //     Object.keys(this.props.slots).length > 0) {
        //     Object.keys(this.props.slots).map((key) => {
        //         const id = this.props.slots[key] && this.props.slots[key]._id;
        //         this.props.deleteSlot(this.props.channel, this.props.stream, id, (cb) => {});
        //     });
        // }

        defaultSlots.map((v, index) => {
            this.props.addSlot(this.props.channel,
                this.props.stream,
                v,
                (cb) => {
                    if (cb) {
                        return;
                    }
                    if (index === defaultSlots.length - 1) {
                        this.getSlots();
                    }
                });

            return null;
        });
    }

    getPrograms () {
        this.props.slots &&
        Object.keys(this.props.slots).length &&
        Object.values(this.props.slots).map((slot, index) => (
            this.props.fetchPrograms(slot.channel,
                slot['live_stream'],
                slot._id, (cb) => {
                })
        ));
    }

    handleChangeWindowWidth () {
        this.setState({
            streamSectionWidth: this.a ? this.a.offsetWidth : 800,
        });
    }

    handleOpen (slot) {
        this.setState({
            open: true,
            selectedContent: Object.values(this.props.programs).filter((v) => v.slot._id === slot._id),
        });
        this.props.setCurrentSlot(slot,
            Object.values(this.props.programs).filter((v) => v.slot._id === slot._id));
    }

    close () {
        this.setState({
            open: false,
            show: '',
            checked: {},
            selectedList: [],
        });
    }

    handleCloseSlotPopUp () {
        this.props.setSlotPopupShow(false);
        this.props.clearDynamicInputValues();
    }

    handleClose () {
        if (this.state.value) {
            this.setState({
                value: 0,
                checked: {},
                selectedContent: Object.values(this.props.programs).filter((v) =>
                    v.slot._id === this.props.currentSlot._._id),
            });
        } else {
            this.setState({
                open: false,
                show: '',
                checked: {},
                selectedList: [],
            });
        }
    }

    handleAddContent () {
        if (this.state.show === 'bugs') {
            const selectedOverlay = this.state.selectedContent.filter((v) => v.category &&
                v.category.name === 'bugs')[0];

            const data = {
                startAt: this.state[selectedOverlay._id]
                    ? this.state[selectedOverlay._id]
                    : selectedOverlay.start_at,
                endAt: selectedOverlay.end_at,
                asset: selectedOverlay._id,
            };

            return this.props.addProgramOverlay(this.props.channel,
                this.props.stream,
                this.props.currentSlot._._id,
                this.props.currentSlot.currentProgram._id,
                data, (cb) => {
                    this.handleClose();
                });
        } else if (this.state.show === 'adx') {
            const selectedAd = this.state.selectedContent.filter((v) => v.category &&
                v.category.name === 'adx')[0];
            const adStartTime = this.state[selectedAd._id]
                ? this.state[selectedAd._id]
                : this.state.addTime
                    ? this.state.addTime
                    : selectedAd.start_at;

            const updateData = {
                startAt: this.props.currentSlot.currentProgram['start_at'],
                endAt: adStartTime,
                asset: {
                    id: this.props.currentSlot.currentProgram.asset._._id,
                    startAt: 0,
                    endAt: moment(adStartTime).diff(moment(this.props.currentSlot.currentProgram.start_at)),
                },
            };
            return this.props.updateProgram(this.props.channel,
                this.props.stream,
                this.props.currentSlot._._id,
                this.props.currentSlot.currentProgram._id,
                updateData, (cb) => {
                    if (cb) {
                        return;
                    }
                    // data of ad to be added;
                    const adData = {
                        startAt: moment(adStartTime).utc().format(),
                        endAt: moment(adStartTime).add(selectedAd.file.length, 's').utc().format(),
                        asset: {
                            id: selectedAd._id,
                            startAt: 0,
                            endAt: selectedAd.file.length,
                        },
                    };
                    this.props.addProgram(this.props.channel,
                        this.props.stream,
                        this.props.currentSlot._._id,
                        adData, (cb) => {
                            if (cb) {
                                return;
                            }
                            // data of new program to be add;
                            const programData = {
                                startAt: selectedAd.end_at,
                                endAt: moment(selectedAd.end_at).add(selectedAd.file.length, 's').utc().format(),
                                asset: {
                                    id: this.props.currentSlot.currentProgram.asset._._id,
                                    startAt: moment(selectedAd.start_at)
                                        .diff(moment(this.props.currentSlot.currentProgram.start_at)),
                                    endAt: this.props.currentSlot.currentProgram.asset._.file.length,
                                },
                            };
                            this.props.addProgram(this.props.channel,
                                this.props.stream,
                                this.props.currentSlot._._id,
                                programData, (cb) => {
                                    this.handleClose();
                                });
                        });
                });
        } else if (this.state.show !== 'adx' &&
            this.state.show !== 'bugs') {
            const promises = this.state.selectedContent.map(async (v, index) => {
                if (v.slot &&
                    v.slot._id
                ) {
                    if (this.state[v._id]) {
                        const data = {
                            startAt: this.state[v._id],
                            endAt: moment(this.state[v._id]).add(v.asset._.file.length, 's').utc().format(),
                            asset: {
                                id: v._id,
                                startAt: 0,
                                endAt: v.asset._.file.length,
                            },
                            // take file length in amount
                        };
                        return this.props.updateProgram(this.props.channel,
                            this.props.stream,
                            this.props.currentSlot._._id,
                            v._id,
                            data, () => {
                            });
                    } else if (index === this.state.selectedContent.length - 1) {
                        this.handleClose();
                    }
                } else {
                    const data = {
                        startAt: this.state[v._id]
                            ? this.state[v._id]
                            : v.start_at,
                        endAt: v.end_at,
                        asset: {
                            id: v._id,
                            startAt: 0,
                            endAt: v.file.length,
                        },
                    };
                    return this.props.addProgram(this.props.channel,
                        this.props.stream,
                        this.props.currentSlot._._id,
                        data, () => {
                        });
                }

                return null;
            });
            Promise.all(promises)
                .then((res) => {
                    this.setState({
                        selectedContent: [],
                        selectedList: [],
                    });

                    this.props.fetchPrograms(this.props.channel,
                        this.props.stream,
                        this.props.currentSlot._._id, () => {
                        });

                    this.handleClose();
                })
                .catch(() => {
                });
        }
    }

    handleChange (event, value) {
        this.setState({ value });
    }

    onSortEnd ({ oldIndex, newIndex }, name) {
        if (name === 'selected') {
            this.setState({
                selectedContent: arrayMove(this.state.selectedContent, oldIndex, newIndex),
            });
        } else {
            this.setState({
                items: arrayMove(this.props.assetData, oldIndex, newIndex),
            });
        }
    }

    modalContent (value) {
        this.state.selectedContent && this.state.selectedContent.length &&
        this.state.selectedContent.sort((left, right) => {
            return moment.utc(left.start_at).diff(moment.utc(right.start_at));
        });

        return (
            <div className="content">
                {value === 0 && (
                    <div>
                        <div className="section_header">
                            <p
                                className="sarabun_font_weight"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                Available List
                                <div
                                    className="nodata_dynamic_slots">
                                    <Button
                                        style={{ marginLeft: '20px' }}
                                        onClick={() =>
                                            this.handleOpenProgramPopup()}>
                                        Add Dynamic Program
                                    </Button>
                                </div>
                            </p>
                            <div className="header_right">
                                {
                                    this.props.currentSlot &&
                                    this.props.currentSlot._ &&
                                    this.props.currentSlot._._id
                                        ? this.props.checked
                                            ? <p className="sarabun_font_weight slot_text">
                                                {`Slot Time:
                                            ${moment(this.props.currentSlot._.start_at).local().format('hh:mm A ')}
                                            -
                                            ${moment(this.props.currentSlot._.end_at).local().format(' hh:mm A')} `}
                                            </p>
                                            : <p className="sarabun_font_weight slot_text">
                                                {`Slot Time:
                                            ${moment(this.props.currentSlot._.start_at).local().format('HH:mm ')}
                                            -
                                            ${moment(this.props.currentSlot._.end_at).local().format(' HH:mm') + ' Hrs'} `}
                                            </p>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="data_table table_modal slot_selected_data table1">
                            <SlotDataTable
                                data={
                                    this.state.selectedContent &&
                                    this.state.selectedContent.length
                                        ? this.state.selectedContent.map((value, index) => (
                                            this.renderSlotData(value, value.type, index)
                                        ))
                                        : []
                                }
                                handleMultiDelete={this.handleMultiDelete}
                                inProgress={this.props.assetsInProgress}/>
                        </div>
                    </div>
                )}
                {value !== 0 && (
                    <div className="add_program_section">
                        <div className="section_header">
                            <p
                                className="sarabun_font_weight"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                Available List
                                <div
                                    className="nodata_dynamic_slots">
                                    <Button
                                        style={{ marginLeft: '20px' }}
                                        onClick={() => this.handleOpenSlotPopup()}> Add Dynamic Program </Button>
                                </div>
                            </p>
                        </div>
                        <div
                            className="data_table table_modal table_scroll_bar table1"
                            onScroll={this.handleScroll}>
                            <ProgramDataTable
                                data={this.props.assetData.length
                                    ? this.props.assetData.map((value, index) => (
                                        this.renderCategories(value, value.type, index)
                                    ))
                                    : []}
                                inProgress={this.props.assetDataInProgress}
                                parentProps={this.props}
                                selectedList={this.state.selectedList}
                                onRowClick={this.handleRowClick}/>
                        </div>
                    </div>
                )}

                <div className="footer sarabun_font_weight">
                    <div>
                        <Button
                            className="close_adding"
                            onClick={() => this.close()}>
                            Close
                        </Button>
                        <Button
                            className="add_stream"
                            onClick={() => this.handleAddContent()}>
                            {value ? 'Add' : 'Update'}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    modalHeader (value, classes) {
        return (
            <div className="header header_tab">
                <div className={classes.root}>
                    <AppBar
                        className="modal_appbar"
                        position="static">
                        <Tabs
                            scrollButtons="auto"
                            value={value}
                            variant="scrollable"
                            onChange={this.handleChange}>
                            <Tab
                                key="slotData-tab"
                                className={
                                    value === 0
                                        ? 'active_tab'
                                        : 'default_tab sarabun_font_weight'
                                }
                                label={`Slot Programming (${Object.keys(this.state.selectedContent).length})`}
                            />
                            {
                                this.props.assetsOverView.length
                                    ? this.props.assetsOverView.map((item, index) => {
                                        if (item.type !== 'video') {
                                            return;
                                        }

                                        return (
                                            <Tab
                                                key={item._id}
                                                className={
                                                    value === (index + 1)
                                                        ? 'active_tab'
                                                        : 'default_tab sarabun_font_weight'}
                                                label={`${item.type} (${item.count
                                                    ? item.count
                                                    : 0})`} onClick={() => this.handleFetch(item.type,
                                                    DEFAULT_SKIP,
                                                    DEFAULT_LIMIT,
                                                    DEFAULT_SEARCH_KEY,
                                                    DEFAULT_SORT_BY,
                                                    DEFAULT_ORDER)}
                                            />
                                        );
                                    })
                                    : <div className="no_data loader_div">No Assets Found</div>
                            }
                        </Tabs>
                    </AppBar>
                </div>
            </div>
        );
    }

    handleChangeCheckBox (event, name, data) {
        let selected = this.state.selectedContent;
        const check = this.state.checked;

        check[name] = !!event.target.checked;

        this.setState({
            checked: check,
        });

        if (event.target.checked) {
            if (selected.length) {
                data.start_at = selected[selected.length - 1].end_at;
                data.end_at = moment(selected[selected.length - 1].end_at).add(data.file.length, 's');
            } else {
                if (this.props.currentSlot) {
                    data.start_at = moment(this.props.currentSlot._['start_at']);
                    data.end_at = moment(this.props.currentSlot._['start_at']).add(data.file.length, 's');
                }
            }
            selected.push(data);
            this.updateSelectedContent(selected);
        } else {
            selected = selected.filter((a) => a._id !== data._id);
            this.updateSelectedContent(selected);
        }
    }

    updateSelectedContent (selected) {
        this.setState({
            selectedContent: selected,
        });
    }

    handleTimeChange (name, event) {
        const data = this.state.selectedContent.filter((v) => v._id === name)[0];
        data.start_at = moment(event).utc().format();
        data.end_at = moment(event).utc().add(data.file &&
        data.file.length
            ? data.file.length
            : data.asset._.file.length, 's')
            .format();
        const updated = this.state.selectedContent.map((v) => {
            if (v._id === name) {
                return data;
            }
            return v;
        });
        this.setState({
            [name]: moment(event).utc().format(),
            selectedContent: updated,
        });
    }

    handleRowClick (index) {
        const data = this.props.assetData[index];
        const name = 'checked' + (data && this.props.assetData[index]._id);
        let selected = this.state.selectedContent;
        const check = this.state.checked;

        check[name] = !check[name];

        this.setState({
            checked: check,
        });

        if (check[name]) {
            if (selected.length) {
                data.start_at = selected[selected.length - 1].end_at;
                data.end_at = moment(selected[selected.length - 1].end_at).add(data.file.length, 's');
            } else {
                if (this.props.currentSlot) {
                    data.start_at = moment(this.props.currentSlot._['start_at']);
                    data.end_at = moment(this.props.currentSlot._['start_at']).add(data.file.length, 's');
                }
            }
            selected.push(data);
            this.updateSelectedContent(selected);
        } else {
            selected = selected.filter((a) => a._id !== data._id);
            this.updateSelectedContent(selected);
        }

        if (check[name]) {
            const array = [...this.state.selectedList, data._id];

            this.setState({
                selectedList: [...array],
            });
        } else {
            const array = [...this.state.selectedList];
            const index = array.indexOf(data._id);

            array.splice(index, 1);

            this.setState({
                selectedList: [...array],
            });
        }
    }

    renderCategories (value, name, index) {
        const sourceName = (name === 'audio') ? 'track_name' : 'name';
        const type = value && value.type;

        return (
            [
                <Checkbox
                    key={index}
                    checked={this.state.checked && this.state.checked['checked' + value._id]}
                    className="check_box"
                    onChange={(e) =>
                        this.handleChangeCheckBox(e, 'checked' + value._id, value)}/>,
                <div
                    key={index}
                    className="video_name sarabun_font_weight"
                    title={value[type] && value[type][sourceName]
                        ? value[type][sourceName]
                        : value.asset &&
                        value.asset &&
                        value.asset._ &&
                        value.asset._.file &&
                        value.asset._.file.name}>
                    {
                        value[type] && value[type][sourceName]
                            ? value[type][sourceName]
                            : value.asset &&
                            value.asset &&
                            value.asset._ &&
                            value.asset._.file &&
                            value.asset._.file.name
                    }
                </div>,
                <p
                    key={index}
                    className="video_duration sarabun_font_weight">{
                        value.file
                            ? moment.utc(moment.duration(value.file.length, 'seconds')
                                .asMilliseconds()).format(this.props.timeFormat)
                            : value.asset &&
                        value.asset._ &&
                        value.asset._.file &&
                        value.asset._.file.length
                                ? moment.utc(moment.duration(value.asset._ && value.asset._.file.length, 'seconds')
                                    .asMilliseconds()).format(this.props.timeFormat)
                                : ''}  </p>,
                value._id,
            ]
        );
    }

    renderSlotData (value, name, index) {
        const sourceName = (name === 'audio') ? 'track_name' : 'name';
        const type = value && value.type;

        return (
            [<div
                key={index}
                className="video_name sarabun_font_weight"
                title={value[type] && value[type][sourceName]
                    ? value[type][sourceName]
                    : value.asset &&
                    value.asset._ &&
                    value.asset._.video &&
                    value.asset._.video.name
                        ? value.asset._.video.name
                        : value.asset &&
                        value.asset &&
                        value.asset._ &&
                        value.asset._.file &&
                        value.asset._.file.name}>
                {
                    value[type] && value[type][sourceName]
                        ? value[type][sourceName]
                        : value.asset &&
                        value.asset._ &&
                        value.asset._.video &&
                        value.asset._.video.name
                            ? value.asset._.video.name
                            : value.asset &&
                        value.asset &&
                        value.asset._ &&
                        value.asset._.file &&
                        value.asset._.file.name
                }
            </div>,
            <>
                {
                    value['start_at']
                        ? this.props.checked
                            ? moment(value['start_at']).format(' hh:mm A')
                            : moment(value['start_at']).format(' HH:mm ') + 'Hrs'
                        : ''
                }
            </>,
            <>
                {
                    value['end_at']
                        ? this.props.checked
                            ? moment(value['end_at']).format(' hh:mm A')
                            : moment(value['end_at']).format(' HH:mm ') + 'Hrs'
                        : ''
                }
            </>,
            <p
                key={index}
                className="video_duration sarabun_font_weight">{
                    value.file
                        ? moment.utc(moment.duration(value.file.length, 'seconds')
                            .asMilliseconds()).format(this.props.timeFormat)
                        : value.asset &&
                        value.asset._ &&
                        value.asset._.file &&
                        value.asset._.file.length
                            ? moment.utc(moment.duration(value.asset._ && value.asset._.file.length, 'seconds')
                                .asMilliseconds()).format(this.props.timeFormat)
                            : ''}  </p>,
            <p
                key={index}
                className="video_speed sarabun_font_weight">
                {(
                    (value.file
                        ? value.file.size
                        : value.asset && value.asset._ && value.asset._.file.size) / 1000000
                ).toFixed(2)} MB</p>,
            <div key={index}>
                <Button
                    className="add_button"
                    variant="contained"
                    onClick={() => this.handleDelete(value, index)}>
                </Button>
            </div>,
            ]
        );
    }

    handleOpenProgramPopup () {
        const token = localStorage.getItem('authorizationToken');

        this.props.fetchSlotTemplate(token);
        this.props.setSlotPopupShow(true);
    }

    handleOpenSlotPopup (slot) {
        const token = localStorage.getItem('authorizationToken');

        this.props.fetchSlotTemplate(token);
        this.props.setSlotPopupShow(true);
        if (slot) {
            this.props.setCurrentSlot(slot);
        }
    }

    renderSlots (slot) {
        const slotPrograms = Object.values(this.props.programs).filter((v) => v.slot._id === slot._id);

        slotPrograms && slotPrograms.length &&
        slotPrograms.sort((left, right) => {
            return moment.utc(left.start_at).diff(moment.utc(right.start_at));
        });

        const programs = slotPrograms.map((program, index, programsList) => {
            if (index === 0) {
                const startMinutes = moment(program['start_at'])
                    .diff(moment(slot['start_at']), 'minutes');
                const duration = moment(program['end_at'])
                    .diff(moment(program['start_at']), 'minutes');
                program.lStart = startMinutes;
                program.rem = duration % BUG_INTERVAL;
                program.count = duration / BUG_INTERVAL;
                program.bugStartTime = moment(program['start_at']).add(startMinutes, 'm').local().format();
            } else {
                program.lStart = BUG_INTERVAL - programsList[index - 1].rem;
                const duration = moment(program['end_at'])
                    .diff(moment(program['start_at']), 'minutes') - program.lStart;
                program.rem = duration % BUG_INTERVAL;
                program.count = duration / BUG_INTERVAL;
                program.bugStartTime = moment(program['start_at']).add(program.lStart, 'm').local().format();
            }
            return program;
        });

        if (this.props.currentUpdatingSlot && this.props.currentUpdatingSlot === slot._id) {
            return <SkeletonLoader/>;
        }
        if (this.props.currentUpdatingSlot && this.props.currentUpdatingSlot === slot._id &&
            (this.props.updateProgramInProgress || this.props.deleteProgramInProgress ||
                this.props.addProgramInProgress)) {
            return <SkeletonLoader/>;
        }
        if ((!this.props.currentUpdatingSlot && this.props.programsInProgress)) {
            return <SkeletonLoader/>;
        } else {
            if (programs.length) {
                return (
                    <Slots
                        channel={this.props.channel}
                        handleAddAds={this.handleAddAds}
                        program={programs}
                        slot={slot}
                        stream={this.props.stream}
                        updateProgram={this.props.updateProgram}
                        onClick={() => this.handleOpen(slot)}/>
                );
            } else {
                return (
                    <>
                        <div className="no_programs"> No Programs Scheduled</div>
                        <div className="no_data nodata_dynamic_slots">
                            {
                                !(moment(slot['end_at']).isBefore(moment(), 'second'))
                                    ? <>
                                        <Button onClick={() => this.handleOpenSlotPopup(slot)}>
                                            Create Dynamic Slot
                                        </Button>
                                        <Button onClick={() => this.handleOpen(slot)}>
                                            Add Program
                                        </Button>
                                    </>
                                    : null
                            }
                        </div>
                    </>
                );
            }
        }
    }

    handleAddAds (program, slot, time) {
        this.setState({
            open: true,
            show: 'adx',
            addTime: time._d,
            selectedContent: Object.values(this.props.programs).filter((v) => v.slot._id === slot._id),
        });

        this.props.setCurrentSlot(slot,
            Object.values(this.props.programs).filter((v) => v.slot._id === slot._id),
            program);
    }

    handleAddBugs (slot, program) {
        this.setState({
            open: true,
            show: 'bugs',
            selectedContent: [program],
        });

        this.props.setCurrentSlot(slot,
            Object.values(this.props.programs).filter((v) => v.slot._id === slot._id),
            program);
    }

    handleDeleteOverlay (program, bug) {
        this.props.deleteProgramOverlay(this.props.channel,
            this.props.stream,
            program.slot._id,
            program._id,
            bug._id, (cb) => {
            });
    }

    renderBugs (program) {
        const bugs = [];
        for (let i = 0; i <= program.count; i++) {
            bugs.push(
                <Tooltip
                    key={i}
                    placement="top"
                    title={moment(program.bugStartTime).add(((i) * BUG_INTERVAL), 'm').local().format(this.props.timeFormat)}>
                    <p
                        className="bug"
                        style={{ left: (program.lStart + ((i) * BUG_INTERVAL)) + '%' }}/>
                </Tooltip>,
            );
        }

        return bugs;
    }

    handleExpansion (slots, index, b) {
        if (this.state['slot' + index] === undefined && b) {
            this.setState({
                ['slot' + index]: false,
            });
        } else {
            this.setState({
                ['slot' + index]: !this.state['slot' + index],
            });
        }
    }

    handleSlotSettings (slot) {
        const token = localStorage.getItem('authorizationToken');

        this.props.fetchBugs(slot.channel, slot.live_stream, slot._id, token);
        this.props.showSlotSettingsDialog();
    }

    handleFetch (type, skip, limit, searchText, sortBy, order) {
        const token = localStorage.getItem('authorizationToken');

        this.props.fetchAssetsData(type, token, 'complete', null, null,
            skip,
            limit,
            searchText,
            sortBy,
            order);
    }

    handleScroll (e) {
        const scroll = e.target.scrollTop;
        const scrollHeight = e.target.scrollHeight;
        const height = e.target.clientHeight;

        if ((this.props.assetData.length < this.props.count) && (scrollHeight - scroll <= height + 256) && !this.props.assetDataInProgress) {
            this.handleFetch('video',
                this.props.skip,
                this.props.limit + 10,
                this.props.searchKey,
                this.props.sortBy,
                this.props.order);
        }
    }

    render () {
        const { classes } = this.props;
        const {
            value,
        } = this.state;

        return (
            <div className="stream sarabun_font_weight">
                {
                    this.props.slotsInProgress
                        ? <div className="no_data loader_div">
                            <CircularProgress className="loader_css"/>
                        </div>
                        : <div>
                            {
                                this.props.slots &&
                                Object.keys(this.props.slots).length
                                    ? Object.values(this.props.slots).map((slot, index) => {
                                        const a = new Date();
                                        const b = moment(a).diff(slot.end_at) >= 0;
                                        const showExpansion = this.state['slot' + index] || this.state['slot' + index] !== undefined
                                            ? this.state['slot' + index]
                                            : b;
                                        const slotPrograms = Object.values(this.props.programs).filter((v) => v.slot._id === slot._id);

                                        const timeZone = this.props.configuration['stream_timezone'];
                                        const start = momentTimeZone.tz(slot['start_at'], timeZone).format('hh:mm A ').split(' ');
                                        const end = momentTimeZone.tz(slot['end_at'], timeZone).format('hh:mm A').split(' ');
                                        return (
                                            <section key={index}>
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                        marginBottom: '10px',
                                                        display: 'inline-flex',
                                                        float: 'right',
                                                    }}>
                                                    {
                                                        !(moment(slot['end_at']).isBefore(moment(), 'second'))
                                                            ? <div className="slot_buttons">
                                                                <ClearSlotButton slot={slot}/>
                                                                <Button
                                                                    className="add_new"
                                                                    onClick={() => this.handleSlotSettings(slot)}>
                                                                    <CustomIcon
                                                                        className="settings"
                                                                        icon="settings"/>
                                                                </Button>
                                                                <Button
                                                                    className="add_new"
                                                                    onClick={() => {
                                                                        this.handleOpen(slot);
                                                                    }}>
                                                                    <CustomIcon
                                                                        className="plus"
                                                                        icon="plus"/>
                                                                </Button>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="stream_time_section">
                                                    {
                                                        this.props.checked
                                                            ? <p style={{ marginBottom: '10px' }}>
                                                                {start[0]}
                                                                <span style={{ fontSize: '12px' }}> {start[1]} </span>
                                                                <span>-</span> {end[0]}
                                                                <span style={{ fontSize: '12px' }}> {end[1]} </span>
                                                            </p>
                                                            : <p style={{ marginBottom: '10px' }}>
                                                                {moment(slot['start_at']).local().format('HH:mm ')}
                                                                <span>-</span> {moment(slot['end_at']).local().format('HH:mm')}
                                                                <span style={{ fontSize: '12px' }}> Hrs </span>
                                                            </p>
                                                    }
                                                    <p>&nbsp;({momentTimeZone.tz(timeZone).zoneAbbr()})</p>
                                                    {
                                                        slotPrograms && slotPrograms.length > 0
                                                            ? <div
                                                                className="expansion_open down_button"
                                                                onClick={() => this.handleExpansion(slot, index, b)}>
                                                                {
                                                                    showExpansion
                                                                        ? <img alt="side" src={rightIcon}/>
                                                                        : <img alt="down" src={downIcon}/>
                                                                }
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div
                                                    key={index}
                                                    className="stream_inside"
                                                    style={{
                                                        height: showExpansion ? '100px' : 'max-content',
                                                        overflow: showExpansion ? 'hidden' : '',
                                                    }}>
                                                    <div className="stream_sections">
                                                        {this.renderSlots(slot)}
                                                    </div>
                                                </div>
                                            </section>
                                        );
                                    })
                                    : <div className="no_data loader_div">
                                        No data
                                    </div>
                            }
                        </div>
                }
                <Modal
                    aria-describedby="simple-modal-description"
                    aria-labelledby="simple-modal-title"
                    className="modal_slotpopup"
                    open={this.props.showSlotPopup}
                    onClose={this.handleCloseSlotPopUp}>
                    <div className="slotpopup">
                        <DynamicSlot/>
                    </div>
                </Modal>

                <Modal
                    aria-describedby="simple-modal-description"
                    aria-labelledby="simple-modal-title"
                    className="stream_modal"
                    open={this.state.open}
                    onClose={this.handleClose}>
                    <div
                        className={classes.paper}
                        style={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}>
                        {this.modalHeader(value, classes)}
                        {this.modalContent(value)}
                    </div>
                </Modal>
            </div>
        );
    }
}

Stream.propTypes = {
    addProgram: PropTypes.func.isRequired,
    addProgramInProgress: PropTypes.bool.isRequired,
    addProgramOverlay: PropTypes.func.isRequired,
    addSlot: PropTypes.func.isRequired,
    assetData: PropTypes.array.isRequired,
    assetDataInProgress: PropTypes.bool.isRequired,
    assets: PropTypes.array.isRequired,
    assetsInProgress: PropTypes.bool.isRequired,
    assetsOverView: PropTypes.array.isRequired,
    assetsOverViewInProgress: PropTypes.bool.isRequired,
    channel: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    clearDynamicInputValues: PropTypes.func.isRequired,
    configuration: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    currentSlot: PropTypes.object.isRequired,
    currentUpdatingSlot: PropTypes.bool.isRequired,
    deleteProgram: PropTypes.func.isRequired,
    deleteProgramInProgress: PropTypes.bool.isRequired,
    deleteProgramOverlay: PropTypes.func.isRequired,
    deleteSlot: PropTypes.func.isRequired,
    fetchAssetsData: PropTypes.func.isRequired,
    fetchAssetsOverviewData: PropTypes.func.isRequired,
    fetchBugs: PropTypes.func.isRequired,
    fetchPrograms: PropTypes.func.isRequired,
    fetchSlotTemplate: PropTypes.func.isRequired,
    fetchSlots: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    programs: PropTypes.object.isRequired,
    programsInProgress: PropTypes.bool.isRequired,
    searchKey: PropTypes.string.isRequired,
    setCurrentAsset: PropTypes.func.isRequired,
    setCurrentSlot: PropTypes.func.isRequired,
    setSlotPopupShow: PropTypes.func.isRequired,
    setUpdatedPrograms: PropTypes.func.isRequired,
    showSlotPopup: PropTypes.bool.isRequired,
    showSlotSettingsDialog: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    slots: PropTypes.array.isRequired,
    slotsInProgress: PropTypes.bool.isRequired,
    sortBy: PropTypes.string.isRequired,
    stream: PropTypes.string.isRequired,
    timeFormat: PropTypes.string.isRequired,
    updateProgram: PropTypes.func.isRequired,
    updateProgramInProgress: PropTypes.bool.isRequired,
    order: PropTypes.number,
    selectedDate: PropTypes.instanceOf(Date),
};

const stateToProps = (state) => {
    return {
        assets: state.asset._.list,
        assetData: state.asset.assetData.list,
        assetDataInProgress: state.asset.assetData.inProgress,
        channel: state.live.channel.current,
        configuration: state.live.stream.configuration,
        stream: state.live.stream.current,
        slots: state.live.slot.list,
        currentSlot: state.live.slot.current,
        selectedDate: state.live.selectedDate,
        programs: state.live.program.list,
        programsInProgress: state.live.program.inProgress,
        updateProgramInProgress: state.live.program.updateInProgress,
        deleteProgramInProgress: state.live.program.deleteInProgress,
        addProgramInProgress: state.live.program.addInProgress,
        assetsOverView: state.asset.assetsOverViewData.encoded,
        assetsOverViewInProgress: state.asset.assetsOverViewData.inProgress,
        slotsInProgress: state.live.slot.inProgress,
        assetsInProgress: state.asset._.inProgress,
        timeFormat: state.live.timeFormat,
        showSlotPopup: state.live.showSlotPopup,
        checked: state.profile.timeFormat.checked,
        currentUpdatingSlot: state.live.currentUpdatingSlot,
        skip: state.asset.assetData.skip,
        limit: state.asset.assetData.limit,
        count: state.asset.assetData.count,
        searchKey: state.asset.assetData.searchKey,
        sortBy: state.asset.assetData.sortBy,
        order: state.asset.assetData.order,
    };
};

const actionsToProps = {
    addProgram,
    fetchAssetsData,
    fetchSlots,
    addSlot,
    setCurrentSlot,
    fetchPrograms,
    deleteProgram,
    updateProgram,
    deleteSlot,
    addProgramOverlay,
    deleteProgramOverlay,
    setCurrentAsset,
    setSlotPopupShow,
    clearDynamicInputValues,
    fetchSlotTemplate,
    fetchAssetsOverviewData,
    showSlotSettingsDialog,
    fetchBugs,
    setUpdatedPrograms,
};

export default withStyles(styles)(connect(stateToProps, actionsToProps)(Stream));
