import {
    ACCOUNT_ADD_ERROR,
    ACCOUNT_ADD_SUCCESS,
    ACCOUNT_AUTHENTICATION_ERROR,
    ACCOUNT_BALANCE_FETCH_ERROR,
    ACCOUNT_DELETE_ERROR,
    ACCOUNT_DELETE_SUCCESS,
    ACCOUNT_UPDATE_ERROR,
    ACCOUNT_UPDATE_SUCCESS,
    ACCOUNTS_FETCH_ERROR,
    NFT_MINT_ERROR,
    TOKENS_FETCH_ERROR,
} from '../constants/account';
import {
    ASSET_ADD_SUCCESS_MESSAGE,
    ASSET_CATEGORY_DELETE_SUCCESS_MESSAGE,
    ASSET_CATEGORY_TABLE_ERROR,
    ASSET_DELETE_ERROR,
    ASSET_DELETE_SUCCESS,
    ASSET_FETCH_ERROR,
    ASSET_FILE_ADD_SUCCESS_MESSAGE,
    ASSET_FILE_ENCODE_ERROR,
    ASSET_FILE_ENCODE_SUCCESS_MESSAGE, ASSET_NFT_UPDATE_ERROR,
    ASSET_SCENES_FETCH_ERROR,
    ASSET_TAGS_FETCH_ERROR,
    ASSET_UPDATE_SUCCESS_MESSAGE,
    ASSETS_CATEGORIES_FETCH_ERROR,
    ASSETS_FETCH_ERROR, DELETE_ASSET_THUMBNAIL_ERROR, DELETE_ASSET_THUMBNAIL_SUCCESS,
    FILE_DOWNLOAD_ERROR,
    FILE_DOWNLOAD_SUCCESS,
    FILE_UPLOAD_ERROR,
    FILE_UPLOAD_SUCCESS,
    GENRE_DELETE_SUCCESS_MESSAGE,
    NEW_ASSET_CATEGORY_ADD_SUCCESS_MESSAGE,
    NEW_GENRE_ADD_SUCCESS_MESSAGE,
    TAGS_LIST_FETCH_ERROR,
} from '../constants/asset';
import {
    BUGS_ADD_ERROR,
    DELETE_BUGS_ERROR,
    FETCH_ALL_BUGS_ERROR,
    FETCH_BUGS_ERROR,
    UPDATE_BUGS_ERROR,
} from '../constants/bugs';
import {
    CHANNEL_LIVE_STREAM_CONFIG_FETCH_ERROR,
    CHANNEL_LIVE_STREAM_TEXT_ADD_ERROR,
    CHANNEL_LIVE_STREAM_TEXT_ADD_SUCCESS,
    CHANNEL_LIVE_STREAMS_FETCH_ERROR,
    CHANNELS_AUTHENTICATION_ERROR,
    CHANNELS_FETCH_ERROR,
    LINEAR_BROADCAST_MINT_ERROR,
} from '../constants/channel';
import {
    ASSET_CHAPTER_ADD_ERROR,
    ASSET_CHAPTER_CATEGORIES_FETCH_ERROR,
    ASSET_CHAPTER_OBJECTS_FETCH_ERROR,
    ASSET_CHAPTER_PEOPLE_FETCH_ERROR,
    ASSET_CHAPTER_UPDATE_ERROR,
    ASSET_CHAPTERS_FETCH_ERROR,
} from '../constants/chapterMarking';
import {
    DELETE_TEMPLATE_ERROR,
    DELETE_TEMPLATE_SUCCESS,
    FETCH_SLOT_TEMPLATE_ERROR,
} from '../constants/dynamicPrograms';
import {
    INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_ERROR,
    INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_ERROR,
    INTERACTIVE_VIDEO_ADD_ERROR, INTERACTIVE_VIDEO_UPDATE_ERROR, INTERACTIVE_VIDEO_UPDATE_SUCCESS,
    INTERACTIVE_VIDEOS_FETCH_ERROR,
} from '../constants/interactiveVideo';
import { USER_LOGIN_ERROR, USER_LOGIN_SUCCESS_MESSAGE } from '../constants/login';
import { PEOPLE_ADD_ERROR, PEOPLE_DELETE_ERROR, PEOPLE_FETCH_ERROR } from '../constants/people';
import {
    PLAY_OUT_FETCH_ERROR,
    PLAY_OUT_REFRESH_ERROR,
    PROGRAM_ADD_ERROR,
    PROGRAM_DELETE_ERROR,
    PROGRAM_DELETE_SUCCESS,
    PROGRAM_OVERLAY_ADD_ERROR,
    PROGRAM_OVERLAY_DELETE_ERROR,
    PROGRAM_UPDATE_ERROR,
    PROGRAMS_CLEAR_ERROR,
    PROGRAMS_CLEAR_SUCCESS,
    PROGRAMS_FETCH_ERROR,
    PROGRAMS_FILL_ERROR,
} from '../constants/program';
import {
    SLOT_ADD_ERROR,
    SLOT_PUSH_ERROR,
    SLOT_PUSH_SUCCESS,
    SLOTS_FETCH_ERROR,
    STREAM_CONFIG_UPDATE_ERROR,
    STREAM_CONFIG_UPDATE_SUCCESS,
    STREAM_START_ERROR,
    STREAM_START_SUCCESS,
    STREAM_STOP_ERROR,
    STREAM_STOP_SUCCESS,
} from '../constants/slots';
import { MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import { TEXT_TO_AUDIO_ADD_ERROR, TEXT_TO_AUDIO_ADD_SUCCESS } from '../constants/textToSpeech';
import { DEVICE_ID_FETCH_ERROR, WATCH_URL_FETCH_ERROR } from '../constants/videoAssets';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case DELETE_ASSET_THUMBNAIL_SUCCESS:
    case DELETE_ASSET_THUMBNAIL_ERROR:
    case USER_LOGIN_SUCCESS_MESSAGE:
    case USER_LOGIN_ERROR:
    case PROGRAM_ADD_ERROR:
    case ASSET_CATEGORY_TABLE_ERROR:
    case NEW_ASSET_CATEGORY_ADD_SUCCESS_MESSAGE:
    case ASSET_ADD_SUCCESS_MESSAGE:
    case ASSET_CATEGORY_DELETE_SUCCESS_MESSAGE:
    case SLOTS_FETCH_ERROR:
    case PROGRAMS_FETCH_ERROR:
    case PROGRAM_UPDATE_ERROR:
    case PROGRAM_DELETE_ERROR:
    case SLOT_ADD_ERROR:
    case PROGRAM_OVERLAY_ADD_ERROR:
    case PROGRAM_OVERLAY_DELETE_ERROR:
    case ASSETS_FETCH_ERROR:
    case ASSET_FETCH_ERROR:
    case ASSET_FILE_ENCODE_SUCCESS_MESSAGE:
    case ASSET_FILE_ENCODE_ERROR:
    case ASSET_FILE_ADD_SUCCESS_MESSAGE:
    case GENRE_DELETE_SUCCESS_MESSAGE:
    case NEW_GENRE_ADD_SUCCESS_MESSAGE:
    case ASSET_UPDATE_SUCCESS_MESSAGE:
    case CHANNELS_FETCH_ERROR:
    case CHANNEL_LIVE_STREAMS_FETCH_ERROR:
    case PLAY_OUT_FETCH_ERROR:
    case SLOT_PUSH_ERROR:
    case SLOT_PUSH_SUCCESS:
    case ASSET_DELETE_SUCCESS:
    case ASSET_TAGS_FETCH_ERROR:
    case PROGRAMS_FILL_ERROR:
    case CHANNELS_AUTHENTICATION_ERROR:
    case STREAM_STOP_ERROR:
    case STREAM_STOP_SUCCESS:
    case STREAM_START_SUCCESS:
    case STREAM_START_ERROR:
    case STREAM_CONFIG_UPDATE_ERROR:
    case STREAM_CONFIG_UPDATE_SUCCESS:
    case FILE_UPLOAD_ERROR:
    case FILE_UPLOAD_SUCCESS:
    case FILE_DOWNLOAD_SUCCESS:
    case UPDATE_BUGS_ERROR:
    case BUGS_ADD_ERROR:
    case FETCH_BUGS_ERROR:
    case FETCH_ALL_BUGS_ERROR:
    case DELETE_BUGS_ERROR:
    case TAGS_LIST_FETCH_ERROR:
    case ASSETS_CATEGORIES_FETCH_ERROR:
    case DELETE_TEMPLATE_SUCCESS:
    case DELETE_TEMPLATE_ERROR:
    case FETCH_SLOT_TEMPLATE_ERROR:
    case PEOPLE_FETCH_ERROR:
    case PEOPLE_ADD_ERROR:
    case PEOPLE_DELETE_ERROR:
    case ASSET_DELETE_ERROR:
    case ASSET_SCENES_FETCH_ERROR:
    case ASSET_CHAPTER_ADD_ERROR:
    case ASSET_CHAPTERS_FETCH_ERROR:
    case CHANNEL_LIVE_STREAM_CONFIG_FETCH_ERROR:
    case CHANNEL_LIVE_STREAM_TEXT_ADD_SUCCESS:
    case CHANNEL_LIVE_STREAM_TEXT_ADD_ERROR:
    case ASSET_CHAPTER_UPDATE_ERROR:
    case PLAY_OUT_REFRESH_ERROR:
    case ASSET_CHAPTER_OBJECTS_FETCH_ERROR:
    case ASSET_CHAPTER_PEOPLE_FETCH_ERROR:
    case ASSET_CHAPTER_CATEGORIES_FETCH_ERROR:
    case FILE_DOWNLOAD_ERROR:
    case PROGRAMS_CLEAR_ERROR:
    case PROGRAMS_CLEAR_SUCCESS:
    case PROGRAM_DELETE_SUCCESS:
    case WATCH_URL_FETCH_ERROR:
    case ACCOUNT_BALANCE_FETCH_ERROR:
    case TOKENS_FETCH_ERROR:
    case NFT_MINT_ERROR:
    case ACCOUNTS_FETCH_ERROR:
    case ACCOUNT_ADD_SUCCESS:
    case ACCOUNT_ADD_ERROR:
    case ACCOUNT_AUTHENTICATION_ERROR:
    case ACCOUNT_UPDATE_SUCCESS:
    case ACCOUNT_UPDATE_ERROR:
    case ACCOUNT_DELETE_SUCCESS:
    case ACCOUNT_DELETE_ERROR:
    case LINEAR_BROADCAST_MINT_ERROR:
    case DEVICE_ID_FETCH_ERROR:
    case TEXT_TO_AUDIO_ADD_SUCCESS:
    case TEXT_TO_AUDIO_ADD_ERROR:
    case INTERACTIVE_VIDEOS_FETCH_ERROR:
    case INTERACTIVE_VIDEO_ADD_ERROR:
    case INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_ERROR:
    case INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_ERROR:
    case MESSAGE_SHOW:
    case INTERACTIVE_VIDEO_UPDATE_SUCCESS:
    case INTERACTIVE_VIDEO_UPDATE_ERROR:
    case ASSET_NFT_UPDATE_ERROR: {
        if (action.message === 'Error occurred while verifying the JWT token.') {
            return state;
        }

        return {
            open: true,
            message: action.message,
        };
    }
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };

    default:
        return state;
    }
};

export default snackbar;
