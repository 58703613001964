import Axios from 'axios';
import {
    ADD_STREAM_SET,
    CHANNEL_LIVE_STREAM_CONFIG_FETCH_ERROR,
    CHANNEL_LIVE_STREAM_CONFIG_FETCH_IN_PROGRESS,
    CHANNEL_LIVE_STREAM_CONFIG_FETCH_SUCCESS,
    CHANNEL_LIVE_STREAM_TEXT_ADD_ERROR,
    CHANNEL_LIVE_STREAM_TEXT_ADD_IN_PROGRESS,
    CHANNEL_LIVE_STREAM_TEXT_ADD_SUCCESS,
    CHANNEL_LIVE_STREAMS_FETCH_ERROR,
    CHANNEL_LIVE_STREAMS_FETCH_IN_PROGRESS,
    CHANNEL_LIVE_STREAMS_FETCH_SUCCESS,
    CHANNELS_AUTHENTICATION_ERROR,
    CHANNELS_AUTHENTICATION_IN_PROGRESS,
    CHANNELS_AUTHENTICATION_SUCCESS,
    CHANNELS_FETCH_ERROR,
    CHANNELS_FETCH_IN_PROGRESS,
    CHANNELS_FETCH_SUCCESS,
    CURRENT_CHANNEL_SET,
    LINEAR_BROADCAST_MINT_ERROR,
    LINEAR_BROADCAST_MINT_IN_PROGRESS,
    LINEAR_BROADCAST_MINT_SUCCESS,
} from '../constants/channel';
import {
    LINEAR_BROADCAST_MINT_URL,
    URL_AUTHENTICATE_CHANNELS,
    URL_CHANNELS_GET,
    urlAddChannelLiveStreamText,
    urlFetchChannelLiveStreamConfig,
    urlFetchChannelLiveStreams,
} from '../constants/url';

const fetchChannelsInProgress = () => {
    return {
        type: CHANNELS_FETCH_IN_PROGRESS,
    };
};

const fetchChannelsSuccess = (channels) => {
    const current = localStorage.getItem('c')
        ? localStorage.getItem('c')
        : channels.list.length && channels.list[0]._id;
    return {
        type: CHANNELS_FETCH_SUCCESS,
        channels: channels.list ? channels.list : channels,
        current,
    };
};

const fetchChannelsError = (message) => {
    return {
        type: CHANNELS_FETCH_ERROR,
        message,
    };
};

export const fetchChannels = (cb) => (dispatch) => {
    dispatch(fetchChannelsInProgress());

    Axios.get(URL_CHANNELS_GET, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchChannelsSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchChannelsError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const authenticateChannelsInProgress = () => {
    return {
        type: CHANNELS_AUTHENTICATION_IN_PROGRESS,
    };
};

const authenticateChannelsSuccess = (channels) => {
    return {
        type: CHANNELS_AUTHENTICATION_SUCCESS,
        channels: channels,
    };
};

const authenticateChannelsError = (message) => {
    return {
        type: CHANNELS_AUTHENTICATION_ERROR,
        message,
    };
};

export const authenticateChannels = (params, cb) => (dispatch) => {
    dispatch(authenticateChannelsInProgress());

    Axios.get(`${URL_AUTHENTICATE_CHANNELS}?${params}`, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(authenticateChannelsSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(authenticateChannelsError('Error while authentication'));
            cb(error);
        });
};

const fetchChannelLiveStreamsInProgress = () => {
    return {
        type: CHANNEL_LIVE_STREAMS_FETCH_IN_PROGRESS,
    };
};

const fetchChannelLiveStreamsSuccess = (liveStreams) => {
    const current = localStorage.getItem('s')
        ? localStorage.getItem('s')
        : liveStreams.length
            ? liveStreams[0]._id
            : '';

    return {
        type: CHANNEL_LIVE_STREAMS_FETCH_SUCCESS,
        liveStreams,
        current,
    };
};

const fetchChannelLiveStreamsError = (message) => {
    return {
        type: CHANNEL_LIVE_STREAMS_FETCH_ERROR,
        message,
    };
};

export const fetchChannelLiveStreams = (channelId, status, cb) => (dispatch) => {
    dispatch(fetchChannelLiveStreamsInProgress());
    const URL = urlFetchChannelLiveStreams(channelId, status);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchChannelLiveStreamsSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchChannelLiveStreamsError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

export const setAddChannel = (value) => {
    localStorage.setItem('c', value);

    return {
        type: CURRENT_CHANNEL_SET,
        value,
    };
};

export const setAddStream = (value) => {
    localStorage.setItem('s', value);
    return {
        type: ADD_STREAM_SET,
        value,
    };
};

const fetchChannelLiveStreamConfigInProgress = () => {
    return {
        type: CHANNEL_LIVE_STREAM_CONFIG_FETCH_IN_PROGRESS,
    };
};

const fetchChannelLiveStreamConfigSuccess = (item) => {
    return {
        type: CHANNEL_LIVE_STREAM_CONFIG_FETCH_SUCCESS,
        item,
    };
};

const fetchChannelLiveStreamConfigError = (message) => {
    return {
        type: CHANNEL_LIVE_STREAM_CONFIG_FETCH_ERROR,
        message,
    };
};

export const fetchChannelLiveStreamConfig = (channelId, streamId) => (dispatch) => {
    dispatch(fetchChannelLiveStreamConfigInProgress());

    const URL = urlFetchChannelLiveStreamConfig(channelId, streamId);

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchChannelLiveStreamConfigSuccess(res.data && res.data.result ? res.data.result : {}));
        })
        .catch((error) => {
            dispatch(fetchChannelLiveStreamConfigError(error.response ? error.response.data.message : error.message,
            ));
        });
};

const addChannelLiveStreamTextInProgress = () => {
    return {
        type: CHANNEL_LIVE_STREAM_TEXT_ADD_IN_PROGRESS,
    };
};

const addChannelLiveStreamTextSuccess = (item) => {
    return {
        type: CHANNEL_LIVE_STREAM_TEXT_ADD_SUCCESS,
        item,
        message: 'Success',
    };
};

const addChannelLiveStreamTextError = (message) => {
    return {
        type: CHANNEL_LIVE_STREAM_TEXT_ADD_ERROR,
        message,
    };
};

export const addChannelLiveStreamText = (channelId, streamId, data, cb) => (dispatch) => {
    dispatch(addChannelLiveStreamTextInProgress());

    const URL = urlAddChannelLiveStreamText(channelId, streamId);

    Axios.post(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(addChannelLiveStreamTextSuccess(res.data && res.data.result ? res.data.result : {}));
            cb(null);
        })
        .catch((error) => {
            dispatch(addChannelLiveStreamTextError(error.response ? error.response.data.message : error.message,
                cb(error),
            ));
        });
};

const mintLinearBroadcastInProgress = () => {
    return {
        type: LINEAR_BROADCAST_MINT_IN_PROGRESS,
    };
};

const mintLinearBroadcastSuccess = (item) => {
    return {
        type: LINEAR_BROADCAST_MINT_SUCCESS,
        item,
        message: 'Success',
    };
};

const mintLinearBroadcastError = (message) => {
    return {
        type: LINEAR_BROADCAST_MINT_ERROR,
        message,
    };
};

export const mintLinearBroadcast = (data, cb) => (dispatch) => {
    dispatch(mintLinearBroadcastInProgress());

    Axios.post(LINEAR_BROADCAST_MINT_URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(mintLinearBroadcastSuccess(res.data && res.data.result ? res.data.result : {}));
            cb(null, res.data);
        })
        .catch((error) => {
            // as it is a block-chain api, we are getting error in : error.response.data
            dispatch(mintLinearBroadcastError(error.response ? error.response.data : error.message,
                cb(error),
            ));
        });
};
