import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    deleteAssetThumbnail,
    fetchAssetsData,
    hideDialogDeleteThumb,
    hideDialogViewhumb,
    uploadedThumbs,
} from '../../../../../actions/asset';
import './index.css';

class DialogDeleteThumb extends React.Component {
    constructor (props) {
        super(props);

        this.onClickDelete = this.onClickDelete.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
    }

    onClickDelete () {
        if (this.props.path === 'main') {
            if (this.props.assetId) {
                this.props.deleteAssetThumbnail(this.props.assetId, this.props.value, (cb) => {
                    if (cb) {
                        this.handleFetch();
                        this.props.onClickCancel();
                    }
                });
            }
        } else if (this.props.path === 'local') {
            this.props.thumbsList && this.props.thumbsList.length > 0 &&
            this.props.thumbsList.map((item, index) => {
                if (item && item.type === this.props.value) {
                    this.props.successList && this.props.successList.length > 0 &&
                    this.props.successList.map((succ) => {
                        if (succ.type === this.props.value) {
                            this.props.thumbsList.splice(index, 1);
                            this.props.successList.splice(index, 1);
                            this.props.onClickCancel();
                            this.props.hideDialogViewhumb();
                            this.props.uploadedThumbs([...this.props.thumbsList], [...this.props.successList]);
                        }
                    });
                }
            });
        }
    }

    handleFetch () {
        const token = localStorage.getItem('authorizationToken');
        const recent = this.props.location.pathname === '/manage/assets';

        if (recent) {
            this.props.fetchAssetsData(this.props.assetType, token, false, true, this.props.days,
                this.props.skip,
                this.props.limit,
                this.props.searchKey,
                this.props.sortBy,
                this.props.order);
        } else {
            this.props.fetchAssetsData(this.props.assetType, token, null, null, null,
                this.props.skip,
                this.props.limit,
                this.props.searchKey,
                this.props.sortBy,
                this.props.order);
        }
    }

    render () {
        return (
            <Dialog
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                className="dialog_delete_thumb"
                open={this.props.open}
                onClose={this.props.onClickCancel}>
                <DialogTitle
                    className="title"
                    id="alert-dialog-title">
                    <Typography className="text-16 mb-8 font-600">
                        Are your sure, you want to delete <bold>{this.props.value}</bold> thumbnail?
                    </Typography>
                </DialogTitle>
                <DialogContent className="content">
                    <Button
                        aria-label="Delete"
                        className="delete"
                        onClick={() => this.onClickDelete()}>
                        Delete
                    </Button>
                    <Button
                        className="cancel"
                        onClick={() => this.props.onClickCancel()}>
                        Cancel
                    </Button>
                </DialogContent>
            </Dialog>
        );
    }
}

DialogDeleteThumb.propTypes = {
    assetType: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    days: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    order: PropTypes.number.isRequired,
    path: PropTypes.string.isRequired,
    searchKey: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    assetId: PropTypes.string,
    deleteAssetThumbnail: PropTypes.func,
    fetchAssetsData: PropTypes.func,
    hideDialogViewhumb: PropTypes.func,
    successList: PropTypes.array,
    thumbsList: PropTypes.array,
    uploadedThumbs: PropTypes.func,
    value: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        open: state.asset.dialogDeleteThumb.open,
        value: state.asset.dialogDeleteThumb.name,
        path: state.asset.dialogDeleteThumb.path,
        assetId: state.asset.new.assetId,
        assetType: state.asset.new.dialog.assetType,
        days: state.asset.updatedFilter.days,
        successList: state.asset.thumbsList.successList,
        thumbsList: state.asset.thumbsList.list,
        skip: state.asset.assetData.skip,
        limit: state.asset.assetData.limit,
        count: state.asset.assetData.count,
        searchKey: state.asset.assetData.searchKey,
        sortBy: state.asset.assetData.sortBy,
        order: state.asset.assetData.order,
    };
};

const actionsToProps = {
    onClickCancel: hideDialogDeleteThumb,
    fetchAssetsData,
    deleteAssetThumbnail: deleteAssetThumbnail,
    uploadedThumbs: uploadedThumbs,
    hideDialogViewhumb,
};

export default withRouter(connect(stateToProps, actionsToProps)(DialogDeleteThumb));
