import { AppBar, Button, Modal, Tab, Tabs } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchAssetsData, fetchAssetsOverviewData, setCurrentAsset } from '../../../../../actions/asset';
import { fetchBugs } from '../../../../../actions/bugs';
import { fetchSlotTemplate } from '../../../../../actions/dynamicPrograms';
import {
    addProgram,
    addProgramOverlay,
    deleteProgram,
    deleteProgramOverlay,
    fetchPrograms,
    setUpdatedPrograms,
    updateProgram,
} from '../../../../../actions/program';
import {
    addSlot,
    clearDynamicInputValues,
    deleteSlot,
    fetchSlots,
    setCurrentSlot,
    setSlotPopupShow,
    showSlotSettingsDialog,
} from '../../../../../actions/slot';
import {
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../../../../../constants/url';
import DynamicSlot from '../../DynamicSlotModal';
import ProgramDataTable from '../../Streams/ProgramDataTable';
import SlotDataTable from '../../Streams/SlotDataTable';

const styles = (theme) => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
});

class AddProgram extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            open: false,
            value: 0,
            streamSectionWidth: '',
            selectedContent: [],
            checked: {},
            slotPopup: false,
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
        this.renderCategories = this.renderCategories.bind(this);
        this.updateSelectedContent = this.updateSelectedContent.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
        this.close = this.close.bind(this);
        this.handleOpenProgramPopup = this.handleOpenProgramPopup.bind(this);
        this.handleOpenSlotPopup = this.handleOpenSlotPopup.bind(this);
        this.handleCloseSlotPopUp = this.handleCloseSlotPopUp.bind(this);
        this.renderSlotData = this.renderSlotData.bind(this);
        this.handleMultiDelete = this.handleMultiDelete.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount () {
        this.props.childFunction(this.handleOpen, this.handleOpenSlotPopup);
    }

    handleDelete (value) {
        this.props.deleteProgram(this.props.channel,
            this.props.stream,
            this.props.currentSlot._._id,
            value._id, 1, (cb) => {
                if (cb) {
                    return;
                }
                const selected = this.state.selectedContent.filter((a) => a._id !== value._id);
                this.updateSelectedContent(selected);

                const updatedContent = Object.values(this.props.programs)
                    .filter((v) => (v.slot && v.slot._id) !== (value.slot && value.slot._id));

                this.props.setUpdatedPrograms(updatedContent);
                this.props.fetchPrograms(this.props.channel,
                    this.props.stream,
                    this.props.currentSlot._._id, () => {
                    });
            });
    }

    handleMultiDelete (rowsDeleted) {
        if (rowsDeleted.data && rowsDeleted.data.length) {
            this.deleteRow(rowsDeleted.data, rowsDeleted.data[0], 0);
        }

        return true;
    }

    deleteRow (rowsDeleted, value, index) {
        this.props.deleteProgram(this.props.channel,
            this.props.stream,
            this.props.currentSlot._._id,
            this.state.selectedContent[value.index]._id,
            index + 1, (error) => {
                if (!error) {
                    if (rowsDeleted[index + 1]) {
                        this.deleteRow(rowsDeleted, rowsDeleted[index + 1], index + 1);
                    }

                    if (index === rowsDeleted.length - 1) {
                        this.setState({
                            selectedContent: Object.values(this.props.programs).filter((v) => v.slot._id === this.props.currentSlot._._id),
                        });
                        this.props.setCurrentSlot(this.props.currentSlot._,
                            Object.values(this.props.programs).filter((v) => v.slot._id === this.props.currentSlot._._id));

                        const updatedContent = Object.values(this.props.programs)
                            .filter((v) => (v.slot && v.slot._id) !== (this.props.currentSlot && this.props.currentSlot._._id));
                        this.props.setUpdatedPrograms(updatedContent);
                        this.props.fetchPrograms(this.props.channel,
                            this.props.stream,
                            this.props.currentSlot._._id, (cb) => {
                            });
                    }
                }
            });
    }

    handleOpen (slot) {
        this.setState({
            open: true,
            selectedContent: Object.values(this.props.programs).filter((v) => v.slot._id === slot._id),
        });
        this.props.setCurrentSlot(slot,
            Object.values(this.props.programs).filter((v) => v.slot._id === slot._id));
    }

    close () {
        this.setState({
            open: false,
            show: '',
            checked: {},
        });
    }

    handleCloseSlotPopUp () {
        this.props.setSlotPopupShow(false);
        this.props.clearDynamicInputValues();
    }

    handleClose () {
        if (this.state.value) {
            this.setState({
                value: 0,
                checked: {},
                selectedContent: Object.values(this.props.programs).filter((v) =>
                    v.slot._id === this.props.currentSlot._._id),
            });
        } else {
            this.setState({
                open: false,
                show: '',
                checked: {},
            });
        }
    }

    handleAddContent () {
        if (this.state.show === 'bugs') {
            const selectedOverlay = this.state.selectedContent.filter((v) => v.category &&
                v.category.name === 'bugs')[0];

            const data = {
                startAt: this.state[selectedOverlay._id]
                    ? this.state[selectedOverlay._id]
                    : selectedOverlay.start_at,
                endAt: selectedOverlay.end_at,
                asset: selectedOverlay._id,
            };

            return this.props.addProgramOverlay(this.props.channel,
                this.props.stream,
                this.props.currentSlot._._id,
                this.props.currentSlot.currentProgram._id,
                data, (cb) => {
                    this.handleClose();
                });
        } else if (this.state.show === 'adx') {
            const selectedAd = this.state.selectedContent.filter((v) => v.category &&
                v.category.name === 'adx')[0];
            const adStartTime = this.state[selectedAd._id]
                ? this.state[selectedAd._id]
                : this.state.addTime
                    ? this.state.addTime
                    : selectedAd.start_at;

            const updateData = {
                startAt: this.props.currentSlot.currentProgram['start_at'],
                endAt: adStartTime,
                asset: {
                    id: this.props.currentSlot.currentProgram.asset._._id,
                    startAt: 0,
                    endAt: moment(adStartTime).diff(moment(this.props.currentSlot.currentProgram.start_at)),
                },
            };
            return this.props.updateProgram(this.props.channel,
                this.props.stream,
                this.props.currentSlot._._id,
                this.props.currentSlot.currentProgram._id,
                updateData, (cb) => {
                    if (cb) {
                        return;
                    }
                    // data of ad to be added;
                    const adData = {
                        startAt: moment(adStartTime).utc().format(),
                        endAt: moment(adStartTime).add(selectedAd.file.length, 's').utc().format(),
                        asset: {
                            id: selectedAd._id,
                            startAt: 0,
                            endAt: selectedAd.file.length,
                        },
                    };
                    this.props.addProgram(this.props.channel,
                        this.props.stream,
                        this.props.currentSlot._._id,
                        adData, (cb) => {
                            if (cb) {
                                return;
                            }
                            // data of new program to be add;
                            const programData = {
                                startAt: selectedAd.end_at,
                                endAt: moment(selectedAd.end_at).add(selectedAd.file.length, 's').utc().format(),
                                asset: {
                                    id: this.props.currentSlot.currentProgram.asset._._id,
                                    startAt: moment(selectedAd.start_at)
                                        .diff(moment(this.props.currentSlot.currentProgram.start_at)),
                                    endAt: this.props.currentSlot.currentProgram.asset._.file.length,
                                },
                            };
                            this.props.addProgram(this.props.channel,
                                this.props.stream,
                                this.props.currentSlot._._id,
                                programData, (cb) => {
                                    this.handleClose();
                                });
                        });
                });
        } else if (this.state.show !== 'adx' &&
            this.state.show !== 'bugs') {
            this.state.selectedContent.map((v, index) => {
                if (v.slot &&
                    v.slot._id
                ) {
                    if (this.state[v._id]) {
                        const data = {
                            startAt: this.state[v._id],
                            endAt: moment(this.state[v._id]).add(v.asset._.file.length, 's').utc().format(),
                            asset: {
                                id: v._id,
                                startAt: 0,
                                endAt: v.asset._.file.length,
                            },
                            // take file length in amount
                        };
                        return this.props.updateProgram(this.props.channel,
                            this.props.stream,
                            this.props.currentSlot._._id,
                            v._id,
                            data, (cb) => {
                                if (index === this.state.selectedContent.length - 1) {
                                    this.handleClose();
                                }
                            });
                    } else if (index === this.state.selectedContent.length - 1) {
                        this.handleClose();
                    }
                } else {
                    const data = {
                        startAt: this.state[v._id]
                            ? this.state[v._id]
                            : v.start_at,
                        endAt: v.end_at,
                        asset: {
                            id: v._id,
                            startAt: 0,
                            endAt: v.file.length,
                        },
                    };
                    return this.props.addProgram(this.props.channel,
                        this.props.stream,
                        this.props.currentSlot._._id,
                        data, (cb) => {
                            if (index === this.state.selectedContent.length - 1) {
                                this.handleClose();
                            }
                        });
                }

                return null;
            });
        }
    }

    handleChange (event, value) {
        this.setState({ value });
    }

    modalContent (value) {
        return (
            <div className="content">
                {value === 0 && (
                    <div>
                        <div className="section_header">
                            <p
                                className="sarabun_font_weight"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                Available List
                                <div
                                    className="nodata_dynamic_slots">
                                    <Button
                                        style={{ marginLeft: '20px' }}
                                        onClick={() =>
                                            this.handleOpenProgramPopup()}>
                                        Add Dynamic Program
                                    </Button>
                                </div>
                            </p>
                            <div className="header_right">
                                {
                                    this.props.currentSlot &&
                                    this.props.currentSlot._ &&
                                    this.props.currentSlot._._id
                                        ? this.props.checked
                                            ? <p className="sarabun_font_weight slot_text">
                                                {`Slot Time:
                                            ${moment(this.props.currentSlot._.start_at).local().format('hh:mm A ')}
                                            -
                                            ${moment(this.props.currentSlot._.end_at).local().format(' hh:mm A')} `}
                                            </p>
                                            : <p className="sarabun_font_weight slot_text">
                                                {`Slot Time:
                                            ${moment(this.props.currentSlot._.start_at).local().format('HH:mm ')}
                                            -
                                            ${moment(this.props.currentSlot._.end_at).local().format(' HH:mm') + ' Hrs'} `}
                                            </p>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="data_table table_modal slot_selected_data table1">
                            <SlotDataTable
                                data={
                                    this.state.selectedContent &&
                                    this.state.selectedContent.length
                                        ? this.state.selectedContent.map((value, index) => (
                                            this.renderSlotData(value, value.type, index)
                                        ))
                                        : []
                                }
                                handleMultiDelete={this.handleMultiDelete}
                                inProgress={this.props.assetsInProgress}/>
                        </div>
                    </div>
                )}
                {value !== 0 && (
                    <div className="add_program_section">
                        <div className="section_header">
                            <p
                                className="sarabun_font_weight"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                Available List
                                <div
                                    className="nodata_dynamic_slots">
                                    <Button
                                        style={{ marginLeft: '20px' }}
                                        onClick={(e) => this.handleOpenSlotPopup(e)}> Add Dynamic Program </Button>
                                </div>
                            </p>
                        </div>
                        <div
                            className="data_table table_modal table_scroll_bar table1"
                            onScroll={this.handleScroll}>
                            <ProgramDataTable
                                data={this.props.assetData.length
                                    ? this.props.assetData.map((value, index) => (
                                        this.renderCategories(value, value.type, index)
                                    ))
                                    : []}
                                inProgress={this.props.assetDataInProgress}
                                parentProps={this.props}
                                onRowClick={this.handleRowClick}/>
                        </div>
                    </div>
                )}

                <div className="footer sarabun_font_weight">
                    <div>
                        <Button
                            className="close_adding"
                            onClick={() => this.close()}>
                            Close
                        </Button>
                        <Button
                            className="add_stream"
                            onClick={() => this.handleAddContent()}>
                            {value ? 'Add' : 'Update'}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    modalHeader (value, classes) {
        return (
            <div className="header header_tab">
                <div className={classes.root}>
                    <AppBar
                        className="modal_appbar"
                        position="static">
                        <Tabs
                            scrollButtons="auto"
                            value={value}
                            variant="scrollable"
                            onChange={this.handleChange}>
                            <Tab
                                key="slotData-tab"
                                className={
                                    value === 0
                                        ? 'active_tab'
                                        : 'default_tab sarabun_font_weight'
                                }
                                label={`Slot Programming (${Object.keys(this.state.selectedContent).length})`}
                            />
                            {
                                this.props.assetsOverView.length
                                    ? this.props.assetsOverView.map((item, index) => {
                                        if (item.type !== 'video') {
                                            return;
                                        }

                                        return (
                                            <Tab
                                                key={item._id}
                                                className={
                                                    value === (index + 1)
                                                        ? 'active_tab'
                                                        : 'default_tab sarabun_font_weight'}
                                                label={`${item.type} (${item.count
                                                    ? item.count
                                                    : 0})`} onClick={() => this.handleFetch(item.type,
                                                    DEFAULT_SKIP,
                                                    DEFAULT_LIMIT,
                                                    DEFAULT_SEARCH_KEY,
                                                    DEFAULT_SORT_BY,
                                                    DEFAULT_ORDER)}
                                            />
                                        );
                                    })
                                    : <div className="no_data loader_div">No Assets Found</div>
                            }
                        </Tabs>
                    </AppBar>
                </div>
            </div>
        );
    }

    handleChangeCheckBox (event, name, data) {
        let selected = this.state.selectedContent;
        const check = this.state.checked;

        check[name] = !!event.target.checked;

        this.setState({
            checked: check,
        });

        if (event.target.checked) {
            if (selected.length) {
                data.start_at = selected[selected.length - 1].end_at;
                data.end_at = moment(selected[selected.length - 1].end_at).add(data.file.length, 's');
            } else {
                if (this.props.currentSlot) {
                    data.start_at = moment(this.props.currentSlot._['start_at']);
                    data.end_at = moment(this.props.currentSlot._['start_at']).add(data.file.length, 's');
                }
            }
            selected.push(data);
            this.updateSelectedContent(selected);
        } else {
            selected = selected.filter((a) => a._id !== data._id);
            this.updateSelectedContent(selected);
        }
    }

    updateSelectedContent (selected) {
        this.setState({
            selectedContent: selected,
        });
    }

    handleRowClick (index) {
        const data = this.props.assetData[index];
        const name = 'checked' + (data && this.props.assetData[index]._id);
        let selected = this.state.selectedContent;
        const check = this.state.checked;

        check[name] = !check[name];

        this.setState({
            checked: check,
        });

        if (check[name]) {
            if (selected.length) {
                data.start_at = selected[selected.length - 1].end_at;
                data.end_at = moment(selected[selected.length - 1].end_at).add(data.file.length, 's');
            } else {
                if (this.props.currentSlot) {
                    data.start_at = moment(this.props.currentSlot._['start_at']);
                    data.end_at = moment(this.props.currentSlot._['start_at']).add(data.file.length, 's');
                }
            }
            selected.push(data);
            this.updateSelectedContent(selected);
        } else {
            selected = selected.filter((a) => a._id !== data._id);
            this.updateSelectedContent(selected);
        }
    }

    renderCategories (value, name, index) {
        const sourceName = (name === 'audio') ? 'track_name' : 'name';
        const type = value && value.type;

        return (
            [
                <Checkbox
                    key={index}
                    checked={this.state.checked && this.state.checked['checked' + value._id]}
                    className="check_box"
                    onChange={(e) =>
                        this.handleChangeCheckBox(e, 'checked' + value._id, value)}/>,
                <div
                    key={index}
                    className="video_name sarabun_font_weight"
                    title={value[type] && value[type][sourceName]
                        ? value[type][sourceName]
                        : value.asset &&
                        value.asset &&
                        value.asset._ &&
                        value.asset._.file &&
                        value.asset._.file.name}>
                    {
                        value[type] && value[type][sourceName]
                            ? value[type][sourceName]
                            : value.asset &&
                            value.asset &&
                            value.asset._ &&
                            value.asset._.file &&
                            value.asset._.file.name
                    }
                </div>,
                <p
                    key={index}
                    className="video_duration sarabun_font_weight">{
                        value.file
                            ? moment.utc(moment.duration(value.file.length, 'seconds')
                                .asMilliseconds()).format(this.props.timeFormat)
                            : value.asset &&
                        value.asset._ &&
                        value.asset._.file &&
                        value.asset._.file.length
                                ? moment.utc(moment.duration(value.asset._ && value.asset._.file.length, 'seconds')
                                    .asMilliseconds()).format(this.props.timeFormat)
                                : ''}  </p>,
            ]
        );
    }

    renderSlotData (value, name, index) {
        const sourceName = (name === 'audio') ? 'track_name' : 'name';
        const type = value && value.type;

        return (
            [<div
                key={index}
                className="video_name sarabun_font_weight"
                title={value[type] && value[type][sourceName]
                    ? value[type][sourceName]
                    : value.asset &&
                    value.asset._ &&
                    value.asset._.video &&
                    value.asset._.video.name
                        ? value.asset._.video.name
                        : value.asset &&
                        value.asset &&
                        value.asset._ &&
                        value.asset._.file &&
                        value.asset._.file.name}>
                {
                    value[type] && value[type][sourceName]
                        ? value[type][sourceName]
                        : value.asset &&
                        value.asset._ &&
                        value.asset._.video &&
                        value.asset._.video.name
                            ? value.asset._.video.name
                            : value.asset &&
                        value.asset &&
                        value.asset._ &&
                        value.asset._.file &&
                        value.asset._.file.name
                }
            </div>,
            <>
                {
                    value['start_at']
                        ? this.props.checked
                            ? moment(value['start_at']).format(' hh:mm A')
                            : moment(value['start_at']).format(' HH:mm ') + 'Hrs'
                        : ''
                }
            </>,
            <>
                {
                    value['end_at']
                        ? this.props.checked
                            ? moment(value['end_at']).format(' hh:mm A')
                            : moment(value['end_at']).format(' HH:mm ') + 'Hrs'
                        : ''
                }
            </>,
            <p
                key={index}
                className="video_duration sarabun_font_weight">{
                    value.file
                        ? moment.utc(moment.duration(value.file.length, 'seconds')
                            .asMilliseconds()).format(this.props.timeFormat)
                        : value.asset &&
                        value.asset._ &&
                        value.asset._.file &&
                        value.asset._.file.length
                            ? moment.utc(moment.duration(value.asset._ && value.asset._.file.length, 'seconds')
                                .asMilliseconds()).format(this.props.timeFormat)
                            : ''}  </p>,
            <p
                key={index}
                className="video_speed sarabun_font_weight">
                {(
                    (value.file
                        ? value.file.size
                        : value.asset && value.asset._ && value.asset._.file.size) / 1000000
                ).toFixed(2)} MB</p>,
            <div key={index}>
                <Button
                    className="add_button"
                    variant="contained"
                    onClick={() => this.handleDelete(value, index)}>
                </Button>
            </div>,
            ]
        );
    }

    handleOpenProgramPopup () {
        const token = localStorage.getItem('authorizationToken');

        this.props.fetchSlotTemplate(token);
        this.props.setSlotPopupShow(true);
    }

    handleOpenSlotPopup (slot) {
        const token = localStorage.getItem('authorizationToken');

        this.props.fetchSlotTemplate(token);
        this.props.setSlotPopupShow(true);
        this.props.setCurrentSlot(slot);
    }

    handleFetch (type, skip, limit, searchText, sortBy, order) {
        const token = localStorage.getItem('authorizationToken');

        this.props.fetchAssetsData(type, token, 'complete', null, null,
            skip,
            limit,
            searchText,
            sortBy,
            order);
    }

    handleScroll (e) {
        const scroll = e.target.scrollTop;
        const scrollHeight = e.target.scrollHeight;
        const height = e.target.clientHeight;

        if ((this.props.assetData.length < this.props.count) && (scrollHeight - scroll <= height + 256) && !this.props.assetDataInProgress) {
            this.handleFetch('video',
                this.props.skip,
                this.props.limit + 10,
                this.props.searchKey,
                this.props.sortBy,
                this.props.order);
        }
    }

    render () {
        const { classes } = this.props;
        const {
            value,
        } = this.state;

        return (
            <>
                <Modal
                    aria-describedby="simple-modal-description"
                    aria-labelledby="simple-modal-title"
                    className="modal_slotpopup"
                    open={this.props.showSlotPopup}
                    onClose={this.handleCloseSlotPopUp}>
                    <div className="slotpopup">
                        <DynamicSlot/>
                    </div>
                </Modal>

                <Modal
                    aria-describedby="simple-modal-description"
                    aria-labelledby="simple-modal-title"
                    className="stream_modal"
                    open={this.state.open}
                    onClose={this.handleClose}>
                    <div
                        className={classes.paper}
                        style={{
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}>
                        {this.modalHeader(value, classes)}
                        {this.modalContent(value)}
                    </div>
                </Modal>
            </>
        );
    }
}

AddProgram.propTypes = {
    addProgram: PropTypes.func.isRequired,
    addProgramInProgress: PropTypes.bool.isRequired,
    addProgramOverlay: PropTypes.func.isRequired,
    addSlot: PropTypes.func.isRequired,
    assetData: PropTypes.array.isRequired,
    assetDataInProgress: PropTypes.bool.isRequired,
    assets: PropTypes.array.isRequired,
    assetsInProgress: PropTypes.bool.isRequired,
    assetsOverView: PropTypes.array.isRequired,
    assetsOverViewInProgress: PropTypes.bool.isRequired,
    channel: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    childFunction: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    clearDynamicInputValues: PropTypes.func.isRequired,
    configuration: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    currentSlot: PropTypes.object.isRequired,
    currentUpdatingSlot: PropTypes.bool.isRequired,
    deleteProgram: PropTypes.func.isRequired,
    deleteProgramInProgress: PropTypes.bool.isRequired,
    deleteProgramOverlay: PropTypes.func.isRequired,
    deleteSlot: PropTypes.func.isRequired,
    fetchAssetsData: PropTypes.func.isRequired,
    fetchAssetsOverviewData: PropTypes.func.isRequired,
    fetchBugs: PropTypes.func.isRequired,
    fetchPrograms: PropTypes.func.isRequired,
    fetchSlotTemplate: PropTypes.func.isRequired,
    fetchSlots: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    order: PropTypes.number.isRequired,
    programs: PropTypes.object.isRequired,
    programsInProgress: PropTypes.bool.isRequired,
    searchKey: PropTypes.string.isRequired,
    setCurrentAsset: PropTypes.func.isRequired,
    setCurrentSlot: PropTypes.func.isRequired,
    setSlotPopupShow: PropTypes.func.isRequired,
    setUpdatedPrograms: PropTypes.func.isRequired,
    showSlotPopup: PropTypes.bool.isRequired,
    showSlotSettingsDialog: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    slots: PropTypes.array.isRequired,
    slotsInProgress: PropTypes.bool.isRequired,
    sortBy: PropTypes.string.isRequired,
    stream: PropTypes.string.isRequired,
    timeFormat: PropTypes.string.isRequired,
    updateProgram: PropTypes.func.isRequired,
    updateProgramInProgress: PropTypes.bool.isRequired,
    selectedDate: PropTypes.instanceOf(Date),
};

const stateToProps = (state) => {
    return {
        assets: state.asset._.list,
        assetData: state.asset.assetData.list,
        assetDataInProgress: state.asset.assetData.inProgress,
        channel: state.live.channel.current,
        configuration: state.live.stream.configuration,
        stream: state.live.stream.current,
        slots: state.live.slot.list,
        currentSlot: state.live.slot.current,
        selectedDate: state.live.selectedDate,
        programs: state.live.program.list,
        programsInProgress: state.live.program.inProgress,
        updateProgramInProgress: state.live.program.updateInProgress,
        deleteProgramInProgress: state.live.program.deleteInProgress,
        addProgramInProgress: state.live.program.addInProgress,
        assetsOverView: state.asset.assetsOverViewData.encoded,
        assetsOverViewInProgress: state.asset.assetsOverViewData.inProgress,
        slotsInProgress: state.live.slot.inProgress,
        assetsInProgress: state.asset._.inProgress,
        timeFormat: state.live.timeFormat,
        showSlotPopup: state.live.showSlotPopup,
        checked: state.profile.timeFormat.checked,
        currentUpdatingSlot: state.live.currentUpdatingSlot,
        skip: state.asset.assetData.skip,
        limit: state.asset.assetData.limit,
        count: state.asset.assetData.count,
        searchKey: state.asset.assetData.searchKey,
        sortBy: state.asset.assetData.sortBy,
        order: state.asset.assetData.order,
    };
};

const actionsToProps = {
    addProgram,
    fetchAssetsData,
    fetchSlots,
    addSlot,
    setCurrentSlot,
    fetchPrograms,
    deleteProgram,
    updateProgram,
    deleteSlot,
    addProgramOverlay,
    deleteProgramOverlay,
    setCurrentAsset,
    setSlotPopupShow,
    clearDynamicInputValues,
    fetchSlotTemplate,
    fetchAssetsOverviewData,
    showSlotSettingsDialog,
    fetchBugs,
    setUpdatedPrograms,
};

export default withStyles(styles)(connect(stateToProps, actionsToProps)(AddProgram));
