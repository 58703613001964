import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideSendTokensDialog } from '../../../../actions/account';
import './index.css';
import { sendTx } from '../../../../helper';
import MaxButton from './MaxButton';
import ToAddressTextField from './ToAddressTextField';
import TokensToSendTextField from './TokensToSendTextField';
import { showMessage } from '../../../../actions/snackbar';

const SendTokensDialog = (props) => {
    const handleSend = () => {
        sendTx(props.accounts[0] && props.accounts[0].address,
            props.toAddress, props.tokensToSend, (error) => {
                if (error) {
                    props.showMessage(error);
                    return;
                }

                props.showMessage('Success! Your transaction has been confirmed');
            });
        props.hide();
    };

    return (
        <Dialog
            aria-describedby="send-tokens-description"
            aria-labelledby="send-tokens-title"
            className="dialog send_tokens_dialog"
            open={props.open}
            onClose={props.hide}>
            <DialogContent className="content">
                <p className="title">Enter details to send tokens</p>
                <form className="form">
                    <ToAddressTextField/>
                    <div className="tokens_row">
                        <TokensToSendTextField/>
                        <MaxButton/>
                    </div>
                </form>
            </DialogContent>
            <DialogActions className="action_buttons">
                <Button
                    className="send_button"
                    onClick={handleSend}>
                    Send Tokens
                </Button>
            </DialogActions>
        </Dialog>
    );
};

SendTokensDialog.propTypes = {
    hide: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    showMessage: PropTypes.func.isRequired,
    toAddress: PropTypes.string.isRequired,
    tokensToSend: PropTypes.string.isRequired,
    accounts: PropTypes.arrayOf(
        PropTypes.shape({
            address: PropTypes.string,
            isPrimary: PropTypes.string,
            status: PropTypes.string,
        }),
    ),
};

const stateToProps = (state) => {
    return {
        accounts: Object.values(state.account.connected.list),
        open: state.account.sendTokensDialog,
        toAddress: state.account.toAddress,
        tokensToSend: state.account.tokensToSend,
    };
};

const actionToProps = {
    hide: hideSendTokensDialog,
    showMessage,
};

export default connect(stateToProps, actionToProps)(SendTokensDialog);
