import { Button } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    fetchPrograms,
    hideUpdateProgramDialog,
    showUpdateProgramDialog,
    updatePrograms,
    setUpdatedPrograms,
} from '../../../../../actions/program';
import addIcon from '../../../../../assets/addYellow.svg';
import Icon from '../../../../../assets/program.png';
import DialogUpdateProgram from '../DialogUpdateProgram';
import Programs from '../Programs';
import './index.css';

class Slots extends Component {
    constructor (props) {
        super(props);
        this.state = {
            programs: [],
            iteration: null,
            currentElement: null,
            iterationCondition: false,
            leftBreakSlot: false,
        };
        this.swapBoxes = this.swapBoxes.bind(this);
        this.DragOver = this.DragOver.bind(this);
        this.DragStart = this.DragStart.bind(this);
        this.Drop = this.Drop.bind(this);
        this.handleProgram = this.handleProgram.bind(this);
        this.hoverEffect = this.hoverEffect.bind(this);
        this.DragIndication = this.DragIndication.bind(this);
    }

    componentDidMount () {
        const img = new Image();
        img.src = Icon;
        img.onload = () => this.setState({ dragImg: img });
        this.setState({
            programs: this.props.program,
        });
    }

    componentDidUpdate (pp, ps, ss) {
        if (pp.program !== this.props.program) {
            this.setState({
                programs: this.props.program,
            });
        }
    }

    swapBoxes (fromBox, toBox) {
        const boxes = this.state.programs.slice();
        let fromIndex = -1;
        let toIndex = -1;

        for (let i = 0; i < boxes.length; i++) {
            if (i === fromBox) {
                fromIndex = i;
            }
            if (i === toBox) {
                toIndex = i;
            }
        }

        if (fromIndex !== -1 && toIndex !== -1) {
            if (fromIndex < toIndex) {
                const { ...swap } = boxes[fromIndex];
                let data = {};
                let startTime = swap.start_at;
                let endTime;
                const program = [];
                for (let i = fromIndex; i < toIndex; i++) {
                    endTime = moment(boxes[i + 1].end_at).diff(moment(boxes[i + 1].start_at));
                    data = {
                        startAt: startTime,
                        endAt: moment(startTime).add(endTime, 'ms').utc().toISOString(),
                        asset: {
                            id: boxes[i + 1].asset._._id,
                            startAt: boxes[i + 1].asset['start_at'],
                            endAt: boxes[i + 1].asset['end_at'],
                        },
                    };
                    program.push(data);
                    startTime = moment(startTime).add(endTime, 'ms').utc().toISOString();
                    boxes[i] = boxes[i + 1];
                }
                endTime = moment(swap.end_at).diff(moment(swap.start_at));
                data = {
                    startAt: startTime,
                    endAt: moment(startTime).add(endTime, 'ms').utc().toISOString(),
                    asset: {
                        id: swap.asset._._id,
                        startAt: swap.asset['start_at'],
                        endAt: swap.asset['end_at'],
                    },
                };
                program.push(data);
                const updatedProgram = { programs: program };
                this.props.updatePrograms(this.props.channel,
                    this.props.stream,
                    this.props.slot._id,
                    updatedProgram, (error) => {
                        if (!error) {
                            const updatedContent = Object.values(this.props.programs).filter((v) => v.slot._id !== this.props.slot._id);
                            this.props.setUpdatedPrograms(updatedContent);
                            this.props.fetchPrograms(this.props.channel,
                                this.props.stream,
                                this.props.slot._id, (cb) => {
                                });
                        }
                    });
                boxes[toIndex] = { ...swap };
            } else if (fromIndex > toIndex) {
                const { ...swap } = boxes[fromIndex];
                let data;
                let temp = boxes[toIndex];
                let startTime = temp.start_at;
                let endTime;
                const program = [];
                endTime = moment(swap.end_at).diff(moment(swap.start_at));
                data = {
                    startAt: startTime,
                    endAt: moment(startTime).add(endTime, 'ms').utc().toISOString(),
                    asset: {
                        id: swap.asset._._id,
                        startAt: swap.asset['start_at'],
                        endAt: swap.asset['end_at'],
                    },
                };
                program.push(data);
                for (let i = toIndex; i < fromIndex; i++) {
                    startTime = moment(startTime).add(endTime, 'ms').utc().toISOString();
                    endTime = moment(temp.end_at).diff(moment(temp.start_at));
                    data = {
                        startAt: startTime,
                        endAt: moment(startTime).add(endTime, 'ms').utc().toISOString(),
                        asset: {
                            id: temp.asset._._id,
                            startAt: temp.asset['start_at'],
                            endAt: temp.asset['end_at'],
                        },
                    };
                    program.push(data);
                    temp = boxes[i + 1];
                }
                const updatedProgram = { programs: program };
                this.props.updatePrograms(this.props.channel,
                    this.props.stream,
                    this.props.slot._id,
                    updatedProgram, (error) => {
                        if (!error) {
                            const updatedContent = Object.values(this.props.programs).filter((v) => v.slot._id !== this.props.slot._id);
                            this.props.setUpdatedPrograms(updatedContent);

                            this.props.fetchPrograms(this.props.channel,
                                this.props.stream,
                                this.props.slot._id, (cb) => {
                                });
                        }
                    });
                for (let i = fromIndex; i > toIndex; i--) {
                    boxes[i] = boxes[i - 1];
                }
                boxes[toIndex] = { ...swap };
            }

            this.setState({ programs: boxes });
        }
    }

    DragStart (event, data) {
        event.currentTarget.classList.add('no_hover');
        const fromBox = JSON.stringify(data);
        event.dataTransfer.setData('dragContent', fromBox);
        event.dataTransfer.setDragImage(this.state.dragImg, 8, 8);
        this.setState({
            startElement: event.currentTarget,
            startData: fromBox,
            data: fromBox,
        });
    }

    DragIndication (event, data, iteration) {
        const boxes = this.state.programs.slice();
        let fromIndex = -1;
        let toIndex = -1;

        for (let i = 0; i < boxes.length; i++) {
            if (i === JSON.parse(this.state.startData)) {
                fromIndex = i;
            }
            if (i === data) {
                toIndex = i;
            }
        }

        if (fromIndex !== -1 && toIndex !== -1) {
            if (fromIndex < toIndex) {
                event.currentTarget.classList.add('right_drag');
            } else if (fromIndex > toIndex) {
                event.currentTarget.classList.add('left_drag');
                if (iteration) {
                    this.setState({
                        leftBreakSlot: true,
                    });
                }
            }
        }

        this.setState({
            data: data,
        });
    }

    DragOver (event, data, iteration) {
        event.preventDefault();
        if (this.state.data !== data) {
            this.DragIndication(event, data);
        }
        if (this.state.currentElement !== event.currentTarget) {
            if (iteration && event.currentTarget.classList[0] === 'break_slot') {
                this.DragIndication(event, data, iteration);
                this.setState({
                    iteration: iteration,
                });
            } else if (this.state.iteration !== null) {
                this.setState({
                    iteration: null,
                    leftBreakSlot: false,
                });
            }
            event.currentTarget.classList.add('drag_class');
            if (this.state.currentElement) {
                this.state.currentElement.classList.remove('drag_class', 'right_drag', 'left_drag');
            }
            this.setState({
                currentElement: event.currentTarget,
                iterationCondition: true,
            });
        } else if (iteration === null && this.state.iterationCondition) {
            event.currentTarget.classList.add('drag_class');
            this.DragIndication(event, data);
            this.setState({
                iterationCondition: false,
            });
        }
        return false;
    }

    Drop (event, data) {
        this.state.startElement.classList.remove('no_hover');
        if (this.state.currentElement) {
            this.state.currentElement.classList.remove('drag_class', 'right_drag', 'left_drag');
        }
        if (this.state.iteration !== null) {
            this.setState({
                iteration: null,
            });
        }
        const fromBox = JSON.parse(event.dataTransfer.getData('dragContent'));

        this.swapBoxes(fromBox, data);
        return false;
    }

    hoverEffect (i) {
        this.setState({
            iteration: i,
        });
    }

    handleProgram (value) {
        let total = 0;
        return (
            <div className="slots_section" onDragOver={this.dragOver}>
                {value.map((val, index) => {
                    total = total + (val.asset._ && val.asset._.file.length);
                    const per = (val.asset._ && val.asset._.file.length) / 60;

                    return (
                        <Programs
                            key={index}
                            draggable={true}
                            handleEditProgram={this.props.updateSelectedProgram}
                            index={index}
                            individualSlot={this.props.individualSlot}
                            percentage={per}
                            program={val}
                            slot={this.props.slot}
                            slotList={value}
                            stream={this.props.stream}
                            value={val.asset._ && val.asset._.file}
                            onDragOver={this.DragOver}
                            onDragStart={this.DragStart}
                            onDrop={this.Drop}/>
                    );
                })}
                {!(this.props.slot['end_at'] &&
                    moment(this.props.slot['end_at']).isBefore(moment(), 'second'))
                    ? <div className="add_program">
                        <Button onClick={() => this.props.onClick(this.props.slot)}>
                            <img alt="add" src={addIcon}/>
                        </Button>
                    </div>
                    : null
                }
            </div>
        );
    }

    render () {
        return (
            <div
                key={this.props.slot && this.props.slot._id}
                className="slot_div">
                {this.handleProgram(this.state.programs)}
                <DialogUpdateProgram/>
            </div>
        );
    }
}

Slots.propTypes = {
    channel: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    fetchPrograms: PropTypes.func.isRequired,
    program: PropTypes.object.isRequired,
    programs: PropTypes.object.isRequired,
    setUpdatedPrograms: PropTypes.func.isRequired,
    slot: PropTypes.object.isRequired,
    slots: PropTypes.array.isRequired,
    stream: PropTypes.string.isRequired,
    timeFormat: PropTypes.string.isRequired,
    updateProgram: PropTypes.func.isRequired,
    updatePrograms: PropTypes.func.isRequired,
    individualSlot: PropTypes.bool,
    updateSelectedProgram: PropTypes.func,
    onClick: PropTypes.func,
};

const stateToProps = (state) => {
    return {
        timeFormat: state.live.timeFormat,
        checked: state.profile.timeFormat.checked,
        slots: state.live.slot.list,
        programs: state.live.program.list,
    };
};

const actionsToProps = {
    updatePrograms,
    fetchPrograms,
    onUpdateProgramHide: hideUpdateProgramDialog,
    updateSelectedProgram: showUpdateProgramDialog,
    setUpdatedPrograms,
};

export default connect(stateToProps, actionsToProps)(Slots);
