import React from 'react';
import keplr from '../../../assets/logos/keplr.png';
import ConnectButton from './ConnectButton';
import './index.css';
import ConnectDialog from './ConnectDialog';

const ConnectAccount = () => {
    return (
        <div className="connect_account">
            <img alt="keplr" src={keplr}/>
            <p className="info">Connect your account with Keplr</p>
            <ConnectButton/>
            <ConnectDialog/>
        </div>
    );
};

export default ConnectAccount;
