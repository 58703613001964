import { Button, Tooltip, Typography, withStyles } from '@material-ui/core';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../components/Icon';

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#232323',
        color: '#fff',
        boxShadow: theme.shadows[1],
        margin: 'unset',
        padding: '0',
    },
}))(Tooltip);

const TooltipTabs = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTabClick = (route, event) => {
        props.handleClick(route, event);
        handleClose();
    };

    const id = open ? 'side-tabs-tooltip' : undefined;

    return (
        <CustomTooltip
            interactive
            id={id}
            open={open}
            placement="right-start"
            title={<div className="tooltip_div">
                {props.list && props.list.map((route, index) => (
                    <Typography
                        key={index}
                        className={ClassNames('tab', props.pathName === route.url ? 'active_tab' : '')}>
                        <Button onClick={(event) => handleTabClick(route, event)}>
                            <Icon
                                className={route.icon}
                                icon={route.icon}/>
                            <p className="tab_label">{route.label}</p>
                            {props.overViewData && props.overViewData.length > 0 &&
                            props.overViewData.map((value, ind) => {
                                if (value.type === route.key) {
                                    return (
                                        <p
                                            key={ind}
                                            className="hover_side_bar label"
                                            style={{ margin: '0 0 0 10px' }}>
                                            ({value.count})
                                        </p>
                                    );
                                }
                            })}
                        </Button>
                    </Typography>
                ))}
            </div>}
            onClose={handleClose}
            onOpen={handleClick}>
            {props.children}
        </CustomTooltip>
    );
};

TooltipTabs.propTypes = {
    children: PropTypes.any.isRequired,
    handleClick: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    overViewData: PropTypes.array.isRequired,
    pathName: PropTypes.string.isRequired,
};

export default TooltipTabs;
