import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSlotTime } from '../../../../../actions/slot';
import SelectField from '../../../../../components/SelectField';

class SlotSelectField extends Component {
    constructor (props) {
        super(props);
        this.state = {
            options: [],
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        const slotLength = this.props.configuration['slot_length']
            ? this.props.configuration['slot_length'] / 3600
            : 3;
        let time = null;
        const options = [];
        if (this.props.data && this.props.data.slot && this.props.data.slot.start_at) {
            const currentSlot = moment(this.props.data.slot.start_at).local().format();
            time = moment(currentSlot).hour();
        }

        for (let a = 0; a < 24; a = a + slotLength) {
            let from = a;
            let to = a + slotLength;
            if (a > 12) {
                from = a - 12;
            }
            if (to > 12) {
                to = to - 12;
            }
            if (from === 0 || from === 24) {
                options.push({
                    name: `12:00:00 - ${to}:00:00`,
                    value: `0 - ${a + slotLength}`,
                });
            } else if (to === 0 || to === 24) {
                options.push({
                    name: `${from}:00:00 - 12:00:00`,
                    value: `${a} - 0`,
                });
            } else {
                options.push({
                    name: `${from}:00:00 - ${to}:00:00`,
                    value: `${a} - ${a + slotLength}`,
                });
            }

            if (a <= time || time >= a + slotLength) {
                this.props.onChange(`${a === 24 ? 0 : a} - ${(a + slotLength) === 24 ? 0 : (a + slotLength)}`);
            }
        }

        this.setState({
            options: [...options],
        });
    }

    handleChange (value) {
        this.props.handleChange(value);
        this.props.onChange(value);
    }

    render () {
        return (
            <SelectField
                id="slot_select_field"
                items={this.state.options}
                name="slot_select"
                placeholder="Select Slot"
                value={this.props.value}
                onChange={this.handleChange}/>
        );
    }
}

SlotSelectField.propTypes = {
    configuration: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        configuration: state.live.stream.configuration,
        data: state.live.individualSlot.data,
        value: state.live.individualSlot.time,
    };
};

const actionToProps = {
    onChange: setSlotTime,
};

export default connect(stateToProps, actionToProps)(SlotSelectField);
