import { CURRENT_OPINION_SET } from '../constants/interactive';
import { combineReducers } from 'redux';

const opinionsList = [{
    name: 'type1',
    startTime: 7,
    question: 'Question-1',
    options: [{
        name: 'Manage Assets',
        gotoValue: 20,
    }, {
        name: 'Program Assets',
        gotoValue: 67,
    }],
},
{
    name: 'type2',
    startTime: 72,
    question: 'Question-2',
    options: [{
        name: 'Schedule Manually',
        gotoValue: 85,
    },
    {
        name: 'Schedule Dynamically',
        gotoValue: 103,
    }],
}];

export const currentOpinion = (state = 0, action) => {
    if (action.type === CURRENT_OPINION_SET) {
        return action.value;
    }

    return state;
};

export const opinions = (state = opinionsList) => {
    return state;
};

export const loopTime = (state = 10) => {
    return state;
};

export default combineReducers({
    currentOpinion,
    opinions,
    loopTime,
});
