import { combineReducers } from 'redux';
import { DOWNLOAD_CANCEL_SET } from '../../constants/asset';
import { DOWNLOAD_AUDIO_FILE_CANCEL_SET, DOWNLOAD_AUDIO_FILE_DETAILS_SET } from '../../constants/audioAssets';

const downloadFile = (state = {
    details: {},
    index: 0,
    list: [],
    cancel: () => {
    },
}, action) => {
    switch (action.type) {
    case DOWNLOAD_AUDIO_FILE_DETAILS_SET:
        return {
            ...state,
            details: action.value,
            index: action.index,
            list: action.list,
        };
    case DOWNLOAD_CANCEL_SET:
        return {
            ...state,
            cancel: action.cancel,
        };
    case DOWNLOAD_AUDIO_FILE_CANCEL_SET:
        return {
            ...state,
            list: action.list,
        };
    default:
        return state;
    }
};

export default combineReducers({
    downloadFile,
});
