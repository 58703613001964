import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { clearPrograms, setUpdatedPrograms } from '../../../../actions/program';

const ClearSlotButton = (props) => {
    const handleClick = () => {
        props.clearPrograms(props.slot.channel,
            props.slot['live_stream'],
            props.slot._id, (error) => {
                if (!error) {
                    const updatedContent = Object.values(props.programs).filter((v) => v.slot._id !== props.slot._id);
                    props.setUpdatedPrograms(updatedContent);
                }
            });
    };

    const programs = Object.values(props.programs).filter((v) => v.slot._id === props.slot._id);
    const disable = programs.length === 0 || props.inProgress;

    return (
        <Button
            className="add_new clear_slot"
            disabled={disable}
            onClick={handleClick}>
            Clear Slot
        </Button>
    );
};

ClearSlotButton.propTypes = {
    clearPrograms: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    programs: PropTypes.object.isRequired,
    setUpdatedPrograms: PropTypes.func.isRequired,
    slot: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        programs: state.live.program.list,
        inProgress: state.live.program.deleteInProgress,
    };
};

const actionToProps = {
    clearPrograms,
    setUpdatedPrograms,
};

export default connect(stateToProps, actionToProps)(ClearSlotButton);
