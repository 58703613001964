import * as PropTypes from 'prop-types';
import React from 'react';

const VideoPlayer = (props) => {
    return (
        <div>
            <iframe
                allowFullScreen
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                id="video_player"
                src={props.url}
                style={{
                    width: '100%',
                    height: '100%',
                }}
                title="Video Player">
            </iframe>
        </div>
    );
};

VideoPlayer.propTypes = {
    url: PropTypes.string.isRequired,
};

export default VideoPlayer;
