export const QUICK_VIEW_MOVIE_ASSET_SHOW = 'QUICK_VIEW_MOVIE_ASSET_SHOW';
export const QUICK_VIEW_MOVIE_ASSET_HIDE = 'QUICK_VIEW_MOVIE_ASSET_HIDE';

export const POPOVER_FILTER_SHOW = 'POPOVER_FILTER_SHOW';
export const POPOVER_FILTER_HIDE = 'POPOVER_FILTER_HIDE';
export const CHANGED_FILTER = 'CHANGED_FILTER';

export const DIALOG_ADD_ASSET_SHOW = 'DIALOG_ADD_ASSET_SHOW';
export const DIALOG_ADD_ASSET_HIDE = 'DIALOG_ADD_ASSET_HIDE';

export const DIALOG_EDIT_ASSET_SHOW = 'DIALOG_EDIT_ASSET_SHOW';
export const DIALOG_EDIT_ASSET_HIDE = 'DIALOG_EDIT_ASSET_HIDE';

export const DIALOG_UPDATE_ASSET_SHOW = 'DIALOG_UPDATE_ASSET_SHOW';
export const DIALOG_UPDATE_ASSET_HIDE = 'DIALOG_UPDATE_ASSET_HIDE';

export const TABLE_ROW_DATA = 'TABLE_ROW_DATA';
export const QUICK_VIEW_ASSET_DATA = 'QUICK_VIEW_ASSET_DATA';

export const ASSET_ADD_SUCCESS = 'ASSET_ADD_SUCCESS';
export const ASSET_ADD_IN_PROGRESS = 'ASSET_ADD_IN_PROGRESS';
export const ASSET_ADD_ERROR = 'ASSET_ADD_ERROR';
export const ASSET_ADD_SUCCESS_MESSAGE = 'ASSET_ADD_SUCCESS_MESSAGE';

export const ASSET_FILE_ENCODE_IN_PROGRESS = 'ASSET_FILE_ENCODE_IN_PROGRESS';
export const ASSET_FILE_ENCODE_SUCCESS = 'ASSET_FILE_ENCODE_SUCCESS';
export const ASSET_FILE_ENCODE_ERROR = 'ASSET_FILE_ENCODE_ERROR';
export const ASSET_FILE_ENCODE_SUCCESS_MESSAGE = 'ASSET_FILE_ENCODE_SUCCESS_MESSAGE';

export const ASSET_GENRES_FETCH_IN_PROGRESS = 'ASSET_GENRES_FETCH_IN_PROGRESS';
export const ASSET_GENRES_FETCH_SUCCESS = 'ASSET_GENRES_FETCH_SUCCESS';
export const ASSET_GENRES_FETCH_ERROR = 'ASSET_GENRES_FETCH_ERROR';

export const ASSET_SOURCE_TYPE_FETCH_IN_PROGRESS = 'ASSET_SOURCE_TYPE_FETCH_IN_PROGRESS';
export const ASSET_SOURCE_TYPE_FETCH_SUCCESS = 'ASSET_SOURCE_TYPE_FETCH_SUCCESS';

export const ASSET_CATEGORIES_FETCH_IN_PROGRESS = 'ASSET_CATEGORIES_FETCH_IN_PROGRESS';
export const ASSET_CATEGORIES_FETCH_SUCCESS = 'ASSET_CATEGORIES_FETCH_SUCCESS';
export const ASSET_CATEGORIES_FETCH_ERROR = 'ASSET_CATEGORIES_FETCH_ERROR';

export const ADD_ASSET_ID_SET = 'ADD_ASSET_ID_SET';
export const ADD_ASSET_NAME_SET = 'ADD_ASSET_NAME_SET';
export const ADD_ASSET_FILE_NAME_SET = 'ADD_ASSET_FILE_NAME_SET';
export const ADD_ASSET_CATEGORY_SET = 'ADD_ASSET_CATEGORY_SET';
export const ADD_ASSET_GENRE_SET = 'ADD_ASSET_GENRE_SET';
export const ADD_ASSET_FILE_TYPE_SET = 'ADD_ASSET_FILE_TYPE_SET';
export const ADD_ASSET_DURATION_SET = 'ADD_ASSET_DURATION_SET';
export const ADD_ASSET_SOURCE_SET = 'ADD_ASSET_SOURCE_SET';
export const ADD_ASSET_DATE_OF_UPLOAD_SET = 'ADD_ASSET_DATE_OF_UPLOAD_SET';
export const ADD_ASSET_ENCODED_STATE_SET = 'ADD_ASSET_ENCODED_STATE_SET';
export const ADD_ASSET_PUBLISHED_FOR_VOD_SET = 'ADD_ASSET_PUBLISHED_FOR_VOD_SET';
export const ADD_ASSET_DATE_PUBLISHED_SET = 'ADD_ASSET_DATE_PUBLISHED_SET';
export const ADD_ASSET_LAST_RUN_SET = 'ADD_ASSET_LAST_RUN_SET';
export const ADD_SOURCE_TYPE_SET = 'ADD_SOURCE_TYPE_SET';
export const ADD_SOURCE_URL_SET = 'ADD_SOURCE_URL_SET';
export const ADD_FILE_SET = 'ADD_FILE_SET';
export const ADD_ASSET_ALBUM_SET = 'ADD_ASSET_ALBUM_SET';

export const ASSET_CATEGORY_TABLE_LIST = 'ASSET_CATEGORY_TABLE_LIST';
export const ASSET_CATEGORY_TABLE_ERROR = 'ASSET_CATEGORY_TABLE_ERROR';

export const YOUTUBE_FILES_FETCH_SUCCESS = 'YOUTUBE_FILES_FETCH_SUCCESS';

export const ASSET_FILE_ADD_ERROR = 'ASSET_FILE_ADD_ERROR';
export const ASSET_FILE_ADD_SUCCESS = 'ASSET_FILE_ADD_SUCCESS';
export const ASSET_FILE_ADD_IN_PROGRESS = 'ASSET_FILE_ADD_IN_PROGRESS';
export const ASSET_FILE_ADD_SUCCESS_MESSAGE = 'ASSET_FILE_ADD_SUCCESS_MESSAGE';

export const ASSET_UPDATE_IN_PROGRESS = 'ASSET_UPDATE_IN_PROGRESS';
export const ASSET_UPDATE_SUCCESS = 'ASSET_UPDATE_SUCCESS';
export const ASSET_UPDATE_SUCCESS_MESSAGE = 'ASSET_UPDATE_SUCCESS_MESSAGE';
export const ASSET_UPDATE_ERROR = 'ASSET_UPDATE_ERROR';

export const DIALOG_ADD_ASSET_CATEGORY_SHOW = 'DIALOG_ADD_ASSET_CATEGORY_SHOW';
export const DIALOG_ADD_ASSET_CATEGORY_HIDE = 'DIALOG_ADD_ASSET_CATEGORY_HIDE';
export const DIALOG_ADD_GENRE_SHOW = 'DIALOG_ADD_GENRE_SHOW';
export const DIALOG_ADD_GENRE_HIDE = 'DIALOG_ADD_GENRE_HIDE';
export const DIALOG_CONFIRM_GENRE_SHOW = 'DIALOG_CONFIRM_GENRE_SHOW';
export const DIALOG_CONFIRM_GENRE_HIDE = 'DIALOG_CONFIRM_GENRE_HIDE';
export const DIALOG_ADD_VIDEO_SHOW = 'DIALOG_ADD_VIDEO_SHOW';
export const DIALOG_ADD_VIDEO_HIDE = 'DIALOG_ADD_VIDEO_HIDE';

export const ADD_GENRE_NEW_LABEL = 'ADD_GENRE_NEW_LABEL';
export const NEW_GENRE_ADD_IN_PROGRESS = 'NEW_GENRE_ADD_IN_PROGRESS';
export const NEW_GENRE_ADD_SUCCESS = 'NEW_GENRE_ADD_SUCCESS';
export const NEW_GENRE_ADD_SUCCESS_MESSAGE = 'NEW_GENRE_ADD_SUCCESS_MESSAGE';
export const NEW_GENRE_ADD_ERROR = 'NEW_GENRE_ADD_ERROR';

export const EDITABLE_ASSET_DATA = 'EDITABLE_ASSET_DATA';
export const EDITABLE_ASSET_VIDEO_DATA = 'EDITABLE_ASSET_VIDEO_DATA';
export const EDITABLE_ASSET_AUDIO_DATA = 'EDITABLE_ASSET_AUDIO_DATA';
export const EDITABLE_ASSET_IMAGE_DATA = 'EDITABLE_ASSET_IMAGE_DATA';
export const EDITABLE_ASSET_TEXT_DATA = 'EDITABLE_ASSET_TEXT_DATA';

export const GENRE_DELETE_IN_PROGRESS = 'GENRE_DELETE_IN_PROGRESS';
export const GENRE_DELETE_SUCCESS = 'GENRE_DELETE_SUCCESS';
export const GENRE_DELETE_SUCCESS_MESSAGE = 'GENRE_DELETE_SUCCESS_MESSAGE';
export const GENRE_DELETE_ERROR = 'GENRE_DELETE_ERROR';

export const GENRE_UPDATE_IN_PROGRESS = 'GENRE_UPDATE_IN_PROGRESS';
export const GENRE_UPDATE_SUCCESS = 'GENRE_UPDATE_SUCCESS';
export const GENRE_UPDATE_SUCCESS_MESSAGE = 'GENRE_UPDATE_SUCCESS_MESSAGE';
export const GENRE_UPDATE_ERROR = 'GENRE_UPDATE_ERROR';

export const DIALOG_CONFIRM_SHOW = 'DIALOG_CONFIRM_SHOW';
export const DIALOG_CONFIRM_HIDE = 'DIALOG_CONFIRM_HIDE';

export const ASSET_CATEGORIES_LIST = 'ASSET_CATEGORIES_LIST';
export const ASSET_CATEGORIES_LIST_ENCODED = 'ASSET_CATEGORIES_LIST_ENCODED';
export const ASSET_CATEGORIES_ERROR = 'ASSET_CATEGORIES_ERROR';
export const ADD_ASSET_CATEGORY_NEW_LABEL = 'ADD_ASSET_CATEGORY_NEW_LABEL';
export const NEW_ASSET_CATEGORY_ADD_SUCCESS = 'NEW_ASSET_CATEGORY_ADD_SUCCESS';
export const NEW_ASSET_CATEGORY_ADD_ERROR = 'NEW_ASSET_CATEGORY_ADD_ERROR';
export const NEW_ASSET_CATEGORY_ADD_IN_PROGRESS = 'NEW_ASSET_CATEGORY_ADD_IN_PROGRESS';
export const NEW_ASSET_CATEGORY_ADD_SUCCESS_MESSAGE = 'NEW_ASSET_CATEGORY_ADD_SUCCESS_MESSAGE';

export const ASSET_CATEGORY_DELETE_IN_PROGRESS = 'ASSET_CATEGORY_DELETE_IN_PROGRESS';
export const ASSET_CATEGORY_DELETE_ERROR = 'ASSET_CATEGORY_DELETE_ERROR';
export const ASSET_CATEGORY_DELETE_SUCCESS = 'ASSET_CATEGORY_DELETE_SUCCESS';
export const ASSET_CATEGORY_DELETE_SUCCESS_MESSAGE = 'ASSET_CATEGORY_DELETE_SUCCESS_MESSAGE';

export const ASSET_CATEGORY_UPDATE_IN_PROGRESS = 'ASSET_CATEGORY_UPDATE_IN_PROGRESS';
export const ASSET_CATEGORY_UPDATE_SUCCESS = 'ASSET_CATEGORY_UPDATE_SUCCESS';
export const ASSET_CATEGORY_UPDATE_SUCCESS_MESSAGE = 'ASSET_CATEGORY_UPDATE_SUCCESS_MESSAGE';
export const ASSET_CATEGORY_UPDATE_ERROR = 'ASSET_CATEGORY_UPDATE_ERROR';

export const ASSET_FILES_LIST = 'ASSET_FILES_LIST';
export const ASSET_FILES_ERROR = 'ASSET_FILES_ERROR';
export const ASSET_FILES_IN_PROGRESS = 'ASSET_FILES_IN_PROGRESS';

export const ASSETS_FETCH_IN_PROGRESS = 'ASSETS_FETCH_IN_PROGRESS';
export const ASSETS_FETCH_ERROR = 'ASSETS_FETCH_ERROR';
export const ASSETS_FETCH_SUCCESS = 'ASSETS_FETCH_SUCCESS';

export const ASSETS_DATA_FETCH_IN_PROGRESS = 'ASSETS_DATA_FETCH_IN_PROGRESS';
export const ASSETS_DATA_FETCH_ERROR = 'ASSETS_DATA_FETCH_ERROR';
export const ASSETS_DATA_FETCH_SUCCESS = 'ASSETS_DATA_FETCH_SUCCESS';

export const ASSETS_OVERVIEW_DATA_FETCH_IN_PROGRESS = 'ASSETS_OVERVIEW_DATA_FETCH_IN_PROGRESS';
export const ASSETS_OVERVIEW_DATA_FETCH_ERROR = 'ASSETS_OVERVIEW_DATA_FETCH_ERROR';
export const ASSETS_OVERVIEW_DATA_FETCH_SUCCESS = 'ASSETS_OVERVIEW_DATA_FETCH_SUCCESS';
export const ENCODED_ASSETS_OVERVIEW_DATA_FETCH_SUCCESS = 'ENCODED_ASSETS_OVERVIEW_DATA_FETCH_SUCCESS';

export const CURRENT_ASSET_SET = 'CURRENT_ASSET_SET';

export const DIALOG_ACTION_MENU_SHOW = 'DIALOG_ACTION_MENU_SHOW';
export const DIALOG_ACTION_MENU_HIDE = 'DIALOG_ACTION_MENU_HIDE';

export const ASSET_DELETE_IN_PROGRESS = 'ASSET_DELETE_IN_PROGRESS';
export const ASSET_DELETE_SUCCESS = 'ASSET_DELETE_SUCCESS';
export const ASSET_DELETE_SUCCESS_MESSAGE = 'ASSET_DELETE_SUCCESS_MESSAGE';
export const ASSET_DELETE_ERROR = 'ASSET_DELETE_ERROR';

export const ASSETS_CATEGORIES_FETCH_ERROR = 'ASSETS_CATEGORIES_FETCH_ERROR';
export const ASSETS_CATEGORIES_FETCH_IN_PROGRESS = 'ASSETS_CATEGORIES_FETCH_IN_PROGRESS';
export const ASSETS_CATEGORIES_FETCH_SUCCESS = 'ASSETS_CATEGORIES_FETCH_SUCCESS';

export const ASSETS_LANGUAGES_FETCH_ERROR = 'ASSETS_LANGUAGES_FETCH_ERROR';
export const ASSETS_LANGUAGES_FETCH_IN_PROGRESS = 'ASSETS_LANGUAGES_FETCH_IN_PROGRESS';
export const ASSETS_LANGUAGES_FETCH_SUCCESS = 'ASSETS_LANGUAGES_FETCH_SUCCESS';

export const ADD_ASSET_LANGUAGE_SET = 'ADD_ASSET_LANGUAGE_SET';
export const ADD_ASSET_TAGS_SET = 'ADD_ASSET_TAGS_SET';

export const ASSET_TAGS_FETCH_IN_PROGRESS = 'ASSET_TAGS_FETCH_IN_PROGRESS';
export const ASSET_TAGS_FETCH_SUCCESS = 'ASSET_TAGS_FETCH_IN_SUCCESS';
export const ASSET_TAGS_FETCH_ERROR = 'ASSET_TAGS_FETCH_ERROR';

export const SHOW_PREVIEW_SET = 'SHOW_PREVIEW_SET';

export const UPLOAD_FILE_SET = 'UPLOAD_FILE_SET';

export const FILE_UPLOAD_IN_PROGRESS = 'FILE_UPLOAD_IN_PROGRESS';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';

export const FILE_DOWNLOAD_IN_PROGRESS = 'FILE_DOWNLOAD_IN_PROGRESS';
export const FILE_DOWNLOAD_SUCCESS = 'FILE_DOWNLOAD_SUCCESS';
export const FILE_DOWNLOAD_ERROR = 'FILE_DOWNLOAD_ERROR';

export const UPLOAD_PROGRESS_SET = 'UPLOAD_PROGRESS_SET';
export const DOWNLOAD_PROGRESS_SET = 'DOWNLOAD_PROGRESS_SET';

export const RECENT_ASSETS_FROM_DAYS = 10;

export const TAGS_LIST_FETCH_IN_PROGRESS = 'TAGS_LIST_FETCH_IN_PROGRESS';
export const TAGS_LIST_FETCH_SUCCESS = 'TAGS_LIST_FETCH_SUCCESS';
export const TAGS_LIST_FETCH_ERROR = 'TAGS_LIST_FETCH_ERROR';

export const DOWNLOAD_CANCEL_SET = 'DOWNLOAD_CANCEL_SET';
export const UPLOAD_CANCEL_SET = 'UPLOAD_CANCEL_SET';

export const TRACK_NAME_SET = 'TRACK_NAME_SET';
export const OWNERSHIP_STATUS_SET = 'OWNERSHIP_STATUS_SET';
export const OWNERSHIP_STATUS_SELECT_SET = 'OWNERSHIP_STATUS_SELECT_SET';
export const ALBUM_SET = 'ALBUM_SET';
export const IS_RC_CODE_SET = 'IS_RC_CODE_SET';
export const RECORD_LABEL_SET = 'RECORD_LABEL_SET';
export const MUSIC_DIRECTORS_SET = 'MUSIC_DIRECTORS_SET';
export const MUSIC_COMPOSERS_SET = 'MUSIC_COMPOSERS_SET';
export const SINGERS_SET = 'SINGERS_SET';
export const LYRICISTS_SET = 'LYRICISTS_SET';
export const MOOD_SET = 'MOOD_SET';

export const COUNTRY_SET = 'COUNTRY_SET';
export const RATING_SET = 'RATING_SET';
export const RELEASE_DATE_SET = 'RELEASE_DATE_SET';
export const DIRECTOR_SET = 'DIRECTOR_SET';
export const PRODUCER_SET = 'PRODUCER_SET';
export const CINEMATOGRAPHY_SET = 'CINEMATOGRAPHY_SET';
export const DURATION_SET = 'DURATION_SET';
export const CAST_CREW_SET = 'CAST_CREW_SET';
export const ART_DIRECTOR = 'ART_DIRECTOR';
export const STYLE_COSTUME_DESIGNER = 'STYLE_COSTUME_DESIGNER';

export const ALBUM_LIST_SUCCESS = 'ALBUM_LIST_SUCCESS';
export const ALBUM_LIST_IN_PROGRESS = 'ALBUM_LIST_IN_PROGRESS';

export const DIALOG_ADD_ALBUM_SHOW = 'DIALOG_ADD_ALBUM_SHOW';
export const DIALOG_ADD_ALBUM_HIDE = 'DIALOG_ADD_ALBUM_HIDE';

export const DIALOG_ADD_PEOPLE_SHOW = 'DIALOG_ADD_PEOPLE_SHOW';
export const DIALOG_ADD_PEOPLE_HIDE = 'DIALOG_ADD_PEOPLE_HIDE';

export const ASSET_SCENES_FETCH_SUCCESS = 'ASSET_SCENES_FETCH_SUCCESS';
export const ASSET_SCENES_FETCH_IN_PROGRESS = 'ASSET_SCENES_FETCH_IN_PROGRESS';
export const ASSET_SCENES_FETCH_ERROR = 'ASSET_SCENES_FETCH_ERROR';

export const ASSET_FETCH_IN_PROGRESS = 'ASSET_FETCH_IN_PROGRESS';
export const ASSET_FETCH_ERROR = 'ASSET_FETCH_ERROR';
export const ASSET_FETCH_SUCCESS = 'ASSET_FETCH_SUCCESS';

export const ASSET_NFT_UPDATE_IN_PROGRESS = 'ASSET_NFT_UPDATE_IN_PROGRESS';
export const ASSET_NFT_UPDATE_ERROR = 'ASSET_NFT_UPDATE_ERROR';
export const ASSET_NFT_UPDATE_SUCCESS = 'ASSET_NFT_UPDATE_SUCCESS';

export const INSTAGRAM_ID_SET = 'INSTAGRAM_ID_SET';
export const YOUTUBE_ID_SET = 'YOUTUBE_ID_SET';
export const TWITTER_ID_SET = 'TWITTER_ID_SET';
export const MEDIA_NFT_ID_SET = 'MEDIA_NFT_ID_SET';
export const STORY_SET = 'STORY_SET';
export const DIALOGUES_SET = 'DIALOGUES_SET';
export const COMPUTER_GRAPHICS_SET = 'COMPUTER_GRAPHICS_SET';
export const CAST_SET = 'CAST_SET';
export const CREW_SET = 'CREW_SET';
export const STYLIST_SET = 'STYLIST_SET';
export const EDITOR_SET = 'EDITOR_SET';

export const TAB_VALUE_SET = 'TAB_VALUE_SET';

export const DIALOG_DELETE_THUMB_SHOW = 'DIALOG_DELETE_THUMB_SHOW';
export const DIALOG_DELETE_THUMB_HIDE = 'DIALOG_DELETE_THUMB_HIDE';

export const DIALOG_UPDATE_THUMB_SHOW = 'DIALOG_UPDATE_THUMB_SHOW';
export const DIALOG_UPDATE_THUMB_HIDE = 'DIALOG_UPDATE_THUMB_HIDE';

export const DIALOG_VIEW_THUMB_SHOW = 'DIALOG_VIEW_THUMB_SHOW';
export const DIALOG_VIEW_THUMB_HIDE = 'DIALOG_VIEW_THUMB_HIDE';

export const DIALOG_UPLOAD_THUMB_SHOW = 'DIALOG_UPLOAD_THUMB_SHOW';
export const DIALOG_UPLOAD_THUMB_HIDE = 'DIALOG_UPLOAD_THUMB_HIDE';

export const UPLOADED_THUMBS_LIST = 'UPLOADED_THUMBS_LIST';

export const UPLOAD_ASSET_THUMBNAIL_SUCCESS = 'UPLOAD_ASSET_THUMBNAIL_SUCCESS';
export const UPLOAD_ASSET_THUMBNAIL_ERROR = 'UPLOAD_ASSET_THUMBNAIL_ERROR';
export const UPLOAD_ASSET_THUMBNAIL_IN_PROGRESS = 'UPLOAD_ASSET_THUMBNAIL_IN_PROGRESS';

export const DELETE_ASSET_THUMBNAIL_SUCCESS = 'DELETE_ASSET_THUMBNAIL_SUCCESS';
export const DELETE_ASSET_THUMBNAIL_ERROR = 'DELETE_ASSET_THUMBNAIL_ERROR';
export const DELETE_ASSET_THUMBNAIL_IN_PROGRESS = 'DELETE_ASSET_THUMBNAIL_IN_PROGRESS';
