import { Button, CircularProgress, IconButton, Popover } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setDownloadCancel, setUploadCancel } from '../../../actions/videoAssets';
import { setDownloadCancel as setAudioDownloadCancel } from '../../../actions/audioAssets';
import audioIcon from '../../../assets/audio.svg';
import videoIcon from '../../../assets/videoIcon.svg';
import Icon from '../../../components/Icon';
import './index.css';
import StatusIcon from './StatusIcon';

const DownloadStatus = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const downloading = (props.downloadInProgress &&
        props.downloadProgress.total &&
        props.downloadProgress.loaded) ||
        (props.uploadInProgress &&
            props.uploadProgress.total &&
            props.uploadProgress.loaded) ||
        (props.downloadList &&
            props.downloadList.length > 0) ||
        (props.audioDownloadList &&
            props.audioDownloadList.length > 0) ||
        (props.uploadList &&
            props.uploadList.length > 0);

    const handleCancelDownload = () => {
        const downloadedList = [...props.downloadList];
        downloadedList.pop();
        const object = { ...props.details };
        object.cancel = true;
        downloadedList.push({ ...object });

        props.setDownloadCancel([...downloadedList]);
        props.downloadFileCancel();
    };

    const handleCancelAudioDownload = () => {
        const downloadedList = [...props.audioDownloadList];
        downloadedList.pop();
        const object = { ...props.audioDetails };
        object.cancel = true;
        downloadedList.push({ ...object });

        props.setAudioDownloadCancel([...downloadedList]);
        props.downloadFileCancel();
    };

    const handleCancelUpload = () => {
        const uploadList = [...props.uploadList];
        uploadList.pop();
        const object = { ...props.uploadDetails };
        object.cancel = true;
        uploadList.push({ ...object });

        props.setUploadCancel([...uploadList]);
        props.uploadFileCancel();
    };

    return (
        <>
            {downloading
                ? <IconButton
                    aria-label="delete"
                    className="download_status"
                    size="small"
                    onClick={handleClick}>
                    <StatusIcon color={downloading ? '#FAC01E' : '#C4C4C4'}/>
                </IconButton>
                : null}
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                id="simple-popper"
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
            >
                <div className="download_bar">
                    <p className="header">Upload and Download Files</p>
                    {props.downloadList &&
                    props.downloadList.length > 0
                        ? props.downloadList.map((value, index) => {
                            if ((props.downloadInProgress &&
                                props.downloadProgress.total &&
                                props.downloadProgress.loaded && props.downloadType === 'video') &&
                                (index === props.downloadList.length - 1)) {
                                return null;
                            }

                            return (
                                <div key={index} className="download_details">
                                    {value && value.video &&
                                    value.video.name
                                        ? <p className="file_name" title={value.video.name}>
                                            {value.video.name}
                                        </p>
                                        : value && value.file &&
                                        value.file.name
                                            ? <p className="file_name" title={value.file.name}>
                                                {value.file.name}
                                            </p>
                                            : null}
                                    <div className="assets_type">
                                        {value && value.file &&
                                        value.file['MIME_type']
                                            ? <>
                                                {((value.file['MIME_type'].split('/') &&
                                                    (value.file['MIME_type']).split('/').length > 0 &&
                                                    (value.file['MIME_type']).split('/')[0]) === 'video')
                                                    ? <img alt="video" src={videoIcon}/>
                                                    : value.file['MIME_type'] === 'audio'
                                                        ? <img alt="audio" src={audioIcon}/>
                                                        : null}
                                            </>
                                            : null
                                        }
                                    </div>
                                    <div className="progress_indication">
                                        {value && value.cancel
                                            ? <Icon className="cross" icon="cross"/>
                                            : <Icon className="check_mark" icon="check_mark"/>}
                                    </div>
                                    <div className="completed">
                                        {value && value.cancel
                                            ? 'Canceled'
                                            : 'Download Completed'}
                                    </div>
                                </div>
                            );
                        })
                        : null}
                    {props.audioDownloadList &&
                    props.audioDownloadList.length > 0
                        ? props.audioDownloadList.map((value, index) => {
                            if ((props.downloadInProgress &&
                                props.downloadProgress.total &&
                                props.downloadProgress.loaded && props.downloadType === 'audio') &&
                                (index === props.audioDownloadList.length - 1)) {
                                return null;
                            }

                            return (
                                <div key={index} className="download_details">
                                    {value && value.audio &&
                                    value.audio['track_name']
                                        ? <p className="file_name" title={value.audio['track_name']}>
                                            {value.audio['track_name']}
                                        </p>
                                        : value && value.audio &&
                                        value.audio.name
                                            ? <p className="file_name" title={value.audio.name}>
                                                {value.audio.name}
                                            </p>
                                            : value && value.file &&
                                            value.file.name
                                                ? <p className="file_name" title={value.file.name}>
                                                    {value.file.name}
                                                </p>
                                                : null}
                                    <div className="assets_type">
                                        <img alt="audio" src={audioIcon}/>
                                    </div>
                                    <div className="progress_indication">
                                        {value && value.cancel
                                            ? <Icon className="cross" icon="cross"/>
                                            : <Icon className="check_mark" icon="check_mark"/>}
                                    </div>
                                    <div className="completed">
                                        {value && value.cancel
                                            ? 'Canceled'
                                            : 'Download Completed'}
                                    </div>
                                </div>
                            );
                        })
                        : null}
                    {props.uploadList &&
                    props.uploadList.length > 0
                        ? props.uploadList.map((value, index) => {
                            if ((props.uploadInProgress &&
                                props.uploadProgress.total &&
                                props.uploadProgress.loaded) && (index === props.uploadList.length - 1)) {
                                return null;
                            }

                            return (
                                <div key={index} className="download_details">
                                    {value && value.name
                                        ? <p className="file_name" title={value.name}>
                                            {value.name}
                                        </p>
                                        : null}
                                    <div className="assets_type">
                                        {value && value.type &&
                                        value.type === 'video'
                                            ? <img alt="video" src={videoIcon}/>
                                            : value.type === 'audio'
                                                ? <img alt="audio" src={audioIcon}/>
                                                : null}
                                    </div>
                                    <div className="progress_indication">
                                        {value && value.cancel
                                            ? <Icon className="cross" icon="cross"/>
                                            : <Icon className="check_mark" icon="check_mark"/>}
                                    </div>
                                    <div className="completed">
                                        {value && value.cancel
                                            ? 'Canceled'
                                            : 'Uploaded Successfully'}
                                    </div>
                                </div>
                            );
                        })
                        : null}
                    {downloading
                        ? null
                        : <p className="nodata">No Files Found</p>}
                    {
                        props.downloadInProgress &&
                        props.downloadProgress.total &&
                        props.downloadProgress.loaded &&
                        props.downloadType === 'video'
                            ? <div className="download_details">
                                {props.details && props.details.video &&
                                props.details.video.name
                                    ? <p className="file_name" title={props.details.video.name}>
                                        {props.details.video.name}
                                    </p>
                                    : props.details && props.details.file &&
                                    props.details.file.name
                                        ? <p className="file_name" title={props.details.file.name}>
                                            {props.details.file.name}
                                        </p>
                                        : null}
                                <div className="assets_type">
                                    {props.details && props.details.file &&
                                    props.details.file['MIME_type']
                                        ? <>
                                            {((props.details.file['MIME_type'].split('/') &&
                                                (props.details.file['MIME_type']).split('/').length > 0 &&
                                                (props.details.file['MIME_type']).split('/')[0]) === 'video')
                                                ? <img alt="video" src={videoIcon}/>
                                                : props.details.file['MIME_type'] === 'audio'
                                                    ? <img alt="audio" src={audioIcon}/>
                                                    : null}
                                        </>
                                        : null
                                    }
                                </div>
                                <div
                                    className="progress_indication"
                                    title={((props.downloadProgress.loaded / props.downloadProgress.total) * 100).toFixed(0) + '%'}>
                                    <Icon className="download" icon="download"/>
                                    <CircularProgress
                                        value={((props.downloadProgress.loaded / props.downloadProgress.total) * 100).toFixed(0)}
                                        variant="static"/>
                                </div>
                                <Button
                                    className="cancel_button"
                                    variant="outlined"
                                    onClick={() => handleCancelDownload()}>
                                    Cancel Download
                                </Button>
                            </div>
                            : null
                    }
                    {
                        props.downloadInProgress &&
                        props.downloadProgress.total &&
                        props.downloadProgress.loaded &&
                        props.downloadType === 'audio'
                            ? <div className="download_details">
                                {props.audioDetails && props.audioDetails.audio &&
                                props.audioDetails.audio['track_name']
                                    ? <p className="file_name" title={props.audioDetails.audio['track_name']}>
                                        {props.audioDetails.audio['track_name']}
                                    </p>
                                    : props.audioDetails && props.audioDetails.audio &&
                                    props.audioDetails.audio.name
                                        ? <p className="file_name" title={props.audioDetails.audio.name}>
                                            {props.audioDetails.audio.name}
                                        </p>
                                        : props.audioDetails && props.audioDetails.file &&
                                        props.audioDetails.file.name
                                            ? <p className="file_name" title={props.audioDetails.file.name}>
                                                {props.audioDetails.file.name}
                                            </p>
                                            : null}
                                <div className="assets_type">
                                    <img alt="audio" src={audioIcon}/>
                                </div>
                                <div
                                    className="progress_indication"
                                    title={((props.downloadProgress.loaded / props.downloadProgress.total) * 100).toFixed(0) + '%'}>
                                    <Icon className="download" icon="download"/>
                                    <CircularProgress
                                        value={((props.downloadProgress.loaded / props.downloadProgress.total) * 100).toFixed(0)}
                                        variant="static"/>
                                </div>
                                <Button
                                    className="cancel_button"
                                    variant="outlined"
                                    onClick={() => handleCancelAudioDownload()}>
                                    Cancel Download
                                </Button>
                            </div>
                            : null
                    }
                    {
                        props.uploadInProgress &&
                        props.uploadProgress.total &&
                        props.uploadProgress.loaded
                            ? <div className="download_details">
                                {props.uploadDetails && props.uploadDetails.name
                                    ? <p className="file_name" title={props.uploadDetails.name}>
                                        {props.uploadDetails.name}
                                    </p>
                                    : null}
                                <div className="assets_type">
                                    {props.uploadDetails && props.uploadDetails.type &&
                                    props.uploadDetails.type === 'video'
                                        ? <img alt="video" src={videoIcon}/>
                                        : props.uploadDetails.type === 'audio'
                                            ? <img alt="audio" src={audioIcon}/>
                                            : null}
                                </div>
                                <div
                                    className="progress_indication"
                                    title={((props.uploadProgress.loaded / props.uploadProgress.total) * 100).toFixed(0) + '%'}>
                                    <Icon className="upload" icon="upload"/>
                                    <CircularProgress
                                        value={((props.uploadProgress.loaded / props.uploadProgress.total) * 100).toFixed(0)}
                                        variant="static"/>
                                </div>
                                <Button
                                    className="cancel_button"
                                    variant="outlined"
                                    onClick={handleCancelUpload}>
                                    Cancel Upload
                                </Button>
                            </div>
                            : null
                    }
                </div>
            </Popover>
        </>
    );
};

DownloadStatus.propTypes = {
    audioDetails: PropTypes.object.isRequired,
    audioDownloadList: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
    downloadFileCancel: PropTypes.func.isRequired,
    downloadInProgress: PropTypes.bool.isRequired,
    downloadList: PropTypes.array.isRequired,
    downloadType: PropTypes.string.isRequired,
    setAudioDownloadCancel: PropTypes.func.isRequired,
    setDownloadCancel: PropTypes.func.isRequired,
    setUploadCancel: PropTypes.func.isRequired,
    uploadDetails: PropTypes.object.isRequired,
    uploadFileCancel: PropTypes.func.isRequired,
    uploadInProgress: PropTypes.bool.isRequired,
    uploadList: PropTypes.array.isRequired,
    downloadProgress: PropTypes.shape({
        total: PropTypes.number,
        loaded: PropTypes.number,
    }),
    uploadProgress: PropTypes.shape({
        total: PropTypes.number,
        loaded: PropTypes.number,
    }),
};

const stateToProps = (state) => {
    return {
        audioDetails: state.asset.audioAssets.downloadFile.details,
        audioDownloadList: state.asset.audioAssets.downloadFile.list,
        details: state.asset.videoAssets.downloadFile.details,
        downloadFileCancel: state.asset.videoAssets.downloadFile.cancel,
        downloadInProgress: state.asset.download.inProgress,
        downloadList: state.asset.videoAssets.downloadFile.list,
        downloadProgress: state.asset.download.progress,
        downloadType: state.asset.download.downloadFileType,
        uploadDetails: state.asset.videoAssets.uploadFile.details,
        uploadFileCancel: state.asset.videoAssets.uploadFile.cancel,
        uploadInProgress: state.asset.upload.inProgress,
        uploadList: state.asset.videoAssets.uploadFile.list,
        uploadProgress: state.asset.upload.progress,
    };
};

const actionToProps = {
    setDownloadCancel,
    setUploadCancel,
    setAudioDownloadCancel,
};

export default connect(stateToProps, actionToProps)(DownloadStatus);
