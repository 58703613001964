import { REST_URL, RPC_URL } from './constants/url';

const { SigningCosmosClient } = require('@cosmjs/launchpad');

const chainId = 'flixnet-1';
const chainConfig = {
    chainId: chainId,
    chainName: 'Flix Net',
    rpc: RPC_URL,
    rest: REST_URL,
    stakeCurrency: {
        coinDenom: 'FLIX',
        coinMinimalDenom: 'uflix',
        coinDecimals: 6,
    },
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: 'freeflix',
        bech32PrefixAccPub: 'freeflixpub',
        bech32PrefixValAddr: 'freeflixvaloper',
        bech32PrefixValPub: 'freeflixvaloperpub',
        bech32PrefixConsAddr: 'freeflixvalcons',
        bech32PrefixConsPub: 'freeflixvalconspub',
    },
    currencies: [
        {
            coinDenom: 'FLIX',
            coinMinimalDenom: 'uflix',
            coinDecimals: 6,
        },
    ],
    feeCurrencies: [
        {
            coinDenom: 'FLIX',
            coinMinimalDenom: 'uflix',
            coinDecimals: 6,
        },
    ],
    coinType: 118,
    gasPriceStep: {
        low: 0.01,
        average: 0.025,
        high: 0.04,
    },
};

export const initializeFlixStudio = (cb) => {
    (async () => {
        if (!window.getOfflineSigner || !window.keplr) {
            const error = 'Please install keplr extension';
            cb(error);
        } else {
            if (window.keplr.experimentalSuggestChain) {
                try {
                    await window.keplr.experimentalSuggestChain(chainConfig);
                    const offlineSigner = window.getOfflineSigner(chainId);
                    const accounts = await offlineSigner.getAccounts();

                    cb(null, accounts[0] && accounts[0].address);
                } catch (error) {
                    cb(error && error.message);
                }
            } else {
                const versionError = 'Please use the recent version of keplr extension';
                cb(versionError);
            }
        }
    })();
};

export const sendTx = (sender, recipient, amount, cb) => {
    amount = parseFloat(amount);
    if (isNaN(amount)) {
        const error = 'Invalid amount';
        cb(error);

        return false;
    }

    amount *= 1000000;
    amount = Math.floor(amount);

    (async () => {
        await window.keplr && window.keplr.enable(chainId);
        const offlineSigner = window.getOfflineSigner && window.getOfflineSigner(chainId);

        const accounts = await offlineSigner.getAccounts();

        const cosmJS = new SigningCosmosClient(
            REST_URL,
            accounts[0].address,
            offlineSigner,
        );

        if (sender !== accounts[0].address) {
            const error = 'Sender account is not active in Keplr';
            cb(error);

            return false;
        }

        cosmJS.sendTokens(recipient, [
            {
                denom: 'uflix',
                amount: amount.toString(),
            },
        ]).then((result) => {
            if (result && result.code !== undefined && result.code !== 0) {
                cb(result.log || result.rawLog);
            } else {
                cb(null, result);
            }
        }).catch((error) => {
            cb(error && error.message);
        });
    })();
};

export const signTx = (tx, address, cb) => {
    (async () => {
        await window.keplr && window.keplr.enable(chainId);
        const offlineSigner = window.getOfflineSigner && window.getOfflineSigner(chainId);

        const cosmJS = new SigningCosmosClient(
            REST_URL,
            address,
            offlineSigner,
        );

        cosmJS.signAndBroadcast(tx.msg, tx.fee, tx.memo).then((result) => {
            if (result && result.code !== undefined && result.code !== 0) {
                cb(result.log || result.rawLog);
            } else {
                cb(null, result);
            }
        }).catch((error) => {
            cb(error && error.message);
        });
    })();
};
