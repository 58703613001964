import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAdsSlotData } from '../../../../../actions/ads';
import SelectField from '../../../../../components/SelectField';

const options = [{
    value: 'none',
    name: 'None',
}, {
    value: 'Top Left',
    name: 'Top Left',
}, {
    value: 'Top Right',
    name: 'Top Right',
}, {
    value: 'Bottom Left',
    name: 'Bottom Left',
}, {
    value: 'Bottom Right',
    name: 'Bottom Right',
}];

const PositionSelectField = (props) => {
    const onChange = (value) => {
        const arr = props.data;
        arr[props.index].position = value;
        props.setAdsSlotData([...arr]);
    };

    return (
        <SelectField
            disabled={props.data[props.index].frequency === 0}
            id={'position_select_field' + props.index}
            items={options}
            name="position"
            value={props.value}
            onChange={onChange}/>
    );
};

PositionSelectField.propTypes = {
    data: PropTypes.array.isRequired,
    disabled: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    setAdsSlotData: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        data: state.live.ads.adsList.list,
    };
};

const actionToProps = {
    setAdsSlotData,
};

export default connect(stateToProps, actionToProps)(PositionSelectField);
