import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setMediaNFTID } from '../../../../actions/asset';
import TextField from '../../../../components/TextField';

const MediaNftIDTextField = (props) => {
    const onChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            id="media-nft-id_text_field"
            label="Media NFT ID"
            name="mediaNftID"
            type="text"
            value={props.value}
            variant="outlined"
            onChange={onChange}/>
    );
};

MediaNftIDTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.mediaNFTID,
    };
};

const actionsToProps = {
    onChange: setMediaNFTID,
};

export default connect(stateToProps, actionsToProps)(MediaNftIDTextField);
