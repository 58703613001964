import { Button, Tooltip } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';
import Icon from '../Icon';

const CopyButtonIcon = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCopy = (e) => {
        navigator.clipboard.writeText(props.data);

        e.stopPropagation();
        setOpen(true);
        setTimeout(handleClose, 1000);
    };

    return (
        <Tooltip
            arrow
            open={open}
            title="Copied!">
            <Button
                className="copy_button copy_icon_button"
                variant="contained"
                onClick={handleCopy}>
                <Icon className="copy" icon="copy"/>
            </Button>
        </Tooltip>
    );
};

CopyButtonIcon.propTypes = {
    data: PropTypes.string.isRequired,
};

export default CopyButtonIcon;
