import { Dialog, DialogContent } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setShowPreview } from '../../actions/asset';
import Content from './Content';
import './index.css';

const DialogPreview = (props) => {
    const onClick = (id) => {
        props.setShowPreview(false);
    };

    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            className="dialog_confirm video_player_dialog"
            open={props.open}
            onClose={onClick}>
            <DialogContent className="content">
                <Content/>
            </DialogContent>
        </Dialog>
    );
};

DialogPreview.propTypes = {
    open: PropTypes.bool.isRequired,
    setShowPreview: PropTypes.func.isRequired,
    id: PropTypes.string,
    selectedAsset: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        file: PropTypes.shape({
            runner: PropTypes.shape({
                public_address: PropTypes.string.isRequired,
            }),
        }),
    }),
};

const stateToProps = (state) => {
    return {
        open: state.asset.preview.show,
        selectedAsset: state.asset.preview.asset,
    };
};

const actionsToProps = {
    setShowPreview,
};

export default withRouter(connect(stateToProps, actionsToProps)(DialogPreview));
