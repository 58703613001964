import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setUserLoginEmail } from '../../actions/login';
import Checkbox from '../../components/Checkbox';
import './index.css';

class RememberMeCheckbox extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            rememberMe: 'on',
            remember: false,
        };
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.rememberUser = this.rememberUser.bind(this);
        this.forgetUser = this.forgetUser.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount () {
        const userName = localStorage.getItem('usernameFF');
        const password = localStorage.getItem('passwordFF');
        if (userName && password) {
            this.setState({
                remember: false,
                email: userName,
                password: password,
            });
        } else if (userName === null || password === null) {
            localStorage.clear();
        }
    }

    handleChangeCheckbox (event) {
        const value = event.target.checked;
        const anotherValue = value ? 'on' : 'off';
        this.setState({
            remember: event.target.checked,
            rememberMe: anotherValue,
        });
        if (value === true) {
            this.rememberUser();
        } else {
            this.forgetUser();
        }
    }

    rememberUser () {
        localStorage.setItem('usernameFF', this.props.email);
        localStorage.setItem('passwordFF', this.props.password);
    }

    forgetUser () {
        localStorage.removeItem('usernameFF');
        localStorage.removeItem('passwordFF');
    }

    render () {
        const { remember } = this.state;

        return (
            <Checkbox
                checked={remember}
                className="checkbox_color"
                id="remember-me"
                label="Remember Me"
                name="remember"
                onChange={this.handleChangeCheckbox}
            />
        );
    }
}

RememberMeCheckbox.propTypes = {
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        email: state.login.email.value,
        password: state.login.password,
        valid: state.login.email.valid,
    };
};

const actionsToProps = {
    onChange: setUserLoginEmail,
};

export default connect(stateToProps, actionsToProps)(RememberMeCheckbox);
