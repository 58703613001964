export const ALBUM_FETCH_IN_PROGRESS = 'ALBUM_FETCH_IN_PROGRESS';
export const ALBUM_FETCH_SUCCESS = 'ALBUM_FETCH_SUCCESS';
export const ALBUM_FETCH_ERROR = 'ALBUM_FETCH_ERROR';

export const ALBUM_ADD_IN_PROGRESS = 'ALBUM_ADD_IN_PROGRESS';
export const ALBUM_ADD_SUCCESS = 'ALBUM_ADD_SUCCESS';
export const ALBUM_ADD_ERROR = 'ALBUM_ADD_ERROR';

export const ALBUM_DELETE_IN_PROGRESS = 'ALBUM_DELETE_IN_PROGRESS';
export const ALBUM_DELETE_SUCCESS = 'ALBUM_DELETE_SUCCESS';
export const ALBUM_DELETE_ERROR = 'ALBUM_DELETE_ERROR';

export const NEW_ALBUM_NAME_SET = 'NEW_ALBUM_NAME_SET';
