import { combineReducers } from 'redux';
import {
    ADD_ASSET_ALBUM_SET,
    ADD_ASSET_CATEGORY_SET,
    ADD_ASSET_DATE_OF_UPLOAD_SET,
    ADD_ASSET_DATE_PUBLISHED_SET,
    ADD_ASSET_DURATION_SET,
    ADD_ASSET_ENCODED_STATE_SET,
    ADD_ASSET_FILE_NAME_SET,
    ADD_ASSET_FILE_TYPE_SET,
    ADD_ASSET_GENRE_SET,
    ADD_ASSET_ID_SET,
    ADD_ASSET_LANGUAGE_SET,
    ADD_ASSET_LAST_RUN_SET,
    ADD_ASSET_NAME_SET,
    ADD_ASSET_PUBLISHED_FOR_VOD_SET,
    ADD_ASSET_SOURCE_SET,
    ADD_ASSET_TAGS_SET,
    ADD_FILE_SET,
    ADD_SOURCE_TYPE_SET,
    ADD_SOURCE_URL_SET,
    ASSET_ADD_ERROR,
    ASSET_ADD_IN_PROGRESS,
    ASSET_ADD_SUCCESS,
    ASSET_UPDATE_ERROR,
    ASSET_UPDATE_IN_PROGRESS,
    ASSET_UPDATE_SUCCESS,
    ASSETS_CATEGORIES_FETCH_ERROR,
    ASSETS_CATEGORIES_FETCH_IN_PROGRESS,
    ASSETS_CATEGORIES_FETCH_SUCCESS,
    ASSETS_LANGUAGES_FETCH_ERROR,
    ASSETS_LANGUAGES_FETCH_IN_PROGRESS,
    ASSETS_LANGUAGES_FETCH_SUCCESS,
    CAST_SET,
    COMPUTER_GRAPHICS_SET,
    CREW_SET,
    DIALOG_ADD_ASSET_HIDE,
    DIALOG_ADD_ASSET_SHOW,
    DIALOG_ADD_PEOPLE_HIDE,
    DIALOG_ADD_PEOPLE_SHOW,
    DIALOG_EDIT_ASSET_HIDE,
    DIALOG_EDIT_ASSET_SHOW,
    DIALOGUES_SET,
    EDITABLE_ASSET_AUDIO_DATA,
    EDITABLE_ASSET_DATA,
    EDITABLE_ASSET_IMAGE_DATA,
    EDITABLE_ASSET_TEXT_DATA,
    EDITABLE_ASSET_VIDEO_DATA,
    EDITOR_SET,
    INSTAGRAM_ID_SET,
    MEDIA_NFT_ID_SET,
    OWNERSHIP_STATUS_SELECT_SET,
    STORY_SET,
    STYLIST_SET,
    TAB_VALUE_SET,
    TWITTER_ID_SET,
    YOUTUBE_FILES_FETCH_SUCCESS,
    YOUTUBE_ID_SET,
} from '../../constants/asset';
import { EDIT_CHAPTER_DETAILS_HIDE } from '../../constants/chapterMarking';
import {
    PEOPLE_FETCH_ERROR,
    PEOPLE_FETCH_IN_PROGRESS,
    PEOPLE_FETCH_SUCCESS,
    PROFESSIONS_LIST_SET,
} from '../../constants/people';
import movies from './movies';
import music from './music';
import textToSpeech from './textToSpeech';

const asset = (state = '', action) => {
    switch (action.type) {
    case ASSET_ADD_SUCCESS:
        return action.result;
    case EDITABLE_ASSET_DATA:
        return action.value ? action.value : '';
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const assetId = (state = '', action) => {
    switch (action.type) {
    case ASSET_ADD_SUCCESS:
        return action.result._id;
    case EDITABLE_ASSET_DATA:
        return action.value._id ? action.value._id : '';
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const id = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_ID_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    case EDITABLE_ASSET_DATA:
        return action.value.custom_id ? action.value.custom_id : '';
    default:
        return state;
    }
};

const name = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_NAME_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
        // case EDITABLE_ASSET_DATA:
        //     return action.value.name ? action.value.name : '';
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value.video ? action.value.video.name : '';
    case EDITABLE_ASSET_AUDIO_DATA:
        return action.value && action.value.audio && action.value.audio['track_name']
            ? action.value.audio['track_name']
            : action.value.name ? action.value.name : '';
    case EDITABLE_ASSET_IMAGE_DATA:
        return action.value.graphic ? action.value.graphic.name : '';
    case EDITABLE_ASSET_TEXT_DATA:
        return action.value.text ? action.value.text.name : '';
    default:
        return state;
    }
};

const filename = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_FILE_NAME_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const category = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_CATEGORY_SET:
        return action.value ? action.value : null;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value &&
        action.value.category &&
        action.value.category._id
            ? action.value.category._id
            : '';
    case EDITABLE_ASSET_AUDIO_DATA:
        return action.value &&
        action.value.category &&
        action.value.category._id
            ? action.value.category._id : '';
    default:
        return state;
    }
};

const genre = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_GENRE_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    case EDITABLE_ASSET_DATA:
        return action.value.genre && action.value.genre._id ? action.value.genre._id : '';
    default:
        return state;
    }
};

const fileType = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_FILE_TYPE_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';

    default:
        return state;
    }
};

const duration = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_DURATION_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const source = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_SOURCE_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const dateofUpload = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_DATE_OF_UPLOAD_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const encodedState = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_ENCODED_STATE_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const publishedForVOD = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_PUBLISHED_FOR_VOD_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const datePublished = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_DATE_PUBLISHED_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const lastRun = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_LAST_RUN_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const sourceTypeValue = (state = '', action) => {
    switch (action.type) {
    case ADD_SOURCE_TYPE_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
    case DIALOG_EDIT_ASSET_HIDE:
        return '';
    case EDITABLE_ASSET_DATA:
        return action.value && action.value.file && action.value.file.download && action.value.file.download.source
            ? action.value.file.download.source : '';
    default:
        return state;
    }
};

const assetFile = (state = '', action) => {
    switch (action.type) {
    case ADD_FILE_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const sourceUrl = (state = '', action) => {
    switch (action.type) {
    case ADD_SOURCE_URL_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
    case YOUTUBE_FILES_FETCH_SUCCESS:
        return '';
    case EDITABLE_ASSET_DATA:
        return action.value && action.value.file && action.value.file.download && action.value.file.download.id
            ? action.value.file.download.id
            : '';
    default:
        return state;
    }
};

const dialog = (state = {
    status: false,
    data: '',
    assetType: '',
}, action) => {
    switch (action.type) {
    case DIALOG_ADD_ASSET_SHOW:
        return {
            status: true,
            data: '',
            assetType: action.assetType,
        };
    case DIALOG_EDIT_ASSET_SHOW:
        return {
            status: true,
            data: 'Update',
            assetType: action.assetType,
        };
    case DIALOG_ADD_ASSET_HIDE:
    case DIALOG_EDIT_ASSET_HIDE:
        return {
            ...state,
            status: false,
            data: '',
        };
    default:
        return state;
    }
};

const inProgress = (state = false, action) => {
    switch (action.type) {
    case ASSET_ADD_IN_PROGRESS:
    case ASSET_UPDATE_IN_PROGRESS:
        return true;
    case ASSET_ADD_SUCCESS:
    case ASSET_ADD_ERROR:
    case ASSET_UPDATE_SUCCESS:
    case ASSET_UPDATE_ERROR:
        return false;
    default:
        return state;
    }
};

const album = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_ALBUM_SET:
        return action.value ? action.value : null;
    case EDITABLE_ASSET_DATA:
        return action.value.video && action.value.video.album ? action.value.video.album : '';
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.album
            ? action.value.video.album : '';
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const categories = (state = {
    inProgress: false,
    list: [],
}, action) => {
    switch (action.type) {
    case ASSETS_CATEGORIES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSETS_CATEGORIES_FETCH_SUCCESS:
        return {
            list: action.items,
            inProgress: false,
        };
    case ASSETS_CATEGORIES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const language = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_LANGUAGE_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const languages = (state = {
    inProgress: false,
    list: [],
}, action) => {
    switch (action.type) {
    case ASSETS_LANGUAGES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSETS_LANGUAGES_FETCH_SUCCESS:
        return {
            list: action.items,
            inProgress: false,
        };
    case ASSETS_LANGUAGES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const tags = (state = [], action) => {
    switch (action.type) {
    case ADD_ASSET_TAGS_SET:
        return [...action.value];
    case EDITABLE_ASSET_DATA:
        return action.value.tags ? action.value.tags : [];
    case DIALOG_ADD_ASSET_HIDE:
    case EDIT_CHAPTER_DETAILS_HIDE:
        return [];
    default:
        return state;
    }
};

const peoplesDialog = (state = {
    open: false,
    value: '',
    list: [],
    func: () => {},
}, action) => {
    switch (action.type) {
    case DIALOG_ADD_PEOPLE_SHOW:
        return {
            open: true,
            value: action.value,
            list: action.list,
            func: action.onChange,
        };
    case DIALOG_ADD_PEOPLE_HIDE:
        return {
            open: false,
            value: '',
            list: [],
            func: () => {},
        };
    default:
        return state;
    }
};

const peopleList = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case PEOPLE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PEOPLE_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.value,
        };
    case PEOPLE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const profession = (state = [], action) => {
    switch (action.type) {
    case PROFESSIONS_LIST_SET:
        return action.value;
    case DIALOG_ADD_PEOPLE_SHOW: {
        if (action.profession) {
            const arr = [];
            arr.push(action.profession);
            return [...arr];
        } else {
            return [];
        }
    }
    case DIALOG_ADD_PEOPLE_HIDE:
        return [];
    default:
        return state;
    }
};

const ownerShipStatus = (state = 'owned', action) => {
    switch (action.type) {
    case OWNERSHIP_STATUS_SELECT_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video['ownership_status']
            ? action.value.video['ownership_status'] : '';
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const instagramID = (state = '', action) => {
    if (action.type === INSTAGRAM_ID_SET) {
        return action.value;
    }

    return state;
};

const youtubeID = (state = '', action) => {
    if (action.type === YOUTUBE_ID_SET) {
        return action.value;
    }

    return state;
};

const twitterID = (state = '', action) => {
    if (action.type === TWITTER_ID_SET) {
        return action.value;
    }

    return state;
};

const mediaNFTID = (state = '', action) => {
    if (action.type === MEDIA_NFT_ID_SET) {
        return action.value;
    }

    return state;
};

const story = (state = [], action) => {
    switch (action.type) {
    case STORY_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.story
            ? action.value.video.story : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const dialogues = (state = [], action) => {
    switch (action.type) {
    case DIALOGUES_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.dialogues
            ? action.value.video.dialogues : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const computerGraphics = (state = [], action) => {
    switch (action.type) {
    case COMPUTER_GRAPHICS_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video['computer_graphics']
            ? action.value.video['computer_graphics'] : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const cast = (state = [], action) => {
    switch (action.type) {
    case CAST_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.cast
            ? action.value.video.cast : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const crew = (state = [], action) => {
    switch (action.type) {
    case CREW_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.crew
            ? action.value.video.crew : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const stylist = (state = [], action) => {
    switch (action.type) {
    case STYLIST_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.stylists
            ? action.value.video.stylists : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const editor = (state = [], action) => {
    switch (action.type) {
    case EDITOR_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.editors
            ? action.value.video.editors : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const tabValue = (state = 0, action) => {
    switch (action.type) {
    case TAB_VALUE_SET:
        return action.value;
    case DIALOG_ADD_ASSET_SHOW:
    case DIALOG_EDIT_ASSET_SHOW:
        return 0;
    default:
        return state;
    }
};

const editData = (state = {}, action) => {
    switch (action.type) {
    case EDITABLE_ASSET_DATA:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value;
    case ASSET_ADD_SUCCESS:
        return action.result;
    case DIALOG_ADD_ASSET_HIDE:
        return {};
    default:
        return state;
    }
};

export default combineReducers({
    dialog,
    id,
    name,
    filename,
    category,
    genre,
    fileType,
    duration,
    source,
    dateofUpload,
    encodedState,
    publishedForVOD,
    datePublished,
    lastRun,
    sourceUrl,
    sourceTypeValue,
    assetFile,
    inProgress,
    assetId,
    album,
    categories,
    language,
    languages,
    tags,
    music,
    movies,
    peoplesDialog,
    peopleList,
    profession,
    textToSpeech,
    ownerShipStatus,
    instagramID,
    youtubeID,
    twitterID,
    mediaNFTID,
    story,
    dialogues,
    computerGraphics,
    cast,
    crew,
    stylist,
    editor,
    tabValue,
    editData,
    asset,
});
