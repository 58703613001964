import React, { useState } from 'react';
import SelectField from '../../components/SelectField';
import * as PropTypes from 'prop-types';
import { setOptionType } from '../../actions/interactiveVideo';
import { connect } from 'react-redux';

const items = [{
    value: 'TEXT',
    name: 'Text',
}, {
    value: 'IMAGE',
    name: 'Image',
}, {
    value: 'URL',
    name: 'Url',
}];

const TypeSelect = (props) => {
    const [value, setValue] = useState();

    const handleChange = (value) => {
        setValue(value);
        props.setOptionType(value, props.index);
    };

    return (
        <SelectField
            className="text_field"
            id="select_field"
            items={items}
            label="Option Display Type"
            name=""
            value={value || props.defaultValue || 'TEXT'}
            onChange={handleChange}/>
    );
};

TypeSelect.propTypes = {
    index: PropTypes.number.isRequired,
    setOptionType: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
};

const actionsToProps = {
    setOptionType,
};

export default connect(null, actionsToProps)(TypeSelect);
