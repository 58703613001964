import { Button, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mintNFT, setAccountAddress } from '../../../actions/account';
import {
    downloadFile,
    downloadFileErrorMessage,
    editableData,
    editableVideoAsset,
    encode, fetchAsset,
    setAddAssetID,
    setShowPreview,
    showAssetCategoryTableList,
    showDialogActionMenu,
    showDialogAddAsset,
    showQuickViewMovieAsset,
    updateAssetNFT,
    fetchAssetsData,
} from '../../../actions/asset';
import { setDownloadDetails } from '../../../actions/videoAssets';
import audioIcon from '../../../assets/audio.svg';
import instaIcon from '../../../assets/instaActive.svg';
import mintIcon from '../../../assets/NFT_Icon.png';
import sftpIcon from '../../../assets/sftp.svg';
import twitterIcon from '../../../assets/twitterActive.svg';
import videoIcon from '../../../assets/videoIcon.svg';
import youtubeIcon from '../../../assets/youtubeActive.svg';
import DataTable from '../../../components/DataTable';
import Icon from '../../../components/Icon';
import SkeletonLoader from '../../../components/SkeletonLoader/Table';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../constants/url';
import { initializeFlixStudio, signTx } from '../../../helper';
import { removeUnderScroll } from '../../../utils/statusMessages';
import Preview from '../../Preview';
import PopOverActionMenu from '../PopOverActionMenu';
import { showMessage } from '../../../actions/snackbar';

class AssetVideoTable extends React.Component {
    constructor (props) {
        super(props);
        this.onClickEncode = this.onClickEncode.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.createNFT = this.createNFT.bind(this);
        this.initKeplrAndCreateNFT = this.initKeplrAndCreateNFT.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
    }

    initKeplrAndCreateNFT (index) {
        initializeFlixStudio((error, address) => {
            if (error) {
                this.props.showMessage(error);
                return;
            }

            this.props.setAccountAddress(address);
            this.createNFT(index);
        });
    }

    handleEdit (index) {
        const data = this.props.data && this.props.data.length > 0 && this.props.data.filter((item) => item.type === 'video');
        this.props.editableData(data[index.rowIndex]);
        this.props.editableVideoAsset(data[index.rowIndex]);
        this.props.onClickAdd('video');
        this.props.fetchAsset(data[index.rowIndex]._id, localStorage.getItem('authorizationToken'), (cb) => {
        });
    }

    handleView (index) {
        const selectedAsset = this.props.data[index.rowIndex];

        this.props.setShowPreview(true, selectedAsset);
    }

    createNFT (index) {
        const selectedAsset = this.props.data[index.rowIndex];
        const id = selectedAsset._id;
        const organizationId = selectedAsset.organization && selectedAsset.organization._id;
        if (!this.props.address) {
            this.props.showMessage('Unable to fetch account address');
            return;
        }
        if (!organizationId) {
            this.props.showMessage('Unable to find organization');
            return;
        }

        const data = {
            creator: this.props.address,
        };

        this.props.mintNFT(id, data, (error, tx) => {
            if (error) {
                return;
            }

            signTx(tx.value, this.props.address, (error) => {
                if (error) {
                    this.props.showMessage(error);
                    return;
                }

                this.props.showMessage('Successfully minted MediaNFT');
                const nftId = tx.value && tx.value.msg && tx.value.msg[0] &&
                    tx.value.msg[0].value && tx.value.msg[0].value.ID;

                this.props.updateAssetNFT(id, localStorage.getItem('authorizationToken'),
                    { nftId });
            });
        });
    }

    handleDownload (index) {
        const selectedAsset = this.props.data[index.rowIndex];
        const token = localStorage.getItem('authorizationToken');
        const downloadedList = [...this.props.downloadList];
        downloadedList.push(selectedAsset);

        this.props.setDownloadDetails(selectedAsset, index.rowIndex, [...downloadedList]);

        if (token && this.props.runners.length) {
            if (selectedAsset.file && selectedAsset.file.download &&
                selectedAsset.file.download.status === 'COMPLETE') {
                this.props.download(this.props.runners[0].public_address,
                    this.props.runners[0].organization,
                    selectedAsset.file.download.path + '/' + selectedAsset.file.name,
                    token, selectedAsset.file.name, 'video');
            }
        } else {
            this.props.showDownloadErrorMessage();
        }
    }

    handleQuickView (index) {
        const data = this.props.data && this.props.data.length > 0 && this.props.data.filter((item) => item.type === 'video');
        this.props.onClickView(data[index.dataIndex]);
    }

    onClickEncode (id, token) {
        this.props.encodeFile(id, token, (cb) => {
            if (cb) {
                return;
            }
            const url = this.props.location.pathname;
            const pathName = url.split('/');
            const token = localStorage.getItem('authorizationToken');
            this.props.tableList(pathName ? pathName[3] : '', token);
        });
    }

    handleAdd () {
        this.props.onClickAdd('video');
    }

    handleFetch (skip, limit, searchText, sortBy, order) {
        const token = localStorage.getItem('authorizationToken');

        if (this.props.recent) {
            this.props.fetch('video', token, false, true, this.props.days,
                skip,
                limit,
                searchText,
                sortBy,
                order);
        } else {
            this.props.fetch('video', token, null, null, null,
                skip,
                limit,
                searchText,
                sortBy,
                order);
        }
    }

    render () {
        const options = {
            responsive: true,
            serverSide: true,
            count: this.props.count,
            download: false,
            print: false,
            selectableRows: 'none',
            onSearchChange: (searchText) => {
                this.handleFetch(DEFAULT_SKIP, DEFAULT_LIMIT, searchText, this.props.sortBy,
                    this.props.sortBy !== '' ? this.props.order : null);
            },
            onChangePage: (currentPage) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                this.handleFetch(this.props.limit * currentPage, this.props.limit, this.props.searchKey, this.props.sortBy,
                    this.props.sortBy !== '' ? this.props.order : null);
            },
            onChangeRowsPerPage: (numberOfRows) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                this.handleFetch(this.props.skip, numberOfRows, this.props.searchKey, this.props.sortBy,
                    this.props.sortBy !== '' ? this.props.order : null);
            },
            onColumnSortChange: (changedColumn, direction) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                const order = direction === 'asc' ? 1 : -1;
                this.handleFetch(this.props.skip, this.props.limit, this.props.searchKey, changedColumn, order);
            },
            onCellClick: (colData, cellMeta) => {
                const value = colData.props ? colData.props.children !== 'Encode' : true;
                if (cellMeta.colIndex !== 14 && value && this.props.data && this.props.data.length > 0) {
                    this.handleQuickView(cellMeta);
                }
            },
            customToolbar: () => {
                return (
                    <span>
                        <Button className="add_icon" href="" onClick={this.handleAdd}>
                            <Icon className="add" icon="add"/>
                        </Button>
                    </span>
                );
            },
            // onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            //     console.log('555555555', changedColumn, filterList, type, changedColumnIndex, displayData);
            // },
            // onFilterConfirm: (filterList) => {
            //     console.log('44444444', filterList);
            // },
            // setFilterChipProps: (colIndex, colName, filterValue) => {
            //     console.log('11111111', colIndex, colName, filterValue);
            // },
            textLabels: {
                body: {
                    noMatch: this.props.inProgress ? <SkeletonLoader columns={8}/>
                        : <p className="no_data_table"> No data found </p>,
                    toolTip: 'Sort',
                    columnHeaderTooltip: (column) => `Sort for ${column.label}`,
                },
                pagination: {
                    next: 'Next Page',
                    previous: 'Previous Page',
                    rowsPerPage: 'Rows per page:',
                    displayRows: 'of',
                },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },
                viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Table Columns',
                },
            },
        };

        const columns = [{
            name: 'video.name',
            label: 'Asset Name',
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <div className="filename">
                            {value}
                        </div>
                    );
                },
            },
        }, {
            name: 'genre.name',
            label: 'Asset Genre',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, {
            name: 'category.name',
            label: 'Asset Category',
            options: {
                filter: true,
                sort: true,
                display: false,
                customBodyRender: (value) => {
                    return (
                        <div className="category">
                            {value}
                        </div>
                    );
                },
            },
        }, {
            name: 'file.MIME_type',
            label: 'File Type',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <div className="assets_type">
                            {
                                value
                                    ? <>
                                        {((value.split('/') &&
                                            (value).split('/').length > 0 &&
                                            (value).split('/')[0]) === 'video')
                                            ? <img alt="video" src={videoIcon}/>
                                            : value === 'audio'
                                                ? <img alt="audio" src={audioIcon}/>
                                                : null}
                                        <p className="text">{value}</p>
                                    </>
                                    : null
                            }
                        </div>
                    );
                },
            },
        }, {
            name: 'tags',
            label: 'Tags',
            options: {
                filter: true,
                sort: false,
                display: false,
            },
        }, {
            name: 'file.length',
            label: 'Duration',
            options: {
                filter: true,
                sort: true,
                display: true,
            },
        }, {
            name: 'file.download.source',
            label: 'Source',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        value
                            ? <div className="source_tooltip">
                                <div className="source_css">
                                    {value === 'youtube'
                                        ? <img alt="ff youtube" src={youtubeIcon}/>
                                        : value === 'twitter'
                                            ? <img alt="ff twitter" src={twitterIcon}/>
                                            : value === 'instagram'
                                                ? <img alt="ff insta" src={instaIcon}/>
                                                : value === 'sftp'
                                                    ? <img alt="ff sftp" src={sftpIcon}/>
                                                    : value}
                                </div>
                                <p className={'title ' + (value === 'sftp' ? 'upper_case' : '')}>
                                    {value === 'youtube'
                                        ? 'YouTube'
                                        : value === 'twitter' || value === 'instagram' || value === 'sftp'
                                            ? value
                                            : ''}
                                </p>
                            </div>
                            : ''
                    );
                },
            },
        }, {
            name: 'file.download.at',
            label: 'Date of Upload',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, {
            name: 'video.updated_at',
            label: 'Last Updated',
            options: {
                filter: true,
                sort: true,
                display: true,
            },
        }, {
            name: 'file.download.status',
            label: 'Download Status',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <div style={{
                            color: value === 'IN_PROGRESS' ? '#1AACFF'
                                : value === 'COMPLETE' ? '#39FF14'
                                    : null,
                        }}>
                            {value}
                        </div>
                    );
                },
            },
        }, {
            name: 'file.encode.status',
            label: 'Encoded Status',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        value &&
                        (value === 'PENDING' &&
                            this.props.data[tableMeta.rowIndex].file &&
                            this.props.data[tableMeta.rowIndex].file.download &&
                            this.props.data[tableMeta.rowIndex].file.download.status === 'COMPLETE')
                            ? <Button
                                className="encode encode_asset"
                                onClick={() => this.onClickEncode(this.props.data[tableMeta.rowIndex]._id, token)}>
                                Encode
                            </Button>
                            : value === 'ERROR'
                                ? ''
                                : <div
                                    className={value === 'encode' ? 'encode_asset' : ''}
                                    style={{
                                        color: value === 'IN_PROGRESS' ? '#1AACFF'
                                            : value === 'COMPLETE' ? '#39FF14' : null,
                                    }}>
                                    {value === 'IN_PROGRESS'
                                        ? 'IN PROGRESS'
                                        : value === 'IN_QUEUE'
                                            ? 'IN QUEUE'
                                            : value}
                                </div>
                    );
                },
            },
        }, {
            name: 'publishedForVOD',
            label: 'Published for VOD',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, {
            name: 'datePublished',
            label: 'Date Published (VOD)',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, {
            name: 'lastRun',
            label: 'Last Run',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, {
            name: 'Action',
            label: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, i) => {
                    return (
                        <div className="row_action">
                            <Button
                                className="row_action_icon mint_nft"
                                onClick={() => this.initKeplrAndCreateNFT(i)}>
                                <img alt="mint nft" src={mintIcon}/>
                            </Button>
                            <Button
                                className="row_action_icon download"
                                disabled={this.props.downloadInProgress}
                                onClick={() => this.handleDownload(i)}>
                                {this.props.downloadInProgress &&
                                this.props.downloadProgress.total &&
                                this.props.downloadProgress.loaded &&
                                this.props.downloadType === 'video' &&
                                this.props.downloadIndex === i.rowIndex
                                    ? <>
                                        <p className="percentage_count">
                                            {((this.props.downloadProgress.loaded / this.props.downloadProgress.total) * 100).toFixed(0) + '%'}
                                        </p>
                                        <CircularProgress
                                            className="progress_circle"
                                            value={((this.props.downloadProgress.loaded / this.props.downloadProgress.total) * 100).toFixed(0)}
                                            variant="static"/>
                                    </>
                                    : <Icon
                                        className="download"
                                        icon="download"/>}
                            </Button>
                            <Button
                                className="row_action_icon preview"
                                onClick={() => this.handleView(i)}>
                                <Icon
                                    className="eye"
                                    icon="eye"/>
                            </Button>
                            <Button
                                className="row_action_icon edit"
                                onClick={() => this.handleEdit(i)}>
                                <Icon
                                    className="edit_action"
                                    icon="edit_action"/>
                            </Button>
                            <Button
                                className="row_action_icon delete"
                                disabled={this.props.downloadInProgress}
                                onClick={() => this.props.onClickConfirm(this.props.data[i.rowIndex], 'video')}>
                                <Icon
                                    className="delete_action"
                                    icon="delete_action"/>
                            </Button>
                        </div>
                    );
                },
            },
        }];

        const token = localStorage.getItem('authorizationToken');
        const data = this.props.data;
        const tableData = data && data.length > 0
            ? data.map((item, index) => [
                item.video && item.video.name,
                item.genre ? item.genre.name : '',
                item.category && item.category.name,
                item.file && item.file['MIME_type']
                    ? item.file['MIME_type'] : item.type,
                item.tags && item.tags.length > 0 &&
                item.tags.join(', '),
                item.file && item.file.length
                    ? moment.utc(moment.duration(item.file.length, 'seconds')
                        .asMilliseconds()).format('HH:mm:ss')
                    : '',
                item.file && item.file.download && item.file.download.source,
                item.file && item.file.download && item.file.download.at
                    ? moment(item.file.download.at).format('DD-MMM-YYYY HH:mm:ss')
                    : '',
                item.video && item.video.updated_at
                    ? moment(item.video.updated_at).format('DD-MMM-YYYY HH:mm:ss')
                    : '',
                item.file && item.file.download && item.file.download.status
                    ? removeUnderScroll(item.file.download.status) : '',
                item.file && item.file.encode && item.file.encode.status
                    ? removeUnderScroll(item.file.encode.status) : '',
                item.publishedForVOD,
                item.datePublished,
                item.lastRun,
            ])
            : [];

        return (
            <>
                <DataTable
                    columns={columns}
                    data={tableData}
                    name=""
                    options={options}/>
                <Preview/>
                <PopOverActionMenu/>
            </>
        );
    }
}

AssetVideoTable.propTypes = {
    count: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    days: PropTypes.number.isRequired,
    download: PropTypes.func.isRequired,
    downloadInProgress: PropTypes.bool.isRequired,
    downloadIndex: PropTypes.number.isRequired,
    downloadList: PropTypes.array.isRequired,
    downloadType: PropTypes.string.isRequired,
    editableData: PropTypes.func.isRequired,
    editableVideoAsset: PropTypes.func.isRequired,
    encodeFile: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    fileEncoded: PropTypes.object.isRequired,
    inProgress: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    mintNFT: PropTypes.func.isRequired,
    order: PropTypes.number.isRequired,
    runners: PropTypes.arrayOf(
        PropTypes.shape({
            public_address: PropTypes.string.isRequired,
            organization: PropTypes.string.isRequired,
        }),
    ).isRequired,
    searchKey: PropTypes.string.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    setAddAssetID: PropTypes.func.isRequired,
    setDownloadDetails: PropTypes.func.isRequired,
    setShowPreview: PropTypes.func.isRequired,
    showDownloadErrorMessage: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    updateAssetNFT: PropTypes.func.isRequired,
    onClickActionMenu: PropTypes.func.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onClickConfirm: PropTypes.func.isRequired,
    onClickView: PropTypes.func.isRequired,
    address: PropTypes.string,
    downloadProgress: PropTypes.shape({
        total: PropTypes.number.isRequired,
        loaded: PropTypes.number.isRequired,
    }),
    fetchAsset: PropTypes.func,
    recent: PropTypes.bool,
    tableList: PropTypes.func,
};

const stateToProps = (state) => {
    return {
        data: state.asset.assetData.list,
        days: state.asset.updatedFilter.days,
        inProgress: state.asset.assetData.inProgress,
        fileEncoded: state.asset.assetFileEncode.result,
        runners: state.runner.list,
        downloadType: state.asset.download.downloadFileType,
        downloadInProgress: state.asset.download.inProgress,
        downloadIndex: state.asset.videoAssets.downloadFile.index,
        downloadProgress: state.asset.download.progress,
        downloadList: state.asset.videoAssets.downloadFile.list,
        address: state.account.address,
        skip: state.asset.assetData.skip,
        limit: state.asset.assetData.limit,
        count: state.asset.assetData.count,
        searchKey: state.asset.assetData.searchKey,
        sortBy: state.asset.assetData.sortBy,
        order: state.asset.assetData.order,
    };
};

const actionToProps = {
    onClickView: showQuickViewMovieAsset,
    onClickAdd: showDialogAddAsset,
    tableList: showAssetCategoryTableList,
    encodeFile: encode,
    setAddAssetID: setAddAssetID,
    editableData: editableData,
    editableVideoAsset,
    onClickConfirm: showDialogActionMenu,
    setShowPreview,
    download: downloadFile,
    setDownloadDetails,
    showDownloadErrorMessage: downloadFileErrorMessage,
    mintNFT,
    setAccountAddress,
    fetchAsset: fetchAsset,
    updateAssetNFT,
    showMessage,
    fetch: fetchAssetsData,
};

export default withRouter(connect(stateToProps, actionToProps)(AssetVideoTable));
