import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import App from './App';
import rootReducer from './reducers';
import * as serviceWorker from './serviceWorker';

const customMiddleWare = () => (next) => (action) => {
    if (action.message && action.message === 'Error occurred while verifying the JWT token.') {
        localStorage.setItem('authorizationToken', '');
    }

    next(action);
};

const store = createStore(
    rootReducer,
    composeWithDevTools({
        trace: true,
    })(applyMiddleware(
        thunk,
        customMiddleWare,
    )),
);

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
