import * as PropTypes from 'prop-types';
import React from 'react';

class Player extends React.Component {
    componentDidMount () {
        this.props.initializePlayer(this.node);
    }

    render () {
        return (
            <div>
                {/* Adding this parent div to resolve the error while removing the player from dom */}
                <video
                    ref={(node) => (this.node = node)}
                    className="video-js vjs-default-skin"
                    data-setup={this.props.setup}
                    id="video-player"/>
            </div>
        );
    }
}

Player.propTypes = {
    initializePlayer: PropTypes.func.isRequired,
    setup: PropTypes.string.isRequired,
};

export default Player;
