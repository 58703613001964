import { Popover } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Agenda from './Agenda';

class DropDown extends React.Component {
    constructor (props) {
        super(props);
        this.state = {};
    }

    render () {
        return (
            <div>
                <Popover
                    anchorEl={this.props.anchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className="calendar"
                    id="simple-popper"
                    open={this.props.open}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={this.props.close}>
                    <Agenda select={this.props.select}/>
                </Popover>
            </div>
        );
    }
}

DropDown.propTypes = {
    close: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    select: PropTypes.func.isRequired,
    anchor: PropTypes.object,
};

export default DropDown;
