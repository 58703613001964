import { Button, Drawer as MaterialDrawer } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchNFT } from '../../../actions/account';
import { hideQuickViewMovieAsset, setShowPreview } from '../../../actions/asset';
import audioIcon from '../../../assets/audio.svg';
import instaIcon from '../../../assets/instaActive.svg';
import sftpIcon from '../../../assets/sftp.svg';
import twitterIcon from '../../../assets/twitterActive.svg';
import videoIcon from '../../../assets/videoIcon.svg';
import youtubeIcon from '../../../assets/youtubeActive.svg';
import CopyButton from '../../../components/CopyButton';
import DotsLoading from '../../../components/DotsLoading';
import { removeUnderScroll } from '../../../utils/statusMessages';
import TagVideoButton from '../../AssetsOverview/TagVideoButton';
import Header from './Header';
import './index.css';
import InteractButton from './InteractButton';

class MovieQuickViewDrawer extends React.Component {
    componentDidUpdate (prevProps) {
        if (this.props.data !== prevProps.data && !(this.props.data && this.props.data.nft_id)) {
            if (this.props.data && this.props.data._id) {
                this.props.fetchNFT(this.props.data && this.props.data._id);
            }
        }
    }

    render () {
        const { data } = this.props;
        return (
            <MaterialDrawer
                anchor="right"
                className="quick_panel"
                open={this.props.open}
                onClose={this.props.onClickCancel}
                onEscapeKeyDown={this.props.onClickCancel}>
                <Header data={data || {}}/>
                <div className="quick_view_data">
                    <div>
                    </div>
                    <div>
                    </div>
                    <div>
                        <p> Asset Name </p>
                        <p>
                            {(data.type) === 'video'
                                ? data.video && data.video.name
                                : (data.type) === 'audio'
                                    ? data.audio && data.audio.track_name
                                    : (data.type) === 'graphic'
                                        ? data.graphic && data.graphic.name
                                        : (data.type) === 'text'
                                            ? data.text && data.text.name
                                            : ''}
                        </p>
                    </div>
                    {
                        (data.type) !== 'audio'
                            ? <div>
                                <p> Asset Category </p>
                                <p> {(data.category) ? data.category.name : ''} </p>
                            </div>
                            : null
                    }
                    <div>
                        <p> File Name </p>
                        <p className="first_letter">{(data.file) ? data.file.name : ''}</p>
                    </div>
                    {
                        (data.type) === 'audio'
                            ? <div>
                                <p> Film Name (Album) </p>
                                <p>{data.audio && data.audio['film_name']}</p>
                            </div>
                            : null
                    }
                    {
                        (data.type) === 'audio'
                            ? <div>
                                <p> Music Director(s) </p>
                                <p>{data.audio && data.audio['music_directors']
                                    ? data.audio['music_directors'].join(', ')
                                    : ''}</p>
                            </div>
                            : null
                    }
                    {
                        (data.type) === 'audio'
                            ? <div>
                                <p> Popularity </p>
                                <p>{data.audio && data.audio.popularity}</p>
                            </div>
                            : null
                    }
                    <div>
                        <p> File Type </p>
                        <p className="file_type tags_div">
                            {(data.file && data.file['MIME_type'] && (data.file && data.file['MIME_type']).split('/') &&
                                (data.file && data.file['MIME_type'] && data.file['MIME_type']).split('/').length > 0 &&
                                (data.file && data.file['MIME_type'] && data.file['MIME_type']).split('/')[0]) === 'video'
                                ? <img alt="video" src={videoIcon}/>
                                : data.file && data.file['MIME_type'] === 'audio'
                                    ? <img alt="audio" src={audioIcon}/>
                                    : null}
                            <p className="first_letter">{(data.file && data.file['MIME_type'])}</p>
                            {(data.type) === 'video'
                                ? <span className="copy_button_span">
                                    <Button
                                        className="copy_button"
                                        variant="contained"
                                        onClick={() => this.props.setShowPreview(true, data)}>
                                        Watch
                                    </Button>
                                    <TagVideoButton hideQuickView={this.props.onClickCancel} id={data._id}/>
                                    <InteractButton hideQuickView={this.props.onClickCancel} id={data._id}/>
                                </span>
                                : null}
                        </p>
                    </div>
                    <div>
                        <p> Tags </p>
                        <div className="tags_div">
                            {data.tags && data.tags.length > 0 &&
                            data.tags.map((value, index) => {
                                return (
                                    <b key={index} className="tags">{value}</b>
                                );
                            })}
                            {data.tags && data.tags.length > 0
                                ? <span className="copy_button_span">
                                    <CopyButton
                                        data={data.tags && data.tags.length > 0 && data.tags.join(', ')}/>
                                </span>
                                : null}
                        </div>
                    </div>
                    <div>
                        <p> People / Cast </p>
                        <div className="tags_div">
                            {data.video && data.video.cast &&
                            data.video.cast.length > 0 &&
                            data.video.cast.map((value, index) => {
                                return (
                                    <b key={index} className="tags">{value.name}</b>
                                );
                            })}
                            {/* {data.video && data.video.cast && */}
                            {/* data.video.cast.length > 0 */}
                            {/*    ? <span className="copy_button_span"> */}
                            {/*        <CopyButton data={data.video && data.video.cast && */}
                            {/*        data.video.cast.length > 0 && */}
                            {/*        data.video.cast.join(', ')}/> */}
                            {/*    </span> */}
                            {/*    : null} */}
                        </div>
                    </div>
                    <div>
                        <p> Duration </p>
                        <p>{data.file && data.file.length
                            ? moment.utc(moment.duration(data.file.length, 'seconds')
                                .asMilliseconds()).format('HH:mm:ss')
                            : ''}</p>
                    </div>
                    <div>
                        <p> Source </p>
                        <p className="file_type" style={{ textTransform: 'uppercase' }}>
                            {data.file && data.file.download && data.file.download.source === 'youtube'
                                ? <img alt="youtube" src={youtubeIcon}/>
                                : data.file && data.file.download && data.file.download.source === 'twitter'
                                    ? <img alt="twitter" src={twitterIcon}/>
                                    : data.file && data.file.download && data.file.download.source === 'instagram'
                                        ? <img alt="insta" src={instaIcon}/>
                                        : data.file && data.file.download && data.file.download.source === 'sftp'
                                            ? <img alt="sftp" src={sftpIcon}/>
                                            : ''}
                            {data.file && data.file.download ? data.file.download.source : ''}
                        </p>
                    </div>
                    <div>
                        <p> Date of upload </p>
                        <p>{data.file && data.file.download && data.file.download.at
                            ? moment(data.file && data.file.download.at).utc().format('Do MMMM YYYY, HH:mm:ss')
                            : ''}</p>
                    </div>
                    <div>
                        <p> Download Status </p>
                        <p style={{
                            color: (data.file && data.file.download && data.file.download.status) === 'IN_PROGRESS'
                                ? '#1AACFF'
                                : (data.file && data.file.download && data.file.download.status) === 'COMPLETE'
                                    ? '#39FF14'
                                    : null,
                        }}>
                            {data.file && data.file.download
                                ? removeUnderScroll(data.file.download.status) : ''}
                        </p>
                    </div>
                    {
                        (data.type) === 'video'
                            ? <div>
                                <p> Encoded Status </p>
                                <p style={{
                                    color: (data.file && data.file.encode && data.file.encode.status) === 'IN_PROGRESS'
                                        ? '#1AACFF'
                                        : (data.file && data.file.encode && data.file.encode.status) === 'COMPLETE'
                                            ? '#39FF14' : null,
                                }}>
                                    {data.file && data.file.encode &&
                                    (data.file && data.file.encode && data.file.encode.status) === 'IN_PROGRESS'
                                        ? 'IN PROGRESS'
                                        : (data.file && data.file.encode &&
                                            data.file.encode.status) === 'IN_QUEUE'
                                            ? 'IN QUEUE'
                                            : (data.file && data.file.encode && data.file.encode.status)
                                                ? removeUnderScroll(data.file.encode.status)
                                                : ''}
                                </p>
                            </div>
                            : null
                    }
                    {data.type === 'video' &&
                    <div className="nft_id">
                        <p>NFT ID</p>
                        <div className="tags_div">
                            {this.props.nftInProgress
                                ? <DotsLoading/>
                                : <p>{(this.props.data && this.props.data._id) ===
                                (this.props.nft && this.props.nft.assetID) ? this.props.nft.id : ''}</p>}

                            {this.props.data && this.props.data.nft_id ? <p> {this.props.data.nft_id}</p> : null}

                            {(
                                (this.props.data && this.props.data._id) ===
                                (this.props.nft && this.props.nft.assetID) && !this.props.nftInProgress) ||
                            (this.props.data && this.props.data.nft_id)
                                ? <span className="copy_button_span">
                                    <CopyButton data={(this.props.data && this.props.data.nft_id) ||
                                    this.props.nft.id}/>
                                </span>
                                : null}
                        </div>
                    </div>}
                </div>
            </MaterialDrawer>
        );
    }
}

MovieQuickViewDrawer.propTypes = {
    data: PropTypes.object.isRequired,
    fetchNFT: PropTypes.func.isRequired,
    nftInProgress: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    setShowPreview: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    index: PropTypes.object,
    nft: PropTypes.shape({
        assetID: PropTypes.string,
        id: PropTypes.string,
    }),
};

const stateToProps = (state) => {
    return {
        open: state.asset.quickView.display,
        data: state.asset.quickView.data,
        index: state.asset.quickView.state,
        nft: state.account.nft.data,
        nftInProgress: state.account.nft.inProgress,
    };
};

const actionToProps = {
    onClickCancel: hideQuickViewMovieAsset,
    setShowPreview,
    fetchNFT,
};

export default connect(stateToProps, actionToProps)(MovieQuickViewDrawer);
