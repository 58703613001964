export const DOWNLOAD_FILE_DETAILS_SET = 'DOWNLOAD_FILE_DETAILS_SET';
export const DOWNLOAD_FILE_CANCEL_SET = 'DOWNLOAD_FILE_CANCEL_SET';

export const UPLOAD_FILE_DETAILS_SET = 'UPLOAD_FILE_DETAILS_SET';
export const UPLOAD_FILE_CANCEL_SET = 'UPLOAD_FILE_CANCEL_SET';

export const WATCH_URL_FETCH_IN_PROGRESS = 'WATCH_URL_FETCH_IN_PROGRESS';
export const WATCH_URL_FETCH_SUCCESS = 'WATCH_URL_FETCH_SUCCESS';
export const WATCH_URL_FETCH_ERROR = 'WATCH_URL_FETCH_ERROR';

export const DEVICE_ID_FETCH_IN_PROGRESS = 'DEVICE_ID_FETCH_IN_PROGRESS';
export const DEVICE_ID_FETCH_SUCCESS = 'DEVICE_ID_FETCH_SUCCESS';
export const DEVICE_ID_FETCH_ERROR = 'DEVICE_ID_FETCH_ERROR';
