import React from 'react';
import './index.css';

const OverviewCards = () => {
    return (
        <div className="overview_loading_card">
            <div className="image"/>
        </div>
    );
};

export default OverviewCards;
