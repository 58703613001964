import { CircularProgress } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import VideoJS from 'video.js';
import 'video.js/dist/video-js.css';
import { getPreviewUrl } from '../../actions/asset';
import { fetchDeviceID, fetchWatchURL } from '../../actions/videoAssets';
import posterLink from '../../assets/OF_logo.gif';
import Player from '../../components/Player';

const videoJSOptions = {
    autoplay: true,
    controls: true,
    loop: false,
    loadingSpinner: false,
    width: 500,
    height: 600,
};

class Preview extends React.Component {
    constructor (props) {
        super(props);
        this.initializePlayer = this.initializePlayer.bind(this);
        this.state = {};
    }

    initializePlayer (ref) {
        this.player = VideoJS(ref, videoJSOptions);
        if (this.player) {
            this.player.poster(posterLink);

            this.player.on('ended', () => {
                this.player.poster('');
            });
        }
    }

    componentDidMount () {
        const asset = this.props.selectedAsset;

        if (this.props.deviceID && Object.keys(this.props.deviceID).length) {
            this.props.fetchWatchURL(asset._id, this.props.deviceID, (cb) => {
                if (cb) {
                    const url = cb.assetUrl;

                    this.props.getPreviewUrl(url, (format) => {
                        const config = {
                            sources: [{
                                type: format === 'mp4' || (asset && asset.type === 'audio')
                                    ? 'video/mp4'
                                    : 'application/x-mpegURL',
                                src: format === 'mp4'
                                    ? url.replace('m3u8', 'mp4')
                                    : url,
                            }],
                        };

                        this.setState({ config: JSON.stringify(config) });
                    });
                }
            });
        } else {
            this.props.fetchDeviceID((deviceID) => {
                if (deviceID) {
                    this.props.fetchWatchURL(asset._id, deviceID, (cb) => {
                        if (cb) {
                            const url = cb.assetUrl;

                            this.props.getPreviewUrl(url, (format) => {
                                const config = {
                                    sources: [{
                                        type: format === 'mp4' || (asset && asset.type === 'audio')
                                            ? 'video/mp4'
                                            : 'application/x-mpegURL',
                                        src: format === 'mp4'
                                            ? url.replace('m3u8', 'mp4')
                                            : url,
                                    }],
                                };

                                this.setState({ config: JSON.stringify(config) });
                            });
                        }
                    });
                }
            });
        }
    }

    componentWillUnmount () {
        if (this.player) {
            this.player.dispose();
        }
    }

    render () {
        const inProgress = this.props.deviceIDInProgress || this.props.urlLinkInProgress;

        return (
            <div className="video-wrapper">
                {inProgress
                    ? <CircularProgress/>
                    : this.state.config
                        ? <Player
                            initializePlayer={this.initializePlayer}
                            setup={this.state.config}/>
                        : null}
            </div>
        );
    }
}

Preview.propTypes = {
    deviceID: PropTypes.string.isRequired,
    deviceIDInProgress: PropTypes.bool.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    fetchWatchURL: PropTypes.func.isRequired,
    getPreviewUrl: PropTypes.func.isRequired,
    urlLinkInProgress: PropTypes.bool.isRequired,
    selectedAsset: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        file: PropTypes.shape({
            view_link_m3u8: PropTypes.string.isRequired,
            view_link: PropTypes.string,
        }),
        type: PropTypes.string,
    }),
    url: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        selectedAsset: state.asset.preview.asset,
        deviceID: state.asset.videoAssets.deviceID.id,
        deviceIDProgress: state.asset.videoAssets.deviceID.inProgress,
        urlLinkInProgress: state.asset.videoAssets.playerLink.inProgress,
    };
};

const actionsToProps = {
    getPreviewUrl,
    fetchWatchURL,
    fetchDeviceID,
};

export default (connect(stateToProps, actionsToProps)(Preview));
