import { combineReducers } from 'redux';
import {
    ASSET_SCENES_FETCH_ERROR,
    ASSET_SCENES_FETCH_IN_PROGRESS,
    ASSET_SCENES_FETCH_SUCCESS,
} from '../../constants/asset';
import {
    ASSET_CHAPTER_ADD_ERROR,
    ASSET_CHAPTER_ADD_IN_PROGRESS,
    ASSET_CHAPTER_ADD_SUCCESS,
    ASSET_CHAPTER_CATEGORIES_FETCH_ERROR,
    ASSET_CHAPTER_CATEGORIES_FETCH_IN_PROGRESS,
    ASSET_CHAPTER_CATEGORIES_FETCH_SUCCESS,
    ASSET_CHAPTER_OBJECTS_FETCH_ERROR,
    ASSET_CHAPTER_OBJECTS_FETCH_IN_PROGRESS,
    ASSET_CHAPTER_OBJECTS_FETCH_SUCCESS,
    ASSET_CHAPTER_PEOPLE_FETCH_ERROR,
    ASSET_CHAPTER_PEOPLE_FETCH_IN_PROGRESS,
    ASSET_CHAPTER_PEOPLE_FETCH_SUCCESS,
    ASSET_CHAPTER_UPDATE_ERROR,
    ASSET_CHAPTER_UPDATE_SUCCESS,
    ASSET_CHAPTERS_FETCH_ERROR,
    ASSET_CHAPTERS_FETCH_IN_PROGRESS,
    ASSET_CHAPTERS_FETCH_SUCCESS,
    CHAPTER_DETAILS_TAB_SET,
    CHAPTER_MAX_VALUE_SET,
    CHAPTER_MIN_VALUE_SET,
    CHAPTER_RANGE_VALUES_SET, SEARCH_ASSETS_SET,
    SEARCH_CHAPTER_SET,
    SIDE_BAR_CONTENT1_SET,
    SIDE_BAR_CONTENT2_SET, SLIDER_TYPE_SET,
} from '../../constants/chapterMarking';
import { details } from '../../dummy/chapterMarking';
import _new from './new';

const tab1 = (state = {
    value: 0,
    name: 'Cinematic Intelligence',
}, action) => {
    if (action.type === SIDE_BAR_CONTENT1_SET) {
        return {
            value: action.value,
            name: action.name,
        };
    }

    return state;
};

const tab2 = (state = 0, action) => {
    switch (action.type) {
    case SIDE_BAR_CONTENT2_SET:
        return action.value;
    case SIDE_BAR_CONTENT1_SET:
        return 0;
    default:
        return state;
    }
};

const search = (state = '', action) => {
    switch (action.type) {
    case SEARCH_CHAPTER_SET:
        return action.value;
    case SIDE_BAR_CONTENT1_SET:
        return '';
    default:
        return state;
    }
};

const searchAsset = (state = '', action) => {
    switch (action.type) {
    case SEARCH_ASSETS_SET:
        return action.value;
    default:
        return state;
    }
};

const chapterDetails = (state = {
    tab: 0,
    details: details,
}, action) => {
    if (action.type === CHAPTER_DETAILS_TAB_SET) {
        return {
            ...state,
            tab: action.value,
        };
    }

    return state;
};

const range = (state = [], action) => {
    if (action.type === CHAPTER_RANGE_VALUES_SET) {
        return action.range;
    }

    return state;
};

const scenes = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_SCENES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_SCENES_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.items,
        };
    case ASSET_SCENES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const chapter = (state = {
    list: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_CHAPTER_ADD_IN_PROGRESS:
    case ASSET_CHAPTERS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_CHAPTERS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.items,
        };
    case ASSET_CHAPTER_UPDATE_SUCCESS: {
        const data = state.list;

        if (action.item && action.item._id) {
            data[action.item._id] = action.item;
        }

        return {
            ...state,
            inProgress: false,
            list: data,
        };
    }
    case ASSET_CHAPTER_ADD_SUCCESS: {
        const data = state.list;

        if (action.item && action.item._id) {
            data[action.item._id] = action.item;
        }

        return {
            ...state,
            inProgress: false,
            list: data,
        };
    }
    case ASSET_CHAPTER_ADD_ERROR:
    case ASSET_CHAPTERS_FETCH_ERROR:
    case ASSET_CHAPTER_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};
const min = (state = 0, action) => {
    if (action.type === CHAPTER_MIN_VALUE_SET) {
        return action.value;
    }

    return state;
};

const max = (state = 0, action) => {
    if (action.type === CHAPTER_MAX_VALUE_SET) {
        return action.value;
    }

    return state;
};

const categories = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_CHAPTER_CATEGORIES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_CHAPTER_CATEGORIES_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.items,
        };
    case ASSET_CHAPTER_CATEGORIES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const people = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_CHAPTER_PEOPLE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_CHAPTER_PEOPLE_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.items,
        };
    case ASSET_CHAPTER_PEOPLE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const objects = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_CHAPTER_OBJECTS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_CHAPTER_OBJECTS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.items,
        };
    case ASSET_CHAPTER_OBJECTS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const sliderType = (state = 'automatic', action) => {
    switch (action.type) {
    case SLIDER_TYPE_SET:
        return action.value;
    case ASSET_CHAPTERS_FETCH_IN_PROGRESS:
        return 'automatic';
    default:
        return state;
    }
};

export default combineReducers({
    tab1,
    tab2,
    search,
    searchAsset,
    chapterDetails,
    range,
    scenes,
    min,
    max,
    new: _new,
    chapter,
    categories,
    people,
    objects,
    sliderType,
});
