import { Checkbox as MaterialCheckbox, FormControl, FormControlLabel } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';

const Checkbox = (props) => {
    return (
        <FormControl>
            <FormControlLabel
                control={
                    <MaterialCheckbox
                        checked={props.remember}
                        className={props.className}
                        name={props.name}
                        onChange={props.onChange}/>
                }
                label={props.label}
            />
        </FormControl>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    remember: PropTypes.bool,
    value: PropTypes.string,
};

export default Checkbox;
