import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showAssetCategoryTableList, updateAsset, showQuickViewMovieAsset } from '../../../actions/asset';

const UpdateButton = (props) => {
    const disable = props.id === '' ||
        props.name === '';
    // || (props.fileInfo && props.fileInfo._id === undefined);

    const onClick = () => {
        if (props.inProgress) {
            return;
        }

        const url = props.location.pathname;
        const pathName = url.split('/');
        const token = localStorage.getItem('authorizationToken');
        const data = {};

        if (props.name) {
            data.name = props.name;
        }
        if (props.type) {
            data.type = props.type;
        }
        if (props.customId) {
            data.customId = props.customId;
        }
        if (props.selectedCategory) {
            data.category = props.selectedCategory;
        }
        if (props.tags) {
            data.tags = props.tags;
        }

        props.onClick(props.assetId, data, token, (cb) => {
            if (cb) {
                if (props.open) {
                    props.showQuickViewMovieAsset(cb);
                }

                return;
            }
            props.tableList(pathName ? pathName[3] : '', token);
        });
    };

    return (
        <Button
            className={disable ? 'action_button disabled_button' : 'action_button'}
            color="primary"
            disabled={disable}
            onClick={onClick}>
            Update
        </Button>
    );
};
UpdateButton.propTypes = {
    assetFileId: PropTypes.string.isRequired,
    assetId: PropTypes.string.isRequired,
    category: PropTypes.object.isRequired,
    customId: PropTypes.string.isRequired,
    fileInfo: PropTypes.object.isRequired,
    genre: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    selectedCategory: PropTypes.string.isRequired,
    showQuickViewMovieAsset: PropTypes.func.isRequired,
    tableList: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        assetId: state.asset.new.assetId,
        name: state.asset.new.name,
        category: state.asset.assetCategoriesList,
        selectedCategory: state.asset.new.category,
        genre: state.asset.new.genre,
        fileInfo: state.asset.addAssetFile.result,
        assetFileId: state.asset.new.assetFile,
        inProgress: state.asset.new.inProgress,
        customId: state.asset.new.id,
        tags: state.asset.new.tags,
        open: state.asset.quickView.display,
    };
};

const actionsToProps = {
    onClick: updateAsset,
    tableList: showAssetCategoryTableList,
    showQuickViewMovieAsset,
};

export default withRouter(connect(stateToProps, actionsToProps)(UpdateButton));
