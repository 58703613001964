import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import viewIcon from '../../../../../assets/viewVideo.png';
import closeIcon from '../../../../../assets/close.png';

const SlotDetails = (props) => {
    return (
        <div className="slot_details">
            <div className="slot_sections">
                <div className="slot_section">
                    <div className="slot_section_name"> Asset Name</div>
                    <div className="slot_section_value">
                        {props.data && props.data.program && props.data.program.asset &&
                        props.data.program.asset._ && props.data.program.asset._.name
                            ? props.data.program.asset._.name
                            : null}
                    </div>
                    <Button className="slot_section_button">
                        <img alt="view" src={viewIcon}/>
                        View
                    </Button>
                </div>
                <div className="slot_section">
                    <div className="slot_section_name"> Asset Genre</div>
                    <div className="slot_section_value">
                        {props.data && props.data.program && props.data.program.asset &&
                        props.data.program.asset._ && props.data.program.asset._.genre &&
                        props.data.program.asset._.genre.name
                            ? props.data.program.asset._.genre.name
                            : null}
                    </div>
                </div>
                <div className="slot_section">
                    <div className="slot_section_name"> Tags</div>
                    <div className="slot_section_tags">
                        {props.data && props.data.program && props.data.program.asset &&
                        props.data.program.asset._ && props.data.program.asset._.tags &&
                        props.data.program.asset._.tags.length
                            ? props.data.program.asset._.tags.map((value) => {
                                return (
                                    <div key={value}>
                                        {value}
                                        <img alt="close" src={closeIcon}/>
                                    </div>
                                );
                            })
                            : null}
                    </div>
                </div>
                <div className="slot_section">
                    <div className="slot_section_name"> File Type</div>
                    <div className="slot_section_value">
                        {props.data && props.data.program && props.data.program.asset &&
                        props.data.program.asset._ && props.data.program.asset._.file &&
                        props.data.program.asset._.file['MIME_type']
                            ? props.data.program.asset._.file['MIME_type']
                            : null}
                    </div>
                </div>
                {/* <div className="slot_section"> */}
                {/*    <div className="slot_section_name"> Video Resolution</div> */}
                {/*    <div className="slot_section_value"> 4k</div> */}
                {/* </div> */}
            </div>
        </div>
    );
};

SlotDetails.propTypes = {
    data: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        data: state.live.individualSlot.data,
    };
};

export default withRouter(connect(stateToProps)(SlotDetails));
