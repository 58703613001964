import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
    downloadFile,
    editableAudioAsset,
    editableData,
    editableVideoAsset, hideQuickViewMovieAsset,
    showDialogActionMenu,
    showDialogAddAsset,
} from '../../../actions/asset';

const Header = (props) => {
    const handleDownload = () => {
        const token = localStorage.getItem('authorizationToken');

        if (token && props.runners.length) {
            if (props.data && props.data.file && props.data.file.encode &&
                props.data.file.encode.status === 'COMPLETE') {
                props.download(props.runners[0].public_address,
                    props.runners[0].organization,
                    true, props.data.file.encode.path,
                    token,
                    props.data.file.name.substr(0, props.data.file.name.lastIndexOf('.')) + '.mp4',
                );
            } else if (props.data && props.data.file && props.data.file.encode &&
                props.data.file.encode.status === 'PENDING' &&
                props.data.file.download.status === 'COMPLETE') {
                props.download(props.runners[0].public_address,
                    props.runners[0].organization,
                    false, props.data.file.download.path + '/' + props.data.file.name,
                    token, props.data.file.name);
            }
        }
    };

    const handleEdit = () => {
        if (props.data && props.data.type === 'video') {
            props.editableData(props.data);
            props.editableVideoAsset(props.data);
            props.onClickEdit('video');
        } else if (props.data && props.data.type === 'audio') {
            props.editableData(props.data);
            props.editableAudioAsset(props.data);
            props.onClickEdit('video');
        }
    };

    const handleDelete = () => {
        props.onClickConfirm(props.data, props.data.type);
        props.onClickCancel();
    };

    return (
        <div className="header_div">
            <p>Asset Details</p>
            <div className="action_buttons">
                {props.data && props.data.type === 'video'
                    ? <Button onClick={handleDownload}>Download</Button>
                    : null}
                <Button onClick={handleEdit}>Edit</Button>
                <Button onClick={handleDelete}>Delete</Button>
            </div>
        </div>
    );
};

Header.propTypes = {
    data: PropTypes.object.isRequired,
    download: PropTypes.func.isRequired,
    editableAudioAsset: PropTypes.func.isRequired,
    editableData: PropTypes.func.isRequired,
    editableVideoAsset: PropTypes.func.isRequired,
    runners: PropTypes.arrayOf(
        PropTypes.shape({
            public_address: PropTypes.string.isRequired,
            organization: PropTypes.string.isRequired,
        }),
    ).isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickConfirm: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        runners: state.runner.list,
    };
};

const actionToProps = {
    download: downloadFile,
    editableData: editableData,
    editableVideoAsset,
    editableAudioAsset,
    onClickConfirm: showDialogActionMenu,
    onClickEdit: showDialogAddAsset,
    onClickCancel: hideQuickViewMovieAsset,
};

export default connect(stateToProps, actionToProps)(Header);
