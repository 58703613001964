import { DOWNLOAD_AUDIO_FILE_CANCEL_SET, DOWNLOAD_AUDIO_FILE_DETAILS_SET } from '../constants/audioAssets';

export const setDownloadDetails = (value, index, list) => {
    return {
        type: DOWNLOAD_AUDIO_FILE_DETAILS_SET,
        value,
        index,
        list,
    };
};

export const setDownloadCancel = (list) => {
    return {
        type: DOWNLOAD_AUDIO_FILE_CANCEL_SET,
        list,
    };
};
