import {
    ADD_STREAM_SET,
    CHANNEL_LIVE_STREAMS_FETCH_ERROR,
    CHANNEL_LIVE_STREAMS_FETCH_IN_PROGRESS,
    CHANNEL_LIVE_STREAMS_FETCH_SUCCESS,
} from '../../constants/channel';
import {
    STREAM_START_IN_PROGRESS,
    SLOT_PUSH_IN_PROGRESS,
    STREAM_START_SUCCESS,
    SLOT_PUSH_SUCCESS,
    STREAM_START_ERROR,
    SLOT_PUSH_ERROR, STREAM_STOP_ERROR, STREAM_STOP_SUCCESS, STREAM_STOP_IN_PROGRESS,
    STREAM_CONFIG_UPDATE_SUCCESS,
    STREAM_CONFIG_UPDATE_IN_PROGRESS,
    STREAM_CONFIG_UPDATE_ERROR,
} from '../../constants/slots';

export const stream = (state = {
    list: [],
    current: '',
    inProgress: false,
    configuration: {},
    syncInProgress: false,
}, action) => {
    switch (action.type) {
    case CHANNEL_LIVE_STREAMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CHANNEL_LIVE_STREAMS_FETCH_SUCCESS: {
        const selected = action.liveStreams.filter((v) => v._id === action.current);

        return {
            ...state,
            list: action.liveStreams,
            current: action.current,
            inProgress: false,
            configuration: selected.length
                ? selected[0].configuration
                : {},
        };
    }
    case CHANNEL_LIVE_STREAMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case ADD_STREAM_SET: {
        const selected = state.list.filter((v) => v._id === action.value);

        return {
            ...state,
            current: action.value,
            configuration: selected.length
                ? selected[0].configuration
                : {},
        };
    }
    case STREAM_START_IN_PROGRESS:
    case SLOT_PUSH_IN_PROGRESS:
    case STREAM_STOP_IN_PROGRESS:
    case STREAM_CONFIG_UPDATE_IN_PROGRESS:
        return {
            ...state,
            syncInProgress: true,
        };

    case STREAM_START_SUCCESS:
    case SLOT_PUSH_SUCCESS:
    case STREAM_START_ERROR:
    case SLOT_PUSH_ERROR:
    case STREAM_STOP_SUCCESS:
    case STREAM_STOP_ERROR:
    case STREAM_CONFIG_UPDATE_ERROR:
        return {
            ...state,
            syncInProgress: false,
        };
    case STREAM_CONFIG_UPDATE_SUCCESS: {
        return {
            ...state,
            syncInProgress: false,
            configuration: action.configuration ? action.configuration : {},
        };
    }
    default:
        return state;
    }
};
