import { combineReducers } from 'redux';
import { DIALOG_ADD_ASSET_HIDE, EDITABLE_ASSET_VIDEO_DATA } from '../../constants/asset';
import {
    CHAPTER_CATEGORY_SET,
    CHAPTER_GENRE_SET,
    CHAPTER_LOCATION_SET,
    CHAPTER_NAME_SET,
    CHAPTER_OBJECTS_SET,
    CHAPTER_PEOPLE_SET,
    DESCRIPTION_SET,
    EDIT_CHAPTER_DETAILS_HIDE,
    EDIT_CHAPTER_DETAILS_SHOW,
} from '../../constants/chapterMarking';

const description = (state = '', action) => {
    switch (action.type) {
    case DESCRIPTION_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.description
            ? action.value.description : '';
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    case EDIT_CHAPTER_DETAILS_HIDE:
        return '';
    default:
        return state;
    }
};

const genre = (state = [], action) => {
    switch (action.type) {
    case CHAPTER_GENRE_SET:
        return action.value;
    case EDIT_CHAPTER_DETAILS_HIDE:
        return [];
    default:
        return state;
    }
};

const category = (state = [], action) => {
    switch (action.type) {
    case CHAPTER_CATEGORY_SET:
        return action.value;
    case EDIT_CHAPTER_DETAILS_HIDE:
        return [];
    default:
        return state;
    }
};

const people = (state = [], action) => {
    switch (action.type) {
    case CHAPTER_PEOPLE_SET:
        return action.value;
    case EDIT_CHAPTER_DETAILS_HIDE:
        return [];
    default:
        return state;
    }
};

const objects = (state = [], action) => {
    switch (action.type) {
    case CHAPTER_OBJECTS_SET:
        return action.value;
    case EDIT_CHAPTER_DETAILS_HIDE:
        return [];
    default:
        return state;
    }
};

const location = (state = [], action) => {
    switch (action.type) {
    case CHAPTER_LOCATION_SET:
        return action.value;
    case EDIT_CHAPTER_DETAILS_HIDE:
        return [];
    default:
        return state;
    }
};

const chapterName = (state = '', action) => {
    switch (action.type) {
    case CHAPTER_NAME_SET:
        return action.value;
    case EDIT_CHAPTER_DETAILS_HIDE:
        return '';
    default:
        return state;
    }
};

const edit = (state = false, action) => {
    switch (action.type) {
    case EDIT_CHAPTER_DETAILS_SHOW:
        return true;
    case EDIT_CHAPTER_DETAILS_HIDE:
        return false;
    default:
        return state;
    }
};

export default combineReducers({
    description,
    genre,
    category,
    people,
    objects,
    location,
    chapterName,
    edit,
});
