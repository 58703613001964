export const PROFESSIONS_LIST_SET = 'PROFESSIONS_LIST_SET';

export const PEOPLE_FETCH_IN_PROGRESS = 'PEOPLE_FETCH_IN_PROGRESS';
export const PEOPLE_FETCH_SUCCESS = 'PEOPLE_FETCH_SUCCESS';
export const PEOPLE_FETCH_ERROR = 'PEOPLE_FETCH_ERROR';

export const PEOPLE_ADD_IN_PROGRESS = 'PEOPLE_ADD_IN_PROGRESS';
export const PEOPLE_ADD_SUCCESS = 'PEOPLE_ADD_SUCCESS';
export const PEOPLE_ADD_ERROR = 'PEOPLE_ADD_ERROR';

export const PEOPLE_DELETE_IN_PROGRESS = 'PEOPLE_DELETE_IN_PROGRESS';
export const PEOPLE_DELETE_SUCCESS = 'PEOPLE_DELETE_SUCCESS';
export const PEOPLE_DELETE_ERROR = 'PEOPLE_DELETE_ERROR';
