import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setTokensToSend } from '../../../../actions/account';
import TextField from '../../../../components/TextField';

const TokensToSendTextField = (props) => {
    const onChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            id="tokens-to-send_text_field"
            label="Tokens To Send"
            name="tokensToSend"
            type="number"
            value={props.value}
            variant="outlined"
            onChange={onChange}/>
    );
};

TokensToSendTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.account.tokensToSend,
    };
};

const actionsToProps = {
    onChange: setTokensToSend,
};

export default connect(stateToProps, actionsToProps)(TokensToSendTextField);
