import * as PropTypes from 'prop-types';
import React from 'react';

const OAuth = (props) => {
    return (
        <div className="no_data">
            {props.match.params.status &&
            props.match.params.status === 'success'
                ? 'Google authentication completed successfully.'
                : props.match.params.status === 'fail'
                    ? 'Error occurred while google authentication.'
                    : ''}
        </div>
    );
};

OAuth.propTypes = {
    match: PropTypes.shape(
        {
            params: PropTypes.shape({
                status: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
};

export default OAuth;
