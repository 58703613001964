import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DataTable from '../../../../../components/DataTable';
import PositionSelectField from './PositionSelectField';
import FrequencySelectField from './FrequencySelectField';
import './index.css';
import IntervalSelectField from './IntervalSelectField';

const AdsTable = (props) => {
    const [index, setIndex] = React.useState([]);

    const options = {
        serverSide: false,
        pagination: false,
        selectableRows: true,
        rowsSelected: index,
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress className="loader_css"/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Sort for ${column.label}`,
            },
        },
        onCellClick: (colData, cellMeta) => {
            if (cellMeta.colIndex === 3 ||
                cellMeta.colIndex === 4 ||
                cellMeta.colIndex === 5) {
                return;
            }

            const ind = [...index];
            const alreadyExit = ind.filter((value) => value === cellMeta.rowIndex);
            if (alreadyExit.length === 0) {
                ind.push(cellMeta.rowIndex);
                setIndex([...ind]);
            } else {
                ind.map((value, index1) => {
                    if (value === cellMeta.rowIndex) {
                        ind.splice(index1, 1);
                    }
                });
                setIndex([...ind]);
            }
        },
    };

    const columns = [{
        name: 'ads',
        label: 'Ads',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'type',
        label: 'Type',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'duration',
        label: 'Duration',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'frequency',
        label: 'Frequency',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'interval',
        label: 'Interval (in minutes)',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'position',
        label: 'Position',
        options: {
            filter: false,
            sort: true,
        },
    }];

    const tableData = props.data && props.data.length
        ? props.data.map((item, index) =>
            [
                <div key={index} title={item.ads}>
                    {item.ads}
                </div>,
                item.type,
                item.duration,
                <FrequencySelectField
                    key={index}
                    index={index}
                    value={item.frequency
                        ? item.frequency
                        : 0}/>,
                <IntervalSelectField
                    key={index}
                    index={index}
                    value={item.interval
                        ? item.interval
                        : 'none'}/>,
                <PositionSelectField
                    key={index}
                    index={index}
                    value={item.position
                        ? item.position
                        : 'none'}/>,
            ])
        : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={tableData}
                name=""
                options={options}/>
        </>
    );
};

AdsTable.propTypes = {
    data: PropTypes.array.isRequired,
    inProgress: PropTypes.bool.isRequired,
    slot: PropTypes.object.isRequired,
    slots: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
    return {
        slot: state.live.bugs.slot,
        slots: state.live.slot.list,
        data: state.live.ads.adsList.list,
        inProgress: state.live.ads.adsList.inProgress,
    };
};

export default connect(stateToProps)(AdsTable);
