import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideEditChapters, showEditChapters, updateAssetChapter } from '../../../actions/chapterMarking';

const EditButton = (props) => {
    const handleUpdate = () => {
        props.hideEditChapters();

        const data = {};
        const filteredGenres = data.genres = props.genres.map((item) => item.value)
            .filter((value) => value);
        if (filteredGenres.length) {
            data.genres = filteredGenres;
        }

        const filteredPeople = props.people.map((item) => item._id)
            .filter((value) => value);

        if (filteredPeople.length) {
            data.people = filteredPeople;
        }

        const filteredCategories = props.categories.map((item) => item._id)
            .filter((value) => value);

        if (filteredCategories.length) {
            data.categories = filteredCategories;
        }
        if (props.chapterName) {
            data.name = props.chapterName;
        }
        if (props.description) {
            data.description = props.description;
        }
        if (props.tags.length) {
            data.tags = props.tags;
        }
        if (props.objects.length) {
            data.objects = props.objects;
        }
        if (props.locations.length) {
            data.locations = props.locations;
        }
        if (props.chapter && props.chapter.asset) {
            props.updateAssetChapter(props.chapter.asset._id,
                props.chapter._id,
                data,
                localStorage.getItem('authorizationToken'));
        }
    };

    return (
        props.value
            ? <Button onClick={handleUpdate}>
                Update
            </Button>
            : <Button onClick={props.showEditChapters}>
                Edit
            </Button>
    );
};

EditButton.propTypes = {
    hideEditChapters: PropTypes.func.isRequired,
    locations: PropTypes.array.isRequired,
    objects: PropTypes.array.isRequired,
    showEditChapters: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    updateAssetChapter: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            _id: PropTypes.string.isRequired,
        }),
    ),
    chapter: PropTypes.shape(
        {
            _id: PropTypes.string.isRequired,
            asset: PropTypes.shape({
                _id: PropTypes.string.isRequired,
            }),
        }),
    chapterName: PropTypes.string,
    description: PropTypes.string,
    genres: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ),
    people: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            _id: PropTypes.string.isRequired,
        }),
    ),
};

const stateToProps = (state) => {
    return {
        value: state.chapterMarking.new.edit,
        description: state.chapterMarking.new.description,
        genres: state.chapterMarking.new.genre,
        categories: state.chapterMarking.new.category,
        people: state.chapterMarking.new.people,
        objects: state.chapterMarking.new.objects,
        locations: state.chapterMarking.new.location,
        chapterName: state.chapterMarking.new.chapterName,
        tags: state.asset.new.tags,
    };
};

const actionToProps = {
    showEditChapters,
    hideEditChapters,
    updateAssetChapter,
};

export default connect(stateToProps, actionToProps)(EditButton);
