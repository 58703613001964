import {
    SLOT_ADD_ERROR,
    SLOT_ADD_IN_PROGRESS,
    SLOT_ADD_SUCCESS,
    SLOT_DELETE_ERROR,
    SLOT_DELETE_IN_PROGRESS,
    SLOT_DELETE_SUCCESS,
    SLOT_PUSH_ERROR,
    SLOT_PUSH_IN_PROGRESS,
    SLOT_PUSH_SUCCESS,
    SLOT_SET,
    SLOT_UPDATE_ERROR,
    SLOT_UPDATE_IN_PROGRESS,
    SLOT_UPDATE_SUCCESS,
    SLOTS_FETCH_ERROR,
    SLOTS_FETCH_IN_PROGRESS,
    SLOTS_FETCH_SUCCESS,
} from '../../constants/slots';

export const slot = (state = {
    list: [],
    current: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case SLOTS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SLOTS_FETCH_SUCCESS:
        return {
            ...state,
            list: action.slots,
            inProgress: false,
        };
    case SLOTS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case SLOT_ADD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SLOT_ADD_SUCCESS: {
        const list = state.list;

        list.push(action.slot);

        return {
            ...state,
            list,
            inProgress: action.slot && action.slot.name === 'Slot8' ? false : state.inProgress,
        };
    }
    case SLOT_ADD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case SLOT_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SLOT_UPDATE_SUCCESS:
        return {
            ...state,
            list: action.slot,
            inProgress: false,
        };
    case SLOT_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case SLOT_DELETE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SLOT_DELETE_SUCCESS:
        return {
            ...state,
            list: action.slot,
            inProgress: false,
        };
    case SLOT_DELETE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case SLOT_SET:
        return {
            ...state,
            current: action.slot,
        };
    case SLOT_PUSH_ERROR:
    case SLOT_PUSH_SUCCESS:
    case SLOT_PUSH_IN_PROGRESS:
        return {
            ...state,
        };
    default:
        return state;
    }
};
