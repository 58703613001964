import * as PropTypes from 'prop-types';
import React from 'react';
import '../MCQType/index.css';
import { connect } from 'react-redux';
import {
    addInteractionsToInteractiveVideo, deleteInteractionOfInteractiveVideo,
    getInteractiveVideos,
    setQuestionsList,
    setQuestionType, updateInteractionOfInteractiveVideo,
} from '../../../actions/interactiveVideo';
import CancelButton from '../MCQType/CancelButton';
import DeleteButton from '../MCQType/DeleteButton';
import EditButton from '../MCQType/EditButton';
import QuestionTextArea from '../MCQType/QuestionTextArea';
import SaveButton from '../MCQType/SaveButton';
import AnswerTextField from './AnswerTextField';

const TextType = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disable && props.edit) {
            handleEdit();
            return;
        }

        if (event.key === 'Enter' && !disable) {
            handleSave();
        }
    };

    const fetchDataToSend = () => {
        return {
            startAt: props.player.currentTime(),
            type: 'QUIZ',
            question: {
                title: props.question,
                type: 'TEXT_INPUT',
            },
            timeLimit: 10,
            answers: [props.default],
        };
    };

    const handleSave = () => {
        const data = fetchDataToSend();

        props.addInteraction(props.currentInteractiveVideo && props.currentInteractiveVideo._id,
            data, localStorage.getItem('authorizationToken'), (error) => {
                if (!error) {
                    props.player.play();
                    props.setQuestionType('');
                }
            });
    };

    const handleEdit = () => {
        const data = fetchDataToSend();

        props.updateInteraction(data, props.currentInteractiveVideo._id,
            props.currentInteraction._id, localStorage.getItem('authorizationToken'), (error) => {
                if (!error) {
                    props.player.play();
                    props.setQuestionType('');
                }
            });
    };

    const handleDelete = () => {
        const token = localStorage.getItem('authorizationToken');

        props.deleteInteraction(props.currentInteractiveVideo._id,
            props.currentInteraction._id, token,
            (error) => {
                if (error) return;

                props.getInteractiveVideos(props.currentInteractiveVideo &&
                    props.currentInteractiveVideo.asset &&
                    props.currentInteractiveVideo.asset._id,
                token);
                props.player.play();
                props.setQuestionType('');
            });
    };

    const disable = props.question === '' || props.default === '';
    const editDisable = props.question === '' && props.default === '';

    return (
        <div className="question_details mcq_type">
            <div className="heading_row">
                <p className="heading">Text Type</p>
                <div className="buttons_section">
                    <CancelButton/>
                    {props.edit
                        ? <EditButton disable={disable} onClick={handleEdit}/>
                        : <SaveButton disable={editDisable} onClick={handleSave}/>}
                    {props.edit && <DeleteButton onClick={handleDelete}/>}
                </div>
            </div>
            <form
                noValidate
                autoComplete="off"
                onKeyPress={handleKeyPress}
                onSubmit={(e) => {
                    e.preventDefault();
                }}>
                <QuestionTextArea/>
                <AnswerTextField/>
            </form>
        </div>
    );
};

TextType.propTypes = {
    addInteraction: PropTypes.func.isRequired,
    default: PropTypes.string.isRequired,
    deleteInteraction: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
    getInteractiveVideos: PropTypes.func.isRequired,
    player: PropTypes.object.isRequired,
    question: PropTypes.string.isRequired,
    setQuestionType: PropTypes.func.isRequired,
    setQuestionsList: PropTypes.func.isRequired,
    updateInteraction: PropTypes.func.isRequired,
    currentInteraction: PropTypes.shape({
        _id: PropTypes.string.isRequired,
    }),
    currentInteractiveVideo: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        asset: PropTypes.shape({
            _id: PropTypes.string.isRequired,
        }),
    }),
};

const stateToProps = (state) => {
    return {
        default: state.interactiveVideo.options.default,
        edit: state.interactiveVideo.edit,
        player: state.interactiveVideo.player,
        question: state.interactiveVideo.question,
        currentInteraction: state.interactiveVideo.current.interaction,
        currentInteractiveVideo: state.interactiveVideo.current.data,
    };
};

const actionsToProps = {
    setQuestionsList,
    setQuestionType,
    getInteractiveVideos,
    addInteraction: addInteractionsToInteractiveVideo,
    updateInteraction: updateInteractionOfInteractiveVideo,
    deleteInteraction: deleteInteractionOfInteractiveVideo,
};

export default connect(stateToProps, actionsToProps)(TextType);
