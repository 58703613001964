import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchAccounts, setAccountAddress } from '../../actions/account';
import Cards from './Cards';
import ConnectAccount from './ConnectAccount';
import './index.css';
import SuccessDialog from './ConnectAccount/SuccessDialog';
import TransactionsTable from './TransactionsTable';
import { showMessage } from '../../actions/snackbar';

class Accounts extends React.Component {
    componentDidMount () {
        this.props.fetchAccounts();
    }

    render () {
        return (
            <div className="accounts">
                <p className="heading_text">Accounts</p>
                <p className="title">{this.props.accounts && this.props.accounts.length ? '' : 'No '}Active Accounts</p>
                {this.props.accounts && this.props.accounts.length
                    ? <div className="content">
                        <Cards account={this.props.accounts[0]}/>
                        <TransactionsTable/>
                    </div>
                    : <ConnectAccount/>}
                <SuccessDialog/>
            </div>
        );
    }
}

Accounts.propTypes = {
    fetchAccounts: PropTypes.func.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    accounts: PropTypes.arrayOf(
        PropTypes.shape({
            address: PropTypes.string,
            isPrimary: PropTypes.string,
            status: PropTypes.string,
        }),
    ),
};

const stateToProps = (state) => {
    return {
        accounts: Object.values(state.account.connected.list),
    };
};

const actionsToProps = {
    setAccountAddress,
    fetchAccounts,
    showMessage,
};

export default connect(stateToProps, actionsToProps)(Accounts);
