import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTags } from '../../../../../actions/asset';
import Exclude from './Exclude';
import Include from './Include';

class Tags extends Component {
    constructor (props) {
        super(props);
        this.fetch = this.fetch.bind(this);
    }

    componentDidMount () {
        if (this.props.tags.length) {
            return;
        }

        this.fetch();
    }

    fetch () {
        if (this.props.inProgress) {
            return;
        }

        const token = localStorage.getItem('authorizationToken');

        if (token) {
            this.props.fetch(token);
        }
    }

    render () {
        const Items = this.props.tags.length
            ? this.props.tags
            : [];

        return (
            <>
                <Include
                    inProgress={this.props.inProgress}
                    items={Items}/>
                <Exclude
                    inProgress={this.props.inProgress}
                    items={Items}/>
            </>
        );
    }
}

Tags.propTypes = {
    fetch: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    tags: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.live.dynamic.category,
        tags: state.asset.tags.list,
        inProgress: state.asset.new.tags.inProgress,
    };
};

const actionsToProps = {
    fetch: fetchTags,
};

export default connect(stateToProps, actionsToProps)(Tags);
