import Axios from 'axios';
import {
    ACTIVE_TAB_SET,
    ADD_DAY_TYPE_SET,
    CALENDAR_OPEN,
    CURRENT_UPDATING_SLOT_SET,
    DIALOG_UPDATE_PROGRAM_HIDE,
    DIALOG_UPDATE_PROGRAM_SHOW,
    PLAY_OUT_FETCH_ERROR,
    PLAY_OUT_FETCH_IN_PROGRESS,
    PLAY_OUT_FETCH_SUCCESS,
    PLAY_OUT_REFRESH_ERROR,
    PLAY_OUT_REFRESH_SUCCESS,
    PROGRAM_ADD_ERROR,
    PROGRAM_ADD_IN_PROGRESS,
    PROGRAM_ADD_SUCCESS,
    PROGRAM_DELETE_ERROR,
    PROGRAM_DELETE_IN_PROGRESS,
    PROGRAM_DELETE_SUCCESS,
    PROGRAM_OVERLAY_ADD_ERROR,
    PROGRAM_OVERLAY_ADD_IN_PROGRESS,
    PROGRAM_OVERLAY_ADD_SUCCESS,
    PROGRAM_OVERLAY_DELETE_ERROR,
    PROGRAM_OVERLAY_DELETE_IN_PROGRESS,
    PROGRAM_OVERLAY_DELETE_SUCCESS,
    PROGRAM_OVERLAY_UPDATE_ERROR,
    PROGRAM_OVERLAY_UPDATE_IN_PROGRESS,
    PROGRAM_OVERLAY_UPDATE_SUCCESS,
    PROGRAM_UPDATE_ERROR,
    PROGRAM_UPDATE_IN_PROGRESS,
    PROGRAM_UPDATE_SUCCESS,
    PROGRAMS_CLEAR_ERROR,
    PROGRAMS_CLEAR_IN_PROGRESS,
    PROGRAMS_CLEAR_SUCCESS,
    PROGRAMS_FETCH_ERROR,
    PROGRAMS_FETCH_IN_PROGRESS,
    PROGRAMS_FETCH_SUCCESS,
    PROGRAMS_FILL_ERROR,
    PROGRAMS_FILL_IN_PROGRESS,
    PROGRAMS_FILL_SUCCESS,
    PROGRAMS_UPDATE_ERROR,
    PROGRAMS_UPDATE_IN_PROGRESS,
    PROGRAMS_UPDATE_SET,
    PROGRAMS_UPDATE_SUCCESS,
    SELECTED_DATE_SET,
    VIDEO_PLAYER_TYPE_SET,
    WEEK_START_DATE_SET,
} from '../constants/program';
import {
    urlAddProgram,
    urlAddProgramOverlay,
    urlClearPrograms,
    urlDeleteProgram,
    urlDeleteProgramOverlay,
    urlFetchPlayOut,
    urlFetchProgram,
    urlFillPrograms,
    urlUpdateProgram,
    urlUpdateProgramOverlay,
} from '../constants/url';

export const setSelectedDate = (date) => {
    return {
        type: SELECTED_DATE_SET,
        date,
    };
};

export const setWeekStartDate = (date) => {
    return {
        type: WEEK_START_DATE_SET,
        date,
    };
};

export const setActiveTab = (value) => {
    return {
        type: ACTIVE_TAB_SET,
        value,
    };
};

export const openCalendar = (value) => {
    return {
        type: CALENDAR_OPEN,
        value,
    };
};

const fetchProgramsInProgress = () => {
    return {
        type: PROGRAMS_FETCH_IN_PROGRESS,
    };
};

const fetchProgramsSuccess = (programs) => {
    const data = {};

    programs.forEach(
        (program) => {
            data[program._id] = program;
        });

    return {
        type: PROGRAMS_FETCH_SUCCESS,
        programs: data,
    };
};

const fetchProgramsError = (message) => {
    return {
        type: PROGRAMS_FETCH_ERROR,
        message,
    };
};

export const fetchPrograms = (channelId, liveStreamId, slotId, cb) => (dispatch) => {
    dispatch(fetchProgramsInProgress());
    dispatch(setCurrentUpdatingSlot(slotId));
    const URL = urlFetchProgram(channelId, liveStreamId, slotId);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchProgramsSuccess(res.data.result));
            dispatch(setCurrentUpdatingSlot(''));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchProgramsError(error.response ? error.response.data.message : error.message,
            ));
            dispatch(setCurrentUpdatingSlot(''));
            cb(error);
        });
};

const addProgramInProgress = () => {
    return {
        type: PROGRAM_ADD_IN_PROGRESS,
    };
};

const addProgramSuccess = (program) => {
    return {
        type: PROGRAM_ADD_SUCCESS,
        program,
    };
};

const addProgramError = (message) => {
    return {
        type: PROGRAM_ADD_ERROR,
        message,
    };
};

export const addProgram = (channelId = '5dbae42112fbe624c336e3f6',
    liveStreamId = '5dddc4dac92679a87b33a4dc',
    slotId = '5de66062fed9ee295667e513', data, cb) => (dispatch) => {
    dispatch(addProgramInProgress());
    dispatch(setCurrentUpdatingSlot(slotId));
    const URL = urlAddProgram(channelId, liveStreamId, slotId);
    Axios.post(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(addProgramSuccess(res.data.result));
            dispatch(setCurrentUpdatingSlot(''));
            cb(null);
        })
        .catch((error) => {
            dispatch(addProgramError(error.response ? error.response.data.message : error.message,
            ));
            dispatch(setCurrentUpdatingSlot(''));
            cb(error);
        });
};

const updateProgramInProgress = () => {
    return {
        type: PROGRAM_UPDATE_IN_PROGRESS,
    };
};

const updateProgramSuccess = (program) => {
    return {
        type: PROGRAM_UPDATE_SUCCESS,
        program,
    };
};

const updateProgramError = (message) => {
    return {
        type: PROGRAM_UPDATE_ERROR,
        message,
    };
};

export const updateProgram = (channelId, liveStreamId, slotId, programId, data, cb) => (dispatch) => {
    dispatch(updateProgramInProgress());
    const URL = urlUpdateProgram(channelId, liveStreamId, slotId, programId);
    Axios.put(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(updateProgramSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(updateProgramError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const deleteProgramInProgress = () => {
    return {
        type: PROGRAM_DELETE_IN_PROGRESS,
    };
};

const deleteProgramSuccess = (slot, message) => {
    return {
        type: PROGRAM_DELETE_SUCCESS,
        slot,
        message,
    };
};

const deleteProgramError = (message) => {
    return {
        type: PROGRAM_DELETE_ERROR,
        message,
    };
};

const setCurrentUpdatingSlot = (value) => {
    return {
        type: CURRENT_UPDATING_SLOT_SET,
        value,
    };
};

export const deleteProgram = (channelId, liveStreamId, slotId, programId, count, cb) => (dispatch) => {
    dispatch(deleteProgramInProgress());
    dispatch(setCurrentUpdatingSlot(slotId));
    const URL = urlDeleteProgram(channelId, liveStreamId, slotId, programId);
    Axios.delete(URL,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(deleteProgramSuccess({
                res: res.data.result,
                slotId,
            }, `Successfully deleted ${count}`));
            dispatch(setCurrentUpdatingSlot(''));
            cb(null);
        })
        .catch((error) => {
            dispatch(deleteProgramError(error.response ? error.response.data.message : error.message,
            ));
            dispatch(setCurrentUpdatingSlot(''));
            cb(error);
        });
};

const addProgramOverlayInProgress = () => {
    return {
        type: PROGRAM_OVERLAY_ADD_IN_PROGRESS,
    };
};

const addProgramOverlaySuccess = (program) => {
    return {
        type: PROGRAM_OVERLAY_ADD_SUCCESS,
        program,
    };
};

const addProgramOverlayError = (message) => {
    return {
        type: PROGRAM_OVERLAY_ADD_ERROR,
        message,
    };
};

export const addProgramOverlay = (channelId, liveStreamId, slotId, programId, data, cb) => (dispatch) => {
    dispatch(addProgramOverlayInProgress());
    const URL = urlAddProgramOverlay(channelId, liveStreamId, slotId, programId);
    Axios.post(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(addProgramOverlaySuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(addProgramOverlayError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const updateProgramOverlayInProgress = () => {
    return {
        type: PROGRAM_OVERLAY_UPDATE_IN_PROGRESS,
    };
};

const updateProgramOverlaySuccess = (program) => {
    return {
        type: PROGRAM_OVERLAY_UPDATE_SUCCESS,
        program,
    };
};

const updateProgramOverlayError = (message) => {
    return {
        type: PROGRAM_OVERLAY_UPDATE_ERROR,
        message,
    };
};

export const updateProgramOverlay = (channelId,
    liveStreamId,
    slotId,
    programId,
    overlayId,
    data,
    cb) => (dispatch) => {
    dispatch(updateProgramOverlayInProgress());
    const URL = urlUpdateProgramOverlay(channelId, liveStreamId, slotId, programId, overlayId);
    Axios.put(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(updateProgramOverlaySuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(updateProgramOverlayError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const deleteProgramOverlayInProgress = () => {
    return {
        type: PROGRAM_OVERLAY_DELETE_IN_PROGRESS,
    };
};

const deleteProgramOverlaySuccess = (program) => {
    return {
        type: PROGRAM_OVERLAY_DELETE_SUCCESS,
        program,
    };
};

const deleteProgramOverlayError = (message) => {
    return {
        type: PROGRAM_OVERLAY_DELETE_ERROR,
        message,
    };
};

export const deleteProgramOverlay = (channelId,
    liveStreamId,
    slotId,
    programId,
    overlayId,
    cb) => (dispatch) => {
    dispatch(deleteProgramOverlayInProgress());
    const URL = urlDeleteProgramOverlay(channelId, liveStreamId, slotId, programId, overlayId);
    Axios.delete(URL,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(deleteProgramOverlaySuccess({
                res: res.data.result,
                programId,
                overlayId,
            }));
            cb(null);
        })
        .catch((error) => {
            dispatch(deleteProgramOverlayError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const fetchPlayOutInProgress = () => {
    return {
        type: PLAY_OUT_FETCH_IN_PROGRESS,
    };
};

const fetchPlayOutSuccess = (data) => {
    return {
        type: PLAY_OUT_FETCH_SUCCESS,
        stream: data.status === 'running' ? data.stream : {},
        status: data.status,
    };
};

const fetchPlayOutError = (message) => {
    return {
        type: PLAY_OUT_FETCH_ERROR,
        message,
    };
};

export const fetchPlayOut = (channelId, liveStreamId, cb) => (dispatch) => {
    dispatch(fetchPlayOutInProgress());

    // send the time in milli-seconds
    const URL = urlFetchPlayOut(channelId, liveStreamId);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchPlayOutSuccess(res.data && res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchPlayOutError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

export const showUpdateProgramDialog = () => {
    return {
        type: DIALOG_UPDATE_PROGRAM_SHOW,
    };
};

export const hideUpdateProgramDialog = () => {
    return {
        type: DIALOG_UPDATE_PROGRAM_HIDE,
    };
};

const updateProgramsInProgress = () => {
    return {
        type: PROGRAMS_UPDATE_IN_PROGRESS,
    };
};

const updateProgramsSuccess = (program) => {
    return {
        type: PROGRAMS_UPDATE_SUCCESS,
        program,
    };
};

const updateProgramsError = (message) => {
    return {
        type: PROGRAMS_UPDATE_ERROR,
        message,
    };
};

export const updatePrograms = (channelId, liveStreamId, slotId, data, cb) => (dispatch) => {
    dispatch(updateProgramsInProgress());
    dispatch(setCurrentUpdatingSlot(slotId));
    const URL = urlAddProgram(channelId, liveStreamId, slotId);
    Axios.put(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(updateProgramsSuccess(res.data.result));
            dispatch(setCurrentUpdatingSlot(''));
            cb(null);
        })
        .catch((error) => {
            dispatch(updateProgramsError(error.response.data.message));
            dispatch(setCurrentUpdatingSlot(''));
            cb(error);
        });
};

const fillProgramsInProgress = () => {
    return {
        type: PROGRAMS_FILL_IN_PROGRESS,
    };
};

const fillProgramsSuccess = (programs) => {
    const data = {};

    programs.forEach(
        (program) => {
            data[program._id] = program;
        });

    return {
        type: PROGRAMS_FILL_SUCCESS,
        programs: data,
    };
};

const fillProgramsError = (message) => {
    return {
        type: PROGRAMS_FILL_ERROR,
        message,
    };
};

export const fillPrograms = (channelId, liveStreamId, slotId, data, cb) => (dispatch) => {
    dispatch(fillProgramsInProgress());
    const URL = urlFillPrograms(channelId, liveStreamId, slotId);
    Axios.put(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(fillProgramsSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(fillProgramsError(error.response.data.message));
            cb(error);
        });
};

export const setAddDayType = (value) => {
    return {
        type: ADD_DAY_TYPE_SET,
        value,
    };
};

export const setVideoPlayerType = (value) => {
    return {
        type: VIDEO_PLAYER_TYPE_SET,
        value,
    };
};

const refreshPlayOutSuccess = (data) => {
    return {
        type: PLAY_OUT_REFRESH_SUCCESS,
        stream: data.status === 'running' ? data.stream : {},
    };
};

const refreshPlayOutError = (message) => {
    return {
        type: PLAY_OUT_REFRESH_ERROR,
        message,
    };
};
export const refreshPlayOut = (channelId, liveStreamId) => (dispatch) => {
    const URL = urlFetchPlayOut(channelId, liveStreamId);

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(refreshPlayOutSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(refreshPlayOutError(error.response ? error.response.data.message : error.message,
            ));
        });
};

export const setUpdatedPrograms = (value) => {
    return {
        type: PROGRAMS_UPDATE_SET,
        value,
    };
};

const clearProgramsInProgress = () => {
    return {
        type: PROGRAMS_CLEAR_IN_PROGRESS,
    };
};

const clearProgramsSuccess = (message) => {
    return {
        type: PROGRAMS_CLEAR_SUCCESS,
        message,
    };
};

const clearProgramsError = (message) => {
    return {
        type: PROGRAMS_CLEAR_ERROR,
        message,
    };
};

export const clearPrograms = (channelId, liveStreamId, slotId, cb) => (dispatch) => {
    dispatch(clearProgramsInProgress());
    dispatch(setCurrentUpdatingSlot(slotId));

    const URL = urlClearPrograms(channelId, liveStreamId, slotId);

    Axios.delete(URL,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(clearProgramsSuccess('Cleared Slot Programs!'));
            dispatch(setCurrentUpdatingSlot(''));
            cb(null);
        })
        .catch((error) => {
            dispatch(clearProgramsError(error.response ? error.response.data.message : error.message));
            dispatch(setCurrentUpdatingSlot(''));
            cb(error);
        });
};
