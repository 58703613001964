import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setInteractiveSave, setQuestionType } from '../../../actions/interactiveVideo';
import SaveButton from '../MCQType/SaveButton';
import AnswerTextField from './AnswerTextField';

const TextType = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disable) {
            handleSave();
        }
    };

    const handleChange = (value) => {
        const obj = {
            ...props.answers,
            [props.value._id]: value,
        };

        props.onChange(obj);
    };

    const handleSave = () => {
        props.player.play();
    };

    const disable = props.answers[props.value && props.value._id] === '' || !props.answers[props.value && props.value._id];

    return (
        <div className="options_section">
            <form
                noValidate
                autoComplete="off"
                onKeyPress={handleKeyPress}
                onSubmit={(e) => {
                    e.preventDefault();
                }}>
                <AnswerTextField
                    id={props.value && props.value._id}
                    value={props.answers && props.answers[props.value && props.value._id]}
                    onChange={handleChange}/>
                <SaveButton disable={disable} onClick={handleSave}/>
            </form>
        </div>
    );
};

TextType.propTypes = {
    answers: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    setQuestionType: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        player: state.interactiveVideo.player,
        answers: state.interactiveVideo.answers,
    };
};

const actionToProps = {
    onChange: setInteractiveSave,
    setQuestionType,
};

export default connect(stateToProps, actionToProps)(TextType);
