import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';
import { connect } from 'react-redux';
import TextToSpeech from '../../../asset/Audio/AddAssetDialog/TextToSpeech';
import TagsTextField from './TagsTextField';

const AudioMetaData = (props) => {
    const type = props.assetCategoriesList && props.assetCategoriesList.length > 0 &&
        props.assetCategoriesList.filter((value) => value._id === props.assetCategory);

    return (
        <div className="meta_data">
            <p className="heading_text">Meta data for distribution</p>
            <div className="row">
                <div className="column">
                    <TagsTextField/>
                </div>
                <div className="column">
                    {type && type.length > 0 && (type[0].name === 'text to speech')
                        ? <TextToSpeech/>
                        : null}
                </div>
            </div>
        </div>
    );
};

AudioMetaData.propTypes = {
    assetCategoriesList: PropTypes.array.isRequired,
    assetCategory: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        assetCategory: state.asset.new.category,
        assetCategoriesList: state.asset.assetCategoriesList.list,
    };
};

export default connect(stateToProps)(AudioMetaData);
