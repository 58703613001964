import { combineReducers } from 'redux';
import { USER_FORGOT_PASSWORD_EMAIL_SET } from '../constants/forgotPassword';

const email = (state = {
    value: '',
    valid: false,
}, action) => {
    if (action.type === USER_FORGOT_PASSWORD_EMAIL_SET) {
        return {
            value: action.value,
            valid: action.valid,
        };
    }

    return state;
};

export default combineReducers({
    email,
});
