import { IconButton, Popover } from '@material-ui/core';
import React, { useState } from 'react';
import ClassNames from 'classnames';
import Icon from '../../../components/Icon';
import LanguageSelectField from '../../AddAssets/Tabs/MetadataForDistribution/LanguageSelectField';
import TagsTextField from '../../AddAssets/Tabs/MetadataForDistribution/TagsTextField';
import PeopleSelectField from '../EditPanel/PeopleSelectField';

const Filter = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;

    return (
        <div className="chapter_filter">
            <IconButton
                aria-label="filter"
                className={ClassNames('filter_button', open ? 'active' : '')}
                onClick={handleClick}>
                <Icon
                    className="filter"
                    icon="filter"/>
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleClose}>
                <div className="filter_popover">
                    <p className="heading">Filter By</p>
                    <TagsTextField/>
                    <PeopleSelectField label="People/Cast"/>
                    <LanguageSelectField/>
                </div>
            </Popover>
        </div>
    );
};

export default Filter;
