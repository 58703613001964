import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchAllBugs, setAddBugs } from '../../../../../actions/bugs';
import DataTable from '../../../../../components/DataTable';
import SkeletonLoader from '../../../../../components/SkeletonLoader/Table';
import './BugsInSlotTable/index.css';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../../../constants/url';

const AllBugsTable = (props) => {
    const {
        skip,
        limit,
        sortBy,
        searchKey,
        order,
        id,
    } = props;
    const token = localStorage.getItem('authorizationToken');

    const [index, setIndex] = React.useState([]);
    const options = {
        serverSide: true,
        pagination: true,
        count: props.count,
        selectableRows: true,
        selectableRowsOnClick: true,
        disableToolbarSelect: true,
        rowsSelected: index,
        onRowsSelect: (currentRowsSelected, allRowsSelected) => {
            const arr = [];
            const index = [];
            allRowsSelected.map((value) => {
                arr.push(props.data[value.index]);
                index.push(value.index);

                return null;
            });

            setIndex([...index]);
            props.setAddBugs([...arr]);
        },
        onSearchChange: (searchText) => {
            props.fetchAllBugs(id, DEFAULT_SKIP, DEFAULT_LIMIT, searchText, sortBy,
                sortBy !== '' ? order : null, token);
        },
        onChangePage: (currentPage) => {
            if (props.data && props.data.length === 0) {
                return;
            }

            props.fetchAllBugs(id, limit * currentPage, limit, searchKey, sortBy, sortBy !== '' ? order : null, token);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.data && props.data.length === 0) {
                return;
            }

            props.fetchAllBugs(id, skip, numberOfRows, searchKey, sortBy, sortBy !== '' ? order : null, token);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.data && props.data.length === 0) {
                return;
            }

            const order = direction === 'asc' ? 1 : -1;
            props.fetchAllBugs(id, skip, limit, searchKey, changedColumn, order, token);
        },
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <SkeletonLoader columns={3}/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Sort for ${column.label}`,
            },
        },
    };

    const columns = [{
        name: 'bugList',
        label: 'Bugs list',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'duration',
        label: 'Duration',
        options: {
            filter: false,
            sort: true,
        },
    }];

    const tableData = props.data && props.data.length
        ? props.data.map((item, index) =>
            [
                <div key={index} title={item.name || (item.file && item.file.name)}>
                    {item.name || (item.file && item.file.name)}
                </div>,
                moment.utc(moment.duration(item.file && item.file.length, 'seconds')
                    .asMilliseconds()).format('HH:mm:ss'),
            ])
        : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={tableData}
                name=""
                options={options}/>
        </>
    );
};

AllBugsTable.propTypes = {
    addBugs: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    fetchAllBugs: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    searchKey: PropTypes.string.isRequired,
    setAddBugs: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    count: PropTypes.number,
    id: PropTypes.string,
    order: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        addBugs: state.live.bugs.addBugs.list,
        data: state.live.bugs.allBugs.list,
        inProgress: state.live.bugs.allBugs.inProgress,
        skip: state.live.bugs.allBugs.skip,
        limit: state.live.bugs.allBugs.limit,
        count: state.live.bugs.allBugs.count,
        searchKey: state.live.bugs.allBugs.searchKey,
        sortBy: state.live.bugs.allBugs.sortBy,
        order: state.live.bugs.allBugs.order,
        id: state.live.bugs.allBugs.id,
    };
};

const actionToProps = {
    fetchAllBugs,
    setAddBugs,
};

export default connect(stateToProps, actionToProps)(AllBugsTable);
