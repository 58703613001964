import { Button, IconButton, Popover, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setSliderType } from '../../../actions/chapterMarking';
import ClassNames from 'classnames';

const options = [{
    value: 'automatic',
    name: 'Automatic',
}, {
    value: 'manual',
    name: 'Manual',
}];

const SliderTypeSelectField = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (value) => {
        props.onChange(value);
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton
                aria-describedby={id}
                className="slider_type_button"
                onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                className="popover slider_type_popover"
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                onClose={handleClose}>
                {options.map((value, index) => {
                    return (
                        <Typography key={index}>
                            <Button
                                className={ClassNames('type_list', props.value === value.value ? 'active' : null)}
                                onClick={() => handleChange(value.value)}>
                                {value.name}
                            </Button>
                        </Typography>
                    );
                })}
            </Popover>
        </>
    );
};

SliderTypeSelectField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.chapterMarking.sliderType,
    };
};

const actionToProps = {
    onChange: setSliderType,
};

export default connect(stateToProps, actionToProps)(SliderTypeSelectField);
