import { Button } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';

const SaveButton = (props) => {
    const handleClick = () => {
        props.onClick();
    };

    return (
        <Button
            className="filled_button"
            disabled={props.disable}
            onClick={handleClick}>
            Save
        </Button>
    );
};

SaveButton.propTypes = {
    disable: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default SaveButton;
