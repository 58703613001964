import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setTemplateName } from '../../../../actions/slot';
import TextField from '../../../../components/TextField';

const TemplateNameTextfield = (props) => {
    const onChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            className="template_name"
            id="template_name_text_field"
            label="Template Name"
            name="templateName"
            value={props.value}
            onChange={onChange}/>
    );
};

TemplateNameTextfield.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    max: PropTypes.number,
    style: PropTypes.string,
    type: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        value: state.live.dynamic.templateName,
    };
};

const actionsToProps = {
    onChange: setTemplateName,
};

export default connect(stateToProps, actionsToProps)(TemplateNameTextfield);
