import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import './permissions.css';

class Permissions extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            checked: true,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (name, event) {
        this.setState({
            [name]: event.target.checked,
        });
    }

    render () {
        const uniqueNames = ['Assets', 'Programming', 'Monetization'];
        const appUrlsData = [
            {
                group: 'Assets',
                _id: 'read_assets',
                name: 'Read Assets',
            },
            {
                group: 'Assets',
                _id: 'create',
                name: 'CreateButton',
            },
            {
                group: 'Assets',
                _id: 'delete',
                name: 'Delete',
            },
            {
                group: 'Assets',
                _id: 'update',
                name: 'Update',
            },
            {
                group: 'Programming',
                _id: 'read_programming',
                name: 'Read Programming',
            },
            {
                group: 'Programming',
                _id: 'create',
                name: 'CreateButton',
            },
            {
                group: 'Programming',
                _id: 'delete',
                name: 'Delete',
            },
            {
                group: 'Programming',
                _id: 'update',
                name: 'Update',
            },
            {
                group: 'Monetization',
                _id: 'read_monetization',
                name: 'Read Monetization',
            },
            {
                group: 'Monetization',
                _id: 'create',
                name: 'CreateButton',
            },
            {
                group: 'Monetization',
                _id: 'delete',
                name: 'Delete',
            },
            {
                group: 'Monetization',
                _id: 'updtae',
                name: 'Update',
            },
        ];
        return (
            <div className="permissions_section">
                <Card className="permissions_checkbox">
                    <h2> Permissions </h2>
                    {
                        uniqueNames.map((names, index) => (
                            <div key={index + names} className="uniquenames_section">
                                <div className="uniquenames_card_header">
                                    <h2>{names} </h2>
                                </div>
                                <div className="urls_sections">
                                    {
                                        appUrlsData.map((value, i) => (
                                            names === value.group
                                                ? <div>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={this.state.checkedB}
                                                                color="secondary"
                                                                value={value._id}
                                                                onChange={(e) => this.handleChange('checkedB', e)}
                                                            />
                                                        }
                                                        label={value.name}
                                                    />
                                                </div>
                                                : ''
                                        ))}
                                </div>
                            </div>
                        ))}
                </Card>
            </div>
        );
    }
}

export default Permissions;
