import { Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideDialogViewhumb, showDialogDeleteThumb } from '../../../../../actions/asset';
import './index.css';
import deleteIcon from '../../../../../assets/delete.svg';

const DialogViewThumb = (props) => {
    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            className="dialog_view_thumb"
            open={props.open}
            onClose={props.onClickCancel}>
            {
                (props.thumbValue === 'horizontal' ||
                props.thumbValue === 'vertical' ||
                props.thumbValue === 'square')
                    ? <div className="view_thumb_section">
                        <div className="view_thumb_header">
                            <div className="view_thumb_action">
                                <img
                                    alt=""
                                    src={deleteIcon}
                                    onClick={() => props.showDialogDeleteThumb(props.thumbValue, 'main')}/>
                            </div>
                        </div>
                        <div className={
                            props.thumbValue === 'horizontal'
                                ? 'view_thumb_preview hor_thumb'
                                : props.thumbValue === 'vertical'
                                    ? 'view_thumb_preview vert_thumb'
                                    : props.thumbValue === 'square'
                                        ? 'view_thumb_preview squa_thumb'
                                        : 'view_thumb_preview'
                        }>
                            <img alt="preview" src={props.thumbDetails}/>
                        </div>
                    </div>
                    : <div className="view_thumb_section">
                        <div className="view_thumb_header">
                            <div className="view_thumb_action">
                                <img
                                    alt=""
                                    src={deleteIcon}
                                    onClick={() => props.showDialogDeleteThumb(props.thumbDetails && props.thumbDetails.type, 'local')}/>
                            </div>
                        </div>
                        <div className={
                            (props.thumbDetails && props.thumbDetails.type) === 'horizontal'
                                ? 'view_thumb_preview hor_thumb'
                                : (props.thumbDetails && props.thumbDetails.type) === 'vertical'
                                    ? 'view_thumb_preview vert_thumb'
                                    : (props.thumbDetails && props.thumbDetails.type) === 'square'
                                        ? 'view_thumb_preview squa_thumb'
                                        : 'view_thumb_preview'
                        }>
                            <img alt="preview" src={props.thumbDetails && props.thumbDetails.img && props.thumbDetails.img.data_url}/>
                        </div>
                    </div>
            }
        </Dialog>
    );
};

DialogViewThumb.propTypes = {
    open: PropTypes.bool.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    showDialogDeleteThumb: PropTypes.func,
    thumbDetails: PropTypes.object,
    thumbValue: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        open: state.asset.dialogViewThumb.open,
        thumbDetails: state.asset.dialogViewThumb.thumbDetails,
        thumbValue: state.asset.dialogViewThumb.thumbValue,
    };
};

const actionsToProps = {
    onClickCancel: hideDialogViewhumb,
    showDialogDeleteThumb: showDialogDeleteThumb,
};

export default connect(stateToProps, actionsToProps)(DialogViewThumb);
