import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setTokensToSend } from '../../../../actions/account';

const MaxButton = (props) => {
    const balance = props.balance && props.balance[0]
        ? `${props.balance[0].amount
            ? props.balance[0].amount / 1000000
            : 0}`
        : 0;

    return (
        <div className="max_tokens">
            <Button onClick={() => props.onChange(balance)}>
                Max
            </Button>
            <p className="helper_text">
                Total Tokens<br/>
                <b>({balance})</b>
            </p>
        </div>
    );
};

MaxButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    balance: PropTypes.arrayOf(
        PropTypes.shape({
            amount: PropTypes.string,
            denom: PropTypes.string,
        }),
    ),
};

const stateToProps = (state) => {
    return {
        balance: state.account.balance.amount,
    };
};

const actionsToProps = {
    onChange: setTokensToSend,
};

export default connect(stateToProps, actionsToProps)(MaxButton);
