import { ReactComponent as CinematicIntelligence } from '../assets/chapterMarking/cinematicIntelligence.svg';
import { ReactComponent as Objects } from '../assets/chapterMarking/objects.svg';
import { ReactComponent as People } from '../assets/chapterMarking/people.svg';

const CustomIcons = {
    CinematicIntelligence,
    People,
    Objects,
};

export default CustomIcons;
