import Axios from 'axios';
import {
    PROFILE_IMAGE__FETCH_ERROR,
    PROFILE_IMAGE__FETCH_IN_PROGRESS,
    PROFILE_IMAGE__FETCH_SUCCESS,
    CHANGE_TIME_FORMAT,
    CLOSE_SEARCH_BAR,
    POPOVER_TIME_FORMAT_HIDE,
    POPOVER_TIME_FORMAT_SHOW,
    SHOW_SEARCH_BAR,
    USER_GLOBAL_SEARCH_SET,
} from '../constants/profile';

import { urlFetchProfileImage } from '../constants/url';

export const showPopoverTimeFormat = (event) => {
    return {
        type: POPOVER_TIME_FORMAT_SHOW,
        anchorEl: event.currentTarget,
    };
};

export const hidePopoverTimeFormat = () => {
    return {
        type: POPOVER_TIME_FORMAT_HIDE,
    };
};

export const setGlobalSearch = (value) => {
    return {
        type: USER_GLOBAL_SEARCH_SET,
        value,
    };
};

export const closeSearchBar = (value) => {
    return {
        type: CLOSE_SEARCH_BAR,
        value,
    };
};

export const showSearchBar = (value) => {
    return {
        type: SHOW_SEARCH_BAR,
        value,
    };
};

export const changeTime = (event) => {
    return {
        type: CHANGE_TIME_FORMAT,
        checked: event.target.checked,
    };
};

const fetchProfileImageInProgress = () => {
    return {
        type: PROFILE_IMAGE__FETCH_IN_PROGRESS,
    };
};

const fetchProfileImageSuccess = (value) => {
    return {
        type: PROFILE_IMAGE__FETCH_SUCCESS,
        value,
    };
};

const fetchProfileImageError = (message) => {
    return {
        type: PROFILE_IMAGE__FETCH_ERROR,
        message,
    };
};

export const getProfileImage = (organizationId, userId, token, cb) => (dispatch) => {
    dispatch(fetchProfileImageInProgress());
    const url = urlFetchProfileImage('5de5fa095240fc38a4a15dde', '5db817255f499d236b679ce9');
    Axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            const profileImage = res.data;
            const file = new Blob([profileImage], { type: profileImage.type });
            const imageUrl = URL.createObjectURL(file);
            dispatch(fetchProfileImageSuccess(imageUrl));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchProfileImageError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};
