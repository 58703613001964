import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setLanguageCode } from '../../../../../actions/textToSpeech';
import SelectField from '../../../../../components/SelectField';
import { AUVoiceOptions, GBVoiceOptions, INVoiceOptions, USVoiceOptions } from '../../../../../utils/assetOptions';

const LanguageCodeSelectField = (props) => {
    const handleChange = (value) => {
        switch (value) {
        case 'en-AU':
            props.onChange(value, AUVoiceOptions);
            break;
        case 'en-US':
            props.onChange(value, USVoiceOptions);
            break;
        case 'en-IN':
            props.onChange(value, INVoiceOptions);
            break;
        case 'en-GB':
            props.onChange(value, GBVoiceOptions);
            break;
        default:
            props.onChange(value);
            break;
        }
    };

    return (
        <SelectField
            id="language_code_select_field"
            items={props.options}
            label="Language Code"
            name="languageCode"
            value={props.value}
            onChange={handleChange}/>
    );
};

LanguageCodeSelectField.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.textToSpeech.languageCode.value,
        options: state.asset.new.textToSpeech.languageCode.list,
    };
};

const actionsToProps = {
    onChange: setLanguageCode,
};

export default connect(stateToProps, actionsToProps)(LanguageCodeSelectField);
