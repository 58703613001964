import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setProducer, showPeoplesDialog } from '../../../../../actions/asset';
import AutoCompleteTextField from '../../../../../components/AutoCompleteTextField/WithAddButton';

const ProducerSelectField = (props) => {
    const showDialog = (value) => {
        props.dialog(value, 'producer', [...props.list], props.onChange);
    };

    return (
        <AutoCompleteTextField
            label="Producer"
            options={props.options}
            showDialog={showDialog}
            value={props.list}
            onChange={props.onChange}/>
    );
};

ProducerSelectField.propTypes = {
    dialog: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        list: state.asset.new.movies.producer,
        options: state.asset.new.peopleList.list,
    };
};

const actionToProps = {
    onChange: setProducer,
    dialog: showPeoplesDialog,
};

export default connect(stateToProps, actionToProps)(ProducerSelectField);
