import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setActiveTab, setSelectedDate } from '../../../../actions/program';
import { formatDate, formatDay } from '../../../../utils/date';
import './index.css';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
});

class SelectDate extends React.Component {
    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevProps.selectedDate !== this.props.selectedDate) {
            this.props.setActiveTab(moment(this.props.selectedDate).day());
        }
    }

    handleChange (event, value) {
        this.props.setSelectedDate(moment(this.props.weekStartDate).add(value, 'days')._d);
        this.props.setActiveTab(value);
    }

    getFormattedLabel (start, value) {
        return (
            <div>
                <p>{formatDay(start, value)}</p>
                <p>{formatDate(start, value)}</p>
            </div>
        );
    }

    render () {
        const {
            classes,
            weekStartDate,
            activeTab,
        } = this.props;

        return (
            <div className={classes.root}>
                <AppBar
                    color="default"
                    position="static">
                    <Tabs
                        className="tabs_select_date"
                        indicatorColor="primary"
                        scrollButtons="on"
                        textColor="primary"
                        value={activeTab}
                        variant="scrollable"
                        onChange={this.handleChange}>
                        <Tab
                            className={
                                activeTab === 0
                                    ? 'active_date default'
                                    : 'default sarabun_font_weight'
                            }
                            label={this.getFormattedLabel(weekStartDate, 0)}/>
                        <Tab
                            className={
                                activeTab === 1
                                    ? 'active_date default'
                                    : 'default sarabun_font_weight'
                            }
                            label={this.getFormattedLabel(weekStartDate, 1)}/>
                        <Tab
                            className={
                                activeTab === 2
                                    ? 'active_date default'
                                    : 'default sarabun_font_weight'
                            }
                            label={this.getFormattedLabel(weekStartDate, 2)}/>
                        <Tab
                            className={
                                activeTab === 3
                                    ? 'active_date default'
                                    : 'default sarabun_font_weight'
                            }
                            label={this.getFormattedLabel(weekStartDate, 3)}/>
                        <Tab
                            className={
                                activeTab === 4
                                    ? 'active_date default'
                                    : 'default sarabun_font_weight'
                            }
                            label={this.getFormattedLabel(weekStartDate, 4)}/>
                        <Tab
                            className={
                                activeTab === 5
                                    ? 'active_date default'
                                    : 'default sarabun_font_weight'
                            }
                            label={this.getFormattedLabel(weekStartDate, 5)}/>
                        <Tab
                            className={
                                activeTab === 6
                                    ? 'active_date default'
                                    : 'default sarabun_font_weight'
                            }
                            label={this.getFormattedLabel(weekStartDate, 6)}/>
                    </Tabs>
                </AppBar>
            </div>
        );
    }
}

SelectDate.propTypes = {
    activeTab: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    setSelectedDate: PropTypes.func.isRequired,
    selectedDate: PropTypes.instanceOf(Date),
    weekStartDate: PropTypes.instanceOf(Date),
};

const stateToProps = (state) => {
    return {
        weekStartDate: state.live.weekStartDate,
        selectedDate: state.live.selectedDate,
        activeTab: state.live.activeTab,
    };
};

const actionToProps = {
    setSelectedDate,
    setActiveTab,
};

export default withStyles(styles)(connect(stateToProps, actionToProps)(SelectDate));
