export const SIDEBAR_DRAWER_TOGGLE = 'SIDEBAR_DRAWER_TOGGLE';
export const SIDEBAR_SELECTED_TAB_SET = 'SIDEBAR_SELECTED_TAB_SET';

export const DIALOG_ADD_FOLDER_SHOW = 'DIALOG_ADD_FOLDER_SHOW';
export const DIALOG_ADD_FOLDER_HIDE = 'DIALOG_ADD_FOLDER_HIDE';

export const ADD_FOLDER_NEW_LABEL = 'ADD_FOLDER_NEW_LABEL';
export const NEW_FOLDER_ADD_SUCCESS = 'NEW_FOLDER_ADD_SUCCESS';
export const NEW_FOLDER_ADD_IN_PROGRESS = 'NEW_FOLDER_ADD_IN_PROGRESS';
export const NEW_FOLDER_ADD_ERROR = 'NEW_FOLDER_ADD_ERROR';
