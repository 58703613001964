import Axios from 'axios';
import {
    ALBUM_ADD_ERROR,
    ALBUM_ADD_IN_PROGRESS,
    ALBUM_ADD_SUCCESS,
    ALBUM_DELETE_ERROR,
    ALBUM_DELETE_IN_PROGRESS,
    ALBUM_DELETE_SUCCESS,
    ALBUM_FETCH_ERROR,
    ALBUM_FETCH_IN_PROGRESS,
    ALBUM_FETCH_SUCCESS,
    NEW_ALBUM_NAME_SET,
} from '../constants/album';
import { albumUpdate, fetchAlbumURL } from '../constants/url';

const fetchAlbumInProgress = () => {
    return {
        type: ALBUM_FETCH_IN_PROGRESS,
    };
};

const fetchAlbumSuccess = (value) => {
    return {
        type: ALBUM_FETCH_SUCCESS,
        value,
    };
};

const fetchAlbumError = (message) => {
    return {
        type: ALBUM_FETCH_ERROR,
        message,
    };
};

export const fetchAlbum = () => (dispatch) => {
    dispatch(fetchAlbumInProgress());

    Axios.get(fetchAlbumURL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchAlbumSuccess(res.data.result));
        })
        .catch((error) => {
            dispatch(fetchAlbumError(
                error.response
                    ? error.response.data.message
                    : error.message,
            ));
        });
};

const addAlbumInProgress = () => {
    return {
        type: ALBUM_ADD_IN_PROGRESS,
    };
};

const addAlbumSuccess = (value) => {
    return {
        type: ALBUM_ADD_SUCCESS,
        value,
    };
};

const addAlbumError = (message) => {
    return {
        type: ALBUM_ADD_ERROR,
        message,
    };
};

export const addAlbum = (data, cb) => (dispatch) => {
    dispatch(addAlbumInProgress());

    Axios.post(fetchAlbumURL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(addAlbumSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(addAlbumError(
                error.response
                    ? error.response.data.message
                    : error.message,
            ));
            cb(error);
        });
};

const deleteAlbumInProgress = () => {
    return {
        type: ALBUM_DELETE_IN_PROGRESS,
    };
};

const deleteAlbumSuccess = (value) => {
    return {
        type: ALBUM_DELETE_SUCCESS,
        value,
    };
};

const deleteAlbumError = (message) => {
    return {
        type: ALBUM_DELETE_ERROR,
        message,
    };
};

export const deleteAlbum = (id, cb) => (dispatch) => {
    dispatch(deleteAlbumInProgress());

    const url = albumUpdate(id);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(deleteAlbumSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(deleteAlbumError(
                error.response
                    ? error.response.data.message
                    : error.message,
            ));
            cb(error);
        });
};

export const newNameSet = (value) => {
    return {
        type: NEW_ALBUM_NAME_SET,
        value,
    };
};
