import { Button } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateProgram } from '../../../../../actions/program';
import SkeletonLoader from '../../../../../components/SkeletonLoader/Slots';
import Slots from '../../Streams/Slots';
import AddProgram from './AddProgram';

const BUG_INTERVAL = 5;

class SlotPreview extends Component {
    constructor (props) {
        super(props);

        this.handleOpen = this.handleOpen.bind(this);
        this.handleOpenSlotPopup = this.handleOpenSlotPopup.bind(this);
        this.childFunction = this.childFunction.bind(this);
        this.renderSlots = this.renderSlots.bind(this);
    }

    handleOpen () {
    }

    handleOpenSlotPopup () {
    }

    childFunction (newHandleOpen, newHandleOpenSlotPopup) {
        this.handleOpen = newHandleOpen;
        this.handleOpenSlotPopup = newHandleOpenSlotPopup;
    }

    renderSlots (slot) {
        const slotPrograms = Object.values(this.props.programs).filter((v) => v.slot._id === slot._id);

        slotPrograms && slotPrograms.length &&
        slotPrograms.sort((left, right) => {
            return moment.utc(left.start_at).diff(moment.utc(right.start_at));
        });

        const programs = slotPrograms.map((program, index, programsList) => {
            if (index === 0) {
                const startMinutes = moment(program['start_at'])
                    .diff(moment(slot['start_at']), 'minutes');
                const duration = moment(program['end_at'])
                    .diff(moment(program['start_at']), 'minutes');
                program.lStart = startMinutes;
                program.rem = duration % BUG_INTERVAL;
                program.count = duration / BUG_INTERVAL;
                program.bugStartTime = moment(program['start_at']).add(startMinutes, 'm').local().format();
            } else {
                program.lStart = BUG_INTERVAL - programsList[index - 1].rem;
                const duration = moment(program['end_at'])
                    .diff(moment(program['start_at']), 'minutes') - program.lStart;
                program.rem = duration % BUG_INTERVAL;
                program.count = duration / BUG_INTERVAL;
                program.bugStartTime = moment(program['start_at']).add(program.lStart, 'm').local().format();
            }
            return program;
        });

        if (this.props.currentUpdatingSlot && this.props.currentUpdatingSlot === slot._id) {
            return <SkeletonLoader/>;
        }
        if (this.props.currentUpdatingSlot && this.props.currentUpdatingSlot === slot._id &&
            (this.props.updateProgramInProgress || this.props.deleteProgramInProgress ||
                this.props.addProgramInProgress)) {
            return <SkeletonLoader/>;
        }
        if ((!this.props.currentUpdatingSlot && this.props.programsInProgress)) {
            return <SkeletonLoader/>;
        } else {
            if (programs.length) {
                return (
                    <Slots
                        individualSlot
                        channel={this.props.channel}
                        program={programs}
                        slot={this.props.data.slot}
                        stream={this.props.stream}
                        updateProgram={this.props.updateProgram}
                        onClick={() => this.handleOpen(slot)}/>
                );
            } else {
                return (
                    <>
                        <div className="no_programs"> No Programs Scheduled</div>
                        <div className="no_data nodata_dynamic_slots">
                            {
                                !(moment(slot['end_at']).isBefore(moment(), 'second'))
                                    ? <>
                                        <Button onClick={() => this.handleOpenSlotPopup(slot)}>
                                            Create Dynamic Slot
                                        </Button>
                                        <Button onClick={() => this.handleOpen(slot)}>
                                            Add Program
                                        </Button>
                                    </>
                                    : null
                            }
                        </div>
                    </>
                );
            }
        }
    }

    render () {
        return (
            <div className="stream_sections">
                {this.renderSlots(this.props.data.slot)}
                <AddProgram childFunction={this.childFunction}/>
            </div>
        );
    }
}

SlotPreview.propTypes = {
    addProgramInProgress: PropTypes.bool.isRequired,
    channel: PropTypes.string.isRequired,
    currentUpdatingSlot: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    deleteProgramInProgress: PropTypes.bool.isRequired,
    programs: PropTypes.object.isRequired,
    programsInProgress: PropTypes.bool.isRequired,
    stream: PropTypes.string.isRequired,
    updateProgram: PropTypes.func.isRequired,
    updateProgramInProgress: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        addProgramInProgress: state.live.program.addInProgress,
        channel: state.live.channel.current,
        currentUpdatingSlot: state.live.currentUpdatingSlot,
        data: state.live.individualSlot.data,
        deleteProgramInProgress: state.live.program.deleteInProgress,
        programs: state.live.program.list,
        programsInProgress: state.live.program.inProgress,
        updateProgramInProgress: state.live.program.updateInProgress,
        stream: state.live.stream.current,
    };
};

const actionsToProps = {
    updateProgram,
};

export default withRouter(connect(stateToProps, actionsToProps)(SlotPreview));
