import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TagsTextField from '../../AddAssets/Tabs/MetadataForDistribution/TagsTextField';
import Player from '../Player';
import Filter from '../SearchBarTextField/filter';
import AssetSettingsButton from './AssetSettingsButton';
import CategorySelectField from './CategorySelectField';
import ChapterNameTextField from './ChapterNameTextField';
import DescriptionTextField from './DescriptionTextField';
import EditButton from './EditButton';
import GenreSelectField from './GenreSelectField';
import './index.css';
import LocationSelectField from './LocationSelectField';
import ObjectsSelectField from './ObjectsSelectField';
import PeopleSelectField from './PeopleSelectField';
import momentDurationFormatSetup from 'moment-duration-format';
import SliderTypeSelectField from './SliderTypeSelectField';

momentDurationFormatSetup(moment);

const EditPanel = (props) => {
    return (
        <div className="edit_panel">
            <div className="heading_div">
                <div className="left_content">
                    <p className="heading_text">Edit Panel: &nbsp;</p>
                    <p className="content_text">{props.details.title}</p>
                </div>
                <div className="buttons_div">
                    <Filter/>
                    <AssetSettingsButton/>
                    <SliderTypeSelectField/>
                </div>
            </div>
            <div className="content_details scroll">
                <Player/>
                <div className="sub_content">
                    <p className="main_heading">
                        Seek / Video Timeline
                    </p>
                    <div className="details">
                        <div className="row">
                            <div className="left_content">
                                <p className="heading_text">Start Time <b>:</b></p>
                                <p className="content_text">
                                    {props.range && props.range.length > 0 &&
                                    moment.duration(props.range[0], 'seconds').format()}
                                </p>
                            </div>
                            <div className="right_content">
                                <p className="heading_text">Scenes in Chapter <b>:</b></p>
                                <p className="content_text">{props.details.scenes}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="left_content">
                                <p className="heading_text">End Time <b>:</b></p>
                                <p className="content_text">
                                    {props.range && props.range.length > 0 &&
                                    moment.duration(props.range[1], 'seconds').format()}
                                </p>
                            </div>
                            <div className="right_content">
                                <p className="heading_text">Total Duration <b>:</b></p>
                                <p className="content_text">
                                    {props.range && props.range.length > 0 &&
                                    moment.duration(
                                        moment(props.range[1]).diff(moment(props.range[0])), 'seconds')
                                        .format()}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_content">
                    <div className="heading_div">
                        <p className="heading_text">
                            Chapter Details
                        </p>
                        {/* Remove the props sending after setting current chapter */}
                        <EditButton chapter={props.chapters[props.selectedChapter]}/>
                    </div>
                    <div className="details">
                        <div className="row">
                            <p className="heading_text text1 mt-14">Chapter Name</p>
                            {props.edit
                                ? <div className="tags text2">
                                    <ChapterNameTextField defaultValue={
                                        props.chapters.length
                                            ? props.chapters[props.selectedChapter] &&
                                            props.chapters[props.selectedChapter].name
                                            : ''
                                    }/>
                                </div>
                                : <p className="content_text text2 mt-14">{props.chapters.length
                                    ? props.chapters[props.selectedChapter] &&
                                    props.chapters[props.selectedChapter].name
                                    : ''}</p>}
                        </div>
                        <div className="row">
                            <p className="heading_text text1">Total Scenes</p>
                            <p className="content_text text2">{props.chapters.length
                                ? props.chapters[props.selectedChapter] &&
                                props.chapters[props.selectedChapter].scenes &&
                                props.chapters[props.selectedChapter].scenes.length
                                : ''}</p>
                        </div>
                        <div className="row">
                            <p className="heading_text text1">Total Duration</p>
                            <p className="content_text text2">{props.chapters.length &&
                            props.chapters[props.selectedChapter] &&
                            props.chapters[props.selectedChapter].duration
                                ? moment.utc(moment.duration(props.chapters[props.selectedChapter].duration, 'seconds')
                                    .asMilliseconds()).format('HH:mm:ss')
                                : ''}</p>
                        </div>
                        {props.edit
                            ? <>
                                <p className="heading_text text1 description_text">Description</p>
                                <DescriptionTextField defaultValue={
                                    props.chapters.length
                                        ? props.chapters[props.selectedChapter] &&
                                        props.chapters[props.selectedChapter].description
                                        : ''
                                }/>
                            </>
                            : <>
                                <p className="heading_text text1 description_text">Description</p>
                                <p className="text2 description description_content">
                                    {props.chapters.length
                                        ? props.chapters[props.selectedChapter] &&
                                        props.chapters[props.selectedChapter].description
                                        : ''}
                                </p>
                            </>}
                        <div className="row m-0">
                            <p className="heading_text text1">Genre</p>
                            <div className="tags text2">
                                {props.edit
                                    ? <GenreSelectField defaultValue={
                                        props.chapters.length
                                            ? props.chapters[props.selectedChapter] &&
                                            props.chapters[props.selectedChapter].genres
                                            : ''
                                    }/>
                                    : props.chapters.length &&
                                    props.chapters[props.selectedChapter] &&
                                    props.chapters[props.selectedChapter].genres &&
                                    props.chapters[props.selectedChapter].genres.length > 0 &&
                                    props.chapters[props.selectedChapter].genres.map((value, index) => {
                                        return (
                                            <p key={index}>{value.name}</p>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="row m-0">
                            <p className="heading_text text1">Tags</p>
                            <div className="tags text2">
                                {props.edit
                                    ? <TagsTextField
                                        defaultValue={props.chapters.length
                                            ? props.chapters[props.selectedChapter] &&
                                            props.chapters[props.selectedChapter].tags
                                            : ''}
                                        noLabel={true}/>
                                    : props.chapters.length &&
                                    props.chapters[props.selectedChapter] &&
                                    props.chapters[props.selectedChapter].tags &&
                                    props.chapters[props.selectedChapter].tags.length > 0 &&
                                    props.chapters[props.selectedChapter].tags.map((value, index) => {
                                        return (
                                            <p key={index}>{value}</p>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="row m-0">
                            <p className="heading_text text1">Category</p>
                            <div className="tags text2">
                                {props.edit
                                    ? <CategorySelectField defaultValue={
                                        props.chapters.length
                                            ? props.chapters[props.selectedChapter] &&
                                            props.chapters[props.selectedChapter].categories
                                            : ''
                                    }/>
                                    : props.chapters.length &&
                                    props.chapters[props.selectedChapter] &&
                                    props.chapters[props.selectedChapter].categories &&
                                    props.chapters[props.selectedChapter].categories.length > 0 &&
                                    props.chapters[props.selectedChapter].categories.map((value, index) => {
                                        return (
                                            <p key={index}>{value.name}</p>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="row m-0">
                            <p className="heading_text text1">People</p>
                            <div className="tags text2">
                                {props.edit
                                    ? <PeopleSelectField defaultValue={
                                        props.chapters.length
                                            ? props.chapters[props.selectedChapter] &&
                                            props.chapters[props.selectedChapter].people
                                            : ''
                                    }/>
                                    : props.chapters.length &&
                                    props.chapters[props.selectedChapter] &&
                                    props.chapters[props.selectedChapter].people &&
                                    props.chapters[props.selectedChapter].people.length > 0 &&
                                    props.chapters[props.selectedChapter].people.map((value, index) => {
                                        return (
                                            <p key={index}>{value.name}</p>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="row m-0">
                            <p className="heading_text text1">Objects</p>
                            <div className="tags text2">
                                {props.edit
                                    ? <ObjectsSelectField
                                        defaultValue={
                                            props.chapters.length
                                                ? props.chapters[props.selectedChapter] &&
                                                props.chapters[props.selectedChapter].objects
                                                : ''
                                        }/>
                                    : props.chapters.length &&
                                    props.chapters[props.selectedChapter] &&
                                    props.chapters[props.selectedChapter].objects &&
                                    props.chapters[props.selectedChapter].objects.length > 0 &&
                                    props.chapters[props.selectedChapter].objects.map((value, index) => {
                                        return (
                                            <p key={index}>{value}</p>
                                        );
                                    })}
                            </div>
                        </div>
                        <div className="row m-0">
                            <p className="heading_text text1">Location</p>
                            <div className="tags text2">
                                {props.edit
                                    ? <LocationSelectField defaultValue={props.chapters.length
                                        ? props.chapters[props.selectedChapter] &&
                                        props.chapters[props.selectedChapter].locations
                                        : ''}/>
                                    : props.chapters.length &&
                                    props.chapters[props.selectedChapter] &&
                                    props.chapters[props.selectedChapter].locations &&
                                    props.chapters[props.selectedChapter].locations.length > 0 &&
                                    props.chapters[props.selectedChapter].locations.map((value, index) => {
                                        return (
                                            <p key={index}>{value}</p>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

EditPanel.propTypes = {
    chapters: PropTypes.arrayOf(
        PropTypes.shape(
            {
                start_at: PropTypes.number.isRequired,
                end_at: PropTypes.number.isRequired,
                duration: PropTypes.number.isRequired,
                scenes: PropTypes.array.isRequired,
            }),
    ).isRequired,
    chaptersInProgress: PropTypes.func.isRequired,
    details: PropTypes.object.isRequired,
    edit: PropTypes.bool.isRequired,
    range: PropTypes.array.isRequired,
    selectedChapter: PropTypes.number.isRequired,
};

const stateToProps = (state) => {
    return {
        details: state.chapterMarking.chapterDetails.details,
        range: state.chapterMarking.range,
        edit: state.chapterMarking.new.edit,
        chapters: Object.values(state.chapterMarking.chapter.list),
        chaptersInProgress: state.chapterMarking.chapter.inProgress,
        selectedChapter: state.chapterMarking.chapterDetails.tab,
    };
};

export default connect(stateToProps)(EditPanel);
