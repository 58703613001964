import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAddAssetName } from '../../../../actions/asset';
import TextField from '../../../../components/TextField/InputProps';

const TitleTextField = (props) => {
    return (
        <TextField
            helperText="you can edit this asset title when ever required"
            id="asset-title-text_field"
            label="Asset Title"
            name="assetTitle"
            placeholder="Asset Title"
            required={true}
            value={props.value}
            variant="outlined"
            onChange={props.onChange}/>
    );
};

TitleTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.name,
    };
};

const actionsToProps = {
    onChange: setAddAssetName,
};

export default connect(stateToProps, actionsToProps)(TitleTextField);
