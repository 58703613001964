import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';

const SaveButton = (props) => {
    return (
        <div className="save_button_div">
            <Button
                className="save_button"
                disabled={props.disable}
                onClick={props.onClick}>
                Save and Continue
            </Button>
        </div>
    );
};

SaveButton.propTypes = {
    disable: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default SaveButton;
