import Axios from 'axios';
import { RUNNERS_FETCH_ERROR, RUNNERS_FETCH_IN_PROGRESS, RUNNERS_FETCH_SUCCESS } from '../constants/runner';
import { URL_RUNNERS_LIST } from '../constants/url';

const getRunnerInProgress = () => {
    return {
        type: RUNNERS_FETCH_IN_PROGRESS,
    };
};

const getRunnerSuccess = (items) => {
    return {
        type: RUNNERS_FETCH_SUCCESS,
        items,
    };
};

const getRunnerError = (message) => {
    return {
        type: RUNNERS_FETCH_ERROR,
        message,
    };
};

export const getRunners = (token) => (dispatch) => {
    dispatch(getRunnerInProgress());

    Axios.get(URL_RUNNERS_LIST, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            dispatch(getRunnerSuccess(res.data.result));
        })
        .catch((error) => {
            dispatch(getRunnerError(error.response ? error.response.data.message : error.message));
        });
};
