import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import VideoJS from 'video.js';
import 'video.js/dist/video-js.css';
import { fetchAsset, getPreviewUrl } from '../../actions/asset';
import Player from '../../components/Player';
import './index.css';
import { fetchDeviceID, fetchWatchURL } from '../../actions/videoAssets';
import AddQuestionButton from './AddQuestionButton';
import MCQType from './MCQType';
import Preview from './Preview';
import PublishButton from './PublishButton';
import QuestionTypes from './QuestionTypes';
import {
    setPlayer,
    getInteractiveVideos,
    addInteractiveVideo,
    setCurrentInteraction,
} from '../../actions/interactiveVideo';
import SeekType from './SeekType';
import QuestionMarks from './Tracker';
import PollType from './PollType';
import TextType from './TextType';
import { Button, CircularProgress } from '@material-ui/core';
import 'videojs-hotkeys';

const videoJSOptions = {
    autoplay: false,
    loop: false,
    width: 500,
    height: 600,
    controls: true,
    controlBar: {
        children: [
            'playToggle',
            'muteToggle',
            'volumeControl',
            'remainingTimeDisplay',
            'currentTimeDisplay',
            'timeDivider',
            'durationDisplay',
            'progressControl',
        ],
    },
};

class InteractiveVideo extends Component {
    constructor (props) {
        super(props);

        this.initializePlayer = this.initializePlayer.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.state = {
            config: null,
        };
    }

    initializePlayer (ref) {
        this.player = VideoJS(ref, videoJSOptions);
        this.props.setPlayer(this.player);

        this.player.ready(function () {
            this.hotkeys({
                volumeStep: 0.1,
                seekStep: 1,
                enableModifiersForNumbers: false,
            });
        });

        if (this.player) {
            this.player.on('seeked', () => {
                const current = this.player.currentTime();
                const activeInteractionIndex = this.props.questionsList.findIndex((item) =>
                    item.start_at >= current);

                this.props.setCurrentInteraction(activeInteractionIndex !== -1
                    ? activeInteractionIndex : 0);
            });

            this.player.on('timeupdate', () => {
                if (!this.player.currentTime() || !this.player.duration()) {
                    return;
                }

                const current = this.player.currentTime();
                const activeInteraction = this.props.currentInteraction;
                const interactionData = this.props.questionsList[activeInteraction];

                if (current && interactionData) {
                    if (current > interactionData.start_at &&
                        current < interactionData.start_at + interactionData.time_limit) {
                        const element = document.getElementById(`interaction_buttons-${activeInteraction}`);
                        if (element) {
                            element.classList.add('show_preview');
                            this.player.pause();
                        }
                    }

                    // removing the interaction buttons after loop ending
                    if (current > interactionData.start_at + 0.2) {
                        const element = document.getElementById(`interaction_buttons-${activeInteraction}`);

                        if (element) {
                            element.classList.remove('show_preview');
                            this.player.play();
                            this.props.setCurrentInteraction(activeInteraction + 1);
                        }
                    }

                    if (current < interactionData.start_at - 0.2) {
                        const element = document.getElementById(`interaction_buttons-${activeInteraction}`);

                        if (element) {
                            element.classList.remove('show_preview');
                        }
                    }
                }
            });
        }
    }

    componentDidMount () {
        const id = this.props.match && this.props.match.params.id;
        const token = localStorage.getItem('authorizationToken');

        if (id) {
            this.props.getInteractiveVideos(id, token);
            this.props.fetchAsset(id, token, (error) => {
                if (error) {
                    return;
                }

                if (this.props.deviceID && Object.keys(this.props.deviceID).length) {
                    this.props.fetchWatchURL(id, this.props.deviceID, (cb) => {
                        if (cb) {
                            const url = cb.assetUrl;

                            if (url) {
                                this.props.getPreviewUrl(url, (format) => {
                                    const config = {
                                        sources: [{
                                            type: format === 'mp4'
                                                ? 'video/mp4'
                                                : 'application/x-mpegURL',
                                            src: format === 'mp4'
                                                ? url.replace('m3u8', 'mp4')
                                                : url,
                                        }],
                                    };

                                    this.setState({
                                        config: JSON.stringify(config),
                                        duration: this.props.selectedAsset.file && this.props.selectedAsset.file.length,
                                    });
                                });
                            }
                        }
                    });
                } else {
                    this.props.fetchDeviceID((deviceID) => {
                        if (deviceID) {
                            this.props.fetchWatchURL(id, deviceID, (cb) => {
                                if (cb) {
                                    const url = cb.assetUrl;

                                    if (url) {
                                        this.props.getPreviewUrl(url, (format) => {
                                            const config = {
                                                sources: [{
                                                    type: format === 'mp4'
                                                        ? 'video/mp4'
                                                        : 'application/x-mpegURL',
                                                    src: format === 'mp4'
                                                        ? url.replace('m3u8', 'mp4')
                                                        : url,
                                                }],
                                            };

                                            this.setState({
                                                config: JSON.stringify(config),
                                                duration: this.props.selectedAsset.file && this.props.selectedAsset.file.length,
                                            });
                                        });
                                    }
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    componentWillUnmount () {
        if (this.player) {
            this.player.dispose();
        }
    }

    handleCreate () {
        const id = this.props.match && this.props.match.params.id;
        const token = localStorage.getItem('authorizationToken');
        this.props.addInteractiveVideo({
            name: `IV ${id}`,
            assetId: id,
        }, token, (error) => {
            if (error) return;
            this.props.getInteractiveVideos(id, token);
        });
    }

    render () {
        const { config } = this.state;

        return (
            <div className="interactive_video">
                <div className="row">
                    {config
                        ? <div className="player">
                            <Player
                                initializePlayer={this.initializePlayer}
                                setup={config}/>
                            {this.props.questionsList && this.props.questionsList.length > 0 &&
                            this.props.questionsList.map((value, index) =>
                                <Preview
                                    key={value._id}
                                    currentInteraction={this.state.currentInteraction}
                                    index={index} value={value}/>)}
                        </div>
                        : <div className="player">
                            <CircularProgress/>
                        </div>}
                    <div className="seek video-js">
                        <QuestionMarks max={this.state.duration}/>
                    </div>
                </div>
                {this.props.questionsList.length
                    ? <div><PublishButton/></div>
                    : null}
                {this.props.questionTypes === 'MCQ_SA'
                    ? <MCQType/>
                    : this.props.questionTypes === 'POLL'
                        ? <PollType/>
                        : this.props.questionTypes === 'TEXT_INPUT'
                            ? <TextType/>
                            : this.props.questionTypes === 'SEEK'
                                ? <SeekType/>
                                : this.props.questionTypesShow
                                    ? <QuestionTypes/>
                                    : this.props.interactiveVideos.length
                                        ? <div className="buttons_section">
                                            <AddQuestionButton/>
                                        </div>
                                        : <Button
                                            style={{ color: 'white' }}
                                            onClick={this.handleCreate}>
                                            Create IV</Button>}
            </div>
        );
    }
}

InteractiveVideo.propTypes = {
    addInteractiveVideo: PropTypes.func.isRequired,
    currentInteraction: PropTypes.number.isRequired,
    deviceID: PropTypes.string.isRequired,
    deviceIDInProgress: PropTypes.bool.isRequired,
    fetchAsset: PropTypes.func.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    fetchWatchURL: PropTypes.func.isRequired,
    getInteractiveVideos: PropTypes.func.isRequired,
    getPreviewUrl: PropTypes.func.isRequired,
    match: PropTypes.shape(
        {
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    questionTypes: PropTypes.string.isRequired,
    questionTypesShow: PropTypes.bool.isRequired,
    questionsList: PropTypes.arrayOf(
        PropTypes.shape({
            answers: PropTypes.array.isRequired,
            start_at: PropTypes.number.isRequired,
            time_limit: PropTypes.number.isRequired,
        }),
    ).isRequired,
    selectedAsset: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        file: PropTypes.shape({
            view_link_m3u8: PropTypes.string.isRequired,
            length: PropTypes.number.isRequired,
        }),
        type: PropTypes.string,
    }).isRequired,
    setCurrentInteraction: PropTypes.func.isRequired,
    setPlayer: PropTypes.func.isRequired,
    urlLinkInProgress: PropTypes.bool.isRequired,
    interactiveVideos: PropTypes.array,
    url: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        selectedAsset: state.asset._.current,
        deviceID: state.asset.videoAssets.deviceID.id,
        deviceIDProgress: state.asset.videoAssets.deviceID.inProgress,
        urlLinkInProgress: state.asset.videoAssets.playerLink.inProgress,
        questionTypesShow: state.interactiveVideo.questionTypes.show,
        questionTypes: state.interactiveVideo.questionTypes.value,
        questionsList: state.interactiveVideo.questionsList,
        interactiveVideos: state.interactiveVideo.videos.list,
        currentInteraction: state.interactiveVideo.currentInteraction,
    };
};

const actionsToProps = {
    fetchAsset,
    fetchWatchURL,
    fetchDeviceID,
    getPreviewUrl,
    setPlayer,
    getInteractiveVideos,
    addInteractiveVideo,
    setCurrentInteraction,
};

export default withRouter(connect(stateToProps, actionsToProps)(InteractiveVideo));
