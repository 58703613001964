import { combineReducers } from 'redux';
import asset from './asset';
import forgotPassword from './forgotPassword';
import live from './live';
import login from './login';
import profile from './profile';
import sidebar from './sidebar';
import signup from './signUp';
import snackbar from './snackbar';
import runner from './runner';
import chapterMarking from './chapterMarking';
import account from './Account';
import subscription from './subscription';
import interactive from './interactive';
import interactiveVideo from './interactiveVideo';

const app = combineReducers({
    sidebar,
    asset,
    login,
    snackbar,
    live,
    profile,
    signup,
    forgotPassword,
    runner,
    chapterMarking,
    account,
    subscription,
    interactive,
    interactiveVideo,
});

const root = (state, action) => {
    if (action.type === 'USER_LOGOUT_SUCCESS') {
        state = undefined;
    }

    return app(state, action);
};

export default root;
