import { RUNNERS_FETCH_SUCCESS, RUNNERS_FETCH_ERROR, RUNNERS_FETCH_IN_PROGRESS } from '../constants/runner';

const runner = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case RUNNERS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case RUNNERS_FETCH_SUCCESS: {
        return {
            ...state,
            list: action.items,
            inProgress: false,
        };
    }
    case RUNNERS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default runner;
