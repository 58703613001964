const assetTypes = [{
    type: 'video',
    label: 'Video',
    url: '/manage/assets/video',
}, {
    type: 'audio',
    label: 'Audio',
    url: '/manage/assets/audio',
}, {
    type: 'graphic',
    label: 'Graphic',
    url: '/manage/assets/graphic',
}, {
    type: 'text',
    label: 'Text',
    url: '/manage/assets/text',
}];

export default assetTypes;
