import { Button } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../../actions/logout';
import { showPopoverTimeFormat, showSearchBar } from '../../actions/profile';
// import SearchTextField from './SearchTextField';
import { getRunners } from '../../actions/runner';
import Icon from '../../components/Icon';
import DownloadStatus from './DownloadStatus';
import './index.css';
import PopoverProfile from './PopoverProfile';
import PopoverSelectTimeFormat from './PopoverSelectTimeFormat';

class NavigationBar extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            timer: '',
            anchorElement: null,
            selectedDate: '',
            checked: true,
        };
        this.events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress',
        ];
        this.myTimer = this.myTimer.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.warn = this.warn.bind(this);
        this.destroy = this.destroy.bind(this);
        this.logout = this.logout.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);
    }

    componentDidMount () {
        setInterval(this.myTimer, 1000);
        for (const i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();

        const token = localStorage.getItem('authorizationToken');

        if (token) {
            this.props.getRunners(token);
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const token = localStorage.getItem('authorizationToken');

        if (!token) {
            this.props.history.push({
                pathname: '/login',
            });
        }
    }

    componentWillUnmount () {
        this.destroy();
    }

    clearTimeout () {
        if (this.warnTimeout) {
            clearTimeout(this.warnTimeout);
        }
        if (this.logoutTimeout) {
            clearTimeout(this.logoutTimeout);
        }
    }

    setTimeout () {
        this.warnTimeout = setTimeout(this.warn, 59 * 60 * 1000);
        this.logoutTimeout = setTimeout(this.logout, 60 * 60 * 1000);
    }

    warn () {
        // TODO: warning login here
    }

    resetTimeout () {
        this.clearTimeout();
        this.setTimeout();
    }

    logout () {
        this.destroy();
        this.props.history.push({
            pathname: '/login',
        });
        this.props.logoutUser();
    }

    destroy () {
        this.clearTimeout();

        for (const i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }

    myTimer () {
        const time = new Date();
        if (this.props.checked) {
            const timer = moment(time).format('MMM Do YYYY, ddd  h:mm:ss A');
            this.setState({
                timer: timer,
            });
        } else {
            const timer = moment(time).format('MMM Do YYYY, ddd  HH:mm:ss');
            this.setState({
                timer: timer,
            });
        }
    }

    handleSearch () {
        this.props.handleShowSearchBar(true);
    }

    render () {
        return (
            <div className="top_nav">
                <Button
                    aria-haspopup="true"
                    className="clock_button"
                    variant="contained"
                    onClick={(e) => this.props.onClickTimeFormat(e)}>
                    <Icon
                        className="clock"
                        icon="clock"/>
                </Button>
                <PopoverSelectTimeFormat/>
                <p className="timer"> {this.state.timer} </p>
                {/* <div className="search_section" onClick={(e) => this.handleSearch(e)}> */}
                {/*    <Icon */}
                {/*        className="search" */}
                {/*        icon="search"/> */}
                {/* </div> */}
                <DownloadStatus/>
                {/* { */}
                {/*    this.props.searchBar */}
                {/*        ? <div className="search_field zoomIn delay-5s"> */}
                {/*            <SearchTextField/> */}
                {/*        </div> */}
                {/*        : null */}
                {/* } */}
                <PopoverProfile/>

            </div>
        );
    }
}

NavigationBar.propTypes = {
    checked: PropTypes.bool.isRequired,
    getRunners: PropTypes.func.isRequired,
    handleShowSearchBar: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired,
    searchBar: PropTypes.bool.isRequired,
    onClickTimeFormat: PropTypes.func.isRequired,
    time: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        searchBar: state.profile.searchBar.value,
        checked: state.profile.timeFormat.checked,
        userLogin: state.login.userLogin.result,
    };
};

const actionsToProps = {
    onClickTimeFormat: showPopoverTimeFormat,
    handleShowSearchBar: showSearchBar,
    logoutUser,
    getRunners,
};

export default withRouter(connect(stateToProps, actionsToProps)(NavigationBar));
