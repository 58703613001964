import { Breadcrumbs, Link } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import * as PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import './index.css';

const BreadCrumb = (props) => {
    return (
        <Breadcrumbs
            aria-label="chapter-marking-breadcrumb"
            className="bread_crumb"
            separator={<NavigateNextIcon fontSize="small"/>}>
            <Link
                color="inherit"
                onClick={() => props.history.push({ pathname: '/manage/assets' })}>
                Assets
            </Link>
            <p color="inherit">
                Tag Video
            </p>
            <p>Chapter Marking</p>
        </Breadcrumbs>
    );
};

BreadCrumb.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(BreadCrumb);
