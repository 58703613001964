import * as PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Accounts from './containers/Accounts';
import Audio from './containers/asset/Audio';
import Graphic from './containers/asset/Graphic';
import Permissions from './containers/asset/Permissions';
import Text from './containers/asset/text';
import VideoAssets from './containers/asset/Video';
import AssetsOverview from './containers/AssetsOverview';
import ChapterMarking from './containers/ChapterMarking';
import ForgotPassword from './containers/forgotPassword';
import InteractiveVideo from './containers/InteractiveVideo';
import Login from './containers/login';
import NavigationBar from './containers/NavigationBar';
import OAuth from './containers/OAuth';
import Live from './containers/Programming/Live';
import Sidebar from './containers/Sidebar';
// import SignUpPage from './containers/SignUp/Index';
import Translation from './containers/translation';

const Router = (props) => {
    const token = localStorage.getItem('authorizationToken');
    const routes = [{
        exact: true,
        path: '/accounts',
        component: Accounts,
    }];

    routes.push({
        exact: true,
        path: '/manage/assets/video',
        component: VideoAssets,
    });
    routes.push({
        exact: true,
        path: '/manage/assets/audio',
        component: Audio,
    });
    routes.push({
        exact: true,
        path: '/manage/assets/graphic',
        component: Graphic,
    });
    routes.push({
        exact: true,
        path: '/manage/assets/text',
        component: Text,
    });
    routes.push({
        exact: true,
        path: '/manage/assets',
        component: AssetsOverview,
    });
    routes.push({
        exact: true,
        path: '/assets/chapterMarking/:id',
        component: ChapterMarking,
    });
    routes.push({
        exact: true,
        path: '/login',
        component: Login,
    });
    routes.push({
        exact: true,
        path: '/distribute/live',
        component: Live,
    });
    routes.push({
        exact: true,
        path: '/translation/freeflix',
        component: Translation,
    });
    routes.push({
        exact: true,
        path: '/permissions',
        component: Permissions,
    });
    routes.push({
        exact: true,
        path: '/forgotPassword',
        component: ForgotPassword,
    });
    // routes.push({
    //     exact: true,
    //     path: '/signup',
    //     component: SignUpPage,
    // });
    routes.push({
        exact: true,
        path: '/oauth/:status',
        component: OAuth,
    });
    routes.push({
        path: '/interactive-video/:id',
        component: InteractiveVideo,
    });

    return (
        <div className="main_content">
            {
                props.location.pathname === '/login' || props.location.pathname === '/forgotPassword' || props.location.pathname === '/signup'
                    ? null
                    : <Sidebar/>
            }
            <div className="container">
                {
                    props.location.pathname === '/login' || props.location.pathname === '/forgotPassword' || props.location.pathname === '/signup'
                        ? null
                        : <NavigationBar/>
                }
                <div className="content">
                    <div className="scroll_bar">
                        <Switch>
                            {
                                token
                                    ? routes.map((route) =>
                                        <Route
                                            key={route.path}
                                            exact
                                            component={route.component}
                                            path={route.path}/>,
                                    )
                                    : <>
                                        <Route
                                            // exact
                                            path="/"
                                            render={() => <Redirect to="/login"/>}
                                        />
                                        <Route
                                            exact
                                            component={Login}
                                            path="/login"
                                        />
                                        <Route
                                            exact
                                            component={ForgotPassword}
                                            path="/forgotPassword"
                                        />
                                        {/* <Route */}
                                        {/*    exact */}
                                        {/*    component={SignUpPage} */}
                                        {/*    path="/signup" */}
                                        {/* /> */}
                                    </>
                            }
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

Router.propTypes = {
    location: PropTypes.object.isRequired,
};

export default withRouter(Router);
