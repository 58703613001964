import { Tooltip, Checkbox, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchAssetsData } from '../../../../../actions/asset';
import { addProgram, fetchPrograms, updateProgram } from '../../../../../actions/program';
import assetGroupIcon from '../../../../../assets/asset_group.svg';
import musicTypeIcon from '../../../../../assets/musicType.svg';
import videoTypeIcon from '../../../../../assets/videoType.svg';
import './index.css';
import {
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../../../../../constants/url';
import SearchAssetTextField from './SearchAssetTextField';

class AssetsList extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            checked: {},
            selectedContent: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
    }

    componentDidMount () {
        this.handleFetch(
            DEFAULT_SKIP,
            DEFAULT_LIMIT,
            DEFAULT_SEARCH_KEY,
            DEFAULT_SORT_BY,
            DEFAULT_ORDER);
        if (this.props.programs && this.props.data && this.props.data.slot) {
            this.setState({
                selectedContent: Object.values(this.props.programs).filter((v) => v.slot._id === this.props.data.slot._id),
            });
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (pp.programs !== this.props.programs && this.props.data && this.props.data.slot) {
            this.setState({
                selectedContent: Object.values(this.props.programs).filter((v) => v.slot._id === this.props.data.slot._id),
            });
        }
    }

    handleFetch (skip, limit, searchText, sortBy, order) {
        const token = localStorage.getItem('authorizationToken');

        this.props.fetchAssetsData('video', token, 'complete', null, null,
            skip,
            limit,
            searchText,
            sortBy,
            order);
    }

    handleChange (event, name, selected) {
        const check = this.state.checked;

        check[name] = !!event.target.checked;

        this.setState({
            checked: check,
        });

        this.handleChangeCheckBox(event, name, selected);
    }

    handleChangeCheckBox (event, name, data) {
        let selected = this.state.selectedContent;

        if (event.target.checked) {
            if (selected.length) {
                data.start_at = selected[selected.length - 1].end_at;
                data.end_at = moment(selected[selected.length - 1].end_at).add(data.file.length, 's');
            } else {
                if (this.props.data.slot) {
                    data.start_at = moment(this.props.data.slot['start_at']);
                    data.end_at = moment(this.props.data.slot['start_at']).add(data.file.length, 's');
                }
            }
            selected.push(data);
            this.updateSelectedContent(selected);
        } else {
            selected = selected.filter((a) => a._id !== data._id);
            this.updateSelectedContent(selected);
        }
    }

    updateSelectedContent (selected) {
        this.setState({
            selectedContent: selected,
        });
    }

    handleScroll (e) {
        const scroll = e.target.scrollTop;
        const scrollHeight = e.target.scrollHeight;
        const height = e.target.clientHeight;

        if ((this.props.assetData.length < this.props.count) &&
            (scrollHeight - scroll <= height + 256) && !this.props.assetDataInProgress) {
            this.handleFetch(this.props.skip,
                this.props.limit + 10,
                this.props.searchKey,
                this.props.sortBy,
                this.props.order);
        }
    }

    handleAdd () {
        const promises = this.state.selectedContent.map(async (v, index) => {
            if (!v.slot) {
                const data = {
                    startAt: this.state[v._id]
                        ? this.state[v._id]
                        : v.start_at,
                    endAt: v.end_at,
                    asset: {
                        id: v._id,
                        startAt: 0,
                        endAt: v.file.length,
                    },
                };
                return this.props.addProgram(this.props.channel,
                    this.props.stream,
                    this.props.data.slot._id,
                    data, () => {
                    });
            }

            return null;
        });
        Promise.all(promises)
            .then((res) => {
                this.setState({
                    selectedContent: [],
                    checked: {},
                });

                this.props.fetchPrograms(this.props.channel,
                    this.props.stream,
                    this.props.data.slot._id, () => {
                    });
            })
            .catch(() => {
            });
    }

    render () {
        const count = Object.keys(this.state.checked).filter((key) => this.state.checked[key]).length;
        const disable = count < 1;
        console.log('5555555555', this.state, count);

        return (
            <div className="assets_listing">
                <div className="list_header">
                    <div className="list_header_left">
                        <img alt="asset" src={assetGroupIcon}/>
                        <p className="title">Assets</p>
                    </div>
                    <div className="search_assets">
                        <SearchAssetTextField/>
                    </div>
                </div>
                <div className="assets_list" onScroll={this.handleScroll}>
                    {this.props.assetData && this.props.assetData.length &&
                    this.props.assetData.map((item, index) => (
                        <div key={index} className="asset_data">
                            <div className="data_info">
                                <div className="list_checkbox">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.checked['check' + index]}
                                                className={this.state.checked['check' + index] === true ? 'active'
                                                    : 'inactive'}
                                                value={this.state.checked['check' + index]}
                                                onChange={(event) => this.handleChange(event, 'check' + index, item)}/>
                                        }
                                        label={null}/>
                                </div>
                                {item && item.thumbnail && item.thumbnail.square_compressed
                                    ? <img alt="logo" src={item.thumbnail.square_compressed}/>
                                    : item && item.thumbnail && item.thumbnail.square
                                        ? <img alt="logo" src={item.thumbnail.square}/>
                                        : null}
                                <div className="asset_details">
                                    <div className="asset_top">
                                        <div className="asset_nameDe">
                                            <Tooltip title={item.name}>
                                                <div className="asset_name">{item.name}</div>
                                            </Tooltip>
                                            <span>
                                                {item.year
                                                    ? (item.year)
                                                    : null}
                                            </span>
                                            <div className="asset_type">
                                                {item.type !== 'video'
                                                    ? <img alt="icon" src={musicTypeIcon}/>
                                                    : <img alt="icon" src={videoTypeIcon}/>}
                                                <div>{item.category && item.category.name}</div>
                                            </div>
                                        </div>
                                        <div className="asset_length">
                                            {moment.utc(moment.duration(item.file && item.file.length, 'seconds')
                                                .asMilliseconds()).format(this.props.timeFormat)}
                                        </div>
                                    </div>
                                    {/* <div className="asset_bottom"> */}
                                    {/*    <div className="asset_genre"> {item.genre}</div> */}
                                    {/*    <Tooltip title={item.actors}> */}
                                    {/*        <div className="asset_actors"> {item.actors}</div> */}
                                    {/*    </Tooltip> */}
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    ),
                    )}
                </div>
                <div className="assets_add">
                    {this.props.data && this.props.data.slot &&
                    !(moment(this.props.data.slot['end_at']).isBefore(moment(), 'second'))
                        ? <Button disabled={disable} onClick={this.handleAdd}>
                            Add
                            {
                                count > 0
                                    ? ' (' + count + ')'
                                    : null
                            }
                        </Button>
                        : <Button disabled>
                            Add
                        </Button>}
                </div>
            </div>
        );
    }
}

AssetsList.propTypes = {
    addProgram: PropTypes.func.isRequired,
    assetData: PropTypes.array.isRequired,
    assetDataInProgress: PropTypes.bool.isRequired,
    channel: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    fetchAssetsData: PropTypes.func.isRequired,
    fetchPrograms: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    programs: PropTypes.object.isRequired,
    searchKey: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    stream: PropTypes.string.isRequired,
    timeFormat: PropTypes.string.isRequired,
    updateProgram: PropTypes.func.isRequired,
    order: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        assetData: state.asset.assetData.list,
        assetDataInProgress: state.asset.assetData.inProgress,
        channel: state.live.channel.current,
        data: state.live.individualSlot.data,
        timeFormat: state.live.timeFormat,
        programs: state.live.program.list,
        stream: state.live.stream.current,
        skip: state.asset.assetData.skip,
        limit: state.asset.assetData.limit,
        count: state.asset.assetData.count,
        searchKey: state.asset.assetData.searchKey,
        sortBy: state.asset.assetData.sortBy,
        order: state.asset.assetData.order,
    };
};

const actionsToProps = {
    addProgram,
    fetchAssetsData,
    fetchPrograms,
    updateProgram,
};

export default withRouter(connect(stateToProps, actionsToProps)(AssetsList));
