import { Button } from '@material-ui/core';
import React from 'react';

const ExportButton = () => {
    return (
        <Button className="export_button outline_button" variant="outlined">
            Export Playlist
        </Button>
    );
};

export default ExportButton;
