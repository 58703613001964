import React from 'react';
import { Button } from '@material-ui/core';
import DataTable from '../../components/DataTable';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import Icon from '../../components/Icon';

const TransactionsTable = (props) => {
    const options = {
        responsive: 'standard',
        serverSide: false,
        print: false,
        selectableRows: 'none',
        searchPlaceholder: 'Latest Transactions',
        textLabels: {
            body: {
                noMatch: <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            filter: {
                all: 'All',
                title: 'FILTERS',
                reset: 'RESET',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
    };

    const columns = [{
        name: 'hash',
        label: 'Tx Hash',
        options: {
            filter: false,
            sort: true,
            // eslint-disable-next-line react/display-name
            customBodyRender: (value) => (
                <div className="hash_text" title={value}>
                    <p className="ellipses_text">{value}</p>
                    {value.slice(value.length - 8, value.length)}
                </div>
            ),
        },
    }, {
        name: 'activity',
        label: 'Activity',
        options: {
            filter: false,
            sort: true,
            // eslint-disable-next-line react/display-name
            customBodyRender: (value) => (
                <div className="transaction_type">
                    <p className={value === 'sent'
                        ? 'red'
                        : value === 'received'
                            ? 'green'
                            : ''}>
                        {value}
                    </p>
                    {value === 'sent'
                        ? <Icon className="sent" icon="sent"/>
                        : value === 'received'
                            ? <Icon className="received" icon="received"/>
                            : value === 'minted'
                                ? <Icon className="star" icon="star"/>
                                : null}
                </div>
            ),
        },
    }, {
        name: 'tokens',
        label: 'Token(s)',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'timestamp',
        label: 'Timestamp',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'details',
        label: 'Details',
        options: {
            filter: false,
            sort: false,
            // eslint-disable-next-line react/display-name
            customBodyRender: () => (
                <Button className="view_details_button">
                    View Details
                </Button>
            ),
        },
    }];

    const tableData = props.data && props.data.length
        ? props.data.map((item, index) =>
            [
                item.hash,
                item.activity,
                item.tokens,
                item.time,
            ])
        : [];

    return (
        <div className="table">
            <DataTable
                columns={columns}
                data={tableData}
                name="Latest Transactions"
                options={options}/>
        </div>
    );
};

TransactionsTable.propTypes = {
    data: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
    return {
        data: state.account.transactions,
    };
};

export default connect(stateToProps)(TransactionsTable);
