import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setTabValue } from '../../../actions/asset';
import Icon from '../../../components/Icon';
import TabPanel from '../../../components/TabPanel';
import AssetsInfo from './AssetsInfo';
import './index.css';
import MetadataForDistribution from './MetadataForDistribution';
import AudioMetaData from './MetadataForDistribution/Audio';
import FileUpload from './Upload';

function a11yProps (index) {
    return {
        id: `add-assets-tab-${index}`,
        'aria-controls': `add-assets-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#09090B',
        display: 'flex',
    },
    tabs: {
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        borderRight: '1px solid #222222',
    },
}));

const VerticalTabs = (props) => {
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                aria-label="Add Assets"
                className={ClassNames(classes.tabs, 'asset_tabs')}
                orientation="vertical"
                value={props.value}
                onChange={handleChange}>
                <Tab
                    className={ClassNames('tab', props.value === 0 ? 'active_tab' : '')}
                    icon={<Icon
                        className="file-upload stroke"
                        icon="file-upload"/>}
                    label="Source File"
                    {...a11yProps(0)} />
                <Tab
                    className={ClassNames('tab', props.value === 1 ? 'active_tab' : '')}
                    icon={<Icon
                        className="assets stroke"
                        icon="assets"/>}
                    label="Asset Information"
                    {...a11yProps(1)} />
                {/* <Tab */}
                {/*    className={ClassNames('tab', props.value === 2 ? 'active_tab' : '')} */}
                {/*    icon={<Icon */}
                {/*        className="rights" */}
                {/*        icon="rights"/>} */}
                {/*    label="Rights" */}
                {/*    {...a11yProps(2)} /> */}
                <Tab
                    className={ClassNames('tab', props.value === 2 ? 'active_tab' : '')}
                    icon={<Icon
                        className="metadata"
                        icon="metadata"/>}
                    label="Metadata for Distribution"
                    {...a11yProps(2)} />
                {/* <Tab */}
                {/*    className={ClassNames('tab', props.value === 4 ? 'active_tab' : '')} */}
                {/*    icon={<Icon */}
                {/*        className="distribution" */}
                {/*        icon="distribution"/>} */}
                {/*    label="Distribution Platforms" */}
                {/*    {...a11yProps(4)} /> */}
            </Tabs>
            <TabPanel
                className="tab_content"
                index={0}
                value={props.value}>
                <FileUpload
                    disable={props.disable}
                    onClick={props.onClick}/>
            </TabPanel>
            <TabPanel
                className="tab_content"
                index={1}
                value={props.value}>
                <AssetsInfo/>
            </TabPanel>
            {/* <TabPanel */}
            {/*    className="tab_content" */}
            {/*    index={2} */}
            {/*    value={props.value}> */}
            {/*    Rights */}
            {/* </TabPanel> */}
            <TabPanel
                className="tab_content"
                index={2}
                value={props.value}>
                {props.assetType === 'video'
                    ? <MetadataForDistribution/>
                    : props.assetType === 'audio'
                        ? <AudioMetaData/>
                        : null}
            </TabPanel>
            {/* <TabPanel */}
            {/*    className="tab_content" */}
            {/*    index={4} */}
            {/*    value={props.value}> */}
            {/*    <DistributionPlatforms/> */}
            {/* </TabPanel> */}
        </div>
    );
};

VerticalTabs.propTypes = {
    assetType: PropTypes.string.isRequired,
    disable: PropTypes.bool.isRequired,
    setTabValue: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        assetType: state.asset.new.dialog.assetType,
        value: state.asset.new.tabValue,
    };
};

const actionToProps = {
    setTabValue,
};

export default connect(stateToProps, actionToProps)(VerticalTabs);
