import { combineReducers } from 'redux';
import { LANGUAGE_CODE_SET, TEXT_SET, VOICE_SET } from '../../constants/textToSpeech';
import { languageOptions, voiceOptions } from '../../utils/assetOptions';

const text = (state = '', action) => {
    if (action.type === TEXT_SET) {
        return action.value;
    }

    return state;
};

const voice = (state = {
    value: '',
    list: voiceOptions,
}, action) => {
    if (action.type === VOICE_SET) {
        return {
            ...state,
            value: action.value,
        };
    } else if (action.type === LANGUAGE_CODE_SET) {
        return {
            ...state,
            list: action.list,
        };
    }

    return state;
};

const languageCode = (state = {
    value: '',
    list: languageOptions,
}, action) => {
    if (action.type === LANGUAGE_CODE_SET) {
        return {
            ...state,
            value: action.value,
        };
    }

    return state;
};

export default combineReducers({
    text,
    voice,
    languageCode,
});
