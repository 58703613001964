import { ListSubheader, makeStyles, MenuItem, TextField } from '@material-ui/core';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showDialogAddGenre } from '../../actions/asset';
import add from '../../assets/add.svg';
import '../SelectField/index.css';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#696969',
                borderWidth: '1px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
            },
        },
        '& .MuiFormHelperText-root': {
            '&.Mui-error': {
                width: '100%',
                margin: '-6px 0',
            },
        },
        '& .MuiFormLabel-root': {
            '&.Mui-focused': {
                color: '#838383',
            },
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            backgroundColor: 'red !important',
        },
    },
}));

const SelectFieldAddButton = (props) => {
    const onChange = (e) => props.onChange(e.target.value ? e.target.value : '');

    return (
        <TextField
            select
            className={ClassNames(useStyles().root, 'select_field')}
            id={props.id}
            label={props.label}
            margin="normal"
            name={props.name}
            value={props.value}
            variant="outlined"
            onChange={(e) => onChange(e)}>
            <ListSubheader>
                <div
                    className="add_new"
                    onClick={props.showDialogAddGenre}>
                    <img
                        alt="toggle icon"
                        className="add_custom_nav"
                        src={add}/>
                </div>
            </ListSubheader>
            {
                props.items.map((item) => (
                    <MenuItem
                        key={item.key || item.value || item.name}
                        value={item.value || item.name}>
                        {item.name}
                    </MenuItem>
                ))
            }
        </TextField>
    );
};

SelectFieldAddButton.propTypes = {
    genre: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string.isRequired,
            value: PropTypes.string,
        }),
    ).isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    showDialogAddGenre: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {};
};

const actionToProps = {
    showDialogAddGenre: showDialogAddGenre,
};

export default withRouter(connect(stateToProps, actionToProps)(SelectFieldAddButton));
