import {
    DEFAULT_OPTION_SET,
    INTERACTIVE_PLAYER_SET,
    INTERACTIVE_QUESTION_SET,
    INTERACTIVE_QUESTION_TYPE_SET,
    INTERACTIVE_QUESTIONS_LIST_SET,
    OPTIONS_SET,
    OPTION_TYPE_SET,
    QUESTION_TYPES_SHOW,
    INTERACTIVE_VIDEOS_FETCH_ERROR,
    INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    INTERACTIVE_VIDEO_ADD_ERROR,
    INTERACTIVE_VIDEO_ADD_IN_PROGRESS,
    INTERACTIVE_VIDEO_ADD_SUCCESS,
    INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_ERROR,
    INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_IN_PROGRESS,
    INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_SUCCESS,
    INTERACTIVE_EDIT_SET,
    INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_ERROR,
    INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_IN_PROGRESS,
    INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_SUCCESS,
    INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_ERROR,
    INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS,
    INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_SUCCESS,
    INTERACTIVE_SAVE_SET,
    CURRENT_INTERACTION_SET,
    INTERACTIVE_VIDEO_UPDATE_ERROR, INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS, INTERACTIVE_VIDEO_UPDATE_SUCCESS,
} from '../constants/interactiveVideo';
import axios from 'axios';
import {
    urlFetchInteractiveVideo,
    INTERACTIVE_VIDEO_URL,
    urlAddInteractions,
    urlUpdateInteraction,
    urlUpdateInteractiveVideo,
} from '../constants/url';

export const showQuestionTypes = () => {
    return {
        type: QUESTION_TYPES_SHOW,
    };
};

export const setQuestion = (value) => {
    return {
        type: INTERACTIVE_QUESTION_SET,
        value,
    };
};

export const setQuestionsList = (value) => {
    return {
        type: INTERACTIVE_QUESTIONS_LIST_SET,
        value,
    };
};

export const setPlayer = (value) => {
    return {
        type: INTERACTIVE_PLAYER_SET,
        value,
    };
};

export const setQuestionType = (value) => {
    return {
        type: INTERACTIVE_QUESTION_TYPE_SET,
        value,
    };
};

export const setDefaultOption = (value, option) => {
    return {
        type: DEFAULT_OPTION_SET,
        value,
        option,
    };
};

export const setOptions = (value) => {
    return {
        type: OPTIONS_SET,
        value,
    };
};

export const setOptionType = (value, index) => {
    return {
        type: OPTION_TYPE_SET,
        value,
        index,
    };
};

const fetchInteractiveVideosInProgress = () => {
    return {
        type: INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    };
};

const fetchInteractiveVideosSuccess = (list) => {
    return {
        type: INTERACTIVE_VIDEOS_FETCH_SUCCESS,
        list,
    };
};

const fetchInteractiveVideosError = (message) => {
    return {
        type: INTERACTIVE_VIDEOS_FETCH_ERROR,
        message,
    };
};

export const getInteractiveVideos = (assetId, token) => (dispatch) => {
    dispatch(fetchInteractiveVideosInProgress());
    const url = urlFetchInteractiveVideo(assetId);

    axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    }).then((res) => {
        dispatch(fetchInteractiveVideosSuccess(res.data && res.data.result));
    }).catch((error) => {
        dispatch(fetchInteractiveVideosError(error.response ? error.response.data.message : error.message));
    });
};

const addInteractiveVideoInProgress = () => {
    return {
        type: INTERACTIVE_VIDEO_ADD_IN_PROGRESS,
    };
};

const addInteractiveVideoSuccess = (value) => {
    return {
        type: INTERACTIVE_VIDEO_ADD_SUCCESS,
        value,
    };
};

const addInteractiveVideoError = (message) => {
    return {
        type: INTERACTIVE_VIDEO_ADD_ERROR,
        message,
    };
};

export const addInteractiveVideo = (data, token, cb) => (dispatch) => {
    dispatch(addInteractiveVideoInProgress());

    axios.post(INTERACTIVE_VIDEO_URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    }).then((res) => {
        dispatch(addInteractiveVideoSuccess(res.data && res.data.result));
        cb(null);
    }).catch((error) => {
        dispatch(addInteractiveVideoError(error.response ? error.response.data.message : error.message));
        cb(error);
    });
};

const addInteractionsToInteractiveVideoInProgress = () => {
    return {
        type: INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_IN_PROGRESS,
    };
};

const addInteractionsToInteractiveVideoSuccess = (value) => {
    return {
        type: INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_SUCCESS,
        value,
    };
};

const addInteractionsToInteractiveVideoError = (message) => {
    return {
        type: INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_ERROR,
        message,
    };
};

export const addInteractionsToInteractiveVideo = (id, data, token, cb) => (dispatch) => {
    dispatch(addInteractionsToInteractiveVideoInProgress());

    const url = urlAddInteractions(id);
    axios.post(url, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    }).then((res) => {
        dispatch(addInteractionsToInteractiveVideoSuccess(res.data && res.data.result));
        cb(null);
    }).catch((error) => {
        dispatch(addInteractionsToInteractiveVideoError(error.response ? error.response.data.message : error.message));
        cb(error);
    });
};

export const setInteractiveEdit = (value, options, option, defaultValue) => {
    return {
        type: INTERACTIVE_EDIT_SET,
        value,
        options,
        option,
        defaultValue,
    };
};

export const setInteractiveSave = (value) => {
    return {
        type: INTERACTIVE_SAVE_SET,
        value,
    };
};

const updateInteractionOfInteractiveVideoInProgress = () => {
    return {
        type: INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS,
    };
};

const updateInteractionOfInteractiveVideoSuccess = (value) => {
    return {
        type: INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_SUCCESS,
        value,
    };
};

const updateInteractionOfInteractiveVideoError = (message) => {
    return {
        type: INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_ERROR,
        message,
    };
};

export const updateInteractionOfInteractiveVideo = (data, interactiveVideoId, interactionId, token, cb) => (dispatch) => {
    dispatch(updateInteractionOfInteractiveVideoInProgress());

    const url = urlUpdateInteraction(interactiveVideoId, interactionId);
    axios.put(url, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    }).then((res) => {
        dispatch(updateInteractionOfInteractiveVideoSuccess(res.data && res.data.result));
        cb(null);
    }).catch((error) => {
        dispatch(updateInteractionOfInteractiveVideoError(error.response ? error.response.data.message
            : error.message));
        cb(error);
    });
};

const deleteInteractionOfInteractiveVideoInProgress = () => {
    return {
        type: INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_IN_PROGRESS,
    };
};

const deleteInteractionOfInteractiveVideoSuccess = (value) => {
    return {
        type: INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_SUCCESS,
        value,
    };
};

const deleteInteractionOfInteractiveVideoError = (message) => {
    return {
        type: INTERACTION_OF_INTERACTIVE_VIDEO_DELETE_ERROR,
        message,
    };
};

export const deleteInteractionOfInteractiveVideo = (interactiveVideoId, interactionId, token, cb) => (dispatch) => {
    dispatch(deleteInteractionOfInteractiveVideoInProgress());

    const url = urlUpdateInteraction(interactiveVideoId, interactionId);
    axios.delete(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    }).then((res) => {
        dispatch(deleteInteractionOfInteractiveVideoSuccess(res.data && res.data.result));
        cb(null);
    }).catch((error) => {
        dispatch(deleteInteractionOfInteractiveVideoError(error.response ? error.response.data.message
            : error.message));
        cb(error);
    });
};

export const setCurrentInteraction = (value) => {
    return {
        type: CURRENT_INTERACTION_SET,
        value,
    };
};

const updateInteractiveVideoInProgress = () => {
    return {
        type: INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS,
    };
};

const updateInteractiveVideoSuccess = (value) => {
    return {
        type: INTERACTIVE_VIDEO_UPDATE_SUCCESS,
        value,
        message: 'success',
    };
};

const updateInteractiveVideoError = (message) => {
    return {
        type: INTERACTIVE_VIDEO_UPDATE_ERROR,
        message,
    };
};

export const updateInteractiveVideo = (data, interactiveVideoId, token) => (dispatch) => {
    dispatch(updateInteractiveVideoInProgress());

    const url = urlUpdateInteractiveVideo(interactiveVideoId);
    axios.put(url, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    }).then((res) => {
        dispatch(updateInteractiveVideoSuccess(res.data && res.data.result));
    }).catch((error) => {
        dispatch(updateInteractiveVideoError(error.response ? error.response.data.message
            : error.message));
    });
};
