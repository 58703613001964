import moment from 'moment';
import { combineReducers } from 'redux';
import { ACTIVE_TAB_SET, CALENDAR_OPEN, SELECTED_DATE_SET, WEEK_START_DATE_SET } from '../../constants/program';
import { SLOT_POP_UP_SHOW_SET } from '../../constants/slots';
import { channel } from './channel';
import dynamic from './dynamic';
import {
    lastUpdated, overlay, playOut, program,
    currentUpdatingSlot, updateProgram, dayTypeValue, deleteProgram, videoPlayerType,
} from './program';
import { slot } from './slot';
import { stream } from './stream';
import player from './player';
import bugs from './bugs';
import config from './config';
import slotSettings from './slotSettings';
import ads from './ads';
import individualSlot from './individualSlot';

const selectedDate = (state = new Date(), action) => {
    if (action.type === SELECTED_DATE_SET) {
        return action.date;
    }
    return state;
};

const weekStartDate = (state = moment(new Date()).clone().startOf('week')._d, action) => {
    if (action.type === WEEK_START_DATE_SET) {
        return moment(action.date).clone().startOf('week')._d;
    }
    return state;
};

const activeTab = (state = moment(new Date()).day(), action) => {
    if (action.type === ACTIVE_TAB_SET) {
        return action.value;
    }
    return state;
};

export const calendar = (state = null, action) => {
    if (action.type === CALENDAR_OPEN) {
        return action.value;
    }
    return state;
};

export const timeFormat = (state = 'HH:mm:ss', action) => {
    return state;
};

export const showSlotPopup = (state = false, action) => {
    if (action.type === SLOT_POP_UP_SHOW_SET) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    selectedDate,
    weekStartDate,
    activeTab,
    calendar,
    program,
    overlay,
    slot,
    slotSettings,
    channel,
    stream,
    playOut,
    lastUpdated,
    timeFormat,
    showSlotPopup,
    dynamic,
    updateProgram,
    dayTypeValue,
    bugs,
    player,
    deleteProgram,
    videoPlayerType,
    config,
    ads,
    currentUpdatingSlot,
    individualSlot,
});
