import { Button } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';

const DeleteButton = (props) => {
    const handleClick = () => {
        props.onClick();
    };

    return (
        <Button className="delete_button" onClick={handleClick}>
            Delete
        </Button>
    );
};

DeleteButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default DeleteButton;
