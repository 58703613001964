import { makeStyles, TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import CopyButtonIcon from '../CopyButton/CopyButtonIcon';
import './index.css';

const filter = createFilterOptions();

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#696969',
                borderWidth: '1px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
            },
        },
        '& .MuiAutocomplete-popupIndicatorOpen': {
            transform: 'unset',
        },
        '& .MuiFormHelperText-root': {
            '&.Mui-error': {
                width: '100%',
                margin: '-6px 0',
            },
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            backgroundColor: 'red !important',
        },
    },
}));

const AutoCompleteTextField = (props) => {
    const value = props.value && props.value.map((value) => { return value.name; });

    return (
        <Autocomplete
            multiple
            className={ClassNames(useStyles().root, 'auto_complete text_field ' + (props.className
                ? props.className
                : ''))}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`,
                    });
                }

                return filtered;
            }}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                if (option.inputValue) {
                    return option.inputValue;
                }
                return option.name;
            }}
            getOptionSelected={(option, value) => {
                return option._id === value._id;
            }}
            id="tags-standard"
            options={props.options}
            popupIcon={<CopyButtonIcon data={value.join(' , ')}/>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label ? props.label : null}
                    variant="outlined"/>
            )}
            renderOption={(option) => option.name}
            value={props.value}
            onChange={(event, newValue) => {
                const inputValue = newValue.filter((value) => value.inputValue);
                if (typeof newValue === 'string') {
                    props.showDialog('');
                } else if (newValue && inputValue && inputValue.length > 0) {
                    props.showDialog(inputValue[0].inputValue);
                } else {
                    props.onChange(newValue);
                }
            }}/>
    );
};

AutoCompleteTextField.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    InputLabelProps: PropTypes.bool,
    className: PropTypes.string,
    freeSolo: PropTypes.bool,
    label: PropTypes.string,
    showDialog: PropTypes.func,
    value: PropTypes.any,
};

export default AutoCompleteTextField;
