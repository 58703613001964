import React from 'react';
import './index.css';
import CopyButton from '../../../../../components/CopyButton';
import KeyTextField from './KeyTextField';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

const StreamKey = (props) => {
    return (
        <div className="stream_key">
            <p className="heading_text">Live Stream Inputs</p>
            <div className="content">
                <div className="row">
                    <p className="text1">Stream URL</p>
                    <div className="horizontal_row">
                        <p className="text2">{props.stream &&
                        props.stream.configuration &&
                        props.stream.configuration.stream_keys &&
                        props.stream.configuration.stream_keys.live_input_rtmp &&
                        props.stream.configuration.stream_keys.live_input_rtmp.url}</p>
                        <CopyButton data={props.stream &&
                        props.stream.configuration &&
                        props.stream.configuration.stream_keys &&
                        props.stream.configuration.stream_keys.live_input_rtmp &&
                        props.stream.configuration.stream_keys.live_input_rtmp.url}/>
                    </div>
                </div>
                <div className="row">
                    <p className="text1">Stream Key</p>
                    <KeyTextField value={props.stream &&
                    props.stream.configuration &&
                    props.stream.configuration.stream_keys &&
                    props.stream.configuration.stream_keys.live_input_rtmp &&
                    props.stream.configuration.stream_keys.live_input_rtmp.key}/>
                </div>
                <div className="row">
                    <p className="text1">Status</p>
                    <p className="text2">
                        {props.stream &&
                        props.stream.playing_live ? 'Excellent' : 'Not Playing'}</p>
                </div>
            </div>
        </div>
    );
};

StreamKey.propTypes = {
    stream: PropTypes.shape({
        configuration: PropTypes.shape({
            stream_keys: PropTypes.shape({
                live_input_rtmp: PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    url: PropTypes.string.isRequired,
                }),
            }),
        }),
        playing_live: PropTypes.bool,
    }),
};

const stateToProps = (state) => {
    return {
        stream: state.live.config.stream,
    };
};

export default connect(stateToProps, null)(StreamKey);
