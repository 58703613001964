import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assetCategoryInfo, setAssetCategory } from '../../../../actions/asset';
import LinearProgress from '../../../../components/LinearProgress';
import SelectField from '../../../../components/SelectField';
import { stringToUpperFirstChar } from '../../../../utils/string';
import DialogAddAssetCategory from '../../../Sidebar/DialogAddAssetCategory';

class CategorySelectField extends Component {
    constructor (props) {
        super(props);
        this.fetch = this.fetch.bind(this);
    }

    componentDidMount () {
        if (this.props.categories.length) {
            return;
        }

        this.fetch();
    }

    fetch () {
        if (this.props.inProgress) {
            return;
        }

        const token = localStorage.getItem('authorizationToken');

        if (token) {
            this.props.fetch(token);
        }
    }

    render () {
        const Items = this.props.categories.length
            ? this.props.categories.map(
                (item) => ({
                    value: item._id,
                    name: stringToUpperFirstChar(item.name),
                }),
            )
            : [];

        return (
            <div className={this.props.inProgress ? 'select_field_progress' : ''}>
                <SelectField
                    className="text_field"
                    id="video_select_field"
                    items={Items}
                    label={this.props.label}
                    name={this.props.name}
                    required={true}
                    showAddButton={this.props.showAddButton}
                    value={this.props.value}
                    onChange={this.props.onChange}/>
                {
                    this.props.inProgress
                        ? <LinearProgress/>
                        : null
                }
                <DialogAddAssetCategory/>
            </div>
        );
    }
}

CategorySelectField.propTypes = {
    categories: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    showDialogAddVideo: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    showAddButton: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.category,
        categories: state.asset.assetCategoriesList.list,
        inProgress: state.asset.new.categories.inProgress,
    };
};

const actionsToProps = {
    fetch: assetCategoryInfo,
    onChange: setAssetCategory,
};

export default connect(stateToProps, actionsToProps)(CategorySelectField);
