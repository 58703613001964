import { Button, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchPlayOut } from '../../../actions/program';
import { pushSlot, startStream, stopStream, updateStreamConfig } from '../../../actions/slot';
import instaIcon from '../../../assets/insta.svg';
import instaActiveIcon from '../../../assets/instaActive.svg';
import restartIcon from '../../../assets/restart.svg';
import stopIcon from '../../../assets/stop.svg';
import twitterIcon from '../../../assets/twitter.svg';
import twitterActiveIcon from '../../../assets/twitterActive.svg';
import videoIcon from '../../../assets/video.svg';
import youtubeIcon from '../../../assets/youtube.svg';
import youtubeActiveIcon from '../../../assets/youtubeActive.svg';
import ChannelSelectField from './ChannelStream/ChannelsSelectField';
import StreamSelectField from './ChannelStream/StreamSelectField';
import MintLinearBroadcast from './MintLinearBroadcast';

class Header extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            anchorElement: null,
            selectedDate: '',
            youtubeStream: true,
            twitterStream: false,
            instaStream: false,
        };
        this.handleStreamingDestination = this.handleStreamingDestination.bind(this);
        this.handleStreamingQuality = this.handleStreamingQuality.bind(this);
        this.handleSync = this.handleSync.bind(this);
        this.reloadPlayout = this.reloadPlayout.bind(this);
    }

    handleStreamingDestination (destination) {
        if (destination === 'youtube') {
            this.setState({
                youtubeStream: true,
                twitterStream: false,
                instaStream: false,
            });
        } else if (destination === 'twitter') {
            this.setState({
                youtubeStream: false,
                twitterStream: true,
                instaStream: false,
            });
        } else if (destination === 'insta') {
            this.setState({
                youtubeStream: false,
                twitterStream: false,
                instaStream: true,
            });
        }
    }

    handleStreamingQuality (quality) {
        const token = localStorage.getItem('authorizationToken');

        this.props.update(this.props.channel,
            this.props.stream,
            { streamQuality: quality },
            token, () => {
            });
    }

    handleSync () {
        const currentSlot = this.props.slots.length
            ? this.props.slots.filter((item) => (
                moment(new Date())
                    .isBetween(moment(item['start_at']),
                        moment(item['end_at']),
                        'seconds',
                        [])

            ))
            : [];
        const token = localStorage.getItem('authorizationToken');

        if (currentSlot && currentSlot.length && token) {
            this.props.push(this.props.channel,
                this.props.stream,
                currentSlot[0]._id,
                token, (error) => {
                    if (error) {
                        return;
                    }

                    this.props.start(this.props.channel,
                        this.props.stream,
                        token, (error) => {
                            if (error) {
                                return;
                            }

                            setTimeout(this.reloadPlayout(), 5000);
                        });
                });
        }
    }

    handleStop () {
        const token = localStorage.getItem('authorizationToken');

        this.props.stop(this.props.channel,
            this.props.stream,
            token, (error) => {
                if (error) {
                    return;
                }

                this.reloadPlayout();
            });
    }

    reloadPlayout () {
        this.props.fetchPlayOut(this.props.channel,
            this.props.stream,
            () => {
            },
        );
    }

    render () {
        const {
            youtubeStream,
            twitterStream,
            instaStream,
        } = this.state;
        const timeZone = this.props.streamConfig && this.props.streamConfig['stream_timezone'];

        return (
            <div className="header">
                <div className="header_left">
                    <img
                        alt="channel_image"
                        src={this.props.channelImageUrl}/>
                    <div className="name sarabun_font_weight">
                        <div className="channels_field">
                            <ChannelSelectField/>
                        </div>
                        <div className="channels_field">
                            <StreamSelectField/>
                        </div>
                        <MintLinearBroadcast/>
                        {
                            this.props.inProgress
                                ? <p className="date sarabun_font_weight saving">
                                    Saving<span>.</span><span>.</span><span>.</span>
                                </p>
                                : this.props.streamStatus === 'running'
                                    ? <p className="date sarabun_font_weight">
                                    Streaming Since : {' '}
                                        {
                                            this.props.checked
                                                ? momentTimeZone.tz(this.props.lastUpdate, timeZone).format('hh:mm A, d MMM, YYYY')
                                                : momentTimeZone.tz(this.props.lastUpdate, timeZone).format('HH:mm A, d MMM, YYYY')
                                        }
                                    </p>
                                    : null
                        }
                    </div>
                </div>
                <div className="air_section">
                    <div
                        className={this.props.streamStatus === 'running'
                            ? 'onair_active'
                            : 'onair_inactive'}>
                        ON AIR
                    </div>
                    <div
                        className={this.props.streamStatus !== 'running'
                            ? 'onair_active'
                            : 'onair_inactive'}>
                        OFF AIR
                    </div>
                </div>
                <div className="stream_actions_section">
                    <div>
                        {
                            this.props.streamStatus !== 'running'
                                ? <Button
                                    className="start_button"
                                    disabled={this.props.syncInProgress}
                                    onClick={() => this.handleSync()}>
                                    {
                                        this.props.syncInProgress
                                            ? <CircularProgress
                                                className="loader_css" style={{
                                                    position: 'relative',
                                                    width: '20px',
                                                    height: '20px',
                                                }}/>
                                            : <img alt="restart" src={restartIcon}/>
                                    }
                                    START
                                </Button>
                                : <Button
                                    className="restart_button"
                                    disabled={this.props.syncInProgress}
                                    onClick={() => this.handleSync()}>
                                    {
                                        this.props.syncInProgress
                                            ? <CircularProgress
                                                className="loader_css" style={{
                                                    position: 'relative',
                                                    width: '20px',
                                                    height: '20px',
                                                }}/>
                                            : <img alt="restart" src={restartIcon}/>
                                    }
                                    RESTART
                                </Button>
                        }
                        <Button
                            className="stop_button"
                            disabled={this.props.syncInProgress ||
                            this.props.streamStatus !== 'running'}
                            onClick={() => this.handleStop()}>
                            <img alt="stop" src={stopIcon}/>
                            STOP
                        </Button>
                        {/* { */}
                        {/*    this.props.syncInProgress */}
                        {/*        ? <CircularProgress className="loader_css" style={{ position: 'absolute' }}/> */}
                        {/*        : '' */}
                        {/* } */}
                    </div>
                </div>
                <div className="streaming_destination">
                    <div className="streaming_destination_header"> Streaming Destination</div>
                    <div className="streaming_destination_section">
                        <div>
                            {
                                twitterStream
                                    ? <img alt="twitter" src={twitterActiveIcon}/>
                                    : <img
                                        alt="twitter"
                                        className="twitter_image"
                                        src={twitterIcon}/>
                            }
                        </div>
                        <div className="line"/>
                        <div className="youtube_stream">
                            {
                                youtubeStream
                                    ? <img alt="youtube" src={youtubeActiveIcon}/>
                                    : <img alt="youtube" src={youtubeIcon}/>
                            }

                        </div>
                        <div className="line"/>
                        <div
                            className={instaStream ? 'insta_active' : ''}>
                            {
                                instaStream
                                    ? <img alt="insta" src={instaActiveIcon}/>
                                    : <img alt="insta" src={instaIcon}/>
                            }
                        </div>
                    </div>
                </div>
                <div className="stream_end">
                    <div className="video_player">
                        <img alt="video" src={videoIcon}/>
                    </div>
                    <div className="streaming_quality">
                        <div className="streaming_quality_header"> Streaming Quality</div>
                        <div className="streaming_quality_section">
                            <Button
                                className={this.props.streamConfig &&
                                this.props.streamConfig.stream_quality === 'SD'
                                    ? 'quality_active' : 'quality_inactive'}
                                disabled={(this.props.streamConfig &&
                                    this.props.streamConfig.stream_quality === 'SD') ||
                                !this.props.stream}
                                onClick={() => this.handleStreamingQuality('SD')}>
                                SD
                            </Button>
                            <Button
                                className={this.props.streamConfig &&
                                this.props.streamConfig.stream_quality === 'HD'
                                    ? 'quality_active' : 'quality_inactive'}
                                disabled={(this.props.streamConfig &&
                                    this.props.streamConfig.stream_quality === 'HD') ||
                                !this.props.stream}
                                onClick={() => this.handleStreamingQuality('HD')}>
                                HD
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Header.propTypes = {
    channel: PropTypes.string.isRequired,
    channelImageUrl: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    fetchPlayOut: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lastUpdate: PropTypes.instanceOf(Date).isRequired,
    push: PropTypes.func.isRequired,
    slots: PropTypes.array.isRequired,
    start: PropTypes.func.isRequired,
    stop: PropTypes.func.isRequired,
    stream: PropTypes.string.isRequired,
    streamStatus: PropTypes.string.isRequired,
    syncInProgress: PropTypes.bool.isRequired,
    update: PropTypes.func.isRequired,
    streamConfig: PropTypes.shape({
        stream_quality: PropTypes.string,
        stream_timezone: PropTypes.string,
    }),
};

const stateToProps = (state) => {
    return {
        lastUpdate: state.live.lastUpdated.time,
        inProgress: state.live.lastUpdated.inProgress,
        checked: state.profile.timeFormat.checked,
        channel: state.live.channel.current,
        channelImageUrl: state.live.channel.imageUrl,
        stream: state.live.stream.current,
        streamConfig: state.live.stream.configuration,
        syncInProgress: state.live.stream.syncInProgress,
        slots: state.live.slot.list,
        streamStatus: state.live.playOut.status,
    };
};

const actionsToProps = {
    start: startStream,
    push: pushSlot,
    fetchPlayOut,
    stop: stopStream,
    update: updateStreamConfig,
};

export default connect(stateToProps, actionsToProps)(Header);
