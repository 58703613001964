import { combineReducers } from 'redux';
import {
    DIALOG_DELETE_TEMPLATE_HIDE,
    DIALOG_DELETE_TEMPLATE_SHOW,
    FETCH_SLOT_TEMPLATE_ERROR,
    FETCH_SLOT_TEMPLATE_IN_PROGRESS,
    FETCH_SLOT_TEMPLATE_SUCCESS,
    SELECTED_SLOT_TEMPLATES_SET,
    SLOT_TEMPLATES_SET,
} from '../../constants/dynamicPrograms';
import {
    DYNAMIC_INPUT_VALUES_CLEAR,
    SLOT_CATEGORY_SET,
    SLOT_CONTENT_TYPE_SET,
    SLOT_DURATION_SET,
    SLOT_EXCLUDES_SET,
    SLOT_INCLUDES_SET,
    TEMPLATE_NAME_SET,
} from '../../constants/slots';

const categories = (state = [], action) => {
    switch (action.type) {
    case SLOT_CATEGORY_SET:
        return action.value;
    case DYNAMIC_INPUT_VALUES_CLEAR:
        return [];
    default:
        return state;
    }
};

const excludes = (state = [], action) => {
    switch (action.type) {
    case SLOT_EXCLUDES_SET:
        return action.value;
    case DYNAMIC_INPUT_VALUES_CLEAR:
        return [];
    default:
        return state;
    }
};

const includes = (state = [], action) => {
    switch (action.type) {
    case SLOT_INCLUDES_SET:
        return action.value;
    case DYNAMIC_INPUT_VALUES_CLEAR:
        return [];
    default:
        return state;
    }
};

const contentType = (state = [], action) => {
    switch (action.type) {
    case SLOT_CONTENT_TYPE_SET:
        return action.value;
    case DYNAMIC_INPUT_VALUES_CLEAR:
        return [];
    default:
        return state;
    }
};

const duration = (state = '', action) => {
    switch (action.type) {
    case SLOT_DURATION_SET:
        return Number(action.value) > 180 ? '' : action.value;
    case DYNAMIC_INPUT_VALUES_CLEAR:
        return '';
    default:
        return state;
    }
};

const templateName = (state = '', action) => {
    if (action.type === TEMPLATE_NAME_SET) {
        return action.value;
    }

    return state;
};

const slotTemplates = (state = {
    list: [],
    inProgress: false,
    checked: [],
    selected: {},
}, action) => {
    switch (action.type) {
    case FETCH_SLOT_TEMPLATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_SLOT_TEMPLATE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.value,
        };
    case FETCH_SLOT_TEMPLATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case SLOT_TEMPLATES_SET:
        return {
            ...state,
            checked: action.value,
        };
    case SELECTED_SLOT_TEMPLATES_SET:
        return {
            ...state,
            selected: action.value,
        };
    case DYNAMIC_INPUT_VALUES_CLEAR:
        return {
            ...state,
            checked: [],
            selected: {},
        };
    default:
        return state;
    }
};

const deleteTemplate = (state = {
    open: false,
    item: {},
}, action) => {
    switch (action.type) {
    case DIALOG_DELETE_TEMPLATE_SHOW:
        return {
            open: true,
            item: action.value,
        };
    case DIALOG_DELETE_TEMPLATE_HIDE:
        return {
            open: false,
            item: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    categories,
    contentType,
    includes,
    excludes,
    duration,
    templateName,
    slotTemplates,
    deleteTemplate,
});
