import React from 'react';
import { setNetwork } from '../../../actions/account';
import SelectField from '../../../components/SelectField';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

const items = [{
    value: 'flixnet-1',
    name: 'FlixNet-1',
}, {
    value: 'flixnet-2',
    name: 'FlixNet-2',
}];

const NetworkSelectField = (props) => {
    return (
        <SelectField
            id="network_select_field"
            items={items}
            name="network"
            value={props.value}
            onChange={props.onChange}/>
    );
};

NetworkSelectField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.account.network,
    };
};

const actionToProps = {
    onChange: setNetwork,
};

export default connect(stateToProps, actionToProps)(NetworkSelectField);
