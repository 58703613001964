import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setTabContent2, fetchAssetChapters } from '../../../actions/chapterMarking';
import CinematicIntelligence from './CinematicIntelligence';
import Objects from './Objects';
import People from './People';
import SearchTextField from './SearchTextField';

const ContentSideBar = (props) => {
    const handleClick = (value, id) => {
        if (props.value === value) {
            return;
        }

        updateChapters(props.tabValue === 0 && id,
            props.tabValue === 1 && id, props.tabValue === 2 && id);

        props.onClick(value);
    };

    const updateChapters = (categoryId, peopleId, objectName) => {
        if (props.selectedAsset && props.selectedAsset._id &&
            (categoryId || peopleId || objectName)) {
            props.fetchChapters(props.selectedAsset && props.selectedAsset._id,
                localStorage.getItem('authorizationToken'),
                categoryId, peopleId, objectName);
        }
    };

    return (
        <div className="content_side_bar">
            {props.selectedAsset && props.selectedAsset._id
                ? <><p className="main_heading">{props.tabName}</p>
                    <div className="tabs scroll">
                        <SearchTextField/>
                        {props.tabValue === 0 &&
                        <CinematicIntelligence
                            fetch={updateChapters}
                            value={props.value}
                            onClick={handleClick}/>}
                        {props.tabValue === 1 &&
                        <People
                            fetch={updateChapters}
                            value={props.value}
                            onClick={handleClick}/>}
                        {props.tabValue === 2 &&
                        <Objects
                            fetch={updateChapters}
                            value={props.value}
                            onClick={handleClick}/>}
                    </div>
                </>
                : null
            }

        </div>
    );
};

ContentSideBar.propTypes = {
    fetchChapters: PropTypes.func.isRequired,
    selectedAsset: PropTypes.shape({
        _id: PropTypes.string.isRequired,
    }).isRequired,
    tabName: PropTypes.string.isRequired,
    tabValue: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.chapterMarking.tab2,
        tabValue: state.chapterMarking.tab1.value,
        tabName: state.chapterMarking.tab1.name,
        selectedAsset: state.asset._.current,
    };
};

const actionToProps = {
    onClick: setTabContent2,
    fetchChapters: fetchAssetChapters,
};

export default connect(stateToProps, actionToProps)(ContentSideBar);
