import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setUploadFile } from '../../../../actions/asset';
import { showMessage } from '../../../../actions/snackbar';
import uploadIcon from '../../../../assets/upload.png';
import uploadSuccess from '../../../../assets/addAssets/uploadSuccess.png';
import './index.css';
import UploadInProgress from './UploadInProgress';
import ClassNames from 'classnames';

const FileSelection = (props) => {
    const [name, setName] = useState('');
    const handleFileChange = (selectedFiles) => {
        if (selectedFiles && selectedFiles.length > 0 && selectedFiles[0]) {
            if (selectedFiles[0].name) {
                setName(selectedFiles[0].name);
            }

            props.setUploadFile(selectedFiles[0]);
            props.onClick(selectedFiles[0]);
        } else {
            props.showMessage('File Not Selected');
        }
    };

    const disable = props.uploadList && props.uploadList.length && props.uploadList.filter((value) => !value.cancel);

    return (
        <div
            className="upload_file"
            onDrop={(e) =>
                handleFileChange(e.dataTransfer.files)
            }>
            {(disable && disable.length) || props.assetId
                ? <img
                    alt="upload"
                    src={props.assetId && props.asset && props.asset.file &&
                    props.asset.file.name ? uploadSuccess : uploadIcon}/>
                : <img alt="upload" src={uploadIcon}/>}
            {props.uploadInProgress &&
            props.uploadProgress.total &&
            props.uploadProgress.loaded
                ? <UploadInProgress/>
                : <div className="info">
                    <p className="title">Select Source File from your local files</p>
                    {props.assetId && props.asset && props.asset.file &&
                    props.asset.file.name
                        ? <p className={ClassNames('file_name ', 'success')}>
                            {props.asset.file.name}
                        </p>
                        : name
                            ? <p className="file_name">
                                {name}
                            </p> : null}
                    {(disable && disable.length) || props.assetId
                        ? <Button disabled={props.disable}>
                            {props.assetId && props.asset && props.asset.file &&
                            props.asset.file.name
                                ? 'Replace File'
                                : 'Upload'}
                            <input
                                disabled={props.disable}
                                type="file"
                                onChange={(e) =>
                                    handleFileChange(e.target.files)
                                }/>
                        </Button>
                        : <Button disabled={props.disable}>
                            Upload
                            <input
                                // accept={'video/mp4,video/x-m4v,video/*'}
                                disabled={props.disable}
                                type="file"
                                onChange={(e) =>
                                    handleFileChange(e.target.files)
                                }/>
                        </Button>}
                </div>}
        </div>
    );
};

FileSelection.propTypes = {
    asset: PropTypes.object.isRequired,
    assetId: PropTypes.string.isRequired,
    assetType: PropTypes.string.isRequired,
    disable: PropTypes.bool.isRequired,
    setUploadFile: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    uploadInProgress: PropTypes.bool.isRequired,
    uploadList: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    uploadProgress: PropTypes.shape({
        total: PropTypes.number.isRequired,
        loaded: PropTypes.number.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        asset: state.asset.new.asset,
        assetType: state.asset.new.dialog.assetType,
        assetId: state.asset.new.assetId,
        uploadInProgress: state.asset.upload.inProgress,
        uploadProgress: state.asset.upload.progress,
        uploadList: state.asset.videoAssets.uploadFile.list,
    };
};

const actionsToProps = {
    setUploadFile,
    showMessage,
};

export default connect(stateToProps, actionsToProps)(FileSelection);
