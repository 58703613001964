import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    setTabContent1,
    fetchAssetChapterCategories,
    fetchAssetChapterObjects,
    fetchAssetChapterPeople,
} from '../../actions/chapterMarking';
import CustomIcons from '../../components/CustomIcons';

const MainSideBar = (props) => {
    useEffect(() => {
        if (props.selectedAsset && props.selectedAsset._id) {
            props.fetchCategories(props.selectedAsset && props.selectedAsset._id,
                localStorage.getItem('authorizationToken'));
        }
    }, [props.selectedAsset]);

    const handleClick = (value, name) => {
        if (props.value === value) {
            return;
        }
        if (name === 'Cinematic Intelligence') {
            props.fetchCategories(props.selectedAsset && props.selectedAsset._id,
                localStorage.getItem('authorizationToken'));
        }
        if (name === 'People') {
            props.fetchPeople(props.selectedAsset && props.selectedAsset._id,
                localStorage.getItem('authorizationToken'));
        }
        if (name === 'Objects') {
            props.fetchObjects(props.selectedAsset && props.selectedAsset._id,
                localStorage.getItem('authorizationToken'));
        }

        props.onClick(value, name);
    };

    return (
        <div className="content_side_bar">
            <p className="main_heading">Filters</p>
            <div className="tabs scroll">
                <Button
                    className={props.value === 0 ? 'active' : ''}
                    onClick={() => handleClick(0, 'Cinematic Intelligence')}>
                    <CustomIcons.CinematicIntelligence/>
                    Cinematic Intelligence
                </Button>
                <Button
                    className={props.value === 1 ? 'active' : ''}
                    onClick={() => handleClick(1, 'People')}>
                    <CustomIcons.People/>
                    People
                </Button>
                <Button
                    className={props.value === 2 ? 'active' : ''}
                    onClick={() => handleClick(2, 'Objects')}>
                    <CustomIcons.Objects/>
                    Objects
                </Button>
            </div>
        </div>
    );
};

MainSideBar.propTypes = {
    fetchCategories: PropTypes.func.isRequired,
    fetchObjects: PropTypes.func.isRequired,
    fetchPeople: PropTypes.func.isRequired,
    selectedAsset: PropTypes.shape({
        _id: PropTypes.string.isRequired,
    }).isRequired,
    value: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.chapterMarking.tab1.value,
        selectedAsset: state.asset._.current,
    };
};

const actionToProps = {
    onClick: setTabContent1,
    fetchCategories: fetchAssetChapterCategories,
    fetchObjects: fetchAssetChapterObjects,
    fetchPeople: fetchAssetChapterPeople,
};

export default connect(stateToProps, actionToProps)(MainSideBar);
