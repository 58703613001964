export const SELECTED_DATE_SET = 'SELECTED_DATE_SET';
export const WEEK_START_DATE_SET = 'WEEK_START_DATE_SET';
export const ACTIVE_TAB_SET = 'ACTIVE_TAB_SET';
export const CALENDAR_OPEN = 'CALENDAR_OPEN';
export const PROGRAMS_FETCH_IN_PROGRESS = 'PROGRAMS_FETCH_IN_PROGRESS';
export const PROGRAMS_FETCH_SUCCESS = 'PROGRAMS_FETCH_SUCCESS';
export const PROGRAMS_FETCH_ERROR = 'PROGRAMS_FETCH_ERROR';
export const PROGRAM_ADD_IN_PROGRESS = 'PROGRAM_ADD_IN_PROGRESS';
export const PROGRAM_ADD_SUCCESS = 'PROGRAM_ADD_SUCCESS';
export const PROGRAM_ADD_ERROR = 'PROGRAM_ADD_ERROR';
export const PROGRAM_UPDATE_IN_PROGRESS = 'PROGRAM_UPDATE_IN_PROGRESS';
export const PROGRAM_UPDATE_SUCCESS = 'PROGRAM_UPDATE_SUCCESS';
export const PROGRAM_UPDATE_ERROR = 'PROGRAM_UPDATE_ERROR';
export const PROGRAM_DELETE_IN_PROGRESS = 'PROGRAM_DELETE_IN_PROGRESS';
export const PROGRAM_DELETE_SUCCESS = 'PROGRAM_DELETE_SUCCESS';
export const PROGRAM_DELETE_ERROR = 'PROGRAM_DELETE_ERROR';
export const PROGRAM_OVERLAY_ADD_IN_PROGRESS = 'PROGRAM_OVERLAY_ADD_IN_PROGRESS';
export const PROGRAM_OVERLAY_ADD_SUCCESS = 'PROGRAM_OVERLAY_ADD_SUCCESS';
export const PROGRAM_OVERLAY_ADD_ERROR = 'PROGRAM_OVERLAY_ADD_ERROR';
export const PROGRAM_OVERLAY_UPDATE_IN_PROGRESS = 'PROGRAM_OVERLAY_UPDATE_IN_PROGRESS';
export const PROGRAM_OVERLAY_UPDATE_SUCCESS = 'PROGRAM_OVERLAY_UPDATE_SUCCESS';
export const PROGRAM_OVERLAY_UPDATE_ERROR = 'PROGRAM_OVERLAY_UPDATE_ERROR';
export const PROGRAM_OVERLAY_DELETE_IN_PROGRESS = 'PROGRAM_OVERLAY_DELETE_IN_PROGRESS';
export const PROGRAM_OVERLAY_DELETE_SUCCESS = 'PROGRAM_OVERLAY_DELETE_SUCCESS';
export const PROGRAM_OVERLAY_DELETE_ERROR = 'PROGRAM_OVERLAY_DELETE_ERROR';
export const PLAY_OUT_FETCH_IN_PROGRESS = 'PLAY_OUT_FETCH_IN_PROGRESS';
export const PLAY_OUT_FETCH_SUCCESS = 'PLAY_OUT_FETCH_SUCCESS';
export const PLAY_OUT_FETCH_ERROR = 'PLAY_OUT_FETCH_ERROR';

export const PROGRAMS_UPDATE_IN_PROGRESS = 'PROGRAMS_UPDATE_IN_PROGRESS';
export const PROGRAMS_UPDATE_SUCCESS = 'PROGRAMS_UPDATE_SUCCESS';
export const PROGRAMS_UPDATE_ERROR = 'PROGRAMS_UPDATE_ERROR';

export const PROGRAMS_FILL_IN_PROGRESS = 'PROGRAMS_FILL_IN_PROGRESS';
export const PROGRAMS_FILL_SUCCESS = 'PROGRAMS_FILL_SUCCESS';
export const PROGRAMS_FILL_ERROR = 'PROGRAMS_FILL_ERROR';

export const DIALOG_UPDATE_PROGRAM_SHOW = 'DIALOG_UPDATE_PROGRAM_SHOW';
export const DIALOG_UPDATE_PROGRAM_HIDE = 'DIALOG_UPDATE_PROGRAM_HIDE';

export const ADD_DAY_TYPE_SET = 'ADD_DAY_TYPE_SET';

export const VIDEO_PLAYER_TYPE_SET = 'VIDEO_PLAYER_TYPE_SET';

export const PLAY_OUT_REFRESH_SUCCESS = 'PLAY_OUT_REFRESH_SUCCESS';
export const PLAY_OUT_REFRESH_ERROR = 'PLAY_OUT_REFRESH_ERROR';

export const CURRENT_UPDATING_SLOT_SET = 'CURRENT_UPDATING_SLOT_SET';
export const PROGRAMS_UPDATE_SET = 'PROGRAMS_UPDATE_SET';

export const PROGRAMS_CLEAR_IN_PROGRESS = 'PROGRAMS_CLEAR_IN_PROGRESS';
export const PROGRAMS_CLEAR_SUCCESS = 'PROGRAMS_CLEAR_SUCCESS';
export const PROGRAMS_CLEAR_ERROR = 'PROGRAMS_CLEAR_ERROR';
