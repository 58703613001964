import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
    assetCategoryInfo,
    deleteAssetCategory,
    deleteGenre,
    fetchGenres,
    hideDialogConfirmGenre,
} from '../../../actions/asset';
// import './index.css';

const DialogConfirm = (props) => {
    const token = localStorage.getItem('authorizationToken');
    const onClick = (id) => {
        props.deleteGenre(id, token, (cb) => {
            if (cb) {
                return;
            }

            const token = localStorage.getItem('authorizationToken');
            if (token) {
                props.fetchGenres(token);
            }
        });
    };

    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            className="dialog_confirm"
            open={props.open}
            onClose={props.onClickCancel}>
            <DialogTitle
                className="title"
                id="alert-dialog-title">
                <Typography className="text-16 mb-8 font-600">
                    Are your sure, you want to delete ?
                </Typography>
            </DialogTitle>
            <DialogContent className="content">
                <Button
                    aria-label="Delete"
                    className="delete"
                    onClick={() => onClick(props.id)}>
                    Delete
                </Button>
                <Button
                    className="cancel"
                    onClick={props.onClickCancel}>
                    Cancel
                </Button>
            </DialogContent>
        </Dialog>
    );
};

DialogConfirm.propTypes = {
    assetCategoryInfo: PropTypes.func.isRequired,
    deleteAssetCategory: PropTypes.func.isRequired,
    deleteGenre: PropTypes.func.isRequired,
    fetchGenres: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClickCancel: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.asset.dialogConfirm,
        id: state.asset.dialogConfirmGenreId,
    };
};

const actionsToProps = {
    onClickCancel: hideDialogConfirmGenre,
    deleteAssetCategory: deleteAssetCategory,
    assetCategoryInfo: assetCategoryInfo,
    fetchGenres: fetchGenres,
    deleteGenre: deleteGenre,
};

export default connect(stateToProps, actionsToProps)(DialogConfirm);
