import { Button } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setOptions } from '../../../actions/interactiveVideo';

const AddOptionButton = (props) => {
    const handleClick = () => {
        const option = 'option' + (Object.keys(props.options).length + 1);
        const obj = {
            ...props.options,
            [option]: '',
        };

        props.onChange(obj);
    };

    return (
        <Button
            className="filled_button"
            onClick={handleClick}>
            Add Option
        </Button>
    );
};

AddOptionButton.propTypes = {
    options: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        options: state.interactiveVideo.options.options,
    };
};

const actionToProps = {
    onChange: setOptions,
};

export default connect(stateToProps, actionToProps)(AddOptionButton);
