import * as PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { showPeoplesDialog } from '../../../../actions/asset';
import { addPeople, fetchPeople, setProfession } from '../../../../actions/people';
import { arrayUnique } from '../../../../utils/arrayEquality';

class DialogAddPeople extends Component {
    componentDidUpdate (pp, ps, ss) {
        if ((pp.newLabel !== this.props.newLabel) && (this.props.newLabel !== '')) {
            this.addNewLabel();
        }
    }

    addNewLabel () {
        const data = {
            name: this.props.newLabel,
            professions: this.props.professions,
        };

        if (this.props.professions && this.props.professions.length > 0) {
            this.props.addPeople(data, (error) => {
                if (!error) {
                    this.props.fetchPeople((cb) => {
                        if (cb && cb.length) {
                            const value = cb.filter((value) => value.name === this.props.newLabel);
                            if (value && value.length) {
                                const array = arrayUnique(this.props.list.concat(value));
                                this.props.func(array);
                            }

                            this.props.onChange('');
                            this.props.setProfession([]);
                        }
                    });
                }
            });
        }
    }

    render () {
        return null;
    }
}

DialogAddPeople.propTypes = {
    addPeople: PropTypes.func.isRequired,
    fetchPeople: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    newLabel: PropTypes.string.isRequired,
    professions: PropTypes.array.isRequired,
    setProfession: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    func: PropTypes.func,
};

const stateToProps = (state) => {
    return {
        newLabel: state.asset.new.peoplesDialog.value,
        professions: state.asset.new.profession,
        func: state.asset.new.peoplesDialog.func,
        list: state.asset.new.peoplesDialog.list,
    };
};

const actionsToProps = {
    onChange: showPeoplesDialog,
    addPeople,
    fetchPeople,
    setProfession,
};

export default connect(stateToProps, actionsToProps)(DialogAddPeople);
