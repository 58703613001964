import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import ClassNames from 'classnames';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setOptions } from '../../../actions/interactiveVideo';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#696969',
                borderWidth: '1px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
            },
        },
        '& .MuiFormHelperText-root': {
            '&.Mui-error': {
                width: '100%',
                margin: '-6px 0',
            },
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            backgroundColor: 'red !important',
        },
    },
}));

const GoToTextField = (props) => {
    const [error, setError] = useState(false);

    const onChange = (value) => {
        const option = props.index;
        const hr = value.getHours();
        const min = value.getMinutes();
        const sec = value.getSeconds();
        const goto = moment.duration(`${hr}:${min}:${sec}`).asMilliseconds();

        const validationDate = moment().hours(0).minutes(0).seconds(props.player.duration());

        const diff = validationDate.diff(value);

        if (diff < 0) {
            setError(true);
            setTimeout(handleValidation, 5000);

            return;
        } else if (error) {
            handleValidation();
        }

        const obj = {
            ...props.options,
            [option]: {
                ...props.options[option],
                value: goto,
            },
        };

        props.onChange(obj);
    };

    const handleValidation = () => {
        setError(false);
    };

    const value = props.value
        ? moment().hours(0).minutes(0).seconds(0).milliseconds(props.value)
        : null;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
                ampm={false}
                className={ClassNames(useStyles().root, 'time_picker text_field')}
                format="HH:mm:ss"
                initialFocusedDate={moment().hours(0).minutes(0).seconds(0)}
                inputVariant="outlined"
                maxDateMessage="Select Time Range is Greater than video duration"
                openTo="hours"
                placeholder="GoTo Value"
                value={value}
                views={['hours', 'minutes', 'seconds']}
                onChange={onChange}/>
            {error && <p className="error_message">Selected Time Range is Greater than video duration</p>}
        </MuiPickersUtilsProvider>
    );
};

GoToTextField.propTypes = {
    index: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        options: state.interactiveVideo.options.options,
        player: state.interactiveVideo.player,
    };
};

const actionToProps = {
    onChange: setOptions,
};

export default connect(stateToProps, actionToProps)(GoToTextField);
