import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';

const OverviewCards = (props) => {
    const handleColumns = () => {
        const array = [];
        for (let i = 0; i < props.columns; i++) {
            array.push(<div key={i} className="text"/>);
        }

        return array;
    };

    return (
        <div className="overview_loading_card table_loader">
            <div className="row">
                {handleColumns()}
            </div>
            <div className="row">
                {handleColumns()}
            </div>
        </div>
    );
};

OverviewCards.propTypes = {
    columns: PropTypes.number.isRequired,
};

export default OverviewCards;
