import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setBugsSlotData, setUpdatedBugsList } from '../../../../../../actions/bugs';
import SelectField from '../../../../../../components/SelectField';

const options = [{
    value: 'none',
    name: 'None',
}, {
    value: 'Top Left',
    name: 'Top Left',
}, {
    value: 'Top Right',
    name: 'Top Right',
}, {
    value: 'Bottom Left',
    name: 'Bottom Left',
}, {
    value: 'Bottom Right',
    name: 'Bottom Right',
}];

const PositionSelectField = (props) => {
    const onChange = (value) => {
        const arr = props.data;
        arr[props.index].position = value;
        if (props.updatedBugs.length > 0) {
            const indArray = props.updatedBugs;
            const alreadyExit = props.updatedBugs.filter((val) => val === props.index);
            if (alreadyExit.length === 0) {
                indArray.push(props.index);
            }
            props.setUpdatedBugsList([...indArray]);
        } else {
            const indArray = [];
            indArray.push(props.index);
            props.setUpdatedBugsList([...indArray]);
        }
        props.setBugsSlotData([...arr]);
    };

    const disable = props.data[props.index].frequency === 0;

    return (
        <SelectField
            disabled={disable}
            id={'position_select_field' + props.index}
            items={options}
            name="position"
            value={props.value}
            onChange={onChange}/>
    );
};

PositionSelectField.propTypes = {
    data: PropTypes.array.isRequired,
    disabled: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    setBugsSlotData: PropTypes.func.isRequired,
    setUpdatedBugsList: PropTypes.func.isRequired,
    updatedBugs: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        data: state.live.bugs.bugs.list,
        updatedBugs: state.live.bugs.updatedBugs.list,
    };
};

const actionToProps = {
    setBugsSlotData,
    setUpdatedBugsList,
};

export default connect(stateToProps, actionToProps)(PositionSelectField);
