import { Dialog, DialogContent, DialogTitle, IconButton, Input, List, ListItem, Typography } from '@material-ui/core';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
    addNewGenre,
    fetchGenres,
    hideDialogAddGenre,
    setGenreNewLabel,
    showDialogConfirmGenre,
    updateGenre,
} from '../../../actions/asset';
import Icon from '../../../components/Icon';
import DialogConfirm from '../DailogConfirmGenre';

const DialogAddAssetCategory = (props) => {
    const token = localStorage.getItem('authorizationToken');
    const onChange = (event) => {
        props.addNewLabel(event.target.value);
    };
    const addNewLabel = () => {
        props.addNewGenre(props.newLabel, token, (cb) => {
            if (cb) {
                return;
            }

            const token = localStorage.getItem('authorizationToken');
            if (token) {
                props.fetchGenres(token);
            }
        });
    };

    const handleDeleteLabel = (id) => {
        props.dialogConfirm(id);
        // props.deleteAssetCategory(id, token, (cb) => {
        //     if (cb) {
        //         return;
        //     }
        //
        //     const token = localStorage.getItem('authorizationToken');
        //     if (token) {
        //         props.assetCategoryInfo(token);
        //     }
        // });
    };

    const handleLabelChange = (event, id) => {
        props.updateGenre(event.target.value, id, token, (cb) => {
            if (cb) {
                return;
            }
            const token = localStorage.getItem('authorizationToken');
            if (token) {
                props.fetchGenres(token);
            }
        });
    };

    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            className="dialog_add_asset_category"
            open={props.open}
            onClose={props.onClickCancel}>
            <DialogTitle
                className="title"
                id="alert-dialog-title">
                <Typography className="text-16 mb-8 font-600">
                    Add New Genre
                </Typography>
            </DialogTitle>
            <DialogContent className="content">
                <List dense>
                    <ListItem
                        dense
                        className="p-0 mb-16">
                        <Icon
                            className="add add_svg"
                            icon="add"/>
                        <Input
                            className={classNames('flex flex-1 mx-8', 'text_field')}
                            name="name"
                            placeholder="Create new label"
                            value={props.newLabel}
                            onChange={onChange}
                        />
                        <IconButton
                            aria-label="Delete"
                            className={classNames('w-32 h-32 mx-4 p-0', 'success_button')}
                            disabled={props.newLabel === ''}
                            onClick={addNewLabel}>
                            <Icon
                                className="success success_svg"
                                icon="success"/>
                        </IconButton>
                    </ListItem>
                    {
                        props.genres ? props.genres.map((label) => (
                            <ListItem
                                key={label._id}
                                dense
                                className="p-0">
                                <Icon
                                    className="label label_svg"
                                    icon="label"/>
                                {
                                    typeof label === 'object'
                                        ? <Input
                                            disableUnderline
                                            className={classNames('flex flex-1 mx-8', 'text_field')}
                                            name="name"
                                            value={label.name}
                                            onChange={(event) => handleLabelChange(event, label.value)}
                                        />
                                        : <Input
                                            disableUnderline
                                            className={classNames('flex flex-1 mx-8', 'text_field')}
                                            name="name"
                                            value={label.name}
                                            onChange={(event) => handleLabelChange(event, label.value)}
                                        />
                                }
                                <IconButton
                                    aria-label="Delete"
                                    className="w-32 h-32 mx-4 p-0"
                                    onClick={() => handleDeleteLabel(label.value)}>
                                    <Icon
                                        className="delete"
                                        icon="delete"/>
                                </IconButton>
                                <DialogConfirm/>
                            </ListItem>
                        )) : {}
                    }
                </List>
            </DialogContent>
        </Dialog>
    );
};
DialogAddAssetCategory.propTypes = {
    addNewGenre: PropTypes.func.isRequired,
    addNewLabel: PropTypes.func.isRequired,
    dialogConfirm: PropTypes.func.isRequired,
    fetchGenres: PropTypes.func.isRequired,
    genres: PropTypes.array.isRequired,
    newLabel: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    updateGenre: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    addNewFolder: PropTypes.func,
};

const stateToProps = (state) => {
    return {
        open: state.asset.dialogAddGenre,
        newLabel: state.asset.newGenre,
        genres: state.asset.genres.list,
    };
};

const actionsToProps = {
    onClickCancel: hideDialogAddGenre,
    fetchGenres: fetchGenres,
    addNewLabel: setGenreNewLabel,
    addNewGenre: addNewGenre,
    updateGenre: updateGenre,
    dialogConfirm: showDialogConfirmGenre,
};

export default connect(stateToProps, actionsToProps)(DialogAddAssetCategory);
