import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleSidebarDrawer } from '../../actions/sidebar';
import DialogAddGenre from '../asset/DialogAddGenre';
import AddPeople from '../AddAssets/Tabs/MetadataForDistribution/AddPeople';
import DialogAddAssetCategory from '../Sidebar/DialogAddAssetCategory';
import BreadCrumb from './BreadCrumb';
import ChapterDetailsCards from './ChapterDetailsCards';
import ContentSideBar from './ContentSideBar';
import EditPanel from './EditPanel';
import './index.css';
import MainSideBar from './MainSideBar';
import SearchBarTextField from './SearchBarTextField';

class ChapterMarking extends Component {
    componentDidMount () {
        if (this.props.open) {
            this.props.toggleSidebarDrawer();
        }
    }

    render () {
        return (
            <div className="chapter_marking">
                <div className="heading_bar">
                    <BreadCrumb/>
                    <SearchBarTextField/>
                </div>
                <div className="page_content">
                    <MainSideBar/>
                    <ContentSideBar/>
                    <ChapterDetailsCards/>
                    <EditPanel/>
                    <AddPeople/>
                    <DialogAddGenre/>
                    <DialogAddAssetCategory/>
                </div>
            </div>
        );
    }
}

ChapterMarking.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleSidebarDrawer: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.sidebar.drawer,
    };
};

const actionToProps = {
    toggleSidebarDrawer,
};

export default connect(stateToProps, actionToProps)(ChapterMarking);
