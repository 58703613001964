import { Button, MenuItem } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getAccessToken } from '../../actions/login';
import logo from '../../assets/logo.svg';
import Icon from '../../components/Icon';
import './index.css';
import RecoveryEmailTextField from './RecoveryEmailTextField';

const ForgotPassword = (props) => {
    const characterCode = (event) => {
        if (event.keyCode === 13) {
            onClick();
        }
    };

    const onClick = () => {
        props.onClick(props.email, (cb) => {
            if (cb) {
                return;
            }

            const token = localStorage.getItem('authorizationToken');
            if (token) {
                props.history.push('/assets');
            } else {
                props.history.push('/login');
            }
        });
    };

    const disable = props.email === '';

    return (
        <div className="forgetPassword">
            <div className="forgetPassword_logo_section">
                <img alt="logo" src={logo}/>
                <div className="cross_line"/>
                <div className="bottom_line"/>
            </div>
            <div className="card_div">
                <div className="card_forgetPassword">
                    <form onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            onClick(ev);
                        }
                    }}>
                        <div className="fields_section">
                            <RecoveryEmailTextField/>
                        </div>
                        <div className="forgetPassword_action">
                            <Button
                                className={disable ? 'action_button disabled_button' : 'action_button'}
                                color="primary"
                                disabled={disable}
                                // onClick={onClick}
                                onKeyDown={(event) => characterCode(event)}>
                                Submit
                            </Button>
                        </div>
                        <div className="forgotpassword">
                            <div>
                                <MenuItem className="link_url" component={Link} to="/login">
                                    <Icon
                                        className="signup"
                                        icon="signup"/>Back to Login
                                </MenuItem>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

ForgotPassword.propTypes = {
    email: PropTypes.object.isRequired,
    getAccessToken: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        email: state.forgotPassword.email,
    };
};

const actionsToProps = {
    getAccessToken: getAccessToken,
};

export default withRouter(connect(stateToProps, actionsToProps)(ForgotPassword));
