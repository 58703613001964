import { InputAdornment, IconButton } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { setAddAssetName } from '../../actions/asset';
import TextField from '../../components/TextField/InputProps';
import { ReactComponent as Edit } from '../../assets/edit.svg';
import DoneIcon from '@material-ui/icons/Done';
import ClassName from 'classnames';

const AssetTitleTextField = (props) => {
    const ref = useRef(null);

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (edit) {
            ref.current && ref.current.focus();
        }
    });

    const handleClick = () => {
        setEdit(!edit);
    };

    return (
        props.value &&
        <TextField
            autoFocus={!!edit}
            className="asset_title"
            disable={!edit}
            id="asset-title-text_field"
            inputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            className={ClassName('icon_button', edit ? 'edit' : '')}
                            onClick={handleClick}>
                            {edit
                                ? <DoneIcon/>
                                : <Edit/>}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            inputRef={ref}
            name="assetTitle"
            placeholder="Asset Title*"
            required={true}
            type="textarea"
            value={props.value}
            onChange={props.onChange}/>
    );
};

AssetTitleTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.name,
    };
};

const actionsToProps = {
    onChange: setAddAssetName,
};

export default connect(stateToProps, actionsToProps)(AssetTitleTextField);
