import { Button, Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSlotTemplate, setSelectedSlots, setSlotTemplates, showDeleteTemplateDialog } from '../../../../../actions/dynamicPrograms';
import Icon from '../../../../../components/Icon';
import NoData from '../../../../../components/NoData';
import NameTextField from '../../../../asset/DialogAddAsset/NameTextField';
import DeleteDialog from './DeleteDialog';
import './index.css';

class Templates extends Component {
    constructor (props) {
        super(props);
        this.state = {
            slotPopup: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        const token = localStorage.getItem('authorizationToken');

        if (this.props.templates && this.props.templates.length === 0) {
            this.props.fetchSlotTemplate(token);
        }
    }

    handleChange (event, name, item) {
        const obj = this.props.selectedList;
        obj[name] = event.target.checked;
        this.props.setSelectedSlots(obj);

        if (event.target.checked) {
            const array = [...this.props.checked];
            array.push(item);
            this.props.setSlotTemplates([...array]);
        } else {
            this.props.checked.map((value, index) => {
                if (value._id === item._id) {
                    const array = [...this.props.checked];
                    array.splice(index, 1);
                    this.props.setSlotTemplates([...array]);
                }
            });
        }
    }

    render () {
        return (
            <div className="templates_data">
                <NameTextField label="Search Template"/>
                <div className="scroll_bar">
                    {this.props.inProgress
                        ? <CircularProgress/>
                        : this.props.templates && this.props.templates.length > 0
                            ? this.props.templates.map((item, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        className={this.props.selectedList && this.props.selectedList['check' + index]
                                            ? 'active_label' : ''}
                                        control={
                                            <Checkbox
                                                checked={this.props.selectedList && this.props.selectedList['check' + index]}
                                                value={this.props.selectedList && this.props.selectedList['check' + index]}
                                                onChange={(e) => this.handleChange(e, 'check' + index, item)}
                                            />
                                        }
                                        label={
                                            <div className="template_row">
                                                <div className="header">
                                                    <div className="left_content">
                                                        <Icon
                                                            className="audio"
                                                            icon="audio"/>
                                                        <p>{item.name}</p>
                                                    </div>
                                                    <div className="right_content">
                                                        {item.duration
                                                            ? <p className="time">
                                                                {moment.utc(moment.duration(item.duration, 'seconds')
                                                                    .asMilliseconds()).format('HH:mm:ss')}</p>
                                                            : null}
                                                        <Button
                                                            className="add_slot"
                                                            onClick={() => this.props.showDialog(item)}>
                                                            Delete
                                                        </Button>
                                                        <div className="add_slot">
                                                            {this.props.selectedList && this.props.selectedList['check' + index]
                                                                ? <div>
                                                                    <Icon
                                                                        className="check_mark"
                                                                        icon="check_mark"/>
                                                                    Added
                                                                </div>
                                                                : 'Add Template To Slot'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <p className="side_heading">Categories</p>
                                                    <div className="content">
                                                        {item.categories && item.categories.length > 0 &&
                                                        item.categories.map((value, ind) => {
                                                            return (
                                                                <p key={ind}>{value.name}</p>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <p className="side_heading">Include</p>
                                                    <div className="content">
                                                        {item.include_tags && item.include_tags.length > 0 &&
                                                        item.include_tags.map((value, ind) => {
                                                            return (
                                                                <p key={ind}>{value}</p>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    />);
                            })
                            : <NoData/>
                    }
                </div>
                <DeleteDialog/>
            </div>
        );
    }
}

Templates.propTypes = {
    checked: PropTypes.array.isRequired,
    fetchSlotTemplate: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    selectedList: PropTypes.object.isRequired,
    setSelectedSlots: PropTypes.func.isRequired,
    setSlotTemplates: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    templates: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
    return {
        checked: state.live.dynamic.slotTemplates.checked,
        inProgress: state.live.dynamic.slotTemplates.inProgress,
        selectedList: state.live.dynamic.slotTemplates.selected,
        templates: state.live.dynamic.slotTemplates.list,
    };
};

const actionToProps = {
    fetchSlotTemplate,
    setSlotTemplates,
    setSelectedSlots,
    showDialog: showDeleteTemplateDialog,
};

export default connect(stateToProps, actionToProps)(Templates);
