import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { deleteAccount, fetchAccounts } from '../../../actions/account';
import CopyButtonIcon from '../../../components/CopyButton/CopyButtonIcon';

const AddressCard = (props) => {
    const handleDelete = () => {
        props.deleteAccount(props.account._id, (error) => {
            if (error) return;
            props.fetchAccounts();
        });
    };

    return (
        <div className="card address_card">
            <Button className="active_button">{props.account.status}</Button>
            <div className="info">
                <p className="heading">Address</p>
                <div className="row">
                    <div className="hash_text" title={props.account.address}>
                        <p className="ellipses_text">{props.account.address}</p>
                        {props.account.address && props.account.address.slice(props.account.address.length - 8, props.account.address.length)}
                    </div>
                    <CopyButtonIcon data={props.account.address}/>
                </div>
            </div>
            <Button className="action_button1" onClick={handleDelete}>Delete</Button>
        </div>
    );
};

AddressCard.propTypes = {
    deleteAccount: PropTypes.func.isRequired,
    fetchAccounts: PropTypes.func.isRequired,
    account: PropTypes.shape({
        address: PropTypes.string,
        isPrimary: PropTypes.string,
        status: PropTypes.string,
        _id: PropTypes.string,
    }),
};

const actionsToProps = {
    deleteAccount,
    fetchAccounts,
};

export default connect(null, actionsToProps)(AddressCard);
