import { AppBar, Button, Modal, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideBugDialog, setTabValue } from '../../../../../actions/bugs';
import AddButton from './AddButton';
import AllBugsTable from './AllBugsTable';
import Table from './BugsInSlotTable';
import './index.css';
import UpdateButton from './UpdateButton';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
}));

const BugsDialog = (props) => {
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    return (
        <Modal
            aria-describedby="simple-modal-description"
            aria-labelledby="simple-modal-title"
            className="stream_modal bugs_dialog"
            open={props.open}
            onClose={props.hideBugDialog}>
            <div
                className={classes.paper}
                style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                <div className="header header_tab">
                    <div className={classes.root}>
                        <AppBar
                            className="modal_appbar"
                            position="static">
                            <Tabs
                                scrollButtons="auto"
                                value={props.value}
                                variant="scrollable"
                                onChange={handleChange}>
                                <Tab
                                    className={
                                        props.value === 0
                                            ? 'active_tab'
                                            : 'default_tab sarabun_font_weight'
                                    }
                                    label="Bugs in Slot"
                                />
                                <Tab
                                    className={
                                        props.value === 1
                                            ? 'active_tab'
                                            : 'default_tab sarabun_font_weight'
                                    }
                                    label="All Bugs"
                                />
                            </Tabs>
                        </AppBar>
                    </div>
                </div>
                <div className="content">
                    {props.value === 0 && <div className="data_table table_modal table_scroll_bar">
                        <Table
                            data={props.slotBugs}
                            inProgress={props.slotBugsInProgress}/>
                    </div>}
                    {props.value === 1 && <div className="data_table table_modal all_bugs_table table_scroll_bar">
                        <AllBugsTable/>
                    </div>}
                    <div className="footer sarabun_font_weight">
                        <div>
                            <Button
                                className="close_adding"
                                onClick={props.hideBugDialog}>
                                Close
                            </Button>
                            {props.updatedBugs && props.updatedBugs.length > 0
                                ? <UpdateButton/>
                                : <AddButton/>}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

BugsDialog.propTypes = {
    hideBugDialog: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setTabValue: PropTypes.func.isRequired,
    slotBugs: PropTypes.array.isRequired,
    slotBugsInProgress: PropTypes.bool.isRequired,
    updatedBugs: PropTypes.array.isRequired,
    value: PropTypes.number.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.live.bugs.dialog,
        value: state.live.bugs.tabValue,
        slotBugs: state.live.bugs.bugs.list,
        slotBugsInProgress: state.live.bugs.bugs.inProgress,
        updatedBugs: state.live.bugs.updatedBugs.list,
    };
};

const actionToProps = {
    hideBugDialog,
    setTabValue,
};

export default connect(stateToProps, actionToProps)(BugsDialog);
