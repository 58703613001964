import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assetCategoryInfo, showDialogAddAssetsCategory } from '../../../actions/asset';
import { setCategory } from '../../../actions/chapterMarking';
import AutoCompleteTextField from '../../../components/AutoCompleteTextField/WithAddButton';

class CategorySelectField extends Component {
    constructor (props) {
        super(props);
        this.fetch = this.fetch.bind(this);
    }

    componentDidMount () {
        if (this.props.defaultValue && this.props.defaultValue.length > 0) {
            this.props.onChange([...this.props.defaultValue]);
        }

        if (this.props.categories.length) {
            return;
        }

        this.fetch();
    }

    fetch () {
        if (this.props.inProgress) {
            return;
        }

        const token = localStorage.getItem('authorizationToken');

        if (token) {
            this.props.fetch(token);
        }
    }

    render () {
        return (
            <AutoCompleteTextField
                options={this.props.categories}
                showDialog={this.props.dialog}
                value={this.props.value}
                onChange={this.props.onChange}/>
        );
    }
}

CategorySelectField.propTypes = {
    categories: PropTypes.array.isRequired,
    dialog: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.array,
};

const stateToProps = (state) => {
    return {
        value: state.chapterMarking.new.category,
        categories: state.asset.assetCategoriesList.list,
        inProgress: state.asset.new.categories.inProgress,
    };
};

const actionsToProps = {
    dialog: showDialogAddAssetsCategory,
    fetch: assetCategoryInfo,
    onChange: setCategory,
};

export default connect(stateToProps, actionsToProps)(CategorySelectField);
