import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setDirector, showPeoplesDialog } from '../../../../../actions/asset';
import AutoCompleteTextField from '../../../../../components/AutoCompleteTextField/WithAddButton';

const DirectorSelectField = (props) => {
    const showDialog = (value) => {
        props.dialog(value, 'director', [...props.list], props.onChange);
    };

    return (
        <AutoCompleteTextField
            label="Director"
            options={props.options}
            showDialog={showDialog}
            value={props.list}
            onChange={props.onChange}/>
    );
};

DirectorSelectField.propTypes = {
    dialog: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        list: state.asset.new.movies.director,
        options: state.asset.new.peopleList.list,
    };
};

const actionToProps = {
    onChange: setDirector,
    dialog: showPeoplesDialog,
};

export default connect(stateToProps, actionToProps)(DirectorSelectField);
