import { Button, IconButton } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import VideoJS from 'video.js';
import 'video.js/dist/video-js.css';
import { fetchAsset, fetchScenes, getPreviewUrl } from '../../../actions/asset';
import {
    setChapterRangeValues,
    setMaxValue,
    setMinValue,
    addAssetChapter,
    fetchAssetChapters,
    chapterDetailsTab,
} from '../../../actions/chapterMarking';
import pause from '../../../assets/chapterMarking/pause.png';
import play from '../../../assets/chapterMarking/play.png';
import { ReactComponent as VolumeOff } from '../../../assets/chapterMarking/volume_off.svg';
import { ReactComponent as VolumeOn } from '../../../assets/chapterMarking/volume_on.svg';
import Player from '../../../components/Player';
import Slider from '../Slider';
import './index.css';
import { fetchDeviceID, fetchWatchURL } from '../../../actions/videoAssets';
import ManualSlider from '../Slider/ManualSlider';

const videoJSOptions = {
    autoplay: true,
    loop: false,
    width: 500,
    height: 600,
    controls: false,
    controlBar: {
        children: [
            'playToggle',
            'muteToggle',
            'volumeControl',
        ],
    },
};

class Preview extends React.Component {
    constructor (props) {
        super(props);
        this.initializePlayer = this.initializePlayer.bind(this);
        this.showChapter = this.showChapter.bind(this);
        this.renderChapters = this.renderChapters.bind(this);
        this.makeChapter = this.makeChapter.bind(this);
        this.togglePause = this.togglePause.bind(this);
        this.getScenes = this.getScenes.bind(this);
        this.toggleVolume = this.toggleVolume.bind(this);
        this.state = {
            chapters: [],
            selectedChapterIndex: 0,
            isPlaying: false,
            marks: [],
            marksAsSteps: [],
            volume: true,
        };
    }

    initializePlayer (ref) {
        this.player = VideoJS(ref, videoJSOptions);

        if (this.player) {
            this.player.on('loadeddata', () => {
                this.setState({
                    isPlaying: true,
                });

                this.renderChapters();
            });

            this.player.on('seeked', () => {
                const chapters = this.state.chapters;
                const selectedChapterIndex = chapters.findIndex((item, index, array) =>
                    item < this.player.currentTime() && array[index + 1] > this.player.currentTime());
                this.setState({ selectedChapterIndex });
            });

            this.player.on('pause', () => {
                this.setState({ isPlaying: false });
            });

            this.player.on('play', () => {
                this.setState({ isPlaying: true });
            });
        }
    }

    renderChapters () {
        const array = [];
        const arrayAsSteps = [];

        this.props.chapters.forEach((item) => {
            if (item.start_at && !array.includes(item.start_at)) {
                array.push(item.start_at);
                arrayAsSteps.push({
                    value: item.start_at,
                });
            }
            if (item.end_at && !array.includes(item.end_at)) {
                array.push(item.end_at);
                arrayAsSteps.push({
                    value: item.end_at,
                });
            }
        });

        array.sort((a, b) => {
            return a - b;
        });

        this.setState({
            marks: [...array],
            marksAsSteps: [...arrayAsSteps],
        });
    }

    showChapter (element, left, width, index) {
        const divElement = document.createElement('div');

        divElement.id = `chapters_div-${index}`;
        divElement.style = `border-radius: 2px;
                background-color: #FAC01E;
                left: ${left}%;
                margin-left: 0px;
                position: absolute;
                top: 2px;
                width:  ${width}%;
                height: 10px;
                opacity:50%`;

        divElement.onclick = () => {
            this.setState({ selectedChapterIndex: index });
        };

        element.appendChild(divElement);
    }

    componentDidMount () {
        const id = this.props.match && this.props.match.params.id;

        if (id) {
            this.props.fetchAsset(id, localStorage.getItem('authorizationToken'), (error) => {
                if (error) {
                    return;
                }

                if (this.props.deviceID && Object.keys(this.props.deviceID).length) {
                    this.props.fetchWatchURL(id, this.props.deviceID, (cb) => {
                        if (cb) {
                            const url = cb.assetUrl;

                            if (url) {
                                this.props.getPreviewUrl(url, (format) => {
                                    const config = {
                                        sources: [{
                                            type: format === 'mp4'
                                                ? 'video/mp4'
                                                : 'application/x-mpegURL',
                                            src: format === 'mp4'
                                                ? url.replace('m3u8', 'mp4')
                                                : url,
                                        }],
                                    };

                                    this.setState({
                                        config: JSON.stringify(config),
                                        duration: this.props.selectedAsset.file && this.props.selectedAsset.file.length,
                                    });
                                });
                            }
                        }
                    });
                } else {
                    this.props.fetchDeviceID((deviceID) => {
                        if (deviceID) {
                            this.props.fetchWatchURL(id, deviceID, (cb) => {
                                if (cb) {
                                    const url = cb.assetUrl;

                                    if (url) {
                                        this.props.getPreviewUrl(url, (format) => {
                                            const config = {
                                                sources: [{
                                                    type: format === 'mp4'
                                                        ? 'video/mp4'
                                                        : 'application/x-mpegURL',
                                                    src: format === 'mp4'
                                                        ? url.replace('m3u8', 'mp4')
                                                        : url,
                                                }],
                                            };

                                            this.setState({
                                                config: JSON.stringify(config),
                                                duration: this.props.selectedAsset.file && this.props.selectedAsset.file.length,
                                            });
                                        });
                                    }
                                }
                            });
                        }
                    });
                }

                this.getScenes(id);
                this.getChapters(id);
                // making initial range values empty
                this.props.setChapterRangeValues([]);
            });
        }
    }

    getScenes (id) {
        this.props.fetchScenes(id, localStorage.getItem('authorizationToken'));
    }

    getChapters (id) {
        this.props.fetchAssetChapters(id, localStorage.getItem('authorizationToken'));
    }

    componentWillUnmount () {
        if (this.player) {
            this.player.dispose();
        }
    }

    makeChapter () {
        const array = this.state.marks;
        array.map((v, i) => {
            const chapter = document.getElementById(`chapters_div-${i}`);
            if (chapter) {
                return chapter.remove();
            }

            return null;
        });

        if (this.props.range.length && !array.includes(this.props.range[0]) && this.props.range[0] !== 0) {
            array.push(this.props.range[0]);
            this.props.setMinValue(this.props.range[0]);
        }

        // to handling 2 range values for chapter
        if (this.props.range.length &&
            !array.includes(this.props.range[1]) &&
            this.props.range[1] &&
            this.props.range[1] !== this.player.duration()) {
            array.push(this.props.range[1]);
            this.props.setMaxValue(this.props.range[1]);
        }

        this.setState({ marks: array.sort() });

        const startValue = this.props.range[0];
        const endValue = this.props.range[1];
        const selectedScenes = this.props.scenes.filter((item) => item.start_at >= startValue && item.start_at < endValue);

        this.props.addAssetChapter(this.props.selectedAsset && this.props.selectedAsset._id,
            { scenes: selectedScenes.map((item) => item._id) },
            localStorage.getItem('authorizationToken'),
            (error) => {
                if (!error) {
                    this.props.chapterDetailsTab(this.props.chapters.length - 1);
                    this.renderChapters();
                }
            });
    }

    togglePause () {
        if (this.player.paused()) {
            this.player.play();
            this.setState({ isPlaying: true });
        } else {
            this.player.pause();
            this.setState({ isPlaying: false });
        }
    }

    toggleVolume () {
        if (this.player.muted()) {
            this.player.muted(false);
            this.setState({
                volume: true,
            });
        } else {
            this.player.muted(true);
            this.setState({
                volume: false,
            });
        }
    }

    render () {
        const { config, isPlaying } = this.state;
        return (
            <div className="video-wrapper">
                {
                    config
                        ? <div className="player">
                            <span
                                className="background_layer"
                                onClick={this.togglePause}/>
                            <Button
                                className="player_button"
                                onClick={this.togglePause}>
                                {
                                    isPlaying
                                        ? <img alt="pause button" src={pause}/>
                                        : <img alt="play button" className="play_button" src={play}/>
                                }
                            </Button>
                            <IconButton
                                className="volume_button"
                                onClick={this.toggleVolume}>
                                {
                                    this.state.volume
                                        ? <VolumeOn/>
                                        : <VolumeOff/>
                                }
                            </IconButton>
                            <Player
                                initializePlayer={this.initializePlayer}
                                setup={config}/>
                        </div>
                        : null
                }
                <>
                    {this.props.sliderType === 'automatic'
                        ? <Slider
                            duration={this.state.duration}
                            marks={this.state.marks}
                            max={this.state.duration}
                            min={0}
                            scenes={this.props.scenes}
                            width={100}/>
                        : <ManualSlider
                            duration={this.state.duration}
                            marks={this.state.marksAsSteps}
                            max={this.state.duration}
                            min={0}
                            scenes={this.props.scenes}
                            width={100}/>}
                    <div className="mark_chapter">
                        <Button
                            disabled={!this.props.range.length}
                            onClick={this.makeChapter}>
                            Mark Chapter
                        </Button>
                    </div>
                </>
            </div>
        );
    }
}

Preview.propTypes = {
    addAssetChapter: PropTypes.func.isRequired,
    chapterDetailsTab: PropTypes.func.isRequired,
    chapters: PropTypes.arrayOf(
        PropTypes.shape(
            {
                start_at: PropTypes.number.isRequired,
                end_at: PropTypes.number.isRequired,
                duration: PropTypes.number.isRequired,
                scenes: PropTypes.array.isRequired,
            }),
    ).isRequired,
    chaptersInProgress: PropTypes.func.isRequired,
    deviceID: PropTypes.string.isRequired,
    deviceIDInProgress: PropTypes.bool.isRequired,
    fetchAsset: PropTypes.func.isRequired,
    fetchAssetChapters: PropTypes.func.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    fetchScenes: PropTypes.func.isRequired,
    fetchWatchURL: PropTypes.func.isRequired,
    getPreviewUrl: PropTypes.func.isRequired,
    match: PropTypes.shape(
        {
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    scenes: PropTypes.arrayOf(
        PropTypes.shape({
            start_at: PropTypes.string.isRequired,
        }),
    ).isRequired,
    selectedAsset: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        file: PropTypes.shape({
            view_link_m3u8: PropTypes.string.isRequired,
            length: PropTypes.number.isRequired,
        }),
        type: PropTypes.string,
    }).isRequired,
    setChapterRangeValues: PropTypes.func.isRequired,
    setMaxValue: PropTypes.func.isRequired,
    setMinValue: PropTypes.func.isRequired,
    sliderType: PropTypes.string.isRequired,
    urlLinkInProgress: PropTypes.bool.isRequired,
    range: PropTypes.array,
    url: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        range: state.chapterMarking.range,
        scenes: state.chapterMarking.scenes.list,
        selectedAsset: state.asset._.current,
        chapters: Object.values(state.chapterMarking.chapter.list),
        chaptersInProgress: state.chapterMarking.chapter.inProgress,
        deviceID: state.asset.videoAssets.deviceID.id,
        deviceIDProgress: state.asset.videoAssets.deviceID.inProgress,
        urlLinkInProgress: state.asset.videoAssets.playerLink.inProgress,
        sliderType: state.chapterMarking.sliderType,
    };
};

const actionsToProps = {
    chapterDetailsTab,
    getPreviewUrl,
    setChapterRangeValues,
    fetchScenes,
    fetchAsset,
    setMinValue,
    setMaxValue,
    addAssetChapter,
    fetchAssetChapters,
    fetchWatchURL,
    fetchDeviceID,
};

export default withRouter(connect(stateToProps, actionsToProps)(Preview));
