import { Button, Typography, Tooltip, withStyles } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showDialogAddAsset } from '../../../actions/asset';
import Icon from '../../../components/Icon';
import './index.css';

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#232323',
        color: '#fff',
        boxShadow: theme.shadows[1],
        margin: 'unset',
        padding: '0',
    },
}))(Tooltip);

const CreateButton = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onClick = (value) => {
        props.show(value);
        handleClose();
    };

    const id = open ? 'create-tooltip' : undefined;

    return (
        <div className="create_button">
            <CustomTooltip
                interactive
                id={id}
                open={open}
                title={<div className="tooltip_div">
                    <Typography className="tab">
                        <Button onClick={() => onClick('video')}>
                            Video Asset
                        </Button>
                    </Typography>
                    <Typography className="tab">
                        <Button onClick={() => onClick('audio')}>
                            Audio Asset
                        </Button>
                    </Typography>
                </div>}
                onClose={handleClose}
                onOpen={handleClick}>
                <Button>
                    <Icon
                        className="create"
                        icon="create"/>
                    <p className="hover_side_bar">Create</p>
                </Button>
            </CustomTooltip>
        </div>
    );
};

CreateButton.propTypes = {
    show: PropTypes.func.isRequired,
};

const actionToProps = {
    show: showDialogAddAsset,
};

export default withRouter(connect(null, actionToProps)(CreateButton));
