import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPeople } from '../../../../actions/people';
import GenreSelectField from '../../../asset/DialogAddAsset/GenreSelectField';
import DialogAddGenre from '../../../asset/DialogAddGenre';
import AddPeople from './AddPeople';
import ArtDirectorSelectField from './Movies/ArtDirectorSelectField';
import Cinematography from './Movies/Cinematography';
import DirectorSelectField from './Movies/DirectorSelectField';
import ProducerSelectField from './Movies/ProducerSelectField';
import ReleaseDate from './Movies/ReleaseDate';
// import StyleCostumeDesignerSelectField
//     from './Movies/StyleCostumeDesignerSelectField';
import AlbumSelectField from './Music/AlbumSelectField';
import ISRCCodeTextField from './Music/ISRCCodeTextField';
import LyricistsSelectField from './Music/LyricistsSelectField';
import MoodTextField from './Music/MoodTextField';
import MusicComposersSelectField from './Music/MusicComposersSelectField';
import MusicDirectorsSelectField from './Music/MusicDirectorsSelectField';
import RecordLabelTextField from './Music/RecordLabelTextField';
import SingersSelectField from './Music/SingersSelectField';
import TrackDurationTimePicker from './Music/TrackDurationTimePicker';
import TrackNameTextField from './Music/TrackNameTextField';
import TagsTextField from './TagsTextField';
import DescriptionTextField from '../../../ChapterMarking/EditPanel/DescriptionTextField';
import CastSelectField from './CastSelectField';
import ComputerGraphicsSelectField from './ComputerGraphics';
import CrewSelectField from './CrewSelectField';
import DialoguesSelectField from './DialoguesSelectField';
import './index.css';
import EditorSelectField from './EditorSelectField';
import StorySelectField from './StorySelectField';
import StylistSelectField from './StylistSelectField';

class MetadataForDistribution extends Component {
    componentDidMount () {
        if (this.props.peopleList.length && this.props.peopleListInProgress) {
            return;
        }

        this.props.fetchPeople();
    }

    render () {
        const type = this.props.assetCategoriesList && this.props.assetCategoriesList.length > 0 &&
            this.props.assetCategoriesList.filter((value) => value._id === this.props.assetCategory);

        return (
            <div className="meta_data">
                <p className="heading_text">Meta data for distribution</p>
                <div className="row">
                    <div className="column">
                        {type && type.length > 0 && type[0].name && type[0].name.includes('music')
                            ? <>
                                <TrackNameTextField/>
                                <AlbumSelectField/>
                                <ISRCCodeTextField/>
                                <RecordLabelTextField/>
                                <TrackDurationTimePicker/>
                            </>
                            : type && type.length > 0 && type[0].name &&
                            (type[0].name.includes('movie') ||
                                type[0].name.includes('bug') ||
                                type[0].name.includes('ads'))
                                ? <>
                                    <ReleaseDate/>
                                    <DirectorSelectField/>
                                    <ProducerSelectField/>
                                    <StorySelectField/>
                                    <Cinematography/>
                                    <MusicDirectorsSelectField/>
                                    <DialoguesSelectField/>
                                    <EditorSelectField/>
                                </>
                                : null}
                        <GenreSelectField/>
                        <TagsTextField/>
                    </div>
                    <div className="column">
                        <DescriptionTextField label="Description"/>
                        {type && type.length > 0 && type[0].name && type[0].name.includes('music')
                            ? <>
                                <MusicDirectorsSelectField/>
                                <MusicComposersSelectField/>
                                <SingersSelectField/>
                                <LyricistsSelectField/>
                                <MoodTextField/>
                            </>
                            : type && type.length > 0 && type[0].name &&
                            (type[0].name.includes('movie') ||
                                type[0].name.includes('bug') ||
                                type[0].name.includes('ads'))
                                ? <>
                                    <MusicComposersSelectField/>
                                    <SingersSelectField/>
                                    <LyricistsSelectField/>
                                    <CastSelectField/>
                                    <CrewSelectField/>
                                    <ArtDirectorSelectField/>
                                    <StylistSelectField/>
                                    <ComputerGraphicsSelectField/>
                                    {/* <StyleCostumeDesignerSelectField/> */}
                                </>
                                : null}
                    </div>
                </div>
                <DialogAddGenre/>
                <AddPeople/>
            </div>
        );
    }
}

MetadataForDistribution.propTypes = {
    assetCategoriesList: PropTypes.array.isRequired,
    assetCategory: PropTypes.string.isRequired,
    fetchPeople: PropTypes.func.isRequired,
    peopleList: PropTypes.array.isRequired,
    peopleListInProgress: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        assetCategory: state.asset.new.category,
        assetCategoriesList: state.asset.assetCategoriesList.list,
        peopleList: state.asset.new.peopleList.list,
        peopleListInProgress: state.asset.new.peopleList.inProgress,
    };
};

const actionToProps = {
    fetchPeople,
};

export default connect(stateToProps, actionToProps)(MetadataForDistribution);
