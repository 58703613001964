import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAddAssetName } from '../../../../../actions/asset';
import TextField from '../../../../../components/TextField';

const TrackNameTextField = (props) => {
    return (
        <TextField
            id="track_name_text_field"
            label="Track Name*"
            name="trackName"
            value={props.value}
            onChange={props.onChange}/>
    );
};

TrackNameTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.name,
    };
};

const actionsToProps = {
    onChange: setAddAssetName,
};

export default connect(stateToProps, actionsToProps)(TrackNameTextField);
