import { Button } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchPlayOut, refreshPlayOut } from '../../../actions/program';
import arrowIcon from '../../../assets/arrow.svg';
import CustomIcon from '../../../components/Icon';
import SkeletonLoader from '../../../components/SkeletonLoader/PlayOutList';

const list = (value, width) => {
    return (
        <div
            className="playlist_name sarabun_font_weight common_style"
            style={{ width: width ? '20px' : null }}
            title={value}>
            {value}
        </div>
    );
};

class ScheduledPlayOut extends React.Component {
    constructor (props) {
        super(props);
        this.fetchPlayOut = this.fetchPlayOut.bind(this);
        this.state = { fetching: true };
        this.playingNode = React.createRef();
    }

    componentDidMount () {
        this.fetchPlayOut();
    }

    componentDidUpdate () {
        const node = this.playingNode.current;

        if (node) {
            node.parentNode.scrollTop = node.offsetTop;
        }
    }

    fetchPlayOut () {
        this.props.fetchPlayOut(this.props.channel,
            this.props.stream,
            (cb) => {
                this.setState({
                    fetching: false,
                });
            },
        );
    }

    render () {
        return (
            <div className="planned_list">
                <div className="header">
                    <div className="header_left">
                        <CustomIcon
                            className="planned_playlist"
                            icon="planned_playlist"/>
                        <p className="sarabun_font_weight">
                            Play out
                        </p>
                    </div>
                    <div className="scheduled_header_right">
                        <Button
                            className="refresh_button"
                            disabled={!(this.props.playOutStream.playout &&
                                Object.keys(this.props.playOutStream.playout).length)}
                            onClick={() => this.props.refresh()}>
                            Refresh
                        </Button>
                        <span
                            className="active"> {moment.utc(moment.duration(this.props.playOutStream.elapsed, 'seconds')
                                .asMilliseconds()).format('HH:mm:ss')} </span>
                        <span>/ {moment.utc(moment.duration(this.props.playOutStream.elapsed
                            ? this.props.playOutStream.elapsed + this.props.playOutStream.remaining
                            : 0, 'seconds')
                            .asMilliseconds()).format('HH:mm:ss')} </span>
                    </div>
                </div>
                {
                    (this.props.inProgress ||
                        this.state.fetching)
                        ? <SkeletonLoader/>
                        : <div className="playlist_section scroll_bar">
                            {
                                this.props.playOutStream.playout &&
                                Object.keys(this.props.playOutStream.playout).length
                                    ? Object.values(this.props.playOutStream.playout).map((value, index) => {
                                        const isPlaying = (this.props.playOutStream.nowPlaying &&
                                            this.props.playOutStream.nowPlaying.index) === index;
                                        const percentageOfCompletion = (this.props.playOutStream.nowPlaying &&
                                        this.props.playOutStream.nowPlaying.poc
                                            ? (this.props.playOutStream.nowPlaying.poc)
                                            : 0).toFixed(0);

                                        return (
                                            <div
                                                key={index}
                                                ref={isPlaying ? this.playingNode : ''}
                                                className={isPlaying ? 'playing playing_width' : ''}
                                                style={{
                                                    width: (list(`${percentageOfCompletion}%`)),
                                                    backgroundSize: '40%',
                                                }}
                                            >
                                                {list(isPlaying
                                                    ? <div className="playing_arrow_section">
                                                        <img alt="" src={arrowIcon}/>
                                                        {index + 1}
                                                    </div>
                                                    : <div className="playing_arrow_section">
                                                        <img alt="" src=""/>
                                                        {index + 1}
                                                    </div>)}
                                                {list(value.title
                                                    ? <div
                                                        className="title_section"
                                                        title={value.title}>
                                                        {value.title}
                                                        {list(value.ad ? 'AD' : '')}
                                                    </div>
                                                    : '')}

                                                {isPlaying
                                                    ? list(`${percentageOfCompletion}%`, 'width')
                                                    : list('', 'width')}
                                                <p className="start_end_section">
                                                    {
                                                        value.start_at && value.end_at && this.props.checked
                                                            ? `${moment(value.start_at).format('hh:mm:ss A')} - ${moment(value.end_at).format('hh:mm:ss A')}`
                                                            : `${moment(value.start_at).format('HH:mm:ss')} - ${moment(value.end_at).format('HH:mm:ss ') + ' Hrs'}`
                                                    }
                                                </p>
                                                <p className="start_end_section">
                                                    {
                                                        moment.utc(moment.duration(moment(value.end_at)
                                                            .diff(moment(value.start_at), 'seconds'), 'seconds')
                                                            .asMilliseconds()).format(this.props.timeFormat)
                                                    }
                                                </p>
                                                {
                                                    isPlaying
                                                        ? <div
                                                            className="active_background"
                                                            style={{ width: `${percentageOfCompletion}%` }}/>
                                                        : null
                                                }
                                            </div>
                                        );
                                    },
                                    )
                                    : <div className="no_data no_data_table loader_div">
                                        <p>No Program Scheduled</p>
                                    </div>
                            }
                        </div>
                }
            </div>
        );
    }
}

ScheduledPlayOut.propTypes = {
    channel: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    fetchPlayOut: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    playOutStatus: PropTypes.string.isRequired,
    playOutStream: PropTypes.shape({
        playout: PropTypes.arrayOf(
            PropTypes.shape({
                start_at: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                end_at: PropTypes.string.isRequired,
                ad: PropTypes.bool.isRequired,
            }),
        ),
        nowPlaying: PropTypes.shape({
            index: PropTypes.number.isRequired,
            poc: PropTypes.number.isRequired,
        }),
        elapsed: PropTypes.number,
        remaining: PropTypes.number,
    }).isRequired,
    programsInProgress: PropTypes.bool.isRequired,
    refresh: PropTypes.func.isRequired,
    stream: PropTypes.string.isRequired,
    timeFormat: PropTypes.string.isRequired,
    selectedDate: PropTypes.instanceOf(Date),
};

const stateToProps = (state) => {
    return {
        playOutStream: state.live.playOut.stream,
        programsInProgress: state.live.program.inProgress,
        selectedDate: state.live.selectedDate,
        inProgress: state.live.playOut.inProgress,
        channel: state.live.channel.current,
        stream: state.live.stream.current,
        timeFormat: state.live.timeFormat,
        checked: state.profile.timeFormat.checked,
        playOutStatus: state.live.playOut.status,
    };
};

const actionsToProps = {
    fetchPlayOut,
    refresh: refreshPlayOut,
};

export default connect(stateToProps, actionsToProps)(ScheduledPlayOut);
