import Axios from 'axios';
import {
    LANGUAGE_CODE_SET,
    TEXT_SET,
    TEXT_TO_AUDIO_ADD_ERROR,
    TEXT_TO_AUDIO_ADD_IN_PROGRESS,
    TEXT_TO_AUDIO_ADD_SUCCESS,
    VOICE_SET,
} from '../constants/textToSpeech';
import { addTextToAudioURL } from '../constants/url';

export const setText = (value) => {
    return {
        type: TEXT_SET,
        value,
    };
};

export const setVoice = (value) => {
    return {
        type: VOICE_SET,
        value,
    };
};

export const setLanguageCode = (value, list) => {
    return {
        type: LANGUAGE_CODE_SET,
        value,
        list,
    };
};

const addTextToAudioInProgress = () => {
    return {
        type: TEXT_TO_AUDIO_ADD_IN_PROGRESS,
    };
};

const addTextToAudioSuccess = (value) => {
    return {
        type: TEXT_TO_AUDIO_ADD_SUCCESS,
        value,
        message: 'Success!',
    };
};

const addTextToAudioError = (message) => {
    return {
        type: TEXT_TO_AUDIO_ADD_ERROR,
        message,
    };
};

export const addTextToAudio = (data, cb) => (dispatch) => {
    dispatch(addTextToAudioInProgress());

    Axios.post(addTextToAudioURL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(addTextToAudioSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(addTextToAudioError(
                error.response
                    ? error.response.data.message
                    : error.message,
            ));
            cb(error);
        });
};
