import * as PropTypes from 'prop-types';
import React from 'react';
import DialogAddGenre from '../DialogAddGenre';
import '../index.css';
import QuickView from '../QuickView';
import DialogAddAsset from './AddAssetDialog';
import Table from './Table';

const Text = (props) => {
    return (
        <div className="movies">
            <div className="data_table">
                <Table recent={props.recent}/>
                <QuickView/>
                <DialogAddAsset/>
                <DialogAddGenre/>
            </div>
        </div>
    );
};

Text.propTypes = {
    recent: PropTypes.bool,
};

export default Text;
