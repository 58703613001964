export const details = {
    title: 'Title Video Song',
    start_time: '00:00:50',
    scenes: 2,
    end_time: '00:06:43',
    duration: '00:05:53',
    name: 'Nanum Rowdy Dha',
    total_scenes: 22,
    total_duration: '00:12:44',
    description: 'Presenting the Official Video of #Thangamey from Naanum Rowdy Dhaan.' +
        'https://t.co/88xWWc0QTA' +
        'Apple Music - https://itun.es/in/wdQh-?i=1042194649' +
        'Spotify - https://play.spotify.com/album/2H89We...' +
        'Divo Movies - http://divomovies.com/naanumrowdyd',
    genre: [{
        name: 'Drama',
    }, {
        name: 'Horror',
    }],
    tags: ['Animation', 'TTS'],
    category: [{
        name: 'movies',
    }, {
        name: 'bugs',
    }],
    people: [{
        name: 'Ram Charan',
    }, {
        name: 'Kajal Aggarwal',
    }],
    objects: ['Object 1', 'Object 2', 'Object 3'],
    location: ['Hyderabad'],
};
