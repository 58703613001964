const streams = [{
    id: '1',
    name: 'Haseeno Ka Deewana.mkv',
    duration: '2h 51m 45s',
    speed: '3.21 GB / 4000 kbps',
    quality: '1920 X 1080',
    repeat: '2 Times',
    selected: true,
    startTime: '12.00',
    endTime: '12.30',
    streamUrl: '',
}, {
    id: '2',
    name: 'Haseeno Ka.mkv',
    duration: '1h 51m 45s',
    speed: '3.21 GB / 4000 kbps',
    quality: '1920 X 1080',
    repeat: '2 Times',
    selected: true,
    startTime: '12.30',
    endTime: '12.40',
    streamUrl: '',
}, {
    id: '3',
    name: 'Haseeno Deewana.mkv',
    duration: '3h 51m 45s',
    speed: '3.21 GB / 4000 kbps',
    quality: '1920 X 1080',
    repeat: '2 Times',
    selected: false,
    startTime: '12.40',
    endTime: '12.50',
    streamUrl: '',
}, {
    id: '4',
    name: 'Haseeno Ka.mkv',
    duration: '51m 45s',
    speed: '3.21 GB / 4000 kbps',
    quality: '1920 X 1080',
    repeat: '2 Times',
    selected: false,
    startTime: '12.50',
    endTime: '12.55',
    streamUrl: '',
}, {
    id: '5',
    name: 'Haseeno Ka Deewana.mkv',
    duration: '2h 51m 45s',
    speed: '3.21 GB / 4000 kbps',
    quality: '1920 X 1080',
    repeat: '2 Times',
    selected: true,
    startTime: '12.55',
    endTime: '12.60',
    streamUrl: '',
}];

export const slotAds = [{
    ads: 'Subscribe to my channel',
    type: 'Overlay',
    duration: '10 sec',
    frequency: 0,
    interval: 'none',
    position: 'none',
}];

export default streams;
