import React from 'react';
import './index.css';

const OverviewCards = () => {
    return (
        <div className="overview_loading_card slots_loader">
            <div key={1} className="text" style={{ width: '31%' }}/>
            <div key={2} className="text" style={{ width: '10%' }}/>
            <div key={3} className="text" style={{ width: '20%' }}/>
            <div key={4} className="text" style={{ width: '28%' }}/>
            <div key={5} className="text" style={{ width: '22%' }}/>
            <div key={6} className="text" style={{ width: '40%' }}/>
            <div key={7} className="text" style={{ width: '30%' }}/>
            <div key={8} className="text" style={{ width: '30%' }}/>
        </div>
    );
};

export default OverviewCards;
