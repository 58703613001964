import Axios from 'axios';
import {
    DYNAMIC_INPUT_VALUES_CLEAR,
    INDIVIDUAL_SLOT_DATE_SET,
    INDIVIDUAL_SLOT_DIALOG_HIDE,
    INDIVIDUAL_SLOT_DIALOG_SHOW,
    INDIVIDUAL_SLOT_FETCH_ERROR,
    INDIVIDUAL_SLOT_FETCH_IN_PROGRESS,
    INDIVIDUAL_SLOT_FETCH_SUCCESS,
    INDIVIDUAL_SLOT_PLAYER_SET,
    INDIVIDUAL_SLOT_TIME_SET,
    SEARCH_ASSETS_SET,
    SLOT_ADD_ERROR,
    SLOT_ADD_IN_PROGRESS,
    SLOT_ADD_SUCCESS,
    SLOT_CATEGORY_SET,
    SLOT_CONTENT_TYPE_SET,
    SLOT_DELETE_ERROR,
    SLOT_DELETE_IN_PROGRESS,
    SLOT_DELETE_SUCCESS,
    SLOT_DURATION_SET,
    SLOT_EXCLUDES_SET,
    SLOT_INCLUDES_SET,
    SLOT_NAME_SET,
    SLOT_POP_UP_SHOW_SET,
    SLOT_PUSH_ERROR,
    SLOT_PUSH_IN_PROGRESS,
    SLOT_PUSH_SUCCESS,
    SLOT_SET,
    SLOT_SETTINGS_DIALOG_HIDE,
    SLOT_SETTINGS_DIALOG_SHOW,
    SLOT_SETTINGS_TAB_SET,
    SLOT_UPDATE_ERROR,
    SLOT_UPDATE_IN_PROGRESS,
    SLOT_UPDATE_SUCCESS,
    SLOTS_FETCH_ERROR,
    SLOTS_FETCH_IN_PROGRESS,
    SLOTS_FETCH_SUCCESS,
    STREAM_CONFIG_UPDATE_ERROR,
    STREAM_CONFIG_UPDATE_IN_PROGRESS,
    STREAM_CONFIG_UPDATE_SUCCESS,
    STREAM_PLAYER_CONFIG_SET,
    STREAM_START_ERROR,
    STREAM_START_IN_PROGRESS,
    STREAM_START_SUCCESS,
    STREAM_STOP_ERROR,
    STREAM_STOP_IN_PROGRESS,
    STREAM_STOP_SUCCESS,
    TEMPLATE_NAME_SET,
    TIMER_SET,
} from '../constants/slots';
import {
    urlAddSlot,
    urlDeleteSlot,
    urlFetchSlots,
    urlPushSlot,
    urlStartStream,
    urlStopStream,
    urlStream,
    urlUpdateSlot,
} from '../constants/url';

const fetchSlotsInProgress = () => {
    return {
        type: SLOTS_FETCH_IN_PROGRESS,
    };
};

const fetchSlotsSuccess = (slots) => {
    return {
        type: SLOTS_FETCH_SUCCESS,
        slots,
    };
};

const fetchSlotsError = (message) => {
    return {
        type: SLOTS_FETCH_ERROR,
        message,
    };
};

export const fetchSlots = (channelId, liveStreamId, startAt, endAt, sortBy, order, cb) => (dispatch) => {
    dispatch(fetchSlotsInProgress());
    const URL = urlFetchSlots(channelId, liveStreamId,
        startAt - new Date(0),
        endAt - new Date(0), sortBy, order);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchSlotsSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchSlotsError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const addSlotInProgress = () => {
    return {
        type: SLOT_ADD_IN_PROGRESS,
    };
};

const addSlotSuccess = (slot) => {
    return {
        type: SLOT_ADD_SUCCESS,
        slot,
    };
};

const addSlotError = (message) => {
    return {
        type: SLOT_ADD_ERROR,
        message,
    };
};

export const addSlot = (channelId, liveStreamId, data, cb) => (dispatch) => {
    dispatch(addSlotInProgress());
    const URL = urlAddSlot(channelId, liveStreamId);
    Axios.post(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(addSlotSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(addSlotError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const updateSlotInProgress = () => {
    return {
        type: SLOT_UPDATE_IN_PROGRESS,
    };
};

const updateSlotSuccess = (slot) => {
    return {
        type: SLOT_UPDATE_SUCCESS,
        slot,
    };
};

const updateSlotError = (message) => {
    return {
        type: SLOT_UPDATE_ERROR,
        message,
    };
};

export const updateSlot = (channelId, liveStreamId, slotId, data, cb) => (dispatch) => {
    dispatch(updateSlotInProgress());
    const URL = urlUpdateSlot(channelId, liveStreamId, slotId);
    Axios.put(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(updateSlotSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(updateSlotError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const deleteSlotInProgress = () => {
    return {
        type: SLOT_DELETE_IN_PROGRESS,
    };
};

const deleteSlotSuccess = (slot) => {
    return {
        type: SLOT_DELETE_SUCCESS,
        slot,
    };
};

const deleteSlotError = (message) => {
    return {
        type: SLOT_DELETE_ERROR,
        message,
    };
};

export const deleteSlot = (channelId, liveStreamId, slotId, cb) => (dispatch) => {
    dispatch(deleteSlotInProgress());
    const URL = urlDeleteSlot(channelId, liveStreamId, slotId);
    Axios.delete(URL,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(deleteSlotSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(deleteSlotError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

export const setCurrentSlot = (slot, programs, program) => {
    return {
        type: SLOT_SET,
        slot: {
            _: slot,
            programs,
            currentProgram: program,
        },
    };
};

const pushSlotInProgress = () => {
    return {
        type: SLOT_PUSH_IN_PROGRESS,
    };
};

const pushSlotSuccess = () => {
    return {
        type: SLOT_PUSH_SUCCESS,
        message: 'Programs updated to streamer',
    };
};

const pushSlotError = (message) => {
    return {
        type: SLOT_PUSH_ERROR,
        message,
    };
};

export const pushSlot = (channelId, liveStreamId, slotId, token, cb) => (dispatch) => {
    dispatch(pushSlotInProgress());
    const URL = urlPushSlot(channelId, liveStreamId, slotId);
    Axios.put(URL, {},
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then((res) => {
            dispatch(pushSlotSuccess());
            cb(null);
        })
        .catch((error) => {
            dispatch(pushSlotError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

export const setSlotCategory = (value) => {
    return {
        type: SLOT_CATEGORY_SET,
        value: value || [],
    };
};

export const setSlotExcludes = (value) => {
    return {
        type: SLOT_EXCLUDES_SET,
        value: value || [],
    };
};

export const setSlotIncludes = (value) => {
    return {
        type: SLOT_INCLUDES_SET,
        value: value || [],
    };
};

export const setSlotContentType = (value) => {
    return {
        type: SLOT_CONTENT_TYPE_SET,
        value: value || [],
    };
};

export const setSlotPopupShow = (value) => {
    return {
        type: SLOT_POP_UP_SHOW_SET,
        value,
    };
};

export const setSlotDuration = (value) => {
    return {
        type: SLOT_DURATION_SET,
        value: value,
    };
};

export const clearDynamicInputValues = () => {
    return {
        type: DYNAMIC_INPUT_VALUES_CLEAR,
    };
};

const startStreamInProgress = () => {
    return {
        type: STREAM_START_IN_PROGRESS,
    };
};

const startStreamSuccess = () => {
    return {
        type: STREAM_START_SUCCESS,
        message: 'Stream synced successfully',
    };
};

const startStreamError = (message) => {
    return {
        type: STREAM_START_ERROR,
        message,
    };
};

export const startStream = (channelId, liveStreamId, token, cb) => (dispatch) => {
    const URL = urlStartStream(channelId, liveStreamId);
    dispatch(startStreamInProgress());

    Axios.post(URL, {},
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then((res) => {
            dispatch(startStreamSuccess());
            cb(null);
        })
        .catch((error) => {
            dispatch(startStreamError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const stopStreamInProgress = () => {
    return {
        type: STREAM_STOP_IN_PROGRESS,
    };
};

const stopStreamSuccess = () => {
    return {
        type: STREAM_STOP_SUCCESS,
        message: 'Stream stopped successfully',
    };
};

const stopStreamError = (message) => {
    return {
        type: STREAM_STOP_ERROR,
        message,
    };
};

export const stopStream = (channelId, liveStreamId, token, cb) => (dispatch) => {
    const URL = urlStopStream(channelId, liveStreamId);
    dispatch(stopStreamInProgress());

    Axios.post(URL, {},
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then(() => {
            dispatch(stopStreamSuccess());
            cb(null);
        })
        .catch((error) => {
            dispatch(stopStreamError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const updateStreamConfigInProgress = () => {
    return {
        type: STREAM_CONFIG_UPDATE_IN_PROGRESS,
    };
};

const updateStreamConfigSuccess = (data) => {
    return {
        type: STREAM_CONFIG_UPDATE_SUCCESS,
        message: 'Stream quality updated successfully',
        configuration: data.configuration,
    };
};

const updateStreamConfigError = (message) => {
    return {
        type: STREAM_CONFIG_UPDATE_ERROR,
        message,
    };
};

export const updateStreamConfig = (channelId, liveStreamId, data, token, cb) => (dispatch) => {
    const URL = urlStream(channelId, liveStreamId);
    dispatch(updateStreamConfigInProgress());

    Axios.put(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then((res) => {
            dispatch(updateStreamConfigSuccess(res.data && res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(updateStreamConfigError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

export const setTemplateName = (value) => {
    return {
        type: TEMPLATE_NAME_SET,
        value,
    };
};

export const setStreamPlayerConfig = (value, inProgress) => {
    return {
        type: STREAM_PLAYER_CONFIG_SET,
        value,
        inProgress,
    };
};

export const checkStreamPlayerConnection = (url, cb) => (dispatch) => {
    Axios.get(url, {
        headers: {
            Accept: 'application/json',
        },
    })
        .then(() => {
            cb(null);
        })
        .catch((error) => {
            cb(error);
        });
};

export const setTimer = (value) => {
    return {
        type: TIMER_SET,
        value,
    };
};

export const showSlotSettingsDialog = () => {
    return {
        type: SLOT_SETTINGS_DIALOG_SHOW,
    };
};

export const hideSlotSettingsDialog = () => {
    return {
        type: SLOT_SETTINGS_DIALOG_HIDE,
    };
};

export const setTabValue = (value) => {
    return {
        type: SLOT_SETTINGS_TAB_SET,
        value,
    };
};

export const setSlotName = (value) => {
    return {
        type: SLOT_NAME_SET,
        value,
    };
};

export const showIndividualSlot = (slot, program) => {
    return {
        type: INDIVIDUAL_SLOT_DIALOG_SHOW,
        slot,
        program,
    };
};

export const hideIndividualSlot = () => {
    return {
        type: INDIVIDUAL_SLOT_DIALOG_HIDE,
    };
};

export const setSlotDate = (value) => {
    return {
        type: INDIVIDUAL_SLOT_DATE_SET,
        value,
    };
};

export const setSlotTime = (value) => {
    return {
        type: INDIVIDUAL_SLOT_TIME_SET,
        value,
    };
};

export const setSearchAssets = (value) => {
    return {
        type: SEARCH_ASSETS_SET,
        value,
    };
};

const fetchIndividualSlotInProgress = () => {
    return {
        type: INDIVIDUAL_SLOT_FETCH_IN_PROGRESS,
    };
};

const fetchIndividualSlotSuccess = (slots) => {
    return {
        type: INDIVIDUAL_SLOT_FETCH_SUCCESS,
        slots,
    };
};

const fetchIndividualSlotError = (message) => {
    return {
        type: INDIVIDUAL_SLOT_FETCH_ERROR,
        message,
    };
};

export const fetchIndividualSlot = (channelId, liveStreamId, startAt, endAt, sortBy, order, cb) => (dispatch) => {
    dispatch(fetchIndividualSlotInProgress());
    const URL = urlFetchSlots(channelId, liveStreamId,
        startAt - new Date(0),
        endAt - new Date(0), sortBy, order);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchIndividualSlotSuccess(res.data.result));
            cb(res.data.result);
        })
        .catch((error) => {
            dispatch(fetchIndividualSlotError(error.response ? error.response.data.message : error.message,
            ));
            cb(null);
        });
};

export const setIndividualSlotPlayer = (value) => {
    return {
        type: INDIVIDUAL_SLOT_PLAYER_SET,
        value,
    };
};
