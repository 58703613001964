import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAccountBalance, showSendTokensDialog } from '../../../actions/account';

const BalanceCard = (props) => {
    useEffect(() => {
        props.fetchAccountBalance(props.address);
    }, []);

    return (
        <div className="card balance_card">
            <div className="info">
                <p className="heading">Balance</p>
                <p className="balance">{props.balance && props.balance[0]
                    ? `${props.balance[0].amount
                        ? props.balance[0].amount / 1000000
                        : 0}`
                    : 0} FLIX</p>
            </div>
            <Button
                className="action_button1"
                onClick={props.showDialog}>
                Send
            </Button>
            <Button className="action_button1">
                Receive
            </Button>
        </div>
    );
};

BalanceCard.propTypes = {
    fetchAccountBalance: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    address: PropTypes.string,
    balance: PropTypes.arrayOf(
        PropTypes.shape({
            amount: PropTypes.string,
            denom: PropTypes.string,
        }),
    ),
};

const stateToProps = (state) => {
    return {
        balance: state.account.balance.amount,
    };
};

const actionsToProps = {
    fetchAccountBalance,
    showDialog: showSendTokensDialog,
};

export default connect(stateToProps, actionsToProps)(BalanceCard);
