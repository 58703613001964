import { combineReducers } from 'redux';
import { ADS_SLOT_EDIT } from '../../constants/ads';
import { BUGS_DIALOG_HIDE } from '../../constants/bugs';
import { slotAds } from '../../dummy/streams';

const adsList = (state = {
    list: slotAds,
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ADS_SLOT_EDIT:
        return {
            ...state,
            list: action.value,
        };
    case BUGS_DIALOG_HIDE:
        return {
            ...state,
            list: [],
        };
    default:
        return state;
    }
};

export default combineReducers({
    adsList,
});
