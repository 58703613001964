export const arraysEqual = (a, b) => {
    if (a instanceof Array && b instanceof Array) {
        if (a.length !== b.length) { // assert same length
            return false;
        }
        for (let i = 0; i < a.length; i++) { // assert each element equal
            if (!arraysEqual(a[i], b[i])) {
                return false;
            }
        }
        return true;
    } else {
        return a === b; // if not both arrays, should be the same
    }
};

export const arrayUnique = (array) => {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j]) { a.splice(j--, 1); }
        }
    }

    return a;
};
