import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getSourceType, setAddSourceType } from '../../../actions/asset';
import SelectField from '../../../components/SelectField';

class SourceTypeSelectField extends React.Component {
    constructor (props) {
        super(props);
        this.fetch = this.fetch.bind(this);
    }

    componentDidMount () {
        if (this.props.items.length) {
            return;
        }

        this.fetch();
    }

    fetch () {
        this.props.fetch();
    }

    render () {
        return (
            <div>
                <SelectField
                    id="source_type_select_field"
                    items={this.props.items}
                    label="Source Type"
                    name="source_type"
                    required={true}
                    value={this.props.value}
                    onChange={this.props.onChange}/>
            </div>
        );
    }
}

SourceTypeSelectField.propTypes = {
    fetch: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.sourceTypeValue,
        items: state.asset.sourceType.list,
    };
};

const actionsToProps = {
    fetch: getSourceType,
    onChange: setAddSourceType,
};

export default connect(stateToProps, actionsToProps)(SourceTypeSelectField);
