import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    addAsset,
    fetchAssetsData,
    hideDialogAddAsset,
    updateAsset,
    uploadFile,
    reUploadFile,
} from '../../actions/asset';
import './index.css';
import { showMessage } from '../../actions/snackbar';
import { addTextToAudio } from '../../actions/textToSpeech';
import { setUploadDetails } from '../../actions/videoAssets';
import { BASE_URL } from '../../constants/url';
import { arraysEqual } from '../../utils/arrayEquality';
import AssetTitleTextField from './AssetTitleTextField';
import CloseButton from './CloseButton';
import SaveButton from './SaveButton';
import Tabs from './Tabs';

const AddAssets = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disable) {
            if (props.sourceType && props.sourceType === 'studio' && !props.assetId) {
                handleUpload();
            } else {
                handleSave();
            }
        }
    };

    const handleSave = () => {
        if (props.inProgress) {
            return;
        }

        const token = localStorage.getItem('authorizationToken');
        const data = {
            type: props.assetType,
        };

        if (props.name) {
            if (props.assetType === 'video') {
                if ((props.name !== (props.editData.video && props.editData.video.name))) {
                    data.name = props.name;
                }
            } else {
                data.name = props.name;
            }
        }
        if ((props.tags && props.tags.length > 0) ||
            (props.editData.tags && !arraysEqual(props.tags, props.editData.tags))) {
            data.tags = props.tags;
        }
        if (props.selectedCategory && (props.selectedCategory !== (props.editData.category &&
            props.editData.category._id))) {
            data.category = props.selectedCategory;
        }
        if (props.sourceType && props.sourceId &&
            (props.sourceType !== (props.editData && props.editData.file &&
                props.editData.file.download && props.editData.file.download.source)) &&
            (props.sourceId !== (props.editData && props.editData.file &&
                props.editData.file.download && props.editData.file.download.id))) {
            data.file = {
                source: props.sourceType,
                id: props.sourceId,
            };
        }
        if (props.ownerShipStatus && props.assetType === 'video' &&
            (props.ownerShipStatus !== (props.editData.video &&
                props.editData.video['ownership_status']))) {
            data.ownershipStatus = props.ownerShipStatus;
        }
        if (props.album && (props.album !== (props.editData.video &&
            props.editData.video.album))) {
            data.album = props.album;
        }
        if (props.ISRCCode && (props.ISRCCode !== (props.editData.video &&
            props.editData.video['isrc_code']))) {
            data.isrcCode = props.ISRCCode;
        }
        if (props.recordLabel && (props.recordLabel !== (props.editData.video &&
            props.editData.video['record_label']))) {
            data.recordLabel = props.recordLabel;
        }
        if (props.duration && (props.duration !== (props.editData.video &&
            props.editData.video.duration))) {
            const hr = props.duration.getHours();
            const min = props.duration.getMinutes();
            const sec = props.duration.getSeconds();
            data.duration = moment.duration(`${hr}:${min}:${sec}`).asMinutes();
        }
        if (props.musicDirectors) {
            const array = [];
            if (props.musicDirectors && props.musicDirectors.length > 0) {
                props.musicDirectors.map((value) => array.push(value._id));
            }

            if ((array.length > 0) || (props.editData.video && props.editData.video['music_directors'] &&
                !arraysEqual(props.musicDirectors, props.editData.video['music_directors']))) {
                data.musicDirectors = array;
            }
        }
        if (props.musicComposers) {
            const array = [];
            if (props.musicComposers && props.musicComposers.length > 0) {
                props.musicComposers.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video['music_composers'] &&
                !arraysEqual(props.musicComposers, props.editData.video['music_composers']))) {
                data.musicComposers = array;
            }
        }
        if (props.singers) {
            const array = [];
            if (props.singers && props.singers.length > 0) {
                props.singers.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video.singers &&
                !arraysEqual(props.singers, props.editData.video.singers))) {
                data.singers = array;
            }
        }
        if (props.lyricists) {
            const array = [];
            if (props.lyricists && props.lyricists.length > 0) {
                props.lyricists.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video.lyricists &&
                !arraysEqual(props.lyricists, props.editData.video.lyricists))) {
                data.lyricists = array;
            }
        }
        if (props.mood && (props.mood !== (props.editData.video &&
            props.editData.video.mood))) {
            data.mood = props.mood;
        }
        if (props.genre && (props.genre !== (props.editData.genre &&
            props.editData.genre._id))) {
            data.genre = props.genre;
        }
        if (props.releaseDate && (props.releaseDate !== (props.editData.video &&
            props.editData.video['release_date']))) {
            data.releaseDate = props.releaseDate;
        }
        if (props.director) {
            const array = [];
            if (props.director && props.director.length > 0) {
                props.director.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video.directors &&
                !arraysEqual(props.director, props.editData.video.directors))) {
                data.directors = array;
            }
        }
        if (props.producer) {
            const array = [];
            if (props.producer && props.producer.length > 0) {
                props.producer.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video.producers &&
                !arraysEqual(props.producer, props.editData.video.producers))) {
                data.producers = array;
            }
        }
        if (props.cinematography) {
            const array = [];
            if (props.cinematography && props.cinematography.length > 0) {
                props.cinematography.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video.cinematography &&
                !arraysEqual(props.cinematography, props.editData.video.cinematography))) {
                data.cinematography = array;
            }
        }
        if (props.cast) {
            const array = [];
            if (props.cast && props.cast.length > 0) {
                props.cast.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video.cast &&
                !arraysEqual(props.cast, props.editData.video.cast))) {
                data.cast = array;
            }
        }
        if (props.crew) {
            const array = [];
            if (props.crew && props.crew.length > 0) {
                props.crew.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video.crew &&
                !arraysEqual(props.crew, props.editData.video.crew))) {
                data.crew = array;
            }
        }
        if (props.artDirector) {
            const array = [];
            if (props.artDirector && props.artDirector.length > 0) {
                props.artDirector.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video['art_directors'] &&
                !arraysEqual(props.artDirector, props.editData.video['art_directors']))) {
                data.artDirectors = array;
            }
        }
        if (props.computerGraphics) {
            const array = [];
            if (props.computerGraphics && props.computerGraphics.length > 0) {
                props.computerGraphics.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video['computer_graphics'] &&
                !arraysEqual(props.computerGraphics, props.editData.video['computer_graphics']))) {
                data.computerGraphics = array;
            }
        }
        if (props.editor) {
            const array = [];
            if (props.editor && props.editor.length > 0) {
                props.editor.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video.editors &&
                !arraysEqual(props.editor, props.editData.video.editors))) {
                data.editors = array;
            }
        }
        if (props.stylist) {
            const array = [];
            if (props.stylist && props.stylist.length > 0) {
                props.stylist.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video.stylists &&
                !arraysEqual(props.stylist, props.editData.video.stylists))) {
                data.stylists = array;
            }
        }
        if (props.styleCostumeDesigner) {
            const array = [];
            if (props.styleCostumeDesigner && props.styleCostumeDesigner.length > 0) {
                props.styleCostumeDesigner.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video['costume_designers'] &&
                !arraysEqual(props.styleCostumeDesigner, props.editData.video['costume_designers']))) {
                data.costumeDesigner = array;
            }
        }
        if (props.story) {
            const array = [];
            if (props.story && props.story.length > 0) {
                props.story.map((value) => array.push(value._id));
            }

            if (array.length > 0 || (props.editData.video && props.editData.video.story &&
                !arraysEqual(props.story, props.editData.video.story))) {
                data.story = array;
            }
        }
        if (props.description && (props.description !== props.editData.description)) {
            data.description = props.description;
        }
        if (props.selectedCategory) {
            if (props.text) {
                data.text = props.text;
            }
            if (props.languageCode) {
                data.languageCode = props.languageCode;
            }
            if (props.voice) {
                data.voiceName = props.languageCode + '-' + props.voice;
            }
        }

        if (props.successList && props.successList.length > 0) {
            if (
                props.successList &&
                props.successList.length > 0 &&
                props.successList.filter((item) => item.type === 'vertical') &&
                props.successList.filter((item) => item.type === 'vertical').length > 0) {
                data.verticalThumbnail = `${BASE_URL}` + `${props.successList.filter((item) => item.type === 'vertical') &&
                props.successList.filter((item) => item.type === 'vertical')[0] &&
                props.successList.filter((item) => item.type === 'vertical')[0].img}`;
            }
            if (
                props.successList &&
                props.successList.length > 0 &&
                props.successList.filter((item) => item.type === 'horizontal') &&
                props.successList.filter((item) => item.type === 'horizontal').length > 0) {
                data.horizontalThumbnail = `${BASE_URL}` + `${props.successList.filter((item) => item.type === 'horizontal') &&
                props.successList.filter((item) => item.type === 'horizontal')[0] &&
                props.successList.filter((item) => item.type === 'horizontal')[0].img}`;
            }
            if (
                props.successList &&
                props.successList.length > 0 &&
                props.successList.filter((item) => item.type === 'square') &&
                props.successList.filter((item) => item.type === 'square').length > 0) {
                data.squareThumbnail = `${BASE_URL}` + `${props.successList.filter((item) => item.type === 'square') &&
                props.successList.filter((item) => item.type === 'square')[0] &&
                props.successList.filter((item) => item.type === 'square')[0].img}`;
            }
        }

        const type = props.categoriesList && props.categoriesList.length > 0 &&
            props.categoriesList.filter((value) => value._id === props.selectedCategory);

        if (props.assetId) {
            props.updateAsset(props.assetId, data, token, (cb) => {
                if (cb) {
                    handleFetch();
                    handleTabChange();
                }
            });
        } else if (type && type.length > 0 && (type[0].name === 'text to speech')) {
            props.addTextToAudio(data, (error) => {
                if (!error) {
                    handleFetch();
                    handleTabChange();
                }
            });
        } else {
            props.onClick(data,
                token, (cb) => {
                    if (cb) {
                        return;
                    }

                    handleFetch();
                    handleTabChange();
                });
        }
    };

    const handleTabChange = () => {
        props.hide();
        // if (props.tabValue > 2) {
        //     props.setTabValue(0);
        // } else {
        //     props.setTabValue(props.tabValue + 1);
        // }
    };

    const handleUpload = () => {
        const token = localStorage.getItem('authorizationToken');
        const data = {
            type: props.assetType,
        };

        if (props.name) {
            data.name = props.name;
        }

        if (props.file && props.file.name) {
            data.file_name = props.file.name;
        }

        if (props.selectedCategory) {
            data.category = props.selectedCategory;
        }

        if (!props.file) {
            props.showMessage('File Not Selected');

            return;
        }

        const uploadList = [...props.uploadList];
        uploadList.push(data);

        props.setUploadDetails(data, [...uploadList]);

        if (token && props.runners.length) {
            props.uploadFile(props.runners[0].public_address,
                props.runners[0].organization,
                props.name,
                props.selectedCategory,
                props.tags,
                props.file, token, (cb) => {
                    if (cb) {
                        return;
                    }

                    handleFetch();
                    handleTabChange();
                });
        }
    };

    const handleFileUpload = (file) => {
        const token = localStorage.getItem('authorizationToken');
        const data = {
            type: props.assetType,
        };

        if (props.name) {
            data.name = props.name;
        }

        if (file && file.name) {
            data.file_name = file.name;
        }

        if (props.selectedCategory) {
            data.category = props.selectedCategory;
        }

        if (!file) {
            props.showMessage('File Not Selected');

            return;
        }

        const uploadList = [...props.uploadList];
        uploadList.push(data);

        props.setUploadDetails(data, [...uploadList]);

        if (props.assetId && token && props.runners.length) {
            props.reUploadFile(props.runners[0].public_address,
                props.runners[0].organization,
                props.assetId,
                props.name,
                props.selectedCategory,
                props.tags,
                file, token, (cb) => {
                    if (cb) {
                        return;
                    }

                    handleFetch();
                    handleTabChange();
                });

            return;
        }

        if (token && props.runners.length) {
            props.uploadFile(props.runners[0].public_address,
                props.runners[0].organization,
                props.name,
                props.selectedCategory,
                props.tags,
                file, token, (cb) => {
                    if (cb) {
                        return;
                    }

                    handleFetch();
                    handleTabChange();
                });
        }
    };

    const handleFetch = () => {
        const token = localStorage.getItem('authorizationToken');
        const recent = props.location.pathname === '/manage/assets';

        if (recent) {
            props.fetchAssetsData(props.assetType, token, false, true, props.days,
                props.skip,
                props.limit,
                props.searchKey,
                props.sortBy,
                props.order);
        } else {
            props.fetchAssetsData(props.assetType, token, null, null, null,
                props.skip,
                props.limit,
                props.searchKey,
                props.sortBy,
                props.order);
        }
    };

    const disable =
        props.name === '' ||
        props.selectedCategory === '' ||
        props.uploadInProgress || props.inProgress;

    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            className="dialog add_asset_dialog"
            open={props.open}
            onClose={props.hide}>
            <DialogTitle
                className="title add_assets"
                id="alert-dialog-title">
                <div className="header_div">
                    <AssetTitleTextField/>
                    <div className="row">
                        <SaveButton
                            disable={disable}
                            onClick={props.sourceType && props.sourceType === 'studio' && !props.assetId
                                ? handleUpload
                                : handleSave}/>
                        <CloseButton hideAddAsset={props.hide}/>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent className="add_assets scroll_bar">
                <form
                    noValidate
                    autoComplete="off"
                    onKeyPress={handleKeyPress}
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}>
                    <Tabs
                        disable={disable}
                        onClick={handleFileUpload}/>
                </form>
            </DialogContent>
        </Dialog>
    );
};

AddAssets.propTypes = {
    ISRCCode: PropTypes.string.isRequired,
    addTextToAudio: PropTypes.func.isRequired,
    album: PropTypes.string.isRequired,
    artDirector: PropTypes.array.isRequired,
    assetFileId: PropTypes.string.isRequired,
    assetType: PropTypes.string.isRequired,
    cast: PropTypes.array.isRequired,
    categoriesList: PropTypes.array.isRequired,
    cinematography: PropTypes.array.isRequired,
    computerGraphics: PropTypes.array.isRequired,
    country: PropTypes.string.isRequired,
    crew: PropTypes.array.isRequired,
    days: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    director: PropTypes.array.isRequired,
    distributionPlatforms: PropTypes.object.isRequired,
    editData: PropTypes.object.isRequired,
    editor: PropTypes.array.isRequired,
    fetchAssetsData: PropTypes.func.isRequired,
    fileInfo: PropTypes.object.isRequired,
    genre: PropTypes.string.isRequired,
    hide: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    languageCode: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    lyricists: PropTypes.array.isRequired,
    mood: PropTypes.string.isRequired,
    musicComposers: PropTypes.array.isRequired,
    musicDirectors: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    ownerShipStatus: PropTypes.string.isRequired,
    producer: PropTypes.array.isRequired,
    rating: PropTypes.string.isRequired,
    reUploadFile: PropTypes.func.isRequired,
    recordLabel: PropTypes.string.isRequired,
    runners: PropTypes.arrayOf(
        PropTypes.shape({
            public_address: PropTypes.string.isRequired,
            organization: PropTypes.string.isRequired,
        }),
    ).isRequired,
    searchKey: PropTypes.string.isRequired,
    selectedCategory: PropTypes.string.isRequired,
    setUploadDetails: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    singers: PropTypes.array.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    sourceId: PropTypes.string.isRequired,
    sourceType: PropTypes.string.isRequired,
    story: PropTypes.array.isRequired,
    styleCostumeDesigner: PropTypes.array.isRequired,
    stylist: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    text: PropTypes.string.isRequired,
    updateAsset: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    uploadInProgress: PropTypes.bool.isRequired,
    uploadList: PropTypes.array.isRequired,
    voice: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    assetId: PropTypes.string,
    duration: PropTypes.any,
    file: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        size: PropTypes.number,
    }),
    order: PropTypes.number,
    releaseDate: PropTypes.string,
    successList: PropTypes.array,
    thumbsList: PropTypes.array,
};

const stateToProps = (state) => {
    return {
        assetId: state.asset.new.assetId,
        assetType: state.asset.new.dialog.assetType,
        open: state.asset.new.dialog.status,
        artDirector: state.asset.new.movies.artDirector,
        cast: state.asset.new.cast,
        crew: state.asset.new.crew,
        cinematography: state.asset.new.movies.cinematography,
        days: state.asset.updatedFilter.days,
        director: state.asset.new.movies.director,
        description: state.chapterMarking.new.description,
        editData: state.asset.new.editData,
        id: state.asset.new.id,
        name: state.asset.new.name,
        selectedCategory: state.asset.new.category,
        categoriesList: state.asset.assetCategoriesList.list,
        country: state.asset.new.movies.country.value,
        genre: state.asset.new.genre,
        fileInfo: state.asset.addAssetFile.result,
        assetFileId: state.asset.new.assetFile,
        inProgress: state.asset.new.inProgress,
        sourceType: state.asset.new.sourceTypeValue,
        sourceId: state.asset.new.sourceUrl,
        tags: state.asset.new.tags,
        file: state.asset.upload.file,
        producer: state.asset.new.movies.producer,
        uploadInProgress: state.asset.upload.inProgress,
        rating: state.asset.new.movies.rating.value,
        releaseDate: state.asset.new.movies.releaseDate,
        runners: state.runner.list,
        uploadList: state.asset.videoAssets.uploadFile.list,
        ownerShipStatus: state.asset.new.ownerShipStatus,
        album: state.asset.new.music.album.value,
        ISRCCode: state.asset.new.music.ISRCCode,
        recordLabel: state.asset.new.music.recordLabel,
        duration: state.asset.new.movies.duration,
        musicDirectors: state.asset.new.music.musicDirectors,
        musicComposers: state.asset.new.music.musicComposers,
        singers: state.asset.new.music.singers,
        lyricists: state.asset.new.music.lyricists,
        mood: state.asset.new.music.mood,
        stylist: state.asset.new.stylist,
        styleCostumeDesigner: state.asset.new.movies.styleCostumeDesigner,
        computerGraphics: state.asset.new.computerGraphics,
        editor: state.asset.new.editor,
        story: state.asset.new.story,
        distributionPlatforms: state.subscription.distributionPlatforms,
        text: state.asset.new.textToSpeech.text,
        languageCode: state.asset.new.textToSpeech.languageCode.value,
        voice: state.asset.new.textToSpeech.voice.value,
        skip: state.asset.assetData.skip,
        limit: state.asset.assetData.limit,
        searchKey: state.asset.assetData.searchKey,
        sortBy: state.asset.assetData.sortBy,
        order: state.asset.assetData.order,
        thumbsList: state.asset.thumbsList.list,
        successList: state.asset.thumbsList.successList,
    };
};

const actionToProps = {
    addTextToAudio,
    hide: hideDialogAddAsset,
    onClick: addAsset,
    uploadFile,
    fetchAssetsData,
    updateAsset,
    setUploadDetails,
    showMessage,
    reUploadFile,
};

export default withRouter(connect(stateToProps, actionToProps)(AddAssets));
