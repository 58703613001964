import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchLanguages, setAddAssetLanguage } from '../../../../actions/asset';
import LinearProgress from '../../../../components/LinearProgress';
import SelectField from '../../../../components/SelectField';

class LanguageSelectField extends Component {
    constructor (props) {
        super(props);
        this.fetch = this.fetch.bind(this);
    }

    componentDidMount () {
        if (this.props.items.length) {
            return;
        }

        this.fetch();
    }

    fetch () {
        if (this.props.inProgress) {
            return;
        }

        this.props.fetch();
    }

    render () {
        return (
            <div className={this.props.inProgress ? 'select_field_progress' : ''}>
                <SelectField
                    id="language_select_field"
                    items={this.props.items}
                    label="Select Language"
                    name="language"
                    value={this.props.value}
                    onChange={this.props.onChange}/>
                {
                    this.props.inProgress
                        ? <LinearProgress/>
                        : null
                }
            </div>
        );
    }
}

LanguageSelectField.propTypes = {
    fetch: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.language,
        items: state.asset.new.languages.list,
        inProgress: state.asset.new.languages.inProgress,
    };
};

const actionsToProps = {
    fetch: fetchLanguages,
    onChange: setAddAssetLanguage,
};

export default connect(stateToProps, actionsToProps)(LanguageSelectField);
