import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setTimer } from '../actions/slot';

class CountdownTimer extends React.Component {
    componentDidMount () {
        this.timer = setInterval(this.props.setTimer, 1000);
    }

    componentWillUnmount () {
        clearInterval(this.timer);
    }

    render () {
        return (
            <div className="count_down_timer">
                <div>Reconnecting in {this.props.player.timer} seconds!</div>
            </div>
        );
    }
}

CountdownTimer.propTypes = {
    setTimer: PropTypes.func.isRequired,
    player: PropTypes.shape({
        timer: PropTypes.number.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        player: state.live.player,
    };
};

const actionsToProps = {
    setTimer,
};

export default connect(stateToProps, actionsToProps)(CountdownTimer);
