import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAlbum } from '../../../../../actions/album';
import { setAlbum } from '../../../../../actions/asset';
import LinearProgress from '../../../../../components/LinearProgress';
import SelectField from '../../../../../components/SelectField';
import { stringToUpperFirstChar } from '../../../../../utils/string';
import DialogAddAlbum from '../../../../asset/DialogAddAlbum';

class AlbumSelectField extends Component {
    constructor (props) {
        super(props);
        this.fetch = this.fetch.bind(this);
    }

    componentDidMount () {
        if (this.props.list && this.props.list.length) {
            return;
        }

        this.fetch();
    }

    fetch () {
        if (this.props.inProgress) {
            return;
        }

        this.props.fetch();
    }

    render () {
        const Items = this.props.list.length
            ? this.props.list.map(
                (item) => ({
                    value: item._id,
                    name: stringToUpperFirstChar(item.name),
                }),
            )
            : [];

        return (
            <div className={this.props.inProgress ? 'select_field_progress' : ''}>
                <SelectField
                    album={true}
                    className="text_field"
                    id="video_select_field"
                    items={Items}
                    label="Album"
                    name="album"
                    showAddButton={true}
                    value={this.props.value}
                    onChange={this.props.onChange}/>
                {
                    this.props.inProgress
                        ? <LinearProgress/>
                        : null
                }
                <DialogAddAlbum/>
            </div>
        );
    }
}

AlbumSelectField.propTypes = {
    fetch: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    showDialogAddVideo: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    showAddButton: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.music.album.value,
        list: state.asset.new.music.album.list,
        inProgress: state.asset.new.music.album.inProgress,
    };
};

const actionsToProps = {
    fetch: fetchAlbum,
    onChange: setAlbum,
};

export default connect(stateToProps, actionsToProps)(AlbumSelectField);
