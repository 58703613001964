import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setSlotDuration } from '../../../../actions/slot';
import TextField from '../../../../components/TextField';

class SetTimeTextfield extends React.Component {
    constructor (props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange (value) {
        this.props.onChange(value);
    }

    render () {
        return (
            <TextField
                InputProps={{
                    inputProps: this.props.type === 'number' ? {
                        max: 100,
                        min: 10,
                    } : null,
                }}
                id="settime_text_field"
                label={this.props.label}
                margin={this.props.style ? this.props.style : ''}
                max={this.props.max}
                name="Set Time"
                type={this.props.type ? this.props.type : 'text'}
                value={this.props.value}
                onChange={this.onChange}/>
        );
    }
}

SetTimeTextfield.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    max: PropTypes.number,
    style: PropTypes.string,
    type: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        value: state.live.dynamic.duration,
    };
};

const actionsToProps = {
    onChange: setSlotDuration,
};

export default connect(stateToProps, actionsToProps)(SetTimeTextfield);
