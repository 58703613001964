import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setVoice } from '../../../../../actions/textToSpeech';
import SelectField from '../../../../../components/SelectField';

const VoiceSelectField = (props) => {
    return (
        <SelectField
            id="voice_select_field"
            items={props.options}
            label="Voice"
            name="voice"
            value={props.value}
            onChange={props.onChange}/>
    );
};

VoiceSelectField.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.textToSpeech.voice.value,
        options: state.asset.new.textToSpeech.voice.list,
    };
};

const actionsToProps = {
    onChange: setVoice,
};

export default connect(stateToProps, actionsToProps)(VoiceSelectField);
