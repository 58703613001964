import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setBugsSlotData, setTimeValidation, setUpdatedBugsList } from '../../../../../../actions/bugs';
import Icon from '../../../../../../components/Icon';

const PlayTime = (props) => {
    const onChange = (value) => {
        const minDiff = moment(value).diff(moment(props.slot['start_at']));
        const maxDiff = moment(props.slot['end_at']).diff(value);
        if (minDiff < 0 || maxDiff < 0) {
            props.setTimeValidation(true);
        } else {
            props.setTimeValidation(false);
        }

        const arr = props.data;
        const length = moment.utc(moment.duration(
            moment(arr[props.index].end_at)
                .diff(moment(arr[props.index].start_at), 'seconds'),
            'seconds')
            .asMilliseconds()).format('HH:mm:ss');
        arr[props.index].start_at = moment.utc(value).format();
        arr[props.index].end_at = moment.utc(moment(value).add(length, 'seconds')).format();
        if (props.updatedBugs.length > 0) {
            const indArray = props.updatedBugs;
            const alreadyExit = props.updatedBugs.filter((val) => val === props.index);
            if (alreadyExit.length === 0) {
                indArray.push(props.index);
            }
            props.setUpdatedBugsList([...indArray]);
        } else {
            const indArray = [];
            indArray.push(props.index);
            props.setUpdatedBugsList([...indArray]);
        }
        props.setBugsSlotData([...arr]);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="icon_div">
                <TimePicker
                    className="time_picker"
                    format="HH:mm:ss"
                    openTo="hours"
                    value={moment(props.item.start_at).format()}
                    views={['hours', 'minutes', 'seconds']}
                    onChange={onChange}/>
                <Icon
                    className="calendar_icon"
                    icon="calendar_icon"/>
            </div>
            {props.timeValidation
                ? <p className="error_message">Invalid Time slot</p>
                : null}
        </MuiPickersUtilsProvider>
    );
};

PlayTime.propTypes = {
    data: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    setBugsSlotData: PropTypes.func.isRequired,
    setTimeValidation: PropTypes.func.isRequired,
    setUpdatedBugsList: PropTypes.func.isRequired,
    slot: PropTypes.object.isRequired,
    timeValidation: PropTypes.bool.isRequired,
    updatedBugs: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
    return {
        data: state.live.bugs.bugs.list,
        updatedBugs: state.live.bugs.updatedBugs.list,
        slot: state.live.bugs.slot,
        timeValidation: state.live.bugs.timeValidation,
    };
};

const actionToProps = {
    setBugsSlotData,
    setTimeValidation,
    setUpdatedBugsList,
};

export default connect(stateToProps, actionToProps)(PlayTime);
