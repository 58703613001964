import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setChapterName } from '../../../actions/chapterMarking';
import TextField from '../../../components/TextField';

class ChapterNameTextField extends Component {
    componentDidMount () {
        if (this.props.defaultValue) {
            this.props.onChange(this.props.defaultValue);
        }
    }

    render () {
        return (
            <TextField
                id="chapter_name_text_field"
                name="chapterName"
                value={this.props.value}
                onChange={this.props.onChange}/>
        );
    }
}

ChapterNameTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        value: state.chapterMarking.new.chapterName,
    };
};

const actionsToProps = {
    onChange: setChapterName,
};

export default connect(stateToProps, actionsToProps)(ChapterNameTextField);
