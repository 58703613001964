import { STREAM_PLAYER_CONFIG_SET, STREAM_START_SUCCESS, STREAM_STOP_SUCCESS, TIMER_SET } from '../../constants/slots';
import { PLAY_OUT_FETCH_SUCCESS, VIDEO_PLAYER_TYPE_SET } from '../../constants/program';

const player = (state = {
    config: '',
    inProgress: false,
    timer: 0,
    count: 0,
}, action) => {
    switch (action.type) {
    case STREAM_PLAYER_CONFIG_SET:
        return {
            ...state,
            config: action.value,
            inProgress: action.inProgress,
            timer: action.inProgress
                ? state.count < 1 ? 20 : 5
                : state.timer,
            count: state.count + 1,
        };
    case VIDEO_PLAYER_TYPE_SET:
        return {
            ...state,
            timer: 5,
        };
    case STREAM_STOP_SUCCESS:
        return {
            ...state,
            config: '',
        };
    case PLAY_OUT_FETCH_SUCCESS:
        return {
            ...state,
            config: action.status === 'dead' ? '' : state.config,
        };
    case STREAM_START_SUCCESS:
        return {
            ...state,
            config: '',
        };
    case TIMER_SET:
        return {
            ...state,
            timer: state.timer - 1,
        };

    default:
        return state;
    }
};

export default player;
