import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { showDialogAddAlbum, showDialogAddAssetsCategory } from '../../actions/asset';
import add from '../../assets/add.svg';
import './index.css';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#696969',
                borderWidth: '1px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
            },
        },
        '& .MuiFormHelperText-root': {
            '&.Mui-error': {
                width: '100%',
                margin: '-6px 0',
            },
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            backgroundColor: 'red !important',
        },
    },
}));

const SelectField = (props) => {
    const onChange = (e) => {
        props.onChange(e.target.value);
    };

    return (
        <TextField
            select
            className={ClassNames(useStyles().root, 'text_field select_field')}
            disabled={props.disabled}
            id={props.id}
            label={props.label}
            margin="normal"
            name={props.name}
            required={props.required ? props.required : false}
            value={props.value}
            variant="outlined"
            onChange={onChange}>
            {
                props.placeholder
                    ? <MenuItem disabled value="none">
                        {props.placeholder}
                    </MenuItem>
                    : null
            }
            {
                (props.showAddButton &&
                    (props.name === 'album'))
                    ? <MenuItem
                        className="add_new"
                        onClick={props.showDialogAddAlbum}>
                        <img
                            alt="toggle icon"
                            className="add_custom_nav"
                            src={add}/>
                    </MenuItem>
                    : props.showAddButton
                        ? <MenuItem
                            className="add_new">
                            <img
                                alt="toggle icon"
                                className="add_custom_nav"
                                src={add}
                                onClick={props.showDialogAddAssetsCategory}/>
                        </MenuItem> : null}
            {props.items &&
            props.items.length > 0 &&
            props.items.map((item) => (
                <MenuItem
                    key={item.key || item.value || item.name}
                    className={props.name === 'day_type' ? 'day_type_class'
                        : props.name === 'channel_select' ? 'channel_field' : ''}
                    value={item.value || item.name}>
                    {item.name}
                </MenuItem>
            ))
            }
        </TextField>
    );
};

SelectField.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string.isRequired,
            value: PropTypes.string,
        }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    showDialogAddAssetsCategory: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    showAddButton: PropTypes.string,
    showDialogAddAlbum: PropTypes.func,
};

const actionsToProps = {
    showDialogAddAssetsCategory,
    showDialogAddAlbum,
};

export default connect(null, actionsToProps)(SelectField);
