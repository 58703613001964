import * as PropTypes from 'prop-types';
import React from 'react';
import VideoPlayer from '../../../../components/YouTubePlayer';
import { URL_YOUTUBE_EMBED } from '../../../../constants/url';

const Player = (props) => {
    const getStreamURL = () => {
        const playStream = props.streams.filter((v) => v._id === props.stream);
        const youTubeId = playStream.length
            ? playStream[0]['youtube_id']
            : '';

        return `${URL_YOUTUBE_EMBED}/${youTubeId}?playsinline=1&iv_load_policy=3&modestbranding=1&rel=0&showinfo=0&autoplay=1`;
    };

    const url = getStreamURL();

    return (
        <VideoPlayer url={url}/>
    );
};

Player.propTypes = {
    stream: PropTypes.string.isRequired,
    streams: PropTypes.array.isRequired,
};

export default Player;
