import { CircularProgress } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';
import IndividualSlotDialog from './IndividualSlotDialog';
import BugsDialog from './Streams/BugsDialog';
import SlotSettingsDialog from './Streams/SlotSettingsDialog';
import GenerateText from './Views';
import Header from './Header';
import './index.css';
import LiveStreamPlayer from './LiveStreamPlayer';
import ScheduledPlaylist from './ScheduledPlaylist';
import Streams from './Streams';
import StudioCalendar from './StudioCalendar';

class Live extends React.Component {
    render () {
        return (
            <div className="program">
                <Header/>
                {
                    !this.props.streamInProgress &&
                    !this.props.channelInProgress
                        ? this.props.stream
                            ? <>
                                <div className="row1">
                                    <LiveStreamPlayer/>
                                    <ScheduledPlaylist/>
                                    <GenerateText/>
                                </div>
                                <div className="divider"/>
                                <div className="row2">
                                    <StudioCalendar/>
                                </div>
                                <div className="row3">
                                    <Streams/>
                                </div>
                            </>
                            : <div className="loader_div">
                            No Active Streams Found. Select Another Channel.
                            </div>
                        : <div className="loader_div">
                            <CircularProgress/>
                        </div>
                }
                <BugsDialog/>
                <SlotSettingsDialog/>
                <IndividualSlotDialog/>
            </div>
        );
    }
}

Live.propTypes = {
    channelInProgress: PropTypes.bool.isRequired,
    stream: PropTypes.string.isRequired,
    streamInProgress: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        stream: state.live.stream.current,
        streamInProgress: state.live.stream.inProgress,
        channelInProgress: state.live.channel.inProgress,
    };
};

export default connect(stateToProps, null)(Live);
