import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setUserForgotPasswordEmail } from '../../actions/forgotPassword';
import TextField from '../../components/TextField';
import { isValidEmail } from '../../utils/validation';

class RecoveryEmailTextField extends React.Component {
    constructor (props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange (value) {
        const valid = isValidEmail(value);

        this.props.onChange(value, valid);
    }

    render () {
        return (
            <TextField
                className={this.props.valid ? '' : 'error_text_field'}
                id="recovery_email_text_field"
                name="email"
                placeholder="Recovery Email"
                type="email"
                valid={this.props.valid}
                value={this.props.value}
                onChange={this.onChange}/>
        );
    }
}

RecoveryEmailTextField.propTypes = {
    valid: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.forgotPassword.email.value,
        valid: state.forgotPassword.email.valid,
    };
};

const actionsToProps = {
    onChange: setUserForgotPasswordEmail,
};

export default connect(stateToProps, actionsToProps)(RecoveryEmailTextField);
