import Axios from 'axios';
import {
    BUGS_ADD_ERROR,
    BUGS_ADD_IN_PROGRESS,
    BUGS_ADD_SET,
    BUGS_ADD_SUCCESS,
    BUGS_DIALOG_HIDE,
    BUGS_DIALOG_SHOW,
    BUGS_SLOT_EDIT,
    BUGS_TAB_VALUE,
    DELETE_BUGS_ERROR,
    DELETE_BUGS_IN_PROGRESS,
    DELETE_BUGS_SUCCESS,
    FETCH_ALL_BUGS_ERROR,
    FETCH_ALL_BUGS_IN_PROGRESS,
    FETCH_ALL_BUGS_SUCCESS,
    FETCH_BUGS_ERROR,
    FETCH_BUGS_IN_PROGRESS,
    FETCH_BUGS_SUCCESS,
    TIME_VALIDATION_SET,
    UPDATE_BUGS_ERROR,
    UPDATE_BUGS_IN_PROGRESS,
    UPDATE_BUGS_SUCCESS,
    UPDATED_BUGS_LIST_SET,
} from '../constants/bugs';
import { fetchBugsURL, getBugsListURL, updateBugsURL } from '../constants/url';

export const showBugDialog = (value, slot) => {
    return {
        type: BUGS_DIALOG_SHOW,
        value,
        slot,
    };
};

export const hideBugDialog = () => {
    return {
        type: BUGS_DIALOG_HIDE,
    };
};

export const setTabValue = (value) => {
    return {
        type: BUGS_TAB_VALUE,
        value,
    };
};

export const setBugsSlotData = (value) => {
    return {
        type: BUGS_SLOT_EDIT,
        value,
    };
};

const fetchAllBugsInProgress = () => {
    return {
        type: FETCH_ALL_BUGS_IN_PROGRESS,
    };
};

const fetchAllBugsSuccess = (value, count, skip, limit, searchKey, sortBy, order, id) => {
    return {
        type: FETCH_ALL_BUGS_SUCCESS,
        value,
        count,
        skip,
        limit,
        searchKey,
        sortBy,
        order,
        id,
    };
};

const fetchAllBugsError = (message) => {
    return {
        type: FETCH_ALL_BUGS_ERROR,
        message,
    };
};

export const fetchAllBugs = (id, skip, limit, searchKey, sortBy, order, token) => (dispatch) => {
    dispatch(fetchAllBugsInProgress());

    const url = getBugsListURL(id, skip, limit, searchKey, sortBy, order);
    Axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            const items = res.data.result.list;
            const count = res.data.result.count;

            dispatch(fetchAllBugsSuccess(items, count, skip, limit, searchKey, sortBy, order, id));
        })
        .catch((error) => {
            dispatch(fetchAllBugsError(error.response ? error.response.data.message : error.message));
        });
};

const fetchBugsInProgress = () => {
    return {
        type: FETCH_BUGS_IN_PROGRESS,
    };
};

const fetchBugsSuccess = (value) => {
    return {
        type: FETCH_BUGS_SUCCESS,
        value,
    };
};

const fetchBugsError = (message) => {
    return {
        type: FETCH_BUGS_ERROR,
        message,
    };
};

export const fetchBugs = (channelID, streamID, slotID, token) => (dispatch) => {
    dispatch(fetchBugsInProgress());

    const url = fetchBugsURL(channelID, streamID, slotID);
    Axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            dispatch(fetchBugsSuccess(res.data.result.overlays));
        })
        .catch((error) => {
            dispatch(fetchBugsError(error.response ? error.response.data.message : error.message));
        });
};

export const setAddBugs = (value) => {
    return {
        type: BUGS_ADD_SET,
        value,
    };
};

const addBugsInProgress = () => {
    return {
        type: BUGS_ADD_IN_PROGRESS,
    };
};

const addBugsSuccess = (value) => {
    return {
        type: BUGS_ADD_SUCCESS,
        value,
    };
};

const addBugsError = (message) => {
    return {
        type: BUGS_ADD_ERROR,
        message,
    };
};

export const addBugs = (channelID, streamID, slotID, data, token, cb) => (dispatch) => {
    dispatch(addBugsInProgress());

    const url = fetchBugsURL(channelID, streamID, slotID);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            dispatch(addBugsSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(addBugsError(error.response ? error.response.data.message : error.message));
            cb(error);
        });
};

export const setUpdatedBugsList = (value) => {
    return {
        type: UPDATED_BUGS_LIST_SET,
        value,
    };
};

const updateBugsInProgress = () => {
    return {
        type: UPDATE_BUGS_IN_PROGRESS,
    };
};

const updateBugsSuccess = (value) => {
    return {
        type: UPDATE_BUGS_SUCCESS,
        value,
    };
};

const updateBugsError = (message) => {
    return {
        type: UPDATE_BUGS_ERROR,
        message,
    };
};

export const updateBugs = (channelID, streamID, slotID, ID, data, token, cb) => (dispatch) => {
    dispatch(updateBugsInProgress());

    const url = updateBugsURL(channelID, streamID, slotID, ID);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            dispatch(updateBugsSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(updateBugsError(error.response ? error.response.data.message : error.message));
            cb(error);
        });
};

const deleteBugInProgress = () => {
    return {
        type: DELETE_BUGS_IN_PROGRESS,
    };
};

const deleteBugSuccess = (value) => {
    return {
        type: DELETE_BUGS_SUCCESS,
        value,
    };
};

const deleteBugError = (message) => {
    return {
        type: DELETE_BUGS_ERROR,
        message,
    };
};

export const deleteBug = (channelID, streamID, slotID, ID, token, cb) => (dispatch) => {
    dispatch(deleteBugInProgress());

    const url = updateBugsURL(channelID, streamID, slotID, ID);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            dispatch(deleteBugSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(deleteBugError(error.response ? error.response.data.message : error.message));
            cb(error);
        });
};

export const setTimeValidation = (value) => {
    return {
        type: TIME_VALIDATION_SET,
        value,
    };
};
