import { InputAdornment, makeStyles } from '@material-ui/core';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setSearchAssets } from '../../../actions/chapterMarking';
import searchIcon from '../../../assets/search.svg';
import TextField from '../../../components/TextField/InputProps';
import Filter from './filter';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#696969',
                borderWidth: '1px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
            },
        },
        '& .MuiFormHelperText-root': {
            '&.Mui-error': {
                width: '100%',
                margin: '-6px 0',
            },
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            backgroundColor: 'red !important',
        },
    },
}));

const SearchTextField = (props) => {
    return (
        <TextField
            className={ClassNames(useStyles().root, 'search_bar')}
            id="asset_search_text_field"
            inputProps={{
                startAdornment: (
                    <InputAdornment className="left_icon" position="start">
                        <img alt="search" src={searchIcon}/>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <Filter/>
                    </InputAdornment>
                ),
            }}
            name="search"
            placeholder="Search your assets and tag them"
            value={props.value}
            variant="outlined"
            onChange={props.onChange}/>
    );
};

SearchTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.chapterMarking.searchAsset,
    };
};

const actionsToProps = {
    onChange: setSearchAssets,
};

export default connect(stateToProps, actionsToProps)(SearchTextField);
