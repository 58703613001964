import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import { setDefaultOption } from '../../../actions/interactiveVideo';

const AnswerTextField = (props) => {
    const onChange = (value) => {
        props.onChange(value, '');
    };

    return (
        <TextField
            id="answer_text_field"
            name="answer"
            placeholder="Answer"
            type="text"
            value={props.value}
            onChange={onChange}/>
    );
};

AnswerTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.interactiveVideo.options.default,
    };
};

const actionsToProps = {
    onChange: setDefaultOption,
};

export default connect(stateToProps, actionsToProps)(AnswerTextField);
