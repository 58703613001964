import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../../../components/Icon';

const CloseButton = (props) => {
    return (
        <Button
            className="close_button"
            variant="outlined"
            onClick={props.onClose}>
            Close
            <Icon className="close" icon="close"/>
        </Button>
    );
};

CloseButton.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default CloseButton;
