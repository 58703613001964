import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideDialogAddAsset, hideDialogEditAsset } from '../../../../actions/asset';
import '../../DialogAddAsset/index.css';
import NameTextField from '../../DialogAddAsset/NameTextField';
import SourceTypeSelectField from '../../DialogAddAsset/SourceTypeSelectField';
import UpdateButton from '../../DialogAddAsset/UpdateButton';
import UrlTextField from '../../DialogAddAsset/UrlTextField';
import TagsTextField from '../../../AddAssets/Tabs/MetadataForDistribution/TagsTextField';
import AddButton from './AddButton';

const DialogAddVideoAsset = (props) => {
    const onClickCancel = () => {
        props.hideDialogEditAsset();
        props.hideDialogAddAsset();
    };

    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            className="dialog"
            open={props.open}
            onClose={onClickCancel}>
            <DialogTitle
                className="title"
                id="alert-dialog-title">
                Add Asset
            </DialogTitle>
            <DialogContent className="content">
                <NameTextField/>
                <TagsTextField/>
            </DialogContent>
            <div className="border"></div>
            <DialogTitle
                className="title file_title"
                id="alert-dialog-title">
                <p>
                    File
                </p>
            </DialogTitle>
            <DialogContent className="content" style={{ height: '100px' }}>
                <SourceTypeSelectField/>
                {
                    props.sourceTypeValue
                        ? <UrlTextField label={props.sourceTypeValue}/>
                        : ''
                }
            </DialogContent>
            <DialogActions className="action">
                <Button
                    className="cancel"
                    color="primary"
                    onClick={onClickCancel}>
                    Cancel
                </Button>
                {
                    props.update ? <UpdateButton type="text"/> : <AddButton type="text"/>
                }
            </DialogActions>
        </Dialog>
    );
};

DialogAddVideoAsset.propTypes = {
    hideDialogAddAsset: PropTypes.func.isRequired,
    hideDialogEditAsset: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    sourceTypeValue: PropTypes.string.isRequired,
    update: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.asset.new.dialog.status,
        update: state.asset.new.dialog.data,
        sourceTypeValue: state.asset.new.sourceTypeValue,
        items: state.asset.sourceType.list,
    };
};

const actionsToProps = {
    hideDialogAddAsset,
    hideDialogEditAsset,
};

export default connect(stateToProps, actionsToProps)(DialogAddVideoAsset);
