import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchChannelLiveStreams, fetchChannels, setAddChannel } from '../../../../actions/channel';
import SelectField from '../../../../components/SelectField';

class ChannelSelectField extends React.Component {
    constructor (props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount () {
        this.props.fetchChannels((cb) => {
            if (cb) {
                return;
            }
            if (this.props.channel) {
                this.props.fetchChannelLiveStreams(this.props.channel,
                    'live',
                    () => {
                    });
            }
        });
    }

    onChange (value) {
        this.props.setAddChannel(value);
        this.props.fetchChannelLiveStreams(value,
            'live',
            () => {
            });
    }

    render () {
        return (
            <div>
                <SelectField
                    id="channel_select_field"
                    items={this.props.channels.map((v) => {
                        return {
                            name: v.name,
                            value: v._id,
                        };
                    })}
                    // label="Select Channel"
                    name="channel_select"
                    placeholder="Select Channel"
                    value={localStorage.getItem('c')
                        ? localStorage.getItem('c')
                        : this.props.channel}
                    onChange={(value) => this.onChange(value)}/>
            </div>
        );
    }
}

ChannelSelectField.propTypes = {
    channel: PropTypes.string.isRequired,
    channels: PropTypes.array.isRequired,
    fetchChannelLiveStreams: PropTypes.func.isRequired,
    fetchChannels: PropTypes.func.isRequired,
    setAddChannel: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        channel: state.live.channel.current,
        channels: state.live.channel.list,
    };
};

const actionsToProps = {
    setAddChannel,
    fetchChannelLiveStreams,
    fetchChannels,
};

export default connect(stateToProps, actionsToProps)(ChannelSelectField);
