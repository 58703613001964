import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setSlotDate } from '../../../../../actions/slot';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#696969',
                borderWidth: '1px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
            },
        },
        '& .MuiFormHelperText-root': {
            '&.Mui-error': {
                width: '100%',
                margin: '-6px 0',
            },
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            backgroundColor: 'red !important',
        },
    },
}));

const DateSelectField = (props) => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
                animateYearScrolling
                className={ClassNames(useStyles().root, 'time_picker text_field')}
                format="ddd D MMM"
                value={props.value}
                onChange={props.onChange}/>
        </MuiPickersUtilsProvider>
    );
};

DateSelectField.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.live.individualSlot.date,
    };
};

const actionToProps = {
    onChange: setSlotDate,
};

export default connect(stateToProps, actionToProps)(DateSelectField);
