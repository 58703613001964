import { CircularProgress } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import VideoJS from 'video.js';
import 'video.js/dist/video-js.css';
import { getPreviewUrl } from '../../../../../actions/asset';
import { fetchDeviceID, fetchWatchURL } from '../../../../../actions/videoAssets';
import Icon from '../../../../../components/Icon';
import Player from '../../../../../components/Player';
import './index.css';

const videoJSOptions = {
    autoplay: false,
    loop: false,
    width: 500,
    height: 450,
    controls: true,
};

class InteractiveVideo extends Component {
    constructor (props) {
        super(props);

        this.initializePlayer = this.initializePlayer.bind(this);
        this.fetchAsset = this.fetchAsset.bind(this);
        this.state = {
            config: null,
        };
    }

    initializePlayer (ref) {
        this.player = VideoJS(ref, videoJSOptions);

        if (this.player) {
            this.player.on('loadeddata', () => {
            });
        }
    }

    componentDidMount () {
        this.fetchAsset();
    }

    componentDidUpdate (pp, ps, ss) {
        if (pp.data.program !== this.props.data.program) {
            this.setState({
                config: null,
            });
            this.fetchAsset();
        }
    }

    fetchAsset () {
        if (this.props.deviceID && Object.keys(this.props.deviceID).length &&
            this.props.data && this.props.data.program && this.props.data.program.asset &&
            this.props.data.program.asset._ && this.props.data.program.asset._._id) {
            this.props.fetchWatchURL(this.props.data.program.asset._._id, this.props.deviceID, (cb) => {
                if (cb) {
                    const url = cb.assetUrl;

                    if (url) {
                        this.props.getPreviewUrl(url, (format) => {
                            const config = {
                                sources: [{
                                    type: format === 'mp4'
                                        ? 'video/mp4'
                                        : 'application/x-mpegURL',
                                    src: format === 'mp4'
                                        ? url.replace('m3u8', 'mp4')
                                        : url,
                                }],
                            };

                            this.setState({
                                config: JSON.stringify(config),
                            });
                        });
                    }
                }
            });
        } else if (this.props.data && this.props.data.program && this.props.data.program.asset &&
            this.props.data.program.asset._ && this.props.data.program.asset._._id) {
            this.props.fetchDeviceID((deviceID) => {
                if (deviceID) {
                    this.props.fetchWatchURL(this.props.data.program.asset._._id, deviceID, (cb) => {
                        if (cb) {
                            const url = cb.assetUrl;

                            if (url) {
                                this.props.getPreviewUrl(url, (format) => {
                                    const config = {
                                        sources: [{
                                            type: format === 'mp4'
                                                ? 'video/mp4'
                                                : 'application/x-mpegURL',
                                            src: format === 'mp4'
                                                ? url.replace('m3u8', 'mp4')
                                                : url,
                                        }],
                                    };

                                    this.setState({
                                        config: JSON.stringify(config),
                                    });
                                });
                            }
                        }
                    });
                }
            });
        }
    }

    componentWillUnmount () {
        if (this.player) {
            this.player.dispose();
        }
    }

    render () {
        const { config } = this.state;

        return (
            <div className="individual_video">
                <p className="title">
                    <Icon className="play" icon="play"/>
                    {this.props.data && this.props.data.program && this.props.data.program.asset &&
                    this.props.data.program.asset._ && this.props.data.program.asset._.name}
                </p>
                <div className="player">
                    {config
                        ? <Player
                            initializePlayer={this.initializePlayer}
                            setup={config}/>
                        : <CircularProgress/>}
                </div>
            </div>
        );
    }
}

InteractiveVideo.propTypes = {
    data: PropTypes.object.isRequired,
    deviceID: PropTypes.string.isRequired,
    deviceIDInProgress: PropTypes.bool.isRequired,
    fetchAsset: PropTypes.func.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    fetchWatchURL: PropTypes.func.isRequired,
    getPreviewUrl: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        deviceID: state.asset.videoAssets.deviceID.id,
        deviceIDProgress: state.asset.videoAssets.deviceID.inProgress,
        data: state.live.individualSlot.data,
    };
};

const actionsToProps = {
    fetchWatchURL,
    fetchDeviceID,
    getPreviewUrl,
};

export default withRouter(connect(stateToProps, actionsToProps)(InteractiveVideo));
