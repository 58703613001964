import { Button } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toggleSidebarDrawer } from '../../actions/sidebar';
import logo from '../../assets/logo.svg';
import AddAssets from '../AddAssets';
import CreateButton from './CreateButton';
import ExpansionPanel from './ExpansionPanel';
import './index.css';

const Sidebar = (props) => {
    const handleExpand = () => {
        props.onClick();
    };

    return (
        <div className={'scroll_bar_div side_bar ' + (props.open ? 'side_bar_open' : 'side_bar_closed')}>
            <div className="logo_section">
                <div className="hover_side_bar logo_full_div">
                    <img
                        alt="logo"
                        className="img_logo"
                        src={logo}/>
                </div>
                <Button
                    aria-label="Open drawer"
                    className="toggle_button"
                    color="inherit"
                    onClick={props.onClick}
                    onMouseEnter={handleExpand}>
                    <Menu/>
                </Button>
            </div>
            <CreateButton/>
            <ExpansionPanel/>
            <AddAssets/>
        </div>
    );
};

Sidebar.propTypes = {
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.sidebar.drawer,
    };
};

const actionToProps = {
    onClick: toggleSidebarDrawer,
};

export default connect(stateToProps, actionToProps)(Sidebar);
