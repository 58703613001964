import {
    ASSET_ADD_SUCCESS,
    DIALOG_ADD_ASSET_HIDE,
    DIALOG_EDIT_ASSET_HIDE,
    EDITABLE_ASSET_DATA,
} from '../../constants/asset';
import {
    ACTIVE_TAB_SET,
    ADD_DAY_TYPE_SET,
    CURRENT_UPDATING_SLOT_SET,
    DIALOG_UPDATE_PROGRAM_HIDE,
    DIALOG_UPDATE_PROGRAM_SHOW,
    PLAY_OUT_FETCH_ERROR,
    PLAY_OUT_FETCH_IN_PROGRESS,
    PLAY_OUT_FETCH_SUCCESS,
    PLAY_OUT_REFRESH_SUCCESS,
    PROGRAM_ADD_ERROR,
    PROGRAM_ADD_IN_PROGRESS,
    PROGRAM_ADD_SUCCESS,
    PROGRAM_DELETE_ERROR,
    PROGRAM_DELETE_IN_PROGRESS,
    PROGRAM_DELETE_SUCCESS,
    PROGRAM_OVERLAY_ADD_ERROR,
    PROGRAM_OVERLAY_ADD_IN_PROGRESS,
    PROGRAM_OVERLAY_ADD_SUCCESS,
    PROGRAM_OVERLAY_DELETE_ERROR,
    PROGRAM_OVERLAY_DELETE_IN_PROGRESS,
    PROGRAM_OVERLAY_DELETE_SUCCESS,
    PROGRAM_OVERLAY_UPDATE_ERROR,
    PROGRAM_OVERLAY_UPDATE_IN_PROGRESS,
    PROGRAM_OVERLAY_UPDATE_SUCCESS,
    PROGRAM_UPDATE_ERROR,
    PROGRAM_UPDATE_IN_PROGRESS,
    PROGRAM_UPDATE_SUCCESS,
    PROGRAMS_CLEAR_ERROR,
    PROGRAMS_CLEAR_IN_PROGRESS,
    PROGRAMS_CLEAR_SUCCESS,
    PROGRAMS_FETCH_ERROR,
    PROGRAMS_FETCH_IN_PROGRESS,
    PROGRAMS_FETCH_SUCCESS,
    PROGRAMS_FILL_ERROR,
    PROGRAMS_FILL_IN_PROGRESS,
    PROGRAMS_FILL_SUCCESS,
    PROGRAMS_UPDATE_ERROR,
    PROGRAMS_UPDATE_IN_PROGRESS,
    PROGRAMS_UPDATE_SET,
    PROGRAMS_UPDATE_SUCCESS,
    VIDEO_PLAYER_TYPE_SET,
} from '../../constants/program';

export const program = (state = {
    list: {},
    inProgress: false,
    updateInProgress: false,
    deleteInProgress: false,
    addInProgress: false,
}, action) => {
    switch (action.type) {
    case PROGRAMS_FETCH_IN_PROGRESS:
    case PROGRAMS_FILL_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROGRAMS_FETCH_SUCCESS:
    case PROGRAMS_FILL_SUCCESS:
        return {
            ...state,
            // merging objects
            list: { ...state.list, ...action.programs },
            inProgress: false,
        };
    case ACTIVE_TAB_SET:
        return {
            ...state,
            list: {},
        };
    case PROGRAMS_FETCH_ERROR:
    case PROGRAMS_FILL_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case PROGRAM_ADD_IN_PROGRESS:
        return {
            ...state,
            addInProgress: true,
        };
    case PROGRAM_ADD_SUCCESS: {
        const list = state.list;
        if (action.program && action.program.length > 0) {
            action.program.map((value) => {
                list[value._id] = value;
            });
        }

        return {
            ...state,
            list: { ...list },
            addInProgress: false,
        };
    }
    case PROGRAMS_UPDATE_SET:
        return {
            ...state,
            list: action.value,
        };
    case PROGRAM_ADD_ERROR:
        return {
            ...state,
            addInProgress: false,
        };
    case PROGRAM_UPDATE_IN_PROGRESS:
    case PROGRAMS_UPDATE_IN_PROGRESS:
        return {
            ...state,
            updateInProgress: true,
        };
    case PROGRAM_UPDATE_SUCCESS:
    case PROGRAMS_UPDATE_SUCCESS:
        return {
            ...state,
            updateInProgress: false,
        };

    case PROGRAM_UPDATE_ERROR:
    case PROGRAMS_UPDATE_ERROR:
        return {
            ...state,
            updateInProgress: false,
        };
    case PROGRAM_DELETE_IN_PROGRESS:
    case PROGRAMS_CLEAR_IN_PROGRESS:
        return {
            ...state,
            deleteInProgress: true,
        };
    case PROGRAM_DELETE_SUCCESS:
    case PROGRAMS_CLEAR_SUCCESS:
    case PROGRAMS_CLEAR_ERROR:
        return {
            ...state,
            deleteInProgress: false,
        };
    case PROGRAM_DELETE_ERROR:
        return {
            ...state,
            deleteInProgress: false,
        };
    case PROGRAM_OVERLAY_ADD_SUCCESS:
        return {
            ...state,
            list: state.list.map((program) => {
                if (program._id === action.program._id) {
                    return action.program;
                } else {
                    return program;
                }
            }),
            inProgress: false,
        };
    case PROGRAM_OVERLAY_DELETE_SUCCESS:
        return {
            ...state,
            list: state.list.map((program) => {
                if (program._id === action.program.programId) {
                    program.overlays = program.overlays.filter((v) => v._id !== action.program.overlayId);
                    return program;
                } else {
                    return program;
                }
            }),
            inProgress: false,
        };
    default:
        return state;
    }
};

export const overlay = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case PROGRAM_OVERLAY_ADD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROGRAM_OVERLAY_ADD_SUCCESS:
        return {
            ...state,
            list: action.program,
            inProgress: false,
        };
    case PROGRAM_OVERLAY_ADD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case PROGRAM_OVERLAY_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROGRAM_OVERLAY_UPDATE_SUCCESS:
        return {
            ...state,
            list: action.program,
            inProgress: false,
        };
    case PROGRAM_OVERLAY_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case PROGRAM_OVERLAY_DELETE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROGRAM_OVERLAY_DELETE_SUCCESS:
        return {
            ...state,
            list: action.program,
            inProgress: false,
        };
    case PROGRAM_OVERLAY_DELETE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export const playOut = (state = {
    stream: {},
    status: '',
    inProgress: false,
}, action) => {
    switch (action.type) {
    case PLAY_OUT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PLAY_OUT_FETCH_SUCCESS:
        return {
            ...state,
            stream: action.stream,
            status: action.status,
            inProgress: false,
        };
    case PLAY_OUT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case PLAY_OUT_REFRESH_SUCCESS: {
        return {
            ...state,
            stream: action.stream,
        };
    }
    default:
        return state;
    }
};

export const lastUpdated = (state = {
    time: new Date(),
    inProgress: false,
}, action) => {
    switch (action.type) {
    case PROGRAMS_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROGRAM_ADD_SUCCESS:
    case PROGRAM_DELETE_SUCCESS:
    case PROGRAM_UPDATE_SUCCESS:
    case PROGRAMS_UPDATE_SUCCESS:
        return {
            ...state,
            time: new Date(),
            inProgress: false,
        };
    case PROGRAMS_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export const updateProgram = (state = false, action) => {
    switch (action.type) {
    case DIALOG_UPDATE_PROGRAM_SHOW:
        return true;
    case DIALOG_UPDATE_PROGRAM_HIDE:
        return false;
    default:
        return state;
    }
};

export const dayTypeValue = (state = '', action) => {
    switch (action.type) {
    case ADD_DAY_TYPE_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
    case DIALOG_EDIT_ASSET_HIDE:
    case ASSET_ADD_SUCCESS:
        return '';
    case EDITABLE_ASSET_DATA:
        return action.value && action.value.file && action.value.file.download && action.value.file.download.source
            ? action.value.file.download.source : '';
    default:
        return state;
    }
};

export const deleteProgram = (state = {
    inProgress: false,
}, action) => {
    switch (action.type) {
    case PROGRAM_DELETE_IN_PROGRESS:
        return {
            inProgress: true,
        };
    case PROGRAM_DELETE_SUCCESS:
    case PROGRAM_DELETE_ERROR:
        return {
            inProgress: false,
        };
    default:
        return state;
    }
};

export const videoPlayerType = (state = 'nativeStream', action) => {
    if (action.type === VIDEO_PLAYER_TYPE_SET) {
        return action.value;
    }

    return state;
};

export const currentUpdatingSlot = (state = '', action) => {
    if (action.type === CURRENT_UPDATING_SLOT_SET) {
        return action.value;
    }

    return state;
};
