import * as PropTypes from 'prop-types';
import React from 'react';
import TextField from '../../../components/TextField/InputProps';

const AnswerTextField = (props) => {
    const onChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            className="description_text_field"
            id={props.id + '_text_field'}
            multiline={true}
            name={props.id}
            placeholder="Enter Answer"
            value={props.value}
            variant="outlined"
            onChange={onChange}/>
    );
};

AnswerTextField.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default AnswerTextField;
