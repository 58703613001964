import { ListItemText, MenuItem, Popover } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from '../../../actions/logout';
import './index.css';
import user from '../../../assets/user.svg';

const PopoverProfile = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogOut = () => {
        props.logout();
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'profile-popover' : undefined;

    return (
        <div className="profile_section">
            <img
                alt="user"
                src={user}
                onClick={handleClick}/>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className="popover_profile"
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}>
                <div className="section_profile">
                    <MenuItem
                        className="tab"
                        component={Link}
                        to="/accounts"
                        onClick={handleClose}>
                        <ListItemText className="pl-0" primary="Account"/>
                    </MenuItem>
                    <MenuItem
                        className="tab"
                        component={Link}
                        to="/login"
                        onClick={() => handleLogOut()}>
                        <ListItemText className="pl-0" primary="Logout"/>
                    </MenuItem>
                </div>
            </Popover>
        </div>
    );
};

PopoverProfile.propTypes = {
    logout: PropTypes.func.isRequired,
};

const actionsToProps = {
    logout: logoutUser,
};

export default connect(null, actionsToProps)(PopoverProfile);
