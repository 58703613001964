import { Button } from '@material-ui/core';
import React from 'react';
import MediaNftIDTextField from './MediaNftIDTextField';

const MediaNFT = () => {
    return (
        <div className="action_div">
            <p className="heading_text">
                Media NFT
            </p>
            <p className="note">
                create a reference of your asset on blockchain
            </p>
            <div className="flex_row">
                <MediaNftIDTextField/>
                <Button
                    className="button1">
                    Mint Media NFT
                </Button>
            </div>
        </div>
    );
};

export default MediaNFT;
