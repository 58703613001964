import React from 'react';
import './app.css';
import Snackbar from './containers/Snackbar';
import Router from './Router';

const App = () => {
    return (
        <div className="ff_studio">
            <Router/>
            <Snackbar/>
        </div>
    );
};

export default App;
