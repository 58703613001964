import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setSearchAssets } from '../../../../../actions/slot';
import TextField from '../../../../../components/TextField';

const SearchAssetTextField = (props) => {
    return (
        <TextField
            id="search_assets_text_field"
            name="search_asset"
            placeholder="Search"
            value={props.value}
            onChange={props.onChange}/>
    );
};

SearchAssetTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.live.individualSlot.searchAssets,
    };
};

const actionToProps = {
    onChange: setSearchAssets,
};

export default connect(stateToProps, actionToProps)(SearchAssetTextField);
