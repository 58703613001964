import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { deleteTemplate, hideDeleteTemplateDialog, fetchSlotTemplate } from '../../../../../../actions/dynamicPrograms';
import './index.css';

const DeleteDialog = (props) => {
    const handleDelete = () => {
        const token = localStorage.getItem('authorizationToken');

        props.deleteTemplate(token, props.item._id, (error) => {
            if (!error) {
                props.fetchSlotTemplate();
                props.hide();
            }
        });
    };

    return (
        <Dialog
            aria-describedby="delete-template-dialog-description"
            aria-labelledby="delete-template-dialog-title"
            className="delete_template_dialog"
            open={props.open}
            onClose={props.hide}>
            <DialogContent className="content">
                <p>Are you sure you want to delete “{props.item && props.item.name}”</p>
            </DialogContent>
            <DialogActions className="action_content">
                <Button
                    className="cancel_button"
                    onClick={props.hide}>
                    Cancel
                </Button>
                <Button
                    autoFocus
                    className="add_slot"
                    onClick={handleDelete}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DeleteDialog.propTypes = {
    deleteTemplate: PropTypes.func.isRequired,
    fetchSlotTemplate: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        item: state.live.dynamic.deleteTemplate.item,
        open: state.live.dynamic.deleteTemplate.open,
    };
};

const actionToProps = {
    deleteTemplate,
    fetchSlotTemplate,
    hide: hideDeleteTemplateDialog,
};

export default connect(stateToProps, actionToProps)(DeleteDialog);
