import { combineReducers } from 'redux';
import {
    ADD_ASSET_CATEGORY_NEW_LABEL,
    ADD_FILE_SET,
    ADD_GENRE_NEW_LABEL,
    ASSET_ADD_SUCCESS,
    ASSET_CATEGORIES_FETCH_ERROR,
    ASSET_CATEGORIES_FETCH_IN_PROGRESS,
    ASSET_CATEGORIES_FETCH_SUCCESS,
    ASSET_CATEGORIES_LIST,
    ASSET_CATEGORIES_LIST_ENCODED,
    ASSET_CATEGORY_DELETE_SUCCESS,
    ASSET_CATEGORY_TABLE_LIST,
    ASSET_DELETE_ERROR,
    ASSET_DELETE_IN_PROGRESS,
    ASSET_DELETE_SUCCESS,
    ASSET_FETCH_SUCCESS,
    ASSET_FILE_ADD_SUCCESS,
    ASSET_FILE_ENCODE_SUCCESS,
    ASSET_FILES_IN_PROGRESS,
    ASSET_FILES_LIST,
    ASSET_GENRES_FETCH_ERROR,
    ASSET_GENRES_FETCH_IN_PROGRESS,
    ASSET_GENRES_FETCH_SUCCESS,
    ASSET_SOURCE_TYPE_FETCH_IN_PROGRESS,
    ASSET_SOURCE_TYPE_FETCH_SUCCESS,
    ASSET_TAGS_FETCH_ERROR,
    ASSET_TAGS_FETCH_IN_PROGRESS,
    ASSET_TAGS_FETCH_SUCCESS,
    ASSET_UPDATE_SUCCESS,
    ASSETS_DATA_FETCH_ERROR,
    ASSETS_DATA_FETCH_IN_PROGRESS,
    ASSETS_DATA_FETCH_SUCCESS,
    ASSETS_FETCH_ERROR,
    ASSETS_FETCH_IN_PROGRESS,
    ASSETS_FETCH_SUCCESS,
    ASSETS_OVERVIEW_DATA_FETCH_ERROR,
    ASSETS_OVERVIEW_DATA_FETCH_IN_PROGRESS,
    ASSETS_OVERVIEW_DATA_FETCH_SUCCESS,
    CHANGED_FILTER,
    CURRENT_ASSET_SET,
    DELETE_ASSET_THUMBNAIL_SUCCESS,
    DIALOG_ACTION_MENU_HIDE,
    DIALOG_ACTION_MENU_SHOW,
    DIALOG_ADD_ALBUM_HIDE,
    DIALOG_ADD_ALBUM_SHOW,
    DIALOG_ADD_ASSET_CATEGORY_HIDE,
    DIALOG_ADD_ASSET_CATEGORY_SHOW,
    DIALOG_ADD_ASSET_HIDE,
    DIALOG_ADD_GENRE_HIDE,
    DIALOG_ADD_GENRE_SHOW,
    DIALOG_ADD_VIDEO_HIDE,
    DIALOG_ADD_VIDEO_SHOW,
    DIALOG_CONFIRM_GENRE_HIDE,
    DIALOG_CONFIRM_GENRE_SHOW,
    DIALOG_CONFIRM_HIDE,
    DIALOG_CONFIRM_SHOW,
    DIALOG_DELETE_THUMB_HIDE,
    DIALOG_DELETE_THUMB_SHOW,
    DIALOG_EDIT_ASSET_SHOW,
    DIALOG_UPDATE_THUMB_HIDE,
    DIALOG_UPDATE_THUMB_SHOW,
    DIALOG_UPLOAD_THUMB_HIDE,
    DIALOG_UPLOAD_THUMB_SHOW,
    DIALOG_VIEW_THUMB_HIDE,
    DIALOG_VIEW_THUMB_SHOW,
    DOWNLOAD_PROGRESS_SET,
    EDITABLE_ASSET_DATA,
    ENCODED_ASSETS_OVERVIEW_DATA_FETCH_SUCCESS,
    FILE_DOWNLOAD_ERROR,
    FILE_DOWNLOAD_IN_PROGRESS,
    FILE_DOWNLOAD_SUCCESS,
    FILE_UPLOAD_ERROR,
    FILE_UPLOAD_IN_PROGRESS,
    FILE_UPLOAD_SUCCESS,
    GENRE_DELETE_SUCCESS,
    NEW_ASSET_CATEGORY_ADD_SUCCESS,
    NEW_GENRE_ADD_SUCCESS,
    POPOVER_FILTER_HIDE,
    POPOVER_FILTER_SHOW,
    QUICK_VIEW_ASSET_DATA,
    QUICK_VIEW_MOVIE_ASSET_HIDE,
    QUICK_VIEW_MOVIE_ASSET_SHOW,
    SHOW_PREVIEW_SET,
    TAGS_LIST_FETCH_ERROR,
    TAGS_LIST_FETCH_IN_PROGRESS,
    TAGS_LIST_FETCH_SUCCESS,
    UPLOAD_ASSET_THUMBNAIL_ERROR,
    UPLOAD_ASSET_THUMBNAIL_IN_PROGRESS,
    UPLOAD_ASSET_THUMBNAIL_SUCCESS,
    UPLOAD_FILE_SET,
    UPLOAD_PROGRESS_SET,
    UPLOADED_THUMBS_LIST,
} from '../../constants/asset';
import {
    DEFAULT_COUNT,
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../../constants/url';
import types from '../../dummy/assets';
import audioAssets from './audioAssets';
import _new from './new';
import videoAssets from './videoAssets';

const fileDownload = require('js-file-download');
const categoryTableList = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_CATEGORY_TABLE_LIST:
        // case ASSET_FILE_ADD_SUCCESS:
        return {
            ...state,
            list: action.list,
        };
    default:
        return state;
    }
};

const quickView = (state = {
    display: false,
    data: {},
}, action) => {
    if (action.type === QUICK_VIEW_MOVIE_ASSET_SHOW) {
        return {
            ...state,
            display: true,
            data: action.value,
        };
    } else if (action.type === QUICK_VIEW_MOVIE_ASSET_HIDE) {
        return {
            ...state,
            display: false,
            data: {},
        };
    } else if (action.type === ASSET_FETCH_SUCCESS) {
        return {
            ...state,
            data: action.item,
        };
    }

    return state;
};

const genres = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_GENRES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_GENRES_FETCH_SUCCESS:
        return {
            list: action.items,
            inProgress: false,
        };
    case ASSET_GENRES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const sourceType = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_SOURCE_TYPE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_SOURCE_TYPE_FETCH_SUCCESS:
        return {
            list: action.items,
            inProgress: false,
        };
    case DIALOG_ADD_ASSET_HIDE:
        return {
            list: [],
        };
    default:
        return state;
    }
};

const categories = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_CATEGORIES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_CATEGORIES_FETCH_SUCCESS:
        return {
            list: action.items,
            inProgress: false,
        };
    case ASSET_CATEGORIES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const dialogAddAssetCategory = (state = false, action) => {
    switch (action.type) {
    case DIALOG_ADD_ASSET_CATEGORY_SHOW:
        return true;
    case DIALOG_ADD_ASSET_CATEGORY_HIDE:
        return false;
    default:
        return state;
    }
};

const dialogAddGenre = (state = false, action) => {
    switch (action.type) {
    case DIALOG_ADD_GENRE_SHOW:
        return true;
    case DIALOG_ADD_GENRE_HIDE:
        return false;
    default:
        return state;
    }
};

const dialogAddAlbum = (state = false, action) => {
    switch (action.type) {
    case DIALOG_ADD_ALBUM_SHOW:
        return true;
    case DIALOG_ADD_ALBUM_HIDE:
        return false;
    default:
        return state;
    }
};

const dialogAddVideo = (state = false, action) => {
    switch (action.type) {
    case DIALOG_ADD_VIDEO_SHOW:
        return true;
    case DIALOG_ADD_VIDEO_HIDE:
        return false;
    default:
        return state;
    }
};

const dialogActionConfirm = (state = {
    open: false,
    id: {},
    type: '',
}, action) => {
    switch (action.type) {
    case DIALOG_ACTION_MENU_SHOW:
        return {
            ...state,
            open: true,
            id: action.id,
            type: action.assetType,
        };
    case DIALOG_ACTION_MENU_HIDE:
    case ASSET_DELETE_SUCCESS:
        return {
            open: false,
            id: {},
            type: '',
        };
    default:
        return state;
    }
};

const dialogConfirm = (state = false, action) => {
    switch (action.type) {
    case DIALOG_CONFIRM_SHOW:
    case DIALOG_CONFIRM_GENRE_SHOW:
        return true;
    case DIALOG_CONFIRM_HIDE:
    case DIALOG_CONFIRM_GENRE_HIDE:
    case ASSET_CATEGORY_DELETE_SUCCESS:
    case GENRE_DELETE_SUCCESS:
        return false;
    default:
        return state;
    }
};

const dialogConfirmId = (state = '', action) => {
    switch (action.type) {
    case DIALOG_CONFIRM_SHOW:
        return action.id;
    case DIALOG_CONFIRM_HIDE:
        return null;
    default:
        return state;
    }
};

const dialogConfirmGenreId = (state = '', action) => {
    switch (action.type) {
    case DIALOG_CONFIRM_GENRE_SHOW:
        return action.id;
    case DIALOG_CONFIRM_GENRE_HIDE:
        return null;
    default:
        return state;
    }
};

const assetFilesList = (state = {
    list: [],
    inProgress: false,
}, action) => {
    if (action.type === ASSET_FILES_LIST) {
        return {
            ...state,
            list: action.list,
        };
    }

    return state;
};

const assetCategoriesList = (state = {
    list: [],
    isProgress: false,
    encodedList: [],
}, action) => {
    switch (action.type) {
    case ASSET_CATEGORIES_LIST:
        return {
            ...state,
            list: action.list,
        };
    case ASSET_CATEGORIES_LIST_ENCODED:
        return {
            ...state,
            encodedList: action.list,
        };
    default:
        return state;
    }
};

const newAssetCategory = (state = '', action) => {
    switch (action.type) {
    case ADD_ASSET_CATEGORY_NEW_LABEL:
        return action.value;
    case DIALOG_ADD_ASSET_CATEGORY_HIDE:
    case NEW_ASSET_CATEGORY_ADD_SUCCESS:
        return '';
    default:
        return state;
    }
};

const newGenre = (state = '', action) => {
    switch (action.type) {
    case ADD_GENRE_NEW_LABEL:
        return action.value;
    case DIALOG_ADD_GENRE_HIDE:
    case NEW_GENRE_ADD_SUCCESS:
        return '';
    default:
        return state;
    }
};

const filesInProgress = (state = false, action) => {
    switch (action.type) {
    case ASSET_FILES_IN_PROGRESS:
        return true;
    case ASSET_FILES_LIST:
        return false;
    default:
        return state;
    }
};

const addAssetFile = (state = {
    result: {},
}, action) => {
    if (action.type === ASSET_FILE_ADD_SUCCESS) {
        return {
            ...state,
            result: action.result,
        };
    }

    return state;
};

const assetFileEncode = (state = {
    result: {},
}, action) => {
    if (action.type === ASSET_FILE_ENCODE_SUCCESS) {
        return {
            ...state,
            result: action.result,
        };
    }

    return state;
};

const addAsset = (state = {
    result: {},
    data: [],
    state: {},
}, action) => {
    if (action.type === ASSET_ADD_SUCCESS) {
        return {
            ...state,
            result: action.result,
        };
    } else if (action.type === QUICK_VIEW_ASSET_DATA) {
        return {
            ...state,
            data: action.data,
            state: action.state,
        };
    }

    return state;
};

const selectedAssetFileId = (state = '', action) => {
    if (action.type === ADD_FILE_SET) {
        return action.value;
    }

    return state;
};

export const asset = (state = {
    list: [],
    current: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSETS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSETS_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
        };
    case ASSETS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CURRENT_ASSET_SET:
        return {
            ...state,
            current: Object.values(state.list).filter((v) => v.category.name === action.asset),
        };
    case ASSET_FETCH_SUCCESS:
        return {
            ...state,
            current: action.item,
        };
    default:
        return state;
    }
};

const dialogEditAsset = (state = {
    data: [],
    state: {},
}, action) => {
    if (action.type === DIALOG_EDIT_ASSET_SHOW) {
        return true;
    }

    return state;
};

export const assetData = (state = {
    list: [],
    inProgress: true,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: DEFAULT_COUNT,
    searchKey: DEFAULT_SEARCH_KEY,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case ASSETS_DATA_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSETS_DATA_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
            count: action.count,
            skip: action.skip,
            limit: action.limit,
            searchKey: action.searchKey,
            sortBy: action.sortBy,
            order: action.order,
        };
    case ASSETS_DATA_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case ASSET_ADD_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.result],
        };
    case ASSET_UPDATE_SUCCESS: {
        let array = state.list;
        array = array.filter((item) => item._id !== action.data._id);

        array.unshift(action.data);

        return {
            ...state,
            list: array,
        };
    }
    default:
        return state;
    }
};

export const assetsOverViewData = (state = {
    list: [],
    encoded: [],
    inProgress: true,
}, action) => {
    switch (action.type) {
    case ASSETS_OVERVIEW_DATA_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSETS_OVERVIEW_DATA_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
        };
    case ENCODED_ASSETS_OVERVIEW_DATA_FETCH_SUCCESS:
        return {
            ...state,
            encoded: action.items,
            inProgress: false,
        };
    case ASSETS_OVERVIEW_DATA_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const assetTypes = (state = types) => {
    return state;
};

const preview = (state = {
    show: false,
    asset: null,
}, action) => {
    if (action.type === SHOW_PREVIEW_SET) {
        return {
            ...state,
            show: action.value,
            asset: action.asset,
        };
    }

    return state;
};

const tags = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_TAGS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_TAGS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.items,
        };
    case ASSET_TAGS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const upload = (state = {
    file: undefined,
    inProgress: false,
    progress: {},
}, action) => {
    switch (action.type) {
    case UPLOAD_FILE_SET:
        return {
            ...state,
            file: action.value,
        };
    case FILE_UPLOAD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FILE_UPLOAD_SUCCESS:
        return {
            ...state,
            inProgress: false,
            progress: {},
        };
    case FILE_UPLOAD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case UPLOAD_PROGRESS_SET: {
        return {
            ...state,
            progress: action.value,
        };
    }
    default:
        return state;
    }
};

const download = (state = {
    file: {},
    inProgress: false,
    progress: {},
    downloadFileType: '',
}, action) => {
    switch (action.type) {
    case FILE_DOWNLOAD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            downloadFileType: action.downloadFileType,
        };
    case FILE_DOWNLOAD_SUCCESS: {
        fileDownload(action.data, action.fileName, action.data && action.data.type);

        return {
            ...state,
            inProgress: false,
            progress: {},
        };
    }
    case FILE_DOWNLOAD_ERROR:
        return {
            ...state,
            inProgress: false,
            progress: {},
        };
    case DOWNLOAD_PROGRESS_SET: {
        return {
            ...state,
            progress: action.value,
        };
    }
    default:
        return state;
    }
};

const tagsList = (state = {
    inProgress: false,
    list: [],
}, action) => {
    switch (action.type) {
    case TAGS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TAGS_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.list,
        };
    case TAGS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export const filterMenu = (state = {
    anchor: null,
}, action) => {
    switch (action.type) {
    case POPOVER_FILTER_SHOW:
        return {
            anchorEl: action.anchorEl,
        };
    case POPOVER_FILTER_HIDE:
        return {
            anchorEl: null,
        };
    default:
        return state;
    }
};

export const updatedFilter = (state = {
    filter: 'thisMonth',
    days: new Date().getDate(),
}, action) => {
    switch (action.type) {
    case CHANGED_FILTER:
        return {
            filter: action.filter,
            days: action.value,
        };

    default:
        return state;
    }
};

export const deleteAsset = (state = {
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_DELETE_IN_PROGRESS:
        return {
            inProgress: true,
        };
    case ASSET_DELETE_SUCCESS:
    case ASSET_DELETE_ERROR:
        return {
            inProgress: false,
        };
    default:
        return state;
    }
};

const dialogDeleteThumb = (state = {
    open: false,
    name: '',
    path: '',
}, action) => {
    switch (action.type) {
    case DIALOG_DELETE_THUMB_SHOW:
        return {
            ...state,
            open: true,
            name: action.name,
            path: action.path,
        };
    case DIALOG_DELETE_THUMB_HIDE:
        return {
            open: false,
            name: '',
            path: '',
        };
    default:
        return state;
    }
};

const dialogUpdateThumb = (state = {
    open: false,
    name: '',
}, action) => {
    switch (action.type) {
    case DIALOG_UPDATE_THUMB_SHOW:
        return {
            ...state,
            open: true,
            name: action.name,
        };
    case DIALOG_UPDATE_THUMB_HIDE:
        return {
            open: false,
            name: '',
        };
    default:
        return state;
    }
};

const dialogViewThumb = (state = {
    open: false,
    thumbDetails: {},
    thumbValue: '',
}, action) => {
    switch (action.type) {
    case DIALOG_VIEW_THUMB_SHOW:
        return {
            ...state,
            open: true,
            thumbDetails: action.name,
            thumbValue: action.value ? action.value : '',
        };
    case DIALOG_VIEW_THUMB_HIDE:
    case DELETE_ASSET_THUMBNAIL_SUCCESS:
        return {
            open: false,
            thumbDetails: '',
            thumbValue: '',
        };
    default:
        return state;
    }
};

const dialogUploadThumb = (state = {
    open: false,
    thumbs: [],
}, action) => {
    switch (action.type) {
    case DIALOG_UPLOAD_THUMB_SHOW:
        return {
            ...state,
            open: true,
            thumbs: action.thumbs,
        };
    case DIALOG_UPLOAD_THUMB_HIDE:
        return {
            open: false,
        };
    default:
        return state;
    }
};

const thumbData = (state = {
    item: {},
}, action) => {
    switch (action.type) {
    case ASSET_FETCH_SUCCESS:
        return action.item && action.item.thumbnail ? action.item.thumbnail : {};
    case EDITABLE_ASSET_DATA:
        return action.value && action.value.thumbnail ? action.value.thumbnail : {};
    case DIALOG_ADD_ASSET_HIDE:
        return '';

    default:
        return state;
    }
};

const thumbsList = (state = {
    list: [],
    successList: [],
}, action) => {
    switch (action.type) {
    case UPLOADED_THUMBS_LIST:
        return {
            ...state,
            open: true,
            list: action.list,
            successList: action.successList,
        };
    case DIALOG_ADD_ASSET_HIDE:
    case ASSET_UPDATE_SUCCESS:
    case ASSET_ADD_SUCCESS:
        return {
            ...state,
            list: [],
            successList: [],
        };

    default:
        return state;
    }
};

const uploadedThumbnails = (state = {
    inProgress: false,
}, action) => {
    switch (action.type) {
    case UPLOAD_ASSET_THUMBNAIL_IN_PROGRESS:
        return {
            inProgress: true,
        };
    case UPLOAD_ASSET_THUMBNAIL_ERROR:
        return {
            inProgress: false,
        };
    case UPLOAD_ASSET_THUMBNAIL_SUCCESS:
        return {
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    categoryTableList,
    quickView,
    new: _new,
    _: asset,
    categories,
    genres,
    sourceType,
    dialogAddAssetCategory,
    assetCategoriesList,
    newAssetCategory,
    filesInProgress,
    assetFilesList,
    addAssetFile,
    assetFileEncode,
    selectedAssetFileId,
    addAsset,
    dialogConfirm,
    dialogConfirmId,
    // tableRowList,
    dialogAddGenre,
    newGenre,
    dialogConfirmGenreId,
    dialogEditAsset,
    dialogActionConfirm,
    assetData,
    assetTypes,
    assetsOverViewData,
    dialogAddVideo,
    tags,
    preview,
    upload,
    download,
    tagsList,
    filterMenu,
    updatedFilter,
    videoAssets,
    dialogAddAlbum,
    audioAssets,
    deleteAsset,
    dialogDeleteThumb,
    dialogViewThumb,
    dialogUpdateThumb,
    dialogUploadThumb,
    thumbsList,
    thumbData,
    uploadedThumbnails,
});
