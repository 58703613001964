import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setMood } from '../../../../../actions/asset';
import TextField from '../../../../../components/TextField';

const MoodTextField = (props) => {
    return (
        <TextField
            id="mood_text_field"
            label="Mood"
            name="mood"
            value={props.value}
            onChange={props.onChange}/>
    );
};

MoodTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.music.mood,
    };
};

const actionToProps = {
    onChange: setMood,
};

export default connect(stateToProps, actionToProps)(MoodTextField);
