import { Button } from '@material-ui/core';
import React from 'react';

const SaveButton = () => {
    return (
        <Button className="outline_button" variant="outlined">
            Save
        </Button>
    );
};

export default SaveButton;
