import React from 'react';
import './index.css';
import SearchBar from './SearchBar';

const SearchBarTextField = () => {
    return (
        <div className="search_div">
            <SearchBar/>
        </div>
    );
};

export default SearchBarTextField;
