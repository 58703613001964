import { combineReducers } from 'redux';
import {
    CHANNEL_LIVE_STREAM_CONFIG_FETCH_ERROR,
    CHANNEL_LIVE_STREAM_CONFIG_FETCH_SUCCESS,
} from '../../constants/channel';

const stream = (state = {}, action) => {
    switch (action.type) {
    case CHANNEL_LIVE_STREAM_CONFIG_FETCH_SUCCESS:
        return action.item;
    case CHANNEL_LIVE_STREAM_CONFIG_FETCH_ERROR:
        return state;
    }
    return state;
};

export default combineReducers({
    stream,
});
