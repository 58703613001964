import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { deleteBug, fetchBugs } from '../../../../../../actions/bugs';
import DataTable from '../../../../../../components/DataTable';
import FrequencySelectField from './FrequencySelectField';
import './index.css';
import PlayTime from './PlayTime';
import PositionSelectField from './PositionSelectfield';
import RepeatSelectField from './RepeatSelectField';
import SkeletonLoader from '../../../../../../components/SkeletonLoader/Table';
import { fetchPrograms } from '../../../../../../actions/program';

const Table = (props) => {
    const [index, setIndex] = React.useState([]);

    const getPrograms = () => {
        props.slots &&
        Object.keys(props.slots).length &&
        Object.values(props.slots).map((slot, index) => (
            props.fetchPrograms(slot.channel,
                slot['live_stream'],
                slot._id, () => {
                })
        ));
    };

    const options = {
        serverSide: false,
        pagination: false,
        selectableRows: true,
        rowsSelected: index,
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <SkeletonLoader columns={7}/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Sort for ${column.label}`,
            },
        },
        onCellClick: (colData, cellMeta) => {
            if (cellMeta.colIndex === 2 ||
                cellMeta.colIndex === 3 ||
                cellMeta.colIndex === 4 ||
                cellMeta.colIndex === 5) {
                return;
            }

            const ind = [...index];
            const alreadyExit = ind.filter((value) => value === cellMeta.rowIndex);
            if (alreadyExit.length === 0) {
                ind.push(cellMeta.rowIndex);
                setIndex([...ind]);
            } else {
                ind.map((value, index1) => {
                    if (value === cellMeta.rowIndex) {
                        ind.splice(index1, 1);
                    }
                });
                setIndex([...ind]);
            }
        },
        onRowsDelete: (rowsDeleted) => {
            const token = localStorage.getItem('authorizationToken');

            rowsDeleted.data &&
            rowsDeleted.data.map((value) => {
                props.deleteBug(props.slot.channel, props.slot.live_stream, props.slot._id, props.data[value.index]._id, token, (error) => {
                    if (!error) {
                        props.fetchBugs(props.slot.channel, props.slot.live_stream, props.slot._id, token);
                        getPrograms();
                    }
                });
            });
        },
    };

    const columns = [{
        name: 'bugList',
        label: 'Bugs list',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'duration',
        label: 'Duration',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'playTime',
        label: 'Play time',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'repeat',
        label: 'Repeat',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'frequency',
        label: 'Frequency',
        options: {
            filter: false,
            sort: true,
        },
    }, {
        name: 'position',
        label: 'Position',
        options: {
            filter: false,
            sort: true,
        },
    }];

    const tableData = props.data && props.data.length
        ? props.data.map((item, index) =>
            [
                <div
                    key={index}
                    className="line_clamp"
                    title={item.asset && item.asset.file && item.asset.file.name}>
                    {item.asset && item.asset.file && item.asset.file.name}
                </div>,
                moment.utc(moment.duration(
                    moment(item.end_at)
                        .diff(moment(item.start_at), 'seconds'),
                    'seconds')
                    .asMilliseconds()).format('HH:mm:ss'),
                <PlayTime
                    key={'calendar' + index}
                    index={index}
                    item={item}/>,
                <RepeatSelectField
                    key={'repeat' + index}
                    index={index}
                    value={item.repeat
                        ? item.repeat
                        : 0}/>,
                <FrequencySelectField
                    key={'frequency' + index}
                    index={index}
                    value={item.frequency
                        ? item.frequency
                        : 0}/>,
                <PositionSelectField
                    key={'position' + index}
                    index={index}
                    value={item.position
                        ? item.position
                        : 'none'}/>])
        : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={tableData}
                name=""
                options={options}/>
        </>
    );
};

Table.propTypes = {
    data: PropTypes.array.isRequired,
    deleteBug: PropTypes.func.isRequired,
    fetchBugs: PropTypes.func.isRequired,
    fetchPrograms: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    slot: PropTypes.object.isRequired,
    slots: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
    return {
        slot: state.live.bugs.slot,
        slots: state.live.slot.list,
    };
};

const actionToProps = {
    deleteBug,
    fetchBugs,
    fetchPrograms,
};

export default connect(stateToProps, actionToProps)(Table);
