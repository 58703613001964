import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deleteAsset, fetchAssetsData, hideDialogActionMenu } from '../../../actions/asset';

const DialogConfirm = (props) => {
    const onClick = (id, source, encoded) => {
        const token = localStorage.getItem('authorizationToken');

        props.deleteAsset(id, token, source, encoded, (cb) => {
            if (cb) {
                return;
            }

            handleFetch();
        });
    };

    const handleFetch = () => {
        const token = localStorage.getItem('authorizationToken');
        const recent = props.location.pathname === '/manage/assets';
        const type = props.type === 'recent' ? '' : props.type;

        if (recent) {
            props.fetchAssetsData(type, token, false, true, props.days,
                props.skip,
                props.limit,
                props.searchKey,
                props.sortBy,
                props.order);
        } else {
            props.fetchAssetsData(type, token, null, null, null,
                props.skip,
                props.limit,
                props.searchKey,
                props.sortBy,
                props.order);
        }
    };

    const disable = props.inProgress;

    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            className="dialog_confirm"
            open={props.open}
            onClose={props.onClickCancel}>
            <DialogTitle
                className="title"
                id="alert-dialog-title">
                <Typography className="text-16 mb-8 font-600">
                    Are you sure? Do you want to delete both the source & the encoded file?
                </Typography>
            </DialogTitle>
            <DialogContent className="content">
                {props.id && props.id.file && props.id.file.download && props.id.file.download.status === 'COMPLETE'
                    ? <>
                        <Button
                            aria-label="Delete"
                            className="delete"
                            disabled={disable}
                            onClick={() => onClick(props.id, true, null)}>
                            Delete Source
                        </Button>
                        <Button
                            aria-label="Delete"
                            className="delete"
                            disabled={disable}
                            onClick={() => onClick(props.id, null, true)}>
                            Delete Encoded
                        </Button>
                    </>
                    : null}
                <Button
                    aria-label="Delete"
                    className="delete"
                    disabled={disable}
                    onClick={() => onClick(props.id, true, true)}>
                    {props.id && props.id.file && props.id.file.download && props.id.file.download.status === 'COMPLETE'
                        ? 'Delete All'
                        : 'Delete'}
                </Button>
                <Button
                    className="cancel"
                    onClick={props.onClickCancel}>
                    Cancel
                </Button>
            </DialogContent>
        </Dialog>
    );
};

DialogConfirm.propTypes = {
    days: PropTypes.number.isRequired,
    deleteAsset: PropTypes.func.isRequired,
    fetchAssetsData: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    searchKey: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    order: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        days: state.asset.updatedFilter.days,
        open: state.asset.dialogActionConfirm.open,
        id: state.asset.dialogActionConfirm.id,
        type: state.asset.dialogActionConfirm.type,
        inProgress: state.asset.deleteAsset.inProgress,
        skip: state.asset.assetData.skip,
        limit: state.asset.assetData.limit,
        searchKey: state.asset.assetData.searchKey,
        sortBy: state.asset.assetData.sortBy,
        order: state.asset.assetData.order,
    };
};

const actionsToProps = {
    onClickCancel: hideDialogActionMenu,
    deleteAsset,
    fetchAssetsData,
};

export default withRouter(connect(stateToProps, actionsToProps)(DialogConfirm));
