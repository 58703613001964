export const list = [{
    hash: 'fkjoiwejoriwoeriosdfkmnowe',
    activity: 'sent',
    tokens: '48303 FLIX',
    time: 'fri 29 oct, 5:30 pm',
}, {
    hash: 'fkjoiwejoriwoeriosdfkmnowe',
    activity: 'received',
    tokens: '48303 FLIX',
    time: 'fri 29 oct, 5:30 pm',
}, {
    hash: 'fkjoiwejoriwoeriosdfkmnowe',
    activity: 'minted',
    tokens: 'mediaNFT',
    time: 'fri 29 oct, 5:30 pm',
}];
