import { Button } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    editableData,
    editableImageAsset,
    encode, fetchAsset, fetchAssetsData,
    setAddAssetID,
    showAssetCategoryTableList,
    showDialogActionMenu,
    showDialogAddAsset,
    showDialogEditAsset,
    showQuickViewMovieAsset,
} from '../../../actions/asset';
import instaIcon from '../../../assets/instaActive.svg';
import twitterIcon from '../../../assets/twitterActive.svg';
import youtubeIcon from '../../../assets/youtubeActive.svg';
import DataTable from '../../../components/DataTable';
import Icon from '../../../components/Icon';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../constants/url';
import PopOverActionMenu from '../PopOverActionMenu';
import SkeletonLoader from '../../../components/SkeletonLoader/Table';

class AssetVideoTable extends React.Component {
    constructor (props) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onClickEncode = this.onClickEncode.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
    }

    onClick () {
        this.props.onClickAdd();
    }

    handleEdit (index) {
        const data = this.props.data && this.props.data.length > 0 && this.props.data.filter((item) => item.type === 'image');
        this.props.editableData(data[index.rowIndex]);
        this.props.editableImageAsset(data[index.rowIndex]);
        this.props.onClickEdit();
        this.props.fetchAsset(data[index.rowIndex]._id, localStorage.getItem('authorizationToken'), (cb) => {});
    }

    handleView (index) {
        const data = this.props.data && this.props.data.length > 0 && this.props.data.filter((item) => item.type === 'image');
        this.props.onClickView(data[index.dataIndex]);
    }

    onClickEncode (id, token) {
        this.props.encodeFile(id, token, (cb) => {
            if (cb) {
                return;
            }
            const url = this.props.location.pathname;
            const pathName = url.split('/');
            const token = localStorage.getItem('authorizationToken');
            this.props.tableList(pathName ? pathName[3] : '', token);
        });
    }

    handleFetch (skip, limit, searchText, sortBy, order) {
        const token = localStorage.getItem('authorizationToken');

        if (this.props.recent) {
            this.props.fetch('video', token, false, true, this.props.days,
                skip,
                limit,
                searchText,
                sortBy,
                order);
        } else {
            this.props.fetch('video', token, null, null, null,
                skip,
                limit,
                searchText,
                sortBy,
                order);
        }
    }

    render () {
        const options = {
            responsive: true,
            serverSide: true,
            count: this.props.count,
            download: false,
            print: false,
            selectableRows: 'none',
            onSearchChange: (searchText) => {
                this.handleFetch(DEFAULT_SKIP, DEFAULT_LIMIT, searchText, this.props.sortBy,
                    this.props.sortBy !== '' ? this.props.order : null);
            },
            onChangePage: (currentPage) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                this.handleFetch(this.props.limit * currentPage, this.props.limit, this.props.searchKey, this.props.sortBy,
                    this.props.sortBy !== '' ? this.props.order : null);
            },
            onChangeRowsPerPage: (numberOfRows) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                this.handleFetch(this.props.skip, numberOfRows, this.props.searchKey, this.props.sortBy,
                    this.props.sortBy !== '' ? this.props.order : null);
            },
            onColumnSortChange: (changedColumn, direction) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                const order = direction === 'asc' ? 1 : -1;
                this.handleFetch(this.props.skip, this.props.limit, this.props.searchKey, changedColumn, order);
            },
            onCellClick: (colData, cellMeta) => {
                const data = this.props.data && this.props.data.length > 0 && this.props.data.filter((item) => item.type === 'image');
                if (cellMeta.colIndex !== 10 && data && data.length > 0) {
                    this.handleView(cellMeta);
                }
            },
            customToolbar: () => {
                return (
                    <span>
                        <Button className="add_icon" href="" onClick={this.props.onClickAdd}>
                            <Icon className="add" icon="add"/>
                        </Button>
                    </span>
                );
            },
            textLabels: {
                body: {
                    noMatch: this.props.inProgress ? <SkeletonLoader columns={5}/>
                        : <p className="no_data_table"> No data found </p>,
                    toolTip: 'Sort',
                    columnHeaderTooltip: (column) => `Sort for ${column.label}`,
                },
                pagination: {
                    next: 'Next Page',
                    previous: 'Previous Page',
                    rowsPerPage: 'Rows per page:',
                    displayRows: 'of',
                },
                toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'View Columns',
                    filterTable: 'Filter Table',
                },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },
                viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Table Columns',
                },
            },
        };

        const columns = [{
            name: 'assetName',
            label: 'Asset Name',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'assetGenre',
            label: 'Asset Genre',
            options: {
                filter: true,
                sort: true,
            },
        }, {
            name: 'category',
            label: 'Category',
            options: {
                filter: false,
                sort: true,
            },
        }, {
            name: 'fileType',
            label: 'File Type',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, {
            name: 'duration',
            label: 'Duration',
            options: {
                filter: true,
                sort: true,
            },
        }, {
            name: 'source',
            label: 'Source',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, {
            name: 'dateofUpload',
            label: 'Date of Upload',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, {
            name: 'publishedForVOD',
            label: 'Published for VOD',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, {
            name: 'datePublished',
            label: 'Date Published (VOD)',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, {
            name: 'lastRun',
            label: 'Last Run',
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        }, {
            name: 'Action',
            label: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, i) => {
                    return (
                        <div className="row_action">
                            <Button
                                className="row_action_icon preview">
                                <Icon
                                    className="eye"
                                    icon="eye"/>
                            </Button>
                            <Button
                                className="row_action_icon edit"
                                onClick={() => this.handleEdit(i)}>
                                <Icon
                                    className="edit_action"
                                    icon="edit_action"/>
                            </Button>
                            <Button
                                className="row_action_icon delete"
                                onClick={() => this.props.onClickConfirm(this.props.data[i.rowIndex], 'graphic')}>
                                <Icon
                                    className="delete_action"
                                    icon="delete_action"/>
                            </Button>
                        </div>
                    );
                },
            },
        }];
        const data = this.props.data && this.props.data.length > 0 && this.props.data.filter((item) => item.type === 'image');
        const tableData = data && data.length > 0
            ? data.map((item, index) => [
                <div key={index} className="filename">
                    {item.graphic && item.graphic.name}
                </div>,
                item.genre ? item.genre.name : '',
                <div key={index} className="filename">
                    {item.category && item.category.name}
                </div>,
                item.type,
                item.file && item.file.length
                    ? ((Math.floor(item.file.length / 3600)) + ':' +
                    (Math.floor((item.file.length - ((Math.floor(item.file.length / 3600)) * 3600)) / 60)) + ':' +
                    (item.file.length -
                        ((Math.floor(item.file.length / 3600)) * 3600) -
                        ((Math.floor((item.file.length -
                            ((Math.floor(item.file.length / 3600)) * 3600)) / 60)) * 60)).toFixed(0))
                    : '',
                item.file && item.file.download && item.file.download.source
                    ? <div key={index} className="source_css">
                        {item.file.download.source === 'youtube'
                            ? <img alt="youtube" src={youtubeIcon}/>
                            : item.file.download.source === 'twitter'
                                ? <img alt="twitter" src={twitterIcon}/>
                                : item.file.download.source === 'instagram'
                                    ? <img alt="insta" src={instaIcon}/>
                                    : item.file.download.source}
                    </div>
                    : '',
                item.created_at ? moment(item.created_at).format('DD-MMM-YY') : '',
                item.publishedForVOD ? item.publishedForVOD : '',
                item.datePublished ? item.datePublished : '',
                item.lastRun ? item.lastRun : '',
            ])
            : [];

        return (
            <>
                <DataTable
                    columns={columns}
                    data={tableData}
                    name=""
                    options={options}/>
                <PopOverActionMenu/>
            </>
        );
    }
}

AssetVideoTable.propTypes = {
    anchor: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    days: PropTypes.number.isRequired,
    editableData: PropTypes.func.isRequired,
    editableImageAsset: PropTypes.func.isRequired,
    encodeFile: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    fileEncoded: PropTypes.object.isRequired,
    inProgress: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    menuAction: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    order: PropTypes.number.isRequired,
    searchKey: PropTypes.string.isRequired,
    setAddAssetID: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    onClickActionMenu: PropTypes.func.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onClickConfirm: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickView: PropTypes.func.isRequired,
    fetchAsset: PropTypes.func,
    recent: PropTypes.bool,
    tableList: PropTypes.func,
};

const stateToProps = (state) => {
    return {
        data: state.asset.assetData.list,
        days: state.asset.updatedFilter.days,
        inProgress: state.asset.assetData.inProgress,
        rowData: state.asset.addAsset.data,
        rowIndex: state.asset.addAsset.state,
        fileEncoded: state.asset.assetFileEncode.result,
        menuAction: state.asset.dialogShowMenu,
        anchor: state.asset.dialogShowMenu,
        open: state.asset.dialogShowMenu,
        skip: state.asset.assetData.skip,
        limit: state.asset.assetData.limit,
        count: state.asset.assetData.count,
        searchKey: state.asset.assetData.searchKey,
        sortBy: state.asset.assetData.sortBy,
        order: state.asset.assetData.order,
    };
};

const actionToProps = {
    onClickView: showQuickViewMovieAsset,
    onClickAdd: showDialogAddAsset,
    tableList: showAssetCategoryTableList,
    encodeFile: encode,
    setAddAssetID: setAddAssetID,
    onClickEdit: showDialogEditAsset,
    editableData: editableData,
    editableImageAsset,
    onClickConfirm: showDialogActionMenu,
    fetchAsset: fetchAsset,
    fetch: fetchAssetsData,
};

export default withRouter(connect(stateToProps, actionToProps)(AssetVideoTable));
