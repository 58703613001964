import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAddStream } from '../../../../actions/channel';
import SelectField from '../../../../components/SelectField';

class StreamSelectField extends React.Component {
    render () {
        return (
            <div>
                <SelectField
                    id="stream_select_field"
                    items={this.props.streams.map((v) => {
                        return {
                            name: v.name,
                            value: v._id,
                        };
                    })}
                    name="stream_select"
                    placeholder="Select Stream"
                    value={localStorage.getItem('s')
                        ? localStorage.getItem('s')
                        : this.props.stream}
                    onChange={this.props.onChange}/>
            </div>
        );
    }
}

StreamSelectField.propTypes = {
    stream: PropTypes.string.isRequired,
    streams: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        stream: state.live.stream.current,
        streams: state.live.stream.list,
    };
};

const actionsToProps = {
    onChange: setAddStream,
};

export default connect(stateToProps, actionsToProps)(StreamSelectField);
