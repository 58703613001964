import Axios from 'axios';
import {
    ADD_SLOT_TEMPLATE_ERROR,
    ADD_SLOT_TEMPLATE_IN_PROGRESS,
    ADD_SLOT_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_ERROR,
    DELETE_TEMPLATE_IN_PROGRESS,
    DELETE_TEMPLATE_SUCCESS,
    DIALOG_DELETE_TEMPLATE_HIDE,
    DIALOG_DELETE_TEMPLATE_SHOW,
    FETCH_SLOT_TEMPLATE_ERROR,
    FETCH_SLOT_TEMPLATE_IN_PROGRESS,
    FETCH_SLOT_TEMPLATE_SUCCESS,
    SELECTED_SLOT_TEMPLATES_SET,
    SLOT_TEMPLATES_SET,
} from '../constants/dynamicPrograms';
import { SLOT_TEMPLATE_URL, slotTemplateUpdateURL } from '../constants/url';

const token = localStorage.getItem('authorizationToken');

const fetchSlotTemplateInProgress = () => {
    return {
        type: FETCH_SLOT_TEMPLATE_IN_PROGRESS,
    };
};

const fetchSlotTemplateSuccess = (value) => {
    return {
        type: FETCH_SLOT_TEMPLATE_SUCCESS,
        value,
    };
};

const fetchSlotTemplateError = (message) => {
    return {
        type: FETCH_SLOT_TEMPLATE_ERROR,
        message,
    };
};

export const fetchSlotTemplate = (Token) => (dispatch) => {
    dispatch(fetchSlotTemplateInProgress());
    let UpdateToken = token;
    if (Token) {
        UpdateToken = Token;
    }

    Axios.get(SLOT_TEMPLATE_URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + UpdateToken,
        },
    })
        .then((res) => {
            dispatch(fetchSlotTemplateSuccess(res.data.result));
        })
        .catch((error) => {
            dispatch(fetchSlotTemplateError(
                error.response &&
                error.response.data &&
                error.response.data.result
                    ? error.response.data.result
                    : 'Failed!',
            ));
        });
};

const addSlotTemplateInProgress = () => {
    return {
        type: ADD_SLOT_TEMPLATE_IN_PROGRESS,
    };
};

const addSlotTemplateSuccess = (value) => {
    return {
        type: ADD_SLOT_TEMPLATE_SUCCESS,
        value,
    };
};

const addSlotTemplateError = (message) => {
    return {
        type: ADD_SLOT_TEMPLATE_ERROR,
        message,
    };
};

export const addSlotTemplate = (Token, data, cb) => (dispatch) => {
    dispatch(addSlotTemplateInProgress());

    Axios.post(SLOT_TEMPLATE_URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + Token,
        },
    })
        .then((res) => {
            dispatch(addSlotTemplateSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(addSlotTemplateError(
                error.response &&
                error.response.data &&
                error.response.data.result
                    ? error.response.data.result
                    : 'Failed!',
            ));
            cb(error);
        });
};

export const setSlotTemplates = (value) => {
    return {
        type: SLOT_TEMPLATES_SET,
        value,
    };
};

export const setSelectedSlots = (value) => {
    return {
        type: SELECTED_SLOT_TEMPLATES_SET,
        value,
    };
};

export const showDeleteTemplateDialog = (value) => {
    return {
        type: DIALOG_DELETE_TEMPLATE_SHOW,
        value,
    };
};

export const hideDeleteTemplateDialog = () => {
    return {
        type: DIALOG_DELETE_TEMPLATE_HIDE,
    };
};

const deleteTemplateInProgress = () => {
    return {
        type: DELETE_TEMPLATE_IN_PROGRESS,
    };
};

const deleteTemplateSuccess = () => {
    return {
        type: DELETE_TEMPLATE_SUCCESS,
        message: 'Deleted Successfully',
    };
};

const deleteTemplateError = (message) => {
    return {
        type: DELETE_TEMPLATE_ERROR,
        message,
    };
};

export const deleteTemplate = (Token, ID, cb) => (dispatch) => {
    dispatch(deleteTemplateInProgress());

    const url = slotTemplateUpdateURL(ID);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + Token,
        },
    })
        .then((res) => {
            dispatch(deleteTemplateSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(deleteTemplateError(
                error.response &&
                error.response.data &&
                error.response.data.result
                    ? error.response.data.result
                    : 'Failed!',
            ));
            cb(error);
        });
};
