import { Button } from '@material-ui/core';
import React from 'react';

const AssetSettingsButton = () => {
    return (
        <Button>
            Settings
        </Button>
    );
};

export default AssetSettingsButton;
