import { Popover } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeFilter, closeFilterMenu } from '../../../actions/asset';
import './index.css';

const FilterPopover = (props) => {
    const handleChangeFilter = (name, value) => {
        props.changeFilter(name, value);
    };

    return (
        <Popover
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            className="popover_filter"
            id="simple-popper"
            open={Boolean(props.anchorEl)}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={props.closeFilterMenu}>
            <div className="filter_day">
                <div
                    className={props.filterUpdated === 'today' ? 'active_filter' : ''}
                    onClick={() => handleChangeFilter('today', 1)}> Today
                </div>
                <div
                    className={props.filterUpdated === 'yesterday' ? 'active_filter' : ''}
                    onClick={() => handleChangeFilter('yesterday', 2)}> Yesterday
                </div>
                <div
                    className={props.filterUpdated === 'thisWeek' ? 'active_filter' : ''}
                    onClick={() => handleChangeFilter('thisWeek', 7)}> This Week
                </div>
                <div
                    className={props.filterUpdated === 'thisMonth' ? 'active_filter' : ''}
                    onClick={() => handleChangeFilter('thisMonth', new Date().getDate())}> This Month
                </div>
            </div>
        </Popover>
    );
};
FilterPopover.propTypes = {
    anchorEl: PropTypes.any,
    changeFilter: PropTypes.func,
    closeFilterMenu: PropTypes.func,
    filterUpdated: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        anchorEl: state.asset.filterMenu.anchorEl,
        filterUpdated: state.asset.updatedFilter.filter,
    };
};

const actionToProps = {
    closeFilterMenu,
    changeFilter,
};

export default withRouter(connect(stateToProps, actionToProps)(FilterPopover));
