import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setMaxValue, setMinValue } from '../../actions/chapterMarking';

const TagVideoButton = (props) => {
    const handleClick = () => {
        props.setMaxValue(0);
        props.setMinValue(0);
        if (props.hideQuickView) {
            props.hideQuickView();
        }

        props.history.push(`/assets/chapterMarking/${props.id}`);
    };

    return (
        <Button
            className="sync_button tag_video_button copy_button"
            onClick={handleClick}>
            Tag Video
        </Button>
    );
};

TagVideoButton.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    setMaxValue: PropTypes.func.isRequired,
    setMinValue: PropTypes.func.isRequired,
    hideQuickView: PropTypes.func,
};

const actionToProps = {
    setMaxValue,
    setMinValue,
};

export default withRouter(connect(null, actionToProps)(TagVideoButton));
