import { ButtonBase, Card as MaterialCard } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchAssetsData, syncAssets } from '../../actions/asset';
import audioIcon from '../../assets/audioCard.svg';
import graphicsIcon from '../../assets/graphics.svg';
import textIcon from '../../assets/text.svg';
import videoIcon from '../../assets/videoCard.svg';
import {
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../../constants/url';
import SkeletonLoader from '../SkeletonLoader/OverviewCard';
import './index.css';

const Card = (props) => {
    const handleClick = (asset) => {
        const token = localStorage.getItem('authorizationToken');
        if (token) {
            props.fetchAssetsData(asset, token, null, null, null,
                DEFAULT_SKIP,
                DEFAULT_LIMIT,
                DEFAULT_SEARCH_KEY,
                DEFAULT_SORT_BY,
                DEFAULT_ORDER,
            );
        }

        props.history.push({
            pathname: '/manage/assets/' + asset,
        });
    };
    return (
        <ButtonBase>
            {props.data
                ? <MaterialCard
                    className="card"
                    onClick={() => handleClick(props.item)}>
                    <div className="overview_card_section">
                        <div className="category_section">
                            {props.data.count}
                        </div>
                        <div className="card_type">
                            {props.item}
                            <div
                                className={(props.item === 'audio' || props.item === 'video')
                                    ? 'video_audio card_img' : 'card_img'}>
                                {
                                    props.item === 'video'
                                        ? <img alt={videoIcon} src={videoIcon}/>
                                        : props.item === 'audio'
                                            ? <img alt={audioIcon} src={audioIcon}/>
                                            : props.item === 'graphic'
                                                ? <img alt={graphicsIcon} src={graphicsIcon}/>
                                                : props.item === 'text'
                                                    ? <img alt={textIcon} src={textIcon}/>
                                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </MaterialCard>
                : <SkeletonLoader/>}
        </ButtonBase>
    );
};

Card.propTypes = {
    data: PropTypes.object.isRequired,
    fetchAssetsData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    item: PropTypes.string.isRequired,
};

const actionToProps = {
    onClick: syncAssets,
    fetchAssetsData,
};

export default withRouter(connect(null, actionToProps)(Card));
