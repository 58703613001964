import Axios from 'axios';
import {
    USER_LOGIN_EMAIL_SET,
    USER_LOGIN_ERROR,
    USER_LOGIN_IN_PROGRESS,
    USER_LOGIN_PASSWORD_SET,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_SUCCESS_MESSAGE,
} from '../constants/login';
import { USER_ACCESS_TOKEN, USER_LOGIN as USER_LOGIN_URL } from '../constants/url';

export const setUserLoginEmail = (value, valid) => {
    return {
        type: USER_LOGIN_EMAIL_SET,
        value,
        valid,
    };
};

export const setUserLoginPassword = (value) => {
    return {
        type: USER_LOGIN_PASSWORD_SET,
        value,
    };
};

export const userLoginSuccess = (result) => {
    return {
        type: USER_LOGIN_SUCCESS,
        result,
    };
};

export const userLoginSuccessMessage = (message) => {
    return {
        type: USER_LOGIN_SUCCESS_MESSAGE,
        message,
    };
};

export const userLoginError = (message) => {
    return {
        type: USER_LOGIN_ERROR,
        message,
    };
};

const userLoginInProgress = () => {
    return {
        type: USER_LOGIN_IN_PROGRESS,
    };
};

export const loginUser = (email, password, cb) => (dispatch) => {
    dispatch(userLoginInProgress());

    Axios.post(USER_LOGIN_URL, {
        emailAddress: email.value,
        password: password,
    }).then((res) => {
        if (res && res.data && res.data.result) {
            dispatch(userLoginSuccess(res.data.result));
            dispatch(userLoginSuccessMessage('Success!'));
            localStorage.setItem('authorizationToken', res.data.result.access_token);
            localStorage.setItem('refreshToken', res.data.result.refresh_token);
            cb(null);
        }
    }).catch((error) => {
        dispatch(userLoginError(error.response ? error.response.data.message : error.message,
        ));
    });
};

export const getAccessToken = (refreshToken, cb) => (dispatch) => {
    dispatch(userLoginInProgress());

    Axios.post(USER_ACCESS_TOKEN, {
        refreshToken: refreshToken,
    }).then((res) => {
        if (res && res.data && res.data.result) {
            dispatch(userLoginSuccess(res.data.result));
            localStorage.setItem('authorizationToken', res.data.result.access_token);
            cb(null);
        }
    }).catch((error) => {
        dispatch(userLoginError(error.response ? error.response.data.message : error.message,
        ));
    });
};
