export const ACCOUNT_BALANCE_FETCH_IN_PROGRESS = 'ACCOUNT_BALANCE_FETCH_IN_PROGRESS';
export const ACCOUNT_BALANCE_FETCH_SUCCESS = 'ACCOUNT_BALANCE_FETCH_SUCCESS';
export const ACCOUNT_BALANCE_FETCH_ERROR = 'ACCOUNT_BALANCE_FETCH_ERROR';

export const TOKENS_FETCH_IN_PROGRESS = 'TOKENS_FETCH_IN_PROGRESS';
export const TOKENS_FETCH_SUCCESS = 'TOKENS_FETCH_SUCCESS';
export const TOKENS_FETCH_ERROR = 'TOKENS_FETCH_ERROR';

export const ACCOUNT_ADDRESS_SET = 'ACCOUNT_ADDRESS_SET';

export const NFT_MINT_IN_PROGRESS = 'NFT_MINT_IN_PROGRESS';
export const NFT_MINT_SUCCESS = 'NFT_MINT_SUCCESS';
export const NFT_MINT_ERROR = 'NFT_MINT_ERROR';

export const NFT_FETCH_IN_PROGRESS = 'NFT_FETCH_IN_PROGRESS';
export const NFT_FETCH_SUCCESS = 'NFT_FETCH_SUCCESS';
export const NFT_FETCH_ERROR = 'NFT_FETCH_ERROR';

export const CONNECT_ACCOUNT_DIALOG_SHOW = 'CONNECT_ACCOUNT_DIALOG_SHOW';
export const CONNECT_ACCOUNT_DIALOG_HIDE = 'CONNECT_ACCOUNT_DIALOG_HIDE';

export const ACCOUNT_SUCCESS_DIALOG_SHOW = 'ACCOUNT_SUCCESS_DIALOG_SHOW';
export const ACCOUNT_SUCCESS_DIALOG_HIDE = 'ACCOUNT_SUCCESS_DIALOG_HIDE';

export const SEND_TOKENS_DIALOG_SHOW = 'SEND_TOKENS_DIALOG_SHOW';
export const SEND_TOKENS_DIALOG_HIDE = 'SEND_TOKENS_DIALOG_HIDE';

export const NETWORK_SET = 'NETWORK_SET';
export const TO_ADDRESS_SET = 'TO_ADDRESS_SET';
export const TOKENS_TO_SEND_SET = 'TOKENS_TO_SEND_SET';

export const ACCOUNT_ADD_IN_PROGRESS = 'ACCOUNT_ADD_IN_PROGRESS';
export const ACCOUNT_ADD_SUCCESS = 'ACCOUNT_ADD_SUCCESS';
export const ACCOUNT_ADD_ERROR = 'ACCOUNT_ADD_ERROR';

export const ACCOUNTS_FETCH_IN_PROGRESS = 'ACCOUNTS_FETCH_IN_PROGRESS';
export const ACCOUNTS_FETCH_SUCCESS = 'ACCOUNTS_FETCH_SUCCESS';
export const ACCOUNTS_FETCH_ERROR = 'ACCOUNTS_FETCH_ERROR';

export const ACCOUNT_AUTHENTICATION_IN_PROGRESS = 'ACCOUNT_AUTHENTICATION_IN_PROGRESS';
export const ACCOUNT_AUTHENTICATION_SUCCESS = 'ACCOUNT_AUTHENTICATION_SUCCESS';
export const ACCOUNT_AUTHENTICATION_ERROR = 'ACCOUNT_AUTHENTICATION_ERROR';

export const ACCOUNT_UPDATE_IN_PROGRESS = 'ACCOUNT_UPDATE_IN_PROGRESS';
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_ERROR = 'ACCOUNT_UPDATE_ERROR';

export const ACCOUNT_DELETE_IN_PROGRESS = 'ACCOUNT_DELETE_IN_PROGRESS';
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_DELETE_ERROR = 'ACCOUNT_DELETE_ERROR';
