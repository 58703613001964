import * as PropTypes from 'prop-types';
import React from 'react';

const StatusIcon = (props) => {
    return (
        <svg fill="none" height="20" viewBox="0 0 26 20" width="26" xmlns="http://www.w3.org/2000/svg">
            <path
                className="svg_cloud"
                d="M19.1541 5.98498C18.9896 4.34415 18.2724 2.82658 17.1397 1.72244C16.007 0.618296 14.5382 0.00507444 13.0143 3.13534e-05C11.4903 -0.00501174 10.0181 0.598477 8.87921 1.6951C7.74028 2.79172 7.01454 4.30453 6.84076 5.94423C6.8349 6.13646 6.76025 6.31868 6.63259 6.45237C6.50493 6.58607 6.33427 6.66076 6.15668 6.66065C4.52444 6.66281 2.95962 7.36601 1.80545 8.616C0.651279 9.86599 0.00199132 11.5607 0 13.3285C0.00669868 14.9832 0.575039 16.5774 1.59681 17.8076C2.61858 19.0379 4.02241 19.8182 5.54102 20L20.5223 19.9963C22.0884 19.8032 23.5271 18.9688 24.5437 17.6642C25.5603 16.3596 26.0779 14.6834 25.9905 12.9789C25.903 11.2744 25.2172 9.6706 24.0734 8.49596C22.9296 7.32132 21.4145 6.66473 19.8382 6.66065C19.6667 6.66138 19.5013 6.59233 19.3746 6.4672C19.2479 6.34206 19.1692 6.16996 19.1541 5.98498Z"
                fill={props.color}/>
            <path d="M17.7645 12.632V7.14307H14.7308V12.632H13L16.25 17.1431L19.5 12.632H17.7645Z" fill="#232323"/>
            <path
                d="M7.28283 11.6542V17.1431H10.9232V11.6542H13.0002L9.10021 7.14307L5.2002 11.6542H7.28283Z"
                fill="#595959"/>
        </svg>
    );
};

StatusIcon.propTypes = {
    color: PropTypes.bool.isRequired,
};

export default StatusIcon;
