import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchAssetsData, openFilterMenu, syncAssets } from '../../actions/asset';
// import syncIcon from '../../assets/sync.svg';
// import syncedIcon from '../../assets/synced.svg';
import Card from '../../components/Card';
import {
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../../constants/url';
import Audio from '../asset/Audio';
import Graphics from '../asset/Graphic';
// import QuickView from '../asset/QuickView';
import Text from '../asset/text';
import Video from '../asset/Video';
import { CardsList } from './CardsList';
import FilterPopover from './FilterPopover';
import './index.css';
import RecentAssets from './RecentAssets';

class AssetsOverview extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            activeTable: 'recentAssets',
            sync: false,
        };
        this.handleAssets = this.handleAssets.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
    }

    componentDidMount () {
        const token = localStorage.getItem('authorizationToken');

        if (token) {
            this.handleFetch('',
                DEFAULT_SKIP,
                DEFAULT_LIMIT,
                DEFAULT_SEARCH_KEY,
                DEFAULT_SORT_BY,
                DEFAULT_ORDER);
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (pp.days !== this.props.days) {
            if (this.state.activeTable === 'recentAssets') {
                this.handleFetch('',
                    DEFAULT_SKIP,
                    DEFAULT_LIMIT,
                    DEFAULT_SEARCH_KEY,
                    DEFAULT_SORT_BY,
                    DEFAULT_ORDER);
            } else {
                this.handleFetch(this.state.activeTable,
                    DEFAULT_SKIP,
                    DEFAULT_LIMIT,
                    DEFAULT_SEARCH_KEY,
                    DEFAULT_SORT_BY,
                    DEFAULT_ORDER);
            }
        }
    }

    handleAssets (name) {
        const token = localStorage.getItem('authorizationToken');

        this.setState({
            activeTable: name,
        });

        if (token) {
            if (name === 'recentAssets') {
                this.handleFetch('',
                    DEFAULT_SKIP,
                    DEFAULT_LIMIT,
                    DEFAULT_SEARCH_KEY,
                    DEFAULT_SORT_BY,
                    DEFAULT_ORDER);
            } else {
                this.handleFetch(name,
                    DEFAULT_SKIP,
                    DEFAULT_LIMIT,
                    DEFAULT_SEARCH_KEY,
                    DEFAULT_SORT_BY,
                    DEFAULT_ORDER);
            }
        }
    }

    handleSync () {
        this.setState({
            sync: !this.state.sync,
        });
    }

    handleFilterMenu (event) {
        this.props.openFilterMenu(event);
    }

    handleFetch (type, skip, limit, searchText, sortBy, order) {
        const token = localStorage.getItem('authorizationToken');

        this.props.fetchAssetsData(type, token, false, true, this.props.days,
            skip,
            limit,
            searchText,
            sortBy,
            order);
    }

    render () {
        const { activeTable } = this.state;
        return (
            <div className="overview_class">
                {/* <div className="sync_section"> */}
                {/* { */}
                {/*    this.state.sync */}
                {/*        ? <div> */}
                {/*            <Button className="synced_button" onClick={() => this.handleSync()}> */}
                {/*                <img alt="Sync Assets" src={syncedIcon}/> Synced */}
                {/*            </Button> */}
                {/*            <p className="synced_updated">Last synced at 16:36:20</p> */}
                {/*        </div> */}
                {/*        : <div> */}
                {/*            <Button className="sync_button" onClick={() => this.handleSync()}> */}
                {/*                <img alt="Sync Assets" src={syncIcon}/> Sync */}
                {/*            </Button> */}
                {/*            <p className="synced_updated"/> */}
                {/*        </div> */}
                {/* } */}
                {/* </div> */}
                <div className="overview">
                    {CardsList.map((item, index) => {
                        const value = this.props.overViewData && this.props.overViewData.length > 0 &&
                            this.props.overViewData.filter((value) => value.type === item);

                        return (
                            <Card
                                key={index}
                                data={value[0]}
                                index={index}
                                item={item}/>
                        );
                    })}
                </div>
                <div className="recent_assets">
                    <div className="assets_header_section">
                        <div className="recent_assets_header">
                            <div
                                className={activeTable === 'recentAssets' ? 'activeTable' : 'table'}
                                onClick={() => this.handleAssets('recentAssets')}> Recent Assets
                            </div>
                            <div className="border_line"/>
                            <div
                                className={activeTable === 'video' ? 'activeTable' : 'table'}
                                onClick={() => this.handleAssets('video')}> Video
                            </div>
                            <div className="border_line"/>
                            <div
                                className={activeTable === 'audio' ? 'activeTable' : 'table'}
                                onClick={() => this.handleAssets('audio')}> Audio
                            </div>
                            <div className="border_line"/>
                            <div
                                className={activeTable === 'graphic' ? 'activeTable' : 'table'}
                                onClick={() => this.handleAssets('graphic')}> Graphics
                            </div>
                            <div className="border_line"/>
                            <div
                                className={activeTable === 'text' ? 'activeTable' : 'table'}
                                onClick={() => this.handleAssets('text')}> Text
                            </div>
                        </div>
                        <div className="filter">
                            <Button onClick={(e) => this.handleFilterMenu(e)}>
                                {
                                    this.props.filterUpdated === '' ? 'Filter'
                                        : this.props.filterUpdated === 'thisWeek' ? 'This Week'
                                            : this.props.filterUpdated === 'thisMonth' ? 'This Month'
                                                : this.props.filterUpdated
                                }
                            </Button>
                        </div>
                    </div>
                    <div className="recent_assets_table data_table">
                        <>
                            {
                                activeTable === 'recentAssets'
                                    ? <RecentAssets/>
                                    : activeTable === 'video'
                                        ? <Video recent={true}/>
                                        : activeTable === 'audio'
                                            ? <Audio recent={true}/>
                                            : activeTable === 'graphic'
                                                ? <Graphics recent={true}/>
                                                : activeTable === 'text'
                                                    ? <Text recent={true}/>
                                                    : null
                            }
                        </>
                    </div>
                </div>
                <FilterPopover/>
            </div>
        );
    }
}

AssetsOverview.propTypes = {
    days: PropTypes.number.isRequired,
    fetchAssetsData: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    overViewData: PropTypes.array.isRequired,
    searchKey: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    filterUpdated: PropTypes.string,
    openFilterMenu: PropTypes.func,
    order: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        days: state.asset.updatedFilter.days,
        url: state.sidebar.tab.url,
        index: state.sidebar.tab.index,
        overViewData: state.asset.assetsOverViewData.list,
        filterUpdated: state.asset.updatedFilter.filter,
        skip: state.asset.assetData.skip,
        limit: state.asset.assetData.limit,
        searchKey: state.asset.assetData.searchKey,
        sortBy: state.asset.assetData.sortBy,
        order: state.asset.assetData.order,
    };
};

const actionToProps = {
    onClick: syncAssets,
    fetchAssetsData,
    openFilterMenu,
};

export default withRouter(connect(stateToProps, actionToProps)(AssetsOverview));
