import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setBugsSlotData, setUpdatedBugsList } from '../../../../../../actions/bugs';
import SelectField from '../../../../../../components/SelectField';

const options = [{
    value: 0,
    name: 0,
}, {
    value: 1,
    name: 1,
}, {
    value: 2,
    name: 2,
}, {
    value: 3,
    name: 3,
}, {
    value: 4,
    name: 4,
}, {
    value: 5,
    name: 5,
}, {
    value: 10,
    name: 10,
}, {
    value: 20,
    name: 20,
}];

const FrequencySelectField = (props) => {
    const onChange = (value) => {
        const arr = props.data;
        arr[props.index].frequency = value;
        if (props.updatedBugs.length > 0) {
            const indArray = props.updatedBugs;
            const alreadyExit = props.updatedBugs.filter((val) => val === props.index);
            if (alreadyExit.length === 0) {
                indArray.push(props.index);
            }
            props.setUpdatedBugsList([...indArray]);
        } else {
            const indArray = [];
            indArray.push(props.index);
            props.setUpdatedBugsList([...indArray]);
        }
        props.setBugsSlotData([...arr]);
    };

    return (
        <SelectField
            id={'frequency_select_field' + props.index}
            items={options}
            name="frequency"
            value={props.value}
            onChange={onChange}/>
    );
};

FrequencySelectField.propTypes = {
    data: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    setBugsSlotData: PropTypes.func.isRequired,
    setUpdatedBugsList: PropTypes.func.isRequired,
    updatedBugs: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        data: state.live.bugs.bugs.list,
        updatedBugs: state.live.bugs.updatedBugs.list,
    };
};

const actionToProps = {
    setBugsSlotData,
    setUpdatedBugsList,
};

export default connect(stateToProps, actionToProps)(FrequencySelectField);
