import { combineReducers } from 'redux';
import {
    ACCOUNT_ADD_ERROR,
    ACCOUNT_ADD_IN_PROGRESS,
    ACCOUNT_ADD_SUCCESS,
    ACCOUNT_ADDRESS_SET,
    ACCOUNT_AUTHENTICATION_SUCCESS,
    ACCOUNT_BALANCE_FETCH_ERROR,
    ACCOUNT_BALANCE_FETCH_IN_PROGRESS,
    ACCOUNT_BALANCE_FETCH_SUCCESS,
    ACCOUNT_SUCCESS_DIALOG_HIDE,
    ACCOUNT_SUCCESS_DIALOG_SHOW,
    CONNECT_ACCOUNT_DIALOG_HIDE,
    CONNECT_ACCOUNT_DIALOG_SHOW,
    NETWORK_SET,
    ACCOUNT_UPDATE_ERROR,
    ACCOUNT_UPDATE_IN_PROGRESS,
    ACCOUNT_UPDATE_SUCCESS,
    ACCOUNTS_FETCH_ERROR,
    ACCOUNTS_FETCH_IN_PROGRESS,
    ACCOUNTS_FETCH_SUCCESS,
    NFT_FETCH_ERROR,
    NFT_FETCH_IN_PROGRESS,
    NFT_FETCH_SUCCESS,
    SEND_TOKENS_DIALOG_SHOW,
    SEND_TOKENS_DIALOG_HIDE,
    TO_ADDRESS_SET,
    TOKENS_TO_SEND_SET,
} from '../constants/account';
import { list } from '../dummy/transactions';

const balance = (state = {
    amount: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ACCOUNT_BALANCE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACCOUNT_BALANCE_FETCH_SUCCESS: {
        return {
            ...state,
            amount: action.value,
            inProgress: false,
        };
    }
    case ACCOUNT_BALANCE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const address = (state = '', action) => {
    if (action.type === ACCOUNT_ADDRESS_SET) {
        return action.value;
    }

    return state;
};

const nft = (state = {
    data: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case NFT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case NFT_FETCH_SUCCESS: {
        return {
            ...state,
            data: action.value,
            inProgress: false,
        };
    }
    case NFT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const connectDialog = (state = false, action) => {
    switch (action.type) {
    case CONNECT_ACCOUNT_DIALOG_SHOW:
        return true;
    case CONNECT_ACCOUNT_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const successDialog = (state = false, action) => {
    switch (action.type) {
    case ACCOUNT_SUCCESS_DIALOG_SHOW:
        return true;
    case ACCOUNT_SUCCESS_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const sendTokensDialog = (state = false, action) => {
    switch (action.type) {
    case SEND_TOKENS_DIALOG_SHOW:
        return true;
    case SEND_TOKENS_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const network = (state = '', action) => {
    if (action.type === NETWORK_SET) {
        return action.value;
    }

    return state;
};

const transactions = (state = list) => {
    return state;
};

const active = (state = false, action) => {
    if (action.type === ACCOUNT_SUCCESS_DIALOG_HIDE) {
        return true;
    }

    return state;
};

const connected = (state = {
    list: {},
    inProgress: false,
    addInProgress: false,
    updateInProgress: false,
}, action) => {
    switch (action.type) {
    case ACCOUNTS_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
        };
    case ACCOUNT_ADD_SUCCESS:
    case ACCOUNT_AUTHENTICATION_SUCCESS: {
        const data = state.list;

        data[action.item._id] = action.item;

        return {
            ...state,
            list: data,
            addInProgress: false,
        };
    }
    case ACCOUNT_ADD_IN_PROGRESS:
        return {
            ...state,
            addInProgress: true,
        };
    case ACCOUNTS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACCOUNT_ADD_ERROR:
        return {
            ...state,
            addInProgress: false,
        };
    case ACCOUNTS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case ACCOUNT_UPDATE_IN_PROGRESS:
        return {
            ...state,
            updateInProgress: true,
        };
    case ACCOUNT_UPDATE_ERROR:
        return {
            ...state,
            updateInProgress: false,
        };
    case ACCOUNT_UPDATE_SUCCESS: {
        const data = state.list;

        data[action.item._id] = action.item;

        return {
            ...state,
            list: data,
            updateInProgress: false,
        };
    }
    default:
        return state;
    }
};

const toAddress = (state = '', action) => {
    if (action.type === TO_ADDRESS_SET) {
        return action.value;
    }

    return state;
};

const tokensToSend = (state = '', action) => {
    if (action.type === TOKENS_TO_SEND_SET) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    balance,
    address,
    nft,
    connectDialog,
    successDialog,
    network,
    transactions,
    active,
    connected,
    sendTokensDialog,
    toAddress,
    tokensToSend,
});
