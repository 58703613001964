import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { assetCategoryInfo, fetchTags } from '../../../../actions/asset';
import { setSlotCategory } from '../../../../actions/slot';
import AutoCompleteTextField from '../../../../components/AutoCompleteTextField';
import LinearProgress from '../../../../components/LinearProgress';
import { stringToUpperFirstChar } from '../../../../utils/string';
import DialogAddAssetCategory from '../../../Sidebar/DialogAddAssetCategory';

class CategorySelect extends Component {
    constructor (props) {
        super(props);
        this.fetch = this.fetch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fetchUpdatedTags = this.fetchUpdatedTags.bind(this);
    }

    componentDidMount () {
        if (this.props.categories.length) {
            return;
        }

        this.fetch();
    }

    fetch () {
        if (this.props.inProgress) {
            return;
        }

        const token = localStorage.getItem('authorizationToken');

        if (token) {
            this.props.fetch(token);
        }
    }

    handleChange (value) {
        if (value && value.length) {
            const categories = value.map((item) => {
                const existValue = this.props.categories.filter((val) => val.name === item.toLowerCase());
                if (existValue && existValue.length > 0) {
                    return existValue[0]._id;
                }
            });
            this.fetchUpdatedTags(categories);
        } else {
            this.fetchUpdatedTags([]);
        }

        this.props.onChange(value);
    }

    fetchUpdatedTags (categories) {
        const token = localStorage.getItem('authorizationToken');

        if (token) {
            this.props.fetchTags(token, categories);
        }
    }

    render () {
        const Items = this.props.categories.length
            ? this.props.categories.map(
                (item) => (stringToUpperFirstChar(item.name)),
            )
            : [];

        return (
            <div className={this.props.inProgress ? 'select_field_progress' : ''}>
                <AutoCompleteTextField
                    label="Category"
                    options={Items}
                    value={this.props.value}
                    onChange={this.handleChange}/>
                {
                    this.props.inProgress
                        ? <LinearProgress/>
                        : null
                }
                <DialogAddAssetCategory/>
            </div>
        );
    }
}

CategorySelect.propTypes = {
    categories: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchTags: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.live.dynamic.categories,
        categories: state.asset.assetCategoriesList.list,
        inProgress: state.asset.new.categories.inProgress,
    };
};

const actionsToProps = {
    fetch: assetCategoryInfo,
    fetchTags: fetchTags,
    onChange: setSlotCategory,
};

export default connect(stateToProps, actionsToProps)(CategorySelect);
