import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import * as PropTypes from 'prop-types';
import React from 'react';

const Bugs = (props) => {
    const bugs = [];
    props.overlays &&
    props.overlays.length > 0 &&
    props.overlays.map((bug, index) => {
        const duration = moment(props.program['end_at'])
            .diff(moment(props.program['start_at']));
        const addTime = moment(bug['start_at'])
            .diff(moment(props.program['start_at']));
        const leftValue = (addTime / duration) * 100;
        bugs.push(
            <Tooltip
                key={index}
                placement="top"
                title={momentTimeZone.tz(bug['start_at'], props.timeZone).format('hh:mm:ss A')}>
                <p
                    className="bug"
                    style={{ left: leftValue + '%' }}/>
            </Tooltip>,
        );

        return bugs;
    });

    return bugs;
};

Bugs.propTypes = {
    overlays: PropTypes.array.isRequired,
    program: PropTypes.object.isRequired,
    timeZone: PropTypes.string.isRequired,
};

export default Bugs;
