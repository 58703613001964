import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
    addInteractionsToInteractiveVideo, deleteInteractionOfInteractiveVideo, getInteractiveVideos,
    setQuestionType, updateInteractionOfInteractiveVideo,
} from '../../../actions/interactiveVideo';
import AddOptionButton from '../MCQType/AddOptionButton';
import CancelButton from '../MCQType/CancelButton';
import DeleteButton from '../MCQType/DeleteButton';
import EditButton from '../MCQType/EditButton';
import OptionTextField from '../MCQType/OptionTextField';
import QuestionTextArea from '../MCQType/QuestionTextArea';
import SaveButton from '../MCQType/SaveButton';
import '../MCQType/index.css';
import GoToTimePicker from './GoToTextField';

const SeekType = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disable && props.edit) {
            handleEdit();
            return;
        }

        if (event.key === 'Enter' && !disable) {
            handleSave();
        }
    };

    const fetchDataToSend = () => {
        const options = [];
        props.options && Object.keys(props.options).length > 0 &&
        Object.keys(props.options).map((key, index) => {
            options.push({
                index: index,
                value: props.options[key].name,
                text: props.options[key].name,
                type: 'TEXT',
                goto: props.options[key].value,
            });
        });

        return {
            startAt: props.player.currentTime(),
            type: 'SEEK',
            question: {
                title: props.question,
                type: 'POLL',
            },
            timeLimit: 10,
            options,
        };
    };

    const handleSave = () => {
        const data = fetchDataToSend();

        props.addInteraction(props.currentInteractiveVideo && props.currentInteractiveVideo._id,
            data, localStorage.getItem('authorizationToken'), (error) => {
                if (!error) {
                    props.player.play();
                    props.setQuestionType('');
                }
            });
    };

    const handleEdit = () => {
        const data = fetchDataToSend();

        props.updateInteraction(data, props.currentInteractiveVideo._id,
            props.currentInteraction._id, localStorage.getItem('authorizationToken'), (error) => {
                if (!error) {
                    props.player.play();
                    props.setQuestionType('');
                }
            });
    };

    const handleDelete = () => {
        const token = localStorage.getItem('authorizationToken');

        props.deleteInteraction(props.currentInteractiveVideo._id,
            props.currentInteraction._id, token,
            (error) => {
                if (error) return;

                props.getInteractiveVideos(props.currentInteractiveVideo &&
                    props.currentInteractiveVideo.asset &&
                    props.currentInteractiveVideo.asset._id,
                token);
                props.player.play();
                props.setQuestionType('');
            });
    };

    const disableArray = props.options && Object.keys(props.options).length > 0 &&
        Object.keys(props.options).filter((key) => props.options[key] === '' ||
            props.options[key].name === '' || !props.options[key].value);

    const disable = disableArray.length > 0 || props.question === '';
    const disableEdit = disableArray.length > 0 && props.question === '';

    return (
        <div className="question_details seek_type">
            <div className="heading_row">
                <p className="heading">Seek Type</p>
                <div className="buttons_section">
                    <CancelButton/>
                    {props.edit
                        ? <EditButton disable={disableEdit} onClick={handleEdit}/>
                        : <SaveButton disable={disable} onClick={handleSave}/>}
                    {props.edit && <DeleteButton onClick={handleDelete}/>}
                </div>
            </div>
            <form
                noValidate
                autoComplete="off"
                onKeyPress={handleKeyPress}
                onSubmit={(e) => {
                    e.preventDefault();
                }}>
                <QuestionTextArea/>
                {props.options && Object.keys(props.options).length > 0 &&
                Object.keys(props.options).map((key) => {
                    return (
                        <div key={key} className="row">
                            <OptionTextField goto={true} index={key} value={props.options[key].name}/>
                            <GoToTimePicker index={key} value={props.options[key].value}/>
                        </div>
                    );
                })}
                <AddOptionButton/>
            </form>
        </div>
    );
};

SeekType.propTypes = {
    addInteraction: PropTypes.func.isRequired,
    deleteInteraction: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
    getInteractiveVideos: PropTypes.func.isRequired,
    options: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    question: PropTypes.string.isRequired,
    setQuestionType: PropTypes.func.isRequired,
    updateInteraction: PropTypes.func.isRequired,
    currentInteraction: PropTypes.shape({
        _id: PropTypes.string.isRequired,
    }),
    currentInteractiveVideo: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        asset: PropTypes.shape({
            _id: PropTypes.string.isRequired,
        }),
    }),
};

const stateToProps = (state) => {
    return {
        edit: state.interactiveVideo.edit,
        player: state.interactiveVideo.player,
        question: state.interactiveVideo.question,
        options: state.interactiveVideo.options.options,
        currentInteraction: state.interactiveVideo.current.interaction,
        currentInteractiveVideo: state.interactiveVideo.current.data,
    };
};

const actionsToProps = {
    setQuestionType,
    getInteractiveVideos,
    addInteraction: addInteractionsToInteractiveVideo,
    updateInteraction: updateInteractionOfInteractiveVideo,
    deleteInteraction: deleteInteractionOfInteractiveVideo,
};

export default connect(stateToProps, actionsToProps)(SeekType);
