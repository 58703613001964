import { IconButton } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../components/Icon';

const CloseButton = (props) => {
    return (
        <IconButton
            className="close_button"
            onClick={props.hideAddAsset}>
            <Icon className="cross" icon="cross"/>
        </IconButton>
    );
};

CloseButton.propTypes = {
    hideAddAsset: PropTypes.func.isRequired,
};

export default CloseButton;
