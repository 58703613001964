import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import DaySelectField from './DaySelectionField';

class Graph extends Component {
    render () {
        const data = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul'],
            datasets: [
                {
                    label: '',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: '#4C4C4C',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#FAC01E',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#FAC01E',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: [1500000, 3900000, 3000000, 4100000, 2300000, 1800000, 2000000],
                },
            ],
        };
        const myChart = {
            type: 'line',
            height: '300px',
            title: {
                display: false,
            },
            options: {
                tooltips: {
                    titleSpacing: 6,
                    titleFontSize: 11,
                    titleFontStyle: 'normal',
                    titleFontColor: '#89A1AD',
                    xPadding: 10,
                    yPadding: 10,
                    bodyFontSize: 11,
                    bodyFontStyle: 'normal',
                    bodyFontColor: '#FFF',
                    callbacks: {
                        label () {
                            return '17.07K views ' +
                                ' 02.42 PM';
                        },
                    },
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                fontColor: '#4C4C4C',
                                fontSize: 0,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Days',
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                labelString: 'No.of Viewers',
                            },
                            ticks: {
                                beginAtZero: true,
                                fontColor: '#4B5F71',
                                fontSize: 0,
                            },
                        },
                    ],
                    legend: {
                        display: false,
                        position: 'bottom',
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            },
            legend: {
                display: false,
            },
        };
        return (
            <>
                <div className="header">
                    <div> 20.0k <span> Watching Now </span></div>
                    <div className="select_day">
                        <DaySelectField/>
                    </div>
                </div>
                <div className="line_graph">
                    <Line
                        data={data}
                        height={250}
                        legend={myChart.legend}
                        options={myChart.options}
                        width={600}
                    />
                </div>
            </>
        );
    }
}

export default Graph;
