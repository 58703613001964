import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setSlotIncludes } from '../../../../../actions/slot';
import AutoCompleteTextField from '../../../../../components/AutoCompleteTextField';
import LinearProgress from '../../../../../components/LinearProgress';
import DialogAddAssetCategory from '../../../../Sidebar/DialogAddAssetCategory';

const Include = (props) => (
    <div className={props.inProgress ? 'select_field_progress' : ''}>
        <AutoCompleteTextField
            label="Includes"
            options={props.items}
            value={props.value}
            onChange={props.onChange}/>
        {
            props.inProgress
                ? <LinearProgress/>
                : null
        }
        <DialogAddAssetCategory/>
    </div>
);

Include.propTypes = {
    inProgress: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        value: state.live.dynamic.includes,
    };
};

const actionsToProps = {
    onChange: setSlotIncludes,
};

export default connect(stateToProps, actionsToProps)(Include);
