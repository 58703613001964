import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, withStyles } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setOwnerShipStatusSelect } from '../../../../actions/asset';
import ClassNames from 'classnames';

const StyleRadio = withStyles({
    root: {
        color: '#ffffff4d',
        '&$checked': {
            color: '#FAC01E',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const OwnerShipStatus = (props) => {
    const handleChange = (event) => {
        props.onChange(event.target.value);
    };

    return (
        <FormControl
            className="owner_ship_status"
            component="fieldset">
            <FormLabel
                className="title"
                component="legend">
                OwnerShip Status
            </FormLabel>
            <RadioGroup
                aria-label="owner-ship-status"
                name="ownerShip"
                value={props.value}
                onChange={handleChange}>
                <FormControlLabel
                    className={ClassNames('label', props.value === 'owned' ? 'active_label' : '')}
                    control={<StyleRadio/>}
                    label="Owned"
                    value="owned"/>
                <FormControlLabel
                    className={ClassNames('label', props.value === 'licensed' ? 'active_label' : '')}
                    control={<StyleRadio/>}
                    label="Licensed"
                    value="licensed"/>
            </RadioGroup>
        </FormControl>
    );
};

OwnerShipStatus.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.ownerShipStatus,
    };
};

const actionToProps = {
    onChange: setOwnerShipStatusSelect,
};

export default connect(stateToProps, actionToProps)(OwnerShipStatus);
