export const CHANNELS_FETCH_IN_PROGRESS = 'CHANNELS_FETCH_IN_PROGRESS';
export const CHANNELS_FETCH_SUCCESS = 'CHANNELS_FETCH_SUCCESS';
export const CHANNELS_FETCH_ERROR = 'CHANNELS_FETCH_ERROR';
export const CHANNEL_LIVE_STREAMS_FETCH_IN_PROGRESS = 'CHANNEL_LIVE_STREAMS_FETCH_IN_PROGRESS';
export const CHANNEL_LIVE_STREAMS_FETCH_SUCCESS = 'CHANNEL_LIVE_STREAMS_FETCH_SUCCESS';
export const CHANNEL_LIVE_STREAMS_FETCH_ERROR = 'CHANNEL_LIVE_STREAMS_FETCH_ERROR';
export const CURRENT_CHANNEL_SET = 'ADD_CHANNEL_SET';
export const ADD_STREAM_SET = 'ADD_STREAM_SET';
export const CHANNELS_AUTHENTICATION_IN_PROGRESS = 'CHANNELS_AUTHENTICATION_IN_PROGRESS';
export const CHANNELS_AUTHENTICATION_SUCCESS = 'CHANNELS_AUTHENTICATION_SUCCESS';
export const CHANNELS_AUTHENTICATION_ERROR = 'CHANNELS_AUTHENTICATION_ERROR';

export const CHANNEL_LIVE_STREAM_CONFIG_FETCH_IN_PROGRESS = 'CHANNEL_LIVE_STREAM_CONFIG_FETCH_IN_PROGRESS';
export const CHANNEL_LIVE_STREAM_CONFIG_FETCH_SUCCESS = 'CHANNEL_LIVE_STREAM_CONFIG_FETCH_SUCCESS';
export const CHANNEL_LIVE_STREAM_CONFIG_FETCH_ERROR = 'CHANNEL_LIVE_STREAM_CONFIG_FETCH_ERROR';

export const CHANNEL_LIVE_STREAM_TEXT_ADD_IN_PROGRESS = 'CHANNEL_LIVE_STREAM_TEXT_ADD_IN_PROGRESS';
export const CHANNEL_LIVE_STREAM_TEXT_ADD_SUCCESS = 'CHANNEL_LIVE_STREAM_TEXT_ADD_SUCCESS';
export const CHANNEL_LIVE_STREAM_TEXT_ADD_ERROR = 'CHANNEL_LIVE_STREAM_TEXT_ADD_ERROR';

export const LINEAR_BROADCAST_MINT_IN_PROGRESS = 'LINEAR_BROADCAST_MINT_IN_PROGRESS';
export const LINEAR_BROADCAST_MINT_SUCCESS = 'LINEAR_BROADCAST_MINT_SUCCESS';
export const LINEAR_BROADCAST_MINT_ERROR = 'LINEAR_BROADCAST_MINT_ERROR';
