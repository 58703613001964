import { combineReducers } from 'redux';
import { DISTRIBUTION_PLATFORM_SET, SUBSCRIPTION_TYPE_SET } from '../constants/subscription';

const distributionPlatforms = (state = {
    nucleus: true,
    youtube: true,
}, action) => {
    if (action.type === DISTRIBUTION_PLATFORM_SET) {
        return action.value;
    }

    return state;
};

const subscriptionType = (state = {
    free: true,
}, action) => {
    if (action.type === SUBSCRIPTION_TYPE_SET) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    distributionPlatforms,
    subscriptionType,
});
