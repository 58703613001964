import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setQuestion } from '../../../actions/interactiveVideo';
import TextField from '../../../components/TextField/InputProps';

const QuestionTextArea = (props) => {
    return (
        <TextField
            className="description_text_field"
            id="question_text_field"
            label="Question"
            multiline={true}
            name="text"
            value={props.value}
            variant="outlined"
            onChange={props.onChange}/>
    );
};

QuestionTextArea.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.interactiveVideo.question,
    };
};

const actionsToProps = {
    onChange: setQuestion,
};

export default connect(stateToProps, actionsToProps)(QuestionTextArea);
