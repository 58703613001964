import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setSlotName } from '../../../../../actions/slot';
import TextField from '../../../../../components/TextField';

const SlotNameTextField = (props) => {
    return (
        <TextField
            id="slot_name_text_field"
            name="slotName"
            value={props.value}
            onChange={props.onChange}/>
    );
};

SlotNameTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.live.slotSettings.slotName,
    };
};

const actionToProps = {
    onChange: setSlotName,
};

export default connect(stateToProps, actionToProps)(SlotNameTextField);
