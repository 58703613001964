import Axios from 'axios';
import {
    ADD_ASSET_ALBUM_SET,
    ADD_ASSET_CATEGORY_NEW_LABEL,
    ADD_ASSET_CATEGORY_SET,
    ADD_ASSET_DATE_OF_UPLOAD_SET,
    ADD_ASSET_DATE_PUBLISHED_SET,
    ADD_ASSET_DURATION_SET,
    ADD_ASSET_ENCODED_STATE_SET,
    ADD_ASSET_FILE_NAME_SET,
    ADD_ASSET_FILE_TYPE_SET,
    ADD_ASSET_GENRE_SET,
    ADD_ASSET_ID_SET,
    ADD_ASSET_LANGUAGE_SET,
    ADD_ASSET_LAST_RUN_SET,
    ADD_ASSET_NAME_SET,
    ADD_ASSET_PUBLISHED_FOR_VOD_SET,
    ADD_ASSET_SOURCE_SET,
    ADD_ASSET_TAGS_SET,
    ADD_FILE_SET,
    ADD_GENRE_NEW_LABEL,
    ADD_SOURCE_TYPE_SET,
    ADD_SOURCE_URL_SET,
    ALBUM_LIST_IN_PROGRESS,
    ALBUM_LIST_SUCCESS,
    ALBUM_SET,
    ART_DIRECTOR,
    ASSET_ADD_ERROR,
    ASSET_ADD_IN_PROGRESS,
    ASSET_ADD_SUCCESS,
    ASSET_ADD_SUCCESS_MESSAGE,
    ASSET_CATEGORIES_ERROR,
    ASSET_CATEGORIES_LIST,
    ASSET_CATEGORIES_LIST_ENCODED,
    ASSET_CATEGORY_DELETE_ERROR,
    ASSET_CATEGORY_DELETE_IN_PROGRESS,
    ASSET_CATEGORY_DELETE_SUCCESS,
    ASSET_CATEGORY_DELETE_SUCCESS_MESSAGE,
    ASSET_CATEGORY_TABLE_ERROR,
    ASSET_CATEGORY_TABLE_LIST,
    ASSET_CATEGORY_UPDATE_ERROR,
    ASSET_CATEGORY_UPDATE_IN_PROGRESS,
    ASSET_CATEGORY_UPDATE_SUCCESS,
    ASSET_CATEGORY_UPDATE_SUCCESS_MESSAGE,
    ASSET_DELETE_ERROR,
    ASSET_DELETE_IN_PROGRESS,
    ASSET_DELETE_SUCCESS,
    ASSET_FETCH_ERROR,
    ASSET_FETCH_IN_PROGRESS,
    ASSET_FETCH_SUCCESS,
    ASSET_FILE_ADD_ERROR,
    ASSET_FILE_ADD_IN_PROGRESS,
    ASSET_FILE_ADD_SUCCESS,
    ASSET_FILE_ADD_SUCCESS_MESSAGE,
    ASSET_FILE_ENCODE_ERROR,
    ASSET_FILE_ENCODE_IN_PROGRESS,
    ASSET_FILE_ENCODE_SUCCESS,
    ASSET_FILE_ENCODE_SUCCESS_MESSAGE,
    ASSET_FILES_ERROR,
    ASSET_FILES_IN_PROGRESS,
    ASSET_FILES_LIST,
    ASSET_GENRES_FETCH_ERROR,
    ASSET_GENRES_FETCH_IN_PROGRESS,
    ASSET_GENRES_FETCH_SUCCESS,
    ASSET_SCENES_FETCH_ERROR,
    ASSET_SCENES_FETCH_IN_PROGRESS,
    ASSET_SCENES_FETCH_SUCCESS,
    ASSET_SOURCE_TYPE_FETCH_IN_PROGRESS,
    ASSET_SOURCE_TYPE_FETCH_SUCCESS,
    ASSET_TAGS_FETCH_ERROR,
    ASSET_TAGS_FETCH_IN_PROGRESS,
    ASSET_TAGS_FETCH_SUCCESS,
    ASSET_UPDATE_ERROR,
    ASSET_UPDATE_IN_PROGRESS,
    ASSET_UPDATE_SUCCESS,
    ASSET_UPDATE_SUCCESS_MESSAGE,
    ASSETS_CATEGORIES_FETCH_ERROR,
    ASSETS_CATEGORIES_FETCH_IN_PROGRESS,
    ASSETS_CATEGORIES_FETCH_SUCCESS,
    ASSETS_DATA_FETCH_ERROR,
    ASSETS_DATA_FETCH_IN_PROGRESS,
    ASSETS_DATA_FETCH_SUCCESS,
    ASSETS_FETCH_ERROR,
    ASSETS_FETCH_IN_PROGRESS,
    ASSETS_FETCH_SUCCESS,
    ASSETS_LANGUAGES_FETCH_ERROR,
    ASSETS_LANGUAGES_FETCH_IN_PROGRESS,
    ASSETS_LANGUAGES_FETCH_SUCCESS,
    ASSETS_OVERVIEW_DATA_FETCH_ERROR,
    ASSETS_OVERVIEW_DATA_FETCH_IN_PROGRESS,
    ASSETS_OVERVIEW_DATA_FETCH_SUCCESS,
    CAST_CREW_SET,
    CAST_SET,
    CHANGED_FILTER,
    CINEMATOGRAPHY_SET,
    COMPUTER_GRAPHICS_SET,
    COUNTRY_SET,
    CREW_SET,
    CURRENT_ASSET_SET, DELETE_ASSET_THUMBNAIL_ERROR, DELETE_ASSET_THUMBNAIL_IN_PROGRESS, DELETE_ASSET_THUMBNAIL_SUCCESS,
    DIALOG_ACTION_MENU_HIDE,
    DIALOG_ACTION_MENU_SHOW,
    DIALOG_ADD_ALBUM_HIDE,
    DIALOG_ADD_ALBUM_SHOW,
    DIALOG_ADD_ASSET_CATEGORY_HIDE,
    DIALOG_ADD_ASSET_CATEGORY_SHOW,
    DIALOG_ADD_ASSET_HIDE,
    DIALOG_ADD_ASSET_SHOW,
    DIALOG_ADD_GENRE_HIDE,
    DIALOG_ADD_GENRE_SHOW,
    DIALOG_ADD_PEOPLE_HIDE,
    DIALOG_ADD_PEOPLE_SHOW,
    DIALOG_ADD_VIDEO_HIDE,
    DIALOG_ADD_VIDEO_SHOW,
    DIALOG_CONFIRM_GENRE_HIDE,
    DIALOG_CONFIRM_GENRE_SHOW,
    DIALOG_CONFIRM_HIDE,
    DIALOG_CONFIRM_SHOW,
    DIALOG_DELETE_THUMB_HIDE,
    DIALOG_DELETE_THUMB_SHOW,
    DIALOG_EDIT_ASSET_HIDE,
    DIALOG_EDIT_ASSET_SHOW,
    DIALOG_UPDATE_THUMB_HIDE,
    DIALOG_UPDATE_THUMB_SHOW, DIALOG_UPLOAD_THUMB_HIDE, DIALOG_UPLOAD_THUMB_SHOW,
    DIALOG_VIEW_THUMB_HIDE,
    DIALOG_VIEW_THUMB_SHOW,
    DIALOGUES_SET,
    DIRECTOR_SET,
    DOWNLOAD_CANCEL_SET,
    DOWNLOAD_PROGRESS_SET,
    DURATION_SET,
    EDITABLE_ASSET_AUDIO_DATA,
    EDITABLE_ASSET_DATA,
    EDITABLE_ASSET_IMAGE_DATA,
    EDITABLE_ASSET_TEXT_DATA,
    EDITABLE_ASSET_VIDEO_DATA,
    EDITOR_SET,
    ENCODED_ASSETS_OVERVIEW_DATA_FETCH_SUCCESS,
    FILE_DOWNLOAD_ERROR,
    FILE_DOWNLOAD_IN_PROGRESS,
    FILE_DOWNLOAD_SUCCESS,
    FILE_UPLOAD_ERROR,
    FILE_UPLOAD_IN_PROGRESS,
    FILE_UPLOAD_SUCCESS,
    GENRE_DELETE_ERROR,
    GENRE_DELETE_IN_PROGRESS,
    GENRE_DELETE_SUCCESS,
    GENRE_DELETE_SUCCESS_MESSAGE,
    GENRE_UPDATE_ERROR,
    GENRE_UPDATE_IN_PROGRESS,
    GENRE_UPDATE_SUCCESS,
    GENRE_UPDATE_SUCCESS_MESSAGE,
    INSTAGRAM_ID_SET,
    IS_RC_CODE_SET,
    LYRICISTS_SET,
    MEDIA_NFT_ID_SET,
    MOOD_SET,
    MUSIC_COMPOSERS_SET,
    MUSIC_DIRECTORS_SET,
    NEW_ASSET_CATEGORY_ADD_ERROR,
    NEW_ASSET_CATEGORY_ADD_IN_PROGRESS,
    NEW_ASSET_CATEGORY_ADD_SUCCESS,
    NEW_ASSET_CATEGORY_ADD_SUCCESS_MESSAGE,
    NEW_GENRE_ADD_ERROR,
    NEW_GENRE_ADD_IN_PROGRESS,
    NEW_GENRE_ADD_SUCCESS,
    NEW_GENRE_ADD_SUCCESS_MESSAGE,
    OWNERSHIP_STATUS_SELECT_SET,
    OWNERSHIP_STATUS_SET,
    POPOVER_FILTER_HIDE,
    POPOVER_FILTER_SHOW,
    PRODUCER_SET,
    QUICK_VIEW_ASSET_DATA,
    QUICK_VIEW_MOVIE_ASSET_HIDE,
    QUICK_VIEW_MOVIE_ASSET_SHOW,
    RATING_SET,
    RECORD_LABEL_SET,
    RELEASE_DATE_SET,
    SHOW_PREVIEW_SET,
    SINGERS_SET,
    STORY_SET,
    STYLE_COSTUME_DESIGNER,
    STYLIST_SET,
    TAB_VALUE_SET,
    TAGS_LIST_FETCH_ERROR,
    TAGS_LIST_FETCH_IN_PROGRESS,
    TAGS_LIST_FETCH_SUCCESS,
    TWITTER_ID_SET, UPLOAD_ASSET_THUMBNAIL_ERROR, UPLOAD_ASSET_THUMBNAIL_IN_PROGRESS, UPLOAD_ASSET_THUMBNAIL_SUCCESS,
    UPLOAD_CANCEL_SET,
    UPLOAD_FILE_SET,
    UPLOAD_PROGRESS_SET, UPLOADED_THUMBS_LIST,
    YOUTUBE_ID_SET,
    ASSET_NFT_UPDATE_ERROR,
    ASSET_NFT_UPDATE_IN_PROGRESS,
    ASSET_NFT_UPDATE_SUCCESS,
} from '../constants/asset';
import {
    downloadFileURL,
    getAssetListURL,
    getAssetsOverviewUrl,
    getTagsURL,
    TAGS_LIST_URL, UPLOAD_ASSET_THUMB,
    uploadFileURL,
    URL_ASSET_CATEGORIES_GET,
    URL_ASSET_GENRES_GET,
    URL_ASSET_LIST,
    URL_ASSETS_LIST,
    URL_CHANNEL_CATEGORIES_GET,
    URL_CHANNEL_LANGUAGES_GET,
    URL_FILES_LIST,
    URL_SYNC_ASSETS,
    urlFetchAsset,
    urlFetchAssets,
    urlUpdateAssetNFT,
    urlFetchScenes, reUploadFileURL,
} from '../constants/url';
import { stringToUpperFirstChar } from '../utils/string';

const token = localStorage.getItem('authorizationToken');

export const editableData = (value) => {
    return {
        type: EDITABLE_ASSET_DATA,
        value,
    };
};

export const editableVideoAsset = (value) => {
    return {
        type: EDITABLE_ASSET_VIDEO_DATA,
        value,
    };
};

export const editableAudioAsset = (value) => {
    return {
        type: EDITABLE_ASSET_AUDIO_DATA,
        value,
    };
};

export const editableImageAsset = (value) => {
    return {
        type: EDITABLE_ASSET_IMAGE_DATA,
        value,
    };
};

export const editableTextAsset = (value) => {
    return {
        type: EDITABLE_ASSET_TEXT_DATA,
        value,
    };
};

export const showQuickViewMovieAsset = (value) => {
    return {
        type: QUICK_VIEW_MOVIE_ASSET_SHOW,
        value,
    };
};

export const hideQuickViewMovieAsset = () => {
    return {
        type: QUICK_VIEW_MOVIE_ASSET_HIDE,
    };
};

export const showDialogEditAsset = (value) => {
    return {
        type: DIALOG_EDIT_ASSET_SHOW,
        assetType: value,
    };
};

export const hideDialogEditAsset = () => {
    return {
        type: DIALOG_EDIT_ASSET_HIDE,
    };
};

export const showDialogActionMenu = (id, assetType) => {
    return {
        type: DIALOG_ACTION_MENU_SHOW,
        id,
        assetType,
    };
};

export const hideDialogActionMenu = () => {
    return {
        type: DIALOG_ACTION_MENU_HIDE,
    };
};

export const selectedRowData = (data, state) => {
    return {
        type: QUICK_VIEW_ASSET_DATA,
        data,
        state,
    };
};

export const showDialogAddAsset = (value) => {
    return {
        type: DIALOG_ADD_ASSET_SHOW,
        assetType: value,
    };
};

export const hideDialogAddAsset = () => {
    return {
        type: DIALOG_ADD_ASSET_HIDE,
    };
};

// export const showDialogUpdateAsset = () => {
//     return {
//         type: DIALOG_UPDATE_ASSET_SHOW,
//     };
// };
// export const hideDialogUpdateAsset = () => {
//     return {
//         type: DIALOG_UPDATE_ASSET_HIDE,
//     };
// };

export const setAddAssetID = (value) => {
    return {
        type: ADD_ASSET_ID_SET,
        value,
    };
};

export const setAddAssetName = (value) => {
    return {
        type: ADD_ASSET_NAME_SET,
        value,
    };
};

export const setAddAssetFileName = (value) => {
    return {
        type: ADD_ASSET_FILE_NAME_SET,
        value,
    };
};

export const setAddAssetGenre = (value) => {
    return {
        type: ADD_ASSET_GENRE_SET,
        value,
    };
};

export const setAddAssetFileType = (value) => {
    return {
        type: ADD_ASSET_FILE_TYPE_SET,
        value,
    };
};

export const setAddAssetDuration = (value) => {
    return {
        type: ADD_ASSET_DURATION_SET,
        value,
    };
};

export const setAddAssetSource = (value) => {
    return {
        type: ADD_ASSET_SOURCE_SET,
        value,
    };
};

export const setAddAssetDateofUpload = (value) => {
    return {
        type: ADD_ASSET_DATE_OF_UPLOAD_SET,
        value,
    };
};

export const setAddAssetEncodedState = (value) => {
    return {
        type: ADD_ASSET_ENCODED_STATE_SET,
        value,
    };
};

export const setAddAssetPublishedForVOD = (value) => {
    return {
        type: ADD_ASSET_PUBLISHED_FOR_VOD_SET,
        value,
    };
};

export const setAddAssetDatePublished = (value) => {
    return {
        type: ADD_ASSET_DATE_PUBLISHED_SET,
        value,
    };
};

export const setAddAssetLastRun = (value) => {
    return {
        type: ADD_ASSET_LAST_RUN_SET,
        value,
    };
};

export const setAddSourceType = (value) => {
    return {
        type: ADD_SOURCE_TYPE_SET,
        value,
    };
};

export const setAddUrl = (value) => {
    return {
        type: ADD_SOURCE_URL_SET,
        value,
    };
};

export const setAddFile = (value) => {
    return {
        type: ADD_FILE_SET,
        value,
    };
};

const fetchAssetsInProgress = () => {
    return {
        type: ASSETS_FETCH_IN_PROGRESS,
    };
};

const fetchAssetsSuccess = (items) => {
    const assets = {};

    items.forEach(
        (item) => {
            assets[item._id] = item;
        });

    return {
        type: ASSETS_FETCH_SUCCESS,
        items: assets,
    };
};

const fetchAssetsError = (message) => {
    return {
        type: ASSETS_FETCH_ERROR,
        message,
    };
};

export const fetchAssets = (token, cb) => (dispatch) => {
    dispatch(fetchAssetsInProgress());

    const URL = urlFetchAssets('complete');
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            const items = res.data.result.list;

            dispatch(fetchAssetsSuccess(items));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchAssetsError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const fetchGenresInProgress = () => {
    return {
        type: ASSET_GENRES_FETCH_IN_PROGRESS,
    };
};

const fetchGenresSuccess = (items) => {
    return {
        type: ASSET_GENRES_FETCH_SUCCESS,
        items,
    };
};

const fetchGenresError = (message) => {
    return {
        type: ASSET_GENRES_FETCH_ERROR,
        message,
    };
};

export const fetchGenres = (token) => (dispatch) => {
    dispatch(fetchGenresInProgress());

    Axios.get(URL_ASSET_GENRES_GET, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            const items = res.data.result.map(
                (item) => ({
                    value: item._id,
                    name: stringToUpperFirstChar(item.name),
                }),
            );

            dispatch(fetchGenresSuccess(items));
        })
        .catch((error) => {
            dispatch(fetchGenresError(error.response ? error.response.data.message : error.message,
            ));
        });
};

export const addGenres = () => (dispatch) => {
    dispatch(fetchGenresInProgress());

    Axios.post(URL_ASSET_GENRES_GET, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            const items = res.data.result.map(
                (item) => ({
                    value: item._id,
                    name: stringToUpperFirstChar(item.name),
                }),
            );

            dispatch(fetchGenresSuccess(items));
        })
        .catch((error) => {
            dispatch(fetchGenresError(error.response ? error.response.data.message : error.message,
            ));
        });
};

const fetchSourceTypeInProgress = () => {
    return {
        type: ASSET_SOURCE_TYPE_FETCH_IN_PROGRESS,
    };
};

const fetchSourceTypeSuccess = (items) => {
    return {
        type: ASSET_SOURCE_TYPE_FETCH_SUCCESS,
        items,
    };
};

const SourceType = [{
    value: 'youtube',
    label: 'YouTube',
}, {
    value: 'twitter',
    label: 'Twitter',
}, {
    value: 'studio',
    label: 'Studio',
}];

export const getSourceType = () => (dispatch) => {
    dispatch(fetchSourceTypeInProgress());
    const items = SourceType.map(
        (item) => ({
            value: item.value,
            name: stringToUpperFirstChar(item.label),
        }),
    );
    dispatch(fetchSourceTypeSuccess(items));
};

const assetCategoryTableList = (list) => {
    return {
        type: ASSET_CATEGORY_TABLE_LIST,
        list,
    };
};

const tableDataError = (message) => {
    return {
        type: ASSET_CATEGORY_TABLE_ERROR,
        message,
    };
};

export const showAssetCategoryTableList = (category, authToken) => (dispatch) => {
    const url = category ? URL_ASSET_LIST + '?category=' + category : URL_ASSET_LIST;
    Axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result.list) {
            dispatch(assetCategoryTableList(res.data.result.list));
        }
    }).catch((error) => {
        if (error) {
            dispatch(tableDataError(error.response ? error.response.data.message : error.message,
            ));
        }
    });
};

const addAssetInProgress = () => {
    return {
        type: ASSET_ADD_IN_PROGRESS,
    };
};

const addAssetSuccess = (result) => {
    return {
        type: ASSET_ADD_SUCCESS,
        result,
    };
};

const addAssetSuccessMessage = (message) => {
    return {
        type: ASSET_ADD_SUCCESS_MESSAGE,
        message,
    };
};

const addAssetError = (message) => {
    return {
        type: ASSET_ADD_ERROR,
        message,
    };
};

export const addAsset = (data, authToken, cb) =>
    (dispatch) => {
        dispatch(addAssetInProgress());

        Axios({
            method: 'post',
            url: URL_ASSET_LIST,
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + authToken,
            },
            data,
        }).then((res) => {
            if (res && res.data && res.data.result) {
                dispatch(addAssetSuccess(res.data.result));
                dispatch(addAssetSuccessMessage('Added Asset'));
                dispatch(fetchAsset(res.data.result && res.data.result._id, authToken, () => {
                }));
                cb(null);
            }
        }).catch((error) => {
            dispatch(addAssetError(error.response ? error.response.data.message : error.message,
            ));
        });
    };

const updateAssetInProgress = () => {
    return {
        type: ASSET_UPDATE_IN_PROGRESS,
    };
};

const updateAssetSuccess = (data) => {
    return {
        type: ASSET_UPDATE_SUCCESS,
        data,
    };
};

const updateAssetSuccessMessage = (message) => {
    return {
        type: ASSET_UPDATE_SUCCESS_MESSAGE,
        message,
    };
};

const updateAssetError = (message) => {
    return {
        type: ASSET_UPDATE_ERROR,
        message,
    };
};

export const updateAsset = (id, data, authToken, cb) => (dispatch) => {
    dispatch(updateAssetInProgress());

    Axios({
        method: 'put',
        url: URL_ASSET_LIST + '/' + id,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
        data,
    }).then((res) => {
        if (res && res.data && res.data.result) {
            dispatch(updateAssetSuccess(res.data.result));
            dispatch(updateAssetSuccessMessage('Updated Asset'));
            dispatch(fetchAsset(id, authToken, () => {
            }));
            cb(res.data.result);
        }
    }).catch((error) => {
        dispatch(updateAssetError(error.response ? error.response.data.message : error.message,
        ));
        cb(null);
    });
};

export const showDialogAddAssetsCategory = () => {
    return {
        type: DIALOG_ADD_ASSET_CATEGORY_SHOW,
    };
};

export const hideDialogAddAssetsCategory = () => {
    return {
        type: DIALOG_ADD_ASSET_CATEGORY_HIDE,
    };
};

export const showDialogAddGenre = () => {
    return {
        type: DIALOG_ADD_GENRE_SHOW,
    };
};

export const showDialogAddVideo = () => {
    return {
        type: DIALOG_ADD_VIDEO_SHOW,
    };
};

export const hideDialogAddVideo = () => {
    return {
        type: DIALOG_ADD_VIDEO_HIDE,
    };
};

export const hideDialogAddGenre = () => {
    return {
        type: DIALOG_ADD_GENRE_HIDE,
    };
};

export const showDialogAddAlbum = () => {
    return {
        type: DIALOG_ADD_ALBUM_SHOW,
    };
};

export const hideDialogAddAlbum = () => {
    return {
        type: DIALOG_ADD_ALBUM_HIDE,
    };
};

export const showDialogConfirmGenre = (id) => {
    return {
        type: DIALOG_CONFIRM_GENRE_SHOW,
        id,
    };
};

export const hideDialogConfirmGenre = () => {
    return {
        type: DIALOG_CONFIRM_GENRE_HIDE,
    };
};

export const showDialogConfirm = (id) => {
    return {
        type: DIALOG_CONFIRM_SHOW,
        id,
    };
};

export const hideDialogConfirm = () => {
    return {
        type: DIALOG_CONFIRM_HIDE,
    };
};

const assetCategoriesList = (list) => {
    return {
        type: ASSET_CATEGORIES_LIST,
        list,
    };
};

const assetCategoriesListEncoded = (list) => {
    return {
        type: ASSET_CATEGORIES_LIST_ENCODED,
        list,
    };
};

const assetCategoriesError = (message) => {
    return {
        type: ASSET_CATEGORIES_ERROR,
        message,
    };
};

export const assetCategoryInfo = (authToken, encode) => (dispatch) => {
    Axios.get(encode ? `${URL_ASSET_CATEGORIES_GET}?encode=${encode}`
        : URL_ASSET_CATEGORIES_GET, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
    }).then((res) => {
        if (res && res.data && res.data.result) {
            dispatch(encode
                ? assetCategoriesListEncoded(res.data.result)
                : assetCategoriesList(res.data.result));
        }
    }).catch((error) => {
        dispatch(assetCategoriesError(error.response ? error.response.data.message : error.message,
        ));
    });
};

export const setGenreNewLabel = (value) => {
    return {
        type: ADD_GENRE_NEW_LABEL,
        value,
    };
};

const addNewGenreInProgress = () => {
    return {
        type: NEW_GENRE_ADD_IN_PROGRESS,
    };
};

const addNewGenreSuccess = (message) => {
    return {
        type: NEW_GENRE_ADD_SUCCESS,
        message,
    };
};

const addNewGenreSuccessMessage = (message) => {
    return {
        type: NEW_GENRE_ADD_SUCCESS_MESSAGE,
        message,
    };
};

const addNewGenreError = (message) => {
    return {
        type: NEW_GENRE_ADD_ERROR,
        message,
    };
};

export const addNewGenre = (value, authToken, cb) => (dispatch) => {
    dispatch(addNewGenreInProgress());
    Axios({
        method: 'post',
        url: URL_ASSET_GENRES_GET,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
        data: {
            name: value,
        },
    }).then((res) => {
        if (res) {
            dispatch(addNewGenreSuccess(res));
            dispatch(addNewGenreSuccessMessage('Asset category added successfully'));
            cb(null);
        }
    }).catch((error) => {
        dispatch(addNewGenreError(error.response ? error.response.data.message : error.message,
        ));
    });
};

const deleteAssetInProgress = () => {
    return {
        type: ASSET_DELETE_IN_PROGRESS,
    };
};

const deleteAssetSuccess = (message, id) => {
    return {
        type: ASSET_DELETE_SUCCESS,
        message,
        id,
    };
};

const deleteAssetError = (message) => {
    return {
        type: ASSET_DELETE_ERROR,
        message,
    };
};

export const deleteAsset = (id, authToken, source, encoded, cb) => (dispatch) => {
    dispatch(deleteAssetInProgress());
    const params = [];

    if (source) {
        params.push(`source=${source}`);
    }
    if (encoded) {
        params.push(`encoded=${encoded}`);
    }

    const url = URL_ASSETS_LIST + '/' + id._id + `?${params.join('&')}`;

    Axios({
        method: 'delete',
        url: url,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
    }).then(() => {
        dispatch(deleteAssetSuccess('Successfully deleted', id._id));
        cb(null);
    }).catch((error) => {
        dispatch(deleteAssetError(error.response ? error.response.data.message : error.message,
        ));
    });
};

const fetchAssetsDataInProgress = () => {
    return {
        type: ASSETS_DATA_FETCH_IN_PROGRESS,
    };
};

const fetchAssetsDataSuccess = (items, count, skip, limit, searchKey, sortBy, order) => {
    return {
        type: ASSETS_DATA_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        searchKey,
        sortBy,
        order,
    };
};

const fetchAssetsDataError = (message) => {
    return {
        type: ASSETS_DATA_FETCH_ERROR,
        message,
    };
};

export const fetchAssetsData = (type, token, encode, recent, days, skip, limit, searchKey, sortBy, order) => (dispatch) => {
    dispatch(fetchAssetsDataInProgress());

    const url = getAssetListURL(type, encode, recent, days, skip, limit, searchKey, sortBy, order);

    Axios.get(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            const items = res.data.result.list;
            const count = res.data.result.count;

            dispatch(fetchAssetsDataSuccess(items, count, skip, limit, searchKey, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchAssetsDataError(error.response ? error.response.data.message : error.message));
        });
};

const fetchAssetsOverviewDataInProgress = () => {
    return {
        type: ASSETS_OVERVIEW_DATA_FETCH_IN_PROGRESS,
    };
};

const fetchAssetsOverviewDataSuccess = (items) => {
    return {
        type: ASSETS_OVERVIEW_DATA_FETCH_SUCCESS,
        items,
    };
};

const fetchEncodedAssetsOverviewDataSuccess = (items) => {
    return {
        type: ENCODED_ASSETS_OVERVIEW_DATA_FETCH_SUCCESS,
        items,
    };
};

const fetchAssetsOverviewDataError = (message) => {
    return {
        type: ASSETS_OVERVIEW_DATA_FETCH_ERROR,
        message,
    };
};

export const fetchAssetsOverviewData = (token, encode) => (dispatch) => {
    dispatch(fetchAssetsOverviewDataInProgress);

    const URL = getAssetsOverviewUrl(encode);

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            const items = res.data.result;

            dispatch(encode
                ? fetchEncodedAssetsOverviewDataSuccess(items)
                : fetchAssetsOverviewDataSuccess(items));
        })
        .catch((error) => {
            dispatch(fetchAssetsOverviewDataError(error.response ? error.response.data.message : error.message));
        });
};

const deleteGenreInProgress = () => {
    return {
        type: GENRE_DELETE_IN_PROGRESS,
    };
};

const deleteGenreSuccess = (message) => {
    return {
        type: GENRE_DELETE_SUCCESS,
        message,
    };
};

const deleteGenreSuccessMessage = (message) => {
    return {
        type: GENRE_DELETE_SUCCESS_MESSAGE,
        message,
    };
};

const deleteGenreError = (message) => {
    return {
        type: GENRE_DELETE_ERROR,
        message,
    };
};

export const deleteGenre = (id, authToken, cb) => (dispatch) => {
    dispatch(deleteGenreInProgress());

    Axios({
        method: 'delete',
        url: URL_ASSET_GENRES_GET + '/' + id,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
    }).then((res) => {
        if (res) {
            dispatch(deleteGenreSuccess(res));
            dispatch(deleteGenreSuccessMessage('Successfully deleted'));
            cb(null);
        }
    }).catch((error) => {
        dispatch(deleteGenreError(error.response ? error.response.data.message : error.message,
        ));
    });
};

const updateGenreInProgress = () => {
    return {
        type: GENRE_UPDATE_IN_PROGRESS,
    };
};

const updateGenreSuccess = (message) => {
    return {
        type: GENRE_UPDATE_SUCCESS,
        message,
    };
};

const updateGenreSuccessMessage = (message) => {
    return {
        type: GENRE_UPDATE_SUCCESS_MESSAGE,
        message,
    };
};

const updateGenreError = (message) => {
    return {
        type: GENRE_UPDATE_ERROR,
        message,
    };
};

export const updateGenre = (value, id, authToken, cb) => (dispatch) => {
    dispatch(updateGenreInProgress());

    Axios({
        method: 'put',
        url: URL_ASSET_GENRES_GET + '/' + id,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
        data: {
            name: value,
        },
    }).then((res) => {
        if (res) {
            dispatch(updateGenreSuccess(res));
            dispatch(updateGenreSuccessMessage('Successfully updated'));
            cb(null);
        }
    }).catch((error) => {
        dispatch(updateGenreError(error.response ? error.response.data.message : error.message,
        ));
    });
};

export const setAssetCategoryNewLabel = (value) => {
    return {
        type: ADD_ASSET_CATEGORY_NEW_LABEL,
        value,
    };
};

const addNewAssetCategoryInProgress = () => {
    return {
        type: NEW_ASSET_CATEGORY_ADD_IN_PROGRESS,
    };
};

const addNewAssetCategorySuccess = (message) => {
    return {
        type: NEW_ASSET_CATEGORY_ADD_SUCCESS,
        message,
    };
};

const addNewAssetCategorySuccessMessage = (message) => {
    return {
        type: NEW_ASSET_CATEGORY_ADD_SUCCESS_MESSAGE,
        message,
    };
};

const addNewAssetCategoryError = (message) => {
    return {
        type: NEW_ASSET_CATEGORY_ADD_ERROR,
        message,
    };
};

export const addNewAssetCategory = (value, authToken, cb) => (dispatch) => {
    dispatch(addNewAssetCategoryInProgress());
    Axios({
        method: 'post',
        url: URL_ASSET_CATEGORIES_GET,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
        data: {
            name: value,
        },
    }).then((res) => {
        if (res) {
            dispatch(addNewAssetCategorySuccess(res));
            dispatch(addNewAssetCategorySuccessMessage('Asset Category added successfully'));
            cb(null);
        }
    }).catch((error) => {
        dispatch(addNewAssetCategoryError(error.response ? error.response.data.message : error.message,
        ));
    });
};

const deleteAssetCategoryInProgress = () => {
    return {
        type: ASSET_CATEGORY_DELETE_IN_PROGRESS,
    };
};

const deleteAssetCategorySuccess = (message) => {
    return {
        type: ASSET_CATEGORY_DELETE_SUCCESS,
        message,
    };
};

const deleteAssetCategorySuccessMessage = (message) => {
    return {
        type: ASSET_CATEGORY_DELETE_SUCCESS_MESSAGE,
        message,
    };
};

const deleteAssetCategoryError = (message) => {
    return {
        type: ASSET_CATEGORY_DELETE_ERROR,
        message,
    };
};

export const deleteAssetCategory = (id, authToken, cb) => (dispatch) => {
    dispatch(deleteAssetCategoryInProgress());

    Axios({
        method: 'delete',
        url: URL_ASSET_CATEGORIES_GET + '/' + id,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
    }).then((res) => {
        if (res) {
            dispatch(deleteAssetCategorySuccess(res));
            dispatch(deleteAssetCategorySuccessMessage('Successfully deleted'));
            cb(null);
        }
    }).catch((error) => {
        dispatch(deleteAssetCategoryError(error.response ? error.response.data.message : error.message,
        ));
    });
};

const updateAssetCategoryInProgress = () => {
    return {
        type: ASSET_CATEGORY_UPDATE_IN_PROGRESS,
    };
};

const updateAssetCategorySuccess = (message) => {
    return {
        type: ASSET_CATEGORY_UPDATE_SUCCESS,
        message,
    };
};

const updateAssetCategorySuccessMessage = (message) => {
    return {
        type: ASSET_CATEGORY_UPDATE_SUCCESS_MESSAGE,
        message,
    };
};

const updateAssetCategoryError = (message) => {
    return {
        type: ASSET_CATEGORY_UPDATE_ERROR,
        message,
    };
};

export const updateAssetCategory = (value, id, authToken, cb) => (dispatch) => {
    dispatch(updateAssetCategoryInProgress());

    Axios({
        method: 'put',
        url: URL_ASSET_CATEGORIES_GET + '/' + id,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
        data: {
            name: value,
        },
    }).then((res) => {
        if (res) {
            dispatch(updateAssetCategorySuccess(res));
            dispatch(updateAssetCategorySuccessMessage('Successfully updated'));
            cb(null);
        }
    }).catch((error) => {
        dispatch(updateAssetCategoryError(error.response ? error.response.data.message : error.message,
        ));
    });
};

const assetFilesInProgress = () => {
    return {
        type: ASSET_FILES_IN_PROGRESS,
    };
};

const assetFilesList = (list) => {
    return {
        type: ASSET_FILES_LIST,
        list,
    };
};

const assetFilesError = (message) => {
    return {
        type: ASSET_FILES_ERROR,
        message,
    };
};

export const getFilesInfo = (authToken, cb) => (dispatch) => {
    dispatch(assetFilesInProgress());

    Axios.get(URL_FILES_LIST, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
    }).then((res) => {
        if (res && res.data && res.data.result) {
            dispatch(assetFilesList(res.data.result));
            cb(null);
        }
    }).catch((error) => {
        if (error) {
            dispatch(assetFilesError(error.response ? error.response.data.message : error.message,
            ));
        }
    });
};

const addAssetFileInProgress = () => {
    return {
        type: ASSET_FILE_ADD_IN_PROGRESS,
    };
};

const addAssetFileSuccess = (result) => {
    return {
        type: ASSET_FILE_ADD_SUCCESS,
        result,
    };
};

const addAssetFileSuccessMessage = (message) => {
    return {
        type: ASSET_FILE_ADD_SUCCESS_MESSAGE,
        message,
    };
};

const addAssetFileError = (message) => {
    return {
        type: ASSET_FILE_ADD_ERROR,
        message,
    };
};

export const addAssetFile = (category, sourceType, sourceId, authToken, cb) => (dispatch) => {
    dispatch(addAssetFileInProgress());
    Axios({
        method: 'post',
        url: URL_FILES_LIST,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
        data: {
            category: category,
            source: sourceType,
            videoId: sourceId,
        },
    }).then((res) => {
        if (res && res.data && res.data.result) {
            dispatch(addAssetFileSuccess(res.data.result));
            dispatch(addAssetFileSuccessMessage('File Added...'));
        }
    }).catch((error) => {
        dispatch(addAssetFileError(error.response ? error.response.data.message : error.message,
        ));
    });
};

const encodeFileInProgress = () => {
    return {
        type: ASSET_FILE_ENCODE_IN_PROGRESS,
    };
};

const encodeFileSuccess = (result) => {
    return {
        type: ASSET_FILE_ENCODE_SUCCESS,
        result,
    };
};

const encodeFileSuccessMessage = (message) => {
    return {
        type: ASSET_FILE_ENCODE_SUCCESS_MESSAGE,
        message,
    };
};

const encodeFileError = (message) => {
    return {
        type: ASSET_FILE_ENCODE_ERROR,
        message,
    };
};

export const encode = (fileId, token, cb) => (dispatch) => {
    dispatch(encodeFileInProgress());

    Axios({
        method: 'put',
        url: URL_ASSETS_LIST + '/' + fileId + '/encode',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    }).then((res) => {
        if (res && res.data && res.data.result) {
            dispatch(encodeFileSuccess(res.data.result));
            dispatch(encodeFileSuccessMessage('Encoding in progress...'));
            cb(null);
        }
    }).catch((error) => {
        dispatch(encodeFileError(error.response ? error.response.data.message : error.message,
        ));
    });
};

export const setCurrentAsset = (asset) => {
    return {
        type: CURRENT_ASSET_SET,
        asset,
    };
};

export const setAssetAlbum = (value) => {
    return {
        type: ADD_ASSET_ALBUM_SET,
        value,
    };
};

export const setAssetCategory = (value) => {
    return {
        type: ADD_ASSET_CATEGORY_SET,
        value,
    };
};

const fetchCategoriesInProgress = () => {
    return {
        type: ASSETS_CATEGORIES_FETCH_IN_PROGRESS,
    };
};

const fetchCategoriesSuccess = (items) => {
    return {
        type: ASSETS_CATEGORIES_FETCH_SUCCESS,
        items,
    };
};

const fetchCategoriesError = (message) => {
    return {
        type: ASSETS_CATEGORIES_FETCH_ERROR,
        message,
    };
};

export const fetchCategories = (authToken, cb) => (dispatch) => {
    dispatch(fetchCategoriesInProgress());

    Axios.get(URL_CHANNEL_CATEGORIES_GET, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + authToken,
        },
    })
        .then((res) => {
            const items = res.data.result.map(
                (item) => ({
                    value: item._id,
                    name: stringToUpperFirstChar(item.name),
                }),
            );

            dispatch(fetchCategoriesSuccess(items));
            cb(items);
        })
        .catch((error) => {
            dispatch(fetchCategoriesError(error.response ? error.response.data.message : error.message));
            cb(null);
        });
};

export const setAddAssetLanguage = (value) => {
    return {
        type: ADD_ASSET_LANGUAGE_SET,
        value,
    };
};

const fetchLanguagesInProgress = () => {
    return {
        type: ASSETS_LANGUAGES_FETCH_IN_PROGRESS,
    };
};

const fetchLanguagesSuccess = (items) => {
    return {
        type: ASSETS_LANGUAGES_FETCH_SUCCESS,
        items,
    };
};

const fetchLanguagesError = (message) => {
    return {
        type: ASSETS_LANGUAGES_FETCH_ERROR,
        message,
    };
};

export const fetchLanguages = () => (dispatch) => {
    dispatch(fetchLanguagesInProgress());

    Axios.get(URL_CHANNEL_LANGUAGES_GET)
        .then((res) => {
            const items = res.data.result.map(
                (item) => ({
                    value: item._id,
                    name: stringToUpperFirstChar(item.name),
                }),
            );

            dispatch(fetchLanguagesSuccess(items));
        })
        .catch((error) => {
            dispatch(fetchLanguagesError(error.data && error.data.message && error.data.message));
        });
};

export const setAssetTags = (value) => {
    return {
        type: ADD_ASSET_TAGS_SET,
        value,
    };
};

export const syncAssets = () => () => {
    Axios({
        method: 'post',
        url: URL_SYNC_ASSETS,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    });
};

const fetchTagsInProgress = () => {
    return {
        type: ASSET_TAGS_FETCH_IN_PROGRESS,
    };
};

const fetchTagsSuccess = (items) => {
    return {
        type: ASSET_TAGS_FETCH_SUCCESS,
        items,
    };
};

const fetchTagsError = (message) => {
    return {
        type: ASSET_TAGS_FETCH_ERROR,
        message,
    };
};

export const fetchTags = (token, categories) => (dispatch) => {
    dispatch(fetchTagsInProgress());

    Axios.get(getTagsURL(categories), {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            dispatch(fetchTagsSuccess(res.data.result));
        })
        .catch((error) => {
            dispatch(fetchTagsError(error.response ? error.response.data.message : error.message));
        });
};

export const setShowPreview = (value, asset) => {
    return {
        type: SHOW_PREVIEW_SET,
        value,
        asset,
    };
};

export const getPreviewUrl = (url, cb) => (dispatch) => {
    Axios.get(url, {
        headers: {
            Accept: 'application/json',
        },
    })
        .then((res) => {
            let format = 'm3u8';

            if ((res && res.statusCode) === 404) {
                format = 'mp4';
            }

            cb(format);
        })
        .catch((error) => {
            let format = 'm3u8';

            if ((error.response && error.response.status) === 404) {
                format = 'mp4';
            }

            cb(format);
        });
};

export const setUploadFile = (value) => {
    return {
        type: UPLOAD_FILE_SET,
        value,
    };
};

const uploadFileInProgress = () => {
    return {
        type: FILE_UPLOAD_IN_PROGRESS,
    };
};

const uploadFileSuccess = () => {
    return {
        type: FILE_UPLOAD_SUCCESS,
        message: 'Uploaded',
    };
};

const uploadFileError = (message) => {
    return {
        type: FILE_UPLOAD_ERROR,
        message,
    };
};

export const uploadFileCancel = (cancel) => {
    return {
        type: UPLOAD_CANCEL_SET,
        cancel,
    };
};

export const uploadFile = (address, organizationId, name, category, tags, file, token, cb) => (dispatch) => {
    dispatch(uploadFileInProgress());

    const CancelToken = Axios.CancelToken;
    const formData = new FormData();
    formData.append('mediaType', 'video');
    formData.append('file', file);
    if (name) {
        formData.append('name', name);
    }
    if (category) {
        formData.append('category', category);
    }
    if (tags && tags.length) {
        formData.append('tags', tags);
    }

    Axios.post(uploadFileURL(address, organizationId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
        },
        onUploadProgress: (progressEvent) => {
            dispatch(setUploadProgress(progressEvent));
        },
        cancelToken: new CancelToken((cancel) => {
            dispatch(uploadFileCancel(cancel));
        }),
    })
        .then((res) => {
            dispatch(uploadFileSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(uploadFileError(
                error.response &&
                error.response.data &&
                error.response.data.result
                    ? error.response.data.result
                    : 'Canceled File Upload!',
            ));
            cb(error);
        });
};

export const reUploadFile = (address, organizationId, assetId, name, category, tags, file, token, cb) => (dispatch) => {
    dispatch(uploadFileInProgress());

    const CancelToken = Axios.CancelToken;
    const formData = new FormData();
    formData.append('mediaType', 'video');
    formData.append('file', file);
    if (name) {
        formData.append('name', name);
    }
    if (category) {
        formData.append('category', category);
    }
    if (tags && tags.length) {
        formData.append('tags', tags);
    }

    Axios.post(reUploadFileURL(address, organizationId, assetId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
        },
        onUploadProgress: (progressEvent) => {
            dispatch(setUploadProgress(progressEvent));
        },
        cancelToken: new CancelToken((cancel) => {
            dispatch(uploadFileCancel(cancel));
        }),
    })
        .then((res) => {
            dispatch(uploadFileSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(uploadFileError(
                error.response &&
                error.response.data &&
                error.response.data.result
                    ? error.response.data.result
                    : 'Canceled File Upload!',
            ));
            cb(error);
        });
};

const setUploadProgress = (value) => {
    return {
        type: UPLOAD_PROGRESS_SET,
        value,
    };
};

const downloadFileInProgress = (downloadFileType) => {
    return {
        type: FILE_DOWNLOAD_IN_PROGRESS,
        downloadFileType,
    };
};

const downloadFileSuccess = (data, fileName) => {
    return {
        type: FILE_DOWNLOAD_SUCCESS,
        message: 'Downloaded',
        data,
        fileName,
    };
};

const downloadFileError = (message) => {
    return {
        type: FILE_DOWNLOAD_ERROR,
        message,
    };
};

export const downloadFileCancel = (cancel) => {
    return {
        type: DOWNLOAD_CANCEL_SET,
        cancel,
    };
};

export const downloadFileErrorMessage = () => {
    return {
        type: FILE_DOWNLOAD_ERROR,
        message: 'Unable to download the file at this moment',
    };
};

export const downloadFile = (address, organizationId, path, token, fileName, type) => (dispatch) => {
    dispatch(downloadFileInProgress(type));
    const CancelToken = Axios.CancelToken;

    Axios.get(downloadFileURL(address, organizationId, path), {
        responseType: 'blob',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
        onDownloadProgress: (progressEvent) => {
            dispatch(setDownloadProgress(progressEvent));
        },
        cancelToken: new CancelToken((cancel) => {
            dispatch(downloadFileCancel(cancel));
        }),
    })
        .then((res) => {
            dispatch(downloadFileSuccess(res.data, fileName));
        })
        .catch((error) => {
            dispatch(downloadFileError(
                error.response &&
                error.response.data &&
                error.response.data.result
                    ? error.response.data.result
                    : 'Canceled File Download!',
            ));
        });
};

const setDownloadProgress = (value) => {
    return {
        type: DOWNLOAD_PROGRESS_SET,
        value,
    };
};

const fetchTagsListInProgress = () => {
    return {
        type: TAGS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchTagsListSuccess = (list) => {
    return {
        type: TAGS_LIST_FETCH_SUCCESS,
        list,
    };
};

const fetchTagsListError = (message) => {
    return {
        type: TAGS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchTagsList = (token) => (dispatch) => {
    dispatch(fetchTagsListInProgress());

    Axios.get(TAGS_LIST_URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            const items = res.data.result;

            dispatch(fetchTagsListSuccess(items));
        })
        .catch((error) => {
            dispatch(fetchTagsListError(error.response ? error.response.data.message : error.message));
        });
};

export const openFilterMenu = (event) => {
    return {
        type: POPOVER_FILTER_SHOW,
        anchorEl: event.currentTarget,
    };
};

export const closeFilterMenu = () => {
    return {
        type: POPOVER_FILTER_HIDE,
    };
};

export const changeFilter = (filter, value) => {
    return {
        type: CHANGED_FILTER,
        filter,
        value,
    };
};

export const setOwnerShipStatus = (value) => {
    return {
        type: OWNERSHIP_STATUS_SET,
        value,
    };
};

export const setAlbum = (value) => {
    return {
        type: ALBUM_SET,
        value,
    };
};

export const setISRCCode = (value) => {
    return {
        type: IS_RC_CODE_SET,
        value,
    };
};

export const setRecordLabel = (value) => {
    return {
        type: RECORD_LABEL_SET,
        value,
    };
};

export const setMusicDirectors = (value) => {
    return {
        type: MUSIC_DIRECTORS_SET,
        value,
    };
};

export const setMusicComposers = (value) => {
    return {
        type: MUSIC_COMPOSERS_SET,
        value,
    };
};

export const setSingers = (value) => {
    return {
        type: SINGERS_SET,
        value,
    };
};

export const setLyricists = (value) => {
    return {
        type: LYRICISTS_SET,
        value,
    };
};

export const setMood = (value) => {
    return {
        type: MOOD_SET,
        value,
    };
};

export const setCountry = (value) => {
    return {
        type: COUNTRY_SET,
        value,
    };
};

export const setRating = (value) => {
    return {
        type: RATING_SET,
        value,
    };
};

export const setReleaseDate = (value) => {
    return {
        type: RELEASE_DATE_SET,
        value,
    };
};

export const setDirector = (value) => {
    return {
        type: DIRECTOR_SET,
        value,
    };
};

export const setProducer = (value) => {
    return {
        type: PRODUCER_SET,
        value,
    };
};

export const setCinematography = (value) => {
    return {
        type: CINEMATOGRAPHY_SET,
        value,
    };
};

export const setDuration = (value) => {
    return {
        type: DURATION_SET,
        value,
    };
};

export const setCastCrew = (value) => {
    return {
        type: CAST_CREW_SET,
        value,
    };
};

export const setArtDirector = (value) => {
    return {
        type: ART_DIRECTOR,
        value,
    };
};

export const setStyleCostumeDesigner = (value) => {
    return {
        type: STYLE_COSTUME_DESIGNER,
        value,
    };
};

const albumList = (list) => {
    return {
        type: ALBUM_LIST_SUCCESS,
        list,
    };
};

const albumListInProgress = () => {
    return {
        type: ALBUM_LIST_IN_PROGRESS,
    };
};

const List = [{
    name: 'album1',
}, {
    name: 'album2',
}, {
    name: 'album3',
}, {
    name: 'album4',
}, {
    name: 'album5',
}, {
    name: 'album6',
}];

export const fetchAlbumInfo = (authToken, encode) => (dispatch) => {
    albumListInProgress();
    albumList(List);
};

export const showPeoplesDialog = (value, profession, list, onChange) => {
    return {
        type: DIALOG_ADD_PEOPLE_SHOW,
        value,
        profession,
        list,
        onChange,
    };
};

export const hidePeoplesDialog = () => {
    return {
        type: DIALOG_ADD_PEOPLE_HIDE,
    };
};

const fetchScenesInProgress = () => {
    return {
        type: ASSET_SCENES_FETCH_IN_PROGRESS,
    };
};

const fetchScenesSuccess = (items) => {
    return {
        type: ASSET_SCENES_FETCH_SUCCESS,
        items,
    };
};

const fetchScenesError = (message) => {
    return {
        type: ASSET_SCENES_FETCH_ERROR,
        message,
    };
};

export const fetchScenes = (id, token) => (dispatch) => {
    dispatch(fetchScenesInProgress());

    const URL = urlFetchScenes(id);

    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            dispatch(fetchScenesSuccess(res.data && res.data.result ? res.data.result : []));
        })
        .catch((error) => {
            dispatch(fetchScenesError(error.response ? error.response.data.message : error.message,
            ));
        });
};

const fetchAssetInProgress = () => {
    return {
        type: ASSET_FETCH_IN_PROGRESS,
    };
};

const fetchAssetSuccess = (item) => {
    return {
        type: ASSET_FETCH_SUCCESS,
        item,
    };
};

const fetchAssetError = (message) => {
    return {
        type: ASSET_FETCH_ERROR,
        message,
    };
};

export const fetchAsset = (id, token, cb) => (dispatch) => {
    dispatch(fetchAssetInProgress());

    const URL = urlFetchAsset(id);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            const item = res.data.result;

            dispatch(fetchAssetSuccess(item));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchAssetError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const updateAssetNFTInProgress = () => {
    return {
        type: ASSET_NFT_UPDATE_IN_PROGRESS,
    };
};

const updateAssetNFTSuccess = (item) => {
    return {
        type: ASSET_NFT_UPDATE_SUCCESS,
        item,
    };
};

const updateAssetNFTError = (message) => {
    return {
        type: ASSET_NFT_UPDATE_ERROR,
        message,
    };
};

export const updateAssetNFT = (id, token, data) => (dispatch) => {
    dispatch(updateAssetNFTInProgress());

    const URL = urlUpdateAssetNFT(id);
    Axios.put(URL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        },
    })
        .then((res) => {
            const item = res.data.result;

            dispatch(updateAssetNFTSuccess(item));
        })
        .catch((error) => {
            dispatch(updateAssetNFTError(error.response ? error.response.data.message : error.message,
            ));
        });
};

export const setOwnerShipStatusSelect = (value) => {
    return {
        type: OWNERSHIP_STATUS_SELECT_SET,
        value,
    };
};

export const setInstagramID = (value) => {
    return {
        type: INSTAGRAM_ID_SET,
        value,
    };
};

export const setYouTubeID = (value) => {
    return {
        type: YOUTUBE_ID_SET,
        value,
    };
};

export const setTwitterID = (value) => {
    return {
        type: TWITTER_ID_SET,
        value,
    };
};

export const setMediaNFTID = (value) => {
    return {
        type: MEDIA_NFT_ID_SET,
        value,
    };
};

export const setStory = (value) => {
    return {
        type: STORY_SET,
        value,
    };
};

export const setDialogues = (value) => {
    return {
        type: DIALOGUES_SET,
        value,
    };
};

export const setComputerGraphics = (value) => {
    return {
        type: COMPUTER_GRAPHICS_SET,
        value,
    };
};

export const setCast = (value) => {
    return {
        type: CAST_SET,
        value,
    };
};

export const setCrew = (value) => {
    return {
        type: CREW_SET,
        value,
    };
};

export const setStylist = (value) => {
    return {
        type: STYLIST_SET,
        value,
    };
};

export const setEditor = (value) => {
    return {
        type: EDITOR_SET,
        value,
    };
};

export const setTabValue = (value) => {
    return {
        type: TAB_VALUE_SET,
        value,
    };
};

export const showDialogDeleteThumb = (name, path) => {
    return {
        type: DIALOG_DELETE_THUMB_SHOW,
        name,
        path,
    };
};

export const hideDialogDeleteThumb = () => {
    return {
        type: DIALOG_DELETE_THUMB_HIDE,
    };
};

export const showDialogUpdateThumb = (name) => {
    return {
        type: DIALOG_UPDATE_THUMB_SHOW,
        name,
    };
};

export const hideDialogUpdateThumb = () => {
    return {
        type: DIALOG_UPDATE_THUMB_HIDE,
    };
};

export const showDialogViewThumb = (name, value) => {
    return {
        type: DIALOG_VIEW_THUMB_SHOW,
        name,
        value,
    };
};

export const hideDialogViewhumb = () => {
    return {
        type: DIALOG_VIEW_THUMB_HIDE,
    };
};

export const showDialogUploadThumb = (thumbs) => {
    return {
        type: DIALOG_UPLOAD_THUMB_SHOW,
        thumbs,
    };
};

export const hideDialogUploadThumb = () => {
    return {
        type: DIALOG_UPLOAD_THUMB_HIDE,
    };
};

export const uploadedThumbs = (list, successList) => {
    return {
        type: UPLOADED_THUMBS_LIST,
        list,
        successList,
    };
};

const uploadAssetThumbnailInProgress = () => {
    return {
        type: UPLOAD_ASSET_THUMBNAIL_IN_PROGRESS,
    };
};

const uploadAssetThumbnailSuccess = (items) => {
    return {
        type: UPLOAD_ASSET_THUMBNAIL_SUCCESS,
        items,
    };
};

const uploadAssetThumbnailError = (message) => {
    return {
        type: UPLOAD_ASSET_THUMBNAIL_ERROR,
        message,
    };
};

export const uploadAssetThumbnail = (file, type, cb) => (dispatch) => {
    dispatch(uploadAssetThumbnailInProgress());

    const formData = new FormData();
    formData.append('type', type);
    formData.append('file', file.file);
    Axios.post(UPLOAD_ASSET_THUMB, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    }).then((res) => {
        dispatch(uploadAssetThumbnailSuccess(res.data));
        cb(res.data);
    }).catch((error) => {
        dispatch(uploadAssetThumbnailError(error.response ? error.response.data.message : error.message));
        cb(error);
    });
};

const deleteAssetThumbnailInProgress = () => {
    return {
        type: DELETE_ASSET_THUMBNAIL_IN_PROGRESS,
    };
};

const deleteAssetThumbnailSuccess = (message, id) => {
    return {
        type: DELETE_ASSET_THUMBNAIL_SUCCESS,
        message,
        id,
    };
};

const deleteAssetThumbnailError = (message) => {
    return {
        type: DELETE_ASSET_THUMBNAIL_ERROR,
        message,
    };
};

export const deleteAssetThumbnail = (id, type, cb) => (dispatch) => {
    dispatch(deleteAssetThumbnailInProgress());
    const url = URL_ASSETS_LIST + '/' + id + '/delete-thumbnail?' + type + '=true';

    Axios({
        method: 'delete',
        url: url,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    }).then((res) => {
        dispatch(deleteAssetThumbnailSuccess('Successfully deleted', id));
        dispatch(fetchAsset(id, localStorage.getItem('authorizationToken'), (cb) => {
        }));
        cb(res && res.data && res.data.success);
    }).catch((error) => {
        dispatch(deleteAssetThumbnailError(error.response ? error.response.data.message : error.message,
        ));
    });
};
