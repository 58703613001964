import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAddDayType } from '../../../../actions/program';
import SelectField from '../../../../components/SelectField';

class DaySelectField extends React.Component {
    render () {
        const options = [{
            value: 'Today',
            label: 'Today',
            name: 'Today',
        }, {
            value: 'This Week',
            label: 'This Week',
            name: 'This Week',
        }, {
            value: 'This Month',
            label: 'This Month',
            name: 'This Month',
        }];
        return (
            <div>
                <SelectField
                    id="day_type_select_field"
                    items={options}
                    name="day_type"
                    showAddButton={false}
                    value={this.props.value}
                    onChange={this.props.onChange}/>
            </div>
        );
    }
}

DaySelectField.propTypes = {
    fetch: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.live.dayTypeValue,
        items: state.live.program.list,
    };
};

const actionsToProps = {
    onChange: setAddDayType,
};

export default connect(stateToProps, actionsToProps)(DaySelectField);
