import * as PropTypes from 'prop-types';
import React from 'react';
import AddressCard from './AddressCard';
import BalanceCard from './BalanceCard';
import './index.css';
import NetworkCard from './NetworkCard';
import SendTokensDialog from './SendTokenDialog';

const Cards = (props) => {
    return (
        <div className="cards">
            <AddressCard account={props.account}/>
            <BalanceCard address={props.account.address}/>
            <NetworkCard/>
            <SendTokensDialog/>
        </div>
    );
};

Cards.propTypes = {
    account: PropTypes.shape({
        address: PropTypes.string,
        isPrimary: PropTypes.string,
        status: PropTypes.string,
    }),
};

export default Cards;
