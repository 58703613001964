import { combineReducers } from 'redux';
import {
    ART_DIRECTOR,
    CAST_CREW_SET,
    CINEMATOGRAPHY_SET,
    COUNTRY_SET,
    DIALOG_ADD_ASSET_HIDE,
    DIRECTOR_SET,
    DURATION_SET,
    EDITABLE_ASSET_VIDEO_DATA,
    PRODUCER_SET,
    RATING_SET,
    RELEASE_DATE_SET,
    STYLE_COSTUME_DESIGNER,
} from '../../constants/asset';
import { countriesList, ratingList } from '../../utils/assetOptions';

export const country = (state = {
    value: '',
    list: countriesList,
}, action) => {
    switch (action.type) {
    case COUNTRY_SET:
        return {
            ...state,
            value: action.value,
        };
    case DIALOG_ADD_ASSET_HIDE:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const rating = (state = {
    value: '',
    list: ratingList,
}, action) => {
    switch (action.type) {
    case RATING_SET:
        return {
            ...state,
            value: action.value,
        };
    case DIALOG_ADD_ASSET_HIDE:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const releaseDate = (state = null, action) => {
    switch (action.type) {
    case RELEASE_DATE_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video['release_date']
            ? action.value.video['release_date'] : null;
    case DIALOG_ADD_ASSET_HIDE:
        return null;
    default:
        return state;
    }
};

const director = (state = [], action) => {
    switch (action.type) {
    case DIRECTOR_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.directors
            ? action.value.video.directors : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const producer = (state = [], action) => {
    switch (action.type) {
    case PRODUCER_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.producers
            ? action.value.video.producers : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const cinematography = (state = [], action) => {
    switch (action.type) {
    case CINEMATOGRAPHY_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.cinematography
            ? action.value.video.cinematography : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const duration = (state = null, action) => {
    switch (action.type) {
    case DURATION_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.duration
            ? action.value.video.duration : null;
    case DIALOG_ADD_ASSET_HIDE:
        return null;
    default:
        return state;
    }
};

const castCrew = (state = [], action) => {
    switch (action.type) {
    case CAST_CREW_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const artDirector = (state = [], action) => {
    switch (action.type) {
    case ART_DIRECTOR:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video['art_directors']
            ? action.value.video['art_directors'] : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const styleCostumeDesigner = (state = [], action) => {
    switch (action.type) {
    case STYLE_COSTUME_DESIGNER:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video['costume_designers']
            ? action.value.video['costume_designers'] : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

export default combineReducers({
    country,
    rating,
    releaseDate,
    director,
    producer,
    cinematography,
    duration,
    castCrew,
    artDirector,
    styleCostumeDesigner,
});
