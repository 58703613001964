import { Button } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    downloadFile, downloadFileErrorMessage, editableAudioAsset,
    editableData, editableVideoAsset,
    encode, fetchAsset,
    fetchAssetsData,
    setShowPreview, showDialogActionMenu,
    showDialogEditAsset,
    showQuickViewMovieAsset,
} from '../../../actions/asset';
import audioIcon from '../../../assets/audio.svg';
import instaIcon from '../../../assets/instaActive.svg';
import sftpIcon from '../../../assets/sftp.svg';
import twitterIcon from '../../../assets/twitterActive.svg';
import videoIcon from '../../../assets/videoIcon.svg';
import youtubeIcon from '../../../assets/youtubeActive.svg';
import DataTable from '../../../components/DataTable';
import Icon from '../../../components/Icon';
import { DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_SORT_BY } from '../../../constants/url';
import { removeUnderScroll } from '../../../utils/statusMessages';
import PopOverActionMenu from '../../asset/PopOverActionMenu';
import QuickView from '../../asset/QuickView';
import Preview from '../../Preview';
import SkeletonLoader from '../../../components/SkeletonLoader/Table';

class RecentAssets extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            selectedRowData: [],
            selectedRowMeta: {},
        };
        this.onClickEncode = this.onClickEncode.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleView = this.handleView.bind(this);
        this.handleQuickView = this.handleQuickView.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
    }

    onClickEncode (item) {
        const token = localStorage.getItem('authorizationToken');

        this.props.encodeFile(item._id, token, (cb) => {
            if (cb) {
                return;
            }

            if (token) {
                this.handleFetch(this.props.skip, this.props.limit, this.props.searchKey, this.props.sortBy, null);
            }
        });
    }

    handleDownload (index) {
        const selectedAsset = this.props.data[index.rowIndex];
        const token = localStorage.getItem('authorizationToken');

        if (token && this.props.runners.length) {
            if (selectedAsset.file && selectedAsset.file.download &&
                selectedAsset.file.download.status === 'COMPLETE') {
                this.props.download(this.props.runners[0].public_address,
                    this.props.runners[0].organization,
                    selectedAsset.file.download.path + '/' + selectedAsset.file.name,
                    token, selectedAsset.file.name);
            }
        } else {
            this.props.showDownloadErrorMessage();
        }
    }

    handleQuickView (index) {
        const data = this.props.data;
        this.props.onClickView(data[index.dataIndex]);
    }

    handleView (index) {
        const selectedAsset = this.props.data[index.rowIndex];

        this.props.setShowPreview(true, selectedAsset);
    }

    handleEdit (index) {
        const data = this.props.data;
        const type = data[index.rowIndex] ? data[index.rowIndex].type : 'video';
        this.props.editableData(data[index.rowIndex]);
        if (type === 'audio') {
            this.props.editableAudioAsset(data[index.rowIndex]);
        } else {
            this.props.editableVideoAsset(data[index.rowIndex]);
        }
        this.props.onClickEdit(type);
        this.props.fetchAsset(data[index.rowIndex]._id, localStorage.getItem('authorizationToken'), (cb) => {});
    }

    handleFetch (skip, limit, searchText, sortBy, order) {
        const token = localStorage.getItem('authorizationToken');

        this.props.fetchAssetsData('', token, false, true, this.props.days,
            skip,
            limit,
            searchText,
            sortBy,
            order);
    }

    render () {
        const options = {
            responsive: true,
            serverSide: true,
            count: this.props.count,
            download: false,
            print: false,
            selectableRows: 'none',
            onSearchChange: (searchText) => {
                this.handleFetch(DEFAULT_SKIP, DEFAULT_LIMIT, searchText, DEFAULT_SORT_BY, null);
            },
            onChangePage: (currentPage) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                this.handleFetch(this.props.limit * currentPage, this.props.limit, this.props.searchKey, this.props.sortBy, null);
            },
            onChangeRowsPerPage: (numberOfRows) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                this.handleFetch(this.props.skip, numberOfRows, this.props.searchKey, this.props.sortBy, null);
            },
            onColumnSortChange: (changedColumn, direction) => {
                if (this.props.data && this.props.data.length === 0) {
                    return;
                }

                const order = direction === 'asc' ? 1 : -1;
                this.handleFetch(this.props.skip, this.props.limit, this.props.searchKey, changedColumn, order);
            },
            onRowClick: (rowData, rowState) => {
            },
            onCellClick: (colData, cellMeta) => {
                const value = colData && colData.props ? colData.props.children !== 'Encode' : true;
                if (cellMeta.colIndex !== 13 && value && this.props.data && this.props.data.length > 0) {
                    this.handleQuickView(cellMeta);
                }
            },
            textLabels: {
                body: {
                    noMatch:
                    this.props.inProgress ? <SkeletonLoader columns={8}/>
                        : <p className="no_data_table"> No data found </p>,
                    toolTip: 'Sort',
                    columnHeaderTooltip: (column) => `Sort for ${column.label}`,
                },
                pagination: {
                    next: 'Next Page',
                    previous: 'Previous Page',
                    rowsPerPage: 'Rows per page:',
                    displayRows: 'of',
                },
                toolbar: {
                    search: 'Search',
                    downloadCsv: 'Download CSV',
                    print: 'Print',
                    viewColumns: 'View Columns',
                    filterTable: 'Filter Table',
                },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },
                viewColumns: {
                    title: 'Show Columns',
                    titleAria: 'Show/Hide Table Columns',
                },
            },
        };

        const columns = [{
            name: 'assetName',
            label: 'Asset Name',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, i) => {
                    return (
                        <div className="filename">
                            {value}
                        </div>
                    );
                },
            },
        }, {
            name: 'genre.name',
            label: 'Asset Genre',
            options: {
                filter: true,
                sort: false,
                display: false,
            },
        }, {
            name: 'category.name',
            label: 'Asset Category',
            options: {
                filter: true,
                sort: false,
                display: false,
                customBodyRender: (value) => {
                    return (
                        <div className="filename">
                            {value}
                        </div>
                    );
                },
            },
        }, {
            name: 'type',
            label: 'File Type',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <div className="assets_type">
                            {
                                value
                                    ? <>
                                        {value === 'video'
                                            ? <img alt="video" src={videoIcon}/>
                                            : value === 'audio'
                                                ? <img alt="audio" src={audioIcon}/>
                                                : null}
                                        <p className="text">{value}</p>
                                    </>
                                    : null
                            }
                        </div>
                    );
                },
            },
        }, {
            name: 'file.length',
            label: 'Duration',
            options: {
                filter: true,
                sort: false,
            },
        }, {
            name: 'file.download.source',
            label: 'Source',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        value
                            ? <div className="source_tooltip">
                                <div className="source_css">
                                    {value === 'youtube'
                                        ? <img alt="ff youtube" src={youtubeIcon}/>
                                        : value === 'twitter'
                                            ? <img alt="ff twitter" src={twitterIcon}/>
                                            : value === 'instagram'
                                                ? <img alt="ff insta" src={instaIcon}/>
                                                : value === 'sftp'
                                                    ? <img alt="ff sftp" src={sftpIcon}/>
                                                    : value}
                                </div>
                                <p className={'title ' + (value === 'sftp' ? 'upper_case' : '')}>
                                    {value === 'youtube'
                                        ? 'YouTube'
                                        : value}
                                </p>
                            </div>
                            : ''
                    );
                },
            },
        }, {
            name: 'file.download.at',
            label: 'Date of Upload',
            options: {
                filter: true,
                sort: false,
                display: false,
            },
        }, {
            name: 'lastUpdated',
            label: 'Last Updated',
            options: {
                filter: true,
                sort: false,
                display: true,
            },
        }, {
            name: 'file.download.status',
            label: 'Download Status',
            options: {
                filter: true,
                sort: false,
                display: true,
                customBodyRender: (value) => {
                    return (
                        <div style={{
                            color: value === 'IN_PROGRESS' ? '#1AACFF'
                                : value === 'COMPLETE' ? '#39FF14'
                                    : null,
                        }}>
                            {value}
                        </div>
                    );
                },
            },
        }, {
            name: 'file.encode.status',
            label: 'Encoded Status',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        value &&
                        (value === 'PENDING' &&
                            this.props.data[tableMeta.rowIndex].file &&
                            this.props.data[tableMeta.rowIndex].file.download &&
                            this.props.data[tableMeta.rowIndex].file.download.status === 'COMPLETE')
                            ? <Button
                                className="encode encode_asset"
                                onClick={() => this.onClickEncode(this.props.data[tableMeta.rowIndex])}>
                                Encode
                            </Button>
                            : value === 'ERROR'
                                ? ''
                                : <div
                                    className={value === 'encode' ? 'encode_asset' : ''}
                                    style={{
                                        color: value === 'IN_PROGRESS' ? '#1AACFF'
                                            : value === 'COMPLETE' ? '#39FF14' : null,
                                    }}>
                                    {value === 'IN_PROGRESS'
                                        ? 'IN PROGRESS'
                                        : value === 'IN_QUEUE'
                                            ? 'IN QUEUE'
                                            : value}
                                </div>
                    );
                },
            },
        }, {
            name: 'publishedForVOD',
            label: 'Published for VOD',
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        }, {
            name: 'datePublished',
            label: 'Date Published (VOD)',
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        }, {
            name: 'lastRun',
            label: 'Last Run',
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        }, {
            name: 'Action',
            label: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, i) => {
                    return (
                        <div className="row_action">
                            <Button
                                className="row_action_icon download"
                                disabled={this.props.downloadInProgress}
                                onClick={() => this.handleDownload(i)}>
                                <Icon
                                    className="download"
                                    icon="download"/>
                            </Button>
                            <Button
                                className="row_action_icon preview"
                                onClick={() => this.handleView(i)}>
                                <Icon
                                    className="eye"
                                    icon="eye"/>
                            </Button>
                            <Button
                                className="row_action_icon edit"
                                onClick={() => this.handleEdit(i)}>
                                <Icon
                                    className="edit_action"
                                    icon="edit_action"/>
                            </Button>
                            <Button
                                className="row_action_icon delete"
                                onClick={() => this.props.onClickConfirm(this.props.data[i.rowIndex], 'recent')}>
                                <Icon
                                    className="delete_action"
                                    icon="delete_action"/>
                            </Button>
                        </div>
                    );
                },
            },
        }];

        const tableData = this.props.data.length
            ? this.props.data.map((item, index) => [
                item.video
                    ? item.video.name
                    : item.audio
                        ? item.audio['track_name'] || item.audio.name
                        : item.graphic
                            ? item.graphic.name
                            : item.text
                                ? item.text.name
                                : '',
                item.genre && item.genre.name,
                item.category && item.category.name,
                item.type,
                item.file && item.file.length
                    ? moment.utc(moment.duration(item.file.length, 'seconds')
                        .asMilliseconds()).format('HH:mm:ss')
                    : '',
                item.file && item.file.download && item.file.download.source
                    ? item.file.download.source : item.source,
                item.file && item.file.download && item.file.download.at
                    ? moment(item.file.download.at).format('DD-MMM-YYYY HH:mm:ss')
                    : '',
                item.video && item.video.updated_at
                    ? moment(item.video.updated_at).format('DD-MMM-YYYY HH:mm:ss')
                    : '',
                item.file && item.file.download && item.file.download.status
                    ? removeUnderScroll(item.file.download.status) : '',
                item.file && item.file.encode && item.file.encode.status
                    ? removeUnderScroll(item.file.encode.status) : '',
                item.publishedForVOD,
                item.datePublished,
                item.lastRun,
            ])
            : [];

        return (
            <>
                <DataTable
                    columns={columns}
                    data={tableData}
                    name=""
                    options={options}/>
                <QuickView/>
                <Preview/>
                <PopOverActionMenu/>
            </>
        );
    }
}

RecentAssets.propTypes = {
    count: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    days: PropTypes.number.isRequired,
    download: PropTypes.func.isRequired,
    downloadInProgress: PropTypes.bool.isRequired,
    editableAudioAsset: PropTypes.func.isRequired,
    editableData: PropTypes.func.isRequired,
    editableVideoAsset: PropTypes.func.isRequired,
    encodeFile: PropTypes.func.isRequired,
    fetchAssetsData: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    runners: PropTypes.arrayOf(
        PropTypes.shape({
            public_address: PropTypes.string.isRequired,
            organization: PropTypes.string.isRequired,
        }),
    ).isRequired,
    searchKey: PropTypes.string.isRequired,
    setShowPreview: PropTypes.func.isRequired,
    showDownloadErrorMessage: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    onClickConfirm: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickView: PropTypes.func.isRequired,
    fetchAsset: PropTypes.func,
    order: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        data: state.asset.assetData.list,
        days: state.asset.updatedFilter.days,
        inProgress: state.asset.assetData.inProgress,
        runners: state.runner.list,
        downloadInProgress: state.asset.download.inProgress,
        skip: state.asset.assetData.skip,
        limit: state.asset.assetData.limit,
        count: state.asset.assetData.count,
        searchKey: state.asset.assetData.searchKey,
        sortBy: state.asset.assetData.sortBy,
        order: state.asset.assetData.order,
    };
};

const actionToProps = {
    fetchAssetsData,
    encodeFile: encode,
    onClickView: showQuickViewMovieAsset,
    setShowPreview,
    onClickEdit: showDialogEditAsset,
    editableData,
    editableVideoAsset,
    editableAudioAsset,
    onClickConfirm: showDialogActionMenu,
    download: downloadFile,
    showDownloadErrorMessage: downloadFileErrorMessage,
    fetchAsset,
};

export default withRouter(connect(stateToProps, actionToProps)(RecentAssets));
