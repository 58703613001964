import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setCinematography, showPeoplesDialog } from '../../../../../actions/asset';
import AutoCompleteTextField from '../../../../../components/AutoCompleteTextField/WithAddButton';

const CinematographySelectField = (props) => {
    const showDialog = (value) => {
        props.dialog(value, 'cinematography', [...props.list], props.onChange);
    };

    return (
        <AutoCompleteTextField
            label="Cinematography"
            options={props.options}
            showDialog={showDialog}
            value={props.list}
            onChange={props.onChange}/>
    );
};

CinematographySelectField.propTypes = {
    dialog: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        list: state.asset.new.movies.cinematography,
        options: state.asset.new.peopleList.list,
    };
};

const actionToProps = {
    onChange: setCinematography,
    dialog: showPeoplesDialog,
};

export default connect(stateToProps, actionToProps)(CinematographySelectField);
