import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setLocation } from '../../../actions/chapterMarking';
import AutoCompleteTextField from '../../../components/AutoCompleteTextField';

class LocationSelectField extends React.Component {
    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        if (this.props.defaultValue && this.props.defaultValue.length > 0) {
            this.props.onChange([...this.props.defaultValue]);
        }
    }

    handleChange (value) {
        this.props.onChange(value);
    }

    render () {
        return (
            <AutoCompleteTextField
                freeSolo
                className="tags_select_field"
                options={[]}
                value={this.props.list}
                onChange={this.handleChange}/>
        );
    }
}

LocationSelectField.propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.array,
    list: PropTypes.array,
};

const stateToProps = (state) => {
    return {
        list: state.chapterMarking.new.location,
    };
};

const actionToProps = {
    onChange: setLocation,
};

export default connect(stateToProps, actionToProps)(LocationSelectField);
