export const USER_GLOBAL_SEARCH_SET = 'USER_GLOBAL_SEARCH_SET';

export const CLOSE_SEARCH_BAR = 'CLOSE_SEARCH_BAR';
export const SHOW_SEARCH_BAR = 'SHOW_SEARCH_BAR';

export const POPOVER_TIME_FORMAT_SHOW = 'POPOVER_TIME_FORMAT_SHOW';
export const POPOVER_TIME_FORMAT_HIDE = 'POPOVER_TIME_FORMAT_HIDE';

export const CHANGE_TIME_FORMAT = 'CHANGE_TIME_FORMAT';

export const PROFILE_IMAGE__FETCH_ERROR = 'PROFILE_IMAGE__FETCH_ERROR';
export const PROFILE_IMAGE__FETCH_IN_PROGRESS = 'PROFILE_IMAGE__FETCH_IN_PROGRESS';
export const PROFILE_IMAGE__FETCH_SUCCESS = 'PROFILE_IMAGE__FETCH_SUCCESS';
