import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAccountAddress } from '../../../actions/account';
import { mintLinearBroadcast } from '../../../actions/channel';
import { initializeFlixStudio, signTx } from '../../../helper';
import { showMessage } from '../../../actions/snackbar';

class MintLinearBroadcast extends React.Component {
    constructor (props) {
        super(props);
        this.createNFT = this.createNFT.bind(this);
        this.initKeplrAndMint = this.initKeplrAndMint.bind(this);
    }

    initKeplrAndMint () {
        initializeFlixStudio((error, address) => {
            if (error) {
                this.props.showMessage(error);
                return;
            }

            this.props.setAccountAddress(address);
            this.createNFT();
        });
    }

    createNFT () {
        const organizationId = this.props.runners[0] && this.props.runners[0].organization;

        if (!organizationId) {
            this.props.showMessage('Unable to find organization');
            return;
        }
        if (!this.props.address) {
            this.props.showMessage('Unable to fetch account address');
            return;
        }

        const data = {
            organizationID: organizationId,
            streamID: this.props.stream,
            creator: this.props.address,
            broadcastType: 'persistent',
            base_req: {
                chain_id: 'flixnet-1',
                from: this.props.address,
            },

        };

        this.props.mintLinearBroadcast(data, (error, tx) => {
            if (error) {
                return;
            }
            signTx(tx.value, this.props.address, (error) => {
                if (error) {
                    this.props.showMessage(error);
                    return;
                }

                this.props.showMessage('Successfully minted MediaNFT');
            });
        });
    }

    render () {
        return (
            <>
                {!this.props.stream
                    ? null
                    : <Button
                        className="mint_linear_broadcast"
                        onClick={this.initKeplrAndMint}>
                        Mint Linear Broadcast
                    </Button>}
            </>
        );
    }
}

MintLinearBroadcast.propTypes = {
    mintLinearBroadcast: PropTypes.func.isRequired,
    runners: PropTypes.arrayOf(
        PropTypes.shape({
            organization: PropTypes.string.isRequired,
        }),
    ).isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    address: PropTypes.string,
    stream: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        stream: state.live.stream.current,
        runners: state.runner.list,
        address: state.account.address,
    };
};

const actionToProps = {
    mintLinearBroadcast,
    setAccountAddress,
    showMessage,
};

export default connect(stateToProps, actionToProps)(MintLinearBroadcast);
