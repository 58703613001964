import Axios from 'axios';
import { urlWatchURL } from '../constants/url';
import {
    DEVICE_ID_FETCH_ERROR,
    DEVICE_ID_FETCH_IN_PROGRESS,
    DEVICE_ID_FETCH_SUCCESS,
    DOWNLOAD_FILE_CANCEL_SET,
    DOWNLOAD_FILE_DETAILS_SET,
    UPLOAD_FILE_CANCEL_SET,
    UPLOAD_FILE_DETAILS_SET,
    WATCH_URL_FETCH_ERROR,
    WATCH_URL_FETCH_IN_PROGRESS,
    WATCH_URL_FETCH_SUCCESS,
} from '../constants/videoAssets';
import calculateDeviceFingerprint from '../utils/fingerprint';

export const setDownloadDetails = (value, index, list) => {
    return {
        type: DOWNLOAD_FILE_DETAILS_SET,
        value,
        index,
        list,
    };
};

export const setUploadDetails = (value, list) => {
    return {
        type: UPLOAD_FILE_DETAILS_SET,
        value,
        list,
    };
};

export const setDownloadCancel = (list) => {
    return {
        type: DOWNLOAD_FILE_CANCEL_SET,
        list,
    };
};

export const setUploadCancel = (list) => {
    return {
        type: UPLOAD_FILE_CANCEL_SET,
        list,
    };
};

const fetchWatchURLInProgress = () => {
    return {
        type: WATCH_URL_FETCH_IN_PROGRESS,
    };
};

const fetchWatchURLSuccess = (value) => {
    return {
        type: WATCH_URL_FETCH_SUCCESS,
        value,
    };
};

const fetchWatchURLError = (message) => {
    return {
        type: WATCH_URL_FETCH_ERROR,
        message,
    };
};

export const fetchWatchURL = (ID, IP, cb) => (dispatch) => {
    dispatch(fetchWatchURLInProgress());

    const URL = urlWatchURL(ID, IP);

    Axios.get(URL,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
            },
        })
        .then((res) => {
            dispatch(fetchWatchURLSuccess(res.data && res.data.result));
            cb(res.data.result);
        })
        .catch((error) => {
            dispatch(fetchWatchURLError(error.response ? error.response.data.message : error.message));
            cb(null);
        });
};

const fetchDeviceIDInProgress = () => {
    return {
        type: DEVICE_ID_FETCH_IN_PROGRESS,
    };
};

const fetchDeviceIDSuccess = (value) => {
    return {
        type: DEVICE_ID_FETCH_SUCCESS,
        value,
    };
};

const fetchDeviceIDError = (message) => {
    return {
        type: DEVICE_ID_FETCH_ERROR,
        message,
    };
};

export const fetchDeviceID = (cb) => (dispatch) => {
    dispatch(fetchDeviceIDInProgress());

    calculateDeviceFingerprint((error, fingerprint) => {
        if (error) {
            dispatch(fetchDeviceIDError(error.response ? error.response.data.message : error.message));
            cb(null);
        } else {
            dispatch(fetchDeviceIDSuccess(fingerprint));
            cb(fingerprint);
        }
    });
};
