import { Button, MenuItem } from '@material-ui/core';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getAccessToken, loginUser } from '../../actions/login';
import logo from '../../assets/logo.svg';
import Icon from '../../components/Icon';
import EmailTextField from './EmailTextField';
import './index.css';
import PasswordTextField from './PasswordTextField';
import RememberMeCheckbox from './RememberMeCheckbox';

const LoginPage = (props) => {
    const characterCode = (event) => {
        if (event.keyCode === 13) {
            onClick();
        }
    };

    const onClick = () => {
        if (props.inProgress) {
            return;
        }
        props.onClick(props.email, props.password, (cb) => {
            if (cb) {
                return;
            }

            const token = localStorage.getItem('authorizationToken');
            if (token) {
                props.history.push('/manage/assets');
            } else {
                props.history.push('/login');
            }

            if (token) {
                const decoded = jwt.decode(token);
                const difference = moment(new Date((decoded.exp) * 1000)).diff(moment(new Date()));
                const nextCallTime = difference - 300000;
                const refreshToken = localStorage.getItem('refreshToken');
                setTimeout(() => {
                    props.getAccessToken(refreshToken, () => {
                    });
                }, nextCallTime);
            }
        });
    };

    const disable = props.email === '' || props.password === '';

    return (
        <div className="login">
            <div className="login_logo_section">
                <img alt="logo" src={logo}/>
                <div className="cross_line"/>
                <div className="bottom_line"/>
            </div>
            <div className="card_div">
                <div className="card_login">
                    <form onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            onClick(ev);
                        }
                    }}>
                        <div className="fields_section">
                            <EmailTextField/>
                            <div className="password_remember">
                                <PasswordTextField/>
                                <RememberMeCheckbox/>
                            </div>
                        </div>
                        <div className="login_action">
                            <Button
                                className={disable ? 'action_button disabled_button' : 'action_button'}
                                color="primary"
                                disabled={disable}
                                onClick={onClick}
                                onKeyDown={(event) => characterCode(event)}>
                                SIGN IN
                            </Button>
                        </div>
                        <div className="signup_forgotpassword">
                            {/* <div> */}
                            {/*    <MenuItem className="link_url" component={Link} to="/signup"> */}
                            {/*        <Icon */}
                            {/*            className="signup" */}
                            {/*            icon="signup"/> */}
                            {/*        Sign Up */}
                            {/*    </MenuItem> */}
                            {/* </div> */}
                            {/* <div/> */}
                            <div>
                                <MenuItem className="link_url" component={Link} to="/forgotPassword">
                                    <Icon
                                        className="forgotPassword"
                                        icon="forgotPassword"/>Forgot Password
                                </MenuItem>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

LoginPage.propTypes = {
    email: PropTypes.object.isRequired,
    getAccessToken: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    inProgress: PropTypes.bool.isRequired,
    password: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        email: state.login.email,
        password: state.login.password,
        inProgress: state.login.inProgress,
    };
};

const actionsToProps = {
    onClick: loginUser,
    getAccessToken: getAccessToken,
};

export default withRouter(connect(stateToProps, actionsToProps)(LoginPage));
