import { Chip, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import CopyButtonIcon from '../CopyButton/CopyButtonIcon';
import './index.css';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#696969',
                borderWidth: '1px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
            },
        },
        '& .MuiFormHelperText-root': {
            '&.Mui-error': {
                width: '100%',
                margin: '-6px 0',
            },
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            backgroundColor: 'red !important',
        },
    },
}));

const AutoCompleteTextField = (props) => {
    return (
        <div className="auto_complete_div">
            <Autocomplete
                multiple
                className={ClassNames(useStyles().root, 'auto_complete text_field ' + (props.className
                    ? props.className : ''))}
                freeSolo={props.freeSolo && props.freeSolo}
                getOptionLabel={(option) => option}
                id="tags-standard"
                options={props.options}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label ? props.label : null}
                        variant="outlined"
                    />
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={index}
                            className="chips_input"
                            label={option}
                            variant="outlined"
                            {...getTagProps({ index })} />
                    ))
                }
                value={props.value}
                onChange={(event, newValue) =>
                    props.onChange(newValue)
                }/>
            <CopyButtonIcon data={props.value.join(' , ')}/>
        </div>
    );
};

AutoCompleteTextField.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    InputLabelProps: PropTypes.bool,
    className: PropTypes.string,
    freeSolo: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.any,
};

export default AutoCompleteTextField;
