import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setComputerGraphics, showPeoplesDialog } from '../../../../actions/asset';
import AutoCompleteTextField from '../../../../components/AutoCompleteTextField/WithAddButton';

const ComputerGraphicsSelectField = (props) => {
    const showDialog = (value) => {
        props.dialog(value, 'computer graphics', [...props.list], props.onChange);
    };

    return (
        <AutoCompleteTextField
            label="Computer Graphics"
            options={props.options}
            showDialog={showDialog}
            value={props.list}
            onChange={props.onChange}/>
    );
};

ComputerGraphicsSelectField.propTypes = {
    dialog: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        list: state.asset.new.computerGraphics,
        options: state.asset.new.peopleList.list,
    };
};

const actionToProps = {
    onChange: setComputerGraphics,
    dialog: showPeoplesDialog,
};

export default connect(stateToProps, actionToProps)(ComputerGraphicsSelectField);
