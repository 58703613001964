import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

const InteractButton = (props) => {
    const handleClick = () => {
        if (props.hideQuickView) {
            props.hideQuickView();
        }

        props.history.push(`/interactive-video/${props.id}`);
    };

    return (
        <Button
            className="copy_button"
            onClick={handleClick}>
            Interact
        </Button>
    );
};

InteractButton.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    hideQuickView: PropTypes.func,
};

export default withRouter(InteractButton);
