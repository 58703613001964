import { Breadcrumbs, Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { addSlotTemplate } from '../../../../actions/dynamicPrograms';
import { fetchPrograms, fillPrograms } from '../../../../actions/program';
import {
    clearDynamicInputValues,
    setSlotCategory,
    setSlotContentType,
    setSlotIncludes,
    setSlotPopupShow,
    setSlotExcludes, setSlotDuration,
} from '../../../../actions/slot';
import { showMessage } from '../../../../actions/snackbar';
import Icon from '../../../../components/Icon';
import Category from './Category';
// import ContentType from './ContentType';
import './index.css';
import SetTimeTextfield from './SetTimeTextfield';
import Tags from './Tags';
import TemplateNameTextfield from './TemplateNameTextField';
import Templates from './Templates';

class DynamicSlot extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            slotPopup: false,
            showInsertButton: false,
        };
        this.handleTemplates = this.handleTemplates.bind(this);
        this.addTemplatesToslot = this.addTemplatesToslot.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleShowInsertButton = this.handleShowInsertButton.bind(this);
        this.handleCreateTemplate = this.handleCreateTemplate.bind(this);
        this.handleCreateSlot = this.handleCreateSlot.bind(this);
    }

    handleCreate () {
        const data = {
            type: 'video',
        };

        // if (this.props.contentType.length) {
        //     data.type = 'video'; // making it as static as per BE code at current moment.
        // }
        if (this.props.categories.length) {
            data.categories = this.props.categories.map((item) => {
                const existValue = this.props.categoriesList.filter((val) => val.name === item.toLowerCase());
                if (existValue && existValue.length > 0) {
                    return existValue[0]._id;
                }
            });
        }
        if (this.props.duration) {
            data.timePeriod = Number(this.props.duration) * 60;
        }
        if (this.props.includes.length) {
            data.tags = {};
            data.tags.include = this.props.includes;
        }
        if (this.props.excludes.length) {
            if (!data.tags) {
                data.tags = {};
            }
            data.tags.exclude = this.props.excludes;
        }

        if (!(this.props.currentSlot._ && this.props.currentSlot._.channel)) {
            this.props.showMessage('Slot details not found!');
            return;
        }

        this.props.createSlot(this.props.currentSlot._.channel,
            this.props.currentSlot._['live_stream'],
            this.props.currentSlot._._id,
            data, (error) => {
                if (error) {
                    return;
                }

                this.props.fetchPrograms(this.props.currentSlot._.channel,
                    this.props.currentSlot._['live_stream'],
                    this.props.currentSlot._._id, () => {
                    });
                this.props.setSlotPopupShow(false);
                this.props.clearDynamicInputValues();
            });
    }

    handleTemplates () {
        this.setState({
            showTemplates: true,
        });
    }

    addTemplatesToslot () {
        this.setState({
            showTemplates: false,
        });
    }

    handleShowInsertButton () {
        this.setState({
            showInsertButton: true,
        });
    }

    handleCreateTemplate () {
        const token = localStorage.getItem('authorizationToken');

        const data = {
            name: this.props.templateName,
            type: 'video',
        };

        // if (this.props.contentType.length) {
        //     data.type = 'video'; // making it as static as per BE code at current moment.
        // }
        if (this.props.categories.length) {
            data.categories = this.props.categories.map((item) => {
                const existValue = this.props.categoriesList.filter((val) => val.name === item.toLowerCase());
                if (existValue && existValue.length > 0) {
                    return existValue[0]._id;
                }
            });
        }
        if (this.props.duration) {
            data.duration = Number(this.props.duration) * 60;
        }
        if (this.props.includes.length || this.props.excludes.length) {
            data.includeTags = this.props.includes;
            data.excludeTags = this.props.excludes;
        }

        this.props.addSlotTemplate(token, data, (error) => {
            if (!error) {
                this.handleCreate();
            }
        });
    }

    handleCreateSlot () {
        let time = this.props.duration;
        this.props.setSlotContentType([{
            label: <div className="video_icon">
                <Icon
                    className="video"
                    icon="video"/>
                Video
            </div>,
            value: 'video',
        }]);
        this.props.templates.map((value) => {
            if (value.categories.length > 0) {
                value.categories.map((val) => {
                    const array = this.props.categories.filter(
                        (val1) => val1 === (val.name.charAt(0).toUpperCase() + val.name.slice(1)));
                    if (array.length === 0) {
                        const arr = this.props.categories;
                        arr.push(val.name.charAt(0).toUpperCase() + val.name.slice(1));
                        this.props.setSlotCategory([...arr]);
                    }
                });
            }

            if (value.include_tags.length > 0) {
                value.include_tags.map((val) => {
                    const array = this.props.includes.filter((val1) => val1 === val);
                    if (array.length === 0) {
                        const arr = this.props.includes;
                        arr.push(val);
                        this.props.setSlotIncludes([...arr]);
                    }
                });
            }

            if (value.exclude_tags.length > 0) {
                value.exclude_tags.map((val) => {
                    const array = this.props.excludes.filter((val1) => val1 === val);
                    if (array.length === 0) {
                        const arr = this.props.excludes;
                        arr.push(val);
                        this.props.setSlotExcludes([...arr]);
                    }
                });
            }

            if (value.duration) {
                time = Number(time) + (value.duration / 60);
                this.props.setSlotDuration(time);
            }
        });
        this.setState({
            showTemplates: false,
        });
    }

    render () {
        const enable = this.props.templateName;

        return (
            this.state.showTemplates
                ? <div className="slot_content">
                    <div className="header_div">
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            className="bread_crumb"
                            separator={<NavigateNextIcon fontSize="small"/>}>
                            <div
                                className="link"
                                onClick={this.addTemplatesToslot}>
                                Dynamic Slot
                            </div>
                            <p className="active_text">
                                Dynamic Slot Template
                            </p>
                        </Breadcrumbs>
                        <Button
                            className="create_slot"
                            onClick={this.handleCreateSlot}>
                            Save And Insert
                        </Button>
                    </div>
                    <Templates/>
                </div>
                : <div className="slot_content">
                    <div className="slot_header">
                        <div className="text_div">
                            <p>Dynamic Slot</p>
                            <p>add tags to configure your dynamic slot</p>
                        </div>
                        <div className="button_div">
                            <Button className="dynamic_slot" onClick={(e) => this.handleTemplates(e)}>
                                Show Dynamic Templates
                            </Button>
                        </div>
                    </div>
                    <div>
                        <div className="fields_dynamicSlots">
                            {/* <ContentType/> */}
                            <SetTimeTextfield
                                label="Set Time (in minutes, Max: 180)"
                                max={180}
                                style={{ margin: '10px 0' }}
                                type="number"/>
                            <Category/>
                            <Tags/>
                            {this.state.showInsertButton
                                ? <TemplateNameTextfield/>
                                : null}
                        </div>
                        <div className="slot_action">
                            {this.state.showInsertButton
                                ? <Button
                                    className="create_slot"
                                    disabled={!enable}
                                    onClick={this.handleCreateTemplate}>
                                    Save And Insert
                                </Button>
                                : null}
                            {!this.state.showInsertButton
                                ? <>
                                    <Button
                                        className="dynamic_slot"
                                        onClick={this.handleShowInsertButton}>
                                        Create New Template
                                    </Button>
                                    <Button
                                        className="create_slot"
                                        // disabled={!(this.props.contentType && this.props.contentType.length)}
                                        onClick={this.handleCreate}>
                                        Create Slot
                                    </Button>
                                </>
                                : null}
                        </div>
                    </div>
                </div>
        );
    }
}

DynamicSlot.propTypes = {
    addSlotTemplate: PropTypes.func.isRequired,
    categoriesList: PropTypes.array.isRequired,
    clearDynamicInputValues: PropTypes.func.isRequired,
    createSlot: PropTypes.func.isRequired,
    currentSlot: PropTypes.object.isRequired,
    fetchPrograms: PropTypes.func.isRequired,
    setSlotCategory: PropTypes.func.isRequired,
    setSlotContentType: PropTypes.func.isRequired,
    setSlotDuration: PropTypes.func.isRequired,
    setSlotExcludes: PropTypes.func.isRequired,
    setSlotIncludes: PropTypes.func.isRequired,
    setSlotPopupShow: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    templateName: PropTypes.string.isRequired,
    templates: PropTypes.array.isRequired,
    value: PropTypes.bool.isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string.isRequired,
        }),
    ),
    contentType: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string.isRequired,
        }),
    ),
    duration: PropTypes.string,
    excludes: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string.isRequired,
        }),
    ),
    includes: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string.isRequired,
        }),
    ),
};

const stateToProps = (state) => {
    return {
        contentType: state.live.dynamic.contentType,
        categories: state.live.dynamic.categories,
        categoriesList: state.asset.assetCategoriesList.list,
        includes: state.live.dynamic.includes,
        excludes: state.live.dynamic.excludes,
        currentSlot: state.live.slot.current,
        duration: state.live.dynamic.duration,
        templates: state.live.dynamic.slotTemplates.checked,
        templateName: state.live.dynamic.templateName,
    };
};

const actionsToProps = {
    addSlotTemplate,
    createSlot: fillPrograms,
    setSlotPopupShow,
    clearDynamicInputValues,
    fetchPrograms,
    setSlotCategory,
    setSlotContentType,
    setSlotIncludes,
    setSlotExcludes,
    setSlotDuration,
    showMessage,
};

export default connect(stateToProps, actionsToProps)(DynamicSlot);
