import { combineReducers } from 'redux';
import {
    SLOT_NAME_SET,
    SLOT_SETTINGS_DIALOG_HIDE,
    SLOT_SETTINGS_DIALOG_SHOW,
    SLOT_SETTINGS_TAB_SET,
} from '../../constants/slots';

const dialog = (state = false, action) => {
    switch (action.type) {
    case SLOT_SETTINGS_DIALOG_SHOW:
        return true;
    case SLOT_SETTINGS_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const tab = (state = 0, action) => {
    if (action.type === SLOT_SETTINGS_TAB_SET) {
        return action.value;
    }

    return state;
};

const slotName = (state = '', action) => {
    if (action.type === SLOT_NAME_SET) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    dialog,
    tab,
    slotName,
});
