import { makeStyles, IconButton, InputAdornment, TextField as MaterialTextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';
import './index.css';
import searchAssetIcon from '../../assets/searchAsset.svg';
import filterIcon from '../../assets/filter.svg';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#696969',
                borderWidth: '1px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
            },
        },
        '& .MuiFormHelperText-root': {
            '&.Mui-error': {
                width: '100%',
                margin: '-6px 0',
            },
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            backgroundColor: 'red !important',
        },
    },
}));

const TextField = (props) => {
    const onChange = (e) => props.onChange(e.target.value);

    return (
        <MaterialTextField
            InputProps={
                props.name === 'password' || props.name === 'streamKey' ? {
                    startAdornment: (
                        <InputAdornment className="icons_login" position="start">
                            <Icon
                                className="password"
                                icon="password"/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                className="eye_icon"
                                onClick={props.handleClickShowPassword}
                            >
                                {props.showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                } : props.name === 'email' ? {
                    startAdornment: (
                        <InputAdornment className="icons_login" position="start">
                            <Icon
                                className="email"
                                icon="email"/>
                        </InputAdornment>
                    ),
                } : props.name === 'signup_password' ? {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                className="eye_icon"
                                onClick={props.handleClickShowPassword}
                            >
                                {props.showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                } : props.name === 'search' ? {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                className="eye_icon"
                                onClick={props.handleCloseSearch}
                            >
                                <Icon
                                    className="close"
                                    icon="close"
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                } : props.name === 'search_asset' ? {
                    startAdornment: (
                        <InputAdornment className="icons_login" position="start">
                            <img alt="search" src={searchAssetIcon} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="filter"
                                className=""
                                onClick={props.handleCloseSearch}
                            >
                                <img alt="filter" src={filterIcon} />
                            </IconButton>
                        </InputAdornment>
                    ),
                } : null
            }
            className={ClassNames(useStyles().root, 'text_field', props.className ? props.className : '', props.margin ? 'field_margin'
                : '')}
            disabled={props.disable ? props.disable : null}
            helperText={props.name === 'email' || props.name === 'signup_email'
                ? props.valid && props.value.length !== 0 ? '' : 'Please enter valid email address' : ''}
            id={props.id}
            label={props.label ? props.label : null}
            margin="normal"
            multiline={props.multiline ? props.multiline : false}
            name={props.name}
            placeholder={props.placeholder ? props.placeholder : ''}
            required={props.required ? props.required : false}
            rows={props.multiline ? 5 : null}
            rowsMax={props.multiline ? 20 : null}
            style={{ position: props.name === 'email' || props.name === 'signup_email' ? 'relative' : '' }}
            type={props.type ? props.type : 'text'}
            value={props.value}
            variant="outlined"
            onChange={onChange}/>
    );
};

TextField.propTypes = {
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    disable: PropTypes.bool,
    handleClickShowPassword: PropTypes.func,
    handleCloseSearch: PropTypes.func,
    id: PropTypes.string,
    label: PropTypes.string,
    margin: PropTypes.string,
    multiline: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    showPassword: PropTypes.bool,
    type: PropTypes.string,
    valid: PropTypes.bool,
    value: PropTypes.string,
    variant: PropTypes.string,
};

export default TextField;
