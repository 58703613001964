import {
    ADD_FOLDER_NEW_LABEL,
    DIALOG_ADD_FOLDER_HIDE,
    DIALOG_ADD_FOLDER_SHOW,
    SIDEBAR_DRAWER_TOGGLE,
    SIDEBAR_SELECTED_TAB_SET,
} from '../constants/sidebar';
// import { URL_FOLDER_LIST } from '../constants/url';

export const toggleSidebarDrawer = () => {
    return {
        type: SIDEBAR_DRAWER_TOGGLE,
    };
};

export const setSidebarSelectedTab = (url, index) => {
    return {
        type: SIDEBAR_SELECTED_TAB_SET,
        url,
        index,
    };
};

export const showDialogAddFolder = () => {
    return {
        type: DIALOG_ADD_FOLDER_SHOW,
    };
};

export const hideDialogAddFolder = () => {
    return {
        type: DIALOG_ADD_FOLDER_HIDE,
    };
};

export const setFolderNewLabel = (value) => {
    return {
        type: ADD_FOLDER_NEW_LABEL,
        value,
    };
};
