import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setISRCCode } from '../../../../../actions/asset';
import TextField from '../../../../../components/TextField';

const ISRCCodeTextField = (props) => {
    return (
        <TextField
            id="ISRC_Code_text_field"
            label="ISRC Code"
            name="ISRCCode"
            value={props.value}
            onChange={props.onChange}/>
    );
};

ISRCCodeTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.music.ISRCCode,
    };
};

const actionsToProps = {
    onChange: setISRCCode,
};

export default connect(stateToProps, actionsToProps)(ISRCCodeTextField);
