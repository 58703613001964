import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchBugs, setTabValue, setUpdatedBugsList, updateBugs } from '../../../../../actions/bugs';
import { fetchPrograms } from '../../../../../actions/program';

const UpdateButton = (props) => {
    const getPrograms = () => {
        props.slots &&
        Object.keys(props.slots).length &&
        Object.values(props.slots).map((slot, index) => (
            props.fetchPrograms(slot.channel,
                slot['live_stream'],
                slot._id, (cb) => {
                })
        ));
    };

    const handleClick = () => {
        const token = localStorage.getItem('authorizationToken');

        props.updatedBugsList.map((value) => {
            const data = {
                startAt: props.list[value]['start_at'],
                endAt: props.list[value]['end_at'],
                asset: props.list[value].asset._id,
                repeat: props.list[value].repeat,
                frequency: props.list[value].frequency,
                position: props.list[value].position,
            };

            props.updateBugs(props.slot.channel, props.slot.live_stream, props.slot._id, props.list[value]._id, data, token, (error) => {
                if (!error) {
                    props.fetchBugs(props.slot.channel, props.slot.live_stream, props.slot._id, token);
                    getPrograms();
                }
            });

            return null;
        });

        props.setUpdatedBugsList([]);

        if (props.tabValue !== 0) {
            props.setTabValue(0);
        }
    };

    const disable = props.updatedBugsList.length === 0 || props.timeValidation;

    return (
        <Button
            className="add_stream"
            disabled={disable}
            onClick={handleClick}>
            Update
        </Button>
    );
};

UpdateButton.propTypes = {
    addBugs: PropTypes.func.isRequired,
    fetchBugs: PropTypes.func.isRequired,
    fetchPrograms: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    setTabValue: PropTypes.func.isRequired,
    setUpdatedBugsList: PropTypes.func.isRequired,
    slot: PropTypes.object.isRequired,
    slots: PropTypes.array.isRequired,
    tabValue: PropTypes.number.isRequired,
    timeValidation: PropTypes.bool.isRequired,
    updateBugs: PropTypes.func.isRequired,
    updatedBugsList: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
    return {
        list: state.live.bugs.bugs.list,
        slot: state.live.bugs.slot,
        slots: state.live.slot.list,
        tabValue: state.live.bugs.tabValue,
        timeValidation: state.live.bugs.timeValidation,
        updatedBugsList: state.live.bugs.updatedBugs.list,
    };
};

const actionToProps = {
    fetchBugs,
    fetchPrograms,
    setTabValue,
    setUpdatedBugsList,
    updateBugs,
};

export default connect(stateToProps, actionToProps)(UpdateButton);
