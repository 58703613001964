export const FETCH_SLOT_TEMPLATE_IN_PROGRESS = 'FETCH_SLOT_TEMPLATE_IN_PROGRESS';
export const FETCH_SLOT_TEMPLATE_SUCCESS = 'FETCH_SLOT_TEMPLATE_SUCCESS';
export const FETCH_SLOT_TEMPLATE_ERROR = 'FETCH_SLOT_TEMPLATE_ERROR';

export const ADD_SLOT_TEMPLATE_IN_PROGRESS = 'ADD_SLOT_TEMPLATE_IN_PROGRESS';
export const ADD_SLOT_TEMPLATE_SUCCESS = 'ADD_SLOT_TEMPLATE_SUCCESS';
export const ADD_SLOT_TEMPLATE_ERROR = 'ADD_SLOT_TEMPLATE_ERROR';

export const SLOT_TEMPLATES_SET = 'SLOT_TEMPLATES_SET';
export const SELECTED_SLOT_TEMPLATES_SET = 'SELECTED_SLOT_TEMPLATES_SET';

export const DIALOG_DELETE_TEMPLATE_SHOW = 'DIALOG_DELETE_TEMPLATE_SHOW';
export const DIALOG_DELETE_TEMPLATE_HIDE = 'DIALOG_DELETE_TEMPLATE_HIDE';

export const DELETE_TEMPLATE_IN_PROGRESS = 'DELETE_TEMPLATE_IN_PROGRESS';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR';
