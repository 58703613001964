import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import { hideAccountSuccessDialog } from '../../../actions/account';
import success from '../../../assets/logos/success.png';

const SuccessDialog = (props) => {
    return (
        <Dialog
            aria-describedby="connect-account-description"
            aria-labelledby="connect-account-title"
            className="dialog connect_account_dialog"
            open={props.open}
            onClose={props.hide}>
            <DialogContent className="content">
                <img alt="Success" src={success}/>
                <p className="title">Your wallet has been verified Successfully</p>
                <div className="action_buttons">
                    <Button
                        className="ok_button"
                        onClick={props.hide}>
                        Ok
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

SuccessDialog.propTypes = {
    hide: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.account.successDialog,
    };
};

const actionToProps = {
    hide: hideAccountSuccessDialog,
};

export default connect(stateToProps, actionToProps)(SuccessDialog);
