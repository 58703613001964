import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import CustomIcon from '../../../components/Icon';
import linkIcon from '../../../assets/stream_link.svg';
import VideoJS from 'video.js';
import 'video.js/dist/video-js.css';
import Player from '../../../components/Player';
import { CircularProgress } from '@material-ui/core';
import { setStreamPlayerConfig, checkStreamPlayerConnection } from '../../../actions/slot';
import CountdownTimer from '../../CountdownTimer';
import PlayerSelectField from './PlayerSelectField';
import YouTubePlayer from './YouTubePlayer';

const videoJSOptions = {
    autoplay: false,
    controls: true,
    loop: false,
    width: 530,
    height: 300,
};

class LiveStreamPlayer extends React.Component {
    constructor (props) {
        super(props);
        this.initializePlayer = this.initializePlayer.bind(this);
        this.getConfig = this.getConfig.bind(this);
    }

    initializePlayer (ref) {
        if (ref) {
            this.player = VideoJS(ref, videoJSOptions);
        }
    }

    componentDidMount () {
        if (this.props.playOutStatus !== 'dead' || this.props.liveStream.playing_live) {
            this.getConfig();
        }
    }

    componentDidUpdate (prevProps) {
        if ((prevProps.channel !== this.props.channel) ||
            ((prevProps.userLogin.access_token !== this.props.userLogin.access_token))) {
            this.getConfig();
        }

        if (prevProps.videoPlayerType !== this.props.videoPlayerType &&
            this.props.videoPlayerType !== 'nativeStream') {
            this.player.dispose();
        }

        if (prevProps.videoPlayerType !== this.props.videoPlayerType &&
            this.props.videoPlayerType === 'nativeStream') {
            this.getConfig();
        }

        if ((prevProps.playOutStatus !== this.props.playOutStatus ||
            (prevProps.player !== this.props.player &&
                !this.props.inProgress &&
                !this.props.player.config &&
                this.props.player.timer === 0)) &&
            (this.props.playOutStatus !== 'dead' || this.props.liveStream.playing_live)) {
            setTimeout(() => {
                this.getConfig();
            }, this.props.player.count <= 1 ? 20000 : 5000);
        }

        if (prevProps.playOutStatus !== this.props.playOutStatus &&
            this.props.playOutStatus === 'dead') {
            if (this.player) {
                this.player.dispose();
            }
        }
    }

    getConfig () {
        const playStream = this.props.streams.filter((v) => v._id === this.props.stream);
        const url = playStream.length
            ? playStream[0] && playStream[0].view_link
            : '';

        this.props.checkStreamPlayerConnection(url, (error) => {
            if (error && !this.props.liveStream.playing_live) {
                this.props.setStreamPlayerConfig('', true);
                return;
            }

            if (url) {
                const config = {
                    sources: [{
                        type: 'application/x-mpegURL',
                        src: url,
                    }],
                };
                this.props.setStreamPlayerConfig(JSON.stringify(config), false);
            }
        });
    }

    componentWillUnmount () {
        if (this.player && !this.player.isDisposed_) {
            this.player.dispose();
        }
    }

    render () {
        return (
            <div className="now_playing">
                <div className="header">
                    <div className="header_left">
                        <CustomIcon
                            className="play"
                            icon="play"/>
                        <p className="sarabun_font_weight"> Now Playing </p>
                    </div>
                    <div className="right_content">
                        <PlayerSelectField
                            enable={this.props.playOutStatus !== 'dead' || this.props.liveStream.playing_live}/>
                        <div className="header_right sarabun_font_weight">
                            <img alt="stream link" src={linkIcon} title="Stream Link"/>
                        </div>
                    </div>
                </div>
                {
                    (this.props.playOutStatus !== 'dead' || this.props.liveStream.playing_live) &&
                    this.props.player.timer && this.props.player.timer > 0 &&
                    this.props.videoPlayerType === 'nativeStream'
                        ? <CountdownTimer/>
                        : null
                }
                <div className="custom_player">
                    {
                        this.props.inProgress
                            ? <CircularProgress className="loader_css"/>
                            : this.props.playOutStatus &&
                            this.props.playOutStatus === 'dead' &&
                            !this.props.liveStream.playing_live
                                ? <div className="no_data_table">
                                    <p>Please schedule the slot to start streaming</p>
                                </div>
                                : (this.props.playOutStatus !== 'dead' || this.props.liveStream.playing_live) &&
                            this.props.player.config &&
                            this.props.videoPlayerType === 'nativeStream'
                                    ? <Player
                                        initializePlayer={this.initializePlayer}
                                        setup={this.props.player.config}/>
                                    : this.props.videoPlayerType === 'youtube'
                                        ? <YouTubePlayer
                                            stream={this.props.stream}
                                            streams={this.props.streams}/>
                                        : <CircularProgress className="loader_css"/>
                    }
                </div>
            </div>
        );
    }
}

LiveStreamPlayer.propTypes = {
    channel: PropTypes.string.isRequired,
    checkStreamPlayerConnection: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    playOutStatus: PropTypes.string.isRequired,
    runners: PropTypes.arrayOf(
        PropTypes.shape({
            public_address: PropTypes.string.isRequired,
            organization: PropTypes.string.isRequired,
        }),
    ).isRequired,
    setStreamPlayerConfig: PropTypes.func.isRequired,
    stream: PropTypes.string.isRequired,
    streams: PropTypes.array.isRequired,
    videoPlayerType: PropTypes.string.isRequired,
    liveStream: PropTypes.shape({
        playing_live: PropTypes.bool,
    }),
    player: PropTypes.shape({
        config: PropTypes.string.isRequired,
        inProgress: PropTypes.bool.isRequired,
        timer: PropTypes.number.isRequired,
        count: PropTypes.number.isRequired,
    }),
    userLogin: PropTypes.shape({
        access_token: PropTypes.string,
    }),
};

const stateToProps = (state) => {
    return {
        stream: state.live.stream.current,
        streams: state.live.stream.list,
        runners: state.runner.list,
        channel: state.live.channel.current,
        playOutStatus: state.live.playOut.status,
        inProgress: state.live.playOut.inProgress,
        userLogin: state.login.userLogin.result,
        player: state.live.player,
        videoPlayerType: state.live.videoPlayerType,
        liveStream: state.live.config.stream,
    };
};

const actionToProps = {
    setStreamPlayerConfig,
    checkStreamPlayerConnection,
};

export default connect(stateToProps, actionToProps)(LiveStreamPlayer);
