import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';
import { connect } from 'react-redux';
import TypeSelect from '../TypeSelect';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import {
    setDefaultOption,
    setQuestionType,
    addInteractionsToInteractiveVideo,
    updateInteractionOfInteractiveVideo,
    deleteInteractionOfInteractiveVideo,
    getInteractiveVideos,
} from '../../../actions/interactiveVideo';
import AddOptionButton from './AddOptionButton';
import CancelButton from './CancelButton';
import OptionTextField from './OptionTextField';
import QuestionTextArea from './QuestionTextArea';
import SaveButton from './SaveButton';

const MCQType = (props) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disable && props.edit) {
            handleEdit();
            return;
        }

        if (event.key === 'Enter' && !disable) {
            handleSave();
        }
    };

    const fetchDataToSend = () => {
        const options = [];

        props.options && Object.keys(props.options).length > 0 &&
        Object.keys(props.options).map((key, index) => {
            options.push({
                index: index,
                value: props.options[key],
                text: props.options[key],
                type: props.optionTypes[index] ? props.optionTypes[index] : 'TEXT',
            });
        });

        return {
            startAt: props.player.currentTime(),
            type: 'QUIZ',
            question: {
                title: props.question,
                type: 'MCQ_SA',
            },
            timeLimit: 10,
            answers: [props.default],
            options,
        };
    };

    const handleSave = () => {
        const data = fetchDataToSend();

        props.addInteraction(props.currentInteractiveVideo && props.currentInteractiveVideo._id,
            data, localStorage.getItem('authorizationToken'), (error) => {
                if (!error) {
                    props.player.play();
                    props.setQuestionType('');
                }
            });
    };

    const handleEdit = () => {
        const data = fetchDataToSend();

        props.updateInteraction(data, props.currentInteractiveVideo._id,
            props.currentInteraction._id, localStorage.getItem('authorizationToken'), (error) => {
                if (!error) {
                    props.player.play();
                    props.setQuestionType('');
                }
            });
    };

    const handleDelete = () => {
        const token = localStorage.getItem('authorizationToken');

        props.deleteInteraction(props.currentInteractiveVideo._id,
            props.currentInteraction._id, token,
            (error) => {
                if (error) return;

                props.getInteractiveVideos(props.currentInteractiveVideo &&
                    props.currentInteractiveVideo.asset &&
                    props.currentInteractiveVideo.asset._id,
                token);
                props.player.play();
                props.setQuestionType('');
            });
    };

    const handleChange = (event) => {
        props.onChange(props.options[event.target.value], event.target.value);
    };

    const disableArray = props.options && Object.keys(props.options).length > 0 &&
        Object.keys(props.options).filter((key) => props.options[key] === '');

    const disable = props.value === '' || disableArray.length > 0 || props.question === '';
    const editDisable = props.value === '' && disableArray.length > 0 && props.question === '';

    return (
        <div className="question_details mcq_type">
            <div className="heading_row">
                <p className="heading">MCQ Type</p>
                <div className="buttons_section">
                    <CancelButton/>
                    {props.edit
                        ? <EditButton disable={editDisable} onClick={handleEdit}/>
                        : <SaveButton disable={disable} onClick={handleSave}/>}
                    {props.edit && <DeleteButton onClick={handleDelete}/>}
                </div>
            </div>
            <form
                noValidate
                autoComplete="off"
                onKeyPress={handleKeyPress}
                onSubmit={(e) => {
                    e.preventDefault();
                }}>
                <QuestionTextArea/>
                <FormControl className="radio_section" component="fieldset">
                    <RadioGroup value={props.value} onChange={handleChange}>
                        {props.options && Object.keys(props.options).length > 0 &&
                        Object.keys(props.options).map((key, index) => {
                            return (
                                <>
                                    <FormControlLabel
                                        key={key}
                                        control={<Radio/>}
                                        label={<OptionTextField index={key} value={props.options[key]}/>}
                                        value={key}/>
                                    <TypeSelect
                                        defaultValue={props.optionTypes[index]}
                                        index={index}/>
                                </>
                            );
                        })}
                        <AddOptionButton/>
                    </RadioGroup>
                </FormControl>
            </form>
        </div>
    );
};

MCQType.propTypes = {
    addInteraction: PropTypes.func.isRequired,
    default: PropTypes.string.isRequired,
    deleteInteraction: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
    getInteractiveVideos: PropTypes.func.isRequired,
    optionTypes: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    question: PropTypes.string.isRequired,
    setQuestionType: PropTypes.func.isRequired,
    updateInteraction: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    currentInteraction: PropTypes.shape({
        _id: PropTypes.string.isRequired,
    }),
    currentInteractiveVideo: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        asset: PropTypes.shape({
            _id: PropTypes.string.isRequired,
        }),
    }),
};

const stateToProps = (state) => {
    return {
        default: state.interactiveVideo.options.default,
        edit: state.interactiveVideo.edit,
        player: state.interactiveVideo.player,
        question: state.interactiveVideo.question,
        value: state.interactiveVideo.options.option,
        options: state.interactiveVideo.options.options,
        optionTypes: state.interactiveVideo.options.optionTypes,
        currentInteraction: state.interactiveVideo.current.interaction,
        currentInteractiveVideo: state.interactiveVideo.current.data,
    };
};

const actionsToProps = {
    onChange: setDefaultOption,
    setQuestionType,
    getInteractiveVideos,
    addInteraction: addInteractionsToInteractiveVideo,
    updateInteraction: updateInteractionOfInteractiveVideo,
    deleteInteraction: deleteInteractionOfInteractiveVideo,
};

export default connect(stateToProps, actionsToProps)(MCQType);
