import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideIndividualSlot } from '../../../../actions/slot';
import AssetsList from './AssetsList';
import Header from './Header';
import './index.css';
import Player from './Player';
import SlotDetails from './SlotDetails';
import SlotsTabs from './SlotsTabs';

const IndividualSlotDialog = (props) => {
    return (
        <Dialog
            aria-describedby="individual-slot-dialog-description"
            aria-labelledby="individual-slot-dialog-title"
            className="dialog individual_slot_dialog"
            open={props.open}
            onClose={props.hide}>
            <DialogTitle
                className="title"
                id="individual-slot-dialog-title">
                <Header hide={props.hide}/>
            </DialogTitle>
            <DialogContent className="individual_slot scroll_bar">
                <div className="row">
                    <Player/>
                    <AssetsList/>
                </div>
                <div className="row">
                    <SlotsTabs/>
                    <SlotDetails/>
                </div>
            </DialogContent>
        </Dialog>
    );
};

IndividualSlotDialog.propTypes = {
    hide: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.live.individualSlot.dialog,
    };
};

const actionToProps = {
    hide: hideIndividualSlot,
};

export default connect(stateToProps, actionToProps)(IndividualSlotDialog);
