import React from 'react';
import Icon from '../../../components/Icon';
import SelectField from '../../../components/SelectField';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setVideoPlayerType } from '../../../actions/program';

const PlayerSelectField = (props) => {
    const options = [{
        name: <p className="icon_div">
            <Icon className="youtube" icon="youtube"/>
            YouTube
        </p>,
        value: 'youtube',
    }, {
        name: 'Native Stream',
        value: 'nativeStream',
    }];

    return (
        <SelectField
            disabled={!props.enable}
            id="video_player_select_field"
            items={options}
            name="video_player_select"
            value={props.value}
            onChange={(value) => props.onChange(value)}/>
    );
};

PlayerSelectField.propTypes = {
    enable: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.live.videoPlayerType,
    };
};

const actionToProps = {
    onChange: setVideoPlayerType,
};

export default connect(stateToProps, actionToProps)(PlayerSelectField);
