import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Objects = (props) => {
    useEffect(() => {
        props.fetch('', '', props.objects[0]);
    }, []);

    return (
        props.objects.map((value, index) => {
            return (
                <Button
                    key={index}
                    className={props.value === index ? 'active' : ''}
                    onClick={() => props.onClick(index, value)}>
                    {value}
                </Button>
            );
        })
    );
};

Objects.propTypes = {
    fetch: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    objects: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            _id: PropTypes.string.isRequired,
        }),
    ),
};

const stateToProps = (state) => {
    return {
        objects: state.chapterMarking.objects.list,
    };
};

const actionToProps = {};

export default connect(stateToProps, actionToProps)(Objects);
