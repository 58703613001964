import React from 'react';
import Player from '../Player';
import Thumbnail from '../Thumbnail';
import './index.css';
import InstagramTextField from './InstagramTextField';
import MediaNFT from './MediaNft';
// import NatureOfDeal from './NatureOfDeal';
// import OwnedSince from './OwnedSince';
import OwnerShipStatus from './OwnerShipStatus';
// import Rights from './Rights';
// import Territories from './Territories';
import TwitterTextField from './TwitterTextField';
import YouTubeTextField from './YouTubeTextField';

const AssetsInfo = () => {
    return (
        <div className="assets_info">
            <p className="heading_text">Assets Information</p>
            <div className="row">
                <div className="column">
                    <OwnerShipStatus/>
                    {/* <p className="title">Below options are based on ownership status*</p> */}
                    {/* <OwnedSince/> */}
                    {/* <NatureOfDeal/> */}
                    {/* <Rights/> */}
                    {/* <Territories/> */}
                    <div className="platform_links">
                        <p className="title">Platform Links</p>
                        <p className="note">Please paste the platform URL’s to stream Content</p>
                        <InstagramTextField/>
                        <YouTubeTextField/>
                        <TwitterTextField/>
                    </div>
                </div>
                <div className="column column2">
                    <Player/>
                    <p className="title">Action</p>
                    <p className="note">what do you want to do with your asset?</p>
                    {/* <DeepTagging/> */}
                    {/* <Projects/> */}
                    <MediaNFT/>
                </div>
            </div>
            <Thumbnail/>
        </div>
    );
};

export default AssetsInfo;
