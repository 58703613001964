import { Dialog, DialogContent, DialogTitle, IconButton, Input, List, ListItem, Typography } from '@material-ui/core';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { addAlbum, deleteAlbum, fetchAlbum, newNameSet } from '../../../actions/album';
import { hideDialogAddAlbum } from '../../../actions/asset';
import Icon from '../../../components/Icon';

const DialogAddAlbum = (props) => {
    const onChange = (event) => props.onChange(event.target.value);

    const addNewLabel = () => {
        const data = {
            name: props.newLabel,
        };

        props.addAlbum(data, (error) => {
            if (!error) {
                props.onChange('');
                props.fetchAlbum();
            }
        });
    };

    const handleDeleteLabel = (id) => {
        props.deleteAlbum(id, (error) => {
            if (!error) {
                props.fetchAlbum();
            }
        });
    };

    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            className="dialog_add_asset_category"
            open={props.open}
            onClose={props.onClickCancel}>
            <DialogTitle
                className="title"
                id="alert-dialog-title">
                <Typography className="text-16 mb-8 font-600">
                    Add New Album
                </Typography>
            </DialogTitle>
            <DialogContent className="content">
                <List dense>
                    <ListItem
                        dense
                        className="p-0 mb-16">
                        <Icon
                            className="add add_svg"
                            icon="add"/>
                        <Input
                            className={classNames('flex flex-1 mx-8', 'text_field')}
                            name="name"
                            placeholder="Create new label"
                            value={props.newLabel}
                            onChange={onChange}
                        />
                        <IconButton
                            aria-label="Delete"
                            className={classNames('w-32 h-32 mx-4 p-0', 'success_button')}
                            disabled={props.newLabel === ''}
                            onClick={addNewLabel}
                        >
                            <Icon
                                className="success success_svg"
                                icon="success"/>
                        </IconButton>
                    </ListItem>
                    {props.list &&
                    props.list.length > 0
                        ? props.list.map((label) => (
                            <ListItem
                                key={label.name}
                                dense
                                className="p-0">
                                <Icon
                                    className="label label_svg"
                                    icon="label"/>
                                {
                                    typeof label === 'object'
                                        ? <Input
                                            disableUnderline
                                            className={classNames('flex flex-1 mx-8', 'text_field')}
                                            name="name"
                                            value={label.name}
                                            // onChange={(event) => handleLabelChange(event, label.value)}
                                        />
                                        : <Input
                                            disableUnderline
                                            className={classNames('flex flex-1 mx-8', 'text_field')}
                                            name="name"
                                            value={label.name}
                                            // onChange={(event) => handleLabelChange(event, label.value)}
                                        />
                                }
                                <IconButton
                                    aria-label="Delete"
                                    className="w-32 h-32 mx-4 p-0"
                                    onClick={() => handleDeleteLabel(label._id)}
                                >
                                    <Icon
                                        className="delete"
                                        icon="delete"/>
                                </IconButton>
                            </ListItem>
                        ))
                        : null}
                </List>
            </DialogContent>
        </Dialog>
    );
};
DialogAddAlbum.propTypes = {
    addAlbum: PropTypes.func.isRequired,
    deleteAlbum: PropTypes.func.isRequired,
    fetchAlbum: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    newLabel: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.asset.dialogAddAlbum,
        newLabel: state.asset.new.music.album.new,
        list: state.asset.new.music.album.list,
    };
};

const actionsToProps = {
    addAlbum,
    deleteAlbum,
    fetchAlbum,
    onChange: newNameSet,
    onClickCancel: hideDialogAddAlbum,
};

export default connect(stateToProps, actionsToProps)(DialogAddAlbum);
