export const BUGS_DIALOG_SHOW = 'BUGS_DIALOG_SHOW';
export const BUGS_DIALOG_HIDE = 'BUGS_DIALOG_HIDE';

export const BUGS_TAB_VALUE = 'BUGS_TAB_VALUE';
export const BUGS_SLOT_EDIT = 'BUGS_SLOT_EDIT';

export const FETCH_ALL_BUGS_IN_PROGRESS = 'FETCH_ALL_BUGS_IN_PROGRESS';
export const FETCH_ALL_BUGS_SUCCESS = 'FETCH_ALL_BUGS_SUCCESS';
export const FETCH_ALL_BUGS_ERROR = 'FETCH_ALL_BUGS_ERROR';

export const FETCH_BUGS_IN_PROGRESS = 'FETCH_BUGS_IN_PROGRESS';
export const FETCH_BUGS_SUCCESS = 'FETCH_BUGS_SUCCESS';
export const FETCH_BUGS_ERROR = 'FETCH_BUGS_ERROR';

export const BUGS_ADD_SET = 'BUGS_ADD_SET';
export const BUGS_ADD_IN_PROGRESS = 'BUGS_ADD_IN_PROGRESS';
export const BUGS_ADD_SUCCESS = 'BUGS_ADD_SUCCESS';
export const BUGS_ADD_ERROR = 'BUGS_ADD_ERROR';

export const UPDATED_BUGS_LIST_SET = 'UPDATED_BUGS_LIST_SET';
export const UPDATE_BUGS_IN_PROGRESS = 'UPDATE_BUGS_IN_PROGRESS';
export const UPDATE_BUGS_SUCCESS = 'UPDATE_BUGS_SUCCESS';
export const UPDATE_BUGS_ERROR = 'UPDATE_BUGS_ERROR';

export const DELETE_BUGS_IN_PROGRESS = 'DELETE_BUGS_IN_PROGRESS';
export const DELETE_BUGS_SUCCESS = 'DELETE_BUGS_SUCCESS';
export const DELETE_BUGS_ERROR = 'DELETE_BUGS_ERROR';

export const TIME_VALIDATION_SET = 'TIME_VALIDATION_SET';
