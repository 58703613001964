import { combineReducers } from 'redux';
import {
    CHANGE_TIME_FORMAT,
    CLOSE_SEARCH_BAR,
    POPOVER_TIME_FORMAT_HIDE,
    POPOVER_TIME_FORMAT_SHOW,
    PROFILE_IMAGE__FETCH_SUCCESS,
    SHOW_SEARCH_BAR,
    USER_GLOBAL_SEARCH_SET,
} from '../constants/profile';

const timeFormatPopover = (state = {
    anchorEl: null,
}, action) => {
    switch (action.type) {
    case POPOVER_TIME_FORMAT_SHOW:
        return {
            anchorEl: action.anchorEl,
        };
    case POPOVER_TIME_FORMAT_HIDE:
        return {
            anchorEl: null,
        };
    default:
        return state;
    }
};

const search = (state = {
    value: '',
}, action) => {
    if (action.type === USER_GLOBAL_SEARCH_SET) {
        return {
            value: action.value,
        };
    }

    return state;
};

const searchBar = (state = {
    value: false,
}, action) => {
    switch (action.type) {
    case SHOW_SEARCH_BAR:
        return {
            value: action.value,
        };
    case CLOSE_SEARCH_BAR:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const timeFormat = (state = {
    checked: true,
}, action) => {
    if (action.type === CHANGE_TIME_FORMAT) {
        return {
            checked: action.checked,
        };
    }

    return state;
};

const image = (state = '', action) => {
    if (action.type === PROFILE_IMAGE__FETCH_SUCCESS) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    search,
    searchBar,
    timeFormatPopover,
    timeFormat,
    image,
});
