import { combineReducers } from 'redux';
import { PROGRAMS_FETCH_SUCCESS } from '../../constants/program';
import {
    INDIVIDUAL_SLOT_DATE_SET,
    INDIVIDUAL_SLOT_DIALOG_HIDE,
    INDIVIDUAL_SLOT_DIALOG_SHOW,
    INDIVIDUAL_SLOT_FETCH_ERROR,
    INDIVIDUAL_SLOT_FETCH_IN_PROGRESS,
    INDIVIDUAL_SLOT_FETCH_SUCCESS,
    INDIVIDUAL_SLOT_PLAYER_SET,
    INDIVIDUAL_SLOT_TIME_SET,
    SEARCH_ASSETS_SET,
} from '../../constants/slots';

const dialog = (state = false, action) => {
    switch (action.type) {
    case INDIVIDUAL_SLOT_DIALOG_SHOW:
        return true;
    case INDIVIDUAL_SLOT_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const data = (state = {
    slot: {},
    program: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case INDIVIDUAL_SLOT_DIALOG_SHOW:
        return {
            ...state,
            slot: action.slot,
            program: action.program,
        };
    case INDIVIDUAL_SLOT_DIALOG_HIDE:
        return {
            ...state,
            slot: {},
            program: {},
        };
    case INDIVIDUAL_SLOT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INDIVIDUAL_SLOT_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            slot: action.slots && action.slots.length
                ? action.slots[0]
                : {},
        };
    case INDIVIDUAL_SLOT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case INDIVIDUAL_SLOT_PLAYER_SET:
        return {
            ...state,
            program: action.value,
        };
    case PROGRAMS_FETCH_SUCCESS: {
        const key = action.programs && Object.keys(action.programs)[0];
        const program = key && action.programs[key];

        return {
            ...state,
            program: { ...program },
        };
    }
    default:
        return state;
    }
};

const date = (state = new Date(), action) => {
    switch (action.type) {
    case INDIVIDUAL_SLOT_DATE_SET:
        return action.value;
    case INDIVIDUAL_SLOT_DIALOG_SHOW:
        return action.slot && action.slot.start_at;
    case INDIVIDUAL_SLOT_DIALOG_HIDE:
        return new Date();
    default:
        return state;
    }
};

const time = (state = 'none', action) => {
    switch (action.type) {
    case INDIVIDUAL_SLOT_TIME_SET:
        return action.value;
    case INDIVIDUAL_SLOT_DIALOG_HIDE:
        return 'none';
    default:
        return state;
    }
};

const searchAssets = (state = '', action) => {
    if (action.type === SEARCH_ASSETS_SET) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    dialog,
    data,
    date,
    time,
    searchAssets,
});
