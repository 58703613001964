import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchGenres, showDialogAddGenre } from '../../../actions/asset';
import { setGenre } from '../../../actions/chapterMarking';
import AutoCompleteTextField from '../../../components/AutoCompleteTextField/WithAddButton';

class GenreSelectField extends React.Component {
    constructor (props) {
        super(props);
        this.fetch = this.fetch.bind(this);
    }

    componentDidMount () {
        if (this.props.defaultValue && this.props.defaultValue.length > 0) {
            this.props.onChange([...this.props.defaultValue]);
        }

        if (this.props.items.length) {
            return;
        }

        this.fetch();
    }

    fetch () {
        if (this.props.inProgress) {
            return;
        }

        const token = localStorage.getItem('authorizationToken');
        this.props.fetch(token);
    }

    render () {
        return (
            <AutoCompleteTextField
                options={this.props.items}
                showDialog={this.props.dialog}
                value={this.props.value}
                onChange={this.props.onChange}/>
        );
    }
}

GenreSelectField.propTypes = {
    dialog: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.array,
};

const stateToProps = (state) => {
    return {
        value: state.chapterMarking.new.genre,
        items: state.asset.genres.list,
        inProgress: state.asset.genres.inProgress,
    };
};

const actionsToProps = {
    dialog: showDialogAddGenre,
    fetch: fetchGenres,
    onChange: setGenre,
};

export default connect(stateToProps, actionsToProps)(GenreSelectField);
