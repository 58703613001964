import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setToAddress } from '../../../../actions/account';
import TextField from '../../../../components/TextField';

const ToAddressTextField = (props) => {
    const onChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            id="to-address_text_field"
            label="Sender Address"
            name="toAddress"
            type="text"
            value={props.value}
            variant="outlined"
            onChange={onChange}/>
    );
};

ToAddressTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.account.toAddress,
    };
};

const actionsToProps = {
    onChange: setToAddress,
};

export default connect(stateToProps, actionsToProps)(ToAddressTextField);
