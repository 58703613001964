import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { chapterDetailsTab, setChapterRangeValues, setMaxValue, setMinValue } from '../../../actions/chapterMarking';
import './index.css';

momentDurationFormatSetup(moment);

const RangeSlider = (props) => {
    const useStyles = makeStyles({
        root: {
            width: props.width ? `${props.width.toFixed(2)}%` : 300,
            marginTop: 10,
        },
        slider: {
            '& .MuiSlider-mark': {
                height: '6px',
                color: '#FAC01E',
                width: '6px',
                top: '17px',
                borderRadius: '50px',
                marginLeft: '2px',
            },
        },
    });

    const { scenes, max, minValue, maxValue, selected } = props;

    const [steps, setSteps] = useState([]);
    const [value, setValue] = React.useState([props.min ? props.min.toFixed(2) : 0,
        props.max ? props.max.toFixed(2) : 90]);

    const handleUpdate = (newValue) => {
        setValue(newValue);
        props.setChapterRangeValues([...newValue]);
    };

    useEffect(() => {
        const array = [];
        props.scenes && props.scenes.length > 0 &&
        props.scenes.map((value) => {
            array.push({
                ...value,
                value: value.start_at,
            });
        });
        array.push({ value: Number(props.max && props.max.toFixed(2)) });
        setSteps(array);

        setValue([minValue, maxValue]);
    }, [scenes, max, selected, minValue, maxValue]);

    const handleChange = (event, newValue) => {
        if (value[0] === newValue[0]) {
            if (props.marks.length === 0) {
                handleUpdate(newValue);
            }

            if (props.marks.length > 0) {
                if (newValue[1] < props.maxValue) {
                    handleUpdate(newValue);

                    return;
                }

                let max = null;
                props.marks.map((v, index) => {
                    if (newValue[1] < v && max === null) {
                        if (props.marks[index - 1]) {
                            handleUpdate([props.marks[index - 1], v]);
                            props.setMaxValue(v);
                            props.setMinValue(props.marks[index - 1]);
                        } else {
                            handleUpdate([0, v]);
                            props.setMaxValue(v);
                            props.setMinValue(0);
                        }

                        max = v;
                    }
                });

                if (max === null) {
                    if (props.marks && props.marks.length > 0 && props.minValue !== props.marks[props.marks.length - 1]) {
                        props.setMinValue(props.marks[props.marks.length - 1]);
                        props.setMaxValue(Number(props.max));
                        handleUpdate([props.marks[props.marks.length - 1], Number(props.max)]);
                    } else {
                        handleUpdate(newValue);
                    }
                }
            }
        } else if (value[1] === newValue[1]) {
            if (props.marks.length === 0) {
                handleUpdate(newValue);
            }

            if (props.marks.length > 0) {
                if (newValue[0] > props.minValue) {
                    handleUpdate(newValue);

                    return;
                }

                let min = null;
                props.marks.map((v, index) => {
                    if (newValue[0] < v && min === null) {
                        if (props.marks[index - 1]) {
                            handleUpdate([props.marks[index - 1], v]);
                            props.setMaxValue(v);
                            props.setMinValue(props.marks[index - 1]);
                        } else {
                            handleUpdate([0, v]);
                            props.setMaxValue(v);
                            props.setMinValue(0);
                        }

                        min = v;
                    }
                });

                if (min === null) {
                    if (props.marks && props.marks.length > 0 && props.minValue !== props.marks[0]) {
                        props.setMinValue(0);
                        props.setMaxValue(props.marks[0]);
                        handleUpdate([0, props.marks[0]]);
                    } else {
                        handleUpdate(newValue);
                    }
                }
            }
        }
    };

    const handleChapterChange = () => {
        const index = props.chapters.findIndex((item) => item.start_at >= props.minValue);

        if (index !== -1) {
            props.setChapterTab(index);
        }
    };

    const valuetext = (value) => {
        const timeValue = moment.duration(value, 'seconds').format();

        return `${timeValue} seconds`;
    };

    const valueLabelFormat = (value) => {
        return moment.duration(value, 'seconds').format();
    };

    const classes = useStyles();

    return (
        <div
            className={classNames(classes.root, 'slider')}
            onClick={handleChapterChange}>
            <Slider
                aria-labelledby="range-slider"
                className={classNames(classes.slider, 'custom-slider')}
                getAriaValueText={valuetext}
                marks={steps}
                max={props.max ? props.max.toFixed(2) : 90}
                step={null}
                value={value}
                valueLabelDisplay="auto"
                valueLabelFormat={valueLabelFormat}
                onChange={handleChange}/>
            {props.marks &&
            props.marks.length > 0
                ? <div className="chapter_marks">
                    {props.marks.map((value, index) => {
                        return <span
                            key={index}
                            className="chapter"
                            style={{ left: `${(value / props.duration) * 100}%` }}/>;
                    })}
                </div>
                : null
            }
        </div>
    );
};

RangeSlider.propTypes = {
    chapters: PropTypes.arrayOf(
        PropTypes.shape(
            {
                start_at: PropTypes.number.isRequired,
                end_at: PropTypes.number.isRequired,
                duration: PropTypes.number.isRequired,
                scenes: PropTypes.array.isRequired,
            }),
    ).isRequired,
    duration: PropTypes.number.isRequired,
    marks: PropTypes.array.isRequired,
    maxValue: PropTypes.number.isRequired,
    minValue: PropTypes.number.isRequired,
    scenes: PropTypes.arrayOf(
        PropTypes.shape({
            start_at: PropTypes.string.isRequired,
        }),
    ).isRequired,
    selected: PropTypes.number.isRequired,
    setChapterRangeValues: PropTypes.func.isRequired,
    setChapterTab: PropTypes.func.isRequired,
    setMaxValue: PropTypes.func.isRequired,
    setMinValue: PropTypes.func.isRequired,
    max: PropTypes.number,
    min: PropTypes.number,
    width: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        minValue: state.chapterMarking.min,
        maxValue: state.chapterMarking.max,
        selected: state.chapterMarking.chapterDetails.tab,
        chapters: Object.values(state.chapterMarking.chapter.list),
    };
};

const actionsToProps = {
    setChapterRangeValues,
    setMinValue,
    setMaxValue,
    setChapterTab: chapterDetailsTab,
};

export default connect(stateToProps, actionsToProps)(RangeSlider);
