import { combineReducers } from 'redux';
import {
    DEFAULT_OPTION_SET,
    INTERACTIVE_VIDEOS_FETCH_ERROR,
    INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEOS_FETCH_SUCCESS,
    INTERACTIVE_PLAYER_SET,
    INTERACTIVE_QUESTION_SET,
    INTERACTIVE_QUESTION_TYPE_SET,
    INTERACTIVE_QUESTIONS_LIST_SET,
    OPTIONS_SET,
    QUESTION_TYPES_SHOW,
    INTERACTIVE_EDIT_SET,
    INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_SUCCESS,
    INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_ERROR,
    INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_IN_PROGRESS,
    INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_SUCCESS,
    INTERACTIVE_SAVE_SET,
    CURRENT_INTERACTION_SET, OPTION_TYPE_SET,
} from '../constants/interactiveVideo';
import { WATCH_URL_FETCH_SUCCESS } from '../constants/videoAssets';

const questionTypes = (state = {
    show: false,
    value: '',
}, action) => {
    switch (action.type) {
    case QUESTION_TYPES_SHOW:
        return {
            ...state,
            show: true,
        };
    case INTERACTIVE_EDIT_SET:
        return {
            show: true,
            value: action.value && action.value.type === 'SEEK'
                ? 'SEEK'
                : action.value && action.value.question && action.value.question.type,
        };
    case INTERACTIVE_QUESTION_TYPE_SET:
        return {
            value: action.value,
            show: false,
        };
    default:
        return state;
    }
};

const question = (state = '', action) => {
    switch (action.type) {
    case INTERACTIVE_QUESTION_SET:
        return action.value;
    case INTERACTIVE_EDIT_SET:
        return action.value && action.value.question && action.value.question.title;
    case INTERACTIVE_QUESTION_TYPE_SET:
        return '';
    default:
        return state;
    }
};

const questionsList = (state = [], action) => {
    switch (action.type) {
    case INTERACTIVE_QUESTIONS_LIST_SET: {
        const array = [...state];
        array.push(action.value);

        return array;
    }
    case INTERACTIVE_VIDEOS_FETCH_SUCCESS: {
        const array = [];
        action.list && action.list[0] && action.list[0].interactions &&
        action.list[0].interactions.map((item) => array.push(item));

        return array;
    }
    case INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_SUCCESS: {
        const array = [];
        action.value && action.value.interactions &&
        action.value.interactions.map((item) => array.push(item));

        return array;
    }
    case INTERACTION_OF_INTERACTIVE_VIDEO_UPDATE_SUCCESS: {
        const array = [];
        action.value && action.value.interactions &&
        action.value.interactions.map((item) => array.push(item));

        return array;
    }
    default:
        return state;
    }
};

const player = (state = null, action) => {
    if (action.type === INTERACTIVE_PLAYER_SET) {
        return action.value;
    }

    return state;
};

const options = (state = {
    default: '',
    option: '',
    type: 'TEXT',
    options: {},
    optionTypes: [],
}, action) => {
    switch (action.type) {
    case DEFAULT_OPTION_SET:
        return {
            ...state,
            default: action.value,
            option: action.option,
        };
    case INTERACTIVE_QUESTION_TYPE_SET:
        return {
            ...state,
            default: '',
            option: '',
            options: {},
            optionTypes: [],
        };
    case OPTIONS_SET:
        return {
            ...state,
            options: action.value,
        };
    case OPTION_TYPE_SET: {
        const array = state.optionTypes;
        array[action.index] = action.value;
        return {
            ...state,
            optionTypes: array,
        };
    }
    case INTERACTIVE_EDIT_SET: {
        const array = state.optionTypes;
        action.value.options.map((item, index) => {
            array[index] = item.type;
            return array;
        },
        );

        return {
            ...state,
            default: action.defaultValue,
            options: action.options,
            option: action.option,
            optionTypes: array,
        };
    }
    default:
        return state;
    }
};

const videos = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        return {
            list: action.list,
            inProgress: false,
        };
    case INTERACTIVE_VIDEOS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const current = (state = {
    data: {},
    inProgress: false,
    interaction: {},
}, action) => {
    switch (action.type) {
    case INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_SUCCESS:
        return {
            data: action.value,
            inProgress: false,
        };
    case INTERACTIONS_TO_INTERACTIVE_VIDEO_ADD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case INTERACTIVE_VIDEOS_FETCH_SUCCESS:
        return {
            ...state,
            data: action.list && action.list[0],
        };
    case INTERACTIVE_EDIT_SET:
        return {
            ...state,
            interaction: action.value,
        };

    default:
        return state;
    }
};

const edit = (state = false, action) => {
    switch (action.type) {
    case INTERACTIVE_EDIT_SET:
        return true;
    case INTERACTIVE_QUESTION_TYPE_SET:
        return false;
    default:
        return state;
    }
};

const answers = (state = {}, action) => {
    switch (action.type) {
    case INTERACTIVE_SAVE_SET:
        return action.value;
    case WATCH_URL_FETCH_SUCCESS:
        return {};
    default:
        return state;
    }
};

export const currentInteraction = (state = 0, action) => {
    if (action.type === CURRENT_INTERACTION_SET) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    questionTypes,
    question,
    questionsList,
    player,
    options,
    edit,
    videos,
    current,
    answers,
    currentInteraction,
});
