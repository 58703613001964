import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setText } from '../../../../../actions/textToSpeech';
import TextField from '../../../../../components/TextField';

const TextTextField = (props) => {
    return (
        <TextField
            id="text_text_field"
            label="Text"
            multiline={true}
            name="text"
            value={props.value}
            onChange={props.onChange}/>
    );
};

TextTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.textToSpeech.text,
    };
};

const actionsToProps = {
    onChange: setText,
};

export default connect(stateToProps, actionsToProps)(TextTextField);
