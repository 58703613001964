import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addAsset, showAssetCategoryTableList } from '../../../../actions/asset';

const AddButton = (props) => {
    const disable =
        props.id === '' ||
        props.name === '' ||
        props.tags.length === 0 ||
        props.sourceType.length === 0 ||
        props.sourceId === '';

    const onClick = () => {
        if (props.inProgress) {
            return;
        }

        const url = props.location.pathname;
        const pathName = url.split('/');
        const token = localStorage.getItem('authorizationToken');
        const data = {
            type: props.type,
            customId: props.id,
            name: props.name,
            tags: props.tags,
            file: {
                source: props.sourceType,
                id: props.sourceId,
            },
        };
        props.onClick(data,
            token, (cb) => {
                if (cb) {
                    return;
                }
                props.tableList(pathName ? pathName[3] : '', token);
            });
    };

    return (
        <Button
            className={disable ? 'action_button disabled_button' : 'action_button'}
            color="primary"
            disabled={disable}
            onClick={onClick}>
            Add
        </Button>
    );
};
AddButton.propTypes = {
    album: PropTypes.string.isRequired,
    assetFileId: PropTypes.string.isRequired,
    category: PropTypes.object.isRequired,
    fileInfo: PropTypes.object.isRequired,
    genre: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    sourceId: PropTypes.string.isRequired,
    sourceType: PropTypes.string.isRequired,
    tableList: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        id: state.asset.new.id,
        name: state.asset.new.name,
        category: state.asset.assetCategoriesList,
        genre: state.asset.new.genre,
        fileInfo: state.asset.addAssetFile.result,
        assetFileId: state.asset.new.assetFile,
        inProgress: state.asset.new.inProgress,
        sourceType: state.asset.new.sourceTypeValue,
        sourceId: state.asset.new.sourceUrl,
        tags: state.asset.new.tags,
        album: state.asset.new.album,
    };
};

const actionsToProps = {
    onClick: addAsset,
    tableList: showAssetCategoryTableList,
};

export default withRouter(connect(stateToProps, actionsToProps)(AddButton));
