import { Button, Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideSlotSettingsDialog, setTabValue } from '../../../../../actions/slot';
import TabPanel from '../../../../../components/TabPanel';
import '../BugsDialog/index.css';
import AdsTable from './AdsTable';
import ContentTab from './ContentTab';
import './index.css';

const SlotSettingsDialog = (props) => {
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    return (
        <Dialog
            className="bugs_dialog slot_settings_dialog"
            open={props.open}
            onClose={props.hide}>
            <DialogTitle
                className="title_div"
                id="alert-dialog-title">
                <Button
                    className="back_button"
                    onClick={props.hide}>
                    <ArrowBackIcon/>
                </Button>
                <p className="title_text">Slot Setting</p>
            </DialogTitle>
            <DialogContent className="content">
                <Tabs
                    className="tabs"
                    value={props.tabValue}
                    onChange={handleChange}>
                    <Tab
                        className={ClassNames('tab', props.tabValue === 0 ? 'active' : '')}
                        label="Content"
                        {...a11yProps(0)}/>
                    <Tab
                        className={ClassNames('tab', props.tabValue === 1 ? 'active' : '')}
                        label="Ad"
                        {...a11yProps(1)}/>
                </Tabs>
                <TabPanel
                    className="tabs_content"
                    index={0}
                    value={props.tabValue}>
                    <ContentTab/>
                </TabPanel>
                <TabPanel
                    className="p-0"
                    index={1}
                    value={props.tabValue}>
                    <div className="data_table table_modal table_scroll_bar">
                        <AdsTable/>
                    </div>
                </TabPanel>
            </DialogContent>
        </Dialog>
    );
};

SlotSettingsDialog.propTypes = {
    hide: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setTabValue: PropTypes.func.isRequired,
    slotBugs: PropTypes.array.isRequired,
    slotBugsInProgress: PropTypes.bool.isRequired,
    tabValue: PropTypes.number.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.live.slotSettings.dialog,
        tabValue: state.live.slotSettings.tab,
        slotBugs: state.live.bugs.bugs.list,
        slotBugsInProgress: state.live.bugs.bugs.inProgress,
    };
};

const actionToProps = {
    hide: hideSlotSettingsDialog,
    setTabValue,
};

export default connect(stateToProps, actionToProps)(SlotSettingsDialog);
