import { Button, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { editableData, fetchCategories, setAssetTags, updateAsset } from '../../../../actions/asset';
import { fetchAllBugs, fetchBugs, showBugDialog } from '../../../../actions/bugs';
import { deleteProgram, fetchPrograms, updatePrograms, setUpdatedPrograms } from '../../../../actions/program';
import { showIndividualSlot, setIndividualSlotPlayer } from '../../../../actions/slot';
import audioIcon from '../../../../assets/audio.svg';
import deleteIcon from '../../../../assets/delete.svg';
import CustomIcon from '../../../../components/Icon';
import {
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../../../../constants/url';
import Bugs from './Bugs';
import playerIcon from '../../../../assets/videoType.svg';
import ClassNames from 'classnames';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        background: '#202123',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        padding: '0px',
    },
}))(Tooltip);

const handleDelete = (props) => {
    props.deleteProgram(props.program.channel,
        props.program['live_stream'],
        props.program.slot && props.program.slot._id,
        props.program._id, 1, (error) => {
            if (error) {
                return;
            }

            const updatedContent = Object.values(props.programs)
                .filter((v) =>
                    (v.slot && v.slot._id) !== (props.program && props.program.slot && props.program.slot._id));

            props.setUpdatedPrograms(updatedContent);
            props.fetchPrograms(props.program.channel,
                props.program['live_stream'],
                props.program.slot && props.program.slot._id, () => {
                });
        });
};

const handleDuplicate = (props, index) => {
    console.log('props', props);
    const duplicate = props.program;
    const program = [];
    let startTime = duplicate.end_at;
    let endTime;
    const boxes = props.slotList;
    let data = {};
    if (props.slotList && props.slotList.length && (index === (props.slotList.length - 1))) {
        data = {
            startAt: startTime,
            endAt: moment(startTime).add(props.value && props.value.length, 's').utc().format(),
            asset: {
                id: duplicate.asset._._id,
                startAt: duplicate.asset['start_at'],
                endAt: duplicate.asset['end_at'],
            },
        };
        program.push(data);
    } else {
        endTime = moment(startTime).add(props.value && props.value.length, 's').utc().format();
        const firstData = {
            startAt: startTime,
            endAt: endTime,
            asset: {
                id: duplicate.asset._._id,
                startAt: duplicate.asset['start_at'],
                endAt: duplicate.asset['end_at'],
            },
        };
        startTime = endTime;
        program.push(firstData);
        for (let i = index; i < boxes.length - 1; i++) {
            endTime = moment(boxes[i + 1].end_at).diff(moment(boxes[i + 1].start_at));
            data = {
                startAt: startTime,
                endAt: moment(startTime).add(endTime, 'ms').utc().toISOString(),
                asset: {
                    id: boxes[i + 1].asset._._id,
                    startAt: boxes[i + 1].asset['start_at'],
                    endAt: boxes[i + 1].asset['end_at'],
                },
            };
            program.push(data);
            startTime = moment(startTime).add(endTime, 'ms').utc().toISOString();
            boxes[i] = boxes[i + 1];
        }
    }
    const updatedProgram = { programs: program };
    props.updatePrograms(props.program.channel,
        props.program['live_stream'],
        props.program.slot && props.program.slot._id,
        updatedProgram, (error) => {
            if (!error) {
                const updatedContent = Object.values(props.programs).filter((v) => v.slot._id !== props.program.slot._id);
                props.setUpdatedPrograms(updatedContent);
                props.fetchPrograms(props.program.channel,
                    props.program['live_stream'],
                    props.program.slot && props.program.slot._id, (cb) => {
                    });
            }
        });
};

const Programs = (props) => {
    const [left, setLeft] = React.useState(0);
    const [top, setTop] = React.useState(0);
    const [display, setDisplay] = React.useState(true);
    const [time, setTime] = React.useState();
    const [sendTime, setSendTime] = React.useState();
    const [open, setOpen] = React.useState(false);
    const timeZone = props.configuration['stream_timezone'];

    const handleHover = (e) => {
        const parent = e.target.closest('.bug_div');
        let element;
        if (parent) {
            element = parent;
        } else {
            element = e.target;
        }

        const DomRect = element.getBoundingClientRect();
        const duration = momentTimeZone.tz(props.program['end_at'], timeZone).diff(
            momentTimeZone.tz(props.program['start_at'], timeZone), 'seconds');
        const time = (e.clientX - Math.floor(DomRect.left)) * (duration / DomRect.width);
        setSendTime(momentTimeZone.tz(props.program['start_at'], timeZone).add(time, 's').format());
        if (props.checked) {
            setTime(momentTimeZone.tz(props.program['start_at'], timeZone).add(time, 's').format('hh:mm:ss A'));
        } else {
            setTime(momentTimeZone.tz(props.program['start_at'], timeZone).add(time, 's').format('HH:mm:ss') + ' Hrs');
        }
        setLeft(e.clientX);
        setTop(e.clientY);
        setDisplay(false);
    };

    const handleMouseOut = () => {
        setDisplay(true);
    };

    const handleShowAddButton = () => {
        setDisplay(false);
    };

    const handleClickAdd = () => {
        const token = localStorage.getItem('authorizationToken');

        if (!props.categoryListInProgress && props.categoryList && props.categoryList.length === 0) {
            props.fetchCategories(token, (data) => {
                if (data) {
                    data.length > 0 &&
                    data.map((val) => {
                        if (val.name === 'Bugs') {
                            props.fetchAllBugs(val.value,
                                DEFAULT_SKIP,
                                DEFAULT_LIMIT,
                                DEFAULT_SEARCH_KEY,
                                DEFAULT_SORT_BY,
                                DEFAULT_ORDER, token);
                        }
                    });
                    props.fetchBugs(props.slot.channel, props.slot.live_stream, props.slot._id, token);
                    props.showBugDialog(sendTime, props.slot);
                }
            });
        } else {
            if (props.allBugs && props.allBugs.length === 0) {
                props.categoryList.map((val) => {
                    if (val.name === 'Bugs') {
                        props.fetchAllBugs(val.value,
                            DEFAULT_SKIP,
                            DEFAULT_LIMIT,
                            DEFAULT_SEARCH_KEY,
                            DEFAULT_SORT_BY,
                            DEFAULT_ORDER, token);
                    }
                });
            }
            props.fetchBugs(props.slot.channel, props.slot.live_stream, props.slot._id, token);
            props.showBugDialog(sendTime, props.slot);
        }
    };

    // const handleTooltipOpen = (program) => {
    //     if (program.asset && program.asset._ && !open) {
    //         setOpen(true);
    //         props.editableData(program.asset._);
    //     }
    // };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleOpenTooltip = () => {
        if (!props.individualSlot) {
            setOpen(true);
        } else {
            props.setIndividualSlotPlayer(props.program);
        }
    };

    // const updateTags = () => {
    //     const token = localStorage.getItem('authorizationToken');
    //     const assetId = props.program.asset &&
    //         props.program.asset &&
    //         props.program.asset._ &&
    //         props.program.asset._._id;
    //     const data = {};
    //
    //     if (props.tags) {
    //         data.tags = props.tags;
    //     }
    //
    //     data.type = 'video'; // putting this static as we dont have this type in program.
    //
    //     if (token && assetId) {
    //         props.onClick(assetId, data, token, () => {
    //             handleTooltipClose();
    //         });
    //     }
    // };

    return (
        <div
            className="slots"
            draggable={props.draggable}
            style={{
                width: props.percentage > 12 ? '20%' : '10%',
            }}
            onDragOver={(e) => props.onDragOver(e, props.index, props.iteration)}
            onDragStart={(e) => props.onDragStart(e, props.index)}
            onDrop={(e) => props.onDrop(e, props.index)}
            onMouseOut={props.iteration ? () => props.onMouseOver(null) : null}
            onMouseOver={props.iteration ? () => props.onMouseOver(props.iteration) : null}>
            <div className="stream_video">
                <div className="add_div">
                    {
                        props.program.asset &&
                        props.program.asset._ &&
                        props.program.asset._.file &&
                        props.program.asset._.file.encode &&
                        props.program.asset._.file.encode.segments
                            ? props.program.asset._.file.encode.segments.map((ad, index) => {
                                const duration = moment(props.program['end_at'])
                                    .diff(moment(props.program['start_at']));
                                const addTime = ad['start_at'] * 1000; // converting to milli Seconds
                                const leftValue = (addTime / duration) * 100;
                                return (
                                    <Tooltip
                                        key={index}
                                        placement="top"
                                        title={moment(props.program['start_at']).add(ad['start_at'], 's').local().format('HH:mm:ss')}>
                                        <p
                                            className="add"
                                            style={{ left: leftValue + '%' }}/>
                                    </Tooltip>
                                );
                            })
                            : ''}
                </div>
                <HtmlTooltip
                    interactive
                    PopperProps={{
                        disablePortal: true,
                    }}
                    className="tooltip_update_program"
                    open={open}
                    placement="top-start"
                    title={
                        <div className="update_program">
                            <div className="header">
                                <div className="profile">
                                    <div>
                                        <img alt="" src=""/>
                                    </div>
                                    <div className="user_details">
                                        {props.program['is_dynamic']
                                            ? <p>Dynamically Added</p>
                                            : <>
                                                <p> Added by</p>
                                                <p>
                                                    {
                                                        props.program &&
                                                        props.program['added_by'] &&
                                                        props.program['added_by'].name
                                                    }
                                                </p>
                                            </>}
                                    </div>
                                </div>
                                <div className="update_tooltip_action">
                                    {!(moment(props.slot['end_at']).isBefore(moment(), 'second'))
                                        ? <div
                                            className="remove_asset"
                                            title="Remove program from slot"
                                            onClick={() => handleDelete(props)}>
                                            <img
                                                alt="delete"
                                                src={deleteIcon}/>
                                        </div>
                                        : null}
                                    <div
                                        className="remove_asset"
                                        title="Remove program from slot"
                                        onClick={() => props.showIndividualSlot(props.slot, props.program)}>
                                        <img
                                            alt="play"
                                            src={playerIcon}/>
                                    </div>
                                    <div
                                        className="remove_asset"
                                        title="Duplicate program"
                                        onClick={() => handleDuplicate(props, props.index)}>
                                        <ControlPointDuplicateIcon/>
                                    </div>
                                </div>
                            </div>
                            <div className="name">
                                <p>  {props.program.asset &&
                                props.program.asset._ &&
                                props.program.asset._.video &&
                                props.program.asset._.video.name
                                    ? props.program.asset._.video.name
                                    : props.program.asset &&
                                    props.program.asset._ &&
                                    props.program.asset._.file &&
                                    props.program.asset._.file.name
                                        ? props.program.asset._.file.name
                                        : ''
                                }</p>
                            </div>
                            <div className="timing">
                                <div>
                                    <p> Start Time </p>
                                    <p> {props.program &&
                                    props.program['start_at']
                                        ? props.checked
                                            ? momentTimeZone.tz(props.program['start_at'], timeZone).format('hh:mm:ss A')
                                            : momentTimeZone.tz(props.program['start_at'], timeZone).format('HH:mm:ss') + ' Hrs'
                                        : ''} </p>
                                </div>
                                <div>
                                    <p> End Time </p>
                                    <p> {props.program &&
                                    props.program['end_at']
                                        ? props.checked
                                            ? momentTimeZone.tz(props.program['end_at'], timeZone).format('hh:mm:ss A')
                                            : momentTimeZone.tz(props.program['end_at'], timeZone).format('HH:mm:ss') + ' Hrs'
                                        : ''} </p>
                                </div>
                                <div>
                                    <p> Duration </p>
                                    <p> {
                                        props.program &&
                                        props.program['end_at']
                                            ? moment.utc(moment.duration(moment(props.program['end_at'])
                                                .diff(moment(props.program['start_at']), 'seconds'),
                                            'seconds')
                                                .asMilliseconds()).format(props.timeFormat)
                                            : ''

                                    } </p>
                                </div>
                            </div>
                            <div className="tags">
                                <div>
                                    Tags
                                </div>
                                <div className="tags_items">
                                    {props.program && props.program.asset && props.program.asset._ && props.program.asset._.tags
                                        ? <div className="assets_tags">
                                            {props.program.asset._.tags.join(', ')}
                                        </div> : []}
                                </div>
                            </div>
                        </div>
                    }
                    onClose={() => handleTooltipClose()}
                    // onOpen={() => handleTooltipOpen(props.program)}
                >
                    <div
                        className={ClassNames(props.program && props.program['is_dynamic'] === true ? 'video dynamic_program'
                            : 'video sarabun_font_weight',
                        props.individualSlot && props.individualSlotData && props.individualSlotData.program &&
                        props.program && (props.individualSlotData.program._id === props.program._id) &&
                            (props.individualSlotData.program.start_at === props.program.start_at)
                            ? 'active_program' : '')}
                        style={{
                            borderColor: props.program && props.program.asset && props.program.asset._ &&
                                props.program.asset._.category &&
                                (props.program.asset._.category.name &&
                                props.program.asset._.category.name.match('movie')
                                    ? 'orange'
                                    : props.program.asset._.category.name &&
                                    props.program.asset._.category.name.match('music')
                                        ? 'cornflowerblue'
                                        : props.program.asset._.category.name &&
                                        props.program.asset._.category.name.match('scene')
                                            ? 'green'
                                            : '#505050'),
                        }}
                        onClick={() => handleOpenTooltip()}>
                        {props.program && props.program.asset && props.program.asset._ &&
                        props.program.asset._.category &&
                        (props.program.asset._.category.name === 'music'
                            ? <img alt="audio" src={audioIcon}/>
                            : <CustomIcon
                                className="video"
                                icon="video"/>)}
                        <div className="program_details">
                            <div className="program_name">
                                {
                                    props.program &&
                                    props.program.asset &&
                                    props.program.asset._ &&
                                    props.program.asset._.video &&
                                    props.program.asset._.video.name
                                        ? props.program.asset._.video.name
                                        : props.program &&
                                        props.program.asset &&
                                        props.program.asset._ &&
                                        props.program.asset._.file &&
                                        props.program.asset._.file.name
                                            ? props.program.asset._.file.name
                                            : ''
                                }
                            </div>
                            <div className="program_duration">
                                {props.duration
                                    ? props.duration
                                    : moment.utc(moment.duration(
                                        moment(props.program['end_at']).diff(
                                            moment(props.program['start_at']), 'seconds'), 'seconds')
                                        .asMilliseconds()).format(props.timeFormat)
                                }
                            </div>
                        </div>
                    </div>
                </HtmlTooltip>
                <div
                    className="bug_div"
                    onMouseOut={handleMouseOut}
                    onMouseOver={(e) => handleHover(e, props.program)}>
                    <Bugs
                        overlays={props.program && props.program.overlays}
                        program={props.program}
                        timeZone={timeZone}/>
                </div>
                <div
                    className="bug_add_section"
                    style={{
                        left: left - 36,
                        top: top - 8,
                        display: display ? 'none' : 'unset',
                    }}>
                    <Button
                        className="add_bug"
                        onClick={handleClickAdd}
                        onMouseOut={handleMouseOut}
                        onMouseOver={handleShowAddButton}>
                        <CustomIcon
                            className="plus"
                            icon="plus"/>
                    </Button>
                    <div className="bug_time">
                        {time}
                    </div>
                </div>
            </div>
        </div>
    );
};

Programs.propTypes = {
    allBugs: PropTypes.array.isRequired,
    assetId: PropTypes.string.isRequired,
    assetUpdating: PropTypes.bool.isRequired,
    categoryList: PropTypes.array.isRequired,
    categoryListInProgress: PropTypes.bool.isRequired,
    checked: PropTypes.bool.isRequired,
    configuration: PropTypes.object.isRequired,
    deleteProgram: PropTypes.func.isRequired,
    draggable: PropTypes.bool.isRequired,
    duration: PropTypes.number.isRequired,
    editableData: PropTypes.func.isRequired,
    fetchAllBugs: PropTypes.func.isRequired,
    fetchBugs: PropTypes.func.isRequired,
    fetchCategories: PropTypes.func.isRequired,
    fetchPrograms: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    individualSlotData: PropTypes.object.isRequired,
    percentage: PropTypes.number.isRequired,
    program: PropTypes.object.isRequired,
    programs: PropTypes.object.isRequired,
    setAssetTags: PropTypes.func.isRequired,
    setIndividualSlotPlayer: PropTypes.func.isRequired,
    setUpdatedPrograms: PropTypes.func.isRequired,
    showBugDialog: PropTypes.func.isRequired,
    showIndividualSlot: PropTypes.func.isRequired,
    slot: PropTypes.object.isRequired,
    slotList: PropTypes.array.isRequired,
    slots: PropTypes.array.isRequired,
    stream: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    timeFormat: PropTypes.string.isRequired,
    updatePrograms: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onDragOver: PropTypes.func.isRequired,
    onDragStart: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    handleEditProgram: PropTypes.func,
    hideUpdateProgramDialog: PropTypes.func,
    individualSlot: PropTypes.bool,
    iteration: PropTypes.number,
    onMouseOver: PropTypes.func,
};

const stateToProps = (state) => {
    return {
        configuration: state.live.stream.configuration,
        timeFormat: state.live.timeFormat,
        checked: state.profile.timeFormat.checked,
        assetUpdating: state.asset.new.inProgress,
        tags: state.asset.new.tags,
        assetId: state.asset.new.assetId,
        categoryList: state.asset.new.categories.list,
        categoryListInProgress: state.asset.new.categories.inProgress,
        allBugs: state.live.bugs.allBugs.list,
        slots: state.live.slot.list,
        programs: state.live.program.list,
        individualSlotData: state.live.individualSlot.data,
    };
};

const actionsToProps = {
    deleteProgram,
    fetchAllBugs,
    fetchBugs,
    fetchCategories,
    setAssetTags,
    onClick: updateAsset,
    showBugDialog,
    editableData,
    updatePrograms,
    fetchPrograms,
    setUpdatedPrograms,
    showIndividualSlot,
    setIndividualSlotPlayer,
};

export default connect(stateToProps, actionsToProps)(Programs);
