import { combineReducers } from 'redux';
import {
    ADD_FOLDER_NEW_LABEL,
    DIALOG_ADD_FOLDER_HIDE,
    DIALOG_ADD_FOLDER_SHOW,
    NEW_FOLDER_ADD_ERROR,
    NEW_FOLDER_ADD_IN_PROGRESS,
    NEW_FOLDER_ADD_SUCCESS,
    SIDEBAR_DRAWER_TOGGLE,
    SIDEBAR_SELECTED_TAB_SET,
} from '../constants/sidebar';

const drawer = (state = true, action) => {
    if (action.type === SIDEBAR_DRAWER_TOGGLE) {
        return !state;
    }

    return state;
};

const tab = (state = {
    url: '',
    index: 0,
}, action) => {
    if (action.type === SIDEBAR_SELECTED_TAB_SET) {
        return {
            url: action.url,
            index: action.index,
        };
    }

    return state;
};

const dialogAddFolder = (state = false, action) => {
    switch (action.type) {
    case DIALOG_ADD_FOLDER_SHOW:
        return true;
    case DIALOG_ADD_FOLDER_HIDE:
    case NEW_FOLDER_ADD_SUCCESS:
    case NEW_FOLDER_ADD_ERROR:
        return false;
    default:
        return state;
    }
};

const newFolder = (state = '', action) => {
    switch (action.type) {
    case ADD_FOLDER_NEW_LABEL:
        return action.value;
    case DIALOG_ADD_FOLDER_HIDE:
        return '';
    default:
        return state;
    }
};

const isProgress = (state = false, action) => {
    switch (action.type) {
    case NEW_FOLDER_ADD_IN_PROGRESS:
        return true;
    case NEW_FOLDER_ADD_SUCCESS:
    case NEW_FOLDER_ADD_ERROR:
        return false;
    default:
        return state;
    }
};

export default combineReducers({
    drawer,
    tab,
    dialogAddFolder,
    newFolder,
    isProgress,
});
