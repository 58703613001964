import { Slider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import DataTable from '../../../../../components/DataTable';
import SkeletonLoader from '../../../../../components/SkeletonLoader/Table';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../../../constants/url';
import '../SlotDataTable/index.css';

const ProgramDataTable = (props) => {
    const {
        skip,
        limit,
        sortBy,
        searchKey,
        fetchAssetsData,
        order,
    } = props.parentProps;

    const handleFetch = (skip, limit, searchText, sortBy, order) => {
        const token = localStorage.getItem('authorizationToken');

        fetchAssetsData('video', token, 'complete', null, null,
            skip,
            limit,
            searchText,
            sortBy,
            order);
    };

    const selectedIndex = [];
    props.data && props.data.length &&
    props.data.map((value, index) => {
        if (value[3] && props.selectedList.indexOf(value[3]) !== -1) {
            selectedIndex.push(index);
        }
    });

    const options = {
        pagination: false,
        selectableRows: true,
        rowsSelected: selectedIndex,
        selectableRowsOnClick: true,
        disableToolbarSelect: true,
        serverSide: true,
        print: false,
        download: false,
        filterType: 'custom',
        customFilterListOptions: {
            render: (v) => v.map((l) => l.toUpperCase()),
            update: (filterList, filterPos, index) => {
                console.log(filterList, filterPos, index);
                filterList[index].splice(filterPos, 1);
                return filterList;
            },
        },
        filterOptions: {
            logic: (duration, filters, row) => {
                // if (filters.length) return !filters.includes(location);
                // return false;
            },
            // eslint-disable-next-line react/display-name
            display: (filterList, onChange, index, column) => {
                const handleChange = (event, newValue) => {
                    filterList[index][0] = newValue;
                    onChange(filterList[index], index, column);
                };

                return (
                    <Slider
                        aria-labelledby="range-slider"
                        step={5}
                        onChange={handleChange}/>
                );
            },
        },
        onSearchChange: (searchText) => {
            handleFetch(DEFAULT_SKIP, DEFAULT_LIMIT, searchText, sortBy,
                sortBy !== '' ? order : null);
        },
        onChangePage: (currentPage) => {
            if (props.data && props.data.length === 0) {
                return;
            }

            handleFetch(limit * currentPage, limit, searchKey, sortBy, sortBy !== '' ? order : null);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.data && props.data.length === 0) {
                return;
            }

            handleFetch(skip, numberOfRows, searchKey, sortBy, sortBy !== '' ? order : null);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.data && props.data.length === 0) {
                return;
            }

            const order = direction === 'asc' ? 1 : -1;
            handleFetch(skip, limit, searchKey, changedColumn, order);
        },
        onRowsSelect: (currentRowsSelected) => {
            props.onRowClick(currentRowsSelected[0].dataIndex);
        },
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <SkeletonLoader columns={4}/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
                columnHeaderTooltip: (column) => `Sort for ${column.label}`,
            },
            toolbar: {
                search: 'Search',
                downloadCsv: 'Download CSV',
                print: 'Print',
                viewColumns: 'View Columns',
                filterTable: 'Filter Table',
            },
            filter: {
                all: 'All',
                title: 'FILTERS',
                reset: 'RESET',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
    };

    const columns = [{
        name: 'video.name',
        label: 'Asset Name',
        options: {
            filter: false,
            sort: true,
            // eslint-disable-next-line react/display-name
            customBodyRender: (value) => {
                return (
                    <div className="filename">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'file.length',
        label: 'Duration',
        options: {
            filter: true,
            sort: true,
        },
    }];
    const tableData = props.data && props.data.length
        ? props.data.map((item) =>
            [item[1].props.title,
                item[2].props.children[0]])
        : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={tableData}
                name=""
                options={options}/>
        </>
    );
};

ProgramDataTable.propTypes = {
    data: PropTypes.array.isRequired,
    inProgress: PropTypes.bool.isRequired,
    parentProps: PropTypes.object.isRequired,
    selectedList: PropTypes.func.isRequired,
    onRowClick: PropTypes.func.isRequired,
};

export default ProgramDataTable;
