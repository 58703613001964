import Axios from 'axios';
import { USER_LOGOUT_ERROR, USER_LOGOUT_IN_PROGRESS, USER_LOGOUT_SUCCESS } from '../constants/logout';
import { USER_LOGOUT as USER_LOGOUT_URL } from '../constants/url';

export const userLogoutSuccess = (result) => {
    return {
        type: USER_LOGOUT_SUCCESS,
        result,
    };
};

export const userLogoutError = (message) => {
    return {
        type: USER_LOGOUT_ERROR,
        message,
    };
};

const userLogoutInProgress = () => {
    return {
        type: USER_LOGOUT_IN_PROGRESS,
    };
};

export const logoutUser = (cb) => (dispatch) => {
    dispatch(userLogoutInProgress());
    const token = localStorage.getItem('refreshToken');

    Axios.post(USER_LOGOUT_URL, {
        refreshToken: token,
    }).then((res) => {
        if (res) {
            dispatch(userLogoutSuccess());
            const email = localStorage.getItem('usernameFF');
            const password = localStorage.getItem('passwordFF');
            localStorage.clear();
            if (email && password) {
                localStorage.setItem('usernameFF', email);
                localStorage.setItem('passwordFF', password);
            }
        }
    }).catch((error) => {
        dispatch(userLogoutError(error.response ? error.response.data.message : error.message,
        ));
    });
};
