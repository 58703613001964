import { Button } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setQuestionType } from '../../../actions/interactiveVideo';

const CancelButton = (props) => {
    const handleClick = () => {
        props.player.play();
        props.setQuestionType('');
    };

    return (
        <Button
            className="secondary_outline_button"
            onClick={handleClick}>
            Cancel
        </Button>
    );
};

CancelButton.propTypes = {
    player: PropTypes.object.isRequired,
    setQuestionType: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        player: state.interactiveVideo.player,
    };
};

const actionToProps = {
    setQuestionType,
};

export default connect(stateToProps, actionToProps)(CancelButton);
