import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAdsSlotData } from '../../../../../actions/ads';
import SelectField from '../../../../../components/SelectField';

const options = [{
    value: 0,
    name: 0,
}, {
    value: 1,
    name: 1,
}, {
    value: 2,
    name: 2,
}, {
    value: 3,
    name: 3,
}, {
    value: 4,
    name: 4,
}, {
    value: 5,
    name: 5,
}];

const FrequencySelectField = (props) => {
    const onChange = (value) => {
        const arr = props.data;
        arr[props.index].frequency = value;
        props.setAdsSlotData([...arr]);
    };

    return (
        <SelectField
            id={'ads_frequency_select_field' + props.index}
            items={options}
            name="adsFrequency"
            value={props.value}
            onChange={onChange}/>
    );
};

FrequencySelectField.propTypes = {
    data: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    setAdsSlotData: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        data: state.live.ads.adsList.list,
    };
};

const actionToProps = {
    setAdsSlotData,
};

export default connect(stateToProps, actionToProps)(FrequencySelectField);
