import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import VideoJS from 'video.js';
import 'video.js/dist/video-js.css';
import { getPreviewUrl } from '../../../../actions/asset';
import { fetchDeviceID, fetchWatchURL } from '../../../../actions/videoAssets';
import Player from '../../../../components/Player';
import './index.css';

const videoJSOptions = {
    autoplay: false,
    controls: true,
    loop: false,
    loadingSpinner: true,
    width: 500,
    height: 600,
};

class Preview extends React.Component {
    constructor (props) {
        super(props);
        this.initializePlayer = this.initializePlayer.bind(this);
        this.handleConfig = this.handleConfig.bind(this);

        this.state = {
            isPlaying: false,
        };
    }

    initializePlayer (ref) {
        this.player = VideoJS(ref, videoJSOptions);
    }

    componentDidMount () {
        this.handleConfig();
    }

    componentDidUpdate (pp, ps, ss) {
        if (pp.asset !== this.props.asset) {
            this.handleConfig();
        }
    }

    handleConfig () {
        const asset = this.props.asset;

        if (asset && asset.file && asset.file.encode && asset.file.encode.status === 'COMPLETE') {
            if (this.props.deviceID && Object.keys(this.props.deviceID).length) {
                this.props.fetchWatchURL(asset && asset._id, this.props.deviceID, (cb) => {
                    if (cb) {
                        const url = cb.assetUrl;

                        this.props.getPreviewUrl(url, (format) => {
                            const config = {
                                sources: [{
                                    type: format === 'mp4' || (asset && asset.type === 'audio')
                                        ? 'video/mp4'
                                        : 'application/x-mpegURL',
                                    src: format === 'mp4'
                                        ? url.replace('m3u8', 'mp4')
                                        : url,
                                }],
                            };

                            this.setState({ config: JSON.stringify(config) });
                        });
                    }
                });
            } else {
                this.props.fetchDeviceID((deviceID) => {
                    if (deviceID) {
                        this.props.fetchWatchURL(asset && asset._id, deviceID, (cb) => {
                            if (cb) {
                                const url = cb.assetUrl;

                                this.props.getPreviewUrl(url, (format) => {
                                    const config = {
                                        sources: [{
                                            type: format === 'mp4' || (asset && asset.type === 'audio')
                                                ? 'video/mp4'
                                                : 'application/x-mpegURL',
                                            src: format === 'mp4'
                                                ? url.replace('m3u8', 'mp4')
                                                : url,
                                        }],
                                    };

                                    this.setState({ config: JSON.stringify(config) });
                                });
                            }
                        });
                    }
                });
            }
        }
    }

    componentWillUnmount () {
        if (this.player) {
            this.player.dispose();
        }
    }

    render () {
        const { config } = this.state;

        return (
            <div className="asset_video_player">
                {config
                    ? <Player
                        initializePlayer={this.initializePlayer}
                        setup={config}/>
                    : null}
            </div>
        );
    }
}

Preview.propTypes = {
    asset: PropTypes.object.isRequired,
    deviceID: PropTypes.string.isRequired,
    deviceIDInProgress: PropTypes.bool.isRequired,
    fetchDeviceID: PropTypes.func.isRequired,
    fetchWatchURL: PropTypes.func.isRequired,
    getPreviewUrl: PropTypes.func.isRequired,
    urlLinkInProgress: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        asset: state.asset.new.asset,
        deviceID: state.asset.videoAssets.deviceID.id,
        deviceIDProgress: state.asset.videoAssets.deviceID.inProgress,
        urlLinkInProgress: state.asset.videoAssets.playerLink.inProgress,
    };
};

const actionsToProps = {
    getPreviewUrl,
    fetchWatchURL,
    fetchDeviceID,
};

export default connect(stateToProps, actionsToProps)(Preview);
