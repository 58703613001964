import { IconButton } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setInteractiveEdit } from '../../actions/interactiveVideo';

const QuestionMarks = (props) => {
    const handleClick = (value) => {
        props.player.pause();
        props.player.currentTime(value.start_at);
        const options = {};
        let option = '';
        let defaultValue = '';

        value && value.options && value.options.length > 0 &&
        value.options.map((val, index) => {
            if (value.type === 'SEEK') {
                options['option' + (index + 1)] = {
                    name: val.value,
                    value: val.goto,
                };
            } else {
                options['option' + (index + 1)] = val.value;
            }

            if (value && value.answers && value.answers.length > 0 &&
                value.answers[0] === val.value) {
                option = 'option' + (index + 1);
                defaultValue = val.value;
            }
        });

        if (!defaultValue && value.answers[0]) {
            defaultValue = value.answers[0];
        }

        props.onClick(value, options, option, defaultValue);
    };

    return (
        <div className="question_marks">
            {props.questionsList && props.questionsList.length > 0 &&
            props.questionsList.map((value, index) => {
                const type = value.question && value.question.type;
                return <IconButton
                    key={index}
                    className="question"
                    style={{ left: `${(value.start_at / props.max) * 100}%` }}
                    onClick={() => handleClick(value)}>
                    {value.type === 'SEEK'
                        ? 'S'
                        : type === 'MCQ_SA'
                            ? 'M'
                            : type === 'POLL'
                                ? 'P'
                                : type === 'TEXT_INPUT'
                                    ? 'T'
                                    : null}
                </IconButton>;
            })}
        </div>
    );
};

QuestionMarks.propTypes = {
    player: PropTypes.object.isRequired,
    questionsList: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    max: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        questionsList: state.interactiveVideo.questionsList,
        player: state.interactiveVideo.player,
    };
};

const actionToProps = {
    onClick: setInteractiveEdit,
};

export default connect(stateToProps, actionToProps)(QuestionMarks);
