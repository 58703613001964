import { Button, Paper } from '@material-ui/core';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    assetCategoryInfo,
    fetchAssetsData,
    fetchAssetsOverviewData,
    showDialogAddAssetsCategory,
} from '../../../actions/asset';
import { getAccessToken } from '../../../actions/login';
import { setSidebarSelectedTab, showDialogAddFolder, toggleSidebarDrawer } from '../../../actions/sidebar';
import toggleIcon from '../../../assets/toggle.png';
import Icon from '../../../components/Icon';
import {
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../../../constants/url';
import './index.css';
import TooltipTabs from './TooltipTabs';

class ExpansionPanel extends React.Component {
    constructor (props) {
        super(props);

        this.tabExpanded = this.tabExpanded.bind(this);
        this.handleChangeURL = this.handleChangeURL.bind(this);
        this.content = this.content.bind(this);
        this.tab = this.tab.bind(this);
    }

    componentDidMount () {
        const urlLocation = this.props.location.pathname;
        const token = localStorage.getItem('authorizationToken');

        if (urlLocation.split('/')[1] === 'add-asset' && this.props.open) {
            this.props.toggleSidebarDrawer();
        }

        if (urlLocation === '/' && token) {
            this.props.fetchAssetsOverviewData(token);
            this.props.onClick('/manage');
            this.props.history.push({ pathname: '/manage/assets' });
        } else {
            this.props.onClick('/' + urlLocation.split('/')[1]);

            const parent = urlLocation.split('/')[1];
            const type = urlLocation.split('/')[3];

            this.props.fetchAssetsOverviewData(token);

            if (token && parent === 'manage' && type) {
                if (type === 'live') {
                    return;
                }

                this.props.fetchAssetsData(type, token, null, null, null,
                    DEFAULT_SKIP,
                    DEFAULT_LIMIT,
                    DEFAULT_SEARCH_KEY,
                    DEFAULT_SORT_BY,
                    DEFAULT_ORDER,
                );
            }
        }
    }

    handleChangeURL (tab, event) {
        // if (tab.popover) {
        //     this.handleClick(event);
        //
        //     return;
        // }

        if (this.props.location.pathname === tab.url) {
            return;
        }

        const token = localStorage.getItem('authorizationToken');

        if (token && tab.key === 'overview' && this.props.overViewData && this.props.overViewData.length === 0) {
            this.props.fetchAssetsOverviewData(token);
        }

        if (token && tab.key !== 'overview' && tab.key !== 'live') {
            this.props.fetchAssetsData(tab.key, token, null, null, null,
                DEFAULT_SKIP,
                DEFAULT_LIMIT,
                DEFAULT_SEARCH_KEY,
                DEFAULT_SORT_BY,
                DEFAULT_ORDER,
            );
        }

        this.props.history.push({ pathname: tab.url });
    }

    content (tabs) {
        return (
            tabs.map((tab, index) => {
                const tabUrl = tab.url.split('/')[1];
                const pathName = this.props.location.pathname.split('/')[1];

                if (tab.popover) {
                    return (
                        <TooltipTabs
                            key={index}
                            handleClick={this.handleChangeURL}
                            list={tab.popover}
                            overViewData={this.props.overViewData}
                            pathName={this.props.location.pathname}>
                            <Button
                                key={index}
                                className={ClassNames('tab active_tab', pathName === tabUrl
                                    ? 'active_sub_tab' : '')}
                                onClick={(event) => this.handleChangeURL(tab, event)}>
                                <span className="collapse_button_left">
                                    <Icon
                                        className={tab.icon}
                                        icon={tab.icon}/>
                                    <p className="hover_side_bar label">{tab.label}</p>
                                </span>
                                {this.props.overViewData && this.props.overViewData.length > 0 &&
                                this.props.overViewData.map((value, ind) => {
                                    if (value.type === tab.key) {
                                        return (
                                            <p
                                                key={ind} className="hover_side_bar label"
                                                style={{ marginLeft: '10px' }}>
                                                {value.count}
                                            </p>
                                        );
                                    }
                                })}
                            </Button>
                        </TooltipTabs>
                    );
                } else {
                    return (
                        <div key={index}>
                            <Button
                                key={index}
                                className={ClassNames('tab active_tab', pathName === tabUrl
                                    ? 'active_sub_tab' : '')}
                                onClick={(event) => this.handleChangeURL(tab, event)}>
                                <span className="collapse_button_left">
                                    <Icon
                                        className={tab.icon}
                                        icon={tab.icon}/>
                                    <p className="hover_side_bar label">{tab.label}</p>
                                </span>
                                {this.props.overViewData && this.props.overViewData.length > 0 &&
                                this.props.overViewData.map((value, ind) => {
                                    if (value.type === tab.key) {
                                        return (
                                            <p
                                                key={ind} className="hover_side_bar label"
                                                style={{ marginLeft: '10px' }}>
                                                {value.count}
                                            </p>
                                        );
                                    }
                                })}
                                {tab.key === 'live'
                                    ? <p className="hover_side_bar label" style={{ marginLeft: '10px' }}>
                                        {this.props.streamStatus === 'running'
                                            ? 'ON AIR'
                                            : 'OFF AIR'}
                                    </p>
                                    : null}
                                {tab.count || tab.count === 0
                                    ? <p className="hover_side_bar label" style={{ marginLeft: '10px' }}>
                                        {tab.count}
                                    </p>
                                    : null}
                            </Button>
                        </div>
                    );
                }
            })
        );
    }

    tabExpanded (item, index) {
        return (
            <div key={index}>
                <Button
                    className={'collapse_tab tab ' +
                    (item.url === this.props.url ? 'active_tab ' : '') +
                    (this.props.index === index || item.url === this.props.url ? 'expand_tab ' : '')}
                    onClick={() => this.props.onClick(item.url, index)}>
                    <span className="collapse_button_left">
                        <Icon
                            className={item.icon}
                            icon={item.icon}/>
                        <p className="hover_side_bar label">{item.label}</p>
                        <span className="left_bar"/>
                    </span>
                    <span className="collapse_button_right">
                        <img
                            alt="toggle icon"
                            src={toggleIcon}/>
                    </span>
                </Button>
                <div
                    className={'expand_div ' +
                    (this.props.index === index || item.url === this.props.url ? 'expand_active' : '')}
                    style={{
                        height: (item.url === this.props.url || this.props.index === index) && ((item.content.length * 60)),
                    }}>
                    {this.content(item.content)}
                </div>
            </div>
        );
    }

    tab (item, index) {
        return (
            item.content
                ? this.tabExpanded(item, index)
                : <Button
                    key={index}
                    className={this.props.index === index ? 'item active_tab' : 'item'}
                    onClick={() => this.props.onClick(item.url, index)}>
                    {item.icon}
                    {item.label}
                    <span className="left_bar"/>
                </Button>
        );
    }

    render () {
        const customTabs = [{
            icon: 'manage',
            label: 'Manage',
            name: 'manage',
            url: '/manage',
            content: [{
                icon: 'assets',
                label: 'Assets',
                url: '/manage/assets',
                key: 'assets',
                popover: [{
                    icon: '',
                    label: 'Overview',
                    url: '/manage/assets',
                    key: 'overview',
                }, {
                    icon: '',
                    label: 'Video',
                    url: '/manage/assets/video',
                    key: 'video',
                }, {
                    icon: '',
                    label: 'Audio',
                    url: '/manage/assets/audio',
                    key: 'audio',
                }, {
                    icon: '',
                    label: 'Graphic',
                    url: '/manage/assets/graphic',
                    key: 'graphic',
                }, {
                    icon: '',
                    label: 'Text',
                    url: '/manage/assets/text',
                    key: 'text',
                }],
            }],
        }, {
            icon: 'program',
            label: 'Distribute',
            name: 'Distribute',
            url: '/distribute',
            content: [{
                icon: 'live',
                label: 'Live',
                url: '/distribute/live',
                key: 'live',
            },
                //     {
                //     icon: 'vod',
                //     label: 'VOD',
                //     url: '/distribute/vod',
                // }
            ],
        },
            // {
            //     icon: 'translation',
            //     label: 'Translation',
            //     name: 'Translation',
            //     url: '/translation',
            //     content: [{
            //         icon: 'translation',
            //         label: 'Translation',
            //         url: '/translation/freeflix',
            //     }],
            // }
        ];

        return (
            <Paper className="side_bar_tabs">
                <div className="side_bar_tab_content">
                    {customTabs.map((item, index) => <div key={item.label}>
                        {this.tab(item, index)}
                    </div>)}
                </div>
            </Paper>
        );
    }
}

ExpansionPanel.propTypes = {
    assetCategories: PropTypes.array.isRequired,
    assetCategoryInfo: PropTypes.func.isRequired,
    fetchAssetsData: PropTypes.func.isRequired,
    fetchAssetsOverviewData: PropTypes.func.isRequired,
    getAccessToken: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    overViewData: PropTypes.array.isRequired,
    showDialogAddAssetsCategory: PropTypes.func.isRequired,
    showDialogAddFolder: PropTypes.func.isRequired,
    streamStatus: PropTypes.string.isRequired,
    toggleSidebarDrawer: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    data: PropTypes.array,
    index: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        url: state.sidebar.tab.url,
        index: state.sidebar.tab.index,
        assetCategories: state.asset.assetCategoriesList.list,
        overViewData: state.asset.assetsOverViewData.list,
        streamStatus: state.live.playOut.status,
        open: state.sidebar.drawer,
    };
};

const actionToProps = {
    onClick: setSidebarSelectedTab,
    showDialogAddFolder: showDialogAddFolder,
    getAccessToken: getAccessToken,
    showDialogAddAssetsCategory: showDialogAddAssetsCategory,
    assetCategoryInfo,
    fetchAssetsData,
    fetchAssetsOverviewData,
    toggleSidebarDrawer,
};

export default withRouter(connect(stateToProps, actionToProps)(ExpansionPanel));
