import { Popover, Switch } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { changeTime, hidePopoverTimeFormat } from '../../../actions/profile';

const PopoverSelectTimeFormat = (props) => {
    return (
        <Popover
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            id="simple-popper"
            open={Boolean(props.anchorEl)}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={props.onClose}
        >
            <div className="time_format">
                24
                <Switch
                    checked={props.checked}
                    value="checked"
                    onChange={(e) => props.handleChangeTime(e)}
                />
                12
            </div>
        </Popover>
    );
};

PopoverSelectTimeFormat.propTypes = {
    checked: PropTypes.bool.isRequired,
    handleChangeTime: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        anchorEl: state.profile.timeFormatPopover.anchorEl,
        checked: state.profile.timeFormat.checked,
    };
};

const actionsToProps = {
    onClose: hidePopoverTimeFormat,
    handleChangeTime: changeTime,
};

export default connect(stateToProps, actionsToProps)(PopoverSelectTimeFormat);
