import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import ClassNames from 'classnames';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setReleaseDate } from '../../../../../actions/asset';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#696969',
                borderWidth: '1px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
            },
        },
        '& .MuiFormHelperText-root': {
            '&.Mui-error': {
                width: '100%',
                margin: '-6px 0',
            },
        },
        ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            backgroundColor: 'red !important',
        },
    },
}));

const ReleaseDate = (props) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                className={ClassNames(useStyles().root, 'time_picker text_field')}
                format="MMMM dd yyyy"
                inputVariant="outlined"
                label="Release Date"
                value={props.value}
                onChange={props.onChange}/>
        </MuiPickersUtilsProvider>
    );
};

ReleaseDate.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.movies.releaseDate,
    };
};

const actionToProps = {
    onChange: setReleaseDate,
};

export default connect(stateToProps, actionToProps)(ReleaseDate);
