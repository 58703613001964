import Axios from 'axios';
import {
    PEOPLE_ADD_ERROR,
    PEOPLE_ADD_IN_PROGRESS,
    PEOPLE_ADD_SUCCESS,
    PEOPLE_DELETE_IN_PROGRESS,
    PEOPLE_DELETE_SUCCESS,
    PEOPLE_FETCH_ERROR,
    PEOPLE_FETCH_IN_PROGRESS,
    PEOPLE_FETCH_SUCCESS,
    PROFESSIONS_LIST_SET,
} from '../constants/people';
import { fetchPeopleURL, peopleUpdate } from '../constants/url';

const fetchPeopleInProgress = () => {
    return {
        type: PEOPLE_FETCH_IN_PROGRESS,
    };
};

const fetchPeopleSuccess = (value) => {
    return {
        type: PEOPLE_FETCH_SUCCESS,
        value,
    };
};

const fetchPeopleError = (message) => {
    return {
        type: PEOPLE_FETCH_ERROR,
        message,
    };
};

export const fetchPeople = (cb) => (dispatch) => {
    dispatch(fetchPeopleInProgress());

    Axios.get(fetchPeopleURL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(fetchPeopleSuccess(res.data.result));
            if (cb) {
                cb(res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchPeopleError(
                error.response
                    ? error.response.data.message
                    : error.message,
            ));
            if (cb) {
                cb(null);
            }
        });
};

const addPeopleInProgress = () => {
    return {
        type: PEOPLE_ADD_IN_PROGRESS,
    };
};

const addPeopleSuccess = (value) => {
    return {
        type: PEOPLE_ADD_SUCCESS,
        value,
    };
};

const addPeopleError = (message) => {
    return {
        type: PEOPLE_ADD_ERROR,
        message,
    };
};

export const addPeople = (data, cb) => (dispatch) => {
    dispatch(addPeopleInProgress());

    Axios.post(fetchPeopleURL, data, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(addPeopleSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(addPeopleError(
                error.response
                    ? error.response.data.message
                    : error.message,
            ));
            cb(error);
        });
};

export const setProfession = (value) => {
    return {
        type: PROFESSIONS_LIST_SET,
        value,
    };
};

const deletePeopleInProgress = () => {
    return {
        type: PEOPLE_DELETE_IN_PROGRESS,
    };
};

const deletePeopleSuccess = (value) => {
    return {
        type: PEOPLE_DELETE_SUCCESS,
        value,
    };
};

const deletePeopleError = (message) => {
    return {
        type: PEOPLE_DELETE_SUCCESS,
        message,
    };
};

export const deletePeople = (id, cb) => (dispatch) => {
    dispatch(deletePeopleInProgress());

    const url = peopleUpdate(id);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken'),
        },
    })
        .then((res) => {
            dispatch(deletePeopleSuccess(res.data.result));
            cb(null);
        })
        .catch((error) => {
            dispatch(deletePeopleError(
                error.response
                    ? error.response.data.message
                    : error.message,
            ));
            cb(error);
        });
};
