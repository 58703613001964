import { Button } from '@material-ui/core';
import * as momentTimeZone from 'moment-timezone';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPrograms } from '../../../../../actions/program';
import { addSlot, fetchIndividualSlot } from '../../../../../actions/slot';
import CloseButton from './CloseButton';
import DateSelectField from './DateSelectField';
import ExportButton from './ExportButton';
import SaveButton from './SaveButton';
import SlotSelectField from './SlotSelectField';

class Header extends Component {
    constructor (props) {
        super(props);

        this.getSlots = this.getSlots.bind(this);
        this.getPrograms = this.getPrograms.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate (pp, ps, ss) {
        if ((pp.date !== this.props.date) && this.props.time !== 'none' &&
            this.props.open && !this.props.data.inProgress) {
            this.handleChange(this.props.time);
        }
    }

    handleChange (value) {
        const timeSplit = value.split(' - ');
        const startTime = timeSplit[0];
        const endTime = timeSplit[1];

        this.getSlots(startTime, endTime);
    }

    getSlots (start, end) {
        const timeZone = this.props.configuration['stream_timezone'];
        const dateWithOutTimeZone = this.props.date;

        this.props.fetchIndividualSlot(this.props.channel,
            this.props.stream,
            momentTimeZone.tz(dateWithOutTimeZone, timeZone).hour(start).minute(0).second(0).millisecond(0).valueOf(),
            momentTimeZone.tz(dateWithOutTimeZone, timeZone).hour(end).minute(0).second(0).millisecond(0).valueOf() + 1,
            'start_at',
            1,
            (cb) => {
                if (!cb) {
                    return;
                }

                if (cb && cb.length === 0) {
                    this.addDefaultSlots(start, end);
                } else {
                    this.getPrograms();
                }
            });
    }

    getPrograms () {
        this.props.data && this.props.data.slot &&
        this.props.fetchPrograms(this.props.data.slot.channel,
            this.props.data.slot['live_stream'],
            this.props.data.slot._id, (cb) => {
            });
    }

    addDefaultSlots (start, end) {
        const timeZone = this.props.configuration['stream_timezone'];
        const slotLength = this.props.configuration['slot_length']
            ? this.props.configuration['slot_length'] / 3600
            : 3;
        let count = 1;
        let slotsCount = 0;

        for (let a = 0; a < 24; a = a + slotLength) {
            if (a === parseInt(start) && ((a + slotLength) === parseInt(end))) {
                slotsCount = count;
            }

            count = count + 1;
        }

        const defaultSlots = [];
        const dateWithOutTimeZone = this.props.date;
        const startDate = momentTimeZone.tz(dateWithOutTimeZone, timeZone).hour(start).minute(0).second(0).millisecond(0).toISOString();
        const endDate = momentTimeZone.tz(dateWithOutTimeZone, timeZone).hour(end).minute(0).second(0).millisecond(0).toISOString();

        defaultSlots.push({
            name: 'Slot' + slotsCount,
            startAt: startDate,
            endAt: endDate,
        });

        defaultSlots.map((v, index) => {
            this.props.addSlot(this.props.channel,
                this.props.stream,
                v,
                (cb) => {
                    if (cb) {
                        return;
                    }
                    if (index === defaultSlots.length - 1) {
                        this.getSlots(start, end);
                    }
                });

            return null;
        });
    }

    render () {
        return (
            <div className="header_div">
                <div className="right_content">
                    <Button onClick={this.props.hide}>
                        <p className="title">Slot</p>
                    </Button>
                    <DateSelectField/>
                    <SlotSelectField handleChange={this.handleChange}/>
                </div>
                <div className="button_div">
                    <ExportButton/>
                    <SaveButton/>
                    <CloseButton onClose={this.props.hide}/>
                </div>
            </div>
        );
    }
}

Header.propTypes = {
    addSlot: PropTypes.func.isRequired,
    channel: PropTypes.string.isRequired,
    configuration: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    fetchIndividualSlot: PropTypes.func.isRequired,
    fetchPrograms: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    stream: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    date: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        channel: state.live.channel.current,
        configuration: state.live.stream.configuration,
        date: state.live.individualSlot.date,
        data: state.live.individualSlot.data,
        open: state.live.individualSlot.dialog,
        stream: state.live.stream.current,
        time: state.live.individualSlot.time,
    };
};

const actionToProps = {
    fetchIndividualSlot,
    fetchPrograms,
    addSlot,
};

export default connect(stateToProps, actionToProps)(Header);
