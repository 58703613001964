import React from 'react';
import './index.css';

const OverviewCards = () => {
    return (
        <div className="overview_loading_card play_out_loader">
            <div key={1} className="text"/>
            <div key={2} className="text"/>
            <div key={3} className="text"/>
            <div key={4} className="text"/>
            <div key={5} className="text"/>
            <div key={6} className="text"/>
            <div key={7} className="text"/>
            <div key={8} className="text"/>
        </div>
    );
};

export default OverviewCards;
