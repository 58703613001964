import { Tab, Tabs } from '@material-ui/core';
import React from 'react';
import GenerateText from './GenerateText';
import Graph from './Graph';
import './index.css';
import StreamKey from './StreamKey';
import { connect } from 'react-redux';
import { fetchChannelLiveStreamConfig } from '../../../../actions/channel';
import * as PropTypes from 'prop-types';

class Views extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            value: 'url_key',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        if (this.props.stream && this.props.channel) {
            this.props.fetchChannelLiveStreamConfig(this.props.channel, this.props.stream);
        }
    }

    handleChange (event, value) {
        this.setState({ value });
    }

    render () {
        const { value } = this.state;

        return (
            <div className="graph_class">
                <div>
                    <Tabs className="tabs_graph" value={value} onChange={this.handleChange}>
                        <Tab
                            className={value === 'analytics'
                                ? 'active_tab' : ''} label="Analytics" title="Analytics" value="analytics"/>
                        <Tab
                            className={value === 'text'
                                ? 'active_tab' : ''} label="Text" title="Text" value="text"/>
                        <Tab
                            className={value === 'url_key'
                                ? 'active_tab' : ''} label="Stream URL & Key" title="Stream URL & Key" value="url_key"/>
                    </Tabs>
                </div>
                <div className="graph_section">
                    {value === 'analytics' && <Graph/>}
                    {value === 'text' && <GenerateText/>}
                    {value === 'url_key' && <StreamKey/>}
                </div>
            </div>
        );
    }
}

Views.propTypes = {
    channel: PropTypes.string.isRequired,
    fetchChannelLiveStreamConfig: PropTypes.func.isRequired,
    stream: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        channel: state.live.channel.current,
        stream: state.live.stream.current,
    };
};

const actionsToProps = {
    fetchChannelLiveStreamConfig,
};
export default connect(stateToProps, actionsToProps)(Views);
