import { combineReducers } from 'redux';
import {
    ALBUM_FETCH_ERROR,
    ALBUM_FETCH_IN_PROGRESS,
    ALBUM_FETCH_SUCCESS,
    NEW_ALBUM_NAME_SET,
} from '../../constants/album';
import {
    ALBUM_SET,
    DIALOG_ADD_ASSET_HIDE,
    EDITABLE_ASSET_DATA,
    EDITABLE_ASSET_VIDEO_DATA,
    IS_RC_CODE_SET,
    LYRICISTS_SET,
    MOOD_SET,
    MUSIC_COMPOSERS_SET,
    MUSIC_DIRECTORS_SET,
    OWNERSHIP_STATUS_SET,
    RECORD_LABEL_SET,
    SINGERS_SET,
    TRACK_NAME_SET,
} from '../../constants/asset';

const trackName = (state = '', action) => {
    switch (action.type) {
    case TRACK_NAME_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.name
            ? action.value.video.name : '';
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const ownerShipStatus = (state = '', action) => {
    switch (action.type) {
    case OWNERSHIP_STATUS_SET:
        return action.value;
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const album = (state = {
    list: [],
    value: '',
    inProgress: false,
    new: '',
}, action) => {
    switch (action.type) {
    case ALBUM_SET:
        return {
            ...state,
            value: action.value,
        };
    case DIALOG_ADD_ASSET_HIDE:
        return {
            ...state,
            value: '',
        };
    case ALBUM_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ALBUM_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.value,
        };
    case ALBUM_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case NEW_ALBUM_NAME_SET:
        return {
            ...state,
            new: action.value,
        };
    case EDITABLE_ASSET_DATA:
        return {
            ...state,
            value: action.value.video && action.value.video.album ? action.value.video.album : '',
        };
    case EDITABLE_ASSET_VIDEO_DATA:
        return {
            ...state,
            value: action.value && action.value.video &&
            action.value.video.album
                ? action.value.video.album : '',
        };
    default:
        return state;
    }
};

const ISRCCode = (state = '', action) => {
    switch (action.type) {
    case IS_RC_CODE_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video['isrc_code']
            ? action.value.video['isrc_code'] : '';
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const recordLabel = (state = '', action) => {
    switch (action.type) {
    case RECORD_LABEL_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video['record_label']
            ? action.value.video['record_label'] : '';
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

const musicDirectors = (state = [], action) => {
    switch (action.type) {
    case MUSIC_DIRECTORS_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video['music_directors']
            ? action.value.video['music_directors'] : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const musicComposers = (state = [], action) => {
    switch (action.type) {
    case MUSIC_COMPOSERS_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video['music_composers']
            ? action.value.video['music_composers'] : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const singers = (state = [], action) => {
    switch (action.type) {
    case SINGERS_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.singers
            ? action.value.video.singers : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const lyricists = (state = [], action) => {
    switch (action.type) {
    case LYRICISTS_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.lyricists
            ? action.value.video.lyricists : [];
    case DIALOG_ADD_ASSET_HIDE:
        return [];
    default:
        return state;
    }
};

const mood = (state = '', action) => {
    switch (action.type) {
    case MOOD_SET:
        return action.value;
    case EDITABLE_ASSET_VIDEO_DATA:
        return action.value && action.value.video &&
        action.value.video.mood
            ? action.value.video.mood : '';
    case DIALOG_ADD_ASSET_HIDE:
        return '';
    default:
        return state;
    }
};

export default combineReducers({
    trackName,
    ownerShipStatus,
    album,
    ISRCCode,
    recordLabel,
    musicDirectors,
    musicComposers,
    singers,
    lyricists,
    mood,
});
