import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAdsSlotData } from '../../../../../actions/ads';
import SelectField from '../../../../../components/SelectField';

const options = [{
    value: 'none',
    name: 'None',
}, {
    value: 'Every 5min',
    name: 'Every 5min',
}, {
    value: 'Every 10min',
    name: 'Every 10min',
}, {
    value: 'Every 15min',
    name: 'Every 15min',
}, {
    value: 'Every 30min',
    name: 'Every 30min',
}, {
    value: 'Every 45min',
    name: 'Every 45min',
}, {
    value: 'Every 60min',
    name: 'Every 60min',
}];

const RepeatSelectField = (props) => {
    const onChange = (value) => {
        const arr = props.data;
        arr[props.index].interval = value;
        props.setAdsSlotData([...arr]);
    };

    return (
        <SelectField
            id={'interval_select_field' + props.index}
            items={options}
            name="interval"
            value={props.value}
            onChange={onChange}/>
    );
};

RepeatSelectField.propTypes = {
    data: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    setAdsSlotData: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        data: state.live.ads.adsList.list,
    };
};

const actionToProps = {
    setAdsSlotData,
};

export default connect(stateToProps, actionToProps)(RepeatSelectField);
