import {
    CHANNELS_AUTHENTICATION_ERROR,
    CHANNELS_AUTHENTICATION_IN_PROGRESS,
    CHANNELS_AUTHENTICATION_SUCCESS,
    CHANNELS_FETCH_ERROR,
    CHANNELS_FETCH_IN_PROGRESS,
    CHANNELS_FETCH_SUCCESS,
    CURRENT_CHANNEL_SET,
} from '../../constants/channel';

export const channel = (state = {
    list: [],
    current: '',
    imageUrl: '',
    authenticatedChannels: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case CHANNELS_FETCH_IN_PROGRESS:
    case CHANNELS_AUTHENTICATION_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CHANNELS_FETCH_SUCCESS: {
        const selected = action.channels.filter((v) => v._id === action.current);

        return {
            ...state,
            list: action.channels,
            current: action.current,
            imageUrl: selected.length
                ? selected[0]['image_url']
                : '',
            inProgress: false,
        };
    }
    case CHANNELS_FETCH_ERROR:
    case CHANNELS_AUTHENTICATION_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CURRENT_CHANNEL_SET: {
        const selected = state.list.filter((v) => v._id === action.value);

        return {
            ...state,
            current: action.value,
            imageUrl: selected.length
                ? selected[0]['image_url']
                : '',
        };
    }
    case CHANNELS_AUTHENTICATION_SUCCESS: {
        return {
            ...state,
            authenticatedChannels: action.channels,
        };
    }
    default:
        return state;
    }
};
