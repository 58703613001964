import { Button } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { addBugs, fetchBugs, setTabValue } from '../../../../../actions/bugs';
import { fetchPrograms } from '../../../../../actions/program';

const AddButton = (props) => {
    const getPrograms = () => {
        props.slots &&
        Object.keys(props.slots).length &&
        Object.values(props.slots).map((slot, index) => (
            props.fetchPrograms(slot.channel,
                slot['live_stream'],
                slot._id, () => {
                })
        ));
    };

    const handleClick = () => {
        const token = localStorage.getItem('authorizationToken');

        props.list.map((value) => {
            const data = {
                startAt: props.time,
                endAt: moment(props.time).add(value.file.length, 'seconds').format(),
                asset: value._id,
                repeat: 0,
                frequency: 0,
                position: 'none',
            };

            props.addBugs(props.slot.channel, props.slot.live_stream, props.slot._id, data, token, (error) => {
                if (!error) {
                    props.fetchBugs(props.slot.channel, props.slot.live_stream, props.slot._id, token);
                    getPrograms();
                }
            });

            return null;
        });

        if (props.tabValue !== 0) {
            props.setTabValue(0);
        }
    };

    const disable = props.list.length === 0;

    return (
        <Button
            className="add_stream"
            disabled={disable}
            onClick={handleClick}>
            Add
        </Button>
    );
};

AddButton.propTypes = {
    addBugs: PropTypes.func.isRequired,
    fetchBugs: PropTypes.func.isRequired,
    fetchPrograms: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    setTabValue: PropTypes.func.isRequired,
    slot: PropTypes.object.isRequired,
    slots: PropTypes.array.isRequired,
    tabValue: PropTypes.number.isRequired,
    time: PropTypes.any.isRequired,
};

const stateToProps = (state) => {
    return {
        list: state.live.bugs.addBugs.list,
        tabValue: state.live.bugs.tabValue,
        time: state.live.bugs.addBugs.time,
        slot: state.live.bugs.slot,
        slots: state.live.slot.list,
    };
};

const actionToProps = {
    addBugs,
    fetchBugs,
    fetchPrograms,
    setTabValue,
};

export default connect(stateToProps, actionToProps)(AddButton);
