import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setDialogues, showPeoplesDialog } from '../../../../actions/asset';
import AutoCompleteTextField from '../../../../components/AutoCompleteTextField/WithAddButton';

const DialoguesSelectField = (props) => {
    const showDialog = (value) => {
        props.dialog(value, 'dialogues', [...props.list], props.onChange);
    };

    return (
        <AutoCompleteTextField
            label="Dialogues"
            options={props.options}
            showDialog={showDialog}
            value={props.list}
            onChange={props.onChange}/>
    );
};

DialoguesSelectField.propTypes = {
    dialog: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        list: state.asset.new.dialogues,
        options: state.asset.new.peopleList.list,
    };
};

const actionToProps = {
    onChange: setDialogues,
    dialog: showPeoplesDialog,
};

export default connect(stateToProps, actionToProps)(DialoguesSelectField);
