import { Button } from '@material-ui/core';
import React from 'react';
import NetworkSelectField from './NetworkSelectField';

const NetworkCard = () => {
    return (
        <div className="card">
            <div className="info">
                <p className="heading">Network</p>
                <NetworkSelectField/>
            </div>
            <Button className="action_button2">
                Change
            </Button>
        </div>
    );
};

export default NetworkCard;
