import * as PropTypes from 'prop-types';
import React from 'react';
import AutoCompleteTextField from '../../../../components/AutoCompleteTextField';
import { connect } from 'react-redux';
import { setAssetTags, fetchTagsList } from '../../../../actions/asset';

class TagsTextField extends React.Component {
    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        if (this.props.defaultValue && this.props.defaultValue.length > 0) {
            this.props.onChange([...this.props.defaultValue]);
        }

        const token = localStorage.getItem('authorizationToken');

        if (!this.props.tagsListInProgress && token) {
            this.props.fetchTagsList(token);
        }
    }

    handleChange (value) {
        this.props.onChange(value);
    }

    render () {
        return (
            <AutoCompleteTextField
                freeSolo
                className="tags_select_field"
                label={this.props.noLabel ? null : 'Tags'}
                options={this.props.tagsList}
                value={this.props.list}
                onChange={this.handleChange}/>
        );
    }
}

TagsTextField.propTypes = {
    fetchTagsList: PropTypes.func.isRequired,
    tagsListInProgress: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.array,
    list: PropTypes.array,
    noLabel: PropTypes.bool,
    tagsList: PropTypes.array,
};

const stateToProps = (state) => {
    return {
        list: state.asset.new.tags,
        tagsList: state.asset.tagsList.list,
        tagsListInProgress: state.asset.tagsList.inProgress,
    };
};

const actionToProps = {
    onChange: setAssetTags,
    fetchTagsList,
};

export default connect(stateToProps, actionToProps)(TagsTextField);
