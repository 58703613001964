import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';
import { connect } from 'react-redux';
import { setQuestionType } from '../../../actions/interactiveVideo';
import Icon from '../../../components/Icon';

const QuestionTypes = (props) => {
    const handleClick = (value) => {
        props.setQuestionType(value);
    };

    return (
        <div className="question_types">
            <p className="heading">Question Types</p>
            <div className="types">
                <Button onClick={() => handleClick('MCQ_SA')}>
                    <Icon className="radio-button" icon="radio-button"/>
                    MCQ Type
                </Button>
                <Button onClick={() => handleClick('POLL')}>
                    <Icon className="poll" icon="poll"/>
                    Poll
                </Button>
                <Button onClick={() => handleClick('TEXT_INPUT')}>
                    <Icon className="text-box" icon="text-box"/>
                    Text
                </Button>
                <Button onClick={() => handleClick('SEEK')}>
                    <Icon className="seek" icon="seek"/>
                    Seek
                </Button>
            </div>
        </div>
    );
};

QuestionTypes.propTypes = {
    setQuestionType: PropTypes.func.isRequired,
};

const actionToProps = {
    setQuestionType,
};

export default connect(null, actionToProps)(QuestionTypes);
