import { ButtonBase } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
    chapterDetailsTab,
    hideEditChapters,
    setChapterRangeValues,
    setMaxValue,
    setMinValue,
} from '../../../actions/chapterMarking';
import './index.css';

const ChapterDetailsCards = (props) => {
    const handleClick = (value, chapter) => {
        if (props.edit) {
            props.hideEditChapters();
        }

        if (props.value === value) {
            return;
        }

        props.onClick(value);
        props.setChapterRangeValues([chapter.start_at, chapter.end_at]);
        props.setMinValue(chapter.start_at);
        props.setMaxValue(chapter.end_at);
    };

    return (
        <div className="chapter_details_cards">
            <p className="main_heading">Chapter Details</p>
            <div className="cards scroll">
                {
                    props.chapters.map((value, index) => {
                        return (
                            <ButtonBase
                                key={index}
                                className={'card ' + (props.value === index ? 'active' : '')}
                                onClick={() => handleClick(index, value)}>
                                <div className="row">
                                    <p className="heading_text">Category <b>{
                                        value.categories
                                            ? value.categories.map((item) => item.name).join(', ')
                                            : ''
                                    }</b></p>
                                    <p className="card_number">{index + 1}</p>
                                </div>
                                <div className="row">
                                    <div>
                                        <p className="heading_text">Context</p>
                                        <p className="content_text">{value.context}</p>
                                    </div>
                                    <div className="row_content">
                                        <div>
                                            <p className="heading_text">People</p>
                                            <p className="content_text">{
                                                value.people
                                                    ? value.people.map((item) => item.name).join(', ')
                                                    : ''}</p>
                                        </div>
                                        <div>
                                            <p className="heading_text">Objects</p>
                                            <p className="content_text">{value.objects
                                                ? value.objects.join(', ')
                                                : ''}</p>
                                        </div>
                                        <div>
                                            <p className="heading_text">Scenes</p>
                                            <p className="content_text">{value.scenes.length}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div>
                                        <p className="heading_text">Start</p>
                                        <p className="content_text">{
                                            moment.utc(moment.duration(value.start_at, 'seconds')
                                                .asMilliseconds()).format('HH:mm:ss')}</p>
                                    </div>
                                    <div>
                                        <p className="heading_text">End</p>
                                        <p className="content_text">{
                                            moment.utc(moment.duration(value.end_at, 'seconds')
                                                .asMilliseconds()).format('HH:mm:ss')}</p>
                                    </div>
                                    <div>
                                        <p className="heading_text">Duration</p>
                                        <p className="content_text">{
                                            moment.utc(moment.duration(value.duration, 'seconds')
                                                .asMilliseconds()).format('HH:mm:ss')}</p>
                                    </div>
                                </div>
                            </ButtonBase>
                        );
                    })}
            </div>
        </div>
    );
};

ChapterDetailsCards.propTypes = {
    chapters: PropTypes.arrayOf(
        PropTypes.shape(
            {
                start_at: PropTypes.number.isRequired,
                end_at: PropTypes.number.isRequired,
                duration: PropTypes.number.isRequired,
                scenes: PropTypes.array.isRequired,
                categories: PropTypes.arrayOf(
                    PropTypes.shape({
                        _id: PropTypes.string.isRequired,
                        name: PropTypes.string.isRequired,
                    }),
                ),
                people: PropTypes.arrayOf(
                    PropTypes.shape({
                        _id: PropTypes.string.isRequired,
                        name: PropTypes.string.isRequired,
                    }),
                ),
                objects: PropTypes.array,
            }),
    ).isRequired,
    chaptersInProgress: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
    hideEditChapters: PropTypes.func.isRequired,
    setChapterRangeValues: PropTypes.func.isRequired,
    setMaxValue: PropTypes.func.isRequired,
    setMinValue: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        edit: state.chapterMarking.new.edit,
        value: state.chapterMarking.chapterDetails.tab,
        chapters: Object.values(state.chapterMarking.chapter.list),
        chaptersInProgress: state.chapterMarking.chapter.inProgress,
    };
};

const actionToProps = {
    onClick: chapterDetailsTab,
    setMaxValue,
    setMinValue,
    setChapterRangeValues,
    hideEditChapters,
};

export default connect(stateToProps, actionToProps)(ChapterDetailsCards);
