import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setUserLoginEmail } from '../../actions/login';
import TextField from '../../components/TextField';
import { isValidEmail } from '../../utils/validation';

class EmailTextField extends React.Component {
    constructor (props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount () {
        const username = localStorage.getItem('usernameFF');

        if (username) {
            const valid = isValidEmail(username);
            this.props.onChange(username, valid);
        }
    }

    onChange (value) {
        const valid = isValidEmail(value);

        this.props.onChange(value, valid);
    }

    render () {
        return (
            <TextField
                className={this.props.valid ? '' : 'error_text_field'}
                id="email_text_field"
                name="email"
                placeholder="Email"
                type="email"
                valid={this.props.valid}
                value={this.props.value}
                variant="filled"
                onChange={this.onChange}/>
        );
    }
}

EmailTextField.propTypes = {
    valid: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.login.email.value,
        valid: state.login.email.valid,
    };
};

const actionsToProps = {
    onChange: setUserLoginEmail,
};

export default connect(stateToProps, actionsToProps)(EmailTextField);
