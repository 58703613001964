import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CopyButton from '../../../../../components/CopyButton';
import TextField from '../../../../../components/TextField';

const KeyTextField = (props) => {
    const [show, setShow] = useState(false);
    const [time, setTime] = useState(10);
    const [interval, setIntervalTime] = useState(null);

    useEffect(() => {
        if (show && time === 10) {
            let timer = 10;
            const interval = setInterval(() => {
                timer = timer - 1;
                handleTime(timer);
            }, 1000);
            return () => {
                setIntervalTime(interval);
            };
        }

        if (show && time === 0) {
            clearInterval(interval);
            setIntervalTime(null);
            setShow(false);
            setTime(10);
        }
    });

    const handleTime = (time) => {
        setTime(time);
    };

    const handleShow = () => {
        setShow(!show);

        if (interval) {
            setTime(10);
            clearInterval(interval);
        }
    };

    return (
        <div className="key horizontal_row">
            <TextField
                disable
                handleClickShowPassword={handleShow}
                id="key_text_field"
                name="streamKey"
                showPassword={show}
                type={show ? 'text' : 'password'}
                value={props.value}
                variant="filled"
                onChange={() => {
                }}/>
            {show
                ? <p className="counter">{time}</p>
                : null}
            <CopyButton data={props.value}/>
        </div>
    );
};

KeyTextField.propTypes = {
    value: PropTypes.string.isRequired,
};

export default KeyTextField;
