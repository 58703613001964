import { Button } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setUploadCancel } from '../../../../actions/videoAssets';
import LinearProgressWithLabel from '../../../../components/LinearProgressWithLabel';

const UploadInProgress = (props) => {
    const handleCancelUpload = () => {
        const uploadList = [...props.uploadList];
        uploadList.pop();
        const object = { ...props.uploadDetails };
        object.cancel = true;
        uploadList.push({ ...object });

        props.setUploadCancel([...uploadList]);
        props.uploadFileCancel();
    };

    return (
        <div className="in_progress">
            {props.uploadDetails && props.uploadDetails.file_name
                ? <p className="file_name" title={props.uploadDetails.file_name}>
                    {props.uploadDetails.file_name}
                </p>
                : props.uploadDetails && props.uploadDetails.name
                    ? <p className="file_name" title={props.uploadDetails.name}>
                        {props.uploadDetails.name}
                    </p>
                    : null}
            <LinearProgressWithLabel
                value={((props.uploadProgress.loaded / props.uploadProgress.total) * 100).toFixed(0)}/>
            <p className="text1">
                {Math.round((props.uploadProgress.loaded / props.uploadProgress.total) * 100)}% Uploaded....
            </p>
            <Button
                className="cancel_button"
                variant="outlined"
                onClick={handleCancelUpload}>
                Cancel
            </Button>
        </div>
    );
};

UploadInProgress.propTypes = {
    setUploadCancel: PropTypes.func.isRequired,
    uploadDetails: PropTypes.object.isRequired,
    uploadFileCancel: PropTypes.func.isRequired,
    uploadInProgress: PropTypes.bool.isRequired,
    uploadList: PropTypes.array.isRequired,
    uploadProgress: PropTypes.shape({
        total: PropTypes.number.isRequired,
        loaded: PropTypes.number.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        uploadDetails: state.asset.videoAssets.uploadFile.details,
        uploadFileCancel: state.asset.videoAssets.uploadFile.cancel,
        uploadInProgress: state.asset.upload.inProgress,
        uploadList: state.asset.videoAssets.uploadFile.list,
        uploadProgress: state.asset.upload.progress,
    };
};

const actionToProps = {
    setUploadCancel,
};

export default connect(stateToProps, actionToProps)(UploadInProgress);
