import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { addAccount, hideConnectDialog, showAccountSuccessDialog, setAccountAddress } from '../../../actions/account';
import keplr from '../../../assets/logos/keplr.png';
import { showMessage } from '../../../actions/snackbar';
import { initializeFlixStudio } from '../../../helper';

const ConnectDialog = (props) => {
    const [inProgress, setInProgress] = useState(false);
    const handleConnect = () => {
        setInProgress(true);
        if (props.address) {
            handleAddAccount(props.address);
        } else {
            initializeFlixStudio((error, address) => {
                if (error) {
                    props.showMessage(error);
                    setInProgress(false);
                    return;
                }

                props.setAccountAddress(address);
                handleAddAccount(address);
            });
        }
    };

    const handleAddAccount = (address) => {
        props.addAccount({ address }, (error) => {
            setInProgress(false);
            if (error) return;

            props.showAccountSuccessDialog();
            props.hideConnectDialog();
        });
    };
    return (
        <Dialog
            aria-describedby="connect-account-description"
            aria-labelledby="connect-account-title"
            className="dialog connect_account_dialog"
            open={props.open}
            onClose={props.hideConnectDialog}>
            <DialogContent className="content">
                <img alt="keplr" src={keplr}/>
                <p className="title">Keplr would like to connect to your account</p>
                <div className="action_buttons">
                    <Button
                        className="cancel_button"
                        disabled={inProgress}
                        onClick={props.hideConnectDialog}>
                        Cancel
                    </Button>
                    <Button
                        className="connect_button"
                        disabled={inProgress}
                        onClick={handleConnect}>
                        Connect
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

ConnectDialog.propTypes = {
    addAccount: PropTypes.func.isRequired,
    hideConnectDialog: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setAccountAddress: PropTypes.func.isRequired,
    showAccountSuccessDialog: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    address: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        open: state.account.connectDialog,
        address: state.account.address,
    };
};

const actionToProps = {
    hideConnectDialog,
    showAccountSuccessDialog,
    addAccount,
    showMessage,
    setAccountAddress,
};

export default connect(stateToProps, actionToProps)(ConnectDialog);
