export const SLOTS_FETCH_IN_PROGRESS = 'SLOTS_FETCH_IN_PROGRESS';
export const SLOTS_FETCH_SUCCESS = 'SLOTS_FETCH_SUCCESS';
export const SLOTS_FETCH_ERROR = 'SLOTS_FETCH_ERROR';
export const SLOT_ADD_IN_PROGRESS = 'SLOT_ADD_IN_PROGRESS';
export const SLOT_ADD_SUCCESS = 'SLOT_ADD_SUCCESS';
export const SLOT_ADD_ERROR = 'SLOT_ADD_ERROR';
export const SLOT_UPDATE_IN_PROGRESS = 'SLOT_UPDATE_IN_PROGRESS';
export const SLOT_UPDATE_SUCCESS = 'SLOT_UPDATE_SUCCESS';
export const SLOT_UPDATE_ERROR = 'SLOT_UPDATE_ERROR';
export const SLOT_DELETE_IN_PROGRESS = 'SLOT_DELETE_IN_PROGRESS';
export const SLOT_DELETE_SUCCESS = 'SLOT_DELETE_SUCCESS';
export const SLOT_DELETE_ERROR = 'SLOT_DELETE_ERROR';
export const SLOT_SET = 'SLOT_SET';
export const SLOT_PUSH_IN_PROGRESS = 'SLOT_PUSH_IN_PROGRESS';
export const SLOT_PUSH_SUCCESS = 'SLOT_PUSH_SUCCESS';
export const SLOT_PUSH_ERROR = 'SLOT_PUSH_ERROR';
export const SLOT_CATEGORY_SET = 'SLOT_CATEGORY_SET';
export const SLOT_EXCLUDES_SET = 'SLOT_EXCLUDES_SET';
export const SLOT_INCLUDES_SET = 'SLOT_INCLUDES_SET';
export const SLOT_CONTENT_TYPE_SET = 'SLOT_CONTENT_TYPE_SET';
export const SLOT_POP_UP_SHOW_SET = 'SLOT_POP_UP_SHOW_SET';
export const DYNAMIC_INPUT_VALUES_CLEAR = 'DYNAMIC_INPUT_VALUES_CLEAR';
export const SLOT_DURATION_SET = 'SLOT_DURATION_SET';

export const STREAM_START_IN_PROGRESS = 'STREAM_START_IN_PROGRESS';
export const STREAM_START_SUCCESS = 'STREAM_START_SUCCESS';
export const STREAM_START_ERROR = 'STREAM_START_ERROR';

export const STREAM_STOP_IN_PROGRESS = 'STREAM_STOP_IN_PROGRESS';
export const STREAM_STOP_SUCCESS = 'STREAM_STOP_SUCCESS';
export const STREAM_STOP_ERROR = 'STREAM_STOP_ERROR';

export const STREAM_CONFIG_UPDATE_IN_PROGRESS = 'STREAM_CONFIG_UPDATE_IN_PROGRESS';
export const STREAM_CONFIG_UPDATE_SUCCESS = 'STREAM_CONFIG_UPDATE_SUCCESS';
export const STREAM_CONFIG_UPDATE_ERROR = 'STREAM_CONFIG_UPDATE_ERROR';

export const TEMPLATE_NAME_SET = 'TEMPLATE_NAME_SET';

export const STREAM_PLAYER_CONFIG_SET = 'STREAM_PLAYER_CONFIG_SET';

export const TIMER_SET = 'TIMER_SET';

export const SLOT_SETTINGS_DIALOG_SHOW = 'SLOT_SETTINGS_DIALOG_SHOW';
export const SLOT_SETTINGS_DIALOG_HIDE = 'SLOT_SETTINGS_DIALOG_HIDE';

export const SLOT_SETTINGS_TAB_SET = 'SLOT_SETTINGS_TAB_SET';

export const SLOT_NAME_SET = 'SLOT_NAME_SET';

export const INDIVIDUAL_SLOT_DIALOG_SHOW = 'INDIVIDUAL_SLOT_DIALOG_SHOW';
export const INDIVIDUAL_SLOT_DIALOG_HIDE = 'INDIVIDUAL_SLOT_DIALOG_HIDE';

export const INDIVIDUAL_SLOT_DATE_SET = 'INDIVIDUAL_SLOT_DATE_SET';
export const INDIVIDUAL_SLOT_TIME_SET = 'INDIVIDUAL_SLOT_TIME_SET';

export const SEARCH_ASSETS_SET = 'SEARCH_ASSETS_SET';

export const INDIVIDUAL_SLOT_FETCH_IN_PROGRESS = 'INDIVIDUAL_SLOT_FETCH_IN_PROGRESS';
export const INDIVIDUAL_SLOT_FETCH_SUCCESS = 'INDIVIDUAL_SLOT_FETCH_SUCCESS';
export const INDIVIDUAL_SLOT_FETCH_ERROR = 'INDIVIDUAL_SLOT_FETCH_ERROR';

export const INDIVIDUAL_SLOT_PLAYER_SET = 'INDIVIDUAL_SLOT_PLAYER_SET';
