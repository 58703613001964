import * as PropTypes from 'prop-types';
import React from 'react';
import './index.css';
import { connect } from 'react-redux';
import SourceTypeSelectField from '../../../asset/DialogAddAsset/SourceTypeSelectField';
import UrlTextField from '../../../asset/DialogAddAsset/UrlTextField';
import CategorySelectField from '../MetadataForDistribution/CategorySelectField';
import FileSelection from './FileSelection';
import TitleTextField from './TitleTextField';

const FileUpload = (props) => {
    return (
        <div className="source_file">
            <p className="heading_text">Select Source File</p>
            <div className="row">
                <TitleTextField/>
            </div>
            <CategorySelectField
                label="Select Category"
                name="select-category"
                showAddButton={true}/>
            <SourceTypeSelectField/>
            {props.sourceTypeValue
                ? props.sourceTypeValue === 'studio'
                    ? <FileSelection
                        disable={props.disable}
                        onClick={props.onClick}/>
                    : <UrlTextField label={props.sourceTypeValue}/>
                : null}
        </div>
    );
};

FileUpload.propTypes = {
    disable: PropTypes.bool.isRequired,
    sourceTypeValue: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        sourceTypeValue: state.asset.new.sourceTypeValue,
    };
};

export default connect(stateToProps)(FileUpload);
