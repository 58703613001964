import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAddAssetEncodedState } from '../../../actions/asset';
import TextField from '../../../components/TextField';

class EncodedStateTextField extends React.Component {
    constructor (props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange (value) {
        this.props.onChange(value);
    }

    render () {
        return (
            <TextField
                id="encoded_state_text_field"
                label="Encoded State"
                name="encodedState"
                type="text"
                value={this.props.value}
                onChange={this.onChange}/>
        );
    }
}

EncodedStateTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.encodedState,
    };
};

const actionsToProps = {
    onChange: setAddAssetEncodedState,
};

export default connect(stateToProps, actionsToProps)(EncodedStateTextField);
