import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showPeoplesDialog } from '../../../actions/asset';
import { setPeople } from '../../../actions/chapterMarking';
import { fetchPeople } from '../../../actions/people';
import AutoCompleteTextField from '../../../components/AutoCompleteTextField/WithAddButton';

class PeopleSelectField extends Component {
    componentDidMount () {
        if (this.props.defaultValue && this.props.defaultValue.length > 0) {
            this.props.onChange([...this.props.defaultValue]);
        }

        if (this.props.peopleList.length && this.props.peopleListInProgress) {
            return;
        }

        this.props.fetchPeople();
    }

    render () {
        return (
            <AutoCompleteTextField
                label={this.props.label ? this.props.label : ''}
                options={this.props.options}
                showDialog={this.props.dialog}
                value={this.props.list}
                onChange={this.props.onChange}/>
        );
    }
}

PeopleSelectField.propTypes = {
    dialog: PropTypes.func.isRequired,
    fetchPeople: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    peopleList: PropTypes.array.isRequired,
    peopleListInProgress: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.array,
    label: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        list: state.chapterMarking.new.people,
        options: state.asset.new.peopleList.list,
        peopleList: state.asset.new.peopleList.list,
        peopleListInProgress: state.asset.new.peopleList.inProgress,
    };
};

const actionToProps = {
    onChange: setPeople,
    dialog: showPeoplesDialog,
    fetchPeople,
};

export default connect(stateToProps, actionToProps)(PeopleSelectField);
