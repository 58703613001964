import Axios from 'axios';
import {
    CHAPTER_CATEGORY_SET,
    CHAPTER_DETAILS_TAB_SET,
    CHAPTER_GENRE_SET,
    CHAPTER_LOCATION_SET,
    CHAPTER_MAX_VALUE_SET,
    CHAPTER_MIN_VALUE_SET,
    CHAPTER_NAME_SET,
    CHAPTER_OBJECTS_SET,
    CHAPTER_PEOPLE_SET,
    CHAPTER_RANGE_VALUES_SET,
    DESCRIPTION_SET,
    EDIT_CHAPTER_DETAILS_HIDE,
    EDIT_CHAPTER_DETAILS_SHOW,
    SEARCH_CHAPTER_SET,
    SIDE_BAR_CONTENT1_SET,
    SIDE_BAR_CONTENT2_SET,
    ASSET_CHAPTER_ADD_ERROR,
    ASSET_CHAPTER_ADD_IN_PROGRESS,
    ASSET_CHAPTER_ADD_SUCCESS,
    ASSET_CHAPTERS_FETCH_ERROR,
    ASSET_CHAPTERS_FETCH_IN_PROGRESS,
    ASSET_CHAPTERS_FETCH_SUCCESS,
    ASSET_CHAPTER_UPDATE_ERROR,
    ASSET_CHAPTER_UPDATE_IN_PROGRESS,
    ASSET_CHAPTER_UPDATE_SUCCESS,
    ASSET_CHAPTER_CATEGORIES_FETCH_ERROR,
    ASSET_CHAPTER_CATEGORIES_FETCH_IN_PROGRESS,
    ASSET_CHAPTER_CATEGORIES_FETCH_SUCCESS,
    ASSET_CHAPTER_PEOPLE_FETCH_ERROR,
    ASSET_CHAPTER_PEOPLE_FETCH_IN_PROGRESS,
    ASSET_CHAPTER_PEOPLE_FETCH_SUCCESS,
    ASSET_CHAPTER_OBJECTS_FETCH_ERROR,
    ASSET_CHAPTER_OBJECTS_FETCH_IN_PROGRESS,
    ASSET_CHAPTER_OBJECTS_FETCH_SUCCESS, SEARCH_ASSETS_SET, SLIDER_TYPE_SET,
} from '../constants/chapterMarking';

import {
    urlAddAssetChapter, urlUpdateAssetChapter,
    urlFetchAssetChapterCategories, urlFetchAssetChapterPeople, urlFetchAssetChapterObjects,
} from '../constants/url';

export const setTabContent1 = (value, name) => {
    return {
        type: SIDE_BAR_CONTENT1_SET,
        value,
        name,
    };
};

export const setTabContent2 = (value) => {
    return {
        type: SIDE_BAR_CONTENT2_SET,
        value,
    };
};

export const setSearchChapter = (value) => {
    return {
        type: SEARCH_CHAPTER_SET,
        value,
    };
};

export const setSearchAssets = (value) => {
    return {
        type: SEARCH_ASSETS_SET,
        value,
    };
};

export const chapterDetailsTab = (value) => {
    return {
        type: CHAPTER_DETAILS_TAB_SET,
        value,
    };
};

export const setChapterRangeValues = (range) => {
    return {
        type: CHAPTER_RANGE_VALUES_SET,
        range,
    };
};

export const setDescription = (value) => {
    return {
        type: DESCRIPTION_SET,
        value,
    };
};

export const setGenre = (value) => {
    return {
        type: CHAPTER_GENRE_SET,
        value,
    };
};

export const setCategory = (value) => {
    return {
        type: CHAPTER_CATEGORY_SET,
        value,
    };
};

export const setPeople = (value) => {
    return {
        type: CHAPTER_PEOPLE_SET,
        value,
    };
};

export const setObjects = (value) => {
    return {
        type: CHAPTER_OBJECTS_SET,
        value,
    };
};

export const setLocation = (value) => {
    return {
        type: CHAPTER_LOCATION_SET,
        value,
    };
};

export const setChapterName = (value) => {
    return {
        type: CHAPTER_NAME_SET,
        value,
    };
};

export const showEditChapters = () => {
    return {
        type: EDIT_CHAPTER_DETAILS_SHOW,
    };
};

export const hideEditChapters = () => {
    return {
        type: EDIT_CHAPTER_DETAILS_HIDE,
    };
};

const addAssetChapterInProgress = () => {
    return {
        type: ASSET_CHAPTER_ADD_IN_PROGRESS,
    };
};

const addAssetChapterSuccess = (item) => {
    return {
        type: ASSET_CHAPTER_ADD_SUCCESS,
        item,
    };
};

const addAssetChapterError = (message) => {
    return {
        type: ASSET_CHAPTER_ADD_ERROR,
        message,
    };
};

export const addAssetChapter = (id, data, token, cb) => (dispatch) => {
    dispatch(addAssetChapterInProgress());
    const URL = urlAddAssetChapter(id);
    Axios.post(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then((res) => {
            dispatch(addAssetChapterSuccess(res.data && res.data.result ? res.data.result : []));
            cb(null);
        })
        .catch((error) => {
            dispatch(addAssetChapterError(error.response ? error.response.data.message : error.message,
            ));
            cb(error);
        });
};

const fetchAssetChaptersInProgress = () => {
    return {
        type: ASSET_CHAPTERS_FETCH_IN_PROGRESS,
    };
};

const fetchAssetChaptersSuccess = (items) => {
    const data = {};
    items.forEach((item) => {
        data[item._id] = item;
    });

    return {
        type: ASSET_CHAPTERS_FETCH_SUCCESS,
        items: data,
    };
};

const fetchAssetChaptersError = (message) => {
    return {
        type: ASSET_CHAPTERS_FETCH_ERROR,
        message,
    };
};

export const fetchAssetChapters = (id, token, categoryId, peopleId, objectName) => (dispatch) => {
    dispatch(fetchAssetChaptersInProgress());
    const URL = urlAddAssetChapter(id, categoryId, peopleId, objectName);
    Axios.get(URL,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then((res) => {
            dispatch(fetchAssetChaptersSuccess(res.data && res.data.result ? res.data.result : []));
        })
        .catch((error) => {
            dispatch(fetchAssetChaptersError(error.response ? error.response.data.message : error.message,
            ));
        });
};

export const setMinValue = (value) => {
    return {
        type: CHAPTER_MIN_VALUE_SET,
        value,
    };
};

export const setMaxValue = (value) => {
    return {
        type: CHAPTER_MAX_VALUE_SET,
        value,
    };
};

const updateAssetChapterInProgress = () => {
    return {
        type: ASSET_CHAPTER_UPDATE_IN_PROGRESS,
    };
};

const updateAssetChapterSuccess = (item) => {
    return {
        type: ASSET_CHAPTER_UPDATE_SUCCESS,
        item,
    };
};

const updateAssetChapterError = (message) => {
    return {
        type: ASSET_CHAPTER_UPDATE_ERROR,
        message,
    };
};

export const updateAssetChapter = (assetId, chapterId, data, token) => (dispatch) => {
    dispatch(updateAssetChapterInProgress());
    const URL = urlUpdateAssetChapter(assetId, chapterId);

    Axios.put(URL, data,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then((res) => {
            dispatch(updateAssetChapterSuccess(res.data && res.data.result ? res.data.result : []));
        })
        .catch((error) => {
            dispatch(updateAssetChapterError(error.response ? error.response.data.message : error.message,
            ));
        });
};

const fetchAssetChapterCategoriesInProgress = () => {
    return {
        type: ASSET_CHAPTER_CATEGORIES_FETCH_IN_PROGRESS,
    };
};

const fetchAssetChapterCategoriesSuccess = (items) => {
    return {
        type: ASSET_CHAPTER_CATEGORIES_FETCH_SUCCESS,
        items,
    };
};

const fetchAssetChapterCategoriesError = (message) => {
    return {
        type: ASSET_CHAPTER_CATEGORIES_FETCH_ERROR,
        message,
    };
};

export const fetchAssetChapterCategories = (id, token) => (dispatch) => {
    dispatch(fetchAssetChapterCategoriesInProgress());

    const URL = urlFetchAssetChapterCategories(id);
    Axios.get(URL,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then((res) => {
            dispatch(fetchAssetChapterCategoriesSuccess(res.data && res.data.result ? res.data.result : []));
        })
        .catch((error) => {
            dispatch(fetchAssetChapterCategoriesError(error.response ? error.response.data.message : error.message,
            ));
        });
};

const fetchAssetChapterPeopleInProgress = () => {
    return {
        type: ASSET_CHAPTER_PEOPLE_FETCH_IN_PROGRESS,
    };
};

const fetchAssetChapterPeopleSuccess = (items) => {
    return {
        type: ASSET_CHAPTER_PEOPLE_FETCH_SUCCESS,
        items,
    };
};

const fetchAssetChapterPeopleError = (message) => {
    return {
        type: ASSET_CHAPTER_PEOPLE_FETCH_ERROR,
        message,
    };
};

export const fetchAssetChapterPeople = (id, token) => (dispatch) => {
    dispatch(fetchAssetChapterPeopleInProgress());

    const URL = urlFetchAssetChapterPeople(id);
    Axios.get(URL,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then((res) => {
            dispatch(fetchAssetChapterPeopleSuccess(res.data && res.data.result ? res.data.result : []));
        })
        .catch((error) => {
            dispatch(fetchAssetChapterPeopleError(error.response ? error.response.data.message : error.message,
            ));
        });
};

const fetchAssetChapterObjectsInProgress = () => {
    return {
        type: ASSET_CHAPTER_OBJECTS_FETCH_IN_PROGRESS,
    };
};

const fetchAssetChapterObjectsSuccess = (items) => {
    return {
        type: ASSET_CHAPTER_OBJECTS_FETCH_SUCCESS,
        items,
    };
};

const fetchAssetChapterObjectsError = (message) => {
    return {
        type: ASSET_CHAPTER_OBJECTS_FETCH_ERROR,
        message,
    };
};

export const fetchAssetChapterObjects = (id, token) => (dispatch) => {
    dispatch(fetchAssetChapterObjectsInProgress());

    const URL = urlFetchAssetChapterObjects(id);
    Axios.get(URL,
        {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
        .then((res) => {
            dispatch(fetchAssetChapterObjectsSuccess(res.data && res.data.result ? res.data.result : []));
        })
        .catch((error) => {
            dispatch(fetchAssetChapterObjectsError(error.response ? error.response.data.message : error.message,
            ));
        });
};

export const setSliderType = (value) => {
    return {
        type: SLIDER_TYPE_SET,
        value,
    };
};
