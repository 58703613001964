import { Button } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showQuestionTypes } from '../../actions/interactiveVideo';

const AddQuestionButton = (props) => {
    const handleClick = () => {
        props.player.pause();
        props.show();
    };

    return (
        <Button
            className="add_question_button"
            onClick={handleClick}>
            Add Question
        </Button>
    );
};

AddQuestionButton.propTypes = {
    player: PropTypes.object.isRequired,
    show: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        player: state.interactiveVideo.player,
    };
};

const actionToProps = {
    show: showQuestionTypes,
};

export default connect(stateToProps, actionToProps)(AddQuestionButton);
