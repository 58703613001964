import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setArtDirector, showPeoplesDialog } from '../../../../../actions/asset';
import AutoCompleteTextField from '../../../../../components/AutoCompleteTextField/WithAddButton';

const ArtDirectorSelectField = (props) => {
    const showDialog = (value) => {
        props.dialog(value, 'art director', [...props.list], props.onChange);
    };

    return (
        <AutoCompleteTextField
            label="Art Director"
            options={props.options}
            showDialog={showDialog}
            value={props.list}
            onChange={props.onChange}/>
    );
};

ArtDirectorSelectField.propTypes = {
    dialog: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        list: state.asset.new.movies.artDirector,
        options: state.asset.new.peopleList.list,
    };
};

const actionToProps = {
    onChange: setArtDirector,
    dialog: showPeoplesDialog,
};

export default connect(stateToProps, actionToProps)(ArtDirectorSelectField);
