import { InputAdornment } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setSearchChapter } from '../../../actions/chapterMarking';
import TextField from '../../../components/TextField/InputProps';
import searchIcon from '../../../assets/search.svg';

const SearchTextField = (props) => {
    return (
        <TextField
            className="search_bar"
            id="chapter_search_text_field"
            inputProps={{
                startAdornment: (
                    <InputAdornment className="left_icon" position="start">
                        <img alt="search" src={searchIcon}/>
                        <p>|</p>
                    </InputAdornment>
                ),
            }}
            name="search"
            placeholder="Search"
            value={props.value}
            variant="filled"
            onChange={props.onChange}/>
    );
};

SearchTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.chapterMarking.search,
    };
};

const actionsToProps = {
    onChange: setSearchChapter,
};

export default connect(stateToProps, actionsToProps)(SearchTextField);
