import { Button } from '@material-ui/core';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchPlayOut, openCalendar, setSelectedDate, setWeekStartDate } from '../../../../actions/program';
import { pushSlot, startStream } from '../../../../actions/slot';
import syncIcon from '../../../../assets/sync1.svg';
import CustomIcon from '../../../../components/Icon';
import SelectDate from '../DateSelection';
import '../DateSelection/index.css';
import DropDown from './DropDown';

class StudioCalendar extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            anchorElement: null,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleToday = this.handleToday.bind(this);
    }

    handleClick (event) {
        this.setState({
            anchorElement: event.currentTarget,
        });
    }

    handleSelect (event) {
        this.props.setWeekStartDate(event.start);
        this.props.setSelectedDate(event.start);
        this.setState({
            anchorElement: null,
        });
    }

    handleClose () {
        this.setState({
            anchorElement: null,
        });
    }

    handleToday () {
        this.props.setWeekStartDate(new Date());
        this.props.setSelectedDate(new Date());
    }

    render () {
        const open = Boolean(this.state.anchorElement);

        return (
            <>
                <div>
                    <div className="select_day">
                        <Button
                            className={
                                moment(this.props.selectedDate).date() === moment(new Date()).date()
                                    ? 'sarabun_font calendar_button today_button gray_bg active_today '
                                    : 'sarabun_font calendar_button today_button gray_bg'}
                            variant="contained"
                            onClick={() => this.handleToday()}>
                            <span style={{
                                marginLeft: '10px',
                                textTransform: 'uppercase',
                            }}>
                               Today
                            </span>
                        </Button>
                        <SelectDate/>
                        <Button
                            aria-haspopup="true"
                            aria-owns={open ? 'simple-popper' : undefined}
                            className="sarabun_font calendar_button gray_bg"
                            style={{ minWidth: 'auto' }}
                            variant="contained"
                            onClick={(event) => this.handleClick(event)}>
                            <CustomIcon
                                className="calendar"
                                icon="calendar"/>
                        </Button>
                    </div>
                    <div className="sync_data">
                        {/* <Button */}
                        {/*    disabled={this.props.syncInProgress} */}
                        {/*    onClick={this.handleSync}> */}
                        {/*    Update </Button> */}
                        {
                            this.props.inProgress
                                ? <p className="sarabun_font_weight saving">
                                    Saving<span>.</span><span>.</span><span>.</span>
                                </p>
                                : <p
                                    className="sarabun_font_weight" title={`Last Updated at
                                    ${
            this.props.checked
                ? moment(this.props.lastUpdate).format(' hh:mm A')
                : moment(this.props.lastUpdate).format(' HH:mm ') + 'Hrs'
            }`}>
                                    <img alt="sync" src={syncIcon}/>
                                    Last Updated at
                                    {
                                        this.props.checked
                                            ? moment(this.props.lastUpdate).format(' hh:mm A')
                                            : moment(this.props.lastUpdate).format(' HH:mm ') + 'Hrs'
                                    }
                                </p>
                        }
                    </div>
                </div>
                <DropDown
                    anchor={this.state.anchorElement}
                    close={this.handleClose}
                    open={open}
                    select={this.handleSelect}/>
            </>
        );
    }
}

StudioCalendar.propTypes = {
    channel: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    fetchPlayOut: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lastUpdate: PropTypes.instanceOf(Date).isRequired,
    openCalendar: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    setSelectedDate: PropTypes.func.isRequired,
    setWeekStartDate: PropTypes.func.isRequired,
    slots: PropTypes.array.isRequired,
    start: PropTypes.func.isRequired,
    stream: PropTypes.string.isRequired,
    syncInProgress: PropTypes.bool.isRequired,
    calendar: PropTypes.object,
    selectedDate: PropTypes.instanceOf(Date),
    weekStartDate: PropTypes.instanceOf(Date),
};

const stateToProps = (state) => {
    return {
        selectedDate: state.live.selectedDate,
        weekStartDate: state.live.weekStartDate,
        calendar: state.live.calendar,
        channel: state.live.channel.current,
        stream: state.live.stream.current,
        syncInProgress: state.live.stream.syncInProgress,
        slots: state.live.slot.list,
        lastUpdate: state.live.lastUpdated.time,
        inProgress: state.live.lastUpdated.inProgress,
        checked: state.profile.timeFormat.checked,
    };
};

const actionToProps = {
    setWeekStartDate,
    setSelectedDate,
    openCalendar,
    start: startStream,
    push: pushSlot,
    fetchPlayOut,
};

export default connect(stateToProps, actionToProps)(StudioCalendar);
