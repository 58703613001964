import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { fetchGenres, setAddAssetGenre } from '../../../actions/asset';
import LinearProgress from '../../../components/LinearProgress';
import SelectField from '../../../components/SelectFieldAddButton';

class GenreSelectField extends React.Component {
    constructor (props) {
        super(props);
        this.fetch = this.fetch.bind(this);
    }

    componentDidMount () {
        if (this.props.items.length) {
            return;
        }

        this.fetch();
    }

    fetch () {
        if (this.props.inProgress) {
            return;
        }
        const token = localStorage.getItem('authorizationToken');
        this.props.fetch(token);
    }

    render () {
        return (
            <div className={this.props.inProgress ? 'select_field_progress' : ''}>
                <SelectField
                    genre={true}
                    id="genre_select_field"
                    items={this.props.items}
                    label="Genre"
                    name="genre"
                    value={this.props.value}
                    onChange={this.props.onChange}/>
                {
                    this.props.inProgress
                        ? <LinearProgress/>
                        : null
                }
            </div>
        );
    }
}

GenreSelectField.propTypes = {
    fetch: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.asset.new.genre,
        items: state.asset.genres.list,
        inProgress: state.asset.genres.inProgress,
    };
};

const actionsToProps = {
    fetch: fetchGenres,
    onChange: setAddAssetGenre,
};

export default connect(stateToProps, actionsToProps)(GenreSelectField);
