import { Button } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showConnectDialog } from '../../../actions/account';

const ConnectButton = (props) => {
    return (
        <Button
            className="connect_button"
            onClick={props.showConnectDialog}>
            Connect with Keplr
        </Button>
    );
};

ConnectButton.propTypes = {
    showConnectDialog: PropTypes.func.isRequired,
};

const actionToProps = {
    showConnectDialog,
};

export default connect(null, actionToProps)(ConnectButton);
