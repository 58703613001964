import { combineReducers } from 'redux';
import { DOWNLOAD_CANCEL_SET, UPLOAD_CANCEL_SET } from '../../constants/asset';
import {
    DEVICE_ID_FETCH_ERROR,
    DEVICE_ID_FETCH_IN_PROGRESS,
    DEVICE_ID_FETCH_SUCCESS,
    DOWNLOAD_FILE_CANCEL_SET,
    DOWNLOAD_FILE_DETAILS_SET,
    UPLOAD_FILE_CANCEL_SET,
    UPLOAD_FILE_DETAILS_SET,
    WATCH_URL_FETCH_ERROR,
    WATCH_URL_FETCH_IN_PROGRESS,
    WATCH_URL_FETCH_SUCCESS,
} from '../../constants/videoAssets';

const downloadFile = (state = {
    details: {},
    index: 0,
    list: [],
    cancel: () => {
    },
}, action) => {
    switch (action.type) {
    case DOWNLOAD_FILE_DETAILS_SET:
        return {
            ...state,
            details: action.value,
            index: action.index,
            list: action.list,
        };
    case DOWNLOAD_CANCEL_SET:
        return {
            ...state,
            cancel: action.cancel,
        };
    case DOWNLOAD_FILE_CANCEL_SET:
        return {
            ...state,
            list: action.list,
        };
    default:
        return state;
    }
};

const uploadFile = (state = {
    details: {},
    list: [],
    cancel: () => {
    },
}, action) => {
    switch (action.type) {
    case UPLOAD_FILE_DETAILS_SET:
        return {
            ...state,
            details: action.value,
            list: action.list,
        };
    case UPLOAD_CANCEL_SET:
        return {
            ...state,
            cancel: action.cancel,
        };
    case UPLOAD_FILE_CANCEL_SET:
        return {
            ...state,
            list: action.list,
        };
    default:
        return state;
    }
};

const playerLink = (state = {
    url: '',
    inProgress: false,
}, action) => {
    switch (action.type) {
    case WATCH_URL_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case WATCH_URL_FETCH_SUCCESS:
        return {
            inProgress: false,
            url: action.value,
        };
    case WATCH_URL_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const deviceID = (state = {
    id: '',
    inProgress: false,
}, action) => {
    switch (action.type) {
    case DEVICE_ID_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case DEVICE_ID_FETCH_SUCCESS:
        return {
            value: action.value,
            inProgress: false,
        };
    case DEVICE_ID_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    downloadFile,
    uploadFile,
    playerLink,
    deviceID,
});
