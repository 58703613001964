import { combineReducers } from 'redux';
import {
    USER_SIGNUP_CREATE_PASSWORD_SET,
    USER_SIGNUP_EMAIL_SET,
    USER_SIGNUP_ERROR,
    USER_SIGNUP_FULLNAME_SET,
    USER_SIGNUP_IN_PROGRESS,
    USER_SIGNUP_RE_ENTER_PASSWORD_SET,
    USER_SIGNUP_SUCCESS,
} from '../constants/signUp';

const email = (state = {
    value: '',
    valid: false,
}, action) => {
    if (action.type === USER_SIGNUP_EMAIL_SET) {
        return {
            value: action.value,
            valid: action.valid,
        };
    }

    return state;
};

const fullName = (state = '', action) => {
    if (action.type === USER_SIGNUP_FULLNAME_SET) {
        return action.value;
    }

    return state;
};

const createPassword = (state = '', action) => {
    if (action.type === USER_SIGNUP_CREATE_PASSWORD_SET) {
        return action.value;
    }

    return state;
};

const reEnterPassword = (state = '', action) => {
    if (action.type === USER_SIGNUP_RE_ENTER_PASSWORD_SET) {
        return action.value;
    }

    return state;
};

const inProgress = (state = false, action) => {
    switch (action.type) {
    case USER_SIGNUP_IN_PROGRESS:
        return true;
    case USER_SIGNUP_SUCCESS:
    case USER_SIGNUP_ERROR:
        return false;
    default:
        return state;
    }
};

const authenticate = (state = false, action) => {
    if (action.type === USER_SIGNUP_SUCCESS) {
        return true;
    }

    return state;
};

const userSignUp = (state = {
    result: {},
}, action) => {
    if (action.type === USER_SIGNUP_SUCCESS) {
        return {
            ...state,
            result: action.result,
        };
    }

    return state;
};

export default combineReducers({
    email,
    fullName,
    createPassword,
    reEnterPassword,
    inProgress,
    userSignUp,
    authenticate,
});
