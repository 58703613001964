import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setInteractiveSave, setQuestionType } from '../../../actions/interactiveVideo';

const MCQType = (props) => {
    const handleChange = (event, id) => {
        const obj = {
            ...props.answers,
            [id]: event.target.value,
        };

        props.onChange(obj);
        props.player.play();
    };

    const handleChecked = (option) => {
        if (option.goto) {
            props.player.currentTime((option.goto) / 1000);
        }
    };

    return (
        <div className="options_section">
            <FormControl className="radio_section" component="fieldset">
                <RadioGroup
                    value={props.answers && props.answers[props.value && props.value._id]}
                    onChange={(event) => handleChange(event, props.value && props.value._id)}>
                    {props.value && props.value.options &&
                    props.value.options.length > 0 &&
                    props.value.options.map((value) => {
                        return (
                            <>{value.type === 'IMAGE' ? <img
                                alt="image_option" height="100px"
                                src={value.value} width="100px"/>
                                : value.type === 'URL'
                                    ? <a href={value.value} rel="noreferrer" target="_blank">Click </a>
                                    : <FormControlLabel
                                        key={value._id}
                                        control={<Radio/>}
                                        label={value.value}
                                        value={value.value}
                                        onChange={() => handleChecked(value)}/>
                            }
                            </>
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </div>
    );
};

MCQType.propTypes = {
    answers: PropTypes.object.isRequired,
    player: PropTypes.object.isRequired,
    setQuestionType: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        player: state.interactiveVideo.player,
        answers: state.interactiveVideo.answers,
    };
};

const actionToProps = {
    onChange: setInteractiveSave,
    setQuestionType,
};

export default connect(stateToProps, actionToProps)(MCQType);
