import React from 'react';
import DialogAddAssetCategory from '../../../../Sidebar/DialogAddAssetCategory';
import AddPeople from '../../../../AddAssets/Tabs/MetadataForDistribution/AddPeople';
import TagsTextField from '../../../../AddAssets/Tabs/MetadataForDistribution/TagsTextField';
import CategorySelectField from '../../../../ChapterMarking/EditPanel/CategorySelectField';
import PeopleSelectField from '../../../../ChapterMarking/EditPanel/PeopleSelectField';
import SlotNameTextField from './SlotNameTextField';

const ContentTab = () => {
    return (
        <div className="content_tab">
            <div className="row">
                <p className="heading_text">Slot Name</p>
                <SlotNameTextField/>
            </div>
            <div className="row">
                <p className="heading_text">Categories</p>
                <CategorySelectField/>
            </div>
            <div className="row">
                <p className="heading_text">Tags</p>
                <TagsTextField noLabel={true}/>
            </div>
            <div className="row">
                <p className="heading_text">People/Cast</p>
                <PeopleSelectField/>
            </div>
            <DialogAddAssetCategory/>
            <AddPeople/>
        </div>
    );
};

export default ContentTab;
